import React from 'react';
import { useSelector } from 'react-redux';
import { Store } from '../../../../../redux/types';
import { Booking } from '../../../../../redux/stores/planning/types';
import useStyles from '../style';
import editIcon from '../../../../../assets/style-elements/Icon-edit.svg';
import commentIcon from '../../../../../assets/style-elements/01-electrons-icon-comment-gay@3x.png';
import { Networks } from '../../../../../redux/stores/global/types';
import EditComment from '../../../modal/editComment';

interface CommentsProps {
  currentBooking: Booking
}

const Comments = ({ currentBooking }: CommentsProps) => {

  const lg = useSelector((state: Store) => state.languageReducer.language);

  const classes = useStyles();

  const isGarageOwner = currentBooking.networkId === Networks.GARAGE_OWNER;

  const isEmpty =
    currentBooking.comment === null || currentBooking.comment === '';
  
  return (
    <div className={classes.donateItemWrapper}>
      <img className="leftIcon" src={commentIcon} alt="comment icon" />

      <div className="donateList">
        <div className="donateItem">
          <p className="first">{lg.command.lateral.comment}</p>

          <p className="second text-wrap">
            {!isEmpty
              ? `${currentBooking.comment}`
              : `${lg.command.lateral.noComment}`}
          </p>
        </div>
      </div>
      {isGarageOwner && (
        <div className="editButton">
          <EditComment>
            <img className="rightIcon" src={editIcon} alt="edit icon" />
          </EditComment>
        </div>
      )}
    </div>
  )
}

export default Comments;
import React from 'react';
import { useSelector } from 'react-redux';
import RenderOnRoles from '../../assets/generic-components/RenderOnRoles';
import logo from '../../assets/images/logo.svg';
import appImg from '../../assets/images/app.png';
import LogoutButton from '../../atomic/components/atoms/LogoutButton';
import AuthenticationService, {
  Roles,
} from '../../services/authentication-service';
import useStyles from './style';
import { Store } from '../../redux/types';
import { Garage } from '../../redux/stores/global/types';
import Language from '../header/language';
import iosImg from '../../assets/images/ios.svg';
import androidImg from '../../assets/images/android.svg';
import iosNlImg from '../../assets/images/ios-nl.svg';
import androidNlImg from '../../assets/images/android-nl.svg';
import { isIos } from '../../utils/constants';

interface MobileViewProps {
  currentGarage?: Garage,
  deviceLink: string
}

const MobileView = ({ deviceLink, currentGarage }: MobileViewProps) => {
  const classes = useStyles();

  const lg = useSelector((state: Store) => state.languageReducer.language);

  const android = lg.locale === 'nl' ? androidNlImg : androidImg;

  const ios = lg.locale === 'nl' ? iosNlImg : iosImg;

  const deviceImg = isIos ? ios : android;

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.headerTop}>
          <img src={logo} alt='logo' className={classes.logo} />
          <div className={classes.language}>
            <Language setLanguage={undefined} setImage={undefined} />
          </div>
          {currentGarage && <LogoutButton onClick={() => AuthenticationService.doLogout()} />}
        </div>
        {currentGarage && (
          <h2 className={classes.garageInfo}>
            {lg.mobileApp.garage}&nbsp;
            <span className={classes.garageName}>
              {currentGarage.companyName} ({currentGarage.id})
            </span>
          </h2>
        )}
        <RenderOnRoles roles={[Roles.CALL_CENTER, Roles.ADMIN]}>
          <div className={classes.admin}>{lg.mobileApp.admin}</div>
        </RenderOnRoles>
      </div>

      <div className={classes.container}>
        <div className={classes.wording}>
          <p>{lg.mobileApp.text1}</p>
          <p className={classes.orangeWording}>{lg.mobileApp.text2}</p>
          <p>
            {lg.mobileApp.text3} <br /> {lg.mobileApp.text4}
          </p>
        </div>
        <img src={appImg} alt="application" className={classes.appImg} />
        <a href={deviceLink}>
          <img
            className={classes.downloadLink}
            src={deviceImg}
            alt="application"
          />
        </a>
      </div>
    </div>
  );
};

MobileView.defaultProps = {
  currentGarage: null,
};

export default MobileView;

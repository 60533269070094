import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { useSelector } from 'react-redux';
import Button from '../../../../assets/generic-components/Button';
import close from '../../../../assets/style-elements/01-electrons-icon-close.png';
import { Store } from '../../../../redux/types';
import { CountryCodes } from '../../../../redux/stores/global/types';
import useStyles from './style';

interface IssueProps {
  onSave: (message: string) => void;
}

enum IssueTab {
  QUOTE = 'QUOTE',
  PLANNING = 'PLANNING',
  OTHER = 'OTHER',
}

const Issue: React.FC<IssueProps> = ({ onSave }) => {
  const classes = useStyles();
  const {
    languageReducer: { language: lg },
    planningStore: { currentBooking },
    globalReducer: { currentGarage },
  } = useSelector((state: Store) => state);
  const isPricelessQuote = currentBooking?.quotes[0].quoteType === 'PRICELESS';
  const isBE = currentGarage?.garageAddress.country === CountryCodes.BE;
  const [show, setShow] = useState(false);
  const [hide, setHide] = useState(false);
  const [message, setMessage] = useState('');
  const [len, setLength] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState({ display: false, message: '' });
  const [activeTab, setActiveTab] = useState(
    isPricelessQuote ? IssueTab.PLANNING : IssueTab.QUOTE
  );

  const issueTabsInformations: Record<IssueTab, string[]> = {
    [IssueTab.QUOTE]: [
      lg.command.issue.details.advertiser,
      lg.command.issue.details.params,
    ],
    [IssueTab.PLANNING]: [
      lg.command.issue.details.advertiser,
      lg.command.issue.details.planning,
    ],
    [IssueTab.OTHER]: [lg.command.issue.details.advertiser],
  };

  const issueTabsInformationsBE: Record<IssueTab, string[]> = {
    [IssueTab.QUOTE]: [lg.command.issue.details.params],
    [IssueTab.PLANNING]: [lg.command.issue.details.planning],
    OTHER: [],
  };

  const TabInformation = isBE ? issueTabsInformationsBE : issueTabsInformations;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleHide = () => setHide(true);

  const handleKeyUp = (e: any) => {
    setLength(e.target.value.length);
    if (len < 10 && len > 0) {
      setError({ display: true, message: lg.command.issue.error });
      setDisabled(true);
    } else {
      setError({ display: false, message: '' });
      setDisabled(false);
    }
    return true;
  };

  return (
    <>
      <Button
        theme="grey"
        title={lg.command.detail.issue}
        onClick={handleShow}
      />
      <Modal
        dialogClassName={classes.content}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className={classes.modal_header}>
          <div className={classes.root}>
            <span className={classes.head}>{lg.command.issue.title}</span>
          </div>

          <img
            className={classes.imgPopup}
            src={close}
            alt="X"
            onClick={() => setShow(false)}
          />
        </Modal.Header>
        <Modal.Body className={classes.modal_body}>
          {!hide ? (
            <div className={classes.root}>
              <p className={classes.title}>{lg.command.issue.select} :</p>
              <div className={classes.block}>
                {!isPricelessQuote && (
                  <div
                    className={
                      activeTab === IssueTab.QUOTE ? 'first' : 'unfocusLeft'
                    }
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex={0}
                    onClick={() => setActiveTab(IssueTab.QUOTE)}
                  >
                    {lg.command.issue.number}
                  </div>
                )}
                <div
                  className={
                    activeTab === IssueTab.PLANNING ? 'second' : 'unfocus'
                  }
                  onKeyPress={() => {}}
                  role="button"
                  tabIndex={0}
                  onClick={() => setActiveTab(IssueTab.PLANNING)}
                >
                  {lg.command.issue.planning}
                </div>
                <div
                  className={activeTab === IssueTab.OTHER ? 'third' : 'unfocus'}
                  onKeyPress={() => {}}
                  role="button"
                  tabIndex={0}
                  onClick={() => setActiveTab(IssueTab.OTHER)}
                >
                  {lg.command.issue.other}
                </div>
              </div>
              {TabInformation[activeTab].map(
                (info: React.ReactChild, index: React.Key) => (
                  <p className="alert" key={index}>
                    <span>
                      <InfoOutlined />
                    </span>
                    {info}
                  </p>
                )
              )}
              <p className={classes.subtitle}>{lg.command.issue.subtitle}</p>
              <textarea
                className={classes.textarea}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                name=""
                cols={60}
                rows={10}
                maxLength={360}
                onKeyUp={handleKeyUp}
              />
              <span className={classes.count}>
                {len}
                /360
              </span>
              <span className={error.display ? classes.error : classes.hide}>
                {error.message}
              </span>
            </div>
          ) : (
            <div className={classes.root}>
              <p className={classes.title}>{lg.command.issue.confirm}</p>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer className={classes.modal_footer}>
          {!hide ? (
            <>
              <Button
                theme="light"
                title={lg.command.issue.close}
                onClick={() => handleClose()}
              />
              <Button
                theme="dark"
                title={lg.command.issue.signal}
                disabled={disabled}
                onClick={() => {
                  onSave(message);
                  handleHide();
                }}
              />
            </>
          ) : (
            <Button
              theme="light"
              title={lg.command.issue.back}
              onClick={() => handleClose()}
            />
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Issue;

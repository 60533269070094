const accountActions = {};

accountActions.setHours = (dispath, data) => {
  dispath({
    type: 'SET_HOURS',
    payload: data
  });
};

export default accountActions;

import {
  ActionTypes,
  ADD_AVAILABILITIES,
  REMOVE_AVAILABILITY,
  SET_AVAILABILITIES,
  SET_CALENDAR,
  SET_CALENDAR_INITIALIZED
} from './actions';
import { CalendarStore } from './types';

const calendarStore = (
  state: CalendarStore = {
    isInitialized: true,
  },
  action: ActionTypes
): CalendarStore => {
  switch (action.type) {
    case SET_CALENDAR_INITIALIZED:
      return { ...state, isInitialized: action.payload };
    case SET_AVAILABILITIES:
      return { ...state, currentWeekAvailabilities: action.payload };
    case ADD_AVAILABILITIES:
      return {
        ...state,
        currentWeekAvailabilities: (state.currentWeekAvailabilities || [])
          .concat(action.payload)
      };
    case REMOVE_AVAILABILITY:
      return {
        ...state,
        currentWeekAvailabilities: (state.currentWeekAvailabilities || [])
          .filter(av => av.day !== action.payload)
      };
    case SET_CALENDAR:
      return { ...state, calendar: action.payload, isInitialized: true };
    default:
      return state;
  }
};

export default calendarStore;

import { ThunkAction } from 'redux-thunk';
import _get from 'lodash/get';
import { Action } from 'redux';
import { getHelpCategoriesData } from '../../../services/strapi-request-service';
import { Store } from '../../types';

export const SET_HELP_DATA = 'SET_HELP_DATA';

export type ActionTypes = { type: typeof SET_HELP_DATA; payload: [] };

export const getHelpCategories = (
  
  force = false

): ThunkAction<void, Store, unknown, Action<string>> => async (
  
  dispatch,
 
  getState: () => Store

) => {
  const existingCategories = _get(getState(), 'helpStore.helpCategories');
  if (!force && existingCategories && existingCategories.length > 0) {
    return;
  }
  const helpCategoriesData = await getHelpCategoriesData();
  dispatch({ type: SET_HELP_DATA, payload: helpCategoriesData });
};

import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import HelpView from './components/helpView';
import { HelpHeader } from '../../redux/stores/languages';
import { getHelpCategories } from '../../redux/stores/help/actions';
import { Store } from '../../redux/types';
import Navigation from './components/navigation';
import CategoryView from './components/categoryView';

interface HelpProps {
  languageReducer: {
    language: {
      helpHeader: HelpHeader;
    };
  };
}

const Help: React.FC<HelpProps> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const helpStore = useSelector((state: Store) => state.helpStore);
  const { helpCategories } = helpStore;
  const { url, path } = useRouteMatch();

  const [selectedQuestionId, setSelectedQuestion] = useState('');
  const [activePath, setActivePath] = useState('homeHelp');

  const useStyles = makeStyles(() => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      color: 'darkgrey',
      overflow: 'hidden'
    }
  }));

  const classes = useStyles();

  useEffect(() => {
    history.push({ pathname: `${url}/homeHelp` });
  }, [history, url]);

  useEffect(() => {
    dispatch(getHelpCategories(process.env.REACT_APP_ENV === 'dev'));
  }, []);

  const handleRedirect = (to: string, selectedId: string): void => {
    setSelectedQuestion(selectedId);
    history.push({ pathname: `${path}/${to}` });
  };

  return (
    <div className={classes.root}>
      <Navigation activePath={activePath} setActivePath={setActivePath} />
      <Switch>
        <Route path={`${path}/homeHelp`}>
          <HelpView
            handleRedirect={handleRedirect}
            setActivePath={setActivePath}
          />
        </Route>
        {(helpCategories || []).map(category => (
          <Route path={`${path}/${category.path}`}>
            <CategoryView
              category={category}
              activeQuestionId={selectedQuestionId}
              setActiveQuestionId={setSelectedQuestion}
            />
          </Route>
        ))}
      </Switch>
    </div>
  );
};

Help.propTypes = {};

export default Help;

import moment from 'moment';
import { Quote, QuoteStatus } from '../../redux/stores/planning/types';
import HttpService from '../http-service';
import { QuoteResponse, QuoteType } from '../../redux/stores/quotes/types';
import { CarService } from '../../redux/stores/global/types';

export const DEFAULT_PRICELESS_SERVICE_ID = '500101';

export const getQuoteByVehicleAndService = (
  vehicleId: string,
  serviceId: string,
  garageId: string,
  km?: number | null,
  releaseDate?: Date | null
): Promise<{ data: QuoteResponse }> => {
  let params: {
    'garageId[]': string;
    kilometers?: number;
    releaseDate?: string;
  } = { 'garageId[]': garageId };

  if (km && releaseDate) {
    params = {
      ...params,
      kilometers: km,
      releaseDate: moment(releaseDate).format('YYYY-MM-DD')
    };
  }
  const url = `${process.env.REACT_APP_API_QUOTES_URL}/vehicles/${vehicleId}/services/${serviceId}`;
  return HttpService.getClient().get(url, {
    headers: { 'api-key': process.env.REACT_APP_API_QUOTES_KEY },
    params
  });
};

/**
 * Retrieve service details for a quote id.
 * @param quote
 * @param carServiceList
 */
export const getServiceDetailForQuote = (
  quote: Quote,
  carServiceList: Record<string, CarService>
): CarService | null => carServiceList[quote.carServiceId];

export const getDefaultPricelessPayload = (
  serviceId: string,
  duration?: number
): Quote => ({
  quoteId: 'PRICELESS-DEFAULT-QUOTE-ID',
  carServiceId: serviceId,
  quantity: 1,
  quoteType: QuoteType.PRICELESS,
  forfaitMalin: false,
  operations: [],
  discountTotal: 0,
  price: {
    maxPriceExclTax: 0,
    minPriceExclTax: 0
  },
  duration,
  status: QuoteStatus.CONFIRMED
});

export const formatPrice = (price: number, locale: string): string =>
  new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'EUR'
  }).format(price);

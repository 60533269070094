import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import './assets/css/print.css';

import RenderOnRoles from './assets/generic-components/RenderOnRoles';
import Unauthorized from './atomic/pages/Unauthorized';
import history from './history';
import './index.css';
import initState from './redux/initialState';
import configureStore from './redux/store';
import AuthenticationService, {
  Roles,
} from './services/authentication-service';
import HttpService from './services/http-service';
import * as serviceWorker from './serviceWorker';
import MobileView from './components/mobile-view';
import { isIos, isMobile, MobileViewProps } from './utils/constants';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENV,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const unAuthorizedView = () => {
  if (isMobile) {
    return <MobileView {...MobileViewProps[isIos ? 'ios' : 'android']} />;
  }

  return <Unauthorized />;
};

const renderApp = (initialState) => {
  const { store, persistor } = configureStore(initialState);
  // eslint-disable-next-line react/no-render-return-value
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={history}>
          <RenderOnRoles
            roles={[Roles.GARAGE, Roles.CALL_CENTER, Roles.ADMIN]}
            fallbackComponent={unAuthorizedView()}
          >
            <App />
          </RenderOnRoles>
        </Router>
      </PersistGate>
    </Provider>,
    document.getElementById('root')
  );
};

const appView = () => {
  const app = isMobile
    ? renderApp(initState())
    : AuthenticationService.initKeycloak(() => renderApp(initState()));

  return app;
};

appView();
HttpService.configure();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

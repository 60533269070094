import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import Button from '../../../assets/generic-components/Button';
import colors from '../../../assets/style-colors';
import { Store } from '../../../redux/types';

interface RefundProps {
  onClick: () => void;
}

const Refund: React.FC<RefundProps> = ({ onClick, children }) => {
  const useStyles = makeStyles(() => ({
    root: {
      display: 'flex',
      flex: '100%',
      width: '530px',
      margin: '0px auto',
      flexWrap: 'wrap',
      alignItems: 'flex-start',

      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    content: {
      width: '530px',
      margin: '0px auto',
    },
    head: {
      display: 'flex',
      flex: '100%',
      fontSize: '15px',
      color: colors.dark,
      marginTop: '5px',
    },
    title: {
      display: 'flex',
      flex: '100%',
      fontSize: '12px',
      color: colors.slateGrey,

      fontWeight: 400,
    },
    subtitle: {
      display: 'flex',
      flex: '100%',
      fontSize: '12px',
      color: colors.slateGrey,
      marginTop: '30px',
    },
    textarea: {
      background: colors.paleGrey,
      padding: '15px',
      borderRadius: '8px',
      border: '0px solid white',
      fontSize: '13px',
      fontWeight: 'bold',
      color: colors.dark,
    },
    block: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      backgroundColor: 'white',
      border: `solid 1px ${colors.whiteLight}`,
      borderRadius: '8px',
      '& .first': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '156px',
        height: '40px',
        backgroundColor: colors.whiteLight,
        fontSize: '13px',
        fontWeight: 'bold',
        textAlign: 'center',
        cursor: 'pointer',
        color: '#2c2e3d',
        borderRadius: '8px 0px 0px 8px',
      },
      '& .second': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '156px',
        height: '40px',
        backgroundColor: '#eceef6',
        fontSize: '13px',
        fontWeight: 'bold',
        textAlign: 'center',
        cursor: 'pointer',
        color: colors.dark,
        borderRadius: '0px 0px 0px 0px',
      },
      '& .third': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '156px',
        height: '40px',
        backgroundColor: colors.whiteLight,
        fontSize: '13px',
        fontWeight: 'bold',
        textAlign: 'center',
        cursor: 'pointer',
        color: colors.dark,
        borderRadius: '0px 8px 8px 0px',
      },
      '& .unfocusLeft': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '156px',
        height: '40px',
        fontSize: '13px',
        fontWeight: 'bold',
        textAlign: 'center',
        cursor: 'pointer',
        color: colors.lightGreyBlue,
      },
      '& .unfocus': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '156px',
        height: '40px',
        fontSize: '13px',
        fontWeight: 'bold',
        textAlign: 'center',
        cursor: 'pointer',
        color: colors.lightGreyBlue,
        borderLeft: `solid 1px ${colors.whiteLight}`,
      },
    },
    modal_header: {
      padding: '30px 30px 0px 30px',
      border: '0px solid white',
    },
    modal_body: {
      padding: '15px 30px 15px 30px',
    },
    modal_footer: {
      background: '#f6f7fb',
      borderTop: '0px solid #f6f7fb',
      padding: '20px',
    },
  }));

  // CONSTANTS:
  const classes = useStyles();
  const { languageReducer } = useSelector((state: Store) => state);
  const lg = languageReducer.language;
  const [show, setShow] = useState(false);

  // FUNCTIONS:

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // STYLES:

  // HTML:

  return (
    <>
      <div
        onKeyPress={() => {}}
        role="button"
        tabIndex={0}
        onClick={handleShow}
      >
        {children}
      </div>

      <Modal
        dialogClassName={classes.content}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className={classes.modal_header} closeButton>
          <div className={classes.root}>
            <span className={classes.head}>{lg.command.refund.title}</span>
          </div>
        </Modal.Header>
        <Modal.Body className={classes.modal_body}>
          <div className={classes.root}>
            <p className={classes.title}>{lg.command.refund.subtitle}</p>
          </div>
        </Modal.Body>

        <Modal.Footer className={classes.modal_footer}>
          <Button
            theme="light"
            title={lg.command.refund.close}
            onClick={() => handleClose()}
          />
          <Button
            theme="dark"
            title={lg.command.refund.cancel}
            onClick={() => {
              onClick();
              handleClose();
            }}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Refund;

import React from 'react';
import useSWRImmutable from "swr/immutable";
import { Vehicle } from "../../../../redux/stores/quotes/types";
import { getVehicleDetailsById } from "../../../../services/vehicle-identification-service";

interface VehicleNameProps {
  vehicleId: string;
}

const VehicleName: React.FC<VehicleNameProps> = ({ vehicleId }) => {
  const { data } = useSWRImmutable<Vehicle | null, any>(
    vehicleId as any,
    getVehicleDetailsById as any
  );

  return data ? (
    <span>
      {data.version.model.make.label} {data.label}
    </span>
  ) : null;
};

export default React.memo(VehicleName);
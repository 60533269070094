import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import autossimoIcon from '../../../assets/images/autossimo@3x.png';
import colors from '../../../assets/style-colors';
import truckIcon from '../../../assets/style-elements/01-electrons-icon-truck@3x.png';
import { PartOperation } from '../../../redux/stores/planning/types';
import { Store } from '../../../redux/types';
import useModeTablet from '../modeTablet';
import AutossimoModalForm from '../../autossimo/autossimo-modal-form';
import { Country } from '../../../redux/stores/garages/type';

const Autossimo = () => {
  // CONSTANTS:
  const {
    languageReducer,
    planningStore: { currentBooking },
    globalReducer: { currentGarage },
  } = useSelector((state: Store) => state);

  const lg = languageReducer.language;
  const modeTablet = useModeTablet();
  const [isModalFormShowed, setIsModalFormShowed] = useState(false);

  if (!currentBooking) return null;

  const pieces = currentBooking.quotes.flatMap((q) => {
    const partOperations = q.operations.filter(
      (o) => o.operationType === 'parts'
    ) as PartOperation[];
    return partOperations.map(
      (o: PartOperation) => `<piece>
      <reference>${o.oemPartsId[0]}</reference>
      <codEquip></codEquip>
      <libelle></libelle>
      <quantite>${o.quantity}</quantite>
      <dispo></dispo>
      <generalArticleTecdoc></generalArticleTecdoc>
      <familleAutodoc></familleAutodoc>
      <prixOrigineHT>${o.unitPrice.toFixed(2)}</prixOrigineHT>
      <prixEquipementierHT></prixEquipementierHT>
      <prixVenteHT></prixVenteHT>
      <prixAchatHT></prixAchatHT>
      <temps></temps>
      <taux></taux>
      <commentairePiece></commentairePiece>
    </piece>`
    );
  });

  const autossimoXML = `<contenu>
    <identification>
      <numCompte></numCompte>
      <siteRattachement></siteRattachement>
      <siteLivraison></siteLivraison>
      <nomPartenaire>${currentBooking.networkId}</nomPartenaire>
      <numDossier>${currentBooking.bookingId}</numDossier>
      <urlNok></urlNok>
      <mailNok></mailNok>
      <hookUrl></hookUrl>
      <commentaireIdentification>codrep: ${
        currentBooking.garageId
      }</commentaireIdentification>
      <numCmdeAutossimo></numCmdeAutossimo>
    </identification>
    <pieces>
      <typePiece>ORIG</typePiece>
      ${pieces.join('')}
    </pieces>
  </contenu>
  `.replaceAll(/\s/g, '');

  // STATES:

  // STYLES:
  const useStyles = makeStyles(() => ({
    auto: {
      display: 'flex',
      flex: modeTablet ? '100%' : '30%',
      flexWrap: 'wrap',
      background: colors.paleGrey,
      '& .bloc': {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        padding: '30px 40px',
        background: '#ffffff',
        borderTop: '4px solid #e50616',
        borderRadius: '10px',
        overflow: 'hidden',

        '& .blocTitle': {
          fontSize: '20px',
          color: '#6d6d6d',
          marginTop: '40px',
          fontWeight: 'bold',
        },
      },
      '& .piece': {
        display: 'flex',
        flex: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#6d6d6d',
        '& .img': {
          width: '175px',
        },
        '& .span': {
          display: 'flex',
          flex: '100%',
          justifyContent: 'center',
          alignItems: 'center',

          fontSize: '20px',
          fontWeight: 'bold',

          lineHeight: '1.2',

          textAlign: 'center',
          marginTop: '60px',
        },
      },
      '& .command': {
        fontSize: '16px',

        lineHeight: '1.56',
        padding: '0 11px',
        textAlign: 'center',
        marginTop: '10px',
      },
      '& .autossimo': {
        width: '100%',
        '& .wrap': {
          display: 'flex',
          fontSize: '12px',
          color: '#045c9d',
          justifyContent: 'center',
          alignItems: 'center',
          border: '1px solid #045c9d',
          padding: '14px 16px',
          background: colors.transparent,
          borderRadius: '10px',
          cursor: 'pointer',
        },
        '& .img': {
          height: '13px',
        },
      },
      '& .delivery': {
        '& .wrap': {
          display: 'flex',
          flexWrap: 'nowrap',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '14px 16px',
        },
        '& .img': {
          width: '19px',
          height: '14px',
        },
        '& .span': {
          fontSize: '13px',

          lineHeight: 'normal',

          textAlign: 'center',
          marginLeft: '9px',
        },
      },
    },
  }));

  const classes = useStyles();

  // HTML:

  return (
    <>
      {currentGarage?.garageAddress.country === Country.FR && (
        <div className={`${classes.auto} noprint`}>
          <AutossimoModalForm
            isOpen={isModalFormShowed}
            onHide={() => setIsModalFormShowed(false)}
            autossimoXML={autossimoXML}
          />
          <div className="bloc">
            <span className="piece">
              <img className="img" src={autossimoIcon} alt="auto" />
            </span>
            <span className="span blocTitle">{lg.command.autossimo.title}</span>
            <span className="command">{lg.command.autossimo.subtitle}</span>

            <div
              className="autossimo noprint"
              onClick={() => {
                setIsModalFormShowed(true);
              }}
              role="button"
              tabIndex={0}
            >
              <span className="wrap">{lg.command.autossimo.piece}</span>
            </div>
            <div className="delivery">
              <span className="wrap">
                <img className="img" alt="truckIcon" src={truckIcon} />
                <span className="span">{lg.command.autossimo.delivery}</span>
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Autossimo;

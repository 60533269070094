import makeStyles from '@material-ui/core/styles/makeStyles';
import colors from '../../../../assets/style-colors';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    paddingTop: '60px',
    width: '60%',
    margin: 'auto',
    minHeight: '100vh',
    flexDirection: 'column',

    '& .tab': {
      '& .active': {
        backgroundColor: `${colors.whiteLight}`,
      },
    },

    '& .tab.active': {
      backgroundColor: `${colors.whiteLight}`,
    },

    '& .label + .show.dropdown': {
      '& .dropdown-menu': {
        width: '100%',
        marginTop: '10px',
        border: 'none',
        boxShadow: '2px 4px 8px 0 rgba(136, 135, 135, 0.5)',
        borderRadius: '8px',
      },

      '& .dropdown-menu .list > p': {
        marginLeft: '20px',
      },
    },

    '& h4': {
      fontSize: '20px',
    },

    '& .btnValidation': {
      backgroundColor: 'rgb(44 46 61 / 25%)',

      '&:disabled': {
        backgroundColor: `${colors.paleBlue}`,
        color: `${colors.fordGray}`,

        '&:hover': {
          backgroundColor: 'none',
        },
      },
    },

    '& .btn': {
      border: 'none',
      backgroundColor: `${colors.white}`,
      color: `${colors.black}`,
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignContent: 'center',
      padding: '9px 15px',
    },

    '& .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle':
      {
        color: 'black',
        backgroundColor: `${colors.paleGrey}`,
      },

    '& .pt60': {
      paddingTop: '60px',
    },

    '& .dropdown-toggle::after': {
      marginTop: '10px',
      marginLeft: '10px',
    },

    '& .label': {
      fontSize: '12px',
      color: `${colors.slateGrey}`,
      marginBottom: '10px',
    },
  },

  boxTitle: {
    cursor: 'pointer',
  },

  backwardGrey: {
    color: `${colors.slateGrey}`,
  },

  backward: {
    fontSize: '12px',
    color: `${colors.slateGrey}`,
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',

    '& svg': {
      fontSize: '17px',
    },

    '& p': {
      margin: 0,
      padding: 0,
    },
  },

  box: {
    flexDirection: 'row',
    padding: '10px 0',

    '& p': {
      marginBottom: '5px',
    },
  },

  wrapBtn: {
    display: 'flex',

    '& button': {
      marginRight: 0,
      boxShadow: 'none',

      '&:first-child': {
        borderRadius: '8px 0 0 8px',
      },

      '&:last-child': {
        borderRadius: '0 8px 8px 0',
      },
    },
  },

  dropdownMenu: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '400px',

    '& input': {
      margin: '10px 20px',
    },

    '& label': {
      marginBottom: 0,
    },
  },

  list: {
    width: '578px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingLeft: '22px',

    '& p': {
      fontSize: '12px',
      border: `0.3px solid ${colors.veryLightBlue}`,
      margin: '5px 5px 6px 0',
      padding: '5px',
      borderRadius: '3px',
      flexWrap: 'wrap',
      backgroundColor: `${colors.veryPaleGrey}`,
      color: `${colors.dark}`,
      fontWeight: 'bold',

      '& span': {
        color: `${colors.red}`,
        fontWeight: 'bold',
        fontSize: '13px',
        marginLeft: '5px',
        marginRight: '5px',
        cursor: 'pointer',
      },
    },
  },

  input: {
    backgroundColor: `${colors.paleGrey}`,
    margin: '20px',
    height: '40px',
    padding: '10px',
    borderRadius: '5px',
    border: 'none',
  },

  boxDropdow: {
    overflow: 'auto',
    marginLeft: '20px',
    marginRight: '20px',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },

  resultfound: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },

  boxBtn: {
    marginLeft: '20px',
    marginRight: '20px',
  },

  nextBtn: {
    paddingTop: '40px',

    '& button': {
      position: 'relative',
    },
  },
}));

export default useStyles;

import userActions from './userAction';
import languageActions from './languageAction';
import statusActions from './statusAction';
import calendarActions from './calendarAction';
import commandActions from './commandAction';
import planningActions from './planningAction';
import accountActions from './accountAction';
import facturationActions from './facturationAction';
import tarifsActions from './tarifsAction';
import helpActions from './helpAction';

const allActions = {
  userActions,
  accountActions,
  languageActions,
  statusActions,
  calendarActions,
  commandActions,
  planningActions,
  facturationActions,
  tarifsActions,
  helpActions
};

export default allActions;

import React from 'react';

const PlanningIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    <path
      fill="#AAAEC5"
      fillRule="evenodd"
      d="M12.657 0c.39 0 .713.276.765.635l.007.1-.001 1.005h.715c2.064 0 3.749 1.542 3.852 3.482l.005.189v8.918C18 16.357 16.273 18 14.143 18H3.857C1.727 18 0 16.357 0 14.33V5.41c0-2.026 1.727-3.67 3.857-3.67h.714V.734c0-.405.346-.734.772-.734.39 0 .713.276.764.635l.007.1V1.74h5.771V.734c0-.405.346-.734.772-.734zm3.8 8.429H1.542v5.9c0 1.166.952 2.12 2.157 2.198l.158.005h10.286c1.278 0 2.314-.986 2.314-2.203v-5.9zm-4.572-5.221H6.114v1.007c0 .405-.345.734-.771.734-.39 0-.713-.277-.765-.635l-.007-.1V3.209h-.714c-1.225 0-2.227.906-2.309 2.052l-.005.15-.001 1.55h14.915V5.41c0-1.165-.951-2.119-2.156-2.196l-.158-.006h-.715v1.007c0 .405-.345.734-.77.734-.391 0-.714-.277-.765-.635l-.007-.1-.001-1.006z"
    />
  </svg>
);

export default PlanningIcon;
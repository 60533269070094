import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import { useSelector } from 'react-redux';
import { Store } from '../../../../redux/types';
import WeekNavigation from './components/navbar';
import WeekView from './components/week';
import PlanningNotInitialized from './components/planningNotInitialized';
import Loader from '../../../../atomic/components/atoms/loader/Loader';
import DayWrapper from './components/dayWrapper';
import { GARAGESTATE } from '../../../../utils/constants';
import AccountDisabledPage from '../../../account-disabled-page';
import AuthenticationService, { Roles } from '../../../../services/authentication-service';

// STYLES:
const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    height: '100%',
    width: '100%',
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'start',
  },
  page: {
    position: 'relative',
  },
}));

const PlanningFull = ({
  pathDayModif,
  pathUpdate,
}: {
  pathDayModif: string;
  pathUpdate: string;
}) => {
  // CONSTANTS:
  const {
    planningStore: { weekView, currentDay, isPlanningLoading },
    calendarStore: { isInitialized },
  } = useSelector((state: Store) => state);
  const classes = useStyles();

  const isAdmin = AuthenticationService.hasRole([Roles.ADMIN, Roles.CALL_CENTER]);

  return (
    <div className={classes.root}>
      {!GARAGESTATE() && !isInitialized && <PlanningNotInitialized />}
      {GARAGESTATE() && !isAdmin && <AccountDisabledPage />}
      <WeekNavigation pathUpdate={pathUpdate} />
      {weekView ? (
        <WeekView pathDayModif={pathDayModif} />
      ) : (
        <DayWrapper currentDay={currentDay} />
      )}
      <Loader isLoading={isPlanningLoading} />
    </div>
  );
};

export default React.memo(PlanningFull);

import React, { useCallback, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Button from '../../../../assets/generic-components/Button';
import InputLabel from '../../../../assets/generic-components/InputLabel';
import Title from '../../../../assets/generic-components/Title';
import { Store } from '../../../../redux/types';
import Header from '../header';
import useStyles from './style';
import {
  setNotification,
  uploadRib,
} from '../../../../redux/stores/global/actions';
import { SeverityMessage } from '../../../../redux/stores/global/types';

export const Box = styled.div`
  padding-top: 50px;
`;

enum FileType {
  jpeg = 'image/jpeg',
  png = 'image/png',
  pdf = 'application/pdf',
}

const Bank: React.FC<{ className: string }> = () => {
  const {
    languageReducer: { language: lg },
    globalReducer: { currentGarage },
  } = useSelector((state: Store) => state);

  if (!currentGarage) return null;

  const [showInformations, setShowInformation] = useState(false);

  const classes = useStyles();

  const input = useRef<HTMLInputElement>(null);

  const [fileName, setFileName] = useState('');

  const [selectedFile, setSelectedFile] = useState<File>();

  const [errorSizeMsg, setErrorSizeMsg] = useState('');

  const [errorTypeMsg, setErrorTypeMsg] = useState('');

  const dispatch = useDispatch();

  const handleSizeError = useCallback(() => {
    const MAX_FILE_SIZE = 4;

    const fileSizeMb =
      input.current?.files?.[0] &&
      Math.round(input.current.files[0].size / (1024 * 1024));

    if (fileSizeMb && fileSizeMb > MAX_FILE_SIZE) {
      setErrorSizeMsg(lg.account.bankInfo.messageSizeError);
      return;
    }
    setErrorSizeMsg('');
  }, [input, setErrorSizeMsg]);

  const handleTypeError = useCallback(() => {
    if (
      (input.current?.files?.[0] &&
        input.current.files[0].type === FileType.pdf) ||
      (input.current?.files?.[0] &&
        input.current.files?.[0].type === FileType.jpeg) ||
      (input.current?.files?.[0] &&
        input.current.files[0].type === FileType.png)
    ) {
      setErrorTypeMsg('');
      return;
    }

    setErrorTypeMsg(lg.account.bankInfo.messageWrongFileError);
  }, [input, setErrorTypeMsg]);

  const handleFileChange = useCallback(() => {
    if (input.current?.files?.[0]) {
      setFileName(input.current.files[0].name);
      setSelectedFile(input.current.files[0]);
    }

    handleSizeError();
    handleTypeError();
  }, [input, handleSizeError, handleTypeError]);

  const handleUploadRib = async () => {
    const formdata = new FormData();
    if (selectedFile) {
      formdata.append('rib', selectedFile);
    }
    formdata.append('garageId', currentGarage.id);

    uploadRib(
      formdata,
      () => {
        dispatch(
          setNotification({
            severity: SeverityMessage.SUCCESS,
            message: lg.general.success,
          })
        );
      },
      () =>
        dispatch(
          setNotification({
            severity: SeverityMessage.ERROR,
            message: lg.general.fatalError,
          })
        )
    );
  };

  const onSubmit = () => {
    setShowInformation(false);
    handleUploadRib();
  };

  return (
    <>
      <Helmet>
        <title>Mon garage | Informations bancaires</title>
      </Helmet>
      <Header show={showInformations} />
      <div className={`${classes.root} custome-latbel`}>
        <Box>
          <Title title={lg.rates.bankInformation} />
          <div className={classes.inputFile}>
            <InputLabel
              reference={input}
              width="21%"
              label={lg.rates.addRib}
              type="file"
              onChange={handleFileChange}
            />
          </div>
          <p>{fileName}</p>
          <p className={classes.errorLabel}>{errorSizeMsg}</p>
          <p className={classes.errorLabel}>{errorTypeMsg}</p>
        </Box>
        <p className={classes.ribInfo}>{lg.rates.change}</p>
        <Button
          width="274px"
          onClick={onSubmit}
          theme="dark"
          title={lg.planning.modification.saveButton}
        />
      </div>
    </>
  );
};

export default Bank;

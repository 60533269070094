import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import _get from 'lodash/get';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import colors from '../../../assets/style-colors';
import Button from '../../../assets/generic-components/Button';
import { Store } from '../../../redux/types';
import { Question } from '../../../redux/stores/help/types';

interface HelpViewProps {
  handleRedirect: (to: string, selectedId: any) => void;
  setActivePath: (path: string) => void;
}

const HelpView: React.FC<HelpViewProps> = ({
  handleRedirect,
  setActivePath,
}) => {
  const store = useSelector((state: Store) => state);
  const lg = store.languageReducer.language;
  const { helpCategories } = store.helpStore;

  const useStyles = makeStyles(() => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      color: 'darkgrey',
      overflow: 'hidden',
    },
    content: {
      display: 'flex',
      flex: '100%',
      flexDirection: 'column',
      margin: '25px 19px',
    },
    title: {
      fontSize: '20px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: colors.dark,
    },
    horizontal: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '20px',
      flexWrap: 'wrap',
    },
    left: {
      display: 'flex',
      flex: '45%',
      marginLeft: '19px',
      marginRight: '19px',
      marginBottom: '20px',
      background: colors.white,
      borderRadius: '2px',
      boxShadow: '0 2px 18px 0 rgba(136, 135, 135, 0.17)',
    },
    middle: {
      display: 'flex',
      flex: '45%',
      marginRight: '19px',
      background: colors.white,
      borderRadius: '2px',
      boxShadow: '0 2px 18px 0 rgba(136, 135, 135, 0.17)',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      padding: '20px 25px',
    },
    coupon: {
      fontSize: '16px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.38',
      letterSpacing: 'normal',
      color: colors.dark,
      marginBottom: '10px',
    },
    question: {
      fontSize: '14px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.43',
      letterSpacing: 'normal',
      transform: 'underline',
      margin: '7px 0px',
      color: colors.dark,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    button: {
      width: '250px !important',
      height: '40px !important',
      marginTop: '20px',
    },
    cuzSpace: {
      whiteSpace: 'nowrap',
    },
    middleCuz: {
      marginLeft: '19px',
    },
  }));

  const classes = useStyles();

  const getFeaturedQuestions = (questions: Question[]): Question[] =>
    questions.filter((question) => question.featured);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <span className={classes.title}>{lg.help.helpHome.title}</span>
      </div>
      <div className={classes.horizontal}>
        {(helpCategories || []).map((category) => (
          <div className={classes.left}>
            <div className={classes.column}>
              <span className={classes.coupon}>{category.categoryLabel}</span>
              {getFeaturedQuestions(_get(category, 'faq.question', []))
                .slice(0, 5)
                .map((faqItem: Question) => (
                  <span
                    className={classes.question}
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      setActivePath(category.path);
                      handleRedirect(category.path, faqItem.id);
                    }}
                  >
                    <ReactMarkdown>{faqItem.question}</ReactMarkdown>
                  </span>
                ))}
              {getFeaturedQuestions(_get(category, 'faq.question', [])).length >
                5 && (
                <span className={classes.button}>
                  <Button
                    theme="dark"
                    title={lg.help.helpHome.questions}
                    onClick={() => {
                      setActivePath(category.path);
                      handleRedirect(category.path, '');
                    }}
                  />
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HelpView;

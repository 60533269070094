import React, { useEffect, useState } from 'react';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import _omit from 'lodash/omit';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '../../../../assets/generic-components/Button';
import Title from '../../../../assets/generic-components/Title';
import ProgressBtnLoader from '../../../../atomic/components/atoms/ProgressBtnLoader';
import CarMaintenance from '../../../../atomic/components/organisms/CarMaintenance/CarMaintenance';
import ImmatriculationInput from '../../../../atomic/components/organisms/ImmatriculationInput';
import VinInput from '../../../../atomic/components/organisms/VinInput/vinInput';
import { makeQuoteEvent } from '../../../../lib/events/devis5s';
import {
  setCurrentGarageServices,
  setNotification,
} from '../../../../redux/stores/global/actions';
import {
  CarService,
  CarServiceType,
  CountryCodesUppercase,
  MessageNotification,
  SeverityMessage,
} from '../../../../redux/stores/global/types';
import {
  getQuotes,
  setKmVehicle,
  setReleaseDate,
  setVehicle,
} from '../../../../redux/stores/quotes/actions';
import { Store } from '../../../../redux/types';
import { QUOTE_SUMMARY, TYRES_SELECTION } from '../../../../router/routes';
import { TYRES_SERVICE_ID } from '../../../../services/tyres';
import TyresSelection from '../../../tyres/components/selection';
import MakeModelFields from '../../makeModelFields';
import QuoteChoicesCarService from './quoteChoicesCarService';
import useStyles from './style';
import QuoteDropdown from './quoteDropdown';

const PRICELESS_BOOKING_SERVICE_ID = '500101';

const QuoteByAppointmentSelection = () => {
  const lg = useSelector((state: Store) => state.languageReducer.language);
  const {
    quotesReducer: { currentVehicle, kmVehicle },
    globalReducer: { currentGarage, availableCarservices, garageServices },
    languageReducer: { language },
  } = useSelector((state: Store) => state);

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [btnRegistrationActive, setBtnRegistrationActive] = useState(true);
  const [btnBrandActive, setBtnBrandActive] = useState(false);
  const [carservices, setCarServices] = useState<Record<string, CarService>>(
    {}
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [defaultReleaseDate, setDefaultReleaseDate] = useState<Date | null>(
    null
  );

  const isGarageBe =
    currentGarage?.garageAddress.country === CountryCodesUppercase.BE;

  useEffect(() => {
    dispatch(setVehicle(null));
    dispatch(setKmVehicle(null));
    dispatch(setReleaseDate(null));
  }, [btnBrandActive]);
  useEffect(() => {
    if (currentGarage?.id) {
      dispatch(setCurrentGarageServices(currentGarage.id));
    }

    if (currentVehicle?.releaseDate) {
      setDefaultReleaseDate(new Date(currentVehicle?.releaseDate));
      dispatch(setReleaseDate(new Date(currentVehicle?.releaseDate)));
    } else {
      setDefaultReleaseDate(null);
    }
  }, [dropdownOpen]);

  const handleOnRemoveCarService = (id: string) => {
    const carServiceRemoved = _omit(carservices, [id]);
    setCarServices(carServiceRemoved);
  };

  const handleOnDateChange = (date: Date | null) => {
    setDefaultReleaseDate(date);
    dispatch(setReleaseDate(date));
  };
  const getCarServicesForGarage = () => {
    const filteredGarageServices = Object.keys(availableCarservices).reduce(
      (acc, key) => {
        if (
          availableCarservices[key].typeDevis === 'ONLINE' &&
          (garageServices || []).find(
            (service) => service.serviceId === availableCarservices[key].id
          )
        ) {
          return { ...acc, [key]: availableCarservices[key] };
        }
        return acc;
      },
      {}
    );

    if (Object.keys(filteredGarageServices).length === 0) {
      return Object.keys(availableCarservices).reduce((acc, key) => {
        if (availableCarservices[key].id === PRICELESS_BOOKING_SERVICE_ID) {
          return { ...acc, [key]: availableCarservices[key] };
        }
        return acc;
      }, {});
    }
    return filteredGarageServices;
  };

  // Functions
  const selectCarservice = (carservice: CarService) => {
    if (Object.keys(carservices).includes(carservice.id)) {
      handleOnRemoveCarService(carservice.id);
    } else {
      setCarServices({
        ...carservices,
        [carservice.id]: carservice,
      });
    }
  };

  // Copying state to filter the search
  let carServicesCloned = Object.values(
    getCarServicesForGarage()
  ) as CarService[];
  carServicesCloned = searchValue
    ? carServicesCloned.filter((p) =>
        p.label.toLowerCase().includes(searchValue)
      )
    : carServicesCloned;

  const { handleSubmit, register, errors } = useForm({ mode: 'onChange' });

  const onSubmit = () => {
    setDropdownOpen(false);
  };
  const goback = () => history.goBack();

  const isTyresInSelectedCarService = () =>
    Object.keys(carservices).includes(TYRES_SERVICE_ID);

  const isCarMaintenanceSelected = () =>
    Object.keys(carservices).includes(CarServiceType.CAR_MAINTENANCE_SCHEDULE);

  const isValuesValid = (): boolean => {
    if (
      (currentVehicle !== null &&
        isCarMaintenanceSelected() &&
        kmVehicle !== null &&
        moment(defaultReleaseDate).isValid()) ||
      (Object.values(carservices).length >= 1 &&
        currentVehicle !== null &&
        !isCarMaintenanceSelected())
    ) {
      return true;
    }

    return false;
  };

  const handleOnClick = (e: any) => {
    e.preventDefault();
    if (isTyresInSelectedCarService()) {
      history.push(TYRES_SELECTION);
      return;
    }
    if (currentVehicle && currentGarage) {
      setLoading(true);
      makeQuoteEvent({
        vehicle: currentVehicle,
        garageId: currentGarage.id,
        garageName: currentGarage.companyName,
        services: Object.values(carservices).map(({ id, label }) => ({
          id,
          label,
        })),
      });
      dispatch(
        getQuotes(
          Object.values(carservices).map((s) => s.id),
          currentVehicle.id,
          currentGarage.id,
          () => {
            history.push(QUOTE_SUMMARY);
          },
          () => {
            const notification: MessageNotification = {
              severity: SeverityMessage.ERROR,
              message: language.error.server,
            };
            dispatch(setNotification(notification));
          },
          kmVehicle,
          defaultReleaseDate
        )
      );
    }
  };

  return (
    <div className={classes.container}>
      <Helmet>
        <title>Devis 5s | Création</title>
      </Helmet>
      {location.state === 'planning' && (
        <div
          onKeyPress={() => {}}
          role="button"
          tabIndex={0}
          onClick={goback}
          className={classes.boxTitle}
        >
          <div className={classes.backward}>
            <KeyboardArrowLeftIcon className={classes.backwardGrey} />
            <p>{lg.quote.back}</p>
          </div>
        </div>
      )}
      <Title
        title={
          location.state === 'planning' ? lg.quote.addQuote : lg.quote.create
        }
      />

      <div className={classes.box}>
        <p>{lg.quote.selectedCar}</p>
        <div className={classes.wrapBtn}>
          <Button
            active={!btnRegistrationActive}
            onClick={() => {
              setBtnRegistrationActive(true);
              setBtnBrandActive(false);
            }}
            theme="light"
            title={
              isGarageBe ? `${lg.quote.vinCode}` : `${lg.quote.registration}`
            }
            height="60px"
            width="147px"
            className={`tab ${btnRegistrationActive ? 'active' : ''}`}
          />
          <Button
            active={!btnBrandActive}
            onClick={() => {
              setBtnBrandActive(true);
              setBtnRegistrationActive(false);
            }}
            theme="light"
            title={lg.quote.model}
            height="60px"
            width="147px"
            className={`tab ${btnBrandActive ? 'active' : ''}`}
          />
        </div>
      </div>
      {btnBrandActive && <MakeModelFields setVehicle={setVehicle} />}
      {btnRegistrationActive && (
        <>
          {isGarageBe ? (
            <VinInput
              invalidRegistrationLabel={lg.quote.invalidRegistrationVin}
              label={lg.quote.vinCode}
              reference={register({})}
              errorLabel={
                errors.codeVin &&
                errors.codeVin.type === 'required' &&
                lg.quote.messageField
              }
              name="vinCode"
            />
          ) : (
            <ImmatriculationInput
              invalidRegistrationLabel={lg.quote.invalidRegistration}
              label={lg.quote.registrationLabel}
              reference={register({})}
              errorLabel={
                errors.immatriculation &&
                errors.immatriculation.type === 'required' &&
                lg.quote.messageField
              }
              name="immatriculation"
            />
          )}
        </>
      )}

      <div>
        <label htmlFor="Dropdown" className="label">
          {lg.quote.prestation}
        </label>
        <QuoteDropdown
          open={dropdownOpen}
          setOpen={() => setDropdownOpen(!dropdownOpen)}
          carServices={carservices}
          removeCarService={handleOnRemoveCarService}
          search={searchValue}
          setSearch={(e) => setSearchValue(e.target.value)}
          carServicesCloned={carServicesCloned}
          selectCarservice={selectCarservice}
          submit={handleSubmit(onSubmit)}
        />
        {!dropdownOpen && (
          <div className={classes.list}>
            {Object.values(carservices).map((c) => (
              <QuoteChoicesCarService
                carService={c}
                key={c.id}
                handleOnRemoveCarService={handleOnRemoveCarService}
              />
            ))}
          </div>
        )}
      </div>

      {isTyresInSelectedCarService() && <TyresSelection lang={lg} />}
      {isCarMaintenanceSelected() && (
        <CarMaintenance
          handleOnChangeKm={(e) => dispatch(setKmVehicle(e))}
          handleDateChange={(e) => handleOnDateChange(e)}
          selectedDate={defaultReleaseDate}
        />
      )}

      <div className={classes.nextBtn}>
        <Button
          disabled={!isValuesValid()}
          width="274px"
          theme="dark"
          title={loading ? lg.isLoading : lg.quote.next}
          onClick={handleOnClick}
        >
          {loading ? <ProgressBtnLoader /> : <></>}
        </Button>
      </div>
    </div>
  );
};

export default QuoteByAppointmentSelection;

import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import MailOutlineIcon from '../../assets/style-elements/enveloppe.svg';
import PowerSettingsNewIcon from '../../assets/style-elements/signout.svg';
import Language from './language';
import Search from './search';
import SignOut from './signOut';
import allActions from '../../redux/actions';
import colors from '../../assets/style-colors';
import * as ROUTES from '../../router/routes';

// REDUX:

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (userData) => allActions.userActions.setUser(dispatch, userData),
  logIn: (bool) => allActions.userActions.logIn(dispatch, bool),
});

//

const Header = () => {
  // CONSTANTS:

  const { languageReducer } = useSelector((state) => state);
  const [width, setWidth] = useState(window.innerWidth);
  const isLarge = width >= 1440;
  const modeTablet = width <= 1024;
  const lg = languageReducer.language;

  // FUNCTIONS:

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
  });

  // STYLES:
  const useStyles = makeStyles(() => ({
    root: {
      position: 'fixed',
      zIndex: 1,
      top: '0',
      left: isLarge ? '196px' : '55px',
      right: '0',
      display: 'flex',
      gap: '1rem',
      paddingInline: '1rem',
      flexDirection: 'row',
      background: colors.white,
      borderBottom: `1px solid ${colors.paleGrey}`,
      height: '70px',
      alignItems: 'center',
      justifyContent: 'space-around',
      fontSize: '12px',
      fontWeight: '500',
    },
    contact: {
      background: colors.veryPaleGrey,
      borderRadius: '10px',
      padding: '.5rem 1rem',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      cursor: 'pointer',
      height: '40px',
      '&:hover': {
        background: colors.veryLightBlue,
      },
    },
    link: {
      cursor: 'pointer',
      color: colors.dark,
      marginLeft: '10px',
      textAlign: 'center',
    },
    aide: {
      marginLeft: '10px',
      '& div': {
        color: colors.lightGreyBlue,
        marginLeft: '0',

        '&:hover': {
          color: colors.dark,
        },
      },
    },
    help: {
      cursor: 'pointer',
      borderRadius: '8px',
      width: '40px',
      height: '40px',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      background: colors.paleGrey,
      '&:hover': {
        background: colors.veryLightBlue,
      },
    },
    error: {
      background: colors.veryLightPink,
      borderRadius: '10px',
      padding: '5px 20px 5px 14px',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      cursor: 'pointer',
      height: '40px',
      transition: 'all .3s',
      '&:hover': {
        background: colors.veryLightBlue,
      },
    },
  }));

  const history = useHistory();
  const classes = useStyles();

  // FUNCTIONS:

  function handleRedirect(e, path) {
    e.preventDefault();
    history.push(path);
  }
  // HTML:

  return (
    <div className={`${classes.root} noprint`}>
      <Search
        className={classes.search}
        items={['1', '2', '13', '145', '157896', '4581', '12301']}
      />
      <div
        className={`${classes.contact}`}
        onKeyPress={() => {}}
        role="button"
        tabIndex={0}
        onClick={(e) => handleRedirect(e, ROUTES.CONTACT)}
      >
        <img src={MailOutlineIcon} alt=" " />

        {modeTablet ? null : (
          <span className={classes.link}>{lg.header.contact}</span>
        )}
      </div>
      <div className={classes.aide}>
        <div
          className={classes.link}
          onKeyPress={() => {}}
          role="button"
          tabIndex={0}
          onClick={(e) => handleRedirect(e, ROUTES.HELP)}
        >
          {lg.header.help}
        </div>
      </div>
      <Language />
      <SignOut>
        <div className={classes.help}>
          <img
            className={classes.iconSignOut}
            src={PowerSettingsNewIcon}
            alt="logout"
          />
        </div>
      </SignOut>
    </div>
  );
};

// EXPORT:

export default styled(connect(mapStateToProps, mapDispatchToProps)(Header))`
  .makeStyles-contact-22 {
    padding: 5px 20px 5px 14px !important;

    img {
      padding-right: 10px;
    }

    .makeStyles-link-23 {
      white-space: nowrap;
    }
  }

  .makeStyles-help-24 {
    .makeStyles-link-23 {
      color: #aaaec5 !important;
    }
  }

  .makeStyles-root-29 {
    .makeStyles-head-30 {
      color: #aaaec5 !important;
    }
  }
`;

import makeStyles from '@material-ui/core/styles/makeStyles';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '../../../../../assets/generic-components/Button';
import colors from '../../../../../assets/style-colors';
import close from '../../../../../assets/style-elements/01-electrons-icon-close.png';
import { displayBooking } from '../../../../../redux/stores/planning/actions';
import { Store } from '../../../../../redux/types';
import { getDuration, getLogo } from '../../../../../utils/booking';

interface ModalDevisProps {
  show: boolean;
}

const ModalDevis = ({ show }: ModalDevisProps) => {
  const {
    globalReducer: { availableCarservices },
    planningStore: { currentBooking },
    quotesReducer: { currentVehicle },
  } = useSelector((state: Store) => state);

  if (!currentBooking) {
    return null;
  }
  const fullName = `${currentBooking.customer.firstName} ${currentBooking.customer.lastName}`;
  const lg = useSelector((state: Store) => state.languageReducer.language);

  const dispatch = useDispatch();

  const history = useHistory();

  // STYLES:
  const useStyles = makeStyles(() => ({
    root: {
      position: 'fixed',
      width: '40vw',
      left: '30vw',
      top: '10vh',
      height: '60vh',
      backgroundColor: colors.white,
      opacity: '1',
      zIndex: 10000,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      fontSize: '15px',
      overflowX: 'hidden',
      overflow: 'scroll',
      borderRadius: '4px',
      '&::-webkit-scrollbar': {
        width: '4px',
        height: '4px',
        borderRadius: '2px',
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        width: '4px',
        height: '4px',
        margin: '2px',
        background: colors.lightGreyBlue,
      },
    },
    header: {
      padding: '30px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'start',
    },
    boxH: {
      display: 'flex',
      flexDirection: 'row',
    },
    imageBox: {
      width: '93px',
      height: '93px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: `1px solid ${colors.veryPaleGrey}`,
      borderRadius: '4px',
      marginRight: '24px',
    },
    imgBox: {
      width: '65px',
      height: '41px',
    },
    titlePop: {},
    namePop: {
      fontSize: '14px',
    },
    footer: {
      padding: '17px 20px',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      width: '100%',
      height: '80px',
      backgroundColor: colors.paleGrey,

      '& button': {
        marginRight: '0 !important',
      },
    },
    bodyBox: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'space-around',
      width: '100%',
      paddingBottom: '30px',
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'space-around',
      width: '100%',
      padding: '30px',
      borderTop: `1px solid ${colors.WhiteDark}`,
    },
    item: {
      color: colors.slateGrey,
      paddingTop: '10px',

      '& img': {
        display: 'inline-block',
      },

      '& div': {
        display: 'inline-block',
      },

      '& span': {
        color: colors.dark,
      },
    },
    devis: {
      color: colors.white,
      padding: '5px',
      width: 'fit-content',
      borderRadius: '4px',
      cursor: 'pointer',
    },
    CONFIRMED: {
      backgroundColor: colors.mediumGreen,
    },
    VALIDATED: {
      backgroundColor: colors.azure,
    },
    REPORTED: {
      backgroundColor: colors.pomeGranate,
    },
    CANCELLED: {
      backgroundColor: colors.dark,
    },
    WALLET: {
      backgroundColor: colors.dark,
    },
    perso: {
      backgroundColor: colors.lightGreyBlue,
    },
    buttonPopup: {
      display: 'block',
      position: 'fixed',
      width: '100vw',
      height: '100vh',
      backgroundColor: 'rgba(44, 46, 61, 0.15)',
      zIndex: 9999,
      top: 0,
      left: 0,
    },
    imgPopup: {
      width: '17px',
      height: '17px',
      cursor: 'pointer',
    },
    infoItemPopup: {
      paddingLeft: '10px',
    },
    datePopup: {
      fontSize: '14px',
      fontWeight: 500,
    },
  }));
  const classes = useStyles();

  // FUNCTIONS:
  function handleHide() {
    dispatch(displayBooking(false));
  }

  function handleRedirect(e: React.MouseEvent<HTMLElement>, path: string) {
    e.preventDefault();
    history.push(path);
  }

  // HTML:
  return (
    <div style={{ display: show ? 'block' : 'none' }}>
      <div
        className={classes.buttonPopup}
        onKeyPress={() => {}}
        role="button"
        aria-label="Hide"
        tabIndex={0}
        onClick={() => handleHide()}
      />
      <div className={classes.root}>
        <div className={classes.bodyBox}>
          <div className={classes.header}>
            <div className={classes.boxH}>
              <div className={classes.imageBox}>
                <img
                  className={classes.imgBox}
                  src={getLogo(currentBooking.networkId)}
                  alt=""
                />
              </div>
              <div>
                <div
                  className={classes.titlePop}
                >{`${lg.devis.command} ${currentBooking.bookingId}`}</div>
                <div className={classes.namePop}>{`${fullName} - ${
                  currentVehicle?.version.model.make.label || 'N/A'
                } ${currentVehicle?.label || ''}`}</div>

                <div className={classes.datePopup}>{`${moment(
                  currentBooking.bookingDate
                ).format('dddd, D MMMM')} ${
                  currentBooking.bookingDepositHour?.split('-')[0]
                }
                `}</div>
              </div>
            </div>
            <img
              className={classes.imgPopup}
              src={close}
              alt="X"
              onClick={() => handleHide()}
            />
          </div>

          {currentBooking.quotes.map((quote) => (
            <div key={`devis_${quote.quoteId}`} className={classes.body}>
              <div
                onKeyPress={() => {}}
                role="button"
                tabIndex={0}
                onClick={(e) => {
                  handleRedirect(e, '/command');
                  handleHide();
                }}
                className={`${
                  currentBooking.networkId === 'GARAGE-OWNER'
                    ? classes.perso
                    : classes[quote.status]
                } ${classes.devis}`}
              >
                {quote.quoteType === 'ONLINE'
                  ? `${lg.planning.quote} N° ${quote.quoteId}`
                  : lg.planning.withoutDevis}
              </div>
              <div className={classes.item}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                >
                  <path
                    fill={colors[quote.status]}
                    d="
M9 .25c4.832 0 8.75 3.918 8.75 8.75S13.832 17.75 9 17.75.25 13.832.25 9 4.168.25 9 .25zm0 1.5C4.996 1.75 1.75 4.996 1.75 9S4.996 16.25 9 16.25s7.25-3.246 7.25-7.25S13.004 1.75 9 1.75zm0 2.5c.38 0 .693.282.743.648L9.75 5v3.689l1.78 1.78c.267.267.29.684.073.977l-.073.084c-.266.267-.683.29-.976.073l-.084-.073-2-2c-.113-.112-.186-.257-.21-.412L8.25 9V5c0-.414.336-.75.75-.75z                              "
                  />
                </svg>
                <div className={classes.infoItemPopup}>{` ${getDuration(
                  quote
                )}${lg.general.shortHour}`}</div>
              </div>
              <div className={classes.item}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                >
                  <path
                    fill={colors[quote.status]}
                    d="
M9.988 0c.928 0 1.781.491 2.249 1.282l.088.162L14.452 5.7h2.41c.87 0 1.584.668 1.657 1.52l.006.143v3.8c0 .87-.668 1.583-1.52 1.656l-.142.006-.337.001.008-.027c-.302 1.11-1.316 1.926-2.521 1.926-1.206 0-2.22-.816-2.522-1.926l.008.027H7.026l.008-.027c-.302 1.11-1.316 1.926-2.521 1.926-1.206 0-2.22-.816-2.522-1.926l.008.027-.337-.001c-.87 0-1.583-.668-1.656-1.52L0 11.163v-3.8c0-.87.668-1.583 1.52-1.656l.143-.006h.435l1.306-3.914C3.74.776 4.654.076 5.706.006L5.882 0h4.106zM4.513 10.925c-.656 0-1.188.532-1.188 1.188 0 .655.532 1.187 1.188 1.187.655 0 1.187-.532 1.187-1.188 0-.655-.532-1.187-1.188-1.187zm9.5 0c-.656 0-1.188.532-1.188 1.188 0 .655.532 1.187 1.188 1.187.655 0 1.187-.532 1.187-1.188 0-.655-.532-1.187-1.188-1.187zm2.85-3.8h-15.2c-.11 0-.202.074-.23.174l-.008.063v3.8c0 .11.074.202.174.23l.064.008h.335l-.006.022C2.295 10.314 3.31 9.5 4.512 9.5c1.204 0 2.218.814 2.52 1.922l-.005-.022h4.471l-.006.022c.303-1.108 1.317-1.922 2.52-1.922 1.204 0 2.218.814 2.52 1.922l-.005-.022h.335c.11 0 .202-.074.23-.174l.008-.064v-3.8c0-.109-.074-.2-.174-.229l-.063-.008zM7.4 1.425H5.882c-.469 0-.889.275-1.08.694l-.047.118L3.6 5.7h2.731L7.4 1.425zm2.588 0h-1.12L7.799 5.7h5.06L11.05 2.081c-.18-.362-.532-.604-.928-.648l-.134-.008z "
                  />
                </svg>
                <div className={classes.infoItemPopup}>{` ${
                  availableCarservices[quote.carServiceId].label
                }`}</div>
              </div>
              <div className={classes.item}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                >
                  <path
                    fill={colors[quote.status]}
                    d="
M11.664.5c1.13 0 2.231.221 3.254.645.372.154.548.58.394.952-.154.372-.58.548-.952.394-.847-.35-1.758-.534-2.696-.534-2.944 0-5.466 1.806-6.518 4.371h7.49c.402 0 .728.327.728.73 0 .368-.274.673-.63.721l-.098.007h-7.91c-.069.394-.105.8-.105 1.214 0 .414.036.82.105 1.214h7.91c.402 0 .728.326.728.729 0 .369-.274.674-.63.722l-.098.006h-7.49c1.052 2.566 3.574 4.372 6.518 4.372.938 0 1.85-.183 2.696-.534.372-.154.798.022.952.394.154.371-.022.798-.394.952-1.023.424-2.123.645-3.254.645-3.76 0-6.951-2.443-8.071-5.828H.979c-.403 0-.729-.327-.729-.73 0-.368.274-.673.63-.721l.099-.007H3.25c-.056-.396-.086-.802-.086-1.214 0-.412.03-.818.086-1.214H.98c-.403 0-.729-.326-.729-.729 0-.369.274-.674.63-.722l.099-.006h2.614C4.713 2.942 7.903.5 11.664.5z "
                  />
                </svg>{' '}
                <div className={classes.infoItemPopup}>
                  {lg.devis.sum}{' '}
                  <span>{`${
                    quote.price.minPriceExclTax?.toFixed(2) || ''
                  } €`}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className={classes.footer}>
          <Button
            onClick={(e) => {
              dispatch(displayBooking(false));
              handleRedirect(e, '/command');
            }}
            theme="dark"
            variant="secondary"
            title={lg.devis.button}
          />
        </div>
      </div>
    </div>
  );
};

export default ModalDevis;

import React from 'react';

const OneViewHeaderTitle: React.FC<{ className: string }> = ({
  className,
  children
}) => (
  <div className={className}>
    <b>{children}</b>
  </div>
);

export default OneViewHeaderTitle;

import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import InputLabel from '../../assets/generic-components/InputLabel';
import InputMutipleSelect from '../../assets/generic-components/InputMutipleSelect';
import { Store } from '../../redux/types';

const civilite = [
  { label: 'Mr', value: 'm' },
  { label: 'Mme', value: 'ms' },
];

type RenderNameProps = {
  name: string;
  errors: Record<string, any>;
  label: string;
  register?: any;
  onChange?: any;
};

const RenderName: React.FC<RenderNameProps> = ({
  name,
  errors,
  register,
  label,
  onChange,
}) => (
  <InputLabel
    name={name}
    label={label}
    reference={register({
      required: true,
      minLength: 3,
    })}
    onChange={onChange}
  >
    {errors[name] && errors[name].type === 'required' && 'Ce champ est requis'}
  </InputLabel>
);

RenderName.defaultProps = {
  register: () => {},
  onChange: () => {},
};

const MainInformation = () => {
  const {
    languageReducer: { language: lg },
    globalReducer: { currentGarage },
  } = useSelector((state: Store) => state);

  if (!currentGarage) return null;

  const { register, errors } = useForm({
    defaultValues: {
      companyName: currentGarage.companyName,
      firstname: currentGarage.firstname,
      lastname: currentGarage.lastname,
    },
  });

  return (
    <div>
      <RenderName
        name="companyName"
        errors={errors}
        register={register}
        label={lg.account.garage}
      />
      <InputMutipleSelect
        name="gender"
        datas={civilite}
        width="440px"
        label={lg.account.civility}
        reference={register({
          required: true,
        })}
      />
      <RenderName
        name="firstname"
        errors={errors}
        register={register}
        label={lg.account.lastname}
      />
      <RenderName
        name="lastname"
        errors={errors}
        register={register}
        label={lg.account.firstname}
      />
    </div>
  );
};

export default MainInformation;

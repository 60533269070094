import googleTagManager from '@analytics/google-tag-manager';
import segmentPlugin from '@analytics/segment';
import Analytics from 'analytics';

const analytics = Analytics({
  app: 'myidgarages-web',
  plugins: [
    googleTagManager({
      containerId: process.env.REACT_APP_GTM_ID
    }),
    segmentPlugin({
      writeKey: process.env.REACT_APP_SEGMENT_KEY
    })
  ]
});

export default analytics;

import { Moment } from 'moment';
import { Garage } from '../../redux/stores/global/types';
import { Customer } from '../../redux/stores/quotes/types';
import { Booking, Quote, QuoteStatus } from '../../redux/stores/planning/types';
import getVatForGarage from '../quote/vat';

export interface BookingPayload {
  currentGarage: Garage;
  customer: Customer;
  registrationNumber?: string | null;
  vehicleId?: string;
  bookingDate: Moment;
  hour?: string;
  quotes: Quote[];
}

export const getNewBookingPayload = ({
  customer,
  registrationNumber,
  bookingDate,
  hour,
  quotes,
  currentGarage,
  vehicleId,
}: BookingPayload): Booking => {
  const vehicle = {
    vehicleId: vehicleId || '',
  };

  const type = quotes[0].quoteType;

  const getVehicleByRegistrationNumber = registrationNumber
    ? { ...vehicle, registrationNumber }
    : vehicle;

  return {
    garageId: currentGarage.id,
    networkId: 'GARAGE-OWNER',
    customer: {
      firstName: customer.firstName,
      lastName: customer.lastName,
      email: customer.email,
      phone: customer.phone,
    },
    vehicle: type === 'PRICELESS' ? undefined : getVehicleByRegistrationNumber,
    bookingDate: bookingDate.format('YYYY-MM-DDTHH:mm:ssZ'),
    bookingDepositHour: hour,
    quotes: quotes.map((quote) => ({
      ...quote,
      status: QuoteStatus.CONFIRMED,
    })),
    paymentStatus: 'ACCEPTED',
    paidByCreditCard: 0,
    paidByWallet: 0,
    price: {
      commission: 0,
      currency: 'EUR',
      garageCommission: 0,
      vat: getVatForGarage(currentGarage.garageAddress),
      totalMaxWithTax: 0,
    },
    comment: customer.comments,
  };
};

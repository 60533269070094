import { getServices } from '../stores/global/actions';

const languageActions = {};

languageActions.setLanguage = (dispatch, string) => {
  dispatch({
    type: 'SET_LANGUAGE',
    payload: string
  });
  dispatch(getServices());
};

languageActions.setLangAccount = (dispath, string) => {
  dispath({
    type: 'SET_LANGUAGE_ACCOUNT',
    payload: string
  });
};

languageActions.setImage = (dispath, image) => {
  dispath({
    type: 'SET_IMAGE',
    payload: image
  });
};

export default languageActions;

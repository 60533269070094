import makeStyles from '@material-ui/core/styles/makeStyles';
import colors from '../../../../assets/style-colors';

const useStyles = makeStyles(() => ({
  inputsContainer: {
    width: '60%',
    margin: '0 10vw',
    paddingTop: '60px',
    display: 'flex',
    flexDirection: 'column',

    '& a': {
      color: colors.slateGrey,
      textDecoration: 'underline',
      fontSize: '13px',
    },

    ' & a:hover': {
      textDecoration: 'none',
    },

    '& .pt-5': {
      paddingTop: '5px !important',
    },

    '& .pt-25': {
      paddingTop: '25px !important',
    },

    '& .pt-40': {
      paddingTop: '40px !important',
    },

    '& .mt-30': {
      marginTop: '30px',
    },

    '& .mb-100': {
      marginBottom: '100px',
    },
  },

  customCheckbox: {
    '& label': {
      '& span': {
        padding: '0 9px',
        fontWeight: 500,
        '& span': {
          padding: 0,
        },
      },
    },
  },

  check: {
    display: 'flex',
    alignItems: 'center',

    '& p': {
      marginLeft: '-10px',
    },

    '& label': {
      marginRight: '5px !important',
    },
  },

  customLabel: {
    '& > div': {
      padding: '10px 0 !important',
    },
  },
}));

export default useStyles;

import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../assets/generic-components/Button';
import close from '../../../assets/style-elements/01-electrons-icon-close.png';
import { Store } from '../../../redux/types';
import colors from '../../../assets/style-colors';
import {
  sendSms,
  setSmsIsSending,
} from '../../../redux/stores/planning/actions';
import { setNotification } from '../../../redux/stores/global/actions';
import { SeverityMessage } from '../../../redux/stores/global/types';
import { vehicleReadyEvent } from '../../../lib/events/booking';

const NotificationBySms: React.FC = ({ children }) => {
  const [isSendingSMS, setIsSendingSMS] = useState(false);

  const useStyles = makeStyles(() => ({
    content: {
      width: '530px',
      margin: '0px auto',
    },
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
    },
    modal_header: {
      padding: '30px 30px 0px 30px',
      border: 'none',
    },
    head: {
      fontWeight: 'bold',
      color: colors.dark,
    },
    title: {
      display: 'flex',
      flex: '100%',
      fontSize: '12px',
      color: colors.slateGrey,

      fontWeight: 500,
    },
    fields: {
      fontSize: '12px',
      margin: '21px 0 0',
    },

    imgPopup: {
      width: '17px',
      height: '17px',
      cursor: 'pointer',
    },
  }));

  const classes = useStyles();
  const {
    planningStore: { currentBooking, planingHasSendedSMS },
    languageReducer: { language: lg },
    calendarStore: { calendar },
    globalReducer: { currentGarage },
  } = useSelector((state: Store) => state);

  if (!currentGarage) return null;

  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  const handleClose = () => {
    setIsSendingSMS(false);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const day = new Date();

  const handleClick = (): void => {
    if (
      currentBooking?.bookingId &&
      !planingHasSendedSMS.includes(currentBooking.bookingId)
    ) {
      dispatch(
        sendSms(
          {
            booking_id: currentBooking?.bookingId,
            opening_hours: calendar
              ? calendar?.regularWeek[day.getDay()].openingHours
              : [],
          },
          () => {
            setIsSendingSMS(true);
            vehicleReadyEvent({
              garageId: currentGarage.id,
              garageName: currentGarage.companyName,
              booking_id: currentBooking.bookingId || 'unknown',
            });
            dispatch(setSmsIsSending(currentBooking?.bookingId || ''));
            dispatch(
              setNotification({
                severity: SeverityMessage.SUCCESS,
                message: lg.general.success,
              })
            );
          },
          () =>
            dispatch(
              setNotification({
                severity: SeverityMessage.ERROR,
                message: lg.command.lateral.phoneNotValide,
              })
            )
        )
      );
    }
  };
  const smsSended = planingHasSendedSMS.includes(
    currentBooking?.bookingId || ''
  );
  const message = isSendingSMS
    ? lg.command.lateral.validationSms
    : !smsSended
    ? lg.command.lateral.bodyModalSms
    : lg.command.lateral.smsAlreadySend;

  return (
    <>
      <div role="button" tabIndex={0} onClick={handleShow}>
        {children}
      </div>
      <Modal
        dialogClassName={classes.content}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className={classes.modal_header}>
          <div className={classes.root}>
            <span className={classes.head}>
              {lg.command.lateral.titleModalSms}
            </span>
            <img
              className={classes.imgPopup}
              src={close}
              alt="close"
              onClick={() => handleClose()}
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          <p className={classes.fields}>{message}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            theme="light"
            title={lg.command.appointment.close}
            onClick={() => handleClose()}
          />
          {!smsSended && (
            <Button
              theme="dark"
              title={lg.command.lateral.sendSms}
              onClick={() => handleClick()}
            />
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NotificationBySms;

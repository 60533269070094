import React from 'react';
import { useSelector } from 'react-redux';
import { NestDataObject, FieldError } from 'react-hook-form';
import InputPassword from '../../assets/generic-components/InputPassword';
import { Store } from '../../redux/types';
import InputLabel from '../../assets/generic-components/InputLabel';

export type AutossimoFormValues = {
  login: string | undefined;
  password: string | undefined;
};

interface AutossimoFormFieldsProps {
  className?: string;
  passwordValue?: string;
  loginChange?: (e: {
    target: { value: React.SetStateAction<string | undefined> };
  }) => void;
  passwordChange?: (e: {
    target: { value: React.SetStateAction<string | undefined> };
  }) => void;
}

export interface AutossimoServicesProps {
  errors: NestDataObject<AutossimoFormValues, FieldError>;
  loginRef: React.LegacyRef<HTMLInputElement>;
  passwordRef: React.LegacyRef<HTMLInputElement>;
}

const AutossimoFormFields = ({
  errors,
  loginRef,
  passwordRef,
  className,
  passwordValue,
  loginChange,
  passwordChange,
}: AutossimoServicesProps & AutossimoFormFieldsProps) => {
  const {
    globalReducer: { currentGarage },
    languageReducer: { language: lg },
  } = useSelector((state: Store) => state);

  return (
    <>
      <p>{lg.account.autossimo}</p>
      <div className={className}>
        <InputLabel
          label={lg.account.autossimoLogin}
          name="login"
          type="text"
          defaultValue={currentGarage?.autossimo?.login}
          reference={loginRef}
          onChange={loginChange}
          theme="dark"
        >
          {errors.login &&
            errors.login.type === 'required' &&
            lg.quote.messageField}
        </InputLabel>
        <InputPassword
          name="password"
          label={lg.account.autossimoPassword}
          theme="dark"
          reference={passwordRef}
          value={passwordValue}
          onChange={passwordChange}
        />
      </div>
    </>
  );
};

AutossimoFormFields.defaultProps = {
  className: '',
  passwordValue: '',
  loginChange: () => {},
  passwordChange: () => {},
};

export default AutossimoFormFields;

import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import { useSelector } from 'react-redux';
import { Store } from '../../../../redux/types';
import DayView from '../planning-full-page/components/day';
import WeekNavigation from '../planning-full-page/components/navbar';
import WeekView from '../planning-full-page/components/week';
import UpdateWeekNavbar from './navbar';

// STYLES:
const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    height: '100%',
    width: '100%'
  }
}));

interface UpdatePlanningProps {
  pathUpdate: string;
  pathHolidays: string;
  pathUpdateDay: string;
}

const UpdatePlanning: React.FC<UpdatePlanningProps> = ({
  pathUpdate,
  pathHolidays,
  pathUpdateDay
}) => {
  const {
    calendarReducer,
    planningStore: { updatePlanningMode, currentDay }
  } = useSelector<Store, Store>(state => state);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {updatePlanningMode ? (
        <UpdateWeekNavbar pathConges={pathHolidays} />
      ) : (
        <WeekNavigation pathUpdate={pathUpdate} />
      )}
      {calendarReducer.activeWeek ? (
        <WeekView pathDayModif={pathUpdateDay} />
      ) : (
        <DayView currentDay={currentDay} />
      )}
    </div>
  );
};

export default UpdatePlanning;

import { useSelector } from 'react-redux';
import { Store } from '../redux/types';
import logoIdg from '../assets/images/logo2mini.svg';

export const GARAGESTATE = () => {
  const {
    globalReducer: { currentGarage }
  } = useSelector((state: Store) => state);
  const state = currentGarage?.state === 'INACTIVE';

  return state;
};

export default GARAGESTATE;

export const getCurrentGarageLogo = () => {
  const {
    globalReducer: { currentGarage }
  } = useSelector((state: Store) => state);

  const logoBrand = `${process.env.REACT_APP_GOOGLE_STORAGE}/Medias/brands_logos/${currentGarage?.garageBrand.slugBrand}.png` ;

  const logo = currentGarage?.garageBrand.slugBrand === 'sans-enseigne' 
    ? logoIdg 
    : logoBrand;

  return logo;
};

const ua = navigator.userAgent;

export const isMobile = /iPhone|Android/i.test(ua);

export const isIos = /iPhone/i.test(ua);

export const isAndroid = /Android/i.test(ua);

export const MobileViewProps = {
  android: {
    deviceLink: 'https://play.google.com/store/apps/details?id=pro.idgarages.release'
  },
  ios: {
    deviceLink: 'https://apps.apple.com/fr/app/idgarages-pro/id1640479232'
  }
};

import React, { CSSProperties, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNotification } from '../../redux/stores/global/actions';
import { Store } from '../../redux/types';
import { SeverityMessage } from '../../redux/stores/global/types';

interface IframeProps {
  src: string;
  style: CSSProperties;
  title: string;
}

const Iframe: React.FC<IframeProps> = ({ src, style, title }) => {
  const dispatch = useDispatch();
  const lg = useSelector((state: Store) => state.languageReducer.language);

  useEffect(() => {
    // fix notification closing when changing route
    setTimeout(() => {
      dispatch(
        setNotification({
          severity: SeverityMessage.INFO,
          message: lg.isLoading,
        })
      );
    }, 500);
  }, [title]);

  return <iframe title={title} src={src} style={style} />;
};

export default Iframe;

import React from 'react';
import { useSelector } from 'react-redux';
import { Store } from '../../../../../redux/types';
import { Booking } from '../../../../../redux/stores/planning/types';
import useStyles from '../style';
import diamantIcon from '../../../../../assets/style-elements/01-electrons-icon-diamant-gay@3x.png';
import { CarService } from '../../../../../redux/stores/global/types';
import Suppliers from '../../suppliers';

interface PremiumProps {
  currentBooking: Booking;
  availableCarservices: Record<string, CarService>;
}

const Premium = ({ currentBooking, availableCarservices }: PremiumProps) => {
  const lg = useSelector((state: Store) => state.languageReducer.language);

  const classes = useStyles();

  return (
    <div className={classes.donateItemWrapper}>
      <img
        className="leftIcon diamond"
        src={diamantIcon}
        alt="recommended icon"
      />
      <div className="donateList">
        <div className="donateItem">
          <p className="first diamondText">
            {lg.command.lateral.recommended} :
          </p>
        </div>
        <div className={`donateItem ${classes.suppliers}`}>
          <Suppliers
            booking={currentBooking}
            availableCarServices={availableCarservices}
            lg={lg}
          />
        </div>
      </div>
    </div>
  );
};

export default Premium;

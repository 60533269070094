import createAnalyticsEmitter from '.';
import { Vehicle } from '../../redux/stores/quotes/types';

interface BaseQuoteEventProps {
  garageId: string;
  garageName: string;
  vehicle: Vehicle | null;
}

interface MakeQuoteEventProps extends BaseQuoteEventProps {
  services: {
    id: string;
    label: string;
  }[];
}

interface QuoteToPDFEventProps extends BaseQuoteEventProps {
  services: {
    id: string;
    label: string;
  }[];
}

interface AddPersonalBookingEventProps extends BaseQuoteEventProps {
  bookingId: string;
  bookingDate: string;
  services: {
    id: string;
    label: string;
  }[];
  price: number;
}

export const makeQuoteEvent = createAnalyticsEmitter<MakeQuoteEventProps>(
  'quote5s_creation'
);
export const quoteToPDFEvent = createAnalyticsEmitter<QuoteToPDFEventProps>(
  'quote5s_print'
);
export const addPersonalBookingEvent = createAnalyticsEmitter<
  AddPersonalBookingEventProps
>('addPersonalBooking');

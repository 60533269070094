import React from 'react';
import { useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Store } from '../../../../redux/types';
import colors from '../../../../assets/style-colors';

type CellProps = {
  /**
   *  Title of the component
   */
  title: string;
  /**
   *  The price
   */
  price: number;
  /**
   *  Whether or not the component is displayed.
   */
  displayIf?: boolean;
};

const useStyles = makeStyles(() => ({
  wrapperboxPrice: {
    '&:last-child': {
      '.borderCell': {
        display: 'none',
      },
    },

    '&:nth-last-child(2):': {
      '& .borderCell': {
        display: 'none',
      },

      '.boxPrice p': {
        fontSize: '14px',
        color: `${colors.dark}`,
      },
    },
  },

  boxPrice: {
    margin: '15px 20px',
    fontSize: '13px',
    display: 'flex',
    justifyContent: 'space-between',

    '& p:first-child': {
      maxWidth: '230px',
      marginBottom: 0,
      color: `${colors.dark}`,
    },

    '& p:last-child': {
      color: `${colors.slateGrey}`,
      marginBottom: 0,
    },
  },

  borderCell: {
    marginRight: '15px',
    marginLeft: '15px',
    backgroundColor: `${colors.paleGreyDrank}`,
    height: '1px',
  },
}));

const Cell = ({ title, price, displayIf }: CellProps) => {
  const classes = useStyles();

  const lg = useSelector((state: Store) => state.languageReducer.language);

  if (!displayIf) {
    return null;
  }

  const formatPrice = (pr: number) =>
    new Intl.NumberFormat(lg.locale, {
      style: 'currency',
      currency: 'EUR',
    }).format(pr);

  return (
    <div className={classes.wrapperboxPrice}>
      <div className={classes.boxPrice}>
        <p>{title}</p>
        <p>{formatPrice(price)}</p>
      </div>
      <div className={classes.borderCell} />
    </div>
  );
};

Cell.defaultProps = {
  displayIf: true,
};

export default Cell;

import axios, { AxiosResponse } from 'axios';
// import AuthenticationService from './authentication-service';

const HttpMethods = {
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE'
};

export interface PaginationDetails {
  count: string;
  perPage: string;
  offset: string;
}

const client = axios.create();

const configure = () => {
  client.interceptors.request.use(
    config =>
      // if (AuthenticationService.isLoggedIn()) {
      //   const cb = () => {
      //     config.headers.Authorization = `Bearer ${AuthenticationService.getToken()}`;
      //     return Promise.resolve(config);
      //   };
      //   AuthenticationService.updateToken(cb);
      // }
      config
  );
};

const getClient = () => client;

const getPaginationDetailsQuery = (
  response: AxiosResponse
): PaginationDetails | null => {
  if (!response.headers['content-range']) {
    return null;
  }
  const contentRangeParts = response.headers['content-range']
    .replace(/[^.\d]/g, ' ')
    .split(' ');
  return {
    count: contentRangeParts[2],
    perPage: contentRangeParts[1],
    offset: contentRangeParts[0]
  };
};

const HttpService = {
  HttpMethods,
  configure,
  getClient,
  getPaginationDetailsQuery
};

export default HttpService;

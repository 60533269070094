import {
  ActionTypes,
  RESET_STORE,
  SET_CUSTOMER,
  SET_QUOTES,
  SET_REGISTRATION_NUMBER,
  SET_VEHICLE,
  SET_MAKES_LIST,
  SET_TYRES_OPTIONS,
  SET_SELECTED_CAR_SERVICES,
  ADD_QUOTE,
  SET_KM_VEHICLE,
  SET_RELEASE_DATE
} from './actions';
import { QuoteStore } from './types';

const initialState: QuoteStore = {
  quotes: null,
  currentVehicle: null,
  customer: null,
  currentRegistrationNumber: null,
  makesList: [],
  tyres: {
    catalog: [],
    options: {}
  },
  selectedCarServices: {},
  kmVehicle: null,
  releaseDate: null
};

const quotesReducer = (
  state: QuoteStore = initialState,
  action: ActionTypes
) => {
  switch (action.type) {
    case RESET_STORE:
      return initialState;
    case SET_QUOTES:
      return { ...state, quotes: action.payload };
    case ADD_QUOTE:
      return { ...state, quotes: [...(state.quotes || []), action.payload] };
    case SET_VEHICLE:
      return { ...state, currentVehicle: action.payload };
    case SET_KM_VEHICLE:
      return { ...state, kmVehicle: action.payload };
    case SET_RELEASE_DATE:
      return { ...state, releaseDate: action.payload };
    case SET_CUSTOMER:
      return { ...state, customer: action.payload };
    case SET_REGISTRATION_NUMBER:
      return { ...state, currentRegistrationNumber: action.payload };
    case SET_MAKES_LIST:
      return { ...state, makesList: action.payload };
    case SET_TYRES_OPTIONS:
      return { ...state, tyres: { ...state.tyres, options: action.payload } };
    case SET_SELECTED_CAR_SERVICES:
      return { ...state, selectedCarServices: action.payload };
    default:
      return state;
  }
};

export default quotesReducer;

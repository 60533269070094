import { CarServiceOperations } from '../../redux/stores/global/types';
import { Language } from '../../redux/stores/languages';
import {
  FixedPrice,
  Liquid,
  Operation,
  OperationTypes,
  PartOperation,
  RepairTime
} from '../../redux/stores/planning/types';
import { QuoteCalculMode } from '../../redux/stores/quotes/types';

export type OneOfOperationType =
  | PartOperation
  | RepairTime
  | Liquid
  | FixedPrice;

export enum UpdatableOperationField {
  PRICE = 'price',
  QUANTITY = 'quantity',
  DISCOUNT = 'discount'
}

export const isQuoteOperationsHasOemParts = (
  quoteOperations: Operation[]
): boolean =>
  quoteOperations
    .filter(o => o.operationType === 'parts')
    .filter((o: any) => o.oemPartsId.length > 1).length > 0;

export const reformatOperationLabel = (labelDisplayed: string, language: Language): string => {
  if (labelDisplayed.includes('PACKAGE_DISCOUNT')) {
    return 'Déduction forfait';
  }
  if (labelDisplayed.includes('PACKAGE')) {
    return 'FORFAIT';
  }
  if (labelDisplayed.includes('DISCOUNT_PART')) {
    return language.quote.discountPart;
  }
  if (labelDisplayed.includes('DISCOUNT_REPAIRTIME')) {
    return language.quote.discountRepairTime;
  }
  return labelDisplayed;
};

export const isOperationUpdatable = (
  label: string,
  calculMode: QuoteCalculMode,
  operationDetails?: CarServiceOperations,
  onOperationUpdate?: (operation: OneOfOperationType) => void
): boolean => {
  if (
    label.includes('PACKAGE') ||
    !onOperationUpdate ||
    (calculMode === QuoteCalculMode.PACKAGE &&
      operationDetails &&
      operationDetails.forfaitisable)
  ) {
    return false;
  }
  return true;
};

const getQtyIndexByOperationType = (operation: OneOfOperationType): string => {
  switch (operation.operationType) {
    case OperationTypes.PARTS:
      return 'quantity';
    case OperationTypes.REPAIR_TIME:
      return 'duration';
    case OperationTypes.LIQUID:
      return 'volume';
    default:
      return 'quantity';
  }
};

export const reformatOperationWithNewValues = (
  newQty: number,
  newDiscountPercent: number,
  newUnitPrice: number,
  operation: OneOfOperationType
): OneOfOperationType => {
  const unitPrice = newUnitPrice;
  const discountAmount = (newQty * unitPrice * newDiscountPercent) / 100;
  const qtyLabel = getQtyIndexByOperationType(operation);
  const total = newQty * unitPrice - discountAmount;
  const newOperation = {
    ...operation,
    discountTotal: discountAmount,
    discountTotalMax: discountAmount,
    garageDiscountValue: discountAmount,
    garageDiscount: newDiscountPercent,
    [qtyLabel]: newQty,
    total,
    totalMax: total,
    unitTotal: newQty * unitPrice,
    unitPrice,
    unitPriceMax: unitPrice,
    totalWithTax: total * 1.2,
    totalMaxWithTax: total * 1.2
  };
  if ('unitTotalMax' in newOperation) {
    const discountAmountMax =
      (newQty * newOperation.unitPriceMax * newDiscountPercent) / 100;
    newOperation.unitTotalMax = newQty * newOperation.unitPriceMax;
    newOperation.garageDiscountValueMax = discountAmountMax;
    newOperation.discountTotalMax = discountAmountMax;
  }
  if ('partsSelected' in newOperation) {
    newOperation.partsSelected.quantity = newQty;
    newOperation.partsSelectedMax.quantity = newQty;
  }

  return newOperation;
};

export const isFieldUpdatableForOperationType = (
  type: string,
  field: string,
  isUserAdmin: boolean = false
): boolean => {
  switch (field) {
    case UpdatableOperationField.PRICE:
      return type === OperationTypes.FIXED_PRICE || isUserAdmin;
    case UpdatableOperationField.QUANTITY:
      return (
        type === OperationTypes.PARTS ||
        type === OperationTypes.LIQUID ||
        type === OperationTypes.REPAIR_TIME
      );
    case UpdatableOperationField.DISCOUNT:
      return (
        type === OperationTypes.PARTS || type === OperationTypes.REPAIR_TIME
      );
    default:
      return false;
  }
};

import React from 'react';
import InfoIcon from '@material-ui/icons/Info';
import makeStyles from '@material-ui/core/styles/makeStyles';
import colors from '../../../assets/style-colors';

interface InfoBubbleProps {
  infoText: string;
  size?: number;
}

const useStyle = makeStyles({
  infoBubble: {
    position: 'relative',
    display: 'inline-block',
    '&:hover': {
      '& svg': {
        color: colors.primary
      },
      '& p': {
        maxHeight: 'none',
        padding: '15px 10px 15px 10px'
      }
    }
  },
  text: {
    position: 'absolute',
    left: '-100px',
    top: '100%',
    maxHeight: '0',
    overflow: 'hidden',
    backgroundColor: colors.white,
    borderRadius: '5px',
    textTransform: 'none',
    margin: 0,
    width: '200px'
  }
});

const InfoBubble: React.FC<InfoBubbleProps> = ({ infoText, size }) => {
  const classes = useStyle();
  return (
    <div className={classes.infoBubble}>
      <InfoIcon style={{ fontSize: `${size}px` }} />
      <p className={classes.text}>{infoText}</p>
    </div>
  );
};

InfoBubble.defaultProps = {
  size: 17
};

export default InfoBubble;

import moment from "moment";

const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1)

const getFormattedVisibilityChart = (visibilityData: any[]) => (visibilityData || []).map((visibilityMonth, index) => ({
    ...visibilityMonth,
    monthName: capitalize(moment().month(index).format('MMM').slice(0, 3)),
}));



export default getFormattedVisibilityChart;
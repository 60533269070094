import {
  ActionTypes,
  GARAGES_LOADING,
  SET_BRANDS,
  SET_GARAGES
} from './actions';
import { GarageStore } from './type';

const initialState: GarageStore = {
  garages: [],
  garagesLoading: false,
  brands: []
};

const garagesReducer = (
  state: GarageStore = initialState,
  action: ActionTypes
) => {
  switch (action.type) {
    case SET_GARAGES:
      return { ...state, garages: action.payload };
    case GARAGES_LOADING:
      return { ...state, garagesLoading: action.payload };
    case SET_BRANDS:
      return { ...state, brands: action.payload };
    default:
      return state;
  }
};
export default garagesReducer;

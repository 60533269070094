import React from 'react';

const RatesIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    <path
      fill="#AAAEC5"
      d="M10.132 0l.113.002 6.466.588c.333.03.605.271.68.59l.018.108.588 6.465c.017.19-.037.379-.149.53l-.074.086-9.404 9.404c-.274.274-.702.299-1.004.075l-.087-.075L.226 10.72c-.274-.274-.299-.703-.075-1.005l.075-.086L9.63.225C9.765.09 9.944.01 10.132 0zm.334 1.57l-8.603 8.604 5.961 5.962 8.603-8.604-.496-5.465-5.465-.497zm3.782 2.181c.95.95.95 2.492 0 3.442-.951.95-2.492.95-3.442 0-.95-.95-.95-2.491 0-3.442.95-.95 2.491-.95 3.442 0zm-2.351 1.091c-.348.348-.348.912 0 1.26.347.348.912.348 1.26 0s.348-.912 0-1.26-.913-.348-1.26 0z"
    />
  </svg>
);

export default RatesIcon;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import alertCopyIcon from '../../../assets/style-elements/01-electrons-icon-alert-big-copy-gay@3x.png';
import checkedIcon from '../../../assets/images/icon-check@3x.png';
import { Store } from '../../../redux/types';
import { setCurrentBookingHistory } from '../../../redux/stores/planning/actions';

const History = () => {
  // CONSTANTS:
  const { languageReducer, planningStore } = useSelector(
    (state: Store) => state
  );
  const { language } = languageReducer;
  const { currentBooking } = planningStore;
  const availableStatus = [
    'CONFIRMED',
    'CANCELLED',
    'WALLET',
    'VALIDATED',
    'REPORTED',
    'POSTPONED'
  ];
  const dispatch = useDispatch();
  const bookingHistories = currentBooking?.bookingHistories ?? [];

  useEffect(() => {
    dispatch(setCurrentBookingHistory());
  }, []);

  if (
    !currentBooking ||
    bookingHistories.length === 0 ||
    !Array.isArray(bookingHistories)
  ) {
    return null;
  }

  return (
    <div className="donateItemWrapper noprint">
      <div className="history">
        <div className="donateItem">
          <p className="four">
            <img
              className="leftIcon"
              src={alertCopyIcon}
              alt="history icon"
              style={{ marginRight: '5px' }}
            />
            {language.command.lateral.history}
          </p>
        </div>
        {bookingHistories.map(
          (history, index) =>
            availableStatus.find(status => status === history.newValue) && (
              <div className={`donateItem ${history.newValue}`} key={index}>
                {/* TODO: rework this block css integration */}
                <div className="in">
                  {(index !== 0 || history.newValue === 'CONFIRMED') && (
                    <span className="a">
                      {history.newValue === 'CONFIRMED' && (
                        <img
                          className="b"
                          src={checkedIcon}
                          alt="checked icon"
                        />
                      )}
                    </span>
                  )}
                  {history.newValue !== 'CONFIRMED' && <span className="b" />}
                  <span className="c" />
                </div>
                <div className="wrap">
                  <div className="background">
                    <span className={`quoteLabel ${history.newValue}`}>
                      {language.command.lateral[history.newValue] ||
                        history.newValue}
                    </span>
                  </div>
                  <span className="quote">
                    {language.command.lateral[history.message] ||
                      history.message}
                    <br />
                  </span>
                  <span className="historyDate">
                    {moment(history.date).format('DD/MM/YYYY HH:mm:ss')}
                  </span>
                </div>
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default History;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../../assets/style-colors';

interface IconButtonProps {
  icon: string;
  isMinified?: boolean;
  label: string;
  onClick: () => any;
  className?: string;
}

const getStyle = (isMinified: boolean) =>
  makeStyles(() => ({
    item: {
      background: colors.white,
      color: colors.black,
      borderRadius: '10px',
      height: '34px',
      padding: '10px',
      margin: '5px',
      border: 'none',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      cursor: 'pointer',
      boxShadow: '0 2px 18px 0 rgba(214,214,214,0.5)',
    },
    icon: {
      color: colors.primary,
      marginRight: isMinified ? 0 : '10px',
      width: '14px',
    },
    link: {
      fontSize: '12px',
      color: colors.dark,
      whiteSpace: 'nowrap',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  }));

const IconButton: React.FC<IconButtonProps> = ({
  icon,
  isMinified,
  label,
  className,
  onClick,
}) => {
  const useStyles = getStyle(isMinified || false);
  const classes = useStyles();

  return (
    <div
      className={`${classes.item} ${className}`}
      role="button"
      tabIndex={0}
      onClick={onClick}
    >
      <img alt={label} src={icon} className={classes.icon} />
      {!isMinified && <span className={classes.link}>{label}</span>}
    </div>
  );
};

IconButton.defaultProps = {
  isMinified: false,
  className: 'item',
};

export default IconButton;

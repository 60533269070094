import React from 'react';
import { useSelector } from 'react-redux';
import { Store } from '../../../../../redux/types';
import carIcon from '../../../../../assets/style-elements/01-electrons-icon-car-gay@3x.png';
import editIcon from '../../../../../assets/style-elements/Icon-edit.svg';
import useStyles from '../style';
import {
  Booking,
  FlexFuelStatus,
} from '../../../../../redux/stores/planning/types';
import {
  Vehicle,
  QuoteIds,
  QuoteType,
} from '../../../../../redux/stores/quotes/types';
import {
  CountryCodes,
  Garage,
  Networks,
} from '../../../../../redux/stores/global/types';
import VehicleEnergyTypes from '../../../../../utils/vehicle';
import EditVehicle from '../../../modal/editVehicle';

interface VehicleProps {
  currentBooking: Booking;
  currentVehicle: Vehicle | null;
  currentGarage: Garage;
}

const VehicleInfos = ({
  currentBooking,
  currentVehicle,
  currentGarage,
}: VehicleProps) => {
  const lg = useSelector((state: Store) => state.languageReducer.language);

  const classes = useStyles();

  const isGarageBe = currentGarage?.garageAddress.country === CountryCodes.BE;

  const mineTypes = currentVehicle?.typeMine || [];

  const isBookingWithFlexFuel: boolean = currentBooking.quotes.some(
    (quote) => quote.carServiceId === QuoteIds.FLEXFUEL_SERVICEID
  );

  const getVehicleHomologation = (): string => {
    const quoteWithFlexFuel = currentBooking.quotes.find(
      (quote) => quote.carServiceId === QuoteIds.FLEXFUEL_SERVICEID
    );
    if (!quoteWithFlexFuel || !quoteWithFlexFuel.flexFuel) {
      return lg.general.unknown;
    }

    switch (quoteWithFlexFuel.flexFuel) {
      case FlexFuelStatus.CERTIFIED:
        return lg.general.yes;
      case FlexFuelStatus.NOT_CERTIFIED:
        return lg.general.no;
      default:
        return lg.general.unknown;
    }
  };

  const isQuotesPriceLess = currentBooking.quotes.every(
    (quote) => quote.quoteType === QuoteType.PRICELESS
  );

  const isOwnerAndPriceLess =
    currentBooking.networkId === Networks.GARAGE_OWNER && isQuotesPriceLess;

  return (
    <div className={classes.donateItemWrapper}>
      <img className="leftIcon" src={carIcon} alt="contact detail icon" />

      <div className="donateList">
        <div className="donateItem">
          {!currentVehicle ? (
            <p className="name-vehicle">{lg.command.lateral.vehicle}</p>
          ) : (
            <p className="name-vehicle">
              {currentVehicle.version.model.make.label} {currentVehicle.label}
            </p>
          )}
          {isGarageBe ? (
            <p className="third">
              {lg.command.lateral.registrationVin}
              <span className="ref">
                {currentBooking.vehicle?.registrationNumber}
              </span>
            </p>
          ) : (
            <p className="third">
              {lg.command.lateral.registration}
              <span className="ref">
                {currentBooking.vehicle?.registrationNumber}
              </span>
            </p>
          )}

          <p className="third">
            {lg.command.lateral.mineType} :
            <span className="ref mine">
              {mineTypes.map((mine, index) => (
                <span className={classes.mineType}>
                  {mine}
                  {index + 1 !== mineTypes.length && `,`}
                </span>
              ))}
            </span>
          </p>

          <p className="third">
            {lg.command.lateral.engine} :
            <span className="ref">{currentVehicle?.labelMotor}</span>
          </p>

          {currentVehicle?.energyType && (
            <p className="third">
              {lg.quote.fuel} :
              <span className="ref">
                {VehicleEnergyTypes[currentVehicle?.energyType]}
              </span>
            </p>
          )}

          {isBookingWithFlexFuel && (
            <p className="third">
              {lg.quote.flexHomologation} :
              <span className="ref">{getVehicleHomologation()}</span>
            </p>
          )}

          {currentBooking.vehicle?.kilometers && (
            <p className="third">
              {lg.quote.km} :
              <span className="ref">{currentBooking.vehicle.kilometers}</span>
            </p>
          )}
        </div>
      </div>
      {isOwnerAndPriceLess && (
        <div className="editButton">
          <EditVehicle>
            <img className="rightIcon" src={editIcon} alt="edit icon" />
          </EditVehicle>
        </div>
      )}
    </div>
  );
};

export default VehicleInfos;

import makeStyles from '@material-ui/core/styles/makeStyles';
import colors from '../../../../assets/style-colors';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    width: '160px',
    position: 'relative',
    marginRight: '16px',

    '& button': {
      width: '100%',
      height: '40px',
      marginRight: 0,
      color: colors.dark,
    },
  },

  buttonContainerClickStyle: {
    boxShadow: '0px 3px 8px rgba(77, 77, 77, 0.2);',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',

    '& button': {
      borderBottom: `1px solid ${colors.cloudWhite}`,
      borderBottomLeftRadius: '0',
      borderBottomRightRadius: '0',
      boxShadow: 'none',
    },
  },

  printLogo: {
    width: '16px',
    height: '16px',
  },

  printText: {
    color: colors.dark,
    fontSize: '12px',
    lineHeight: 'normal',
    paddingLeft: '10px',
  },

  printArrow: {
    marginLeft: '10px',
    transform: 'rotate(0deg)',
    transition: 'transform .25s ease-in-out',
  },

  printArrowTransform: {
    marginLeft: '10px',
    transform: 'rotate(180deg)',
    transition: 'transform .25s ease-in-out',
  },

  smallModal: {
    position: 'absolute',
    width: '100%',
    backgroundColor: colors.white,
    boxShadow: '0px 3px 8px rgba(77, 77, 77, 0.2);',

    '& button': {
      width: '100%',
    },
  },

  smallModalClickStyle: {
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',

    '& button': {
      boxShadow: 'none',
      borderRadius: 0,

      '&:nth-child(1)': {
        borderBottom: `1px solid ${colors.cloudWhite} !important`,
      },

      '&:nth-child(2)': {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
      },
    },
  },
}));

export default useStyles;

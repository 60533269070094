import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RenderOnRoles from '../../../../assets/generic-components/RenderOnRoles';
import CancelQuote from '../../../../components/command-page/modal/cancelQuote';
import Refund from '../../../../components/command-page/modal/refund';
import {
  cancelQuoteEvent,
  refundQuoteEvent,
} from '../../../../lib/events/booking';
import { Garage } from '../../../../redux/stores/global/types';
import { updateQuoteStatus } from '../../../../redux/stores/planning/actions';
import {
  Booking,
  Quote,
  QuoteStatus,
} from '../../../../redux/stores/planning/types';
import { BookingType } from '../../../../redux/stores/quotes/types';
import { Store } from '../../../../redux/types';
import AuthenticationService, {
  Roles,
} from '../../../../services/authentication-service';
import settingIcon from '../../../../assets/style-elements/settings.svg';
import useStyles from './style';
import Button from '../../../../assets/generic-components/Button';

interface SettingButtonProps {
  /**
   *  Whether or not the tooltip is opened.
   */
  isTooltipOpened: boolean;
  /**
   *  Quote.
   */
  quote: Quote;
  /**
   *  Current booking.
   */
  currentBooking: Booking | undefined;
  /**
   *  Current garage.
   */
  currentGarage: Garage | null | undefined;
  /**
   *  Whether or not the admin can edit the quote.
   */
  adminEdit: boolean;
  /**
   *  Function to update the quote.
   */
  toggleEditQuoteMode: () => void;
  /**
   *  Whether or not the user is admin.
   */
  isAdmin: boolean;
  /**
   *  Whether or not the user is a garage owner.
   */
  isGarageOwner: boolean;
  /**
   *  Whether or not the booking networkId is garage owner.
   */
  isNetworkIdGarageOwner: boolean;
}

const SettingButton = ({
  isTooltipOpened,
  quote,
  currentBooking,
  currentGarage,
  adminEdit,
  toggleEditQuoteMode,
  isAdmin,
  isGarageOwner,
  isNetworkIdGarageOwner,
}: SettingButtonProps) => {
  const dispatch = useDispatch();

  const displayError = (error: Error): void => {
    throw error;
  };

  const lg = useSelector((state: Store) => state.languageReducer.language);

  const classes = useStyles();

  const [open, setOpen] = useState(isTooltipOpened);

  const isCancelBlockDisplay = (): boolean => {
    if (AuthenticationService.hasRole([Roles.ADMIN, Roles.CALL_CENTER])) {
      return currentBooking?.networkId !== BookingType.GARAGE_OWNER
        ? true
        : quote.status !== QuoteStatus.CANCELLED;
    }
    if (AuthenticationService.hasRole([Roles.GARAGE])) {
      if (currentBooking?.networkId === BookingType.GARAGE_OWNER) {
        return quote.status !== QuoteStatus.CANCELLED;
      }
    }
    return false;
  };

  const cancelButton =
    !isAdmin && isGarageOwner && isNetworkIdGarageOwner ? (
      <Button
        theme="withBorder"
        title={lg.command.detail.quoteCanceled}
        disabled={adminEdit}
        onClick={() => {
          if (currentBooking?.bookingId) {
            dispatch(
              updateQuoteStatus(
                currentBooking.bookingId,
                quote.quoteId,
                QuoteStatus.CANCELLED,
                'CANCELLED',
                () => {
                  cancelQuoteEvent({
                    garageId: currentGarage?.id || '',
                    garageName: currentGarage?.companyName || '',
                    quote_id: quote.quoteId,
                  });
                },
                displayError
              )
            );
          }
        }}
      />
    ) : null;

  const settingButton = isAdmin ? (
    <div className={classes.setting}>
      <Button theme="withImg" onClick={() => setOpen((prev) => !prev)}>
        <img
          className={classes.settingIconStyle}
          alt="setting"
          src={settingIcon}
        />
      </Button>
      <div className={open ? 'tooltip show' : 'tooltip hide'}>
        {isCancelBlockDisplay() && (
          <CancelQuote
            onClick={() => {
              if (currentBooking?.bookingId) {
                dispatch(
                  updateQuoteStatus(
                    currentBooking.bookingId,
                    quote.quoteId,
                    QuoteStatus.CANCELLED,
                    'CANCELLED',
                    () => {
                      cancelQuoteEvent({
                        garageId: currentGarage?.id || '',
                        garageName: currentGarage?.companyName || '',
                        quote_id: quote.quoteId,
                      });
                    },
                    displayError
                  )
                );
              }
            }}
          >
            <p className={classes.settingTooltipItem}>
              {lg.command.detail.quoteCanceled}
            </p>
          </CancelQuote>
        )}
        {!adminEdit && (
          <RenderOnRoles roles={[Roles.CALL_CENTER, Roles.ADMIN]}>
            <a
              href="#"
              className={classes.settingTooltipItem}
              onClick={() => {
                setOpen(false);
                toggleEditQuoteMode();
              }}
            >
              {lg.devis.updateQuote}
            </a>
          </RenderOnRoles>
        )}
        {quote.status !== QuoteStatus.WALLET &&
          currentBooking?.networkId !== BookingType.GARAGE_OWNER && (
            <Refund
              onClick={() => {
                if (currentBooking?.bookingId) {
                  dispatch(
                    updateQuoteStatus(
                      currentBooking.bookingId,
                      quote.quoteId,
                      QuoteStatus.WALLET,
                      QuoteStatus.WALLET,
                      () => {
                        refundQuoteEvent({
                          garageId: currentGarage?.id || '',
                          garageName: currentGarage?.companyName || '',
                          quote_id: quote.quoteId,
                          amount: quote.commission?.customer || -1,
                        });
                      },
                      displayError
                    )
                  );
                }
              }}
            >
              <p className={classes.setttingRefund}>
                {lg.command.detail.quoteAsset}
              </p>
            </Refund>
          )}
      </div>
    </div>
  ) : null;

  return (
    <>
      {cancelButton}
      {settingButton}
    </>
  );
};

export default SettingButton;

import React, { FunctionComponent } from 'react';
import AuthenticationService, {
  Roles
} from '../../services/authentication-service';

interface RenderOnRolesProps {
  roles: Roles[];
  fallbackComponent?: any;
}

const RenderOnRoles: FunctionComponent<RenderOnRolesProps> = ({
  roles,
  fallbackComponent,
  children
}) =>
  AuthenticationService.hasRole(roles) ? (
    <>{children}</>
  ) : (
    <>{fallbackComponent}</>
  );

RenderOnRoles.defaultProps = {
  fallbackComponent: null
};

export default RenderOnRoles;

import React from 'react';
import { useSelector } from 'react-redux';
import { Store } from '../../../redux/types';
import AuthenticationService from '../../../services/authentication-service';
import UnauthorizedTemplate from '../../components/templates/UnauthorizedTemplate';

export default () => {
  const lang = useSelector((state: Store) => state.languageReducer.language);
  return (
    <UnauthorizedTemplate
      lang={lang}
      onLogout={() => AuthenticationService.doLogout()}
    />
  );
};

import initState from '../initialState';

const calendarReducer = (state = initState, action) => {
  switch (action.type) {
    case 'WEEK_NUMBER':
      return {
        ...state,
        weekNumber: action.payload
      };
    case 'DATETIME':
      return {
        ...state,
        date: action.payload
      };
    case 'ACTIVE_DAY_WEEK':
      return {
        ...state,
        activeWeek: action.payload,
        activeDay: !action.payload
      };
    case 'SHOW_DEVIS':
      return {
        ...state,
        devis: action.payload
      };
    case 'SET_DEVIS_DATA':
      return {
        ...state,
        devisData: action.payload
      };
    case 'SET_DEVIS_DATA_DETAILS':
      return {
        ...state,
        devisDataDetails: action.payload
      };
    case 'SET_ALL_DATA':
      return {
        ...state,
        allData: action.payload
      };
    case 'SET_DAY_INDEX':
      return {
        ...state,
        dayIndex: action.payload
      };
    case 'SET_DAY':
      return {
        ...state,
        day: action.payload
      };
    case 'SET_MODAL':
      return {
        ...state,
        modal: action.payload
      };
    default:
      return state;
  }
};

export default calendarReducer;

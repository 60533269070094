import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import colors from '../../../assets/style-colors';
import { VisibilityTabs } from '../../../redux/stores/global/types';
import { Visible } from '../../../redux/stores/languages';
import { Store } from '../../../redux/types';
import VisibilityChart from './visibilityChart';

interface ChartsProps {
  chartsByMonth: { data: [] };
  chartKeys: { name: keyof Visible; icon: string; color: string }[];
  currentMonthIndex: number;
}

const useStyle = makeStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '15px',
    marginBottom: '30px',
  },
  chart: {
    flex: '300px 0 0',
    backgroundColor: colors.white,
    minHeight: '200px',
    padding: '20px 20px 0 20px',
  },
  head: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    marginBottom: '20px',

    '& p': {
      fontWeight: 'bold',
      margin: '0',
      fontSize: '18px',
    },
    '& h2': {
      fontSize: '13px',
      fontWeight: 'normal',
      margin: 0,
    },
  },
  icon: {
    backgroundColor: colors.paleGrey,
    padding: '10px',
    flex: '52px 0 0',
    height: '52px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& > img': {
      width: '20px',
      height: '20px',
      objectFit: 'contain',
    },
  },
  separator: {
    width: 'calc(100% + 40px)',
    marginBottom: '20px',
    height: '1px',
    content: '""',
    marginLeft: '-20px',
    marginRight: '-20px',
    backgroundColor: colors.WhiteDark,
  },
  description: {
    fontWeight: 'normal',
    marginBottom: '30px',
    fontSize: '12px',
  },
});

const Charts: React.FC<ChartsProps> = ({
  chartsByMonth,
  chartKeys,
  currentMonthIndex,
}) => {
  const lg = useSelector((state: Store) => state.languageReducer.language);
  const classes = useStyle();

  const allOptionIndex = 12;

  const getMonthDataByChart = (month: number, chartKeyName: string): number =>
    chartsByMonth?.data
      ? parseInt(chartsByMonth?.data[month][chartKeyName])
      : 0;

  const sum = (array: number[]) => {
    const initialValue = 0;
    const number = array?.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      initialValue
    );
    return number;
  };

  const getCharts = (chartKeyName: string): number =>
    sum(chartsByMonth?.data.map((q) => parseInt(q[chartKeyName])));

  const getCurrency = (name: keyof Visible) => {
    const currency =
      name !== 'quotes' && window.location.pathname === VisibilityTabs.Sale
        ? '€'
        : '';

    return currency;
  };

  return (
    <div className={classes.container}>
      {chartKeys.map((chartKey, index) => (
        <div className={classes.chart}>
          <div className={classes.head}>
            <div className={classes.icon}>
              <img src={chartKey.icon} alt="icon" />
            </div>
            <div>
              <p>
                {currentMonthIndex === allOptionIndex
                  ? getCharts(chartKey.name)
                  : getMonthDataByChart(currentMonthIndex, chartKey.name)}
                &nbsp;{getCurrency(chartKey.name)}
              </p>
              <h2>{lg.visibility.visible[chartKey.name]}</h2>
            </div>
          </div>
          <div className={classes.separator} />
          <p className={classes.description}>
            {lg.visibility.visible[`${chartKey.name}_text` as keyof Visible]}
          </p>
          <VisibilityChart
            key={index}
            chartsByMonth={chartsByMonth}
            chartKey={chartKey}
            currentMonthIndex={currentMonthIndex}
            lg={lg}
          />
        </div>
      ))}
    </div>
  );
};

export default Charts;

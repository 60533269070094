import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';

import Button from '../../../../assets/generic-components/Button';
import CheckboxLabel from '../../../../assets/generic-components/CheckboxLabel';
import Title from '../../../../assets/generic-components/Title';
import {
  saveGarage,
  saveGarageAddress,
} from '../../../../redux/stores/garages/actions';
import { GarageAddres } from '../../../../redux/stores/garages/type';
import {
  setGarage,
  setNotification,
} from '../../../../redux/stores/global/actions';
import { Store } from '../../../../redux/types';
import Header from '../header';
import {
  SeverityMessage,
  Countries,
  CountryCodesUppercase,
} from '../../../../redux/stores/global/types';
import InputLabel from '../../../../assets/generic-components/InputLabel';
import InputMutipleSelect from '../../../../assets/generic-components/InputMutipleSelect';
import { getCities } from '../../../../redux/stores/cities/actions';
import useStyles from './style';

const Box = styled.div`
  display: flex;
  padding-top: 50px;
  flex-direction: column;
`;

const Info = () => {
  const {
    globalReducer: { currentGarage },
    citiesReducer: { cities },
    languageReducer: { language: lg },
  } = useSelector((state: Store) => state);

  type InfosGarageFormValues = {
    name: string;
    firstname: string;
    lastname: string;
    siret: string;
    rcs: string;
    address1: string;
    address2: string;
    zipcode: string;
    city: string;
    info: string;
  };

  if (!currentGarage) return null;

  const [country, setCountry] = useState(currentGarage.garageAddress.country);

  const [currentCity, setCurrentCity] = useState(
    currentGarage.garageAddress.city
  );

  const [currentZipcode, setCurrentZipcode] = useState(
    currentGarage.garageAddress.zipcode
  );

  const dispatch = useDispatch();

  const { register, handleSubmit, errors, reset } =
    useForm<InfosGarageFormValues>({
      mode: 'onChange',
    });

  const onSubmit = (values: InfosGarageFormValues) => {
    const newGarageAddress: GarageAddres = {
      address1: values.address1,
      address2: values.address2,
      city: currentCity,
      zipcode: values.zipcode,
      country,
    };

    const newGarageInfo = {
      siret: values.siret,
      numrcs: values.rcs,
      favoriteLanguage: 'fr',
    };

    dispatch(
      saveGarage(
        currentGarage.id,
        newGarageInfo,
        () => {
          dispatch(setGarage(currentGarage.id));
          dispatch(
            setNotification({
              severity: SeverityMessage.SUCCESS,
              message: lg.general.success,
            })
          );
        },
        (error: Error) =>
          dispatch(
            setNotification({
              severity: SeverityMessage.ERROR,
              message: error.message,
            })
          )
      )
    );
    dispatch(
      saveGarageAddress(currentGarage.id, newGarageAddress, () =>
        dispatch(setGarage(currentGarage.id))
      )
    );
  };

  const classes = useStyles();

  const zipcodeRegex =
    country === CountryCodesUppercase.BE
      ? /^(?:(?:[1-9])(?:\d{3}))$/
      : /^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/;

  useEffect(() => {
    if (currentZipcode) {
      dispatch(getCities(country.toLowerCase(), currentZipcode));
    }
  }, [currentZipcode]);

  return (
    <>
      <Helmet>
        <title>Mon garage | Informations générales</title>
      </Helmet>
      <Header show={false} />
      <div className={`${classes.inputsContainer} pt-40`}>
        <Box className="mt-e-18 pt-0">
          <Title title={lg.rates.informationGarage} />
        </Box>

        <Box className={`${classes.customLabel} pt-25`}>
          <InputLabel
            label={lg.rates.siret}
            name="siret"
            defaultValue={currentGarage.siret}
            reference={register({
              required: true,
            })}
          >
            {errors.siret &&
              errors.siret.type === 'required' &&
              lg.quote.messageField}
          </InputLabel>

          <InputLabel
            label={lg.rates.rcs}
            name="rcs"
            defaultValue={currentGarage.numrcs}
          />
        </Box>

        <Box className="fontBold">
          <Title title={lg.rates.garageAddress} />
        </Box>

        <Box className={`${classes.customLabel} pt-5`}>
          <InputLabel
            label={lg.rates.addressStreet}
            name="address1"
            defaultValue={currentGarage.garageAddress.address1}
            reference={register({
              required: true,
            })}
          >
            {errors.address1 &&
              errors.address1.type === 'required' &&
              lg.quote.messageField}
          </InputLabel>

          <InputLabel
            label={lg.rates.additionalAddress}
            name="address2"
            defaultValue={currentGarage.garageAddress.address2}
          >
            {errors.address2 &&
              errors.address2.type === 'required' &&
              lg.quote.messageField}
          </InputLabel>
          <InputLabel
            name="zipcode"
            label={lg.rates.postal}
            defaultValue={currentZipcode}
            reference={register({
              required: true,
              pattern: zipcodeRegex,
            })}
            onChange={(e) => setCurrentZipcode(e.currentTarget.value)}
          >
            {errors.zipcode &&
              errors.zipcode.type === 'required' &&
              lg.quote.messageField}
            {errors.zipcode &&
              errors.zipcode.type === 'pattern' &&
              lg.rates.unknownPostal}
          </InputLabel>
          <InputMutipleSelect
            datas={cities}
            dataIsCities
            label={lg.rates.city}
            name="city"
            selected={currentCity}
            onChange={(e) => setCurrentCity(e.currentTarget.value)}
          />
        </Box>
        <InputMutipleSelect
          datas={Countries}
          label={lg.rates.country}
          name="country"
          selected={country}
          onChange={(e) => {
            setCountry(e.currentTarget.value);
            reset({
              zipcode: '',
            });
          }}
        />
        <Box className={`${classes.customCheckbox} pt-5`}>
          <div className={classes.check}>
            <CheckboxLabel disabled checked label={lg.rates.condition} />
            <p>
              <a
                target="blank"
                href="https://www.idgarages.com/fr-fr/a-propos/cgvu-garages"
              >
                {lg.account.see}
              </a>
            </p>
          </div>

          <div className={classes.check}>
            <CheckboxLabel disabled checked label={lg.rates.charter} />
            <p>
              <a
                target="blank"
                href="https://www.idgarages.com/fr-fr/a-propos/charte-qualite"
              >
                {lg.account.see}
              </a>
            </p>
          </div>
        </Box>

        <Button
          width="274px"
          className="mt-30 mb-100"
          theme="dark"
          type="submit"
          title={lg.rates.save}
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </>
  );
};

export default Info;

import React from 'react';
import { useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';
import { SeverityMessage } from '../../../../redux/stores/global/types';
import { Store } from '../../../../redux/types';

interface LoaderProps {
  isLoading: boolean;
}

const Loader: React.FC<LoaderProps> = ({ isLoading }) => {
  const lang = useSelector((state: Store) => state.languageReducer.language);

  return (
    <Snackbar
      open={isLoading}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert severity={SeverityMessage.INFO}>{lang.isLoading}</Alert>
    </Snackbar>
  );
};

export default Loader;

import * as Sentry from '@sentry/react';
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  StoreEnhancer
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';
import persistReducer from 'redux-persist/lib/persistReducer';
import persistStore from 'redux-persist/lib/persistStore';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import calendarReducer from './reducers/calendarReducer';
import helpReducer from './reducers/helpReducer';
import languageReducer from './reducers/languageReducer';
import planningReducer from './reducers/planningReducer';
import calendarStore from './stores/calendar';
// eslint-disable-next-line import/no-cycle
import billingsClaimReducer from './stores/billing-claim';
import billingsReducer from './stores/billings';
import garagesReducer from './stores/garages';
import globalReducer from './stores/global';
import quotesReducer from './stores/quotes';
import helpStore from './stores/help';
import planningStore from './stores/planning';
import citiesReducer from './stores/cities';

const globalPersistCfg = {
  key: 'global',
  storage
};

const quotesPersistCfg = {
  key: 'quotes',
  storage
};

const planningPersistCfg = {
  key: 'planning',
  storage
};

const helpPersistCfg = {
  key: 'help',
  storage
};

export default function configureStore(initialState: any) {
  const combinedReducer = combineReducers({
    globalReducer: persistReducer<any, any>(globalPersistCfg, globalReducer),
    quotesReducer: persistReducer(quotesPersistCfg, quotesReducer),
    planningStore: persistReducer(planningPersistCfg, planningStore),
    helpStore: persistReducer(helpPersistCfg, helpStore),
    garagesReducer,
    calendarStore,
    languageReducer,
    calendarReducer,
    planningReducer,
    helpReducer,
    billingsReducer,
    billingsClaimReducer,
    citiesReducer
  });

  const sentryReduxEnhancer = Sentry.createReduxEnhancer({});
  const store = createStore(
    combinedReducer,
    initialState,
    process.env.REACT_APP_ENV === 'dev'
      ? composeWithDevTools(applyMiddleware(thunk, logger), sentryReduxEnhancer)
      : (compose(applyMiddleware(thunk), sentryReduxEnhancer) as StoreEnhancer)
  );
  const persistor = persistStore(store);
  return { store, persistor };
}

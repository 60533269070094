import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import HttpService from '../../../services/http-service';
import { Store } from '../../types';

import { GarageAddres, GarageBillingInfo, GarageBrand } from './type';
import { Garage } from '../global/types';
import GarageRequest from '../../../services/http/Garage';

import { setGarage } from '../global/actions';

export const SET_GARAGES = 'SET_GARAGES';
export const GARAGES_LOADING = 'GARAGES_LOADING';
export const SET_BRANDS = 'SET_BRANDS';

export type ActionTypes =
  | { type: typeof SET_GARAGES; payload: Garage[] }
  | { type: typeof GARAGES_LOADING; payload: boolean }
  | { type: typeof SET_BRANDS; payload: GarageBrand[] };

export const setGarages = (garages: Garage[]): ActionTypes => ({
  type: SET_GARAGES,
  payload: garages,
});

export const garagesLoading = (isLoading: boolean): ActionTypes => ({
  type: GARAGES_LOADING,
  payload: isLoading,
});

export const setBrands = (brands: GarageBrand[]) => ({
  type: SET_BRANDS,
  payload: brands,
});

export const saveGarage =
  (
    garageId: string,
    garage: any,
    success: () => void,
    failure: (error: Error) => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(garagesLoading(true));
    await HttpService.getClient()
      .patch(
        `${process.env.REACT_APP_API_GARAGES_URL}/garages/${garageId}`,
        garage,
        {
          headers: { 'api-key': process.env.REACT_APP_API_GARAGES_KEY },
        }
      )
      .then(() => {
        success();
        dispatch(garagesLoading(false));
      })
      .catch(failure);
  };

export const saveGarageAddress =
  (
    garageId: string,
    address: GarageAddres,
    success: () => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(garagesLoading(true));
    await HttpService.getClient()
      .put(
        `${process.env.REACT_APP_API_GARAGES_URL}/garages/${garageId}/address`,
        address,
        {
          headers: { 'api-key': process.env.REACT_APP_API_GARAGES_KEY },
        }
      )
      .then(success)
      .catch(() => {});
  };

export const saveGarageBillingInfo =
  (
    garageId: string,
    info: GarageBillingInfo,
    success: () => void,
    failure: (error: Error) => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(garagesLoading(true));
    await HttpService.getClient()
      .put(
        `${process.env.REACT_APP_API_GARAGES_URL}/garages/${garageId}/billing-info`,
        info,
        {
          headers: { 'api-key': process.env.REACT_APP_API_GARAGES_KEY },
        }
      )
      .then(() => {
        success();
        dispatch(garagesLoading(false));
      })
      .catch(failure);
  };

export const getGaragesByName =
  (companyName: string): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(garagesLoading(true));

    new GarageRequest()
      .getGaragesByName(companyName)
      .then((garages: Garage[]) => {
        dispatch(setGarages(garages));
        dispatch(garagesLoading(false));
      })
      .catch(() => {});
  };

export const getBrands =
  (): ThunkAction<void, Store, unknown, Action<string>> => async (dispatch) => {
    dispatch(garagesLoading(true));
    await HttpService.getClient()
      .get(`${process.env.REACT_APP_API_GARAGES_URL}/brand`, {
        headers: {
          'api-key': process.env.REACT_APP_API_GARAGES_KEY,
          ts: new Date().getTime(),
        },
      })
      .then((res) => res.data)
      .then((brands: GarageBrand[]) => {
        dispatch(setBrands(brands));
        dispatch(garagesLoading(false));
      })
      .catch(() => {});
  };

export const updateBrandForGarage =
  (
    garageId: string,
    brand: string,
    success: () => void,
    failure: (error: Error) => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(garagesLoading(true));
    HttpService.getClient()
      .put(
        `${process.env.REACT_APP_API_GARAGES_URL}/garages/${garageId}/brand`,
        { brand },
        {
          headers: { 'api-key': process.env.REACT_APP_API_GARAGES_KEY },
        }
      )
      .then(() => {
        success();
      })
      .catch((e) => {
        failure(e);
      })
      .finally(() => dispatch(garagesLoading(false)));
  };

const updateGarageExtraContentById = (
  garageId: string,
  lang: string,
  field: string,
  content: string
) => {
  HttpService.getClient()
    .put(
      `${process.env.REACT_APP_API_GARAGES_URL}/garages/${garageId}/extra-content/${lang}`,
      { field, content },
      {
        headers: { 'api-key': process.env.REACT_APP_API_GARAGES_KEY },
      }
    )
    .then(() => {
      setGarage(garageId);
    })
    .catch(() => {});
};

const createGarageExtraContentById = (
  garageId: string,
  lang: string,
  field: string,
  content: string
) => {
  HttpService.getClient()
    .post(
      `${process.env.REACT_APP_API_GARAGES_URL}/garages/${garageId}/extra-content`,
      { lang, field, content },
      {
        headers: { 'api-key': process.env.REACT_APP_API_GARAGES_KEY },
      }
    )
    .then(() => {
      setGarage(garageId);
    })
    .catch(() => {});
};

export const saveExtraContent =
  (
    lang: string,
    field: string,
    content: string,
    garageId: string
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch, getState: () => Store) => {
    const garageExistingExtraContents =
      getState().globalReducer.currentGarage?.garageExtraContent;
    if (
      (garageExistingExtraContents || []).find(
        (extra) => extra.lang === lang && extra.field === field
      )
    ) {
      updateGarageExtraContentById(garageId, lang, field, content);
    } else if (content && content.length > 0) {
      createGarageExtraContentById(garageId, lang, field, content);
    }
  };

import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import getBookingSuppliers from '../../../services/suppliers';
import { Booking } from '../../../redux/stores/planning/types';
import { CarService } from '../../../redux/stores/global/types';
import { Language } from '../../../redux/stores/languages';

interface SuppliersProps {
  booking: Booking;
  availableCarServices: Record<string, CarService>;
  lg: Language;
}

const useStyles = makeStyles(() => ({
  supplier: {
    padding: '3px 0px',
    marginRight: '5px',
    marginBottom: '0',
    fontSize: '12px'
  }
}));

const Suppliers: React.FC<SuppliersProps> = ({
  booking,
  availableCarServices,
  lg
}) => {
  const [suppliers, setSuppliers] = useState<Array<string | null>>([]);
  const classes = useStyles();

  useEffect(() => {
    setSuppliers(getBookingSuppliers(booking, availableCarServices));
  }, [booking, availableCarServices]);

  return (
    <>
      {suppliers.length === 0 ? (
        <p className="second">{lg.command.lateral.equipment}</p>
      ) : (
        suppliers.map((supplier: string | null, index: number) => (
          <p key={supplier} className={classes.supplier}>
            {supplier}
            {index !== suppliers.length - 1 && <span>,</span>}
          </p>
        ))
      )}
    </>
  );
};

export default Suppliers;

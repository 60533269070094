import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CheckboxLabel from '../../../assets/generic-components/CheckboxLabel';
import { Language } from '../../../redux/stores/languages';

interface SeasonProps {
  seasons: Array<string>;
  setSeasons: (seasons: Array<string>) => void;
  lang: Language;
}

const SEASON_OPTIONS = [
  { id: 'été 4 saisons', label: 'Pneu été / 4 saisons' },
  { id: 'hiver', label: 'Pneu hiver' },
];

const useStyles = makeStyles(() => ({
  label: {
    marginTop: '30px',
    marginBottom: 0,
  },
}));

const Season: React.FC<SeasonProps> = ({ seasons, setSeasons, lang }) => {
  const classes = useStyles();
  const toggleSeason = (seasonId: string): void => {
    if (seasons.includes(seasonId)) {
      setSeasons(seasons.filter((season) => season !== seasonId));
    } else {
      setSeasons([...seasons, seasonId]);
    }
  };

  return (
    <div>
      <p className={classes.label}>{lang.quote.tyres.season}</p>
      {SEASON_OPTIONS.map((seasonOption) => (
        <CheckboxLabel
          checked={seasons.includes(seasonOption.id)}
          onchange={() => toggleSeason(seasonOption.id)}
          label={seasonOption.label}
        />
      ))}
    </div>
  );
};

export default Season;

import { Snackbar } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Alert } from '@material-ui/lab';
import * as moment from 'moment';
import _get from 'lodash/get';
import 'moment/locale/fr';
import 'moment/locale/nl';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import RenderOnRoles from './assets/generic-components/RenderOnRoles';
import ModalDevis from './components/planning-page/components/planning-full-page/components/devisModal';
import SignInAdmin from './components/sign-admin-page';
import { getCalendar } from './redux/stores/calendar/actions';
import { getBrands } from './redux/stores/garages/actions';
import {
  getServices,
  setAdditionalServices,
  setGarage,
  setNotification,
} from './redux/stores/global/actions';
import { Store } from './redux/types';
import analytics from './lib/events/analytics';
import ErrorBoundary from './atomic/components/organisms/ErrorBoundary';
import Layout from './components/layout';
import ErrorPage from './components/error-page';
import AuthenticationService, {
  Roles,
} from './services/authentication-service';
import Logger from './services/logger';
import { setCurrentDay } from './redux/stores/planning/actions';
import getDateToIsoStringFormat from './utils/date';
import './i18n';
import {
  RoutesNavbar,
  DisabledAccountRoutesNavbar,
} from './components/app-routes';
import { getCities } from './redux/stores/cities/actions';

const App: React.FC = () => {
  const { displayBooking } = useSelector((state: Store) => state.planningStore);
  const {
    globalReducer: { currentGarage, currentLocale, notification },
    languageReducer: { language: lg },
  } = useSelector((state: Store) => state);
  const dispatch = useDispatch();
  const [width, setWidth] = useState(window.innerWidth);
  const isLarge = width >= 1440;

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const location = useLocation();

  useEffect(() => {
    analytics.page({ event: 'pageview' });
  }, [location]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return () => window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  useEffect(() => {
    moment.locale(currentLocale);
    if (!AuthenticationService.hasRole([Roles.ADMIN, Roles.CALL_CENTER])) {
      dispatch(setGarage(AuthenticationService.getUserInfo().garageId));
    }
  }, []);

  useEffect(() => {
    if (currentGarage) {
      dispatch(getCalendar(currentGarage.id));
      dispatch(setCurrentDay(getDateToIsoStringFormat()));
    }
  }, [currentGarage]);

  useEffect(() => {
    dispatch(getServices());
    dispatch(getBrands());
    dispatch(setAdditionalServices());
  }, [currentGarage, currentLocale]);

  useEffect(() => {
    if (currentGarage) {
      dispatch(
        getCities(
          currentGarage.garageAddress.country.toLowerCase(),
          currentGarage.garageAddress.zipcode
        )
      );
    }
  }, []);

  const closeNotifier = () => {
    dispatch(setNotification(null));
  };

  const handleGarageNotFound = (): string | undefined => {
    if (!AuthenticationService.hasRole([Roles.CALL_CENTER, Roles.ADMIN])) {
      return Logger.logException(
        new Error(
          `Garage not found for user ${
            AuthenticationService.getUserInfo().email
          }`
        )
      );
    }

    return undefined;
  };

  const useStyles = makeStyles(() => ({
    root: {
      display: 'block',
      width: '100vw',
      height: '100vh',
    },
    navbar: {
      left: isLarge ? '196px' : '55px',
      top: '70px',
      right: '0',
      position: 'absolute',
    },
  }));

  const styles = useStyles();

  const administrationRoles = [Roles.CALL_CENTER, Roles.ADMIN];

  const GARAGESTATE = currentGarage?.state === 'INACTIVE';

  const isAdmin = AuthenticationService.hasRole([
    Roles.ADMIN,
    Roles.CALL_CENTER,
  ]);

  return (
    <ErrorBoundary>
      <>
        <div className={`${styles.root}`}>
          {!currentGarage && (
            <RenderOnRoles roles={administrationRoles}>
              <SignInAdmin />
            </RenderOnRoles>
          )}

          {currentGarage && (
            <Layout garageName={_get(currentGarage, 'companyName', '')}>
              <ErrorBoundary>
                {GARAGESTATE && !isAdmin ? (
                  <DisabledAccountRoutesNavbar
                    className={`${styles.navbar} printfull`}
                    locale={lg.locale}
                    garageId={currentGarage.id}
                  />
                ) : (
                  <RoutesNavbar
                    className={`${styles.navbar} printfull`}
                    locale={lg.locale}
                    garageId={currentGarage.id}
                  />
                )}
              </ErrorBoundary>
            </Layout>
          )}
          {!currentGarage &&
            !AuthenticationService.hasRole(administrationRoles) && (
              <ErrorPage
                title="Désolé, votre garage n'a pas pu être trouvé."
                eventId={handleGarageNotFound()}
              />
            )}
        </div>
        {displayBooking ? <ModalDevis show={displayBooking} /> : null}
        <Snackbar
          open={notification !== null}
          autoHideDuration={5000}
          onClose={closeNotifier}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert severity={notification?.severity} onClose={closeNotifier}>
            {notification?.message}
          </Alert>
        </Snackbar>
      </>
    </ErrorBoundary>
  );
};

export default App;

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import colors from '../../../../assets/style-colors';
import { Store } from '../../../../redux/types';
import {
  getAllCarServicesWithoutTyres,
  getQuoteForTyre,
  ITyre,
} from '../../../../services/quote/tyres';
import { addQuote, getQuotes } from '../../../../redux/stores/quotes/actions';
import { QUOTE_SUMMARY } from '../../../../router/routes';
import {
  MessageNotification,
  SeverityMessage,
} from '../../../../redux/stores/global/types';
import { setNotification } from '../../../../redux/stores/global/actions';
import { Quote } from '../../../../redux/stores/planning/types';

const useStyle = makeStyles({
  action: {
    display: 'flex',
  },
  quantity: {
    display: 'flex',
    alignItem: 'center',
    flex: '100%',
    border: `1px solid ${colors.lightGreyBlue}`,
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
    minWidth: '80px',
    justifyContent: 'space-between',

    '& p': {
      margin: '5px 0',
      fontSize: '16px',
    },

    '& button': {
      backgroundColor: 'transparent',
      border: 'none',
    },
  },
  choose: {
    minWidth: '60px',
    backgroundColor: colors.primary,
    flex: '100%',
    borderTopRightRadius: '10px',
    border: 'none',
    color: colors.white,
    borderBottomRightRadius: '10px',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: '10px',
  },
});

interface TyreActionProps {
  tyre: ITyre;
}

const TyreAction: React.FC<TyreActionProps> = ({ tyre }) => {
  const lang = useSelector((state: Store) => state.languageReducer.language);
  const garage = useSelector(
    (state: Store) => state.globalReducer.currentGarage
  );
  const currentVehicle = useSelector(
    (state: Store) => state.quotesReducer.currentVehicle?.id
  );
  const selectedCarServices = useSelector(
    (state: Store) => state.quotesReducer.selectedCarServices
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const [quantity, setQuantity] = useState(1);
  const classes = useStyle();

  const addQuantity = (): void => {
    setQuantity(quantity + 1);
  };

  const removeQuantity = (): void => {
    if (quantity === 1) {
      return;
    }
    setQuantity(quantity - 1);
  };

  const handleTyreSelected = (): void => {
    const notification: MessageNotification = {
      severity: SeverityMessage.ERROR,
      message: lang.error.server,
    };
    if (!garage?.id || !currentVehicle) {
      dispatch(setNotification(notification));
      return;
    }
    getQuoteForTyre(tyre, garage, quantity.toString(), currentVehicle)
      .then((tyreQuote: Quote | null) => {
        dispatch(
          getQuotes(
            getAllCarServicesWithoutTyres(selectedCarServices),
            currentVehicle,
            garage.id,
            () => {
              if (tyreQuote) {
                dispatch(addQuote(tyreQuote));
              }
              history.push(QUOTE_SUMMARY);
            },
            () => {
              dispatch(setNotification(notification));
            }
          )
        );
      })
      .catch(() =>
        dispatch(
          setNotification({
            severity: SeverityMessage.ERROR,
            message: lang.quote.tyres.errorSelectingTyre,
          })
        )
      );
  };

  return (
    <div className={classes.action}>
      <div className={classes.quantity}>
        <button type="button" onClick={removeQuantity}>
          -
        </button>
        <p>{quantity}</p>
        <button type="button" onClick={addQuantity}>
          +
        </button>
      </div>
      <button
        className={classes.choose}
        type="button"
        onClick={handleTyreSelected}
      >
        {lang.quote.choose}
      </button>
    </div>
  );
};

export default TyreAction;

import React from 'react';

const BillingIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    <path
      fill="#AAAEC5"
      d="M13.553 11.109c.293-.293.768-.293 1.06 0l2.667 2.666c.025.025.048.051.069.079l.017.024c.015.022.03.045.043.069l.016.032c.012.023.022.048.031.073l.01.032c.008.023.014.047.02.072l.006.037c.005.036.008.074.008.113 0 .076-.011.15-.033.22l-.005.015c-.01.03-.021.06-.035.087l-.012.025c-.012.023-.025.045-.04.066-.027.042-.058.08-.095.117l.071-.082-.007.01-.064.072-2.666 2.667-.084.072c-.294.218-.71.194-.977-.072l-.073-.085c-.217-.293-.193-.71.073-.976l1.387-1.387H1.639c-.414 0-.75-.335-.75-.75 0-.38.282-.693.648-.743l.102-.006 13.301-.001-1.387-1.386-.073-.084c-.217-.294-.193-.71.073-.976zM3.947.219c.266.267.29.684.073.977l-.073.084-1.388 1.386h13.302c.414 0 .75.336.75.75 0 .38-.282.694-.648.744l-.102.007L2.56 4.166l1.388 1.387c.266.266.29.683.073.977l-.073.084c-.266.266-.683.29-.977.072l-.084-.072L.22 3.947l-.064-.072-.004-.006.068.078c-.038-.038-.071-.079-.1-.122l-.034-.06-.014-.026C.026 3.64 0 3.532 0 3.417c0-.039.003-.077.008-.113l.007-.036c.005-.025.011-.05.018-.073l.011-.032c.009-.025.02-.05.03-.073l.017-.032c.013-.024.028-.047.043-.07l.013-.018c.025-.033.048-.06.073-.084L2.886.22c.293-.293.768-.293 1.061 0z"
    />
  </svg>
);

export default BillingIcon;
import makeStyles from '@material-ui/core/styles/makeStyles';
import { range } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Button from '../../../../assets/generic-components/Button';
import CheckboxLabel from '../../../../assets/generic-components/CheckboxLabel';
import colors from '../../../../assets/style-colors';
import { saveSpecialDays } from '../../../../redux/stores/calendar/actions';
import {
  Availability,
  RegularWeek,
} from '../../../../redux/stores/calendar/types';
import { Store } from '../../../../redux/types';
import { getDateSelectionRange } from '../../../../utils/calendar';

// STYLES:
const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '5% 20% 10% 20%',
  },
  header: {
    fontSize: '20px',

    marginBottom: '26px',
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  form: {
    appearance: 'none',
    width: '130px',
    height: '40px',
    textAlign: 'center',
    backgroundColor: colors.white,
    border: '1px solid transparent',
    padding: '.375rem .75rem',
    fontSize: '13px',
    borderRadius: '4px',
  },
  formHours: {
    textAlign: 'left',
    width: '100%',
  },
  wrapFormGroup: {
    maxWidth: '350px',
  },
  spaceItem: {
    padding: '0 20px',
    lineHeight: '40px',
  },
  checkboxRight: {
    marginRight: '68px',
  },
  wrapTitle: {
    color: colors.slateGrey,
  },
}));

interface UpdateDayProps {
  pathUpdate: string;
}

const UpdateDay: React.FC<UpdateDayProps> = ({ pathUpdate }) => {
  const {
    languageReducer,
    calendarStore: { currentWeekAvailabilities, calendar },

    planningStore: { currentDay },

    globalReducer: { currentGarage },
  } = useSelector((state: Store) => state);

  if (!currentGarage) return null;

  const dispatch = useDispatch();
  const lg = languageReducer.language;
  const classes = useStyles();
  const history = useHistory();
  const { index } = useParams<{ index: string }>();
  const dayIndex: number = +index;

  const dateFormatted = moment(currentDay)
    .day(dayIndex + 1)
    .format('YYYY-MM-DD');
  const availableHours = getDateSelectionRange();

  // STATES:

  let currentDaySlot: RegularWeek | null = {
    slots: 0,
    openingHours: [],
  };
  const specialDay = currentWeekAvailabilities?.find(
    (day) => day.day === dateFormatted
  );
  if (specialDay) {
    currentDaySlot.openingHours = specialDay?.availability.openingHours;
    currentDaySlot.slots = specialDay?.availability.slots;
  } else {
    currentDaySlot = calendar?.regularWeek
      ? calendar?.regularWeek[dayIndex]
      : null;
  }

  const openingHours = currentDaySlot ? currentDaySlot.openingHours : null;
  const firstDate = openingHours && openingHours[0]?.split('-');
  const secondDate = openingHours && openingHours[1]?.split('-');

  const [showPause, setShowPause] = useState(
    openingHours?.length === 2 || false
  );
  const [startDate1, setStartDate1] = useState(
    (firstDate && firstDate.shift()) || availableHours[0]
  );
  const [endDate1, setEndDate1] = useState(
    (firstDate && firstDate.pop()) || availableHours[0]
  );
  const [startDate2, setStartDate2] = useState(
    (secondDate && secondDate.shift()) || availableHours[0]
  );
  const [endDate2, setEndDate2] = useState(
    (secondDate && secondDate.pop()) || availableHours[0]
  );

  const [hour, setHour] = useState(currentDaySlot?.slots || 1);

  const [error] = useState([false, false, false, false]);
  const [errorMessage, setErrorMessage] = useState('');
  const [text] = useState('none');

  const handleChanges = (e: any) => {
    e.preventDefault();
    const newOpeningHours = showPause
      ? [`${startDate1}-${endDate1}`, `${startDate2}-${endDate2}`]
      : [`${startDate1}-${endDate1}`];
    const availability: Availability = {
      day: dateFormatted,
      availability: {
        openingHours: newOpeningHours,
        slots: hour,
      },
    };
    dispatch(
      saveSpecialDays(
        currentGarage.id,
        [availability],
        () => history.push(pathUpdate),
        (err: Error) => setErrorMessage(err.message)
      )
    );
  };

  return (
    <Container className={classes.root}>
      <div className={`${classes.row} ${classes.header}`}>
        {`${lg.planning.weekdaysFull[dayIndex]} ${moment(currentDay)
          .weekday(dayIndex)
          .format('DD MMM')}`}
      </div>
      <div className={`${classes.row} ${classes.wrapTitle}`}>
        {lg.planning.modification.openingHours}
      </div>
      <div className={classes.row}>
        <div className={`${classes.row} ${classes.wrapFormGroup}`}>
          <Form.Group>
            <Form.Control
              className={classes.form}
              style={{
                borderBottom: error[0] ? `solid 1px ${colors.coral}` : '',
              }}
              as="select"
              value={startDate1}
              onChange={(e) => {
                setStartDate1(e.currentTarget.value);
              }}
            >
              {availableHours.map((h) => (
                <option key={h}>{h}</option>
              ))}
            </Form.Control>
          </Form.Group>
          <div className={classes.spaceItem}>-</div>
          <Form.Group style={{ display: 'flex' }}>
            <Form.Control
              className={classes.form}
              value={endDate1}
              onChange={(e: any) => {
                setEndDate1(e.currentTarget.value);
              }}
              style={{
                borderBottom: error[1] ? `solid 1px ${colors.coral}` : '',
              }}
              as="select"
            >
              {availableHours.map((h) => (
                <option key={h}>{h}</option>
              ))}
            </Form.Control>
          </Form.Group>

          {showPause && (
            <>
              <Form.Group style={{ display: 'flex' }}>
                <Form.Control
                  className={classes.form}
                  style={{
                    borderBottom: error[2] ? `solid 1px ${colors.coral}` : '',
                  }}
                  as="select"
                  value={startDate2}
                  onChange={(e) => {
                    setStartDate2(e.currentTarget.value);
                  }}
                >
                  {availableHours.map((h) => (
                    <option key={h}>{h}</option>
                  ))}
                </Form.Control>
              </Form.Group>
              <div className={classes.spaceItem} style={{ display: 'flex' }}>
                -
              </div>
              <Form.Group>
                <Form.Control
                  className={classes.form}
                  style={{
                    borderBottom: error[3] ? `solid 1px ${colors.coral}` : '',
                  }}
                  as="select"
                  value={endDate2}
                  onChange={(e) => {
                    setEndDate2(e.currentTarget.value);
                    // handleError(3, endDate2 > startDate2);
                  }}
                >
                  {availableHours.map((h) => (
                    <option key={h}>{h}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </>
          )}
        </div>
        <div className={classes.checkboxRight}>
          <CheckboxLabel
            checked={showPause}
            onchange={() => setShowPause(!showPause)}
            label={lg.planning.modification.pause}
          />
        </div>
      </div>
      <div className={classes.row}>
        <div>{lg.planning.modification.availableHours}</div>
      </div>
      <div className={classes.row}>
        <Form.Group className={classes.formHours}>
          <Form.Control
            className={`${classes.formHours} ${classes.form}`}
            as="select"
            defaultValue={hour}
            onChange={(e) => setHour(parseInt(e.currentTarget.value))}
          >
            {range(1, 51).map((h) => (
              <option key={h}>{h}</option>
            ))}
          </Form.Control>
        </Form.Group>
      </div>
      {errorMessage}
      <div className={classes.row}>
        <Button
          theme="dark"
          title={lg.planning.modification.saveButton}
          disabled={errorMessage !== ''}
          onClick={(e) => handleChanges(e)}
          type="submit"
        />
      </div>
      <div
        className={classes.row}
        style={{ display: text, color: colors.coral }}
      >
        {lg.planning.modification.errorHours}
      </div>
    </Container>
  );
};

export default UpdateDay;

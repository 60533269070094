import makeStyles from '@material-ui/core/styles/makeStyles';
import colors from '../../../../assets/style-colors';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flex: '100%',
    margin: '0 auto',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },

  content: {
    width: '530px',
    margin: '0 auto',
  },

  head: {
    display: 'flex',
    flex: '100%',
    fontSize: '15px',
    color: colors.CANCELLED,
    marginTop: '5px',
  },

  title: {
    display: 'flex',
    flex: '100%',
    fontSize: '12px',
    color: colors.slateGrey,
  },

  subtitle: {
    display: 'flex',
    flex: '100%',
    fontSize: '12px',
    color: colors.slateGrey,
    marginTop: '30px',
    fontWeight: 500,
  },

  hide: {
    display: 'none',
  },

  error: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '10px',
    color: 'red',
    fontWeight: 'bolder',
  },

  textarea: {
    background: colors.paleGrey,
    padding: '15px',
    borderRadius: '8px',
    border: '0px solid white',
    fontSize: '13px',
    color: colors.CANCELLED,
    whiteSpace: 'pre-wrap',
    maxWidth: '467px',
    lineHeight: '1.20',
  },

  block: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: 'white',
    border: `solid 1px ${colors.whiteLight}`,
    borderRadius: '8px',
    margin: '10px auto',

    '& .first, .second, .third': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '156px',
      height: '40px',
      backgroundColor: colors.whiteLight,
      fontSize: '13px',
      fontWeight: '500',
      textAlign: 'center',
      cursor: 'pointer',
      color: colors.CANCELLED,
    },

    '& .first': {
      borderRadius: '8px 0px 0px 8px',
    },

    '& .third': {
      borderRadius: '0 8px 8px 0',
    },

    '& .unfocusLeft, .unfocus': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '156px',
      height: '40px',
      fontSize: '13px',
      fontWeight: '500',
      textAlign: 'center',
      cursor: 'pointer',
      color: colors.lightGreyBlue,
    },

    '& .unfocus': {
      borderLeft: `solid 1px ${colors.whiteLight}`,
    },
  },

  count: {
    transform: 'translate(-45px, 168px)',
    position: 'relative',
    fontSize: '13px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.23',
    letterSpacing: 'normal',
    color: colors.slateGrey,
    bottom: '12px',
    right: '15px',
  },

  modal_header: {
    padding: '30px 30px 0px 30px',
    border: '0px solid white',
  },

  modal_body: {
    padding: '15px 30px 30px 30px',

    '& .alert': {
      padding: '10px 5px',
      margin: '5px 0',
      backgroundColor: colors.alertBcgColor,
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      gap: '10px',
    },
  },

  modal_footer: {
    background: colors.paleGrey,
    borderTop: `0px solid ${colors.paleGrey}`,
    padding: '20px',
  },

  imgPopup: {
    width: '17px',
    height: '17px',
    cursor: 'pointer',
  },
}));

export default useStyles;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import timeIcon from '../../../assets/style-elements/01-electrons-icon-time-big@3x.png';
import {
  updateOperationInBookingQuote,
  updateQuoteStatus,
  getBooking,
} from '../../../redux/stores/planning/actions';
import { Store } from '../../../redux/types';
import Issue from '../modal/issues';
import WebAppointment from '../modal/webAppointment';
import Table from './table';
import SettingButton from '../../../atomic/components/atoms/settingButton';
import {
  Booking,
  FixedPrice,
  Liquid,
  PartOperation,
  Quote,
  QuoteStatus,
  RepairTime,
} from '../../../redux/stores/planning/types';
import ReportedQuoteMessage from './reportedQuoteMessage';
import { BookingType, QuoteType } from '../../../redux/stores/quotes/types';
import { SeverityMessage } from '../../../redux/stores/global/types';
import { isQuoteOperationsHasOemParts } from '../../../services/quote/operation';
import BookingRequest from '../../../services/http/Booking';
import { setNotification } from '../../../redux/stores/global/actions';
import IconWarning from '../../../assets/style-elements/icon-warning';
import { updateQuoteValues } from '../../../redux/stores/quotes/actions';
import {
  confirmQuoteEvent,
  reportIssueEvent,
} from '../../../lib/events/booking';

interface BookingQuoteProps {
  quote: Quote;
  currentBooking: Booking;
  isUserGarageOwner: boolean;
  isQuoteValidated: boolean;
  isUserAdmin: boolean;
  isQuoteReported: boolean;
  isQuoteConfirmed: boolean;
  isNetworkIdGarageOwner: boolean;
}

const BookingQuote: React.FC<BookingQuoteProps> = ({
  quote,
  currentBooking,
  isUserAdmin,
  isQuoteValidated,
  isQuoteReported,
  isQuoteConfirmed,
  isUserGarageOwner,
  isNetworkIdGarageOwner,
}) => {
  const {
    languageReducer,
    globalReducer: { availableCarservices, currentGarage },
  } = useSelector((state: Store) => state);

  const dispatch = useDispatch();
  const lg = languageReducer.language;
  const [tooltipOpened, openTooltip] = useState<boolean>(false);
  const [adminEdit, setAdminEdit] = useState<boolean>(false);
  const [isSummaryUpdatable, setIsSummaryUpdatable] = useState(false);

  const displayError = (error: Error): void => {
    throw error;
  };

  const onOperationQtyDiscountUpdate = (
    operation: PartOperation | RepairTime | Liquid | FixedPrice
  ): void => {
    dispatch(updateOperationInBookingQuote(operation, quote));
  };

  const toggleEditQuoteMode = (): void => {
    setAdminEdit((oldAdminEdit) => !oldAdminEdit);
    openTooltip(false);
  };

  const updateReadyQuote = async (): Promise<void> => {
    if (!currentBooking.bookingId) {
      return;
    }
    await new BookingRequest()
      .updateBookingQuoteWithId(
        currentBooking.bookingId,
        updateQuoteValues(quote)
      )
      .then(() => {
        dispatch(
          setNotification({
            severity: SeverityMessage.SUCCESS,
            message: lg.general.success,
          })
        );
        if (currentBooking.bookingId) {
          dispatch(getBooking(currentBooking.bookingId));
          toggleEditQuoteMode();
        }
      })
      .catch(() =>
        dispatch(
          setNotification({
            severity: SeverityMessage.ERROR,
            message: lg.general.fatalError,
          })
        )
      );
  };

  const updateBookingQuote = (): void => {
    if (!currentBooking.bookingId) {
      return;
    }
    updateReadyQuote();
  };

  const IssueButton = () => {
    const userIsGarageOwnerAndQuoteValidated =
      isUserGarageOwner && isQuoteValidated;
    const userIsAdminAndQuoteNotReported = isUserAdmin && !isQuoteReported;
    const userIsNotNetworkIdGarageOwner = !isNetworkIdGarageOwner;

    if (userIsGarageOwnerAndQuoteValidated || userIsAdminAndQuoteNotReported) {
      if (userIsNotNetworkIdGarageOwner) {
        return (
          <Issue
            onSave={(message) => {
              if (currentBooking.bookingId) {
                dispatch(
                  updateQuoteStatus(
                    currentBooking.bookingId,
                    quote.quoteId,
                    QuoteStatus.REPORTED,
                    message,
                    () => {
                      reportIssueEvent({
                        garageId: currentGarage?.id || '',
                        garageName: currentGarage?.companyName || '',
                        quote_id: quote.quoteId,
                      });
                    },
                    displayError
                  )
                );
              }
            }}
          />
        );
      }
    }

    return null;
  };

  const WebAppointmentButton = () => {
    const userIsGarageOwnerAndQuoteValidated =
      isUserGarageOwner && isQuoteValidated;
    const userIsAdminAndQuoteNotConfirmed = isUserAdmin && !isQuoteConfirmed;
    const userIsNotNetworkIdGarageOwner = !isNetworkIdGarageOwner;

    if (
      (userIsGarageOwnerAndQuoteValidated || userIsAdminAndQuoteNotConfirmed) &&
      userIsNotNetworkIdGarageOwner
    ) {
      return (
        <WebAppointment
          onClick={() => {
            if (currentBooking.bookingId) {
              dispatch(
                updateQuoteStatus(
                  currentBooking.bookingId,
                  quote.quoteId,
                  QuoteStatus.CONFIRMED,
                  'CONFIRMED',
                  () => {
                    confirmQuoteEvent({
                      garageId: currentGarage?.id || '',
                      garageName: currentGarage?.companyName || '',
                      quote_id: quote.quoteId,
                    });
                  },
                  displayError
                )
              );
            }
          }}
        />
      );
    }

    return null;
  };

  return (
    <>
      <div style={{ width: '100%' }}>
        <div className="detail">
          <div className="block">
            <div className="devis">
              <span className="wrap">
                <span
                  className={`span1 ${
                    currentBooking.networkId === BookingType.GARAGE_OWNER
                      ? 'perso'
                      : ''
                  } ${quote.status}`}
                >
                  {`${lg.planning.quote} N° ${quote.quoteId}`}
                </span>
              </span>
              <span className="span2 car-service-label">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="car-service-icon h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z"
                  />
                </svg>

                {availableCarservices[quote.carServiceId]?.label ||
                  quote.carServiceId}
              </span>
            </div>
            <div className="noprint callToAction">
              <IssueButton />
              <WebAppointmentButton />
            </div>

            <div className="no-print">
              <SettingButton
                isTooltipOpened={tooltipOpened}
                quote={quote}
                currentBooking={currentBooking}
                currentGarage={currentGarage}
                adminEdit={adminEdit}
                toggleEditQuoteMode={() => {
                  setIsSummaryUpdatable(true);
                  toggleEditQuoteMode();
                }}
                isGarageOwner={isUserGarageOwner}
                isAdmin={isUserAdmin}
                isNetworkIdGarageOwner={isNetworkIdGarageOwner}
              />
            </div>
          </div>

          {isQuoteOperationsHasOemParts(quote.operations) ? (
            <div className="alert">
              <IconWarning className="" />
              <span id="textAlert">{lg.quote.attention}</span>
            </div>
          ) : null}
          {quote.status === QuoteStatus.REPORTED && (
            <ReportedQuoteMessage quoteId={quote.quoteId} />
          )}
          <div className="block">
            {quote.quoteType === QuoteType.ONLINE && (
              <Table
                quote={quote}
                vat={currentBooking.price.vat}
                onOperationQtyDiscountUpdate={
                  adminEdit ? onOperationQtyDiscountUpdate : undefined
                }
                updateBookingQuote={() => {
                  setIsSummaryUpdatable(false);
                  updateBookingQuote();
                }}
                cancelUpdateBookingQuote={() => {
                  setIsSummaryUpdatable(false);
                  toggleEditQuoteMode();
                }}
                isSummaryUpdatable={isSummaryUpdatable}
              />
            )}
            {quote.quoteType !== QuoteType.ONLINE && (
              <div className="without">
                <span>
                  <img alt="timeIcon" src={timeIcon} />
                </span>
                <span className="apart">{quote?.duration || 1}h</span>
              </div>
            )}
          </div>
          {quote.quoteType !== QuoteType.ONLINE && (
            <div className="block">
              <div className="note">
                <p className="titleNote">{lg.command.detail.nodeRDV}</p>
                <p className="textNote">{currentBooking.comment}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BookingQuote;

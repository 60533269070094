import { makeStyles } from '@material-ui/core';
import React from 'react';

type FullscreenMessageProps = {
  title?: string;
};

const FullscreenMessage: React.FC<FullscreenMessageProps> = ({
  title,
  children,
}) => {
  const useStyles = makeStyles(() => ({
    root: {
      display: 'block',
      width: '100vw',
    },
    block: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      flex: '100%',
      width: '100%',
      padding: '0 20%',
      textAlign: 'center',
    },
    title: {
      fontSize: '5rem',
    },
    message: {
      fontWeight: 100,
    },
  }));

  const styles = useStyles();
  return (
    <>
      <div className={styles.block}>
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.message}>{children}</div>
      </div>
    </>
  );
};

FullscreenMessage.defaultProps = {
  title: '',
};
export default FullscreenMessage;

import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useSelector } from 'react-redux';
import { Store } from '../../redux/types';
import colors from '../style-colors';
import useOutsideClick from '../../utils/hooks/useOutsideClick';
import fr from '../images/fr.png';
import nl from '../images/nl.png';
import arrow from '../style-elements/01-electrons-icon-arrow.png';
import Button from './Button';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    '& button': {
      width: '100%',
      height: '40px',
      marginRight: 0,
      color: colors.dark,
      boxShadow: 'none',
    },
  },

  buttonContainerClickStyle: {
    boxShadow: '0px 3px 8px rgba(77, 77, 77, 0.2);',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',

    '& button': {
      borderBottom: `1px solid ${colors.cloudWhite}`,
      borderBottomLeftRadius: '0',
      borderBottomRightRadius: '0',
      boxShadow: 'none',
    },
  },

  iconLanguage: {
    width: '18px',
    margin: '0 4px 0 15px',
  },

  buttonText: {
    color: colors.dark,
    fontSize: '12px',
    lineHeight: 'normal',
    paddingLeft: '10px',
  },

  buttonArrow: {
    marginLeft: '10px',
    transform: 'rotate(0deg)',
    transition: 'transform .25s ease-in-out',
  },

  buttonArrowTransform: {
    marginLeft: '10px',
    transform: 'rotate(180deg)',
    transition: 'transform .25s ease-in-out',
  },

  smallModal: {
    position: 'absolute',
    width: '100%',
    backgroundColor: colors.white,
    boxShadow: '0px 3px 8px rgba(77, 77, 77, 0.2);',

    '& button': {
      width: '100%',
    },
  },

  smallModalClickStyle: {
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',

    '& button': {
      boxShadow: 'none',
      borderRadius: 0,

      '&:nth-child(1)': {
        borderBottom: `1px solid ${colors.cloudWhite} !important`,
      },

      '&:nth-child(2)': {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
      },
    },
  },
}));

interface LanguageButtonProps {
  onFrClick: () => void;
  onNlClick: () => void;
  tablet: boolean;
}

const LanguageButton = ({
  onFrClick,
  onNlClick,
  tablet,
}: LanguageButtonProps) => {
  const {
    languageReducer: { language: lg, image },
  } = useSelector((state: Store) => state);

  const [isOpen, setIsOpen] = useState(false);

  const handleClickOutside = () => {
    setIsOpen(false);
  };

  const ref = useOutsideClick(handleClickOutside);

  const classes = useStyles();

  return (
    <div
      ref={ref}
      className={`${classes.buttonContainer} ${
        isOpen && classes.buttonContainerClickStyle
      }`}
    >
      <Button theme="light" onClick={() => setIsOpen((prev) => !prev)}>
        {!tablet && <span className={classes.buttonText}>{lg.lg}</span>}
        <img className={classes.iconLanguage} alt="..." src={image} />
        <img
          className={
            isOpen ? classes.buttonArrowTransform : classes.buttonArrow
          }
          alt="arrow"
          src={arrow}
        />
      </Button>
      {isOpen && (
        <div
          className={`${classes.smallModal} ${
            isOpen && classes.smallModalClickStyle
          }`}
        >
          <Button
            theme="light"
            onClick={() => {
              onFrClick();
              setIsOpen(false);
            }}
          >
            <div>Français</div>
            <img className={classes.iconLanguage} alt="" src={fr} />
          </Button>

          <Button
            theme="light"
            onClick={() => {
              onNlClick();
              setIsOpen(false);
            }}
          >
            <div>Nederlands</div>
            <img alt="" className={classes.iconLanguage} src={nl} />
          </Button>
        </div>
      )}
    </div>
  );
};

export default LanguageButton;

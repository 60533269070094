import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FieldError, NestDataObject } from 'react-hook-form';
import CheckBoxRounded from '../../../../../assets/generic-components/checkBoxRounded';
import { Store } from '../../../../../redux/types';
import InputLabel from '../../../../../assets/generic-components/InputLabel';
import { AccountDetailsFormValues } from '../AccountDetailsForm';
import InputPassword from '../../../../../assets/generic-components/InputPassword';

export interface AutossimoServicesProps {
  errors: NestDataObject<AccountDetailsFormValues, FieldError>;
  loginRef: React.LegacyRef<HTMLInputElement>;
  passwordRef: React.LegacyRef<HTMLInputElement>;
}

/**
 * Used to enable & update autossimo service details.
 */
const AutossimoServices = ({
  errors,
  loginRef,
  passwordRef,
}: AutossimoServicesProps) => {
  const {
    globalReducer: { currentGarage },
    languageReducer: { language: lg },
  } = useSelector((state: Store) => state);

  const autossimoGarage = currentGarage?.autossimo;

  const [isActive, setIsActive] = useState<boolean>(!!autossimoGarage);

  const [passwordValue, setPasswordValue] = useState(
    currentGarage?.autossimo?.password
  );

  return (
    <div>
      <CheckBoxRounded
        handleClick={() => setIsActive(!isActive)}
        isActive={isActive}
        label="Autossimo"
      />
      {isActive && (
        <>
          <p>{lg.account.autossimo}</p>
          <div>
            <InputLabel
              label={lg.account.autossimoLogin}
              name="login"
              type="text"
              defaultValue={currentGarage?.autossimo?.login}
              reference={loginRef}
            >
              {errors.login &&
                errors.login.type === 'required' &&
                lg.quote.messageField}
            </InputLabel>
            <InputPassword
              name="password"
              label={lg.account.autossimoPassword}
              theme="dark"
              reference={passwordRef}
              value={passwordValue}
              onChange={(e) => setPasswordValue(e.target.value)}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default AutossimoServices;

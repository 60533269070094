import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import HttpService from '../../../services/http-service';
import { Store } from '../../types';
import { BillingClaim, SearchBillingsClaim } from './type';

export const SET_BILLINGS_CLAIM = 'SET_BILLINGS_CLAIM';
export const BILLINGS_CLAIM_LOADING = 'BILLINGS_CLAIM_LOADING';

export type ActionTypes =
  | { type: typeof SET_BILLINGS_CLAIM; payload: BillingClaim[] }
  | { type: typeof BILLINGS_CLAIM_LOADING; payload: boolean };

export const billingsClaimLoading = (isLoading: boolean): ActionTypes => ({
  type: BILLINGS_CLAIM_LOADING,
  payload: isLoading,
});

export const setBillingsClaim = (billings: BillingClaim[]) => ({
  type: SET_BILLINGS_CLAIM,
  payload: billings,
});

// TODO
// Put a max pagination (in api)
// be able to search the direct value in the JSON (garageId, QuoteId, ...)
// If I put today's date it doesn't work (filter)
export const saveBillingClaim =
  (
    billingClaim: BillingClaim,
    success: () => void,
    failure: (e: Error) => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async () => {
    await HttpService.getClient()
      .post(`${process.env.REACT_APP_API_BILLING_CLAIM_URL}`, billingClaim, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'api-key': process.env.REACT_APP_API_BILLING_CLAIM_KEY,
        },
      })
      .then(() => {
        success();
      })
      .catch(failure);
  };

export const getBillingsClaim =
  (
    searchBillingsClaim: SearchBillingsClaim,
    failure: (e: Error) => void,
    success: () => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(billingsClaimLoading(true));
    await HttpService.getClient()
      .post(
        `${process.env.REACT_APP_API_BILLING_CLAIM_URL}/_search?limit=${searchBillingsClaim.limit}&bill_id=${searchBillingsClaim.bill_id}`,
        {},
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'api-key': process.env.REACT_APP_API_BILLING_CLAIM_KEY,
          },
        }
      )

      .then((res) => {
        dispatch(billingsClaimLoading(false));
        dispatch(setBillingsClaim(res.data.data));
        success();
        return res.data.data;
      })
      .catch(failure);
  };

import { sum } from 'lodash';
import {
  Quote,
  Operation,
  Booking,
  QuoteStatus,
} from '../redux/stores/planning/types';
import logoAd from '../assets/images/logo-ad-auto.png';
import logoIdg from '../assets/images/logo2mini.svg';
import logoPerso from '../assets/style-elements/01-electrons-icon-devis-big.png';
import { Networks } from '../redux/stores/global/types';
import logoCavallari from '../assets/images/booking-logos/cavallari-groupe.svg';
import logoMacif from '../assets/images/booking-logos/macif.svg';
import logoMaif from '../assets/images/booking-logos/maif.svg';
import logoAdPro from '../assets/images/booking-logos/ad-pro.svg';
import logoImaGroupe from '../assets/images/booking-logos/ima-groupe.svg';

interface BookingTotals {
  totalDiscountless: number;
  totalDiscountlessMax: number;
  totalDiscount: number;
  totalDiscountMax: number;
  totalWithDiscounts: number;
  totalWithDiscountsMax: number;
  totalVAT: number;
  totalVATMax: number;
  totalBillingPrice: number;
  totalBillingPriceMax: number;
  totalBillingPriceVat: number;
  totalBillingPriceVatMax: number;
  totalCoupon: number;
  totalCommission: number;
  totalCommissionGarage: number;
  totalCommissionGarageDebit: number;
  totalCommissionGarageCredit: number;
  totalTaxeIncludedForkMax: number;
  totalDiscountlessQuotes: number;
  totalDiscountQuotes: number;
}

export const getQuotesExceptCancelled = (quotes: Quote[]) =>
  quotes.filter((quote) => quote.status !== QuoteStatus.CANCELLED);

const extractCoupon = (quotes: Quote[]) =>
  sum(
    getQuotesExceptCancelled(quotes).map(
      (quote: Quote) => quote.coupon?.amount || 0
    )
  );

export const getAllOperations = (quotes: Quote[]) =>
  getQuotesExceptCancelled(quotes).flatMap((quote) => quote.operations) || [];

export const getSpecificCommission = (booking: Booking) =>
  booking.networkId !== Networks.AD && booking.networkId !== Networks.IDG
    ? booking.price.commission
    : null;

export const getQuoteMaxPriceWithTax = (quote: Quote, vat: number): number => {
  if (quote.totalMaxWithTax) {
    return quote.totalMaxWithTax || 0;
  }
  const maxPriceExcludingTax = quote.price.maxPriceExclTax || 0;
  return maxPriceExcludingTax * (1 + vat / 100);
};

export const getTotals = (
  quotes: Quote[],
  vat: number,
  addBookingCharge: boolean = false,
  specificCommission: number | null = null
): BookingTotals => {
  let packageDiscount = 0;
  quotes.forEach((quote: Quote) => {
    const isPackage = quote.operations.some(
      (operation: Operation) => operation.operationId === 'PACKAGE_DISCOUNT'
    );

    if (isPackage) {
      packageDiscount = quote.discountTotal;
    }
  });
  const aggregateByField = (field: string, fallbackField: string) =>
    getAllOperations(quotes || []).map((o: any) =>
      o ? o[field] || o[fallbackField] || 0 : 0
    );

  const totalDiscount = sum(aggregateByField('garageDiscountValue', ''));
  const totalDiscountMax = sum(
    aggregateByField('garageDiscountValueMax', 'garageDiscountValue')
  );
  const totalDiscountless = sum(aggregateByField('total', '')) + totalDiscount;
  const totalDiscountlessMax =
    sum(aggregateByField('totalMax', 'total')) + totalDiscount;
  const totalCommission =
    specificCommission || sum(quotes.map((q) => q.commission?.customer || 0));
  const totalCommissionGarage = sum(
    quotes.map((q) => q.commission?.garage || 0)
  );
  const totalCoupon = extractCoupon(quotes);
  const deltaCouponCommission = totalCommissionGarage + totalCoupon;

  const totalCommissionGarageCredit =
    deltaCouponCommission > 0
      ? totalCoupon
      : totalCoupon + Math.abs(deltaCouponCommission);
  const totalCommissionGarageDebit =
    deltaCouponCommission > 0 ? deltaCouponCommission : 0;

  const bookingCharge = addBookingCharge ? totalCommission : 0;
  const totalWithDiscounts = totalDiscountless - totalDiscount - bookingCharge;
  const totalWithDiscountsMax =
    totalDiscountlessMax - totalDiscountMax - bookingCharge;

  const totalBillingPrice = totalWithDiscounts - totalCoupon;
  const totalBillingPriceMax = totalWithDiscountsMax - totalCoupon;
  const totalVAT = totalBillingPrice * (vat / 100);
  const totalVATMax = totalBillingPriceMax * (vat / 100);
  const totalBillingPriceVat = totalBillingPrice + totalVAT;

  const totalBillingPriceVatMax = totalBillingPriceMax + totalVATMax;

  const totalDiscountlessQuotes = totalDiscountless - packageDiscount;
  const totalDiscountQuotes = totalDiscount - packageDiscount;

  const totalTaxeIncludedForkMax = sum(
    quotes.map((q) => getQuoteMaxPriceWithTax(q, vat))
  );

  return {
    totalDiscountless,
    totalDiscountlessMax,
    totalDiscount,
    totalDiscountMax,
    totalWithDiscounts,
    totalWithDiscountsMax,
    totalVAT,
    totalCommission,
    totalVATMax,
    totalBillingPrice,
    totalBillingPriceMax,
    totalBillingPriceVat,
    totalBillingPriceVatMax,
    totalCoupon,
    totalCommissionGarage,
    totalCommissionGarageDebit,
    totalCommissionGarageCredit,
    totalTaxeIncludedForkMax,
    totalDiscountlessQuotes,
    totalDiscountQuotes,
  };
};

export const getLogo = (networkId: string) => {
  switch (networkId) {
    case 'AD-AUTO':
      return logoAd;
    case 'IDG':
      return logoIdg;
    case 'AUTO-PRIMO':
      return `${process.env.REACT_APP_GOOGLE_STORAGE}/Medias/brands_logos/autoprimo.png`;
    case 'GO-MECANO':
      return `${process.env.REACT_APP_GOOGLE_STORAGE}/Medias/brands_logos/gomecano.png`;
    case 'NOMBLOT':
      return `${process.env.REACT_APP_GOOGLE_STORAGE}/Medias/brands_logos/nomblot.png`;
    case 'GARAGE-OWNER':
      return logoPerso;
    case 'CAVALLARI':
      return logoCavallari;
    case 'MACIF':
      return logoMacif;
    case 'MAIF':
      return logoMaif;
    case 'AD-PRO':
      return logoAdPro;
    case 'IMA':
      return logoImaGroupe;
    default:
      return '';
  }
};

enum CancelledQuoteType {
  CANCELLED = 'CANCELLED',
  WALLET = 'WALLET',
}

export const getDuration = (
  quote: Quote,
  withoutAnyRule: boolean = false
): number => {
  if (quote.status in CancelledQuoteType && !withoutAnyRule) {
    return 0;
  }
  const repairTimeOperations: number = quote.operations
    .filter((operation) => operation.operationType === 'repairTime')
    .reduce(
      (quoteSum, currentOperation: any) => quoteSum + currentOperation.duration,
      0
    );

  const result = quote.duration ?? repairTimeOperations;
  return Math.ceil(result);
};

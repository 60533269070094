import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import colors from '../../../assets/style-colors';
import {
  getBill,
  getBillings,
  setBill,
  setBillings,
} from '../../../redux/stores/billings/actions';
import { setNotification } from '../../../redux/stores/global/actions';
import { Store } from '../../../redux/types';
import { getBillingsClaim } from '../../../redux/stores/billing-claim/action';
import OneViewHeaderTitle from './oneViewHeaderTable';
import OneViewBillingList from './oneViewBillingList';
import { ViewsListEnum } from '../../../redux/stores/billings/type';
import {
  calculateTtc,
  calculateVat,
  createBillings,
  getHtBilling,
  getQuotesIdsOfClaims,
  sumBillingsHt,
} from '../../../services/billing/billing';
import OneViewTotalBill from './oneViewTotalBill';
import Download from '../pdf/download';
import { SeverityMessage } from '../../../redux/stores/global/types';

interface OneViewProps {
  title: string;
  view: ViewsListEnum;
  year: string;
  month: string;
  currentPath: string;
}

const OneView: React.FC<OneViewProps> = ({
  title,
  view,
  year,
  month,
  currentPath,
}) => {
  const {
    languageReducer: { language: lg },
    globalReducer: { currentGarage },
    billingsReducer: { currentBillings, currentBill },
    billingsClaimReducer: { currentBillingsClaim },
  } = useSelector((state: Store) => state);

  const [billingsLoaded, setBillingsLoaded] = useState(false);
  const [billLoaded, setBillLoaded] = useState(false);
  const [claimLoaded, setClaimLoaded] = useState(false);
  const [totalHt, setTotalHt] = useState<number>(0);
  const [ttc, setTtc] = useState<number>(0);
  const [tva, setTva] = useState<number>(0);
  const [quoteIds, setQuoteIds] = useState<string[]>([]);

  const dispatch = useDispatch();

  if (!currentGarage) return null;

  useEffect(() => {
    dispatch(setBill(null));
    dispatch(setBillings([]));
    setClaimLoaded(false);
    setBillLoaded(false);
    setBillingsLoaded(false);
  }, [currentPath, month, year]);

  useEffect(() => {
    dispatch(
      getBill(
        currentGarage.id,
        view,
        `${year}-${month}`,
        (error: Error) => {
          dispatch(
            setNotification({
              severity: SeverityMessage.ERROR,
              message: error.message,
            })
          );
        },
        () => setBillLoaded(true)
      )
    );
  }, [year, month]);

  useEffect(() => {
    if (view === ViewsListEnum.COMMISSION || view === ViewsListEnum.VOUCHER) {
      dispatch(
        getBillings(
          currentGarage.id,
          view,
          `${year}-${month}`,
          (error: Error) => {
            dispatch(
              setNotification({
                severity: SeverityMessage.ERROR,
                message: error.message,
              })
            );
          },
          () => setBillingsLoaded(true)
        )
      );
    }
  }, [year, month]);
  useEffect(() => {
    if (
      billLoaded &&
      currentBill &&
      (view === ViewsListEnum.ADDITIONAL_VOUCHER ||
        view === ViewsListEnum.SUBSCRIPTION)
    ) {
      dispatch(
        setBillings(
          createBillings(
            currentBill.id,
            lg.billingHeader[view],
            view,
            getHtBilling(currentBill.amount, view)
          )
        )
      );
      setBillingsLoaded(true);
    }
  }, [year, month, billLoaded, view]);

  useEffect(() => {
    if (billingsLoaded && billLoaded && currentBill) {
      const claims = getBillingsClaim(
        {
          bill_id: currentBill.billNumber,
          limit: currentBillings.length,
        },
        (error: Error) => {
          dispatch(
            setNotification({
              severity: SeverityMessage.ERROR,
              message: error.message,
            })
          );
        },
        () => {
          setClaimLoaded(true);
        }
      );

      dispatch(claims);
      setBillingsLoaded(false);
      setBillLoaded(false);
    }
  }, [billingsLoaded, billLoaded]);

  useEffect(() => {
    if (billingsLoaded && billLoaded && currentBill) {
      const ht = sumBillingsHt(currentBillings);

      setTotalHt(ht);
      setTva(calculateVat(ht));
      setTtc(calculateTtc(currentBill.amount, view, ht));
    }
  }, [billingsLoaded, billLoaded]);

  useEffect(() => {
    setQuoteIds(getQuotesIdsOfClaims(currentBillingsClaim));
  }, [currentBillingsClaim]);

  // STYLES:
  const useStyles = makeStyles(() => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      margin: '0px 19px',
      overflow: 'hidden',
      '& .block': {
        display: 'flex',
        flex: '100%',
        background: 'white',
        marginBottom: '10px',
      },
      '& .table': {
        color: colors.dark,
        fontSize: '11px',

        marginTop: '10px',
      },
      '& .tr_head': {
        color: colors.dark,
        display: 'flex',
        borderBottom: '1px solid #eaebf1',
      },
      '& .tr': {
        width: '100%',
        display: 'flex',
        flexFlow: 'row nowrap',
        borderBottom: '1px solid #eaebf1',
      },
      '& .td_tiny_head_start': {
        display: 'flex',
        flexFlow: 'row nowrap',
        flexGrow: 1,
        flexBasis: '0',
        padding: '13px 5px',
        wordBreak: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        minWidth: '0px',
        whiteSpace: 'normal',
        textAlign: 'left',
        '& b': {
          fontWeight: 'normal',
        },
      },
      '& .td_tiny_head_end': {
        display: 'flex',
        flexFlow: 'row nowrap',
        flexGrow: 1,
        flexBasis: '0',
        padding: '13px 5px',
        wordBreak: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        minWidth: '0px',
        whiteSpace: 'normal',
        textAlign: 'left',
        justifyContent: 'flex-start',

        '& b': {
          fontWeight: 'normal',
        },
      },
      '& .td_tiny_border_start': {
        display: 'flex',
        flexFlow: 'row',
        flexGrow: 1,
        flexBasis: '0',
        padding: '13px 5px',
        wordBreak: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        minWidth: '0px',
        whiteSpace: 'normal',
        textAlign: 'left',

        '& span': {
          fontWeight: 'normal!important',
        },
      },
      '& .td_tiny_border_end': {
        display: 'flex',
        flexFlow: 'row nowrap',
        flexGrow: 1,
        flexBasis: '0',
        padding: '13px 5px',
        wordBreak: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        minWidth: '0px',
        whiteSpace: 'normal',
        textAlign: 'left',
        alignItems: 'center',
      },
      '& .td_tiny_withoutBorder_start': {
        display: 'flex',
        flexFlow: 'row',
        flexGrow: 1,
        flexBasis: '0',
        padding: '13px 5px',
        wordBreak: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        minWidth: '0px',
        whiteSpace: 'normal',
        textAlign: 'left',
      },
      '& .td_tiny_withoutBorder_end': {
        display: 'flex',
        flexFlow: 'row nowrap',
        flexGrow: 1,
        flexBasis: '0',
        padding: '0.7em',
        wordBreak: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        minWidth: '0px',
        whiteSpace: 'normal',
        textAlign: 'left',
        justifyContent: 'flex-start',
      },
      '& .td_tiny_total_end': {
        display: 'flex',
        flexFlow: 'row nowrap',
        flexGrow: 2,
        flexBasis: '0',
        padding: '0.7em',

        minWidth: '0px',
        whiteSpace: 'normal',
        justifyContent: 'flex-start',
      },
    },
    contextFalse: {
      display: 'none',
      position: 'absolute',
      transform: 'translate(0px, 0px);',
      background: colors.white,
      padding: '10px 10px',
      borderRadius: '8px',
      boxShadow: '2px 2px 2px silver',
      border: 'solid 1px #f6f7fb',
    },
    contextTrue: {
      fontWeight: 'bold',
    },
    context: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      padding: '10px',
      '& img': {
        width: '15px',
        height: '15px',
        marginRight: '10px',
      },
      '& span': {
        fontSize: '12px',
      },
    },
    wrap: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: '24px',
    },
    left: {
      flexGrow: 1,
    },
    grow4: {
      flexGrow: 4,
    },
    startSpan: {
      fontWeight: 'bold',
    },
    description: {
      maxWidth: '500px',
    },
    alignCenter: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    detail: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      background: colors.white,
      padding: '25px 20px 30px',
      marginBottom: '25px',
      borderRadius: '2px',
      boxShadow: '0 2px 18px 0 rgba(136, 135, 135, 0.17)',
    },
    bloc: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    title: {
      display: 'flex',
      fontSize: '20px',
      color: colors.dark,
    },
    right: {
      display: 'flex',
      flexDirection: 'column',
      width: '315px',
      flexWrap: 'wrap',
      marginLeft: '20px',
    },
    pdf: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: colors.white,
      borderRadius: '8px',
      padding: '9px 0px',
      marginBottom: '15px',
      cursor: 'pointer',
      boxShadow: '0 2px 18px 0 rgba(214, 214, 214, 0.5)',
      '&:hover': {
        textDecoration: 'none',
      },
      '& img': {
        width: '16px',
        height: '16px',
        marginRight: '9px',
      },
      '& span': {
        display: 'flex',

        fontSize: '12px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: colors.dark,
        '&:hover': {
          textDecoration: 'none',
        },
      },
    },
    valid: {
      backgroundColor: colors.primary,
    },
    toValidate: {
      backgroundColor: colors.azure,
    },
    problems: {
      backgroundColor: colors.brightOrange,
    },
    canceled: {
      backgroundColor: colors.dark,
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {currentBillings.length !== 0 ? (
        <div className={classes.wrap}>
          <div className={classes.left}>
            <div className={classes.detail}>
              <>
                <div className={classes.bloc}>
                  <span className={classes.title}>
                    {`${title} N°${currentBill?.billNumber}`}
                  </span>
                </div>
                <div className="table">
                  <div className="tr_head">
                    <OneViewHeaderTitle className="td_tiny_head_start">
                      {lg.billing.orderRef}
                    </OneViewHeaderTitle>
                    <OneViewHeaderTitle className="td_tiny_head_end">
                      {lg.billing.designation}
                    </OneViewHeaderTitle>
                    <OneViewHeaderTitle className="td_tiny_head_end">
                      {lg.billing.amount}
                    </OneViewHeaderTitle>
                    <OneViewHeaderTitle className="td_tiny_head_end">
                      <></>
                    </OneViewHeaderTitle>
                  </div>

                  {claimLoaded ? (
                    currentBillings.map((billing) => (
                      <OneViewBillingList
                        key={billing.id}
                        billing={billing}
                        view={view}
                        isContested={quoteIds.includes(billing.quoteId)}
                        classes={classes}
                      />
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              </>
            </div>
          </div>
          <div className={classes.right}>
            <Download
              view={view}
              garageId={currentGarage.id}
              month={month}
              year={year}
            />

            <OneViewTotalBill
              month={Number(month)}
              year={Number(year)}
              totalHt={totalHt}
              tva={tva}
              ttc={ttc}
            />
          </div>
        </div>
      ) : (
        <div className={classes.wrap}>
          <div className={classes.left}>
            <p>{lg.billing.empty}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default OneView;

import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import _chunk from 'lodash/chunk';
import { useHistory } from 'react-router-dom';
import Button from '../../../../assets/generic-components/Button';
import { saveSpecialDays } from '../../../../redux/stores/calendar/actions';
import { setNotification } from '../../../../redux/stores/global/actions';
import { Range, SeverityMessage } from '../../../../redux/stores/global/types';
import { Store } from '../../../../redux/types';
import { holidays2SpecialDays } from '../../../../utils/calendar';
import Calendar from '../month-view-right/calendar';
import useStyles from './congesStyles';

import { setCurrentDay } from '../../../../redux/stores/planning/actions';

interface UpdateHolidaysProps {
  pathUpdate: string;
}

const UpdateHolidays: React.FC<UpdateHolidaysProps> = ({ pathUpdate }) => {
  // CONSTANTS:
  const {
    languageReducer,
    planningReducer,
    globalReducer: { currentGarage },
  } = useSelector((state: Store) => state);

  if (!currentGarage) return null;

  const { modificationConges } = planningReducer;
  const lg = languageReducer.language;
  const noModif = modificationConges === null;
  const classes = useStyles();

  // STATES:
  type Holiday = {
    dates: Range<Moment>;
    display: Range<boolean>;
    error: string;
  };
  const holidaySizeQuery = 10;

  const initialState: Holiday = {
    dates: { min: moment(), max: moment().add(1, 'days') },
    display: { min: false, max: false },
    error: '',
  };

  const [holidays, setHolidays] = useState<Holiday[]>([initialState]);
  const [width, setWidth] = useState(window.innerWidth);
  const modeTablet = width <= 1024;
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
  }, []);

  const history = useHistory();
  const dispatch = useDispatch();

  const handleChanges = () => {
    const dateRanges = holidays.map((h) => h.dates);
    const holidayDates = dateRanges.flatMap(holidays2SpecialDays);

    // TODO replace with saveHolidays when api is ready
    const holidayPromises = _chunk(holidayDates, holidaySizeQuery).map(
      (dates) =>
        new Promise((resolve, reject) => {
          dispatch(
            saveSpecialDays(
              currentGarage.id,
              dates,
              () => {
                resolve(dates);
              },
              (error: Error) => {
                reject(error);
              }
            )
          );
        })
    );

    Promise.all(holidayPromises).then(
      () => {
        dispatch(setCurrentDay(dateRanges[0].max.toISOString()));
        history.push(pathUpdate);
      },
      (error) => {
        dispatch(
          setNotification({
            severity: SeverityMessage.ERROR,
            message: error.message,
          })
        );
      }
    );
  };

  function updateArrayState<T>(arrayToUpdate: T[], newValue: T, index: number) {
    return [
      ...arrayToUpdate.slice(0, index),
      newValue,
      ...arrayToUpdate.slice(index + 1),
    ];
  }

  function removeIndex<T>(arrayToUpdate: T[], index: number) {
    return [
      ...arrayToUpdate.slice(0, index),
      ...arrayToUpdate.slice(index + 1),
    ];
  }
  // HTML:

  return (
    <Container className={classes.root}>
      <div className={`${classes.row} ${classes.header}`}>
        {noModif
          ? lg.planning.menuRight.button
          : lg.planning.modification.congesModif}
      </div>
      <div className={classes.row}>{lg.planning.modification.congesPeriod}</div>
      <div className={classes.row}>
        {holidays.map(({ dates, display, error }, index) => (
          <React.Fragment key={index}>
            <div className={classes.row}>
              <div
                className={`${classes.form} ${classes.formDate} ${classes.fields}`}
              >
                <span
                  role="button"
                  onClick={() =>
                    setHolidays(
                      updateArrayState(
                        holidays,
                        {
                          dates,
                          error,
                          display: { ...display, min: !display.min },
                        },
                        index
                      )
                    )
                  }
                  tabIndex={0}
                >
                  {dates.min.format(modeTablet ? 'DD/MM/YY' : 'DD MMM YYYY')}
                </span>
                {display.min && (
                  <div
                    className={classes.calendar}
                    onMouseLeave={() =>
                      setHolidays(
                        updateArrayState(
                          holidays,
                          {
                            dates,
                            error,
                            display: { ...display, min: !display.min },
                          },
                          index
                        )
                      )
                    }
                  >
                    <Calendar
                      initialDay={dates.min.toISOString()}
                      onSelect={(min: Moment) => {
                        setHolidays(
                          updateArrayState(
                            holidays,
                            {
                              dates: {
                                ...dates,
                                min,
                                max: min.isAfter(dates.max) ? min : dates.max,
                              },
                              error: min.isAfter(dates.max)
                                ? lg.planning.menuRight.congesError
                                : '',
                              display: { ...display, min: false },
                            },
                            index
                          )
                        );
                      }}
                    />
                  </div>
                )}
              </div>

              <div className={classes.spaceItem}>-</div>

              <div
                className={`${classes.form} ${classes.formDate} ${classes.fields}`}
              >
                <span
                  role="button"
                  onClick={() =>
                    setHolidays(
                      updateArrayState(
                        holidays,
                        {
                          dates,
                          error,
                          display: { ...display, max: !display.max },
                        },
                        index
                      )
                    )
                  }
                  tabIndex={0}
                >
                  {dates.max.format(modeTablet ? 'DD/MM/YY' : 'DD MMM YYYY')}
                </span>
                {display.max && (
                  <div
                    className={classes.calendar}
                    onMouseLeave={() =>
                      setHolidays(
                        updateArrayState(
                          holidays,
                          {
                            dates,
                            error,
                            display: { ...display, max: !display.max },
                          },
                          index
                        )
                      )
                    }
                  >
                    <Calendar
                      initialDay={dates.max.toISOString()}
                      onSelect={(max: Moment) => {
                        setHolidays(
                          updateArrayState(
                            holidays,
                            {
                              dates: { ...dates, max },
                              error: max.isBefore(dates.min)
                                ? lg.planning.menuRight.congesError
                                : '',
                              display: { ...display, max: false },
                            },
                            index
                          )
                        );
                      }}
                    />
                  </div>
                )}
              </div>
            </div>

            {holidays.length > 1 && (
              <div className={classes.row}>
                <button
                  className={classes.link}
                  type="button"
                  onClick={() => setHolidays(removeIndex(holidays, index))}
                >
                  <span className={classes.textButtonEdit}>X</span>
                  {lg.planning.modification.delItem}
                </button>
              </div>
            )}
            <div
              className={`${classes.row} ${
                dates.max.isBefore(dates.min) ? classes.error : null
              }`}
            >
              {error}
            </div>
          </React.Fragment>
        ))}
      </div>

      <div className={classes.row}>
        <Button
          theme="dark"
          disabled={holidays.some((h) => h.error !== '')}
          title={lg.planning.modification.saveButton}
          onClick={() => handleChanges()}
          type="submit"
        />
      </div>
    </Container>
  );
};

export default UpdateHolidays;

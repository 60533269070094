import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import _get from 'lodash/get';
import colors from '../../../assets/style-colors';
import dropdownIcon from '../../../assets/style-elements/01-electrons-icon-dropdown@3x.png';
import commentIcon from '../../../assets/style-elements/01-electrons-icon-comment-orange@3x.png';
import { MyIdgQuestions, Question } from '../../../redux/stores/help/types';
import { Store } from '../../../redux/types';

interface CategoryViewProps {
  category: MyIdgQuestions;
  activeQuestionId: string;
  setActiveQuestionId: (id: string) => void;
}

const CategoryView: React.FC<CategoryViewProps> = ({
  category,
  activeQuestionId,
  setActiveQuestionId,
}) => {
  const languageReducer = useSelector((state: Store) => state.languageReducer);
  const lg = languageReducer.language;
  const history = useHistory();

  const useStyles = makeStyles(() => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      color: 'darkgrey',
      overflow: 'hidden',
    },
    contain: {
      display: 'flex',
      flex: '100%',
      flexDirection: 'column',
      margin: '25px 19px',
    },
    title: {
      fontSize: '20px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: colors.dark,
    },
    all: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '20px',
    },
    left: {
      display: 'flex',
      flex: '70%',
      margin: '0px 19px',
      boxShadow: '0 2px 18px 0 rgba(136, 135, 135, 0.17)',
    },
    detail: {
      display: 'flex',
      flexDirection: 'column',
      flex: '100%',
      padding: '0 19px 47px 19px',
      background: colors.white,
      borderRadius: '2px',
    },
    questions: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      flex: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: '14px',
      paddingBottom: '14px',
      borderBottom: '1px solid #eaebf1',
      cursor: 'pointer',
    },
    question: {
      display: 'flex',

      fontSize: '14px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.29',
      letterSpacing: 'normal',
      color: colors.dark,
    },
    dropdown: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '30px',
      height: '30px',
      border: '1px solid #e4e7f0',
      borderRadius: '5px',
      cursor: 'pointer',
    },
    show: {
      flex: '100%',

      fontSize: '13px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.38',
      letterSpacing: 'normal',
    },
    hide: {
      display: 'none',

      fontSize: '13px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.38',
      letterSpacing: 'normal',
    },
    right: {
      display: 'flex',
      flex: '30%',
    },
    in: {
      display: 'flex',
      flex: '100%',
    },
    arround: {
      display: 'flex',
      marginRight: '19px',
    },
    contact: {
      display: 'flex',
      flexDirection: 'column',
      padding: '30px 30px',
      background: colors.white,
      height: '150px',
      boxShadow: '0 2px 18px 0 rgba(136, 135, 135, 0.17)',
    },
    center: {
      display: 'flex',
      flex: '100%',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    image: {
      width: '18px',
      height: '18px',
    },
    comment: {
      fontSize: '14px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.57',
      letterSpacing: 'normal',
      color: colors.dark,
      paddingLeft: '15px',
    },
    subtitle: {
      fontSize: '14px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.57',
      letterSpacing: 'normal',
      paddingLeft: '33px',
      color: colors.primary,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    arrow: {
      width: '12px',
      height: '12px',
    },
    arrowActive: {
      width: '12px',
      height: '12px',
      transform: 'rotate(180deg)',
    },
  }));

  const classes = useStyles();

  const handleContact = () => {
    history.push('/contact');
  };

  return (
    <div className={classes.root}>
      <div className={classes.contain}>
        <span className={classes.title}>{category.categoryLabel}</span>
      </div>
      <div className={classes.all}>
        <div className={classes.left}>
          <div className={classes.detail}>
            {_get(category, 'faq.question', []).map((faqItem: Question) => (
              <div
                key={faqItem.id}
                className={classes.questions}
                onKeyPress={() => {}}
                role="button"
                tabIndex={0}
                onClick={() =>
                  faqItem.id === activeQuestionId
                    ? setActiveQuestionId('')
                    : setActiveQuestionId(faqItem.id)
                }
              >
                <span className={classes.question}>
                  <ReactMarkdown>{faqItem.question}</ReactMarkdown>
                </span>
                <span className={classes.dropdown}>
                  {faqItem.id === activeQuestionId ? (
                    <img
                      className={classes.arrowActive}
                      src={dropdownIcon}
                      alt="dropdownIcon"
                    />
                  ) : (
                    <img
                      className={classes.arrow}
                      src={dropdownIcon}
                      alt="dropdownIcon"
                    />
                  )}
                </span>
                <span
                  className={
                    faqItem.id === activeQuestionId
                      ? classes.show
                      : classes.hide
                  }
                  onKeyPress={() => {}}
                  role="button"
                  tabIndex={0}
                  onClick={() => {}}
                >
                  <ReactMarkdown>{faqItem.answer}</ReactMarkdown>
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className={classes.right}>
          <div className={classes.in}>
            <div className={classes.arround}>
              <div className={classes.contact}>
                <div className={classes.center}>
                  <img
                    className={classes.image}
                    src={commentIcon}
                    alt="commentIcon"
                  />
                  <span className={classes.comment}>
                    {lg.help.piece.answer}
                  </span>
                </div>
                <span
                  className={classes.subtitle}
                  onKeyPress={() => {}}
                  role="button"
                  tabIndex={0}
                  onClick={handleContact}
                >
                  {lg.help.piece.contact}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryView;

import HttpService, { PaginationDetails } from '../http-service';
import { Quote } from '../../redux/stores/planning/types';
import { TYRES_SERVICE_ID } from '../tyres';
import { CarService, Garage, Networks } from '../../redux/stores/global/types';

export interface ITyre {
  id: string;
  ean: {
    ean: string;
  };
  code_supplier: string;
  width: number;
  aspect: number;
  rim: number;
  load: number;
  speed: string;
  type: string;
  brand: string;
  season: string;
  designation: string;
  category_supplier: string;
  price_public: number;
  price: number;
  runflat: boolean;
  consolidated: boolean;
  image: string;
}

interface TyreResponse {
  tyres: Array<ITyre>;
  paginationDetails: PaginationDetails | null;
}

enum NetworksProviders {
  NOMBLOT = 'NOMBLOT',
  HESS = 'HESS',
}

export const getProviderForGarageNetworks = (garage: Garage): string => {
  const { garageNetwork } = garage;
  if (
    garageNetwork.some(
      (network) => network.networkId === NetworksProviders.NOMBLOT
    )
  ) {
    return 'nomblot';
  }
  if (
    garageNetwork.some(
      (network) => network.networkId === NetworksProviders.HESS
    )
  ) {
    return 'hess';
  }

  return '';
};

export const getAllCarServicesWithoutTyres = (
  selectedCarServices: Record<string, CarService>
) =>
  Object.values(selectedCarServices)
    .filter((carService) => carService.id !== TYRES_SERVICE_ID)
    .map((s) => s.id);

export const getQuoteForTyre = (
  tyre: ITyre,
  garage: Garage,
  quantity: string,
  vehicleId: string
): Promise<Quote> =>
  HttpService.getClient()
    .get(
      `${process.env.REACT_APP_API_TYRES_PRICING_URL}/garagestyres/quote/${garage.id}/${tyre.id}/${quantity}/${vehicleId}`,
      {
        headers: {
          'x-idg-apikey': process.env.REACT_APP_API_TYRES_PRICING_KEY,
          'x-network-id': Networks.IDG,
          countryCode: garage.favoriteLanguage || 'fr',
          'x-provider': getProviderForGarageNetworks(garage),
        },
      }
    )
    .then((response) => response?.data?.data[0] || {});

export const getTyresBrands = (): Promise<Array<string>> =>
  HttpService.getClient()
    .get(
      `${process.env.REACT_APP_API_TYRES_URL}/carservices/tyres/aggregate/brand`,
      {
        headers: {
          'x-idg-apikey': process.env.REACT_APP_API_TYRES_KEY,
          'x-network-id': Networks.IDG,
          countryCode: 'fr',
        },
      }
    )
    .then((response) => response.data.filter((brand: string) => brand));

export const getTyresByOptions = (
  options: Record<string, any>,
  garage: Garage,
  offset: number = 0
): Promise<TyreResponse> =>
  HttpService.getClient()
    .get(`${process.env.REACT_APP_API_TYRES_URL}/carservices/tyres`, {
      params: {
        season: options.seasons?.join(' ') || undefined,
        brand:
          options.tyresBrands.length > 0
            ? options.tyresBrands.join(' ')
            : undefined,
        runflat: options.runflat ? true : undefined,
        consolidated: options.consolidated ? true : undefined,
        width: options.width,
        aspect: options.aspect,
        load: options.load,
        rim: options.rim,
        speed: options.speed,
        type: options.type === 'Tous' ? undefined : options.type,
        sort: 'price',
        sortOrd: options.order || undefined,
        category_supplier:
          options.tyresQualityType.length > 0
            ? options.tyresQualityType.join(' ')
            : undefined,
      },
      headers: {
        'x-idg-apikey': process.env.REACT_APP_API_TYRES_KEY,
        'x-network-id': Networks.IDG,
        countryCode: garage.favoriteLanguage || 'fr',
        'x-provider': getProviderForGarageNetworks(garage),
        range: `unit ${offset}-20`,
      },
    })
    .then((tyresResponse) => ({
      tyres: tyresResponse.data,
      paginationDetails: HttpService.getPaginationDetailsQuery(tyresResponse),
    }));

import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import tyreImg from '../../../../assets/images/tyre.png';
import colors from '../../../../assets/style-colors';
import TyreAction from './tyre-action';
import allSeasons from '../../../../assets/images/tyres/all-seasons.svg';
import budget from '../../../../assets/images/tyres/budget.svg';
import premium from '../../../../assets/images/tyres/premium.svg';
import quality from '../../../../assets/images/tyres/quality.svg';
import summer from '../../../../assets/images/tyres/summer.svg';
import { ITyre } from '../../../../services/quote/tyres';
import winter from '../../../../assets/images/tyres/winter.svg';
import { TyresQuality, TyresSeasons } from '../../../../services/tyres';
import { Store } from '../../../../redux/types';

const useStyle = makeStyles({
  tyre: {
    width: '100%',
    display: 'flex',
    backgroundColor: colors.white,
    padding: '15px',
    borderRadius: '4px',
  },
  image: {
    marginRight: '30px',
    flex: '10% 0 0',

    '& img': {
      objectFit: 'contain',
      height: '100%',
    },
  },
  content: {
    marginRight: '40px',
    flex: '50% 0 0',

    '& > div:not(:last-child)': {
      borderBottom: `1px solid ${colors.WhiteDark}`,
    },
    '& h3': {
      textTransform: 'uppercase',

      fontWeight: 'bold',
      fontSize: '20px',
      paddingBottom: '5px',
    },
  },
  tyreDetails: {
    paddingTop: '10px',

    '& h4': {
      fontSize: '16px',

      fontWeight: 500,
      lineHeight: '1.5',
    },
    '& p': {
      fontSize: '10px',

      fontWeight: 400,
      padding: '5px 0',
    },
  },
  types: {
    display: 'flex',
    gap: '20px',
    marginTop: '10px',

    '& img': {
      marginRight: '5px',
    },

    '& p': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 0,
    },
  },
  rightPart: {},
  price: {
    display: 'flex',
    justifyContent: 'flex-end',

    '& > div': {
      paddingRight: '15px',

      lineHeight: '1.6',
      marginBottom: '10px',
    },

    '& h5': {
      fontSize: '10px',
      fontWeight: 'normal',
      marginBottom: 0,
    },
    '& p': {
      fontSize: '16px',
    },
  },
});

interface TyreProps {
  tyre: ITyre;
}

const Tyre: React.FC<TyreProps> = ({ tyre }) => {
  const lang = useSelector((state: Store) => state.languageReducer.language);
  const classes = useStyle();

  const getImageForSeason = (): string | undefined => {
    switch (tyre.season) {
      case TyresSeasons.SUMMER:
        return summer;
      case TyresSeasons.WINTER:
        return winter;
      case TyresSeasons.ALL_SEASONS:
        return allSeasons;
      default:
        return undefined;
    }
  };

  const getImageForQuality = (): string | undefined => {
    switch (tyre.category_supplier) {
      case TyresQuality.PREMIUM:
        return premium;
      case TyresQuality.QUALITY:
        return quality;
      case TyresQuality.BUDGET:
        return budget;
      default:
        return undefined;
    }
  };

  return (
    <div className={classes.tyre}>
      <div className={classes.image}>
        <img src={tyreImg} alt="Tyre" />
      </div>
      <div className={classes.content}>
        <div>
          <h3>{tyre.brand}</h3>
        </div>
        <div className={classes.tyreDetails}>
          <h4>{tyre.designation}</h4>
          <p>
            {tyre.consolidated && 'Consolidé '}
            {tyre.runflat && 'Renforcé'}
          </p>
        </div>
        <div className={classes.types}>
          <p>
            {getImageForSeason() && (
              <>
                <img src={getImageForSeason()} alt="tyre season" />
                {lang.quote.tyre} {tyre.season.toLowerCase()}
              </>
            )}
          </p>
          <p>
            {getImageForQuality() && (
              <>
                <img src={getImageForQuality()} alt="tyre category" />
                {tyre.category_supplier}
              </>
            )}
          </p>
        </div>
      </div>
      <div className={classes.rightPart}>
        <div className={classes.price}>
          <div>
            <h5>{lang.quote.buyPrice}</h5>
            <p>{tyre.price} €</p>
          </div>
        </div>
        <TyreAction tyre={tyre} />
      </div>
    </div>
  );
};

export default Tyre;

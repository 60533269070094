export interface BillingAddres {
  street1?: any;
  street2?: any;
  postalCode?: any;
  city?: any;
  country?: any;
}

export interface Customer {
  customerId?: string | null;
  gender?: string | null;

  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  billingAddress?: BillingAddres;
}

export interface BookingVehicle {
  vehicleId: string;

  registrationNumber?: string | undefined | null;
  kilometers?: number;
  vinCode?: string | undefined | null;
}

export interface Operation {
  operationId: string;
  operationType: string;
  total: number;
}

export interface Price {
  minPriceExclTax: number | null;
  maxPriceExclTax: number | null;

  commission?: {
    customer?: number;
    garage?: number;
  };
}

export interface Commission {
  customer: number;
  garage: number;
}

export interface Coupon {
  amount: number;
  code: string;
}

export enum QuoteStatus {
  VALIDATED = 'VALIDATED',
  CONFIRMED = 'CONFIRMED',
  CANCELLED = 'CANCELLED',
  WALLET = 'WALLET',
  REPORTED = 'REPORTED',
}

export enum FlexFuelStatus {
  CERTIFIED = 'CERTIFIED',
  NOT_CERTIFIED = 'NOT_CERTIFIED',
  UNKNOWN = 'UNKNOWN',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export interface Quote {
  quoteId: string;
  carServiceId: string;
  quantity: number;
  quoteType: string;

  mechanicalRateLevel?: string | null;
  partsQuality?: string | null;

  forfaitMalin: boolean;
  flexFuel?: FlexFuelStatus;
  operations: (PartOperation | RepairTime | Liquid | FixedPrice)[];
  discountTotal: number;
  status: QuoteStatus;
  requestCancelationAt?: string;
  isRequestCancelation?: any;
  price: Price;
  commission?: Commission;

  coupon?: Coupon | null;
  salesforceId?: string | null;

  duration?: number;
  totalMaxWithTax?: number;
}

enum PartsQuality {
  budget = 'budget',
  premium = 'premium',
}

interface OperationPromotion {
  id: string;
  type: string;
  amount: number;
  combinable: boolean;
  isPercentAmount: boolean;
  minimumActivationValue: number;
}

export interface PartOperation {
  operationId: string;
  operationType: 'parts';
  partsQuality: PartsQuality;
  label: string;
  discountTotal: number;
  garageDiscount: number;
  garageDiscountValue: number;
  garageDiscountValueMax: number;
  manufacturerPartsId: string[];
  oemName: string;
  oemPartsId: string[];
  partsSelected: Part;
  partsSelectedMax: Part;
  preferredManufacturerId: { id: string };
  promotion?: OperationPromotion;
  promotionValue: number;
  promotionValueMax: number;
  quantity: number;
  total: number;
  totalMax: number;
  unitPrice: number;
  unitPriceMax: number;
  unitTotal: number;
  unitTotalMax: number;
}

export interface Part {
  partsId: string;
  manufacturerName: string;
  partsQuality: PartsQuality;
  quantity: number;
  price: number;

  additionalInfos?: string;
}

export enum PartOperationIds {
  REPLACE_PNEUMATICS = '22010101',
}

export enum OperationTypes {
  PARTS = 'parts',
  REPAIR_TIME = 'repairTime',
  LIQUID = 'liquid',
  FIXED_PRICE = 'fixedPrice',
}

export interface RepairTime {
  operationId: string;
  operationType: 'repairTime';
  provider: 'idgarages' | 'autodata';
  duration: number;
  unitPrice: number;
  unitPriceMax: number;
  total: number;
  garageDiscount: number;
  garageDiscountValue: number;
  promotion?: OperationPromotion;
}

export interface Liquid {
  operationId: string;
  operationType: 'liquid';
  liquidId: string;
  unitPrice: number;
  unitPriceMax: number;
  volume: number;
  oilType: string;
  total: number;
}

export interface FixedPrice {
  operationId: string;
  operationType: 'fixedPrice';
  total: number;
}

export interface BookingPrice {
  commission: number;
  garageCommission: number;
  currency: string;
  vat: number;
  discountTotal?: number;
  totalDeductionLess?: number;
  coupon?: Coupon;
  total?: number;
  totalMax?: number;
  vatValue?: number;
  vatValueMax?: number;
  totalWithTax?: number;
  totalMaxWithTax?: number;
}

export interface BookingHistory {
  bookingId: string;
  quoteId: string;
  date: string;
  fieldName: string;
  oldValue: QuoteStatus;
  newValue: QuoteStatus;
  message: QuoteStatus;
}
export enum PartStatus {
  NOT_ORDERED = 'NOT_ORDERED',
  ORDERED = 'ORDERED',
  RECEIVED = 'RECEIVED',
}

export interface Booking {
  bookingId?: string;
  networkId: string;
  garageId: string;
  customer: Customer;
  vehicle?: BookingVehicle;
  bookingDate: string;
  bookingDepositHour?: string;
  quotes: Quote[];
  price: BookingPrice;
  paymentStatus: 'INITIATED' | 'ACCEPTED' | 'REFUSED' | undefined;
  paidByCreditCard: number;
  paidByWallet: number;
  duration?: number;
  comment?: string | null;
  bookingHistories?: BookingHistory[];
  partsOrdered?: PartStatus;
  isWithCourtesyVehicle?: boolean;
  isWithEasyPayment?: boolean;
}

export interface PlanningStore {
  bookings?: Array<Booking[]>;
  bookingsResults?: Booking[];
  nextBookings?: Booking[];
  today: string;
  currentDay: string;
  currentBooking?: Booking;
  displayBooking: boolean;
  updatePlanningMode: boolean;
  weekView: boolean;
  isPlanningLoading: boolean;

  planingHasSendedSMS: string[];
}

export enum NetworkId {
  GARAGE = 'GARAGE-OWNER',
}

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import History from '../history';

import { QuoteStatus } from '../../../../redux/stores/planning/types';
import { getVehicleById } from '../../../../redux/stores/quotes/actions';

import { Store } from '../../../../redux/types';
import AuthenticationService, {
  Roles,
} from '../../../../services/authentication-service';

import Planning from './components/planning';
import Infos from './components/infos';
import Parts from './components/parts';
import VehicleInfos from './components/vehicle';
import Comments from './components/comments';
import Premium from './components/premium';
import Commission from './components/commission';

const LateralWeb = () => {
  const {
    planningStore: { currentBooking },
    globalReducer: { availableCarservices, currentGarage },
    quotesReducer: { currentVehicle },
  } = useSelector<Store, Store>((state) => state);

  if (!currentBooking || !currentGarage) return null;

  const dispatch = useDispatch();

  const getVehicleDetails = (): void => {
    if (currentBooking.vehicle?.vehicleId) {
      dispatch(
        getVehicleById(
          currentBooking.vehicle?.vehicleId,
          () => {},
          () => {}
        )
      );
    }
  };

  const isAdmin = AuthenticationService.hasRole([
    Roles.ADMIN,
    Roles.CALL_CENTER,
  ]);

  const isGarageOwner = currentBooking.networkId === 'GARAGE-OWNER';

  const isQuoteStatusConfirmed = currentBooking.quotes.some(
    (q) => q.status === QuoteStatus.CONFIRMED
  );

  const isCustomerAdditionalDataCanDisplay = (): boolean => {
    if (isAdmin || isGarageOwner) {
      return true;
    }

    return isQuoteStatusConfirmed;
  };

  useEffect(() => {
    getVehicleDetails();
  }, []);

  return (
    <>
      <Planning currentBooking={currentBooking} />

      <Infos
        currentBooking={currentBooking}
        isCustomerAdditionalDataCanDisplay={isCustomerAdditionalDataCanDisplay}
      />

      <Parts currentBooking={currentBooking} currentGarage={currentGarage} />

      <VehicleInfos
        currentBooking={currentBooking}
        currentGarage={currentGarage}
        currentVehicle={currentVehicle}
      />

      <Comments currentBooking={currentBooking} />

      <Premium
        currentBooking={currentBooking}
        availableCarservices={availableCarservices}
      />

      <Commission currentBooking={currentBooking} />
      <History />
    </>
  );
};

export default LateralWeb;

import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LogoutButton from '../../atoms/LogoutButton';

interface LogoutHeaderProps {
  onLogout: () => void;
}

const LogoutHeader = ({ onLogout }: LogoutHeaderProps) => {
  const useStyles = makeStyles(() => ({
    root: {
      position: 'absolute',
      top: '0',
      left: '0px',
      right: '0',
      display: 'flex',
      flexDirection: 'row',
      background: 'white',
      borderBottom: '1px solid silver',
      height: '70px',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0px 40px'
    },
    title: {
      display: 'flex',
      '& .my': {
        height: '20px',

        fontSize: '18px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#769d30'
      },
      '& .garage': {
        height: '20px',
        fontSize: '18px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#2c2e3d'
      }
    }
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <span className="my">MY</span>
        <span className="garage">GARAGE</span>
      </div>
      <LogoutButton onClick={onLogout} />
    </div>
  );
};

export default LogoutHeader;

import moment from 'moment';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Store } from '../../../redux/types';
import Button from '../../../assets/generic-components/Button';
import logo from '../../../assets/style-elements/01-electrons-icon-time-big.png';

type ModalProps = {
  active: boolean;
  setActive: () => void;
  hour: string;
};

const AddAppointmentModal = ({ active, setActive, hour }: ModalProps) => {
  const {
    languageReducer: { language: lg }
  } = useSelector((state: Store) => state);

  const day = moment().format('DD MMM YYYY');

  const history = useHistory();

  return (
    <Modal show={active} onHide={setActive}>
      <Modal.Header closeButton>
        <Modal.Title>{lg.quote.confirmation}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{`${lg.quote.command} N° IDG-20191216-227045`}</p>
        <p>Mr Tarek CHERMAT (CITROEN - C4 PICASSO 1.6 HDI 110 FAB BVR)</p>
        <p>{`${day} ${hour}`}</p>
        <div className="logo">
          <img src={logo} alt="logo" className="logo" />
          <p>2h</p>
        </div>

        <div className="box-logo">
          <img src={logo} alt="logo" className="logo" />
          <p>{lg.quote.intermediateReview}</p>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          onClick={() => history.goBack()}
          theme="dark"
          title="Revenir au planning"
        />
      </Modal.Footer>
    </Modal>
  );
};

export default AddAppointmentModal;

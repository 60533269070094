import React, { LegacyRef } from 'react';

interface AutossimoHiddenFormProps {
  login?: string;
  password?: string;
  autossimoXML?: string;
}

const AutossimoHiddenForm = React.forwardRef(
  ({ login, password, autossimoXML }: AutossimoHiddenFormProps, ref) => (
    <form
      id="formAutossimo"
      ref={ref as LegacyRef<HTMLFormElement>}
      method="POST"
      action="http://www.autossimo.com/extranetv3/DIALOGUES/index.phtml"
      target="_blank"
    >
      <input
        type="hidden"
        id="formPartAutossimoLogin"
        name="username"
        value={login}
      />
      <input
        type="hidden"
        id="formPartAutossimoPassword"
        name="password"
        value={password}
      />
      <input
        type="hidden"
        id="formPartAutossimoPassword"
        name="fichierXML"
        value={autossimoXML}
      />
    </form>
  )
);

AutossimoHiddenForm.defaultProps = {
  login: '',
  password: '',
  autossimoXML: ''
};

export default AutossimoHiddenForm;

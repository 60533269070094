import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import { useSelector } from 'react-redux';
import colors from '../../../assets/style-colors';
import { Store } from '../../../redux/types';

export enum NetworkTabsEnum {
  ALL = 'Tout',
  IDG = 'IDG',
  AD = 'AD-AUTO',
}

enum IDGNetworkTabsEnum {
  IDG = 'IDG',
}

interface NetworkTabsProps {
  activeNetworkTab: NetworkTabsEnum;
  setActiveNetworkTab: (network: NetworkTabsEnum) => void;
}

const NetworkTabs: React.FC<NetworkTabsProps> = ({
  activeNetworkTab,
  setActiveNetworkTab,
}) => {
  const useStyle = makeStyles({
    tabContainer: {
      display: 'flex',
      marginTop: '40px',
      marginBottom: '20px',
    },
    tab: {
      backgroundColor: colors.white,
      color: colors.perso,
      borderRight: `1px solid ${colors.whiteLight}`,
      textAlign: 'center',

      '& > p': {
        padding: '10px 50px',
        margin: 0,
        fontWeight: 'normal',
      },
      '&:last-child': {
        borderTopRightRadius: '8px',
        borderBottomRightRadius: '8px',
      },
      '&:first-child': {
        borderTopLeftRadius: '8px',
        borderBottomLeftRadius: '8px',
      },
    },
    active: {
      backgroundColor: colors.whiteLight,
      color: colors.black,
    },
  });
  const classes = useStyle();

  const { currentGarage } = useSelector((state: Store) => state.globalReducer);

  const isAdAuto = currentGarage?.garageNetwork.some(
    (n) => n.networkId === 'AD-AUTO'
  );

  const Tabs = isAdAuto ? NetworkTabsEnum : IDGNetworkTabsEnum;

  return (
    <div className={classes.tabContainer}>
      {Object.values(Tabs).map((tab, index) => (
        <div
          key={index}
          tabIndex={0}
          role="button"
          onClick={() => setActiveNetworkTab(tab)}
          className={`${classes.tab} ${
            activeNetworkTab === tab ? classes.active : undefined
          }`}
        >
          <p>{tab}</p>
        </div>
      ))}
    </div>
  );
};

export default NetworkTabs;

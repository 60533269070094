import makeStyles from '@material-ui/core/styles/makeStyles';
import colors from '../../assets/style-colors';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'hidden',
    position: 'fixed',
    left: 0,
    right: 0,
    touchAction: 'none',
  },

  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '22px 12px',
    backgroundColor: colors.white,
  },

  headerTop: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },

  logo: {
    width: '137px',
  },

  language: {
    width: '35%',
  },

  admin: {
    color: colors.primary,
    marginTop: '10px',
  },

  garageInfo: {
    marginTop: '32px',
  },

  garageName: {
    fontWeight: 'bold',
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: colors.cloudWhite,
    textAlign: 'center',
    padding: '20px',
    height: '100%',
  },

  appImg: {
    width: '292px',
    height: '251px',
    marginBottom: '30px',
  },

  wording: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: colors.charcoalGrey,
    lineHeight: '37px',
    marginBottom: '30px',
  },

  orangeWording: {
    color: colors.primary,
    fontSize: '32px',
  },

  downloadLink: {
    width: '80%',
    margin: '0 auto',
  },
}));

export default useStyles;

import moment, { Moment } from 'moment';
import { Availability } from '../redux/stores/calendar/types';
import { Range } from '../redux/stores/global/types';

export const hoursRange2Array = (hoursRange: string[]) =>
  hoursRange.flatMap(h => h.split('-'));

export const array2HoursRange = (hours: string[]) => {
  if (hours.length === 4) {
    return [`${hours[0]}-${hours[1]}`, `${hours[2]}-${hours[3]}`];
  }
  return [`${hours[0]}-${hours[1]}`];
};

export const getDateSelectionRange = (): string[] => {
  const start = moment('07:00:00', 'HH:mm:ss');
  const end = moment('21:00:00', 'HH:mm:ss');
  const minutes = [];

  while (start.isSameOrBefore(end)) {
    minutes.push(start.format('HH:mm'));
    start.add(30, 'm');
  }
  return minutes;
};

export function holidays2SpecialDays(dateRange: Range<Moment>) {

  const days = dateRange.max
    .startOf('day')
    .diff(dateRange.min.startOf('day'), 'day');

  const specialDays: Availability[] = [];
  for (let i = 0; i <= days; i++) {
    specialDays.push({
      day: moment(dateRange.min)
        .add(i, 'days')
        .format('YYYY-MM-DD'),
      availability: {
        slots: 0,
        openingHours: ['08:00-18:00']
      }
    });
  }
  return specialDays;
}

import moment from 'moment';
import descriptions from '../assets/api/descriptions';
import fr from '../assets/images/fr.png';
import french from '../assets/language/FR';


export const initState = () => ({
  languageReducer: {
    language: french,
    image: fr,
    langAccount: descriptions
  },
  calendarReducer: {
    date: moment(),
    weekNumber: moment().week(),
    activeWeek: true,
    activeDay: false,
    dayIndex:
      moment()
        .week(moment().week())
        .day() - 1,
    devis: 'none',
    devisData: [],
    devisDataDetails: [],
    modal: {
      show: false,
      data: [],
      dayIndex: 0,
      weekIndex: 0,
      ticketIndex: 0
    },
    day: moment()
  },
  planningReducer: {
    searchForValue: null,
    modificationMode: false,
    modificationDay: false,
    modificationConges: null,
    closeDay: [false, false, false, false, false, false],
    item: [0, { openingHours: [] }],
    conges: [],
    conges2show: [],
    dataWeekAPI: {
      cars: 0,
      weekends: 6,
      weekdays: 10,
      delay: 2
    }
  },
  helpReducer: {
    helpCategories: []
  }
});

export default initState;

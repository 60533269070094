import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Networks } from '../../redux/stores/global/types';
import { Store } from '../../redux/types';
import useStyles from './commandStyles';
import Autossimo from './components/autossimo';
import Lateral from './components/lateral';
import Header from './components/navbar';
import Total from './components/total';

import With from './components/with';
import useModeTablet from './modeTablet';
import { getLogo } from '../../utils/booking';

const Command = () => {
  const {
    planningStore: { currentBooking },
    globalReducer: { currentGarage },
    languageReducer,
  } = useSelector((state: Store) => state);

  const lg = languageReducer.language;

  const classes = useStyles();
  const modeTablet = useModeTablet();

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Planning | Voir RDV</title>
      </Helmet>
      <Header languageReducer={languageReducer} />
      <div className={classes.bodyContainer}>
        <div className={classes.left}>
          <div className="garage-info printVisibleFlex">
            {currentBooking && (
              <img src={getLogo(currentBooking.networkId)} alt="logo garage" />
            )}
            <div>
              <h3>{currentGarage?.companyName}</h3>
              <address>
                <p>{currentGarage?.garageAddress.address1}</p>
                {currentGarage?.garageAddress.address2 && (
                  <p>{currentGarage.garageAddress.address2}</p>
                )}
                <p>
                  {currentGarage?.garageAddress.zipcode}{' '}
                  {currentGarage?.garageAddress.city}
                </p>
                <p>{currentGarage?.phone}</p>
              </address>
            </div>
          </div>
          <With />
          <div className={classes.bottom}>
            <div
              style={{
                display: 'flex',
                gap: '15px',
                flexDirection: modeTablet ? 'column' : 'row',
              }}
            >
              <Autossimo />
              <Total />
            </div>
            {currentBooking?.networkId === Networks.GARAGE_OWNER && (
              <p className={classes.bottomAvailability}>
                {lg.pdf.availability}
              </p>
            )}
          </div>
        </div>
        <Lateral />
      </div>
    </div>
  );
};

export default Command;

const manufacturers: Record<string, string> = {
  ABEL: 'ABEL',
  ABEX: 'FERODO',
  ABS: 'ABS',
  ACCO: 'KENNOL (ACCOR)',
  ACDM: 'ACDM',
  ACRS: 'ACR',
  ADE: 'ADE METALCAUCHO',
  ADED: 'SYNCHRO',
  ADKP: 'AD KIT PREMONT',
  ADPE: 'ANTIFUITE RADIA',
  AECU: 'CURTY',
  AGIP: 'ENI',
  ALC: 'ALCO FILTER',
  ALDE: 'ALDER',
  ALIN: 'SACHS-LEMF',
  ALKA: 'ALKAR AD MAROC',
  ALKO: 'ALKO-ALTONE',
  ALPX: 'ALPITEX',
  ALSI: 'BENDIX - JURID',
  ALTO: 'ALTONE',
  ALUX: 'AUTOLUX',
  ANCR: 'ACK FORANKRA',
  ARME: 'ARVIN MERITOR',
  ARNT: 'ARNOTT',
  ARR: 'ARRC',
  AS: 'AS CATALYSEUR',
  ASDI: 'A.S. DIFFUSION',
  ASPO: 'ASPOCK',
  ASSO: 'ASSO',
  ATDA: 'PLATEFORME TECH',
  ATE: 'CONTINENTAL',
  ATTE: 'ATTENTE',
  AURR: 'AURORA',
  AUTC: 'ACR-AUTEC',
  AUTE: 'ABL LIGHTS',
  AUTK: 'KWASNY',
  AVA: 'AVA VOIR AVCO',
  AVCO: 'AVA COOLING',
  BALD: 'BALDWIN',
  BANN: 'BANNER',
  BAO: 'VARTA',
  BARM: 'BARUM',
  BAT3: 'BAT BUSINESS',
  BDW: 'BUDWEG',
  BEHR: 'BEHR-HELLA',
  BERU: 'BERU REF.COURTE',
  BER1: 'BERU REF.LONGUE',
  BLUE: 'BLUE PRINT FEBI',
  BM: 'BM CATALYSTS',
  BNDC: 'DIGIPARTS EXIDE',
  BNDI: 'DIGIP. VIPIEMME',
  BNDM: 'DIGIP. MAGNETI',
  BNDR: 'DIGIP. ROMBAT',
  BNDS: 'BATT.DIGIPARTS',
  BOIS: 'BOISNIER',
  BOSA: 'BOSAL',
  BOSC: 'BOSCH',
  BOYR: 'BOYRIVEN',
  BRBO: 'BREMBO',
  BVBF: 'AD BOSCH-VARTA',
  CALL: 'CALL VDO',
  CEI: 'C.E.I.(PM FRANC)',
  CEVM: 'CEVAM',
  CFEC: 'BATTERIE STECO',
  CFTD: 'BATTERIE FULMEN',
  CHAM: 'CHAMPION',
  CHEM: 'CHEMICAR',
  COFF: 'MAHLE AFTERMARK',
  COLA: 'COLAERT',
  COLL: 'COLLEX',
  CONT: 'CONTITECH',
  CORA: 'CORA',
  CRIS: 'A.G.C',
  CTAL: 'CONTINENTAL',
  DAS2: 'DA SILVA',
  DAYC: 'DAYCO',
  DBCA: 'DEBICA',
  DCOR: 'CORA DISTRIB',
  DEIT: 'DENSO',
  DELC: 'DELCO-REMY',
  DELH: 'DELPHI',
  DELM: 'DELMO DELCLIM',
  DELP: 'DELPHI DIESEL',
  DEPA: 'DEPA',
  DGBL: 'BALAIS DIGIPART',
  DGPC: 'DIGIPARTS',
  DHOL: 'DHOLLANDIA',
  DIFR: 'DIFRAMA',
  DIMA: 'AUTOVER',
  DINI: 'DINITROL',
  DOGA: 'DOGA AD MAROC',
  DONA: 'DONALDSON KSMO',
  DPAC: 'D.P.A.C.',
  DPAN: 'DPAN',
  DR: 'DR',
  DUPL: 'DUPLI COLOR',
  DUPN: 'DUPONT REFINISH',
  DURA: 'DURAND PRODUCT',
  EAI: 'EAI-SOREA',
  EBER: 'EBERSPACHER',
  EGI: 'E.G.I. EUROPE',
  ELEC: 'ELECTRICFIL',
  ELUX: 'ERA BENELUX',
  ENE: 'ENERGIZER',
  ESC1: 'ELECTRIC CLIM',
  ESSO: 'ESSO MOBIL',
  ETMA: 'DINEX - (ETMA)',
  EUAR: 'EUROREPAR',
  EUJA: 'HERTH BUSS',
  EURG: 'EUROGESTE',
  EURO: 'EUROCODE GLACES',
  EYQ: 'EYQUEM',
  FAAB: 'FAAB AD INDUS',
  FACE: 'FACET',
  FACO: 'FACOM',
  FAE: 'FAE',
  FAG: 'FAG',
  FARA: 'FARAL',
  FARE: 'FARE ALTONE',
  FEBI: 'FEBI',
  FIAA: 'ITALIE FIAAM',
  FIAM: 'FIAM',
  FILT: 'PURFLUX',
  FIRE: 'FIRESTONE',
  FITC: 'BOYRIVEN FIT',
  FLAM: 'TRUCK-LITE',
  FLEE: 'FLEETGUARD',
  FRAC: 'FRANCELEC',
  FRAM: 'FRAM',
  FRIA: 'FRIGAIR',
  FRU1: 'FRUEHAUF',
  FTE: 'FTE AUTOMOTIVE',
  GABR: 'GABRIEL-TRW',
  GARR: 'GARRETT',
  GATE: 'GATES',
  GAT1: 'GATES',
  GEOR: 'CP FRANCE',
  GLAS: 'GLASURIT',
  GLEN: 'GKN GLENCO',
  GOOD: 'GOODYEAR',
  GRAF: 'GRAF',
  GRAU: 'HALDEX/GRAU',
  GUYA: 'GUYA',
  GYS: 'GYS',
  HANK: 'HANKOOK FRANCE',
  HELL: 'HELLA',
  HEN: 'HENGST FILTER',
  HENK: 'LOCTITE HENKEL',
  HITA: 'HITA',
  HOCH: 'ACIA HOCHIM AD',
  HOET: 'HOET',
  HOLS: 'INTEC SARL',
  HOLT: 'HOLTS',
  HPAR: 'HYDROPARTS',
  HUIN: 'HUTCHINSON',
  ICBC: 'ISOTECH EXIDE',
  ICBI: 'VIPIEMME',
  ICBR: 'ISOTECH ROMBAT',
  ICBS: 'ISOTECH STECO',
  ICI: 'NEXA AUTOCOLOR',
  ICPL: 'ISOTECH COUSSIN',
  ICVB: 'ISOTEHC BOSCH',
  INA: 'INA',
  INGE: 'POWERTOOLS',
  INTF: 'INTFRADIS',
  ISAM: 'ISOTECH',
  ISAS: 'ISOTECH',
  ISBC: 'ISOTECH',
  ISBD: 'ISOTECH',
  ISBG: 'BALAIS E-G',
  ISBK: 'ISOTECH',
  ISBL: 'ISOTECH',
  ISBO: 'ISOTECH',
  ISBP: 'ISOTECH',
  ISBS: 'ISOTEC',
  ISCK: 'ISOTECH',
  ISCR: 'ISOTECH',
  ISCT: 'ISOTECH',
  ISDG: 'ISOTECH',
  ISDM: 'ISOTECH',
  ISDT: 'ISOTECH',
  ISEN: 'ISOTECH ',
  ISEP: 'ISOTECH',
  ISER: 'ISOTECH',
  ISFA: 'ISFA / FAP',
  ISFI: 'ISOTECH',
  ISFL: 'ISOTECH',
  ISFO: 'ISOTECH',
  ISFR: 'ISOTECH',
  ISGA: 'GAMME ASIATIQUE',
  ISHY: 'ISOTECH ',
  ISKP: 'KIT PREMONTE',
  ISKR: 'ISKRA',
  ISLA: 'ISOTECH LAMPES',
  ISLF: 'LIQUIDE DE FREI',
  ISLI: 'SUPRIME',
  ISLM: 'ISOTECH  SMB',
  ISLR: 'ISOTECH ACCORD',
  ISLU: 'LIQUIDE DURAND',
  ISMT: 'ISOTECH/DELCO',
  ISPC: 'ISOTECH COLLAGE',
  ISPD: 'ISOTECH',
  ISPT: 'PEINTURE AEROSO',
  ISSD: 'ISOTECH',
  ISTA: 'ISOTECH/EKU',
  ISVE: 'ISOTECH',
  ISWB: 'ISOTECH ROULTS',
  ISWP: 'ISOTECH P. EAU',
  ITEX: 'BP ENERGEAR',
  IWAT: 'IWATA',
  JMBD: 'JMBD',
  JONE: 'JONESCO',
  JOST: 'JOST',
  JURA: 'JURATEK',
  KAGE: 'KAGER',
  KAYA: 'KAYABA',
  KKK: 'K.K.K.',
  KLBE: 'KLEBER',
  KNOR: 'KNORR',
  KONG: 'KONGSBERG',
  KONI: 'KONI',
  KOPA: 'KOPAL',
  KORM: 'KORMORAN-STOMIL',
  KSMO: 'MOTOR SERVICE',
  KUHL: 'KUHLER SCHNEIDE',
  KUMH: 'KUMHO',
  LABB: 'LABBE',
  LCHL: 'LECHLER',
  LECO: 'LECOY',
  LERE: 'LE RESERVOIR',
  LIZA: 'LIZARTE',
  LOCT: 'LOCTITE INDUSTR',
  LPR: 'LPR',
  LUCA: 'LUCAS T-R-W',
  LUKA: 'SCHAEFFLER',
  LUM: 'LUCAS ELECTRIC',
  MACX: 'IMPEX',
  MADR: 'MAD PRODUCTION',
  MAGN: 'MAGNETI',
  MAG1: 'MAGNETI',
  MAHL: 'MAHLE COURTE',
  MAIT: 'MAITRE EQUIPEME',
  MAM: 'M.A.M.',
  MANN: 'MANN',
  MAXM: 'MAXMEYER',
  MEAD: 'MECA AUTO',
  MEAT: 'MEAT-DORIA',
  MEIL: 'CORTECO',
  MEKR: 'MEKRA',
  MELC: 'MELCHIONI',
  MEYL: 'MEYLE',
  MEYT: 'MEYTEC',
  MGA: 'M.G.A',
  MITU: 'MITSUBISHI TURB',
  MLIN: 'MICHELIN',
  MONR: 'MONROE',
  MOOG: 'MOOG (FEDERAL)',
  MOTR: 'MOTRIO RENAULT',
  MTS: 'MTS ECHAPPEMENT',
  NENA: 'LECINENA',
  NGK: 'N.G.K',
  NGK1: 'N.G.K COMMERCIA',
  NIPP: 'NIPPARTS',
  NIPS: 'NIPPON',
  NISN: 'NISSENS',
  NORN: 'LAMPES PHILIPS',
  NORT: 'NORTON AUTOMOBI',
  NRF: 'NRF',
  NVAT: 'SERDITECH',
  OBJC: 'OBJECTIF PREVEN',
  OCAP: 'OCAP S.P.A',
  PAPL: 'PAC',
  PGS: 'PILKINGTON',
  PHOE: 'PHOENIX',
  PIRE: 'PIRELLI',
  PMS: 'PMS INDUSTRIE',
  PNE2: 'PNEUMATIS',
  PODI: 'POLAIRE',
  POMA: 'POMMIER',
  POMC: 'POMMIER CARROSS',
  PPG: 'PPG',
  PPO: 'KENNOL',
  PRCH: 'PROTECH',
  PRF: 'PROFISTART',
  PROA: 'PROMAUTO',
  PTS: 'SAM',
  PURO: 'PUROLATOR',
  QUIN: 'QUINTON-HAZELL',
  RCA: 'RCA',
  REMS: 'REMSA',
  RESM: 'RESMA',
  REST: 'RESTAGRAF',
  ROAD: 'ROADHOUSE',
  ROBE: 'ROBERT LYE',
  ROMB: 'ROMBAT',
  ROSI: 'KLARIUS-QUINTON',
  RTS: 'RTS AD MAROC',
  SACA: 'SACATEC EQUIPEM',
  SADA: 'SADAPS BARDAHL',
  SAFF: 'SAF HOLLAND',
  SAIR: 'BPW',
  SALC: 'SALCOMIX',
  SALE: 'SALERI',
  SAMK: 'SAMKO',
  SAMR: 'SAM REV',
  SARE: 'SASIC',
  SATA: 'SATA',
  SAVG: 'SAVG',
  SBT: 'S.B.T.',
  SCHR: 'SCHRADER',
  SCHW: 'SCHWITZER',
  SCOM: 'NOVOCAR (SCOMA)',
  SCOW: 'SHERWIN WILLIAM',
  SCT: 'SCT GERMANY',
  SEIM: 'SEIM',
  SELX: 'SELEMIX',
  SEST: 'SESTRIERE',
  SFPL: 'G.B.',
  SHEL: 'SHELL',
  SIAR: 'SIARR',
  SIDA: 'SIDAMO',
  SIDT: 'SIDAT',
  SIFA: 'SIFA',
  SIKA: 'SIKA',
  SIKK: 'SIKKENS',
  SKF: 'S.K.F.',
  SMB: 'SMB  DEHON',
  SMBI: 'SMB - GIGANT',
  SNR: 'SNR',
  SNRA: 'SNRA',
  SOLA: 'MECAFILTER',
  SOLO: 'SOLOPLAST',
  SPIE: 'SPIES HECKER',
  SPRI: 'SPRINGRIDE',
  STAD: 'STANDOX -DUPONT',
  STHO: 'STHO',
  STOP: 'STOP',
  SUD: 'SOREA (EAI)',
  TCIC: 'TC MATIC',
  TECF: 'TECNOCAR',
  TEC1: 'NEOCLEAN',
  TEXT: 'TEXTAR',
  TIMK: 'TIMKEN',
  TIP: 'TIP TOP',
  TMD: 'TMD-TEXTAR/PAGI',
  TOPR: 'TOPRAN',
  TOTA: 'TOTAL FINA',
  TRAJ: 'TRAJECTOIRE',
  TTAM: 'MOBIL',
  TUMO: 'TURBOMOTOR',
  TVI: 'STONERIDGE ELEC',
  UFI: 'UFI',
  UPOL: 'U-POL',
  VADI: 'VALEO',
  VALV: 'VALVOLINE',
  VART: 'VARTA',
  VASV: 'VIGNAL SYSTEMS',
  VDO: 'Voir CALL-VDO',
  VEGE: 'VEGE MOTEUR',
  VEIA: 'VEILLAT',
  VERN: 'VERNET',
  VISO: 'VISTEON>HELLA',
  WABC: 'WABCO',
  WABO: 'WALKER BONUS',
  WAEC: 'WAECO',
  WALK: 'WALKER',
  WEWE: 'WEWELER',
  WIX: 'WIX FILTERS',
  WOOD: 'WOODGATE KSMO',
  WYNN: "WYNN'S",
  ZANE: 'ZANETTI',
  ZINT: 'Z INTERNATIONAL',
  '3M': '3M FRANCE',
  ADAB: 'ADAB',
  ADAT: 'ADAT',
  ADBC: 'ADBC',
  ADBG: 'ADBG',
  ADBL: 'ADBL',
  ADCT: 'ADCT',
  ADDQ: 'ADDQ',
  ADEN: 'ADEN',
  ADFA: 'ADFA',
  ADFL: 'ADFL',
  ADFR: 'ADFR',
  ADLD: 'ADLD',
  ADLF: 'ADLF',
  ADLG: 'ADLG',
  ADLH: 'ADLH',
  ADLI: 'ADLI',
  ADLP: 'ADLP',
  ADMT: 'ADMT',
  ADPC: 'ADPC',
  ADPO: 'ADPO',
  ADPQ: 'ADPQ',
  ADPT: 'ADPT',
  ADSV: 'ADSV',
  ADTR: 'ADTR',
  ALPL: 'ALPL',
  BOGE: 'BOGE',
  DT: 'DT',
  DURO: 'DURO',
  ECC: 'ECC',
  ECO: 'ECO',
  ELST: 'ELST',
  EOLO: 'EOLO',
  ERA: 'ERA',
  ETYR: 'ETYR',
  EURL: 'EURL',
  EVED: 'EVED',
  EXID: 'EXID',
  FATE: 'FATE',
  FLAS: 'FLAS',
  FLDA: 'FLDA',
  FLEN: 'FLEN',
  FORF: 'FORF',
  GER: 'GER',
  GISL: 'GISL',
  GOPN: 'GOPN',
  GTRL: 'GTRL',
  HOFF: 'HOFF',
  INFN: 'INFN',
  IPD: 'IPD',
  JAP: 'JAP',
  JLGR: 'JLGR',
  JOHN: 'JOHN',
  KLOK: 'KLOK',
  LEAO: 'LEAO',
  LEMF: 'LEMF',
  MEMB: 'MEMB',
  MHAL: 'MHAL',
  MONS: 'MONS',
  MRAN: 'MRAN',
  MTE: 'MTE',
  NEXE: 'NEXE',
  NOR: 'NOR',
  NRF1: 'NRF1',
  OPTI: 'OPTI',
  ORIO: 'ORIO',
  OSRA: 'OSRA',
  PBR: 'PBR',
  PEX: 'PEX',
  POT: 'POT',
  PRAS: 'PRAS',
  SAIN: 'SAIN',
  SAVP: 'SAVP',
  SBS: 'SBS',
  SCHL: 'SCHL',
  SDIT: 'SDIT',
  SILT: 'SILT',
  SOF: 'SOF',
  SOFI: 'SOFI',
  SPAH: 'SPAH',
  STA: 'STA',
  SWA: 'SWA',
  SWLP: 'SWLP',
  TEAM: 'TEAM',
  TIGA: 'TIGA',
  TURB: 'TURB',
  TYC: 'TYC',
  ULO: 'ULO',
  UNIR: 'UNIR',
  URW: 'URW',
  VAN: 'VAN',
  VEMO: 'VEMO',
  VRED: 'VRED',
  YOKA: 'YOKA',
  ZETA: 'ZETA'
};

export default manufacturers;

import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../layout';

interface ErrorPageProps {
  title: string;
  eventId?: string;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ title, eventId }) => (
  <>
    <Helmet>
      <title>Erreur survenue</title>
    </Helmet>
    <Layout garageName="Error page">
      <div className="error-container">
        <h1>{title}</h1>
        {eventId && <p>Code erreur : {eventId}</p>}
      </div>
    </Layout>
  </>
);

ErrorPage.defaultProps = {
  eventId: undefined
};

export default ErrorPage;

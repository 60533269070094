import { createTheme, makeStyles } from '@material-ui/core/styles';
import moment, { Moment } from 'moment';

import React, { useEffect, useRef, useState } from 'react';

import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import colors from '../../../../../assets/style-colors';
import add from '../../../../../assets/style-elements/add.svg';
import edit from '../../../../../assets/style-elements/Icon-edit.svg';
import arrow from '../../../../../assets/style-elements/arrow.svg';
import time from '../../../../../assets/style-elements/time.svg';
import planningIcon from '../../../../../assets/style-elements/icon-planning.svg';

import {
  activeUpdatePlanningMode,
  setCurrentDay,
  setWeekView,
} from '../../../../../redux/stores/planning/actions';
import { Store } from '../../../../../redux/types';

import {
  ACCOUNT_HOURS,
  ADD_BOOKING,
  QUOTE,
} from '../../../../../router/routes';
import IconButton from '../../../../../atomic/components/atoms/IconButton/IconButton';
import Calendar from '../../month-view-right/calendar';
import { GARAGESTATE } from '../../../../../utils/constants';

interface WeekNavigationProps {
  pathUpdate: string;
}

const WeekNavigation: React.FC<WeekNavigationProps> = ({ pathUpdate }) => {
  const dispatch = useDispatch();
  const {
    languageReducer,
    planningStore: { today, currentDay, weekView },
  } = useSelector((state: Store) => state);
  const activeDay = today;

  const lg = languageReducer.language;
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [show, setShow] = useState<string>('none');

  const [showCalendar, setCalendarShowed] = useState(false);
  const container = useRef<HTMLDivElement>(null);

  const isLarge = width >= 1440;
  const modeTablet = width <= 1024;
  const history = useHistory();

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
  });

  // STYLES:
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1200,
        xl: 1920,
      },
    },
  });

  const useStyles = makeStyles(() => ({
    root: {
      position: 'relative',
      width: isLarge ? `${width - 196}px` : `${width - 55}px`,

      top: '0',
      flexDirection: 'row',
      background: 'transparent',
      borderBottom: `1px solid ${colors.WhiteDark}`,
      height: '60px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: '12px',
      color: colors.black,
    },
    rightBar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      height: '100%',
      paddingRight: '20px',
    },
    leftBar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      height: '100%',
    },
    item: {
      background: colors.white,
      color: colors.black,
      borderRadius: '10px',
      height: '34px',
      padding: '10px',
      margin: '5px',
      border: 'none',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      cursor: 'pointer',
      boxShadow: '0 2px 18px 0 rgba(214,214,214,0.5)',
    },
    arrow: {
      width: '34px',
      '&:focus': {
        outline: 'none',
        border: 'none',
      },
    },
    link: {
      fontSize: '12px',
      color: colors.dark,
      whiteSpace: 'nowrap',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    icon: {
      color: colors.primary,
      marginRight: modeTablet ? 0 : '10px',
    },
    week: {
      width: '80px',
      height: '18px',
      borderRadius: '4px',
      fontSize: '9px',
      fontWeight: 500,
      textAlign: 'center',
      color: colors.slateGrey,
      margin: '5px',
      backgroundColor: colors.veryLightBlue,
      lineHeight: '18px',
    },
    month: {
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: 1.25,
      margin: '5px',
      width: 'auto',
      flexDirection: 'column',
      padding: '0 20px',
    },
    weekNumber: {
      fontSize: '9px',
      textAlign: 'center',
      color: colors.fordGray,
    },
    monthName: {
      marginBottom: 0,
      fontWeight: 'bold',
      fontSize: '14px',
      color: colors.dark,
    },
    calendar: {
      position: 'absolute',
      zIndex: 5,
      top: '10px',
      left: 0,
      background: colors.white,
      '& div': {
        background: colors.white,
      },
    },
    dayWeek: {
      fontSize: '12px',
      minWidth: '150px',
      height: '60%',
      border: `solid 1px ${colors.whiteLight}`,
      borderRadius: '8px',
      background: 'transparent',
      margin: '5px',
      marginLeft: '20px',
    },
    dayWeekBoth: {
      height: '100%',
      width: '50%',
      color: activeDay ? colors.black : colors.dark,
      background: activeDay ? colors.white : 'transparent',
      border: 'none',
      '&:focus': { outline: 'none' },
    },
    dayWeekLeft: {
      color: weekView ? colors.lightGreyBlue : colors.dark,
      background: weekView ? 'transparent' : colors.white,
      borderBottomLeftRadius: '10px',
      borderTopLeftRadius: '10px',
    },
    dayWeekRight: {
      color: weekView ? colors.dark : colors.lightGreyBlue,
      background: weekView ? colors.white : 'transparent',
      borderBottomRightRadius: '10px',
      borderTopRightRadius: '10px',
    },
    dropdown: {
      display: 'block',
      position: 'absolute',
      top: '0px',
      right: '0px',
      width: '145px',
      transform: 'translate3d(0px, 45px, 0px)',
      willChange: 'transform',
      background: colors.white,
      padding: '0 10px',
      zIndex: 3000,
      borderRadius: '8px',
      boxShadow: '0 2px 18px 0 rgba(136, 135, 135, 0.5)',
      [theme.breakpoints.up('lg')]: {
        width: '100%',
        left: '0px',
      },
    },
    dropItem: {
      padding: '15px 10px',
      cursor: 'pointer',
    },
    arrowRight: {
      transform: 'rotate(180deg)',
    },
    lineDropItem: {
      borderBottom: `1px solid ${colors.WhiteDark}`,
    },

    toggle: {
      backgroundColor: 'transparent',
      outline: 'none',
      border: 'none',
      color: colors.black,
      '&:hover': {
        background: colors.veryPaleGrey,
        outline: 'none',
        border: 'none',
        color: colors.black,
      },
      '&:active': {
        background: `${colors.veryPaleGrey}!important`,
        outline: 'none!important',
        border: 'none!important',
        color: colors.black,
      },
      '&:focus': {
        background: `${colors.veryPaleGrey}!important`,
        outline: 'none!important',
        border: 'none!important',
        color: colors.black,
      },
    },
  }));
  const classes = useStyles();

  // Dropdown element
  const RdvDropdown = () => (
    <Dropdown>
      <button
        aria-haspopup="true"
        aria-expanded="false"
        type="button"
        className={classes.toggle}
        onClick={() => {
          setShow(show === 'none' ? 'block' : 'none');
        }}
      >
        <div className={classes.item}>
          <img src={add} alt=" " className={classes.icon} />
          {modeTablet ? null : (
            <span className={classes.link}>{lg.planning.addRDV}</span>
          )}
        </div>
      </button>
      <div className={classes.dropdown} style={{ display: show }}>
        <Link
          to={{
            pathname: QUOTE,
            state: 'planning',
          }}
        >
          <div
            className={`${classes.dropItem} ${classes.lineDropItem}`}
            role="button"
          >
            {lg.planning.with}
          </div>
        </Link>
        <div
          tabIndex={0}
          onClick={(event) => {
            event.preventDefault();
            history.push(ADD_BOOKING);
          }}
          className={classes.dropItem}
          role="button"
        >
          {lg.planning.without}
        </div>
      </div>
    </Dropdown>
  );

  // FUNCTIONS:
  const getMonthName = () => {
    const initialDate = currentDay;

    const month1 =
      lg.planning.menuRight.months[moment(initialDate).day(0).month()];
    const month0 =
      lg.planning.menuRight.months[moment(initialDate).day(6).month()];
    const string1 = month1 === month0 ? month1 : `${month1}/${month0}`;

    const year1 = moment(initialDate).format('YYYY');

    const year0 = moment(initialDate)
      .add(6, 'days')

      .format('YYYY');
    const string2 = year1 === year0 ? year1 : `${year1}/${year0}`;
    return year1 === year0
      ? `${string1} ${string2}`
      : `${month1} ${year1}/${month0} ${year0}`;
  };

  function handleMinusWeek() {
    if (weekView) {
      dispatch(setCurrentDay(moment(currentDay).add(-7, 'days').toISOString()));
    } else {
      const day = moment(currentDay).subtract(1, 'days');
      dispatch(setCurrentDay(day.toISOString()));
    }
  }

  function handlePlusWeek() {
    if (weekView) {
      dispatch(setCurrentDay(moment(currentDay).add(7, 'days').toISOString()));
    } else {
      const day = moment(currentDay).add(1, 'days');
      dispatch(setCurrentDay(day.toISOString()));
    }
  }

  const handleEnableFocus = () => {
    setCalendarShowed(true);
    container?.current?.focus();
  };

  const handleCalendarBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.currentTarget.contains(e.relatedTarget)) {
      return;
    }
    setCalendarShowed(false);
  };

  // HTML:
  return (
    <div className={`${`${classes.root} noprint`}`}>
      <div className={classes.leftBar}>
        <button
          type="button"
          className={`${classes.item} ${classes.arrow}`}
          onClick={() => handleMinusWeek()}
        >
          <img src={arrow} alt="" />
        </button>

        <button
          className={`${classes.item} ${classes.month}`}
          type="button"
          onClick={handleEnableFocus}
        >
          <p className={classes.monthName}>{getMonthName()}</p>
          <p className={classes.weekNumber}>{`${lg.planning.week} ${moment(
            currentDay
          ).week()}`}</p>
        </button>
        <div
          className={classes.calendar}
          ref={container}
          onBlur={handleCalendarBlur}
          tabIndex={-1}
        >
          {showCalendar && (
            <Calendar
              onSelect={(date: Moment) => {
                dispatch(setCurrentDay(date.toISOString()));
                setCalendarShowed(false);
              }}
            />
          )}
        </div>

        <button
          type="button"
          className={`${classes.item} ${classes.arrow}`}
          onClick={() => handlePlusWeek()}
        >
          <img src={arrow} alt="" className={classes.arrowRight} />
        </button>
      </div>

      <div className={classes.rightBar}>
        <div className={classes.dayWeek}>
          <button
            type="button"
            onClick={() => {
              dispatch(setWeekView(false));
              dispatch(setCurrentDay(moment().toISOString()));
            }}
            className={`${classes.dayWeekBoth} ${classes.dayWeekLeft}`}
          >
            {lg.planning.day}
          </button>
          <button
            type="button"
            onClick={() => dispatch(setWeekView(true))}
            className={`${classes.dayWeekBoth} ${classes.dayWeekRight}`}
          >
            {lg.planning.week}
          </button>
        </div>

        {!GARAGESTATE() && (
          <>
            <IconButton
              icon={edit}
              label={lg.planning.modify}
              onClick={() => {
                dispatch(activeUpdatePlanningMode(true));
                history.push(pathUpdate);
              }}
              isMinified={modeTablet}
            />

            <RdvDropdown />

            <IconButton
              icon={time}
              label={lg.planning.editHours}
              onClick={() => {
                history.push(ACCOUNT_HOURS);
              }}
              isMinified={modeTablet}
            />
            <IconButton
              icon={planningIcon}
              label={lg.planning.menuRight.button}
              onClick={() => {
                history.push('/planning/manage-holidays');
              }}
              isMinified={modeTablet}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default WeekNavigation;

import React from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import colors from '../../../assets/style-colors';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    left: '20px',
    color: colors.primary
  }
}));

const ProgressBtnLoader: React.FC = () => {
  const styles = useStyles();

  return <CircularProgress color="primary" size={20} className={styles.root} />;
};

export default ProgressBtnLoader;

import React from 'react';
import { connect } from 'react-redux';
import LateralWeb from './web';
import useStyles from './style';

const mapStateToProps = (state: any) => ({
  ...state
});

const Lateral = () => {

  const classes = useStyles();

  return (
    <div className={`${classes.right}`}>
      <div className={classes.all}>
        <LateralWeb />
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(Lateral);

const planningActions = {};

planningActions.setModificationMode = (dispath, bool) => {
  dispath({
    type: 'MODIF_MODE',
    payload: bool
  });
};
planningActions.setModificationDay = (dispath, bool) => {
  dispath({
    type: 'MODIF_DAY',
    payload: bool
  });
};
planningActions.setCloseDay = (dispath, data) => {
  dispath({
    type: 'CLOSE_DAYS',
    payload: data
  });
};
planningActions.setItem = (dispath, data) => {
  dispath({
    type: 'SET_ITEM',
    payload: data
  });
};
planningActions.setConges = (dispath, data) => {
  dispath({
    type: 'SET_CONGES',
    payload: data
  });
};
planningActions.setConges2show = (dispath, data) => {
  dispath({
    type: 'SET_CONGES_SHOW',
    payload: data
  });
};
planningActions.setCongesModif = (dispath, i) => {
  dispath({
    type: 'SET_CONGES_MODIF',
    payload: i
  });
};
planningActions.setDataSemaine = (dispath, data) => {
  dispath({
    type: 'SET_DATA_SEMAINE',
    payload: data
  });
};
planningActions.setSearchForValue = (dispath, data) => {
  dispath({
    type: 'SET_SEARCH_VALUE',
    payload: data
  });
};

export default planningActions;

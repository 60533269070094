import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useSelector } from 'react-redux';
import NativeSelect from '@material-ui/core/NativeSelect';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import UnfoldMore from '@material-ui/icons/UnfoldMore';
import colors from '../../assets/style-colors';
import { Store } from '../../redux/types';
import OneView from './components/oneView';
import { ViewsListEnum } from '../../redux/stores/billings/type';
import { yearsBilling } from '../../services/billing/billing';
import BillingSummary from './components/billingSummary';
import { BootstrapInput, BootstrapInputMonth } from '../bootstrapInput';

const Billing: React.FC = () => {
  const {
    languageReducer: { language: lg },
    globalReducer: { currentGarage },
  } = useSelector((state: Store) => state);
  const history = useHistory();
  const { url, path } = useRouteMatch();

  if (!currentGarage) return null;

  // STYLES:
  const useStyles = makeStyles(() => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      color: 'darkgrey',
      overflow: 'hidden',
    },
    leftNavBar: {
      display: 'flex',
      justifyContent: 'start',
      background: colors.white,
      borderBottom: '2px solid #eaebf1',
      paddingLeft: '20px',
    },
    help: {
      cursor: 'pointer',
      padding: '20px 10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: colors.lightGreyBlue,
    },
    helping: {
      cursor: 'pointer',
      padding: '20px 10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: colors.lightGreyBlue,
      position: 'relative',

      '&:after': {
        content: '""',
        height: '4px',
        width: 'calc(100% - 20px)',
        backgroundColor: colors.primary,
        position: 'absolute',
        bottom: '-2px',
        left: '10px',
        borderRadius: '2px',
      },
    },
    link: {
      color: colors.lightGreyBlue,
      fontSize: '14px',
      textDecoration: 'none',
      padding: '0 10px 0 10px',
      '&:hover': {
        color: colors.dark,
        textDecoration: 'none',
      },
    },
    linking: {
      fontSize: '14px',
      padding: '0 10px 0 10px',
      color: colors.dark,
      textDecoration: 'none',
    },
    item: {
      fontSize: '9px',
      fontWeight: 500,
      width: '20px',
      height: '20px',
      textAlign: 'center',
      padding: '5px',
      color: colors.white,
      borderRadius: '5px',
    },
    header: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1',
    },
    title: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flex: '1',
      background: 'white',
      fontSize: '16px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: colors.dark,
      padding: '15px 20px 10px',
    },
    choice: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'row',
      flex: '1',
      background: 'white',
      fontSize: '16px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: colors.dark,
      padding: '0 15px 2px',
    },
    spaceBetween: {
      marginRight: '20px',

      '&:first-child': {
        marginRight: '10px',
      },
    },
  }));

  const classes = useStyles();

  const months = moment.months();

  const years = yearsBilling();

  const previousMonthYear = moment().add(-1, 'M').format('YYYY');
  const previousMonth = moment().add(-1, 'M').format('MM');
  const [year, setYear] = useState(previousMonthYear);
  const [month, setMonth] = useState(previousMonth);
  const [active, setActive] = useState([true]);
  const [currentPath, setCurrentPath] = useState('commission');

  const handleChange = (event: React.FormEvent<HTMLSelectElement>) => {
    setYear(event.currentTarget.value);
    event.preventDefault();
  };

  const handleChangeMonth = (event: React.FormEvent<HTMLSelectElement>) => {
    setMonth(event.currentTarget.value);
    event.preventDefault();
  };

  useEffect(() => {
    history.push({ pathname: `${url}/commission` });
  }, [url, history]);

  // FUNCTIONS:
  const handleRedirect = (e: any, to: string, index: number) => {
    e.preventDefault();
    const array = [];
    array[index] = true;
    setActive(array);
    history.push({ pathname: to });
    setCurrentPath(to);
  };

  // HTML:
  return (
    <>
      <Helmet>
        <title>Ma Facturation</title>
      </Helmet>
      <div className={classes.root}>
        <div className={classes.header}>
          <div className={classes.title}>
            <span>{lg.billing.title}</span>
          </div>

          <div className={classes.choice}>
            <div className={classes.spaceBetween}>
              <NativeSelect
                IconComponent={UnfoldMore}
                value={year}
                onChange={handleChange}
                input={<BootstrapInput />}
              >
                {years.map((min) => (
                  <option key={min} value={min}>
                    {min}
                  </option>
                ))}
              </NativeSelect>
            </div>

            <div className={classes.spaceBetween}>
              <NativeSelect
                IconComponent={UnfoldMore}
                value={month}
                onChange={handleChangeMonth}
                input={<BootstrapInputMonth />}
              >
                {months.map((mon, key) => (
                  <option key={mon} value={`0${key + 1}`.toString().slice(-2)}>
                    {mon}
                  </option>
                ))}
              </NativeSelect>
            </div>

            <BillingSummary
              year={year}
              month={month}
              garageId={currentGarage.id}
            />
          </div>

          <div className={classes.leftNavBar}>
            {Object.values(ViewsListEnum).map((view, index) => (
              <div
                key={view}
                className={
                  active[index] === true ? classes.helping : classes.help
                }
                onKeyPress={() => {}}
                role="button"
                tabIndex={0}
                onClick={(e) =>
                  handleRedirect(e, `${path}/${view.toString()}`, index)
                }
              >
                <div
                  className={
                    active[index] === true ? classes.linking : classes.link
                  }
                >
                  {lg.billingHeader[view]}
                </div>
              </div>
            ))}
          </div>
        </div>

        <Switch>
          {Object.values(ViewsListEnum).map((view) => (
            <Route key={view} path={`${path}/${view.toString()}`}>
              <OneView
                key={view}
                view={view}
                title={lg.billingHeader[view]}
                year={year}
                month={month}
                currentPath={currentPath}
              />
            </Route>
          ))}
        </Switch>
      </div>
    </>
  );
};

export default Billing;

import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import React, { useState } from 'react';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '../../../../assets/generic-components/Button';
import Title from '../../../../assets/generic-components/Title';
import Cell from '../../../../atomic/components/organisms/Cell';
import logo1 from '../../../../assets/style-elements/01-electrons-icon-alert-big.png';
import logoCalc from '../../../../assets/style-elements/total.svg';
import {
  FixedPrice,
  Liquid,
  PartOperation,
  Quote,
  RepairTime,
} from '../../../../redux/stores/planning/types';
import {
  setQuotes,
  updateOperationInQuotes,
} from '../../../../redux/stores/quotes/actions';
import { QuoteType } from '../../../../redux/stores/quotes/types';
import { Store } from '../../../../redux/types';
import getVatForGarage from '../../../../services/quote/vat';
import { getTotals } from '../../../../utils/booking';
import Table from '../../../command-page/components/table';
import { isQuoteOperationsHasOemParts } from '../../../../services/quote/operation';
import useStyles from './style';
import QuoteButtonFooter from './quoteButtonFooter';
import { getCurrentGarageLogo } from '../../../../utils/constants';
import { CountryCodes } from '../../../../redux/stores/global/types';

const alertIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
    />
  </svg>
);

const QuoteByAppointmentSummaryOrder = () => {
  const {
    languageReducer: { language: lg },
    quotesReducer: {
      quotes,
      currentVehicle,
      kmVehicle,
      releaseDate,
      currentRegistrationNumber,
    },
    globalReducer: { availableCarservices, currentGarage },
    planningStore: { currentBooking },
  } = useSelector((state: Store) => state);

  const dispatch = useDispatch();

  const history = useHistory();

  const goback = () => history.goBack();

  const classes = useStyles();

  const [adminEdit, setAdminEdit] = useState(false);

  if (quotes === null) {
    return null;
  }

  const [isSummaryUpdatable, setIsSummaryUpdatable] = useState(
    Array.from(quotes, () => false)
  );

  const vat = currentGarage ? getVatForGarage(currentGarage.garageAddress) : 0;

  const {
    totalDiscountless,
    totalDiscount,
    totalWithDiscounts,
    totalCoupon,
    totalVAT,
    totalBillingPriceVat,
    totalTaxeIncludedForkMax,
  } = getTotals(quotes, vat);

  const onOperationQtyDiscountUpdate = (
    operation: PartOperation | RepairTime | Liquid | FixedPrice,
    quote: Quote
  ) => {
    dispatch(updateOperationInQuotes(operation, quote));
  };

  const isWithForkPrice = () =>
    totalBillingPriceVat.toFixed(2) !== totalTaxeIncludedForkMax.toFixed(2);

  const toggleEditQuoteMode = (): void => {
    setAdminEdit((oldAdminEdit) => !oldAdminEdit);
  };

  const toggleEditButtons = (index: number, value: boolean) => {
    const newOpenState = [...isSummaryUpdatable];
    newOpenState[index] = value ?? !newOpenState[index];
    setIsSummaryUpdatable(newOpenState);
  };

  const updateCustomerComissionQuoteValue = () => {
    const comissionQuote = quotes.map((quote) => ({
      ...quote,
      commission: {
        garage: quote.commission ? quote.commission.garage : 0,
        customer: 0,
      },
    }));
    dispatch(setQuotes(comissionQuote));
  };

  const updateBookingQuote = (index: number) => {
    updateCustomerComissionQuoteValue();
    toggleEditQuoteMode();
    toggleEditButtons(index, false);
  };

  const cancelUpdateBookingQuote = (index: number) => {
    toggleEditQuoteMode();
    toggleEditButtons(index, false);
  };

  const isGarageBe = currentGarage?.garageAddress.country === CountryCodes.BE;

  return (
    <div className={classes.container}>
      <Helmet>
        <title>Devis 5s | Résumé</title>
      </Helmet>

      <div className={classes.boxTitle}>
        <div className={`${classes.wrapper} no-print`}>
          <Button theme="text" onClick={goback}>
            <KeyboardArrowLeftIcon className={classes.backwardGrey} />
            <p>{lg.quote.back}</p>
          </Button>
          <Button
            width="274px"
            theme="dark"
            onClick={() => window.print()}
            title={lg.command.print}
          />
        </div>
        <div className={`${classes.headerTitle} no-print`}>
          <Title title={lg.quote.create} />
        </div>
      </div>
      <div className={`${classes.wrapper} devis5s`}>
        <div className={`${classes.box1}`}>
          <div className={classes.flex}>
            <div className={`${classes.garageInfo} printVisibleFlex`}>
              <img src={getCurrentGarageLogo()} alt="logo garage" />
              <div>
                <h3>{currentGarage?.companyName}</h3>
                <address>
                  <p>{currentGarage?.garageAddress.address1}</p>
                  {currentGarage?.garageAddress.address2 && (
                    <p>{currentGarage.garageAddress.address2}</p>
                  )}
                  <p>
                    {currentGarage?.garageAddress.zipcode}{' '}
                    {currentGarage?.garageAddress.city}
                  </p>
                  <p>{currentGarage?.phone}</p>
                </address>
              </div>
            </div>
            <div className={classes.vehicleInfo}>
              <div className={classes.headerBoxContent}>
                <p className={classes.headerSubtitle}>
                  {currentVehicle?.version.model.make.label}{' '}
                  {currentVehicle?.label}
                </p>
                <p className={classes.headerDetails}>
                  {`${lg.quote.type}: ${currentVehicle?.ktypnr}`}
                </p>
                <p className={classes.headerDetails}>
                  {`${lg.quote.code}: ${currentVehicle?.labelMotor}`}
                </p>
                <p className={classes.headerDetails}>
                  {`${lg.quote.fuel}: ${currentVehicle?.energyType.toString()}`}
                </p>
                <p className={classes.headerDetails}>
                  {`${lg.quote.km}:`} <b>{kmVehicle} </b>
                </p>
                <p className={classes.headerDetails}>
                  {isGarageBe
                    ? `${lg.quote.vinCode}: ${
                        currentBooking?.vehicle?.vinCode || ''
                      }`
                    : `${lg.quote.immat}: ${currentRegistrationNumber}`}
                </p>
                <p className={classes.headerDetails}>
                  {`${lg.quote.releaseDate}:`}{' '}
                  <b>{moment(releaseDate).format('DD-MM-YYYY')} </b>
                </p>
              </div>
            </div>
          </div>
          {quotes &&
            quotes.map((quote: Quote, index: number) => (
              <div
                key={`quote-${quote.carServiceId}`}
                className={classes.listItem}
              >
                <div className={classes.listItemHeader}>
                  <p className={classes.headerTitle}>
                    {availableCarservices[quote.carServiceId]?.label}
                  </p>
                  <Button
                    className="no-print"
                    theme="withBorder"
                    title={lg.devis.updateQuote}
                    disabled={adminEdit}
                    onClick={() => {
                      toggleEditQuoteMode();
                      toggleEditButtons(index, true);
                    }}
                  />
                </div>
                {quote.quoteType !== QuoteType.PRICELESS ? (
                  <div>
                    {isQuoteOperationsHasOemParts(quote.operations) && (
                      <div className={`${classes.boxAlert} noprint`}>
                        <div className={classes.boxLogo}>{alertIcon}</div>
                        <p>{lg.quote.attention}</p>
                      </div>
                    )}
                    <div className="list">
                      <Table
                        quote={quote}
                        vat={vat}
                        onOperationQtyDiscountUpdate={(operation) =>
                          adminEdit
                            ? onOperationQtyDiscountUpdate(operation, quote)
                            : undefined
                        }
                        updateBookingQuote={() => updateBookingQuote(index)}
                        cancelUpdateBookingQuote={() =>
                          cancelUpdateBookingQuote(index)
                        }
                        isSummaryUpdatable={isSummaryUpdatable[index]}
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className={classes.boxAlert}>
                      <div className={classes.boxLogo}>
                        <img src={logo1} alt="logo" className="logo" />
                      </div>
                      <p>{lg.devis.pricelessMessage}</p>
                    </div>
                  </div>
                )}
              </div>
            ))}
        </div>
        <div className={classes.box2}>
          <div className={classes.wrapperTotal}>
            <img src={logoCalc} alt="logo" className="logo" />
            <h5>{lg.quote.total}</h5>
          </div>
          <div>
            <Cell title={lg.quote.totalOutDiscount} price={totalDiscountless} />
            <Cell title={lg.quote.totalDiscount} price={totalDiscount} />
            <Cell
              displayIf={totalCoupon > 0}
              title={lg.quote.totalCoupon}
              price={totalCoupon}
            />
            <Cell title={lg.quote.totalHT} price={totalWithDiscounts} />
            <Cell title={lg.quote.tva} price={totalVAT} />
            <Cell title={lg.quote.billingTTC} price={totalBillingPriceVat} />
            <Cell
              displayIf={isWithForkPrice()}
              title={lg.quote.billingMax}
              price={totalTaxeIncludedForkMax}
            />
          </div>
        </div>
        <div className={`${classes.info} printVisible`}>
          {lg.pdf.availability}
        </div>
      </div>
      <QuoteButtonFooter styleMode="container-btn" />
    </div>
  );
};

export default QuoteByAppointmentSummaryOrder;

import React, { useEffect } from 'react';
import useSWRImmutable from 'swr/immutable';
import { useDispatch } from 'react-redux';
import InputMutipleSelect from '../../assets/generic-components/InputMutipleSelect';
import { Language } from '../../redux/stores/languages';
import {
  errorNotificationOccurred,
  getVehicleModelsByMake,
} from '../../services/vehicle-identification-service';
import { setNotification } from '../../redux/stores/global/actions';
import { Version } from '../../redux/stores/quotes/types';

interface ModelsListProps {
  make: string;
  lg: Language;
  defaultOptionChoice: object;
  handleModelValueChanged: (value: string) => void;
}

const ModelsList: React.FC<ModelsListProps> = ({
  make,
  lg,
  defaultOptionChoice,
  handleModelValueChanged,
}) => {
  const { data, error } = useSWRImmutable<Version[] | null, any>(
    make !== '' ? `${make}/models` : null,
    getVehicleModelsByMake
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      dispatch(setNotification(errorNotificationOccurred(lg.error.server)));
    }
  }, [error]);

  const getFormattedData = (vehicleModels: Version[] | null) =>
    (vehicleModels || []).map((model) => ({
      label: model.label,
      value: `${model.model.id}_${model.id}`,
    }));

  return (
    <InputMutipleSelect
      width="578px"
      label={lg.quote.model}
      datas={[defaultOptionChoice, ...getFormattedData(data || null)]}
      onChange={(e) => handleModelValueChanged(e.currentTarget.value)}
      disabled={!data || data.length < 1}
    />
  );
};

export default ModelsList;

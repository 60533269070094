import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { InputHTMLAttributes, LegacyRef } from 'react';
import colors from '../style-colors';

interface InputLabelProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  reference: LegacyRef<HTMLTextAreaElement>;
  length: number;
}

const TextaeraLabel = ({
  // onChange,
  label,
  value,
  name,
  reference,
  length,
  onChange,
}: InputLabelProps) => {
  const textaeraStyles = makeStyles({
    formControl: {
      resize: 'none',
      width: '100%',
      height: '175px',
      display: 'block',
      padding: '10px 15px',
      fontSize: '13px',
      fontWeight: 400,
      lineHeight: '1.5',
      color: '#495057',
      backgroundColor: '#fff',
      backgroundClip: 'padding-box',
      border: 'none',
      borderRadius: '.25rem',
      transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
    },
    label: {
      color: colors.slateGrey,
      fontSize: '12px',
      fontWeight: 'bold',

      marginBottom: '10px',
    },
    length: {
      backgroundColor: 'white',
      width: '100%',
      textAlign: 'end',
      paddingRight: '10px',
      paddingBottom: '10px',
    },
  });

  const classes = textaeraStyles();
  return (
    <div className="form-group">
      <label htmlFor="textarea" className={classes.label}>
        {label}
      </label>
      <textarea
        ref={reference}
        name={name}
        value={value}
        onChange={onChange}
        maxLength={360}
        className={classes.formControl}
        rows={3}
      />
      <div className={classes.length}>
        <p className="labelLength">
          {length || 0}
          /360
        </p>
      </div>
    </div>
  );
};

export default TextaeraLabel;

import React from 'react';
import { CarService } from '../../../../redux/stores/global/types';

const QuoteChoicesCarService: React.FC<{
  carService: CarService;
  handleOnRemoveCarService: (id: string) => void;
}> = ({ carService, handleOnRemoveCarService }) => (
  <p>
    {carService.label}{' '}
    <span
      onClick={() => handleOnRemoveCarService(carService.id)}
      role="button"
      tabIndex={0}
    >
      X
    </span>
  </p>
);

export default QuoteChoicesCarService;

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AuthenticationService, {
  Roles
} from '../../services/authentication-service';
import RenderOnRoles from '../../assets/generic-components/RenderOnRoles';
import {
  setCurrentGarage,
  setGarageServices
} from '../../redux/stores/global/actions';
import { LanguageStore } from '../../redux/stores/languages';
import { setQuotes, setVehicle } from '../../redux/stores/quotes/actions';

interface SignOutProps {
  children: any;
  languageReducer: LanguageStore;
}

// REDUX
const mapStateToProps = ({
  languageReducer
}: {
  languageReducer: LanguageStore;
}) => ({
  languageReducer
});

/**
 * @desc Signout button in header, top right of web
 *       if use is admin -> dont show "Cancel" button
 *       if user is admin -> go to signinAdmin page
 *       if user is not admin -> go to signin page
 */
const SignOut: React.FC<SignOutProps> = ({ children, languageReducer }) => {
  // constants
  const lg = languageReducer.language;
  // state
  const [show, setShow] = useState(false);
  // custom hooks
  const history = useHistory();
  const dispatch = useDispatch();

  /**
   */
  const handleClose = () => setShow(false);

  const onChangeGarage = () => {
    history.push('/planning');
    dispatch(setCurrentGarage(null));
    dispatch(setGarageServices(null));
    dispatch(setQuotes([]));
    dispatch(setVehicle(null));
  };

  const onLogout = () => {
    dispatch({ type: 'RESET_STORE' });
    AuthenticationService.doLogout();
    history.push('/');
  };

  const handleShow = () => setShow(true);
  // HTML:

  return (
    <>
      <div
        onKeyPress={() => {}}
        role="button"
        tabIndex={0}
        onClick={handleShow}
      >
        {children}
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{lg.signOut.question}</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <RenderOnRoles roles={[Roles.CALL_CENTER, Roles.ADMIN]}>
            <Button variant="light" onClick={onChangeGarage}>
              {lg.signOut.changeGarage}
            </Button>
          </RenderOnRoles>
          <Button variant="light" onClick={handleClose}>
            {lg.signOut.cancel}
          </Button>
          <Button variant="dark" onClick={onLogout}>
            {lg.signOut.confirm}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

// EXPORT:

export default connect(mapStateToProps, {})(SignOut);

export enum VehicleEnergyTypes {
  B = 'Bifluel',
  D = 'Gazole',
  E = 'Essence',
  F = 'Superéthanol',
  G = 'GPL',
  H = 'Hybride essence et électrique non rechargeable',
  I = 'Hybride gazole et électrique non rechargeable',
  J = 'Hybride essence et électrique rechargeable',
  K = 'Hybride gazole et électrique rechargeable',
  L = 'Electrique',
  V = 'Gaz Naturel',
  Y = 'Hydrogène',
}

export type VehicleEnergy = keyof typeof VehicleEnergyTypes;

export default VehicleEnergyTypes;

import { Garage } from '../global/types';

/**
 * LOCALE
 */
export enum Country {
  FR = 'FR',
  NL = 'NL',
}

/**
 * GARAGE
 */

export interface Location {
  lat: number;
  lon: number;
}

export interface GarageAddres {
  address1: string;
  address2: string;
  zipcode: string;
  city: string;
  slugCity?: string;
  country: string;
  location?: Location;
}

export interface GarageBillingInfo {
  iban: string;
  bic: string;
}

export interface GarageNetwork {
  networkId: string;
  externalCodeId: number;
  createdAt: string[];
  updatedAt: string[];
}

export interface GarageBrand {
  brand: string;
  slugBrand: string;
  tradeMark: string;
}

export interface GarageExtraContent {
  lang: string;
  field: string;
  content: string;
}

export interface GarageExtraContentLanguages {
  fr?: GarageExtraContent;
  nl?: GarageExtraContent;
}

export interface GarageExtraContentServiceAdd {
  descriptionCertif: GarageExtraContentLanguages;
  descriptionService: GarageExtraContentLanguages;
}

export interface GarageStore {
  garages: Garage[];
  garagesLoading: boolean;
  brands: GarageBrand[];
}

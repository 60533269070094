import React, { ReactElement } from 'react';
import ErrorBoundary from '../../atomic/components/organisms/ErrorBoundary';
import MenuLeft from '../menu-left';
import logo1 from '../../assets/images/logo1.png';
import logo2 from '../../assets/images/logo2.svg';
import Header from '../header';
import logo2mini from '../../assets/images/logo2mini.svg';

interface LayoutProps {
  garageName: string;
  children: ReactElement<any>;
}

const Layout: React.FC<LayoutProps> = ({ garageName, children }) => (
  <ErrorBoundary>
    <>
      <MenuLeft
        logo1={logo1}
        logo2={logo2}
        garageName={garageName}
        logo2mini={logo2mini}
      />
      <Header />
      {children}
    </>
  </ErrorBoundary>
);

export default Layout;

import React from 'react';

const AccountIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    <path
      fill="#AAAEC5"
      d="M9 0c4.97 0 9 4.03 9 9s-4.03 9-9 9-9-4.03-9-9 4.03-9 9-9zM5.589 9.848l-3.87.773c.686 3.095 3.295 5.466 6.51 5.797l-.001-3.989c-1.296-.29-2.321-1.296-2.64-2.581zm4.183 2.581v3.989c3.214-.33 5.823-2.702 6.509-5.797l-3.87-.773c-.318 1.285-1.343 2.29-2.639 2.581zM9 7.03c-1.089 0-1.971.882-1.971 1.971S7.91 10.971 9 10.971 10.971 10.09 10.971 9 10.09 7.029 9 7.029zm0-5.486C4.882 1.543 1.543 4.882 1.543 9l.001.126 4.007-.802C5.866 6.707 7.29 5.486 9 5.486c1.71 0 3.134 1.22 3.45 2.838l4.006.803.001-.127c0-4.118-3.339-7.457-7.457-7.457z"
    />
  </svg>
);

export default AccountIcon;
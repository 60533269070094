import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import colors from '../style-colors';

interface TitleProps {
  title: string;
}

const Title = ({ title }: TitleProps) => {
  const titleStyles = makeStyles({
    title: {
      fontWeight: 'bold',
      color: colors.dark,
      fontSize: '20px',
      paddingBottom: '10px',
    },
  });
  const classes = titleStyles();

  return <h4 className={classes.title}>{title}</h4>;
};

export default Title;

import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import colors from '../style-colors';

interface CheckBoxRoundedProps {
  handleClick: () => void;
  isActive?: boolean;
  label?: string;
}

const CheckBoxRounded: React.FC<CheckBoxRoundedProps> = ({
  handleClick,
  isActive,
  label,
}) => {
  const useStyle = makeStyles({
    checkBoxRounded: {
      display: 'flex',
      alignItems: 'center',
      margin: '15px 0',
    },
    container: {
      position: 'relative',
      borderRadius: '22px',
      width: '40px',
      height: '22px',
      backgroundColor: '#AAAEC5',
      marginRight: '10px',
    },
    selector: {
      position: 'absolute',
      right: '2px',
      top: '2px',
      bottom: '2px',
      borderRadius: '50%',
      backgroundColor: 'white',
      width: '18px', // #83B02B
      zIndex: 5,
      transition: 'right 0.4s, background-color 0.4s',
    },
    active: {
      backgroundColor: colors.primary,
      '& > div': {
        right: 'calc(100% - 20px)',
      },
    },
    label: {
      margin: 0,
    },
  });

  const classes = useStyle();

  return (
    <div
      role="button"
      className={classes.checkBoxRounded}
      onClick={handleClick}
      tabIndex={0}
    >
      <div className={`${classes.container} ${isActive && classes.active}`}>
        <div className={`${classes.selector}`} />
      </div>
      <p className={classes.label}>{label}</p>
    </div>
  );
};

CheckBoxRounded.defaultProps = {
  isActive: false,
  label: '',
};

export default CheckBoxRounded;

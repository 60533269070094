import {
  ActionTypes,
  SET_ADDITIONAL_SERVICES,
  SET_CAR_SERVICES,
  SET_CURRENT_GARAGE,
  SET_CURRENT_LOCALE,
  SET_NOTIFICATION,
  SET_GARAGE_SERVICES,
  RESET_STORE
} from './actions';
import { GlobalStore, Locale } from './types';

const initialState: GlobalStore = {
  currentGarage: undefined,
  additionalServices: null,
  currentLocale: Locale.FR,
  availableCarservices: {},
  notification: null,
  garageServices: []
};

const globalReducer = (
  state: GlobalStore = initialState,
  action: ActionTypes
) => {
  switch (action.type) {
    case SET_CAR_SERVICES:
      return { ...state, availableCarservices: action.payload };
    case SET_CURRENT_GARAGE:
      return { ...state, currentGarage: action.payload };
    case SET_CURRENT_LOCALE:
      return { ...state, currentLocale: action.payload };
    case SET_NOTIFICATION:
      return { ...state, notification: action.payload };
    case SET_GARAGE_SERVICES:
      return { ...state, garageServices: action.payload };
    case SET_ADDITIONAL_SERVICES:
      return { ...state, additionalServices: action.payload };
    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
};

export default globalReducer;

import { makeStyles } from '@material-ui/core';
import React from 'react';
import PowerSettingsNewIcon from '../../../../assets/style-elements/01-electrons-icon-logout.png';
import colors from '../../../../assets/style-colors';

const LogoutButton = ({
  onClick
}: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  const useStyles = makeStyles(() => ({
    help: {
      cursor: 'pointer',
      border: '0 none',
      borderRadius: '8px',
      height: '40px',
      padding: '5px 12px 5px 12px',
      alignItems: 'center',
      display: 'flex',
      background: colors.paleGrey,
      '&:hover': {
        background: colors.veryLightBlue
      }
    },
    iconSignOut: {
      color: 'red'
    }
  }));

  const classes = useStyles();

  return (
    <button type="button" className={classes.help} onClick={onClick}>
      <img
        className={classes.iconSignOut}
        src={PowerSettingsNewIcon}
        alt="logout"
      />
    </button>
  );
};

export default LogoutButton;

import * as Sentry from '@sentry/react';
import { UserInfo } from './authentication-service';

/**
 * Logging custom error with extra context like user & additionnal data.
 * @param message
 * @param user
 * @param extras
 */
const logErrorWithContext = (
  message: string,
  user?: UserInfo,
  extras?: { key: string; data: unknown }[]
): void => {
  Sentry.withScope(scope => {
    scope.setUser(user || null);
    (extras || []).forEach(extra => {
      scope.setExtra(extra.key, extra.data);
    });
    Sentry.captureException(message);
  });
};

/**
 * Capture any exception in app.
 * @param exception
 */
const logException = (exception: any): string =>
  Sentry.captureException(exception);

const Logger = {
  logErrorWithContext,
  logException
};

export default Logger;

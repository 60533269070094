import { BillingClaim } from '../../redux/stores/billing-claim/type';
import { Billing, ViewsListEnum } from '../../redux/stores/billings/type';

export const vatBilling = 20;

export const createBillings = (
  id: string,
  label: string,
  type: string,
  amount: number
): Billing[] => [
  {
    id,
    garageId: id,
    quoteId: id,
    label,
    type,
    amount
  }
];

export const sumBillingsHt = (billings: Billing[]): number =>
  billings.reduce<number>(
    (accumulator: number, billing: Billing) => accumulator + billing.amount,
    0
  );

export const calculateVat = (amount: number): number =>
  (amount * vatBilling) / 100;

export const calculateTtcToTva = (amount: number): number =>
  Math.round(amount / (1 + vatBilling / 100));

export const getHtBilling = (amount: number, view: ViewsListEnum): number =>
  view === ViewsListEnum.SUBSCRIPTION ? calculateTtcToTva(amount) : amount;

export const calculateTtc = (
  amount: number,
  view: ViewsListEnum,
  ht: number
) => {
  if (
    view === ViewsListEnum.COMMISSION ||
    view === ViewsListEnum.VOUCHER ||
    ViewsListEnum.ADDITIONAL_VOUCHER
  ) {
    return ht + calculateVat(ht);
  }
  return amount;
};

export const getQuotesIdsOfClaims = (claims: BillingClaim[]): string[] => {
  const ids: string[] = [];
  claims.forEach((claim: BillingClaim) => ids.push(claim.quote_id));
  return ids;
};

export const yearsBilling = () => {
  const years: Array<number> = [];
  for (let y: number = 2015; y <= new Date().getFullYear(); y++) {
    years.push(y);
  }

  return years;
};

import _get from 'lodash/get';
import moment from 'moment';
import { Booking, BookingHistory } from '../../redux/stores/planning/types';

export const normalizeBooking = (bookingData: any): Booking => ({
  ...bookingData,
  bookingDate: _get(bookingData, 'bookingDate', moment('now').toISOString()),
  quotes: _get(bookingData, 'quotes', []),
  paymentStatus: _get(bookingData, 'paymentStatus', 'REFUSED'),
  paidByCreditCard: _get(bookingData, 'paidByCreditCard', 0),
  paidByWallet: _get(bookingData, 'paidByWallet', 0)
});

export const normalizeBookingHistory = (
  bookingHistory: any
): BookingHistory => ({
  ...bookingHistory,
  date: _get(bookingHistory, 'date', moment('now').toISOString()),
  fieldName: _get(bookingHistory, 'fieldName', '')
});

export default {
  type: 'object',
  properties: {
    data: {
      $ref: '#/definitions/Garage',
    },
  },
  required: ['data'],
  definitions: {
    Garage: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
        },
        codis: {
          type: 'number',
        },
        civility: {
          type: 'string',
        },
        lastname: {
          type: 'string',
        },
        firstname: {
          type: 'string',
        },
        password: {
          type: 'string',
        },
        companyName: {
          type: 'string',
        },
        slugCompanyName: {
          type: 'string',
        },
        siret: {
          type: 'string',
        },
        email: {
          type: 'string',
        },
        phone: {
          type: 'string',
        },
        mobilePhone: {
          type: 'string',
        },
        fax: {
          type: 'string',
        },
        numrcs: {
          type: 'string',
        },
        cliCertificateNumber: {
          type: 'string',
        },
        servicesAdd: {
          $ref: '#/definitions/ServicesAdd',
        },
        url: {
          type: 'string',
        },
        logo: {
          type: 'string',
        },
        state: {
          type: 'string',
        },
        parrain: {
          type: 'string',
        },
        superAdmin: {
          type: 'boolean',
        },
        confidence: {
          $ref: '#/definitions/CONFIDENCE',
        },
        confidenceUpdateAt: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
        dms: {
          type: 'string',
        },
        garageAddress: {
          $ref: '#/definitions/GarageAddres',
        },
        garageBillingAddress: {},
        garageBillingInfo: {
          $ref: '#/definitions/GarageBillingInfo',
        },
        garageCalendar: {
          $ref: '#/definitions/GarageCalendar',
        },
        garageNetwork: {
          type: 'array',
          items: {
            $ref: '#/definitions/GarageNetwork',
          },
        },
        garageBrand: {
          $ref: '#/definitions/GarageBrand',
        },
        garageExtraContent: {
          type: 'array',
          items: {
            $ref: '#/definitions/GarageExtraContent',
          },
        },
        createdAt: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
        updatedAt: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
        distance: {
          type: ['null', 'number'],
        },
        favoriteLanguage: {
          type: 'string',
        },
        sadPartenaireIds: {
          type: 'array',
          items: {},
        },
        membership: {
          $ref: '#/definitions/Membership',
        },
        garageTest: {
          type: 'boolean',
        },
        autossimo: {
          anyOf: [
            {
              $ref: '#/definitions/AutossimoLogin',
            },
            {
              type: 'null',
            },
          ],
        },
      },
      required: [
        'civility',
        'cliCertificateNumber',
        'codis',
        'companyName',
        'confidence',
        'confidenceUpdateAt',
        'createdAt',
        'dms',
        'email',
        'favoriteLanguage',
        'fax',
        'firstname',
        'garageAddress',
        'garageBillingInfo',
        'garageBrand',
        'garageCalendar',
        'garageExtraContent',
        'garageNetwork',
        'garageTest',
        'id',
        'lastname',
        'logo',
        'mobilePhone',
        'numrcs',
        'parrain',
        'password',
        'phone',
        'sadPartenaireIds',
        'servicesAdd',
        'siret',
        'slugCompanyName',
        'state',
        'superAdmin',
        'updatedAt',
        'url',
      ],
    },
    ServicesAdd: {
      description: 'GARAGE',
      type: 'object',
      properties: {
        rep_facilire_paie: {
          type: 'string',
        },
        rep_garage_mobile: {
          type: 'string',
        },
        rep_service_domicile: {
          type: 'string',
        },
      },
    },
    CONFIDENCE: {
      enum: ['PREMIUM', 'STANDARD'],
      type: 'string',
    },
    GarageAddres: {
      type: 'object',
      properties: {
        address1: {
          type: 'string',
        },
        address2: {
          type: 'string',
        },
        zipcode: {
          type: 'string',
        },
        city: {
          type: 'string',
        },
        slugCity: {
          type: 'string',
        },
        country: {
          type: 'string',
        },
        location: {
          $ref: '#/definitions/Location',
        },
      },
      required: [
        'address1',
        'address2',
        'city',
        'country',
        'location',
        'slugCity',
        'zipcode',
      ],
    },
    Location: {
      type: 'object',
      properties: {
        lat: {
          type: 'number',
        },
        lon: {
          type: 'number',
        },
      },
      required: ['lat', 'lon'],
    },
    GarageBillingInfo: {
      type: 'object',
      properties: {
        iban: {
          type: 'string',
        },
        bic: {
          type: 'string',
        },
      },
      required: ['bic', 'iban'],
    },
    GarageCalendar: {
      type: 'object',
      properties: {
        standardWeek: {
          $ref: '#/definitions/StandardWeek',
        },
        exceptionalWeeks: {},
      },
      required: ['standardWeek'],
    },
    StandardWeek: {
      type: 'object',
      properties: {
        normalDelay: {
          type: 'number',
        },
        complexDelay: {
          type: 'number',
        },
        rdvDay1: {
          type: 'number',
        },
        rdvDay2: {
          type: 'number',
        },
        rdvDay3: {
          type: 'number',
        },
        rdvDay4: {
          type: 'number',
        },
        rdvDay5: {
          type: 'number',
        },
        rdvDay6: {
          type: 'number',
        },
        rdvDay7: {
          type: 'number',
        },
        scheduleDay1: {
          type: 'string',
        },
        scheduleDay2: {
          type: 'string',
        },
        scheduleDay3: {
          type: 'string',
        },
        scheduleDay4: {
          type: 'string',
        },
        scheduleDay5: {
          type: 'string',
        },
        scheduleDay6: {
          type: 'string',
        },
        scheduleDay7: {
          type: 'string',
        },
      },
      required: [
        'complexDelay',
        'normalDelay',
        'rdvDay1',
        'rdvDay2',
        'rdvDay3',
        'rdvDay4',
        'rdvDay5',
        'rdvDay6',
        'rdvDay7',
        'scheduleDay1',
        'scheduleDay2',
        'scheduleDay3',
        'scheduleDay4',
        'scheduleDay5',
        'scheduleDay6',
        'scheduleDay7',
      ],
    },
    GarageNetwork: {
      type: 'object',
      properties: {
        networkId: {
          type: 'string',
        },
        externalCodeId: {
          type: 'number',
        },
        createdAt: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
        updatedAt: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
      },
      required: ['createdAt', 'externalCodeId', 'networkId', 'updatedAt'],
    },
    GarageBrand: {
      type: 'object',
      properties: {
        brand: {
          type: 'string',
        },
        slugBrand: {
          type: 'string',
        },
        tradeMark: {
          type: 'string',
        },
      },
      required: ['brand', 'slugBrand', 'tradeMark'],
    },
    GarageExtraContent: {
      type: 'object',
      properties: {
        lang: {
          type: 'string',
        },
        field: {
          type: 'string',
        },
        content: {
          type: 'string',
        },
      },
      required: ['content', 'field', 'lang'],
    },
    Membership: {
      type: 'object',
      properties: {
        activatedAt: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
        amount: {
          type: 'number',
        },
        trial: {
          type: 'number',
        },
        comments: {
          type: 'string',
        },
        enrolledBy: {
          type: 'string',
        },
      },
      required: ['activatedAt', 'amount', 'comments', 'enrolledBy', 'trial'],
    },
    AutossimoLogin: {
      type: 'object',
      properties: {
        login: {
          type: 'string',
        },
        password: {
          type: 'string',
        },
      },
      required: ['login', 'password'],
    },
  },
  $schema: 'http://json-schema.org/draft-07/schema#',
};

const userActions = {};

userActions.setUser = (dispath, userData) => {
  dispath({
    type: 'SET_USER',
    payload: userData
  });
};

userActions.setAdmin = (dispath, adminData) => {
  dispath({
    type: 'SET_ADMIN',
    payload: adminData
  });
};

userActions.logIn = (dispath, isAdmin) => {
  dispath({
    type: 'LOG_IN',
    isAdmin
  });
};

userActions.logOut = dispatch =>
  dispatch({
    type: 'LOG_OUT'
  });

userActions.logOutAdmin = dispatch =>
  dispatch({
    type: 'LOG_OUT_ADMIN'
  });

export default userActions;

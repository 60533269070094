import { Alert, AlertTitle } from '@material-ui/lab';
import { isInteger } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Button from '../../../../assets/generic-components/Button';
import CheckboxLabel from '../../../../assets/generic-components/CheckboxLabel';
import InputLabel from '../../../../assets/generic-components/InputLabel';
import Title from '../../../../assets/generic-components/Title';
import colors from '../../../../assets/style-colors';
import InfoBubble from '../../../../atomic/components/atoms/infoBubble';
import { saveCalendar } from '../../../../redux/stores/calendar/actions';
import { Calendar, RegularWeek } from '../../../../redux/stores/calendar/types';
import { setNotification } from '../../../../redux/stores/global/actions';
import { SeverityMessage } from '../../../../redux/stores/global/types';
import { Store } from '../../../../redux/types';
import ListItem from './ListItem';
import {
  isLunchBreaksConcernAllDays,
  standardDay,
  toggleLunchBreaks,
  updateDay,
} from './schedules.service';

const Schedules: React.FC<{ className?: string }> = ({ className }) => {
  const {
    languageReducer: { language: lg },
    calendarStore: { calendar },
    globalReducer: { currentGarage },
  } = useSelector((state: Store) => state);

  const [regularWeek, setRegularWeek] = useState<RegularWeek[]>(
    calendar?.regularWeek || Array(7).fill(standardDay)
  );
  const [validateHours] = useState(false);
  const [lunchBreaksAllDays, setLunchBreaksAllDays] = useState(false);
  const [delay, setDelay] = useState(calendar?.delays.t1 || 0);
  const dispatch = useDispatch();
  const { semaineType } = lg.planning.modification;

  useEffect(() => {
    setLunchBreaksAllDays(isLunchBreaksConcernAllDays(regularWeek));
  }, [regularWeek]);

  useEffect(() => {
    if (calendar) {
      setRegularWeek(calendar.regularWeek);
      setDelay(calendar?.delays.t1 || 0);
    }
  }, [calendar]);

  const handleSave = () => {
    if (!currentGarage) {
      return;
    }
    const newCalendar: Calendar = {
      delays: {
        t1: delay,
        t2: delay,
        t3: delay,
      },
      regularWeek: regularWeek.map((week) => ({
        ...week,
        slots: isInteger(week.slots) ? week.slots : 1,
      })),
      garageId: currentGarage.id,
    };
    dispatch(
      saveCalendar(
        currentGarage.id,
        newCalendar,
        !calendar,
        () =>
          dispatch(
            setNotification({
              severity: SeverityMessage.SUCCESS,
              message: lg.general.success,
            })
          ),
        () =>
          dispatch(
            setNotification({
              severity: SeverityMessage.ERROR,
              message: lg.error.server,
            })
          )
      )
    );
  };

  return (
    <>
      <Helmet>
        <title>Mon garage | Semaine standard</title>
      </Helmet>
      <div className={`tw-flex tw-flex-col tw-justify-center tw-w-2/4 tw-min-h-screen tw-px-0 tw-py-12 ${className}`}>
        <Title title={semaineType.openingHours} />
        <h2 className="tw-font-bold tw-text-sm tw-mt-6">{semaineType.daysOpenedForWeek}</h2>
        <div className="tw-flex tw-pt-2">
          {regularWeek.slice(0, 6).map((currentDay, dayIndex) => (
            <Button
              className="tw-capitalize hover:tw-border"
              key={dayIndex}
              active={
                currentDay.slots === 0 ||
                (currentDay.openingHours || []).length === 0
              }
              onClick={(e) => {
                updateDay(dayIndex)(
                  {
                    ...currentDay,
                    slots:
                      currentDay.slots === 0 ||
                      currentDay.openingHours.length === 0
                        ? calendar?.regularWeek[dayIndex].slots || 1
                        : 0,
                  },
                  setRegularWeek,
                  regularWeek
                );
                e.currentTarget.blur();
              }}
              theme="lightV2"
              title={moment()
                .day(dayIndex + 1)
                .format('dddd')}
            />
          ))}
        </div>
        <div className="tw-mt-7 tw-mb-1">
          <InputLabel
            width="70px"
            name="delay"
            label={semaineType.delay}
            type="number"
            min={0}
            value={delay}
            onChange={(e) => setDelay(parseInt(e.target.value))}
            bubbleText={semaineType.delayBubble}
          />
        </div>
        <h2 className="tw-font-bold tw-text-sm tw-mt-6">{semaineType.openingByDay}</h2>

        <div className="tw-flex tw-flex-col tw-pt-2 custom-checkbox">
          <CheckboxLabel
            checked={!lunchBreaksAllDays}
            onchange={() => {
              setLunchBreaksAllDays(!lunchBreaksAllDays);
              toggleLunchBreaks(regularWeek, setRegularWeek);
            }}
            label={lg.rates.break}
          />
        </div>

        <div className="tw-flex">
          {validateHours && (
            <Alert severity={SeverityMessage.ERROR}>
              <AlertTitle>{lg.rates.checkSchedules}</AlertTitle>
            </Alert>
          )}
        </div>

        <div className="tw-max-w-[650px] custom-space">
          <div className="tw-flex tw-justify-between tw-items-center tw-h-auto tw-w-full tw-pl-16 tw-pt-9">
            <h3 className="tw-text-xs tw-uppercase">{semaineType.hours}</h3>
            <h3 className="tw-text-xs tw-uppercase">
              {semaineType.hoursCount}{' '}
              <InfoBubble infoText={semaineType.hoursCountBubble} />
            </h3>
          </div>
          {regularWeek.slice(0, 6).map((currentDay, dayIndex) => (
            <ListItem
              key={dayIndex}
              day={currentDay}
              label={moment()
                .day(dayIndex + 1)
                .format('dddd')}
              updateDay={(day) =>
                updateDay(dayIndex)(day, setRegularWeek, regularWeek)
              }
            />
          ))}
        </div>

        <Button
          className="tw-w-full tw-max-w-[247px] tw-mt-14"
          theme="dark"
          title={lg.rates.save}
          onClick={handleSave}
        />
      </div>
    </>
  );
};

Schedules.defaultProps = {
  className: undefined,
};

export default styled(Schedules)`
  .custom-checkbox {
    label {
      span {
        padding: 0 0 0 9px;

        font-weight: 400;

        span {
          padding: 0;
        }
      }
    }
  }

  .custom-space {
    > div {
      &:first-child {
        padding-top: 36px;
      }

      padding-top: 0px;
      display: flex;
      align-items: center;
      height: auto;

      .mainContainer {
        margin-left: -27px;

        select {
          font-size: 13px;
          color: ${colors.dark};
        }
      }
    }
  }
`;

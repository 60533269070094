import React from 'react';
import { Link } from 'react-router-dom';
import RenderOnRoles from '../../assets/generic-components/RenderOnRoles';

type SmallMenuProps = {
  index: number;
  role: any[];
  classNameActive: string;
  redirection: any;
  link: string;
  className: string;
  icon: any;
};

export const SmallMenu = ({
  index,
  role,
  classNameActive,
  redirection,
  link,
  className,
  icon
}: SmallMenuProps) => (
  <RenderOnRoles key={`${index}icon-small`} roles={role}>
    <Link
      className={classNameActive}
      onClick={redirection}
      to={link}
      target="_blank"
    >
      <span className={className}>{icon}</span>
    </Link>
  </RenderOnRoles>
);

type LargeMenuProps = {
  classNameIcon: string;
  classNameText: string;
  name: string;
} & SmallMenuProps;

export const LargeMenu = ({
  index,
  role,
  classNameActive,
  classNameIcon,
  classNameText,
  redirection,
  link,
  className,
  icon,
  name
}: LargeMenuProps) => (
  <RenderOnRoles key={`${index}icon`} roles={role}>
    <Link
      className={classNameActive}
      onClick={redirection}
      to={link}
      target="_blank"
    >
      <div className={classNameIcon}>
        <span className={className}>{icon}</span>
      </div>
      <div className={classNameText}>{name}</div>
    </Link>
  </RenderOnRoles>
);

import Modal from 'react-bootstrap/Modal';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '../../../assets/generic-components/Button';
import colors from '../../../assets/style-colors';
import { Store } from '../../../redux/types';
import { saveBillingClaim } from '../../../redux/stores/billing-claim/action';
import { setNotification } from '../../../redux/stores/global/actions';
import { Billing } from '../../../redux/stores/billings/type';
import { SeverityMessage } from '../../../redux/stores/global/types';

interface ContestProps {
  children: any;
  billing: Billing;
  iContest: Function;
}
const Contest: React.FC<ContestProps> = ({ children, billing, iContest }) => {
  const useStyles = makeStyles(() => ({
    modalFooter: {
      background: '#f6f7fb',
      borderTop: '0px solid #f6f7fb',
      padding: '20px',
      '& [class*="makeStyles-light"]': {
        color: colors.slateGrey,
      },
    },
    root: {
      position: 'relative',
      marginTop: '-10px',
    },
    content: {
      width: '530px',
      margin: '0 auto',
      '& .cuzPop': {
        padding: '30px 30px 0px 30px',
        border: '0px solid white',
      },
      '& .cuzBody': {
        padding: '15px 30px 30px 30px',
      },
    },
    head: {
      fontSize: '15px',
      marginTop: '5px',

      color: colors.dark,
      marginBottom: '20px',
      display: 'block',
    },
    title: {
      fontSize: '12px',
      color: colors.slateGrey,
      marginBottom: '5px',
    },
    subtitle: {
      fontSize: '12px',
      lineHeight: '17px',
      color: colors.slateGrey,
      marginBottom: '20px !important',
    },
    hide: {
      display: 'none',
    },
    error: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '10px',
      color: 'red',
      fontWeight: 'bolder',
    },
    textarea: {
      background: colors.paleGrey,
      padding: '15px',
      borderRadius: '8px',
      border: '0',
      fontSize: '13px',
      width: '100%',
      height: '175px',
      '&::placeholder': {
        color: colors.dark,
      },
    },
    count: {
      position: 'absolute',

      fontSize: '13px',
      color: colors.slateGrey,
      bottom: '15px',
      right: '15px',
    },
  }));

  const {
    languageReducer: { language: lg },
    globalReducer: { currentGarage },
    billingsReducer: { currentBill },
  } = useSelector((state: Store) => state);

  const dispatch = useDispatch();

  // CONSTANTS:
  const classes = useStyles();
  // const { children, languageReducer } = props;
  const [show, setShow] = useState(false);
  const [contested, setContested] = useState(false);
  const [len, setLength] = useState(0);
  const [disable, setDisable] = useState(true);
  const [comment, setComment] = useState('');
  const [errorForm, setErrorForm] = useState({ display: false, message: '' });

  // FUNCTIONS:

  const handleClose = () => {
    if (contested) {
      iContest();
    }
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const handleClick = () => {
    if (currentBill && currentGarage) {
      dispatch(
        saveBillingClaim(
          {
            bill_id: currentBill.billNumber,
            garage_id: currentGarage.id,
            quote_id: billing.quoteId,
            designation: billing.label,
            amount: billing.amount,
            comment,
          },
          () => {
            setContested(true);
          },
          (error: Error) => {
            setContested(false);
            dispatch(
              setNotification({
                severity: SeverityMessage.ERROR,
                message: error.message,
              })
            );
          }
        )
      );
    }
  };

  const handleKeyUp = (e: React.FormEvent<HTMLTextAreaElement>) => {
    setComment(e.currentTarget.value);
    setLength(e.currentTarget.value.length);
    if (e.currentTarget.value.length > 360) {
      setErrorForm({ display: true, message: lg.billing.contest.error });
      setDisable(true);
    } else if (e.currentTarget.value.length <= 5) {
      setDisable(true);
    } else {
      setErrorForm({ display: false, message: '' });
      setDisable(false);
    }
  };

  return (
    <>
      <div
        onKeyPress={() => {}}
        role="button"
        tabIndex={0}
        onClick={handleShow}
      >
        {children}
      </div>
      <Modal
        dialogClassName={classes.content}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="cuzPop" closeButton>
          <div className={classes.root}>
            <span className={classes.head}>{lg.billing.contest.title}</span>
          </div>
        </Modal.Header>
        <Modal.Body className="cuzBody">
          {!contested ? (
            <div className={classes.root}>
              <p className={classes.title}>{billing.quoteId}</p>
              <p className={classes.subtitle}>{billing.label}</p>
              <textarea
                className={classes.textarea}
                placeholder={lg.billing.contest.comment}
                name="description"
                onFocus={() => {}}
                onMouseOver={handleKeyUp}
                onKeyUp={handleKeyUp}
              />
              <span className={classes.count}>
                {len}
                /360
              </span>
              <span
                className={errorForm.display ? classes.error : classes.hide}
              >
                {errorForm.message}
              </span>
            </div>
          ) : (
            <div className={classes.root}>
              <p className={classes.title}>{lg.billing.contest.signal}</p>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer className={classes.modalFooter}>
          {!contested ? (
            <>
              <Button
                theme="light"
                title={lg.billing.contest.close}
                onClick={() => handleClose()}
              />
              <Button
                theme="dark"
                title={lg.billing.contest.cancel}
                disabled={disable}
                onClick={() => handleClick()}
              />
            </>
          ) : (
            <Button
              theme="light"
              title={lg.billing.contest.back}
              onClick={() => handleClose()}
            />
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Contest;

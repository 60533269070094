import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { Store } from '../../../redux/types';
import { setNotification } from '../../../redux/stores/global/actions';
import { SeverityMessage } from '../../../redux/stores/global/types';
import {
  setBooking,
  updateCustomer,
} from '../../../redux/stores/planning/actions';
import Button from '../../../assets/generic-components/Button';
import close from '../../../assets/style-elements/01-electrons-icon-close.png';
import useStyles from './stylesModal';
import { Booking } from '../../../redux/stores/planning/types';
import PhoneField from '../../../assets/generic-components/phoneInput';
import InputLabel from '../../../assets/generic-components/InputLabel';

interface ContactDetailsFormValues {
  email: string;
  phone: string;
  lastName: string;
  firstName: string;
}

const EditContact: React.FC = ({ children }) => {
  const style = useStyles();
  const {
    languageReducer,
    planningStore: { currentBooking },
  } = useSelector<Store, Store>((state) => state);

  const lg = languageReducer.language;
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const { register, handleSubmit, errors, control, reset } =
    useForm<ContactDetailsFormValues>({
      mode: 'onChange',
      defaultValues: {
        firstName: currentBooking?.customer.firstName,
        lastName: currentBooking?.customer.lastName,
        phone: currentBooking?.customer.phone,
        email: currentBooking?.customer.email,
      },
    });

  if (!currentBooking) return null;

  const onSubmit = (values: ContactDetailsFormValues): void => {
    const customerInfo = {
      email: values.email,
      phone: values.phone,
      lastName: values.lastName,
      firstName: values.firstName,
    };
    dispatch(
      updateCustomer(
        currentBooking?.bookingId || '',
        customerInfo,
        () => {
          const newBooking: Booking = {
            ...currentBooking,
            customer: customerInfo,
          };
          dispatch(setBooking(newBooking));

          dispatch(
            setNotification({
              severity: SeverityMessage.SUCCESS,
              message: lg.general.success,
            })
          );
          setShow(false);
        },
        (error: Error) =>
          dispatch(
            setNotification({
              severity: SeverityMessage.ERROR,
              message: error.message,
            })
          )
      )
    );

    reset(customerInfo);
  };

  return (
    <div>
      <div role="button" tabIndex={0} onClick={handleShow}>
        {children}
      </div>
      <Modal
        dialogClassName={style.content}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className={style.modalHeader}>
          <span className={style.titleModal}>{lg.command.user.title}</span>
          <img
            className="imgPopup"
            src={close}
            alt="X"
            onClick={() => handleClose()}
          />
        </Modal.Header>

        <Modal.Body>
          <div className={style.colWidth}>
            <InputLabel
              label={lg.quote.requiredFirstname}
              name="firstName"
              reference={register({
                required: true,
                maxLength: 20,
              })}
            >
              {errors.firstName &&
                errors.firstName.type === 'required' &&
                lg.quote.messageField}
            </InputLabel>

            <InputLabel
              label={lg.quote.lastName}
              name="lastName"
              reference={register({
                required: true,
                maxLength: 20,
              })}
            >
              {errors.lastName &&
                errors.lastName.type === 'required' &&
                lg.quote.messageField}
            </InputLabel>
            <div className={style.phoneWrapper}>
              <span className={style.labelPhone}>{lg.quote.requiredPhone}</span>
              <Controller
                as={
                  <PhoneField reset="" errorMessage={lg.quote.messagePhone} />
                }
                control={control}
                name="phone"
                reference={register({
                  required: true,
                })}
              />
              {errors.phone && errors.phone.type === 'pattern'}
            </div>
            <InputLabel
              name="email"
              label={lg.quote.requiredEmail}
              reference={register({
                required: true,
                pattern: {
                  message: lg.quote.messageEmail,
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                },
              })}
            >
              {errors.email &&
                errors.email.type === 'pattern' &&
                lg.quote.messageEmail}
              {errors.email &&
                errors.email.type === 'required' &&
                lg.quote.messageField}
            </InputLabel>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            theme="light"
            title={lg.command.user.close}
            onClick={() => handleClose()}
          />
          <Button
            theme="dark"
            title={lg.command.user.confirm}
            onClick={handleSubmit(onSubmit)}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditContact;

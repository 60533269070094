import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useStyles from './style';
import dataLoginGarage from '../../assets/api/dataLoginGarage';
import logo from '../../assets/images/logo.svg';
import analytics from '../../lib/events/analytics';
import { getGaragesByName } from '../../redux/stores/garages/actions';
import { setGarage } from '../../redux/stores/global/actions';
import AuthenticationService from '../../services/authentication-service';
import ConnexionHeader from '../header/signIn';
import { Store } from '../../redux/types';
import { Garage } from '../../redux/stores/global/types';

const SignInAdmin = () => {
  const {
    languageReducer: { language: lg },
    garagesReducer: { garages, garagesLoading },
  } = useSelector((state: Store) => state);
  const dispatch = useDispatch();
  const logOut = () => AuthenticationService.doLogout();

  const history = useHistory();
  const [raisonSocial, setRaisonSocial] = useState(true);
  const [adressEmail, setAdressEmail] = useState(false);
  const [codeReparateur, setCodeReparateur] = useState(false);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [companyName, setCompanyName] = useState('garage');
  const [error, setError] = useState({ display: false, message: '' });

  useEffect(() => {
    dispatch(getGaragesByName(companyName));
  }, [companyName]);

  const switchGarage = (garageId: string) => {
    analytics.track('switch_garage', { garageId });
    dispatch({ type: 'RESET_STORE' });
    dispatch(setGarage(garageId));
    history.push('/planning');
  };

  const handleEmailChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setEmail(e.target.value);
  };

  const handleGarageChange = (_e: unknown, newValue: Garage | null) => {
    if (newValue) {
      switchGarage(newValue.id);
    }
  };

  const handleCodeChange = (e: any) => {
    setGarage(e.target.value);
    setCode(e.target.value);
  };

  const handleLogin = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (codeReparateur) {
      if (!code) {
        setError({ display: true, message: lg.loginGarage.code });
      } else {
        setError({ display: false, message: '' });
        switchGarage(code);
      }
    }
  };

  const handleLogout = () => {
    dispatch({ type: 'RESET_STORE' });
    logOut();
    history.push('/');
  };

  const handleRaisonFocus = () => {
    setRaisonSocial(true);
    setAdressEmail(false);
    setCodeReparateur(false);
  };

  const handleCodeFocus = () => {
    setRaisonSocial(false);
    setAdressEmail(false);
    setCodeReparateur(true);
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.form}>
        <ConnexionHeader />

        <div className={classes.wrapperSignIn}>
          <div>
            <img src={logo} alt="logo" className={classes.logo} />
            <div className={classes.connect}>{lg.loginGarage.title}</div>
            <span className={error.display ? classes.error : classes.hide}>
              {error.message}
            </span>
            <span
              className={
                dataLoginGarage[0].errorApi !== ''
                  ? classes.error
                  : classes.hide
              }
            >
              {dataLoginGarage[0].errorApi !== ''
                ? dataLoginGarage[0].errorApi
                : ''}
            </span>
            <div className={classes.block}>
              <div
                className={raisonSocial ? 'focusleft' : 'unfocusleft'}
                onClick={handleRaisonFocus}
                role="button"
                tabIndex={0}
              >
                {lg.loginGarage.socialRaison}
              </div>
              <div
                className={codeReparateur ? 'focusright' : 'unfocusright'}
                onClick={handleCodeFocus}
                role="button"
                tabIndex={0}
              >
                {lg.loginGarage.codeRep}
              </div>
            </div>
            {raisonSocial && (
              <div className={classes.email}>
                <label htmlFor="garage">{lg.loginGarage.garageField}</label>
                <Autocomplete
                  id="combo-box-demo"
                  loading={garagesLoading}
                  options={garages}
                  onInputChange={(_event, newInputValue) => {
                    setCompanyName(newInputValue);
                  }}
                  onChange={handleGarageChange}
                  getOptionLabel={(option) => option.companyName}
                  renderOption={(option) => (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <div style={{ fontWeight: 'bold' }}>
                        {option.companyName} ({option.id})
                      </div>
                      <div>{option.garageAddress?.address1}</div>
                      <div>
                        {option.garageAddress?.zipcode}{' '}
                        {option.garageAddress?.city}
                      </div>
                    </div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      className={classes.textFieldForm}
                      {...params}
                      label=""
                      variant="outlined"
                    />
                  )}
                />
              </div>
            )}
            {adressEmail && (
              <div className={classes.email}>
                <label htmlFor="email">{lg.loginGarage.emailField}</label>
                <input
                  type="text"
                  value={email}
                  onChange={handleEmailChange}
                  name="email"
                  id="email"
                  pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
                  title="UpperCase, LowerCase, Number/SpecialChar and min 8 Chars"
                />
              </div>
            )}
            {codeReparateur && (
              <div className={classes.email}>
                <label htmlFor="garage">{lg.loginGarage.codeField}</label>
                <input
                  className={classes.codeReparateur}
                  type="text"
                  value={code}
                  onChange={handleCodeChange}
                  name="garage"
                  id="garage"
                />
              </div>
            )}
            <div className={classes.send}>
              <button
                className="connection"
                type="button"
                onClick={handleLogin}
              >
                {lg.loginGarage.connectButton}
              </button>
              <button
                className="deconnexion"
                type="button"
                onClick={handleLogout}
              >
                {lg.loginGarage.deconnectionButton}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.photo} />
    </div>
  );
};

export default SignInAdmin;

import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import colors from '../../../../assets/style-colors';

interface ItemProps {
  header: string;
  value: number;
  onChange: any;
}

const Item: React.FC<ItemProps> = ({
  header,
  value,
  onChange
}) => {

  const useStyles = makeStyles(() => ({
    row: {
      display: 'flex',
      flexWrap: 'wrap',
      position: 'relative',
      justifyContent: 'space-between'
    },
    form: {
      appearance: 'none',
      width: '100px',
      height: '40px',
      textAlign: 'center',
      backgroundColor: colors.white,
      border: '1px solid transparent',
      padding: '.375rem .75rem',
      fontSize: '12px',
      fontWeight: 'bold',
      borderRadius: '4px',
      color: colors.dark
    },
    formHours: {
      textAlign: 'left',
      width: '60%'
    }
  }));
  const classes = useStyles();

  return (
    <>
      <div className={classes.row}>
        <div>{header}</div>
      </div>
      <div className={classes.row}>
        <div className={classes.formHours}>
          <input
            type="number"
            className={`${classes.formHours} ${classes.form}`}
            value={value}
            onChange={onChange}
            min={0}
          />
        </div>
      </div>
    </>
  );
};

export default Item;

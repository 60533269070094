import { AxiosError } from 'axios';
import moment, { Moment } from 'moment';
import { Action, Store } from 'redux';
import { ThunkAction } from 'redux-thunk';
import HttpService from '../../../services/http-service';
import { Range } from '../global/types';
import { Availability, Calendar } from './types';

export const SET_AVAILABILITIES = 'SET_AVAILABILITIES';
export const ADD_AVAILABILITIES = 'ADD_AVAILABILITIES';
export const REMOVE_AVAILABILITY = 'REMOVE_AVAILABILITY';
export const SET_CALENDAR = 'SET_CALENDAR';
export const SET_CALENDAR_INITIALIZED = 'SET_CALENDAR_INITIALIZED';

export type ActionTypes =
  | { type: typeof SET_AVAILABILITIES; payload: Availability[] }
  | { type: typeof ADD_AVAILABILITIES; payload: Availability[] }
  | { type: typeof REMOVE_AVAILABILITY; payload: string }
  | { type: typeof SET_CALENDAR; payload: Calendar }
  | { type: typeof SET_CALENDAR_INITIALIZED; payload: boolean };

const setCalendar = (calendar: Calendar): ActionTypes => ({
  type: SET_CALENDAR,
  payload: calendar,
});

const setCalendarInitialized = (isInitialized: boolean): ActionTypes => ({
  type: SET_CALENDAR_INITIALIZED,
  payload: isInitialized,
});

export const getCalendar =
  (garageId: string): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    HttpService.getClient()
      .get(`${process.env.REACT_APP_API_CALENDAR_URL}/${garageId}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'api-key': process.env.REACT_APP_API_CALENDAR_KEY,
          ts: new Date().getTime(),
        },
      })
      .then((response) => dispatch(setCalendar(response.data)))
      .catch(() => {
        dispatch(setCalendarInitialized(false));
      });
  };

export const saveCalendar =
  (
    garageId: string,
    newCalendar: Calendar,
    createCalendar: boolean,
    success: () => void,
    failure: (error: Error) => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    HttpService.getClient()
      .post(`${process.env.REACT_APP_API_CALENDAR_URL}`, newCalendar, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'api-key': process.env.REACT_APP_API_CALENDAR_KEY,
        },
      })
      .then(() => {
        dispatch(setCalendar(newCalendar));
        success();
      })
      .catch(failure);
  };

const setAvailabilities = (availabilities: Availability[]): ActionTypes => ({
  type: SET_AVAILABILITIES,
  payload: availabilities,
});

const addAvailabilities = (availabilities: Availability[]): ActionTypes => ({
  type: ADD_AVAILABILITIES,
  payload: availabilities,
});

const removeSpecialDay = (day: string): ActionTypes => ({
  type: REMOVE_AVAILABILITY,
  payload: day,
});

export const getSpecialDays =
  (
    garageId: string,
    fromDate: string,
    toDate: string
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    await HttpService.getClient()
      .get(
        `${process.env.REACT_APP_API_CALENDAR_URL}/${garageId}/special-days`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'api-key': process.env.REACT_APP_API_CALENDAR_KEY,
          },
          params: {
            fromDate,
            toDate,
            ts: new Date().getTime(),
          },
        }
      )
      .then((response) => response.data)
      .then((specialDays) => dispatch(setAvailabilities(specialDays)))
      .catch((error) => error);
  };

// TODO refactor calendar api to fix update special days routes as it doesn't work at this time (2021-08-31)
export const saveSpecialDays =
  (
    garageId: string,
    specialDays: Availability[],
    success: () => void,
    failure: (e: Error) => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    HttpService.getClient()
      .post(
        `${process.env.REACT_APP_API_CALENDAR_URL}/${garageId}/special-days`,
        specialDays,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'api-key': process.env.REACT_APP_API_CALENDAR_KEY,
          },
        }
      )
      .then(() => {
        dispatch(addAvailabilities(specialDays));
        success();
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 409) {
          HttpService.getClient()
            .delete(
              `${process.env.REACT_APP_API_CALENDAR_URL}/${garageId}/special-days?day=${specialDays[0].day}`,
              {
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  'api-key': process.env.REACT_APP_API_CALENDAR_KEY,
                },
              }
            )
            .then(() => dispatch(removeSpecialDay(specialDays[0].day || '')));
        } else {
          failure(error);
        }
      });
  };

export const saveHolidays =
  (
    garageId: string,
    holidays: Range<Moment>[],
    success: () => void,
    failure: (e: Error) => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async () => {
    await HttpService.getClient()
      .post(
        `${process.env.REACT_APP_API_CALENDAR_URL}/${garageId}/holidays`,
        holidays.map((h) => ({
          min: h.min.format('YYYY-MM-DD'),
          max: h.max.format('YYYY-MM-DD'),
        })),
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'api-key': process.env.REACT_APP_API_CALENDAR_KEY,
          },
        }
      )
      .then(() => {
        success();
      })
      .catch(failure);
  };

export const deleteSpecialDay =
  (
    garageId: string,
    day: string,
    success: () => void,
    failed: (e: Error) => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    await HttpService.getClient()
      .delete(
        `${process.env.REACT_APP_API_CALENDAR_URL}/${garageId}/special-days`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'api-key': process.env.REACT_APP_API_CALENDAR_KEY,
          },
          params: { day },
        }
      )
      .then(() => {
        const fromDate = moment(day).startOf('week').format('YYYY-MM-DD');
        const toDate = moment(day).endOf('week').format('YYYY-MM-DD');
        dispatch(getSpecialDays(garageId, fromDate, toDate));
        success();
      })
      .catch((error: AxiosError) => failed(error));
  };

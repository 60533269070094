import React, { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../../router/routes';
import Button from '../../../../assets/generic-components/Button';
import { Language } from '../../../../redux/stores/languages';

interface QuoteButtonsValidationProps {
  styleMode: string;
  lg: Language;
  onSubmit: (values: any) => void;
  handleSubmit: (
    callBack: (values: any) => void
  ) => MouseEventHandler<HTMLButtonElement>;
}

const QuoteButtonsValidation: React.FC<QuoteButtonsValidationProps> = ({
  styleMode,
  lg,
  onSubmit,
  handleSubmit
}) => (
  <div className={styleMode}>
    <div className="box-btn">
      <Link to={ROUTES.QUOTE_SUMMARY}>
        <Button theme="light" title={lg.quote.previous} />
      </Link>
      <Button
        type="submit"
        width="274px"
        theme="dark"
        title={lg.quote.validedRDV}
        onClick={handleSubmit(onSubmit)}
      />
    </div>
    <div />
  </div>
);

export default QuoteButtonsValidation;

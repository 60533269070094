import { ActionTypes, SET_HELP_DATA } from './actions';
import { HelpStore } from './types';

export const initHelpStore = {
  helpCategories: []
};

const helpStore = (state: HelpStore = initHelpStore, action: ActionTypes) => {
  switch (action.type) {
    case SET_HELP_DATA:
      return { ...state, helpCategories: action.payload };
    default:
      return state;
  }
};

export default helpStore;

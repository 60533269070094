import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
// import components
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import colors from '../../assets/style-colors';
import { Store } from '../../redux/types';
import {
  ACCOUNT,
  ACCOUNT_BILLING,
  ACCOUNT_DESCRIPTION,
  ACCOUNT_DETAILS,
  ACCOUNT_HOURS,
  ACCOUNT_SERVICES
} from '../../router/routes';
import Bank from './components/bank-information';
import Description from './components/description';
import Info from './components/infos-garage';
import Navbar from './components/navbar';
import Schedules from './components/schedules';
import Service from './components/service';
import AccountDetails from './components/account-details/AccountDetails';

const Account: React.FC = () => {
  const useStyles = makeStyles(() => ({
    root: {
      display: 'flex',
      backgroundColor: colors.veryPaleGrey,
      flexDirection: 'column',
      alignItems: 'center'
    },
    wrapper: {
      width: '50%',
      margin: 'auto'
    }
  }));

  const classes = useStyles();

  const {
    languageReducer: { language: lg },
    globalReducer: { currentGarage }
  } = useSelector((state: Store) => state);

  if (!currentGarage) return null;

  return (
    <div className={classes.root}>
      <Router>
        <Navbar lg={lg} />
        <Switch>
          <Route exact path={ACCOUNT} component={Info} />
          <Route path={ACCOUNT_DESCRIPTION} component={Description} />
          <Route path={ACCOUNT_HOURS} component={Schedules} />
          <Route path={ACCOUNT_BILLING} component={Bank} />
          <Route path={ACCOUNT_SERVICES} component={Service} />
          <Route path={ACCOUNT_DETAILS} component={AccountDetails} />
        </Switch>
      </Router>
    </div>
  );
};

export default Account;

import React, { useEffect } from 'react';
import useSWRImmutable from 'swr/immutable';
import { useDispatch } from 'react-redux';
import InputMutipleSelect from '../../assets/generic-components/InputMutipleSelect';
import { Language } from '../../redux/stores/languages';
import {
  errorNotificationOccurred,
  getIdByVersions,
} from '../../services/vehicle-identification-service';
import { setNotification } from '../../redux/stores/global/actions';
import { Vehicle } from '../../redux/stores/quotes/types';

interface VersionsListProps {
  model: string;
  make: string;
  lg: Language;
  defaultOptionChoice: object;
  handleVersionValueChanged: (value: string, vehicleList: Vehicle[]) => void;
}

const VersionsList: React.FC<VersionsListProps> = ({
  model,
  make,
  lg,
  defaultOptionChoice,
  handleVersionValueChanged,
}) => {
  const dispatch = useDispatch();
  const { data, error } = useSWRImmutable<Vehicle[] | null, any>(
    make !== '' && model !== '' ? `${make}_${model}/vehicles` : null,
    getIdByVersions
  );

  useEffect(() => {
    if (error) {
      dispatch(setNotification(errorNotificationOccurred(lg.error.server)));
    }
  }, [error]);

  const getFormattedData = (vehicleVersions: Vehicle[] | null) =>
    (vehicleVersions || []).map((version) => ({
      label: version.label,
      value: version.id,
    }));

  return (
    <InputMutipleSelect
      width="578px"
      label={lg.quote.engine}
      datas={[defaultOptionChoice, ...getFormattedData(data || null)]}
      onChange={(e) =>
        handleVersionValueChanged(e.currentTarget.value, data || [])
      }
      disabled={!data || data.length < 1}
    />
  );
};

export default VersionsList;

import React from 'react';
import { filter, matches } from 'lodash';
import moment from 'moment';
import Col from 'react-bootstrap/Col';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import { useSelector } from 'react-redux';
import CheckboxLarge from '../../../../../assets/generic-components/CheckBoxLarge';
import colors from '../../../../../assets/style-colors';
import Button from '../../../../../assets/generic-components/Button';
import edit from '../../../../../assets/style-elements/Icon-edit.svg';
import Bookings from './bookings';
import { Booking } from '../../../../../redux/stores/planning/types';
import { Store } from '../../../../../redux/types';
import { Availability } from '../../../../../redux/stores/calendar/types';
import DayHours from './dayHours';

interface WeekDayProps {
  bookings: Booking[];
  dayIndex: number;
  startOfWeek: string;
  workingHours: number[];
  handleShowModal: any;
  pathDayModif: string;
}

const useStyles = makeStyles({
  column: {
    borderRight: `1px solid ${colors.WhiteDark}`,
    minHeight: `calc(100vh - 200px)`,
    fontWeight: 500,
    color: colors.lightGreyBlue,
    padding: '0',
    minWidth: '145px',
    paddingTop: '20px',
  },
  dayDark: {
    color: `${colors.dark} !important`,
    background: colors.paleGrey,
    paddingTop: '20px',
  },
  bgActive: {
    background: colors.WhiteDark,
  },
  weekday: {
    fontSize: '12px',
    justifyContent: 'center',
    paddingTop: '10px',
  },
  day: {
    padding: '0 10px 3px 10px',
    fontSize: '25px',
    justifyContent: 'center',
  },
  hours: {
    padding: '9px',
    fontSize: '9px',
    justifyContent: 'center',
    color: colors.dark,
    borderRadius: '4px',
  },
  tickets: {
    margin: '5px',
    flexDirection: 'column',
    justifyContent: 'start',
  },
  ticket: {
    justifyContent: 'center',
    color: colors.dark,
    height: 'auto',
    width: '100%',
    margin: 0,
  },
  boxForCheck: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '51px',
    height: '25px',
    borderRadius: '6px',
    background: colors.white,
  },
  checkMode: {
    background: colors.lightGreyBlue,
    width: '19px',
    height: '19px',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  mgL: {
    '& button': {
      marginRight: '0',
    },
  },
  wrapCheckbox: {
    justifyContent: 'center',
    paddingTop: '10px',
  },
  wrapButtonEdit: {
    justifyContent: 'center',
    marginTop: '10px',
  },
  iconButtonEdit: {
    width: '16px',
  },
  textButtonEdit: {
    paddingLeft: '10px',
    color: colors.dark,
  },
  wrapWeek: {
    flexWrap: 'nowrap',
  },
  wrapInfoHours: {
    justifyContent: 'center',
    alignItems: 'center',
  },

  hoursWeek: {
    width: '96px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '10px',
  },
  iconHoursWeek: {
    marginRight: '5px',
    width: '9px',
    height: '9px',
  },
});

const WeekDay: React.FC<WeekDayProps> = ({
  bookings,
  dayIndex,
  startOfWeek,
  workingHours,
  handleShowModal,
  pathDayModif,
}) => {
  const history = useHistory();
  const currentWeekAvailabilities = useSelector(
    (state: Store) => state.calendarStore.currentWeekAvailabilities
  );
  const { availableCarservices } = useSelector(
    (state: Store) => state.globalReducer
  );
  const { updatePlanningMode } = useSelector(
    (state: Store) => state.planningStore
  );
  const lg = useSelector((state: Store) => state.languageReducer.language);
  const calendar = useSelector((state: Store) => state.calendarStore.calendar);
  const classes = useStyles();

  const handleRedirect = (e: any, path: any) => {
    e.preventDefault();
    history.push(path);
  };

  let totalHours = workingHours[dayIndex] || 0;

  const isToday =
    moment().startOf('day').toISOString() ===
    moment(startOfWeek)
      .day(dayIndex + 1)
      .startOf('day')
      .toISOString();

  const dayOfCurrentWeek = moment(startOfWeek)
    .day(dayIndex + 1)
    .format('D');

  const date = moment(startOfWeek)
    .day(dayIndex + 1)
    .format('YYYY-MM-DD');

  const today = moment().format('YYYY-MM-DD');
  const specialDays = filter(currentWeekAvailabilities, matches({ day: date }));
  const specialDay = currentWeekAvailabilities?.find(
    (day: Availability) => day.day === date
  );

  if (specialDays.length > 0) {
    totalHours =
      specialDays[0].availability.openingHours.length > 0
        ? specialDays[0].availability.slots
        : 0;
  }
  const closed = totalHours === 0;

  return (
    <Col
      key={`weekDay${dayIndex}`}
      className={
        isToday || (updatePlanningMode && !closed)
          ? `${classes.dayDark} ${classes.column} ${classes.bgActive}`
          : classes.column
      }
    >
      {updatePlanningMode && (
        <Row className={classes.wrapCheckbox}>
          <CheckboxLarge
            onOpen={() => {
              handleShowModal(dayIndex, closed);
            }}
            onClose={() => {
              handleShowModal(dayIndex, closed);
            }}
            checked={!closed}
          />
        </Row>
      )}

      <Row className={classes.weekday}>{lg.planning.weekdays[dayIndex]}</Row>

      <Row className={classes.day}>{dayOfCurrentWeek}</Row>

      {updatePlanningMode && closed ? null : (
        <>
          <Row className={classes.wrapInfoHours}>
            <DayHours currentDay={date} />
          </Row>
          {updatePlanningMode && (
            <Row className={classes.hours}>
              {specialDay
                ? `${specialDay?.availability.openingHours.join(' - ')}`
                : `${
                    calendar?.regularWeek[dayIndex].openingHours.join(' - ') ||
                    'hh:mm - hh:mm'
                  }`}
            </Row>
          )}
        </>
      )}

      {updatePlanningMode && !closed && (
        <Row className={`${classes.mgL} ${classes.wrapButtonEdit}`}>
          <Button
            theme="light"
            onClick={(e) => handleRedirect(e, `${pathDayModif}/${dayIndex}`)}
          >
            <img className={classes.iconButtonEdit} src={edit} alt=" " />
            <div className={classes.textButtonEdit}>
              {lg.planning.modification.modify}
            </div>
          </Button>
        </Row>
      )}

      <Row className={`${classes.tickets} clearUp`}>
        <div className="thead">
          <div className="tr">
            <div className="td">
              <div className="page-header-space" />
            </div>
          </div>
        </div>

        <Bookings
          bookings={bookings}
          availableCarservices={availableCarservices}
          opacity={date < today || updatePlanningMode ? 0.4 : 1}
        />
      </Row>
    </Col>
  );
};

export default WeekDay;

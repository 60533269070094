import useSWRImmutable from 'swr/immutable';
import HttpService from '../../services/http-service';
import { ViewsListEnum } from '../../redux/stores/billings/type';

type ViewsListKey = keyof typeof ViewsListEnum;
type ObjectWithValuesOfEnumAsKeys = { [key in ViewsListKey]: any };

const getBillingDataByDate = (garageId: string, date: string, type: string) =>
  HttpService.getClient()
    .get(
      `${
        process.env.REACT_APP_API_BILLING_URL
      }/file-data/${garageId}/${type.toUpperCase()}/${date}`,
      {
        headers: { 'api-key': process.env.REACT_APP_API_GARAGES_KEY },
        params: {},
      }
    )
    .then((response) => response.data.amount);

const useBillingSummary = (garageId: string, date: string) => {
  const summary: ObjectWithValuesOfEnumAsKeys = { ...ViewsListEnum };
  Object.keys(summary).forEach((type) => {
    const { data } = useSWRImmutable(`${date}-${type}-bc`, () =>
      getBillingDataByDate(garageId, date, type)
    );
    summary[type as ViewsListKey] = Math.abs(data) || 0;
  });
  const debit: number = summary.COMMISSION + summary.SUBSCRIPTION;
  const credit: number = summary.VOUCHER + summary.ADDITIONAL_VOUCHER || 0;
  const total = credit - debit;

  return {
    total,
    commission: summary.COMMISSION,
    subscription: summary.SUBSCRIPTION,
    voucher: summary.VOUCHER,
    additionalVoucher: summary.ADDITIONAL_VOUCHER,
  };
};

export default useBillingSummary;

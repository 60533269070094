import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { TyresFieldConfig } from '../../../services/tyres';
import colors from '../../../assets/style-colors';

interface SelectionDropdownProps {
  field: TyresFieldConfig;
  options: Array<string | { key: string }>;
  disabled: boolean;
  onSelect: (value: string | null) => void;
  value: string | null;
}

const useStyles = makeStyles(() => ({
  root: {
    width: '30%',
    marginTop: '10px',
  },
  withoutSelection: {
    color: `${colors.paleDark} !important`,
  },
  selectBtn: {
    border: 'none',
  },
  menu: {
    overflow: 'auto',
  },
  menuItem: {
    margin: 0,
    marginLeft: '10px',
  },
  menuElement: {
    margin: 0,
  },
}));

const SelectionDropdown: React.FC<SelectionDropdownProps> = ({
  field,
  options,
  disabled,
  onSelect,
  value,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const classes = useStyles();

  const getOptionValue = (option: string | { key: string }) =>
    typeof option === 'object' ? option.key?.toString() : option.toString();

  const handleSelectionOption = (option: string | { key: string }) => {
    onSelect(getOptionValue(option));
    setDropdownOpen(false);
  };

  useEffect(() => {
    onSelect(value);
  }, [value]);

  const handleToggleDropdown = () => {
    setDropdownOpen(disabled ? false : !dropdownOpen);
    if (value) {
      onSelect(null);
    }
  };

  return (
    <div className={classes.root}>
      <label htmlFor="Dropdown" className="label">
        {field.label}
      </label>
      <Dropdown onToggle={handleToggleDropdown} show={dropdownOpen}>
        <Dropdown.Toggle
          className={`${!value && classes.withoutSelection} ${
            classes.selectBtn
          }`}
        >
          {value?.toString() || '--'}
        </Dropdown.Toggle>
        <Dropdown.Menu className={classes.menu}>
          {options
            .sort((a, b) => (getOptionValue(a) > getOptionValue(b) ? -1 : 1))
            .map((option, index) => (
              <div
                className={classes.menuItem}
                onClick={() => handleSelectionOption(option)}
                role="button"
                tabIndex={index}
                key={index}
              >
                <p className={classes.menuElement}>{getOptionValue(option)}</p>
                <Dropdown.Divider />
              </div>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default SelectionDropdown;

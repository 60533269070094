import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import colors from '../../../../../assets/style-colors';
import Button from '../../../../../assets/generic-components/Button';
import { ACCOUNT_HOURS } from '../../../../../router/routes';
import { Store } from '../../../../../redux/types';

const PlanningNotInitialized = () => {
  const lang = useSelector((state: Store) => state.languageReducer.language);
  const useStyles = makeStyles(() => ({
    root: {
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      zIndex: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      '&:before': {
        width: '100%',
        left: '0',
        bottom: '0',
        height: '100%',
        content: '""',
        position: 'absolute',
        opacity: '0.2',
        backgroundColor: colors.black,
      },
    },
    popup: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '160px',
      padding: '40px',
      backgroundColor: colors.white,
      zIndex: 11,
    },
    button: {
      '& button': {
        marginRight: 0,
      },
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.popup}>
        <p>{lang.planning.notInit}</p>
        <Link className={classes.button} to={ACCOUNT_HOURS}>
          <Button theme="dark" title={lang.planning.updateMyPlanning} />
        </Link>
      </div>
    </div>
  );
};

export default PlanningNotInitialized;

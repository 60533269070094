import initState from '../initialState';

const languageReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET_LANGUAGE':
      // eslint-disable-next-line no-case-declarations
      const newState = state;
      newState.language = action.payload;
      return {
        ...newState,
        language: action.payload
      };
    case 'SET_LANGUAGE_ACCOUNT':
      return {
        ...state,
        langAccount: action.payload
      };
    case 'SET_IMAGE':
      return {
        ...state,
        image: action.payload
      };
    default:
      return state;
  }
};

export default languageReducer;

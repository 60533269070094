import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as ROUTES from '../../../../router/routes';
import { Part } from '../../../../redux/stores/planning/types';
import manufacturers from '../../../../assets/api/dataManufacturers';
import colors from '../../../../assets/style-colors';
import {
  isFieldUpdatableForOperationType,
  reformatOperationLabel,
  UpdatableOperationField,
} from '../../../../services/quote/operation';
import { formatPrice } from '../../../../services/quote/quote-service';
import { Store } from '../../../../redux/types';
import AuthenticationService, {
  Roles,
} from '../../../../services/authentication-service';
import IconWarning from '../../../../assets/style-elements/icon-warning';

interface OperationLineProps {
  /**
   *  Operation Id.
   */
  operationId: string;
  /**
   *  Part references.
   */
  partReferences?: string[];
  /**
   *  Single part.
   */
  partSelected?: Part;
  /**
   *  Unit price.
   */
  unitPrice: number;
  /**
   *  Total discount.
   */
  discountTotal?: string;
  /**
   *  Discount percentage.
   */
  discountPercent?: number | string;
  /**
   *  Quanitity.
   */
  quantity?: number | string;
  /**
   *  Operation label.
   */
  label: string;
  /**
   *  Total price.
   */
  operationTotal: string;
  /**
   *  Total price with tax.
   */
  operationTotalWithTax: string;
  /**
   *  Whether or not to display digit number.
   */
  isQuantityDigitAllowed?: boolean;
  /**
   *  Whether or not operation is packaged.
   */
  isPackaged: boolean;
  /**
   *  Whether or not inputs are updatable.
   */
  isUpdatable?: boolean;
  /**
   *  Whether or not quote summary page is updatable.
   */
  isSummaryUpdatable?: boolean;
  /**
   *  Operation type.
   */
  type: string;
  /**
   *  Language.
   */
  locale: string;
  /**
   *  Function to update price, quantity and discount percent values.
   */
  onOperationQtyDiscountUpdate: (
    newQuantity: number,
    newDiscountPercent: number,
    newOperationPrice: number
  ) => void;
  /**
   *  Function to track change event on inputs.
   */
  onInputChange: () => void;
}

const useStyles = makeStyles({
  editIcon: {
    cursor: 'pointer',
    width: '16px',
  },

  doneIcon: {
    color: colors.primary,
    cursor: 'pointer',
    width: '16px',
  },

  actions: {
    display: 'flex',
    justifyContent: 'center',
  },

  editInput: {
    width: '60px',
    border: `1px solid ${colors.inputBorder}`,
    borderRadius: '4px',
    color: colors.slateGrey,
    textAlign: 'center',
  },
});

const OperationLine: React.FC<OperationLineProps> = ({
  partReferences,
  partSelected,
  unitPrice,
  discountTotal,
  discountPercent,
  quantity,
  label,
  operationId,
  operationTotal,
  operationTotalWithTax,
  isQuantityDigitAllowed,
  isPackaged,
  isUpdatable,
  isSummaryUpdatable,
  type,
  locale,
  onOperationQtyDiscountUpdate,
  onInputChange,
}) => {
  const lg = useSelector((state: Store) => state.languageReducer.language);
  const formatDecimal = (number: string | number): number =>
    typeof number === 'string' ? parseFloat(number.replace(/,/g, '.')) : number;

  const formatInteger = (number: string | number): number =>
    typeof number === 'string' ? parseInt(number) : number;

  const [state, setState] = useState({
    operationQty: formatDecimal(quantity || 0),
    operationDiscountPercent: formatInteger(discountPercent || 0),
    operationPrice: unitPrice,
  });
  const { operationQty, operationDiscountPercent, operationPrice } = state;
  const classes = useStyles();
  const isUserAdmin = AuthenticationService.hasRole([
    Roles.ADMIN,
    Roles.CALL_CENTER,
  ]);

  const handleUpdateFinished = (_state: typeof state): void => {
    const {
      operationQty: newOperationQty,
      operationDiscountPercent: newOperationDiscountPercent,
      operationPrice: newOperationPrice,
    } = _state;
    onInputChange();
    setState(_state);
    onOperationQtyDiscountUpdate(
      newOperationQty || (isQuantityDigitAllowed ? 0.1 : 1),
      newOperationDiscountPercent,
      newOperationPrice
    );
  };

  const isPageUpdatable = ROUTES.QUOTE_SUMMARY
    ? isSummaryUpdatable
    : isUpdatable;

  return (
    <div
      className={`tr ${
        partReferences && partReferences.length > 1 ? 'warn' : ''
      } ${isPackaged ? 'packaged' : ''} ${isPageUpdatable ? 'inputGrid' : ''}`}
    >
      <div className="td_tiny_border_start">
        <div className="flex gap-2">
          {partReferences && partReferences.length > 1 && (
            <IconWarning className="icon icon-warning" />
          )}
          {operationId === 'PACKAGE' && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="icon w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              />
            </svg>
          )}

          <span>
            {reformatOperationLabel(label, lg)}
            <br />
            <div className="font_bold">{partReferences?.join(',')}</div>
          </span>
        </div>

        {partSelected && !isPackaged && (
          <div className="parts-selected noprint">
            {manufacturers[partSelected.manufacturerName] ||
              partSelected.manufacturerName.toUpperCase() ||
              'IDG'}
            <br />
            <small>{partSelected.partsId || 'DEFAULT'}</small>
          </div>
        )}
      </div>
      <div className="td_tiny_border_end">
        {isPageUpdatable &&
        isFieldUpdatableForOperationType(
          type,
          UpdatableOperationField.PRICE,
          isUserAdmin
        ) ? (
          <input
            step="0.01"
            type="number"
            className={`operationLineInput ${classes.editInput}`}
            value={operationPrice}
            onFocus={(e) => e.target.select()}
            onChange={(e) => {
              handleUpdateFinished({
                ...state,
                operationPrice: parseFloat(e.target.value),
              });
            }}
          />
        ) : (
          <span>{formatPrice(unitPrice || 0, locale)}</span>
        )}
      </div>
      <div className="td_tiny_border_end">
        {isPageUpdatable &&
        isFieldUpdatableForOperationType(
          type,
          UpdatableOperationField.QUANTITY,
          isUserAdmin
        ) ? (
          <input
            step={isQuantityDigitAllowed ? '0.1' : '0'}
            min={isQuantityDigitAllowed ? '0.1' : '1'}
            type="number"
            className={`operationLineInput ${classes.editInput}`}
            value={operationQty}
            required
            onFocus={(e) => e.target.select()}
            onChange={(e) => {
              handleUpdateFinished({
                ...state,
                operationQty: isQuantityDigitAllowed
                  ? parseFloat(e.target.value)
                  : parseInt(e.target.value),
              });
            }}
          />
        ) : (
          <span>{quantity}</span>
        )}
      </div>
      <div className="td_tiny_border_end">
        {isPageUpdatable &&
        isFieldUpdatableForOperationType(
          type,
          UpdatableOperationField.DISCOUNT,
          isUserAdmin
        ) ? (
          <span>
            (
            <input
              type="number"
              value={operationDiscountPercent}
              className={`operationLineInput ${classes.editInput}`}
              min={0}
              max={100}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                handleUpdateFinished({
                  ...state,
                  operationDiscountPercent: Math.min(
                    100,
                    parseInt(e.target.value || '0')
                  ),
                });
              }}
            />{' '}
            %) {discountTotal || 0}
          </span>
        ) : (
          <span>
            {discountPercent && discountPercent > 0
              ? `(${discountPercent} %) `
              : ''}{' '}
            {discountTotal || 0}
          </span>
        )}
      </div>
      <div className="td_tiny_border_end">
        <span>{operationTotal}</span>
      </div>
      <div className="td_tiny_border_end">
        <span>{operationTotalWithTax}</span>
      </div>
    </div>
  );
};

OperationLine.defaultProps = {
  partReferences: [],
  partSelected: undefined,
  discountTotal: '0',
  quantity: '-',
  discountPercent: '0',
  isQuantityDigitAllowed: false,
  isUpdatable: false,
  isSummaryUpdatable: false,
};

export default OperationLine;

import { AxiosInstance } from 'axios';
import AuthenticationService from '../authentication-service';
import Logger from '../logger';

export default class HttpHandler {
  client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
    this.handleResponseValidation();
    this.addAuthenticationOnRequests();
  }

  handleResponseValidation() {
    this.client.interceptors.response.use(async response => {
      const { validate, url } = response.config;
      if (validate) {
        const isValid = await validate(response);
        if (!isValid) {
          Logger.logErrorWithContext(
            `Wrong data types with query ${url}`,
            AuthenticationService.getUserInfo(),
            [
              { key: 'validationError', data: validate.errors },
              { key: 'query', data: response }
            ]
          );
        }
      }
      return response;
    });
  }

  addAuthenticationOnRequests() {
    this.client.interceptors.request.use(
      config =>
        // if (AuthenticationService.isLoggedIn()) {
        //   const cb = () => {
        //     config.headers.Authorization = `Bearer ${AuthenticationService.getToken()}`;
        //     return Promise.resolve(config);
        //   };
        //   AuthenticationService.updateToken(cb);
        // }
        config
    );
  }
}

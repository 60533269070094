import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { range } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TextaeraLabel from '../../../../assets/generic-components/TextaeraLabel';
import Title from '../../../../assets/generic-components/Title';
import logoPrestation from '../../../../assets/style-elements/01-electrons-icon-car.png';
import { addPersonalBookingEvent } from '../../../../lib/events/devis5s';
import { setNotification } from '../../../../redux/stores/global/actions';
import {
  MessageNotification,
  SeverityMessage,
} from '../../../../redux/stores/global/types';
import {
  getBooking,
  setCurrentDay,
} from '../../../../redux/stores/planning/actions';
import { Booking } from '../../../../redux/stores/planning/types';
import { makeBooking } from '../../../../redux/stores/quotes/actions';
import { Store } from '../../../../redux/types';
import * as ROUTES from '../../../../router/routes';
import { getNewBookingPayload } from '../../../../services/booking/booking-creation-service';
import getVatForGarage from '../../../../services/quote/vat';
import { getTotals } from '../../../../utils/booking';
import { getDateSelectionRange } from '../../../../utils/calendar';
import QuoteButtonsValidation from './quoteButtonsValidation';
import QuoteByAppointmentConfirmModal from './quoteByAppointmentConfirmModal';
import useStyles from './style';
import QuoteFields from './quoteFields';
import QuoteServices from './quoteServices';
import QuoteInputDateTime from './quoteInputDateTime';
import QuoteOrder from './quoteOrder';
import useMediaQuery from '../../../../utils/hooks/useMediaQuery';
import Button from '../../../../assets/generic-components/Button';
import DayView from '../../../planning-page/components/planning-full-page/components/day';

const durations = range(1, 16).map((d) => ({
  label: d.toString(),
  value: d.toString(),
}));

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  comments: string;
  date: string;
};

const QuoteByAppointmentConfirm = () => {
  const {
    languageReducer,
    quotesReducer: { quotes, currentVehicle, currentRegistrationNumber },
    globalReducer: { availableCarservices, currentGarage },
    planningStore: { currentDay },
  } = useSelector((state: Store) => state);

  const dispatch = useDispatch();
  const lg = languageReducer.language;
  const history = useHistory();
  const [modalActive, setModalActive] = useState(false);
  const [booking, setBooking] = useState<Booking | null>(null);
  const [hour, setHour] = useState(getDateSelectionRange()[0]);
  const [lateralDay, setLateralDay] = useState(
    moment(currentDay).toISOString()
  );

  const [displayCalendar, setDisplayCalendar] = useState(false);
  const { register, handleSubmit, watch, errors, control } =
    useForm<FormValues>({
      defaultValues: {
        date: moment().format('YYYY-MM-Do'),
      },
    });

  const goback = () => history.goBack();

  const isTablet = useMediaQuery(1024);

  const [currentQuotes, setCurrentQuotes] = useState(quotes);

  const [bookingDate, setBookingDate] = useState(moment());

  const classes = useStyles();

  if (!currentGarage || !currentQuotes || !quotes) {
    return null;
  }

  const vat = getVatForGarage(currentGarage.garageAddress);

  const {
    totalDiscountless,
    totalDiscount,
    totalWithDiscounts,
    totalCoupon,
    totalVAT,
    totalBillingPrice,
    totalBillingPriceMax,
    totalBillingPriceVat,
  } = getTotals(quotes, vat);

  const onSubmit = (values: FormValues) => {
    const newBooking = getNewBookingPayload({
      currentGarage,
      customer: {
        lastName: values.lastName,
        firstName: values.firstName || undefined,
        email: values.email || undefined,
        phone: values.phone || undefined,
        comments: values.comments,
      },
      registrationNumber: currentRegistrationNumber || null,
      vehicleId: currentVehicle?.id,
      bookingDate,
      hour,
      quotes: currentQuotes,
    });
    setBooking(newBooking);

    const failure = (error: Error) => {
      const notification: MessageNotification = {
        severity: SeverityMessage.ERROR,
        message: `${lg.error.server}: ${error.message}`,
      };
      dispatch(setNotification(notification));
    };

    dispatch(
      makeBooking(
        newBooking,
        (bookingId: string) => {
          addPersonalBookingEvent({
            garageId: newBooking.garageId,
            vehicle: currentVehicle,
            bookingId,
            garageName: currentGarage.companyName,
            bookingDate: newBooking.bookingDate,
            services: newBooking.quotes.map((q) => ({
              id: q.carServiceId,
              label: availableCarservices[q.carServiceId].label,
            })),
            price: newBooking.quotes.reduce(
              (total, current) => total + (current.price?.minPriceExclTax || 0),
              0
            ),
          });
          dispatch(
            getBooking(bookingId, () => history.push(ROUTES.COMMAND), failure)
          );
        },
        failure
      )
    );
  };

  const handleDisplayCalendar = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setDisplayCalendar(!displayCalendar);
  };

  const watchAllFields = watch();

  return (
    <>
      <Helmet>
        <title>Devis 5s | Finalisation</title>
      </Helmet>
      <div
        onSubmit={() => handleSubmit(onSubmit)}
        className={classes.container}
      >
        {modalActive && (
          <QuoteByAppointmentConfirmModal
            active={modalActive}
            setActive={() => setModalActive(false)}
            booking={booking}
            submit={(event) => {
              event.preventDefault();

              dispatch(
                setCurrentDay(moment(booking?.bookingDate).toISOString())
              );

              history.push('/planning');
            }}
          />
        )}
        <Button theme="text" onClick={goback}>
          <KeyboardArrowLeftIcon className={classes.backwardGrey} />
          <p>{lg.planningHeader.back}</p>
        </Button>
        <Title title={lg.quote.create} />

        <div className={classes.wrapper}>
          <div className={classes.containerBox}>
            <div className={classes.containerInput}>
              <QuoteFields
                tablet={isTablet}
                firstnameReference={register({
                  required: false,
                  maxLength: 20,
                })}
                lastnameReference={register({
                  required: true,
                  maxLength: 20,
                })}
                lastnameError={
                  <>
                    {errors.lastName &&
                      errors.lastName.type === 'required' &&
                      lg.quote.messageField}
                  </>
                }
                emailReference={register({
                  required: false,
                  pattern: {
                    message: lg.quote.messageEmail,
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  },
                })}
                emailError={
                  <>
                    {errors.email &&
                      errors.email.type === 'pattern' &&
                      lg.quote.messageEmail}
                  </>
                }
                phoneReference={register({
                  required: false,
                })}
                phoneError={
                  <>{errors.phone && errors.phone.type === 'pattern'}</>
                }
                control={control}
              />
              <div className={classes.boxPrestation}>
                <img src={logoPrestation} alt="logo" className={classes.logo} />
                <p>{lg.quote.prestation}</p>
              </div>
              <ul className={classes.listOption}>
                {currentQuotes?.map((quote, index) => (
                  <QuoteServices
                    key={quote.carServiceId}
                    carServices={availableCarservices[quote.carServiceId].label}
                    onChange={(e) =>
                      setCurrentQuotes([
                        ...currentQuotes.slice(0, index),
                        { ...quote, duration: +e.currentTarget.value },
                        ...currentQuotes.slice(index + 1),
                      ])
                    }
                    reference={register}
                    datas={durations}
                    selected={Math.ceil(quote.duration || 1).toString()}
                  />
                ))}
              </ul>
              <QuoteInputDateTime
                isTablet={isTablet}
                displayFunc={handleDisplayCalendar}
                label={bookingDate.format(
                  isTablet ? 'DD/MM/YY' : 'DD MMM YYYY'
                )}
                isDisplayed={displayCalendar}
                day={bookingDate.toISOString()}
                onSelect={(date) => {
                  setBookingDate(date);
                  setDisplayCalendar(false);
                  setLateralDay(date.toISOString());
                }}
                setHour={(e) => setHour(e.target.value)}
                reference={register}
                datas={getDateSelectionRange().map((hourRange) => ({
                  label: hourRange,
                  value: hourRange,
                }))}
              />
              <div className={classes.boxTextaera}>
                <TextaeraLabel
                  length={
                    (watchAllFields.comments &&
                      watchAllFields.comments.length) ||
                    0
                  }
                  name="comments"
                  reference={register}
                  label={lg.quote.noteRDV}
                />
              </div>
            </div>
            <QuoteOrder
              totalDiscountless={totalDiscountless}
              totalDiscount={totalDiscount}
              totalCoupon={totalCoupon}
              totalWithDiscounts={totalWithDiscounts}
              totalVAT={totalVAT}
              totalBillingPriceVat={totalBillingPriceVat}
              totalBillingPrice={totalBillingPrice}
              totalBillingPriceMax={totalBillingPriceMax}
            />
            {!isTablet && (
              <QuoteButtonsValidation
                styleMode="container-btn"
                lg={lg}
                onSubmit={onSubmit}
                handleSubmit={handleSubmit}
              />
            )}
          </div>

          {!isTablet && (
            <div className={classes.dayViewContainer}>
              <h5 className={classes.dayViewHead}>{lg.quote.dayOfRDV}</h5>
              <DayView
                lateralMode
                currentDay={lateralDay}
                setCurrentDay={setLateralDay}
              />
            </div>
          )}
        </div>

        {isTablet && (
          <QuoteButtonsValidation
            styleMode="container-btn"
            lg={lg}
            onSubmit={onSubmit}
            handleSubmit={handleSubmit}
          />
        )}
      </div>
    </>
  );
};

export default QuoteByAppointmentConfirm;

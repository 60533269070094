import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '../../../redux/types';
import { setNotification } from '../../../redux/stores/global/actions';
import {
  CountryCodes,
  SeverityMessage,
} from '../../../redux/stores/global/types';
import {
  updateVehicule,
  setBooking,
} from '../../../redux/stores/planning/actions';
import Button from '../../../assets/generic-components/Button';
import close from '../../../assets/style-elements/01-electrons-icon-close.png';
import { Booking, BookingVehicle } from '../../../redux/stores/planning/types';
import useStyles from './stylesModal';
import {
  setRegistrationNumber,
  getVehicleByVinCode,
  getVehicleByPlateNumber,
} from '../../../redux/stores/quotes/actions';
import InputLabel from '../../../assets/generic-components/InputLabel';

interface VehicleFormValues {
  vehicleId: string;
  registrationNumber?: string | undefined | null;
  kilometers?: number;
  vinCode?: string | undefined | null;
}

const EditVehicle: React.FC = ({ children }) => {
  const style = useStyles();
  const {
    languageReducer,
    planningStore: { currentBooking },
    globalReducer: { currentGarage },
    quotesReducer: { currentVehicle },
  } = useSelector<Store, Store>((state) => state);
  const lg = languageReducer.language;
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  const handleClose = () => setShow(false);
  const isGarageBe = currentGarage?.garageAddress.country === CountryCodes.BE;

  if (!currentBooking) return null;

  const { register, handleSubmit, errors, reset } = useForm<VehicleFormValues>({
    mode: 'onChange',
    defaultValues: {
      registrationNumber: currentBooking.vehicle?.registrationNumber,
      kilometers: currentBooking.vehicle?.kilometers,
      vinCode: currentBooking.vehicle?.vinCode,
    },
  });

  useEffect(() => {
    const registerNumber =
      currentBooking.vehicle?.registrationNumber === undefined ||
      currentBooking.vehicle?.registrationNumber === null
        ? ''
        : currentBooking.vehicle.registrationNumber;

    dispatch(setRegistrationNumber(registerNumber));
  }, []);

  const updateVehicleFR = (values: VehicleFormValues): void => {
    const { registrationNumber } = values;
    if (registrationNumber && currentBooking) {
      const infoVehicle: BookingVehicle = {
        vehicleId: currentVehicle ? currentVehicle.id : values.vehicleId,
        ...(registrationNumber?.length !== 0 && {
          registrationNumber: values.registrationNumber,
        }),
        kilometers: values.kilometers,
        vinCode: values.vinCode,
      };

      const newBooking: Booking = {
        ...currentBooking,
        vehicle: infoVehicle,
      };
      dispatch(setBooking(newBooking));

      dispatch(
        getVehicleByPlateNumber(
          registrationNumber,
          (newVehicleId) => {
            dispatch(setRegistrationNumber(registrationNumber));
            dispatch(setBooking(newBooking));
            dispatch(
              updateVehicule(
                currentBooking?.bookingId || '',
                { ...infoVehicle, vehicleId: newVehicleId },
                () => {
                  dispatch(
                    setNotification({
                      severity: SeverityMessage.SUCCESS,
                      message: lg.general.success,
                    })
                  );
                  setShow(false);
                },
                (error: Error) =>
                  dispatch(
                    setNotification({
                      severity: SeverityMessage.ERROR,
                      message: error.message,
                    })
                  )
              )
            );
          },
          (error: Error) => {
            dispatch(
              setNotification({
                severity: SeverityMessage.ERROR,
                message: error.message,
              })
            );
          }
        )
      );

      setShow(false);
      reset(infoVehicle);
    }
  };

  const updateVehicleBE = (values: VehicleFormValues): void => {
    dispatch(
      getVehicleByVinCode(
        values.vinCode || '',
        (newVehicleId: string) => {
          const infoVehicle = {
            vehicleId: newVehicleId,
            kilometers: values.kilometers,
            vinCode: values.vinCode,
          };

          const newBooking: Booking = {
            ...currentBooking,
            vehicle: infoVehicle,
          };

          dispatch(setRegistrationNumber(values.vinCode || ''));
          dispatch(setBooking(newBooking));

          dispatch(
            updateVehicule(
              currentBooking?.bookingId || '',
              infoVehicle,
              () => {
                dispatch(
                  setNotification({
                    severity: SeverityMessage.SUCCESS,
                    message: lg.general.success,
                  })
                );
                setShow(false);
              },
              (error: Error) =>
                dispatch(
                  setNotification({
                    severity: SeverityMessage.ERROR,
                    message: error.message,
                  })
                )
            )
          );
        },
        () => {}
      )
    );
  };

  const onSubmit = (values: VehicleFormValues): void =>
    isGarageBe ? updateVehicleBE(values) : updateVehicleFR(values);

  return (
    <div>
      <div role="button" tabIndex={0} onClick={handleShow}>
        {children}
      </div>
      <Modal
        dialogClassName={style.content}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className={style.modalHeader}>
          <span className={style.titleModal}>{lg.command.vehicle.title}</span>
          <img
            className="imgPopup"
            src={close}
            alt="X"
            onClick={() => handleClose()}
          />
        </Modal.Header>

        <Modal.Body>
          <div className={style.colWidth}>
            {isGarageBe ? (
              <InputLabel
                name="vinCode"
                label={lg.quote.vinCode}
                reference={register({
                  required: true,
                  pattern: {
                    message: lg.quote.invalidRegistrationVin,
                    value:
                      /^[A-HJ-NPR-Za-hj-npr-z\d]{8}[\dX][A-HJ-NPR-Za-hj-npr-z\d]{2}\d{6}$/i,
                  },
                })}
              >
                {errors.vinCode &&
                  errors.vinCode.type === 'pattern' &&
                  lg.quote.invalidRegistrationVin}
                {errors.vinCode &&
                  errors.vinCode.type === 'required' &&
                  lg.quote.messageField}
              </InputLabel>
            ) : (
              <InputLabel
                name="registrationNumber"
                label={lg.quote.registrationLabel}
                reference={register({
                  required: true,
                  pattern: {
                    message: lg.quote.invalidRegistration,
                    value: /^[A-Z]{2}\d{3}[A-Z]{2}$/i,
                  },
                })}
              >
                {errors.registrationNumber &&
                  errors.registrationNumber.type === 'pattern' &&
                  lg.quote.invalidRegistration}
                {errors.registrationNumber &&
                  errors.registrationNumber.type === 'required' &&
                  lg.quote.messageField}
              </InputLabel>
            )}
            <InputLabel
              label={lg.quote.km}
              name="kilometers"
              reference={register({
                required: false,
                pattern: {
                  message: lg.quote.kmError,
                  value: /^([1-9]\d*)(\.\d+)?$/,
                },
              })}
            >
              {errors.kilometers && lg.quote.kmError}
            </InputLabel>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            theme="light"
            title={lg.command.vehicle.close}
            onClick={() => handleClose()}
          />
          <Button
            theme="dark"
            title={lg.command.vehicle.confirm}
            onClick={handleSubmit(onSubmit)}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditVehicle;

import makeStyles from '@material-ui/core/styles/makeStyles';
import moment from 'moment';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import head from 'lodash/head';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../assets/generic-components/Button';
import colors from '../../../assets/style-colors';
import close from '../../../assets/style-elements/01-electrons-icon-close.png';
import { setNotification } from '../../../redux/stores/global/actions';
import {
  MessageNotification,
  SeverityMessage,
} from '../../../redux/stores/global/types';
import {
  setBooking,
  updateBookingDate,
} from '../../../redux/stores/planning/actions';
import { Store } from '../../../redux/types';
import Calendar from '../../planning-page/components/month-view-right/calendar';
import { getDateSelectionRange } from '../../../utils/calendar';
import { Booking } from '../../../redux/stores/planning/types';

const Appointment: React.FC = ({ children }) => {
  const useStyles = makeStyles(() => ({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: 20,
      flex: '100%',
      width: '530px',
      margin: '0px auto',
      fontWeight: 'bold',
      lineHeight: 'normal',
    },
    content: {
      width: '530px',
      margin: '0px auto',
    },
    form: {
      appearance: 'none',
      width: '130px',
      height: '40px',
      textAlign: 'center',
      backgroundColor: colors.white,
      border: '1px solid transparent',
      fontSize: '13px',
      borderRadius: '4px',
    },
    group: {
      marginBottom: '0px',
    },
    head: {
      display: 'flex',
      flex: '100%',
      fontSize: '15px',

      color: colors.dark,
      marginTop: '5px',
    },
    title: {
      display: 'flex',
      flex: '100%',
      fontSize: '12px',
      color: colors.slateGrey,

      fontWeight: 500,
    },
    fields: {
      display: 'flex',
      flexDirection: 'row',

      '& input': {
        border: 'none',
        background: colors.paleGrey,
        borderRadius: '8px',
        marginRight: '10px',
        padding: '0px 10px',
        fontSize: '13px',
        color: colors.dark,
        fontWeight: 'bold',
      },
      '& .calendar': {
        position: 'absolute',
        transform: 'translate(-20px,45px)',
      },
      '& select': {
        border: 'none',
        background: colors.paleGrey,
        borderRadius: '8px',
        color: colors.dark,
        fontWeight: 'bold',
      },
    },
    modal_header: {
      padding: '30px 30px 0px 30px',
      border: 'none',
    },
    modal_body: {
      padding: '15px 30px 15px 30px',
    },
    modal_footer: {
      background: colors.paleGrey,
      borderTop: `0px solid ${colors.veryPaleGrey}`,
      padding: '20px',
    },
    imgPopup: {
      width: '17px',
      height: '17px',
      cursor: 'pointer',
    },
  }));

  // CONSTANTS:

  const classes = useStyles();
  const {
    languageReducer,
    planningStore: { currentBooking },
  } = useSelector((state: Store) => state);

  if (!currentBooking) {
    return null;
  }

  const dispatch = useDispatch();
  const lg = languageReducer.language;
  const [show, setShow] = useState(false);
  const [hide] = useState(false);
  const [hour, setHour] = useState<string | null>(
    head(currentBooking.bookingDepositHour?.split('-')) || null
  );
  const [calendar, setCalendar] = useState(false);
  const [newDate, setNewDate] = useState(moment(currentBooking.bookingDate));

  // FUNCTIONS:

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (event: any) => {
    setHour(event.target.value);
  };

  // HTML:
  return (
    <>
      <div role="button" tabIndex={0} onClick={handleShow}>
        {children}
      </div>
      <Modal
        dialogClassName={classes.content}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className={classes.modal_header}>
          <div className={classes.root}>
            <span className={classes.head}>{lg.command.appointment.title}</span>
            <img
              className={classes.imgPopup}
              src={close}
              alt="X"
              onClick={() => handleClose()}
            />
          </div>
        </Modal.Header>
        <Modal.Body className={classes.modal_body}>
          {!hide ? (
            <div className={classes.root}>
              <div className={classes.fields}>
                <input
                  value={newDate.format('DD MMMM YYYY')}
                  onClick={() => {
                    setCalendar(!calendar);
                  }}
                  name="date"
                  id="date"
                />
                {calendar && (
                  <div className="calendar">
                    <Calendar
                      onSelect={(date) => {
                        setNewDate(date);
                        setCalendar(false);
                      }}
                    />
                  </div>
                )}
                <Form.Group className={classes.group}>
                  <Form.Control
                    className={classes.form}
                    as="select"
                    value={hour || ''}
                    onChange={handleChange}
                  >
                    {getDateSelectionRange().map((h) => (
                      <option key={h}>{h}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div>
            </div>
          ) : (
            <div className={classes.root}>
              <p className={classes.title}>{lg.command.appointment.confirm}</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className={classes.modal_footer}>
          {!hide ? (
            <>
              <Button
                theme="light"
                title={lg.command.appointment.close}
                onClick={() => handleClose()}
              />
              <Button
                theme="dark"
                title={lg.command.appointment.changedDate}
                onClick={() => {
                  if (currentBooking) {
                    const newBooking: Booking = {
                      ...currentBooking,
                      bookingDepositHour: hour || '',
                    };
                    dispatch(
                      updateBookingDate(
                        currentBooking.bookingId || '',
                        newDate.format('YYYY-MM-DDTHH:mm:ssZ'),
                        hour || '',
                        () => {
                          dispatch(setBooking(newBooking));
                          dispatch(
                            setNotification({
                              severity: SeverityMessage.SUCCESS,
                              message: lg.command.appointment.confirm,
                            })
                          );
                          setShow(false);
                        },
                        () => {
                          const notification: MessageNotification = {
                            severity: SeverityMessage.ERROR,
                            message: lg.error.server,
                          };
                          dispatch(setNotification(notification));
                        }
                      )
                    );
                  }
                }}
              />
            </>
          ) : (
            <Button
              theme="light"
              title={lg.command.appointment.backCommand}
              onClick={() => setShow(false)}
            />
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Appointment;

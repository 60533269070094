import makeStyles from '@material-ui/core/styles/makeStyles';
import colors from '../../../../assets/style-colors';

const useStyles = makeStyles(() => ({
  container: {
    minHeight: '100vh',
    width: '100%',
    margin: 'auto',
    padding: '20px',

    '& .container-btn-tablet': {
      position: 'fixed',
      bottom: 0,
      left: '55px',
      right: 0,
      backgroundColor: `${colors.white}`,
      padding: '10px 0',
      width: '100%',

      '& .box-btn': {
        display: 'flex',
        justifyContent: 'flex-start',
        paddingLeft: '30px',
        width: '100%',

        '& button': {
          marginRight: 0,
        },
      },
    },

    '& .container-btn': {
      margin: '40px 0',

      '& .box-btn': {
        display: 'flex',
        justifyContent: 'space-between',

        '& button': {
          marginRight: 0,
        },
      },
    },

    '& button': {
      marginBottom: '20px',
    },
  },

  boxPrice: {
    fontSize: '13px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '17px 20px',
    borderBottom: `1px solid ${colors.WhiteDark}`,

    '& p:first-child': {
      maxWidth: '230px',
      lineHeight: '18px',
      marginBottom: 0,
    },

    '& p:last-child': {
      color: `${colors.slateGrey}`,
      marginBottom: 0,
    },
  },

  backwardGrey: {
    color: `${colors.dark}`,
    fontSize: '17px',
  },

  backward: {
    fontSize: '12px',
    color: `${colors.slateGrey}`,
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',

    '& svg': {
      fontSize: '17px',
    },

    '& p': {
      margin: 0,
      padding: 0,
    },
  },

  wrapper: {
    paddingTop: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '20px',
  },

  containerBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '55%',
  },

  containerInput: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: `${colors.paleGreyDrank}`,
    padding: '20px 25px',
    borderRadius: '2px',

    '& .d-flex': {
      '& > div': {
        width: '50%',

        '&:first-child': {
          marginRight: '20px',
        },

        '& label, input': {
          width: '100%',
        },
      },
    },
  },

  phoneWrapper: {
    marginTop: '13px',

    '& span': {
      display: 'block',
      height: '14px',
      fontSize: '12px',
      lineHeight: 1.17,
      marginBottom: '10px',
    },
  },

  boxPrestation: {
    margin: '20px 0 12px',

    '& img, p': {
      display: 'inline-block',
    },

    '& p': {
      marginLeft: '10px',
      marginBottom: 0,
    },

    '& h5': {
      fontSize: '14px',
      marginBottom: '30px',
      color: `${colors.CANCELLED}`,
    },
  },

  logo: {
    flexDirection: 'row',
    display: 'flex',
    marginRight: '10px',
    width: '2rem',
  },

  listOption: {
    display: 'flex',
    flexWrap: 'wrap',

    '& li': {
      flex: '0 0 50%',
      marginRight: '20px',
      maxWidth: 'calc(50% - 10px)',
      marginBottom: '25px',

      '&:nth-child(even)': {
        marginRight: 0,
      },
    },
  },

  wrapSl: {
    '& .form-group': {
      marginBottom: 0,

      '& .label': {
        marginBottom: '10px',
      },
    },

    '& select.form-control': {
      width: '100%',
    },
  },

  boxInputDateTime: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    padding: '25px 0',
  },

  fromBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    width: '100%',
  },

  wrapCalendar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    flex: '0 0 30%',
    maxWidth: '30%',
    height: '66px',

    '& label': {
      fontSize: '12px',
    },

    '& button': {
      outline: 'none',
      width: '100%',
      height: '40px',
      border: 'none',
      marginBottom: 0,

      fontSize: '13px',

      '& + div': {
        '& table': {
          '& td': {
            padding: '5px !important',
          },
        },

        '& button': {
          width: 'auto',
          minWidth: '24px',
          height: '22px !important',
          marginBottom: 0,
        },
      },
    },

    '& > div': {
      position: 'absolute',
    },
  },

  wrapHour: {
    flex: '0 0 20%',
    maxWidth: '20%',
    marginLeft: '10px',
    paddingTop: 0,
    height: '70px',

    '& select': {
      width: '100%',
    },

    '& .form-group': {
      marginBottom: 0,
    },
  },

  boxTextaera: {
    width: '100%',
  },

  containerOrder: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: `${colors.white}`,
    borderRadius: '2px',
    minWidth: '340px',
    width: '100%',
    height: '50%',
    marginTop: '50px',
  },

  dayViewContainer: {
    display: 'flex',
    width: '30vw',
    minWidth: '340px',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: `${colors.white}`,
    boxShadow: '0 2px 18px 0 rgba(136, 135, 135, 0.17)',
    borderRadius: '10px',
    marginLeft: '20px',
    padding: '20px',
    marginTop: '6px',
  },

  dayViewHead: {
    paddingTop: '20px',
  },

  headerTotal: {
    display: 'flex',
    alignItems: 'center',
    padding: '20px',
    borderBottom: '1px solid',
    borderBottomColor: `${colors.WhiteDark}`,
  },

  totalBillingPriceVat: {
    fontWeight: 'bold',
  },
}));

export default useStyles;

import React from 'react';
import { useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import downloadIcon from '../../../assets/style-elements/01-electrons-icon-download@3x.png';
import colors from '../../../assets/style-colors';
import { Store } from '../../../redux/types';
import { ViewsListEnum } from '../../../redux/stores/billings/type';

const useStyles = makeStyles(() => ({
  pdf: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: colors.white,
    borderRadius: '8px',
    padding: '9px 0px',
    marginBottom: '15px',
    cursor: 'pointer',
    boxShadow: '0 2px 18px 0 rgba(214, 214, 214, 0.5)',
    '&:hover': {
      textDecoration: 'none',
    },
    '& img': {
      width: '16px',
      height: '16px',
      marginRight: '9px',
    },
    '& span': {
      display: 'flex',

      fontSize: '12px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: colors.dark,
      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
}));

interface DownloadProps {
  view: ViewsListEnum;
  garageId: string;
  year: string;
  month: string;
}

const Download: React.FC<DownloadProps> = ({ view, garageId, year, month }) => {
  const {
    languageReducer: { language: lg },
  } = useSelector((state: Store) => state);
  const classes = useStyles();

  return (
    <a
      target="_blank"
      className={classes.pdf}
      href={`${process.env.REACT_APP_FALLBACK_URL}/billing/download?type-form=${view}&garage-id=${garageId}&yearSelect=${year}&monthSelect=${month}`}
    >
      <img src={downloadIcon} alt="downloadIcon" />
      <span>{lg.billing.asset.pdfDownload}</span>
    </a>
  );
};

export default Download;

import { ActionTypes, SET_CITIES } from './actions';
import { CityStore } from './types';

const initialState: CityStore = {
  cities: []
};

const citiesReducer = (
  state: CityStore = initialState,
  action: ActionTypes
) => {
  switch (action.type) {
    case SET_CITIES:
      return { ...state, cities: action.payload };
    default:
      return state;
  }
};

export default citiesReducer;

const helpActions = {};

helpActions.setFaq = (dispatch, data) => {
  dispatch({
    type: 'SET_FAQ',
    payload: data
  });
};
helpActions.setCoupon = (dispath, data) => {
  dispath({
    type: 'SET_COUPON',
    payload: data
  });
};
helpActions.setCommission = (dispath, data) => {
  dispath({
    type: 'SET_COMMISSION',
    payload: data
  });
};
helpActions.setPiece = (dispath, data) => {
  dispath({
    type: 'SET_PIECE',
    payload: data
  });
};

export default helpActions;

import React from 'react';
import { connect } from 'react-redux';
import { LanguageStore } from '../../../../redux/stores/languages';

import Logger from '../../../../services/logger';

interface ErrorBoundaryProps {
  languageReducer: LanguageStore;
  children: JSX.Element;
}

// REDUX
const mapStateToProps = ({
  languageReducer,
}: {
  languageReducer: LanguageStore;
}) => ({
  languageReducer,
});

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  { hasError: boolean; eventId: string | null }
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      eventId: null,
    };
  }

  static getDerivedStateFromError(error: any) {
    const eventId = Logger.logException(error);

    return { hasError: true, eventId };
  }

  render() {
    const { hasError, eventId } = this.state;
    const { children, languageReducer } = this.props;
    if (hasError) {
      return (
        <div className="error-container">
          <h1>{languageReducer}</h1>
          {eventId && <p>Code erreur : {eventId}</p>}
        </div>
      );
    }

    return children;
  }
}

interface ErrorBoundaryContainerProps {
  children: JSX.Element;
  languageReducer: LanguageStore;
}

const ErrorBoundaryContainer = ({
  children,
  languageReducer,
}: ErrorBoundaryContainerProps) => (
  <ErrorBoundary languageReducer={languageReducer}>{children}</ErrorBoundary>
);

export default connect(mapStateToProps, {})(ErrorBoundaryContainer);

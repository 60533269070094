import React, { useEffect, useState } from 'react';
import last from 'lodash/last';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import CheckboxLabel from '../../../../assets/generic-components/CheckboxLabel';
import colors from '../../../../assets/style-colors';
import { RegularWeek } from '../../../../redux/stores/calendar/types';
import { Store } from '../../../../redux/types';
import {
  array2HoursRange,
  hoursRange2Array,
  getDateSelectionRange,
} from '../../../../utils/calendar';
import InputLabel from '../../../../assets/generic-components/InputLabel';

interface ListItemProps {
  label: string;
  className?: string;
  day: RegularWeek;
  updateDay: (day: RegularWeek) => void;
}

const ListItem: React.FC<ListItemProps> = ({
  label,
  className,
  day,
  updateDay,
}) => {
  const {
    languageReducer: { language: lg },
  } = useSelector((state: Store) => state);

  const [daySlots, setDaySlots] = useState<number>(parseInt(day.slots));
  const [lunchBreak, setLunchBreak] = useState<boolean>(
    day.openingHours.length > 1
  );
  const hoursRange = hoursRange2Array(day.openingHours);

  const updateDayForm = (hasBreak: boolean, slots: number) => {
    updateDay({
      ...day,
      slots,
      openingHours: hasBreak
        ? [`${hoursRange[0]}-12:00`, `14:00-${last(hoursRange)}`]
        : [`${hoursRange[0]}-${last(hoursRange)}`],
    });
  };

  useEffect(() => {
    setLunchBreak(day.openingHours.length > 1);
    setDaySlots(parseInt(day.slots));
  }, [day]);

  return (
    <div className={className}>
      <div className="boxDay">
        <div className="titleDays">{label}</div>
      </div>
      {(day.slots === 0 || (day.openingHours || []).length === 0) && (
        <div className="closedDays">{lg.planning.closed}</div>
      )}
      {day.slots !== 0 && (day.openingHours || []).length > 0 && (
        <>
          <div className="mainContainer">
            {hoursRange.map((hour, index) => (
              <React.Fragment key={`${hour}-${index}`}>
                <Form.Group className="item">
                  <Form.Control
                    as="select"
                    defaultValue={hour}
                    onChange={(e) => {
                      const hours = [
                        ...hoursRange.slice(0, index),
                        e.currentTarget.value,
                        ...hoursRange.slice(index + 1),
                      ];
                      updateDay({
                        ...day,
                        openingHours: array2HoursRange(hours),
                      });
                    }}
                  >
                    {getDateSelectionRange().map((h) => (
                      <option key={h}>{h}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
                {index % 2 === 0 && <div>-</div>}
              </React.Fragment>
            ))}
          </div>

          <div className="boxChekbox">
            <CheckboxLabel
              checked={lunchBreak}
              label={lg.planning.modification.pause}
              onchange={() => {
                updateDayForm(!lunchBreak, daySlots);
              }}
            />
          </div>

          <div className="hours">
            <InputLabel
              value={daySlots}
              type="number"
              required
              onChange={(e) => {
                setDaySlots(parseInt(e.target.value));
                updateDayForm(lunchBreak, parseInt(e.target.value));
              }}
              onFocus={(e) => e.target.select()}
            />
          </div>
        </>
      )}
    </div>
  );
};

ListItem.defaultProps = {
  className: undefined,
};

export default styled(ListItem)`
  display: flex;
  flex-direction: row;
  height: 80px;
  justify-content: space-between;
  padding-top: 40px;
  max-width: 650px;

  .mainContainer {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    width: 370px;
  }
  .item {
    margin: 0 10px 0 10px;
  }

  .boxDay {
    width: 70px;
    height: 40px;
    display: flex;
    justify-content: start;
    align-items: center;
    border-radius: 0.25rem;
  }

  .titleDays {
    color: ${colors.slateGrey};
    font-size: 12px;
    text-transform: capitalize;
    margin: 0;
    padding: 5px;
    font-weight: 500;
  }

  .closedDays {
    background-color: ${colors.veryLightBlue};
    color: ${colors.slateGrey};
    flex-grow: 1;
    text-align: center;
    margin: 0.5em;
    border-radius: 4px;
    line-height: 2.5em;
  }

  .hours {
    width: 70px;

    input {
      width: 80px;
    }
  }

  .form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 70px;
    height: 40px;
    text-align: center;
    text-align-last: center;
    background-color: ${colors.white};
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 12px;
    border-radius: 0.25rem;
    background-image: unset;
  }
  .boxChekbox {
    margin: 0;
    padding: 0;
  }
`;

import React, { useState } from 'react';
import {
  OperationTypes,
  PartOperation,
  PartOperationIds,
  RepairTime,
} from '../../../../redux/stores/planning/types';
import OperationLine from './operationLine';
import {
  OneOfOperationType,
  reformatOperationWithNewValues,
} from '../../../../services/quote/operation';
import { formatPrice } from '../../../../services/quote/quote-service';

interface QuoteOperationLineProps {
  /**
   *  Operation types.
   */
  operation: OneOfOperationType;
  /**
   *  Whether or not operation is packaged.
   */
  isPackaged?: boolean;
  /**
   *  Operation label.
   */
  label: string;
  /**
   *  Language.
   */
  locale: string;
  /**
   *  Tax.
   */
  vat: number;
  /**
   *  Function to update price, quantity and discount percent values.
   */
  onOperationQtyDiscountUpdate?: (operation: OneOfOperationType) => void;
  /**
   *  Whether or not inputs are updatable.
   */
  isUpdatable: boolean;
  /**
   *  Whether or not quote summary page is updatable.
   */
  isSummaryUpdatable?: boolean;
  /**
   *  Function to track change event on inputs.
   */
  onInputChange: () => void;
}

const QuoteOperationLine: React.FC<QuoteOperationLineProps> = ({
  operation,
  label,
  locale,
  vat,
  onOperationQtyDiscountUpdate,
  isPackaged = false,
  isUpdatable,
  isSummaryUpdatable,
  onInputChange,
}) => {
  const formatNumber = (num: number) =>
    new Intl.NumberFormat(locale, {}).format(num);

  const [operationValues, setOperationValues] = useState(operation);

  const getPartReferences = (operationPart: PartOperation): Array<string> => {
    if (operationPart.operationId === PartOperationIds.REPLACE_PNEUMATICS) {
      return [operationPart.label];
    }
    return operationPart.oemPartsId;
  };

  const getDiscountPercentOperation = (
    o: PartOperation | RepairTime
  ): string => {
    if (
      ![OperationTypes.PARTS, OperationTypes.REPAIR_TIME].includes(
        o.operationType as any
      )
    ) {
      return '0';
    }

    return o.promotion?.amount
      ? new Intl.NumberFormat(locale).format(o.promotion.amount)
      : new Intl.NumberFormat(locale).format(o.garageDiscount);
  };

  const updateOperationWithNewValues = (
    newQty: number,
    newDiscountPercent: number,
    newUnitPrice: number
  ): void => {
    const newOperation = reformatOperationWithNewValues(
      newQty,
      newDiscountPercent,
      newUnitPrice,
      operation
    );
    setOperationValues(newOperation);
    onOperationQtyDiscountUpdate?.(newOperation);
  };

  const operationTotalWithTax = formatPrice(
    operationValues.total * (1 + vat / 100),
    locale
  );

  const operationProps = {
    operationId: operationValues.operationId,
    type: operationValues.operationType,
    label,
    locale,
    isUpdatable: isPackaged ? false : isUpdatable,
    isSummaryUpdatable,
    isPackaged,
    onOperationQtyDiscountUpdate: updateOperationWithNewValues,
    operationTotal: formatPrice(operationValues.total, locale),
    operationTotalWithTax,
    onInputChange,
  };

  switch (operationValues.operationType) {
    case OperationTypes.PARTS:
      return (
        <OperationLine
          partReferences={getPartReferences(operationValues)}
          partSelected={operationValues.partsSelected}
          discountTotal={formatPrice(operationValues.discountTotal, locale)}
          unitPrice={operationValues.unitPrice}
          quantity={operationValues.quantity}
          discountPercent={getDiscountPercentOperation(operationValues)}
          {...operationProps}
        />
      );

    case OperationTypes.REPAIR_TIME:
      return (
        <OperationLine
          discountTotal={formatPrice(
            operationValues.garageDiscountValue,
            locale
          )}
          unitPrice={operationValues.unitPrice}
          quantity={`${formatNumber(operationValues.duration)} h`}
          discountPercent={getDiscountPercentOperation(operationValues)}
          isQuantityDigitAllowed
          {...operationProps}
        />
      );
    case OperationTypes.LIQUID:
      return (
        <OperationLine
          partReferences={[operationValues.liquidId]}
          discountTotal="0"
          unitPrice={operationValues.unitPrice}
          quantity={`${formatNumber(operationValues.volume)} l`}
          isQuantityDigitAllowed
          {...operationProps}
        />
      );
    case OperationTypes.FIXED_PRICE:
      return (
        <OperationLine
          unitPrice={operationValues.total}
          quantity={`${('quantity' in operation && operation.quantity) || 1}`}
          {...operationProps}
        />
      );
    default:
      return null;
  }
};

QuoteOperationLine.defaultProps = {
  onOperationQtyDiscountUpdate: undefined,
  isPackaged: false,
  isSummaryUpdatable: false,
};

export default React.memo(QuoteOperationLine);

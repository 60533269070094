import last from 'lodash/last';
import { BookingHistory, PlanningStore, QuoteStatus } from "./types";

export const getCurrentBookingId = (
  state: PlanningStore
): string | undefined => state.currentBooking?.bookingId;

export const getCurrentBookingHistories = (
  state: PlanningStore
): BookingHistory[] | undefined =>
  state.currentBooking?.bookingHistories;

export const getCurrentBookingQuoteReportedMessage = (
  state: PlanningStore,
  quoteId: string
): BookingHistory | undefined => {
  const bookingHistories = getCurrentBookingHistories(state) ?? [];

  return last(bookingHistories.filter((bookingHistory: BookingHistory) => (
    bookingHistory.newValue === QuoteStatus.REPORTED &&
    bookingHistory.quoteId === quoteId
  )));
};

export default getCurrentBookingId;
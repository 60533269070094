import { createTheme, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { QUOTE } from '../../../router/routes';
import colors from '../../../assets/style-colors';
import calculatorIcon from '../../../assets/style-elements/total.svg';
import { Store } from '../../../redux/types';
import useModeTablet from '../modeTablet';
import { Networks } from '../../../redux/stores/global/types';

import Button from '../../../assets/generic-components/Button';
import { QuoteType } from '../../../redux/stores/quotes/types';

const Total: React.FC = () => {
  const {
    languageReducer,
    planningStore: { currentBooking },
  } = useSelector<Store, Store>((state) => state);

  if (!currentBooking) return null;

  const lg = languageReducer.language;
  const planningStoreSet = currentBooking.networkId;

  const coupons = new Set(
    currentBooking.quotes
      .filter((q) => q.coupon?.code)
      .map((q) => q?.coupon?.code)
  );
  const hasCoupon = coupons.size > 0;
  const couponCodes = Array.from(coupons).join(',');
  const modeTablet = useModeTablet();
  const valueTotal = currentBooking.price.totalMaxWithTax?.toFixed(2) || 0;
  const formatPrice = (price: number) =>
    new Intl.NumberFormat(lg.locale, {
      style: 'currency',
      currency: 'EUR',
    }).format(price);

  const isWithForkPrice = () =>
    currentBooking.price.total?.toFixed(2) !==
    currentBooking.price.totalMax?.toFixed(2);

  // STATES:

  // STYLES:
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1200,
        xl: 1920,
      },
    },
  });

  const useStyles = makeStyles(() => ({
    total: {
      display: 'flex',
      flex: modeTablet ? '100%' : '70%',
      '& .bloc': {
        display: 'flex',
        borderRadius: '10px',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'baseline',
        padding: '25px 0px',
        marginTop: '20px',
        background: 'white',
        [theme.breakpoints.up('lg')]: {
          marginLeft: `${theme.breakpoints.values.lg ? '0' : '15px'}`,
          width: '100%',
          marginTop: '0px',
        },
      },
      '& .in': {
        display: 'flex',
        flex: '100%',
        flexWrap: 'wrap',
        '& .totalCommand': {
          display: 'flex',
          flex: '100%',
          flewWrap: 'nowrap',
          alignItems: 'center',
          padding: '0px 20px 10px 20px',
          marginBottom: '15px',

          '& img': {
            width: '2rem',
          },

          '& .span': {
            fontSize: '15px',

            lineHeight: '1.27',
            fontWeight: 'bold',
            color: colors.dark,
            marginLeft: '11px',
          },
        },
        '& .totalHorsRemise': {
          display: 'flex',
          flex: '100%',
          flexWrap: 'nowrap',
          padding: '15px 20px',
          justifyContent: 'space-between',
          borderTop: 'solid 1px #eaebf1',
          marginBottom: '15px',
          '& .left': {
            fontSize: '13px',

            lineHeight: '1.23',

            color: colors.dark,
          },
          '& .right': {
            fontSize: '13px',

            lineHeight: '0.92',

            color: colors.slateGrey,
          },
        },
        '& .totalAvecRemiseCoupon': {
          display: 'flex',
          flex: '100%',
          flewWrap: 'nowrap',
          padding: '15px 20px',
          justifyContent: 'space-between',
          borderTop: 'solid 1px #eaebf1',
          '& .left': {
            fontSize: '13px',
            fontWeight: 'normal',

            lineHeight: '1.23',

            color: colors.dark,
          },
          '& .right': {
            fontSize: '13px',

            lineHeight: '0.92',

            color: colors.slateGrey,
          },
        },
        '& .totalHT': {
          display: 'flex',
          flex: '100%',
          flewWrap: 'nowrap',
          padding: '15px 20px',
          justifyContent: 'space-between',
          borderTop: 'solid 1px #eaebf1',
          '& .left': {
            fontSize: '13px',
            fontWeight: 'normal',

            lineHeight: '1.23',

            color: colors.dark,
            textTransform: 'uppercase',
          },
          '& .right': {
            fontSize: '13px',

            lineHeight: '0.92',

            color: colors.slateGrey,
          },
        },
        '& .tva': {
          display: 'flex',
          flex: '100%',
          flewWrap: 'nowrap',
          padding: '15px 20px',
          justifyContent: 'space-between',
          borderTop: 'solid 1px #eaebf1',
          '& .left': {
            fontSize: '13px',
            fontWeight: 'normal',

            lineHeight: '1.23',

            color: colors.dark,
            textTransform: 'uppercase',
          },
          '& .right': {
            fontSize: '13px',

            lineHeight: '0.92',

            color: colors.slateGrey,
          },
        },
        '& .montantFacturer': {
          display: 'flex',
          flex: '100%',
          flewWrap: 'nowrap',
          padding: '20px 20px 10px 20px',
          justifyContent: 'space-between',
          borderTop: 'solid 1px #eaebf1',
          '& .left': {
            fontSize: '14px',

            lineHeight: '1.14',

            color: colors.dark,
          },
          '& .bold': {
            fontWeight: 'bold',
          },
          '& .right': {
            paddingTop: '1px',

            fontSize: '14px',

            lineHeight: '0.86',

            color: colors.dark,
          },
        },
        '& .fourchette': {
          display: 'flex',
          flex: '100%',
          flewWrap: 'nowrap',
          padding: '10px 20px',
          justifyContent: 'space-between',
          '& .left': {
            fontSize: '13px',
            fontWeight: 'normal',

            lineHeight: '1.23',

            color: colors.dark,
          },
          '& .right': {
            paddingTop: '4px',

            fontSize: '13px',

            lineHeight: '0.92',

            color: colors.slateGrey,
          },
        },
        '& .coupon': {
          display: 'flex',
          flex: '100%',
          flewWrap: 'nowrap',
          padding: '10px 20px 4px 20px',
          justifyContent: 'space-between',
          '& .left': {
            fontSize: '13px',
            fontWeight: 'normal',

            lineHeight: '1.23',

            color: colors.coral,
          },
          '& .right': {
            fontSize: '13px',

            lineHeight: '0.92',

            color: colors.coral,
          },
        },
        '& .price-advice': {
          display: 'flex',
          gap: '.5rem',
          alignItems: 'center',
          margin: '1rem',
          padding: '1rem',
          borderRadius: '10px',
          backgroundColor: '#eef0f7',
          color: '#4D4D4D',

          '&--icon': {
            width: '1.5rem',
            flexShrink: 0,
          },
        },
      },
    },
    span_total: {
      display: 'flex',
      flex: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px 50px',
      '& .textLongUpdate': {
        width: '100%',
        color: colors.dark,
        fontSize: '16px',
        '& .redirectedQuote': {
          marginTop: '10px',
        },
      },
      '& .textLong': {
        fontSize: '16px',
        fontWeight: 'normal',

        lineHeight: '2.38',

        color: colors.dark,
      },
    },

    mb10: {
      marginBottom: '10px',
    },

    mb20: {
      marginBottom: '20px',
    },
  }));

  const classes = useStyles();
  const history = useHistory();
  const { price } = currentBooking;

  if (price.total === 0) {
    return (
      <div className={classes.total}>
        {currentBooking?.quotes[0].quoteType === QuoteType.ONLINE ? (
          <div className="bloc clearUp">
            <div className="in">
              <span className="totalCommand">
                <img
                  className="img"
                  alt="calculatorIcon"
                  src={calculatorIcon}
                />
                <span className="span">{lg.command.total.title}</span>
              </span>

              <span className="totalHorsRemise">
                <span className="left">{lg.command.total.totalHorsRemise}</span>
                <span className="right">
                  {formatPrice(price.totalDeductionLess || 0)}
                </span>
              </span>

              <span className="totalAvecRemiseCoupon">
                <span className="left">{lg.quote.totalDiscount}</span>
                <span className="right">{formatPrice(price.total)}</span>
              </span>

              {currentBooking.networkId !== 'IDG' && (
                <span className="totalAvecRemiseCoupon">
                  <span className="left">
                    {lg.command.total.totalCommission}
                  </span>
                  <span className="right">
                    {formatPrice(price.discountTotal || 0)}
                  </span>
                </span>
              )}

              {hasCoupon && (
                <span className="coupon">
                  <span className="left">
                    {lg.command.total.couponCode} HT {couponCodes}
                  </span>
                  <span className="right">
                    {formatPrice(price.coupon?.amount || 0)}
                  </span>
                </span>
              )}

              <span className="montantFacturer">
                <span className="left">{lg.command.total.totalHT}</span>
                <span className="right">{formatPrice(price.total)}</span>
              </span>

              <span className="tva">
                <span className="left">{lg.command.total.tva}</span>
                <span className="right">
                  {formatPrice(price.vatValue || 0)}
                </span>
              </span>

              <span className="montantFacturer">
                <span className="left">{lg.command.total.montantFacturer}</span>
                <span className="right">
                  {formatPrice(price.totalWithTax || 0)}
                </span>
              </span>
              {isWithForkPrice() && (
                <span className="fourchette">
                  <span className="left">{lg.command.total.fourchette}</span>
                  <span className="right">{valueTotal}</span>
                </span>
              )}
            </div>
          </div>
        ) : (
          <div className="bloc">
            <div className="in">
              <span className="totalCommand">
                <img
                  className="img"
                  alt="calculatorIcon"
                  src={calculatorIcon}
                />
                <span className="span">
                  {planningStoreSet === Networks.GARAGE_OWNER &&
                  currentBooking?.quotes[0].quoteType === QuoteType.PRICELESS
                    ? lg.command.total.quotePricelessTitle
                    : lg.command.total.quotePricelessWebsite}
                </span>
              </span>
              <span className={classes.span_total}>
                <span
                  className={planningStoreSet ? 'textLongUpdate' : 'textLong'}
                >
                  {planningStoreSet === Networks.GARAGE_OWNER &&
                  currentBooking?.quotes[0].quoteType ===
                    QuoteType.PRICELESS ? (
                    <p className="priceLess-text">
                      {lg.command.total.textLongUpdate}
                      <Button
                        variant="primary"
                        width="140px"
                        height="auto"
                        title={lg?.quote.create}
                        active
                        theme="dark"
                        onClick={() => {
                          history.push(QUOTE);
                        }}
                        className="redirectedQuote"
                      />
                    </p>
                  ) : (
                    <>
                      <p className={classes.mb10}>
                        {lg.command.total.textLong}
                      </p>
                      <p className={classes.mb20}>{lg.command.total.booking}</p>
                      <p
                        className={classes.mb10}
                        style={{ fontWeight: 'bold' }}
                      >
                        {lg.command.total.confirmBookingTitle}
                      </p>
                      <p className={classes.mb10}>
                        {lg.command.total.confirmBooking}
                      </p>
                      {planningStoreSet !== Networks.IDG && (
                        <p>{lg.command.total.commissionPriceless}</p>
                      )}
                    </>
                  )}
                </span>
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      {currentBooking?.quotes[0].quoteType !== QuoteType.PRICELESS ? (
        <div className={classes.total}>
          <div className="bloc">
            <div className="in">
              <span className="totalCommand">
                <img
                  className="img"
                  alt="calculatorIcon"
                  src={calculatorIcon}
                />
                <span className="span">{lg.command.total.title}</span>
              </span>

              <span className="totalHorsRemise">
                <span className="left">{lg.command.total.totalHorsRemise}</span>
                <span className="right">
                  {formatPrice(price.totalDeductionLess || 0)}
                </span>
              </span>

              <span className="totalAvecRemiseCoupon">
                <span className="left">{lg.quote.totalDiscount}</span>
                <span className="right">
                  {formatPrice(price.discountTotal || 0)}
                </span>
              </span>

              {!['IDG', 'GARAGE-OWNER'].includes(currentBooking.networkId) && (
                <span className="totalAvecRemiseCoupon">
                  <span className="left">
                    {lg.command.total.totalCommission}
                  </span>
                  <span className="right">
                    {formatPrice(price.commission || 0)}
                  </span>
                </span>
              )}

              {hasCoupon && (
                <span className="coupon">
                  <span className="left">
                    {lg.command.total.couponCode} HT {couponCodes}
                  </span>
                  <span className="right">
                    {formatPrice(price.coupon?.amount || 0)}
                  </span>
                </span>
              )}

              <span className="montantFacturer">
                <span className="left">{lg.command.total.totalHT}</span>
                <span className="right">{formatPrice(price.total || 0)}</span>
              </span>

              <span className="tva">
                <span className="left">{lg.command.total.tva}</span>
                <span className="right">
                  {formatPrice(price.vatValue || 0)}
                </span>
              </span>

              <span className="montantFacturer">
                <span className="left bold">
                  {lg.command.total.montantFacturer}
                </span>
                <span className="right bold">
                  {formatPrice(price.totalWithTax || 0)}
                </span>
              </span>

              {isWithForkPrice() && (
                <span className="fourchette">
                  <span className="left">{lg.command.total.fourchette}</span>
                  <span className="right">
                    {formatPrice(price.totalMaxWithTax || 0)}
                  </span>
                </span>
              )}
              <div className="price-advice">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="price-advice--icon w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                  />
                </svg>
                <p>{lg.command.priceAdvice}</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {price.total === 0 && (
            <div className={classes.total}>
              <div className="bloc">
                <div className="in">
                  <span className="totalCommand">
                    <img
                      className="calculatorIcon"
                      alt="calculatorIcon"
                      src={calculatorIcon}
                    />
                    <span className="span">{lg.command.total.title}</span>
                  </span>
                  <span className={classes.span_total}>
                    <span className="textLong">
                      {lg.command.total.textLong}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Total;

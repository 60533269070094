/* eslint-disable no-underscore-dangle */
import Ajv from 'ajv';
import Requestable from './Requestable';
import bookingResponse from './response/schema/booking-response.jsc';
import bookingsResponse from './response/schema/bookings-response.jsc';
import bookingHistoryResponse from './response/schema/booking-history-response.jsc';
import {
  normalizeBooking,
  normalizeBookingHistory
} from '../normalizer/bookingNormalizer';
import {
  Booking,
  BookingHistory,
  Quote
} from '../../redux/stores/planning/types';

export default class BookingRequest extends Requestable {
  getBookingById(bookingId: string): Promise<Booking> {
    return this.request(
      'GET',
      `${process.env.REACT_APP_API_PLANNING_URL}/bookings/${bookingId}`,
      new Ajv().compile(bookingResponse)
    )
      .then(res => normalizeBooking(res?.data))
      .then(booking =>
        this.getBookingHistoriesById(bookingId)
          .then((bookingHistories: BookingHistory[]) => ({
            ...booking,
            bookingHistories
          }))
          .catch(() => booking)
      );
  }

  updateBookingQuoteWithId(bookingId: string, quote: Quote): Promise<any> {
    return this.request(
      'POST',
      `${process.env.REACT_APP_API_PLANNING_URL}/bookings/${bookingId}`,
      new Ajv().compile(bookingResponse),
      {},
      { ...quote }
    );
  }

  getBookingHistoriesById(bookingId: string): Promise<BookingHistory[]> {
    return this.request(
      'GET',
      `${process.env.REACT_APP_API_PLANNING_URL}/bookings/history/${bookingId}`,
      new Ajv().compile(bookingHistoryResponse)
    ).then(res =>
      (res?.data || []).map((history: BookingHistory) =>
        normalizeBookingHistory(history)
      )
    );
  }

  getBookings(
    garageId: string,
    startDate: string,
    endDate: string | undefined,
    page: number = 1
  ): Promise<Booking[]> {
    return this.request(
      'GET',
      `${process.env.REACT_APP_API_PLANNING_URL}/bookings`,
      new Ajv().compile(bookingsResponse),
      {
        garageId,
        startDate,
        endDate,
        paymentStatus: 'ACCEPTED',
        page
      }
    ).then(async res => {
      const bookings = (
        res.data._embedded?.orders || []
      ).map((booking: Booking) => normalizeBooking(booking));
      if (res.data._page_count > page) {
        const moreBookings = await this.getBookings(
          garageId,
          startDate,
          endDate,
          page + 1
        );
        return [...bookings, ...moreBookings];
      }
      return bookings;
    });
  }
}

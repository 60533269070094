const tarifsActions = {};

tarifsActions.setList = (dispath, data) => {
  dispath({
    type: 'SET_LIST',
    payload: data
  });
};

export default tarifsActions;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '../../../../../redux/types';
import pieceIcon from '../../../../../assets/style-elements/piece-icon.png';
import useStyles from '../style';
import {
  Booking,
  PartStatus,
} from '../../../../../redux/stores/planning/types';
import {
  setBooking,
  updateBookingPartsOrdered,
} from '../../../../../redux/stores/planning/actions';
import {
  Garage,
  SeverityMessage,
} from '../../../../../redux/stores/global/types';
import {
  partsOrderedEvent,
  partsReceivedEvent,
} from '../../../../../lib/events/booking';
import { setNotification } from '../../../../../redux/stores/global/actions';

interface PartsProps {
  currentBooking: Booking;
  currentGarage: Garage;
}

const Parts = ({ currentBooking, currentGarage }: PartsProps) => {
  const lg = useSelector((state: Store) => state.languageReducer.language);

  const classes = useStyles();

  const dispatch = useDispatch();

  const partsEvent = (partsChoice: PartStatus, bookingId: string) => {
    const eventProps = {
      garageId: currentGarage.id,
      garageName: currentGarage.companyName,
      booking_id: bookingId,
      part_ids: currentBooking.quotes.flatMap((q) =>
        q.operations
          .filter((o) => o.operationType === 'parts')
          .map<string>((o: any) => o.oemPartsId)
      ),
      parts_price: currentBooking.quotes.reduce(
        (price, q) =>
          q.operations
            .filter((o) => o.operationType === 'parts')
            .reduce((total, o) => total + o.total, 0),
        0
      ),
    };

    if (partsChoice === PartStatus.ORDERED) {
      partsOrderedEvent(eventProps);
    }

    if (partsChoice === PartStatus.RECEIVED) {
      partsReceivedEvent(eventProps);
    }
  };

  const updateBooking = () => {
    dispatch(setBooking(currentBooking));
    dispatch(
      setNotification({
        severity: SeverityMessage.ERROR,
        message: lg.error.server,
      })
    );
  };

  const setPartsOrdered = (partsChoice: PartStatus): void => {
    const { bookingId } = currentBooking;
    if (bookingId) {
      dispatch(
        updateBookingPartsOrdered(
          currentBooking,
          partsChoice,
          () => {
            partsEvent(partsChoice, bookingId);
          },
          () => updateBooking,
          () => {}
        )
      );
    }
  };

  return (
    <div className={classes.donateItemWrapper}>
      <img className="leftIcon" src={pieceIcon} alt="piece icon" />
      <div className="donateList">
        <div className="donateItem">
          <p className="first">{lg.command.partsOrdered.title}</p>
        </div>
        <div className="dropdown">
          <select
            className="inputDropdown"
            value={currentBooking?.partsOrdered}
            onChange={(e) => setPartsOrdered(e.target.value as PartStatus)}
          >
            <option value={PartStatus.NOT_ORDERED} className="optionList">
              {lg.command.partsOrdered.notOrdered}
            </option>
            <option value={PartStatus.ORDERED} className="optionList">
              {lg.command.partsOrdered.ordered}
            </option>
            <option value={PartStatus.RECEIVED} className="optionList">
              {lg.command.partsOrdered.deliveredOrdered}
            </option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default Parts;

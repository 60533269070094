import React from 'react';

const QuoteIcon = (
  <svg
    width="26"
    height="25"
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.6919 6.79407C21.6919 7.8149 21.3729 8.62838 20.7349 9.2345C20.1014 9.83606 19.231 10.1368 18.1235 10.1368C17.1574 10.1368 16.3826 9.92037 15.7993 9.48743C15.2205 9.04993 14.8628 8.41874 14.7261 7.59387L16.647 7.43665C16.7472 7.8468 16.925 8.1453 17.1802 8.33215C17.4354 8.519 17.7567 8.61243 18.144 8.61243C18.6226 8.61243 19.0031 8.45976 19.2856 8.15442C19.5728 7.84908 19.7163 7.4093 19.7163 6.83508C19.7163 6.32922 19.5819 5.9259 19.313 5.62512C19.0441 5.31978 18.6681 5.16711 18.1851 5.16711C17.9207 5.16711 17.6792 5.21952 17.4604 5.32434C17.2417 5.4246 17.048 5.57955 16.8794 5.78918H15.0063L15.3413 0.368286H21.1313V1.797H17.0845L16.9272 4.23059C17.3921 3.82043 17.9731 3.61536 18.6704 3.61536C19.2811 3.61536 19.812 3.74524 20.2632 4.005C20.7189 4.26021 21.0698 4.6248 21.3159 5.09875C21.5666 5.57271 21.6919 6.13782 21.6919 6.79407Z"
      fill="#EF7050"
    />
    <path
      d="M25.0278 1.70129C25.0278 2.25273 24.9709 2.73124 24.8569 3.13684C24.7476 3.54244 24.5562 3.93665 24.2827 4.31946H23.0386C23.6356 3.54928 23.9341 2.82467 23.9341 2.14563H23.0659V0.368286H25.0278V1.70129Z"
      fill="#EF7050"
    />
    <path d="M10 5.66064H12V7.66064H10V5.66064Z" fill="#ADABAB" />
    <path d="M10 20.2468H12V22.2468H10V20.2468Z" fill="#ADABAB" />
    <path d="M2.61133 13H4.61133V15H2.61133V13Z" fill="#ADABAB" />
    <path d="M17.2954 13H19.2954V15H17.2954V13Z" fill="#ADABAB" />
    <path
      d="M9.7221 15.1773C10.4279 15.8275 11.5272 15.7825 12.1775 15.0767L14.311 10.1962L9.62145 12.7219C8.97121 13.4278 9.01627 14.5271 9.7221 15.1773Z"
      fill="#ADABAB"
    />
    <path
      d="M13.5745 4.33465C12.7531 4.11642 11.8901 4.00012 11 4.00012C5.47715 4.00012 1 8.47727 1 14.0001C1 19.523 5.47715 24.0001 11 24.0001C16.5228 24.0001 21 19.523 21 14.0001C21 13.199 20.9058 12.4199 20.7279 11.6733"
      stroke="#ADABAB"
      strokeWidth="1.5"
    />
  </svg>
);

export default QuoteIcon;
import makeStyles from '@material-ui/core/styles/makeStyles';
import { filter, matches } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Button from '../../../../../assets/generic-components/Button';
import colors from '../../../../../assets/style-colors';

import {
  deleteSpecialDay,
  getSpecialDays,
  saveSpecialDays,
} from '../../../../../redux/stores/calendar/actions';

import { Availability } from '../../../../../redux/stores/calendar/types';

import {
  getBookings,
  updateQuoteStatus,
} from '../../../../../redux/stores/planning/actions';
import {
  Booking,
  QuoteStatus,
} from '../../../../../redux/stores/planning/types';

import { Store } from '../../../../../redux/types';
import WeekDay from './weekDay';

interface WeekViewProps {
  pathDayModif: any;
}

const WeekView = ({ pathDayModif }: WeekViewProps) => {
  const {
    languageReducer,
    calendarStore: { calendar, currentWeekAvailabilities },

    planningStore: { currentDay },

    globalReducer: { currentGarage },
  } = useSelector((state: Store) => state);

  if (!currentGarage) return null;

  const lg = languageReducer.language;
  const dispatch = useDispatch();
  const prettyDate = (index: number) =>
    moment(currentDay)
      .day(index + 1)
      .format('DD/MM/YYYY');

  // STATES:
  const planning =
    useSelector((state: Store) => state.planningStore.bookings, shallowEqual) ||
    [];
  const [show, setShow] = useState(false);
  const [indexToClose, setIndexToClose] = useState(0);
  const [closedDay, setClosedDay] = useState(false);
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setHeight(window.innerHeight);
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
  }, []);

  const getPlanning = () => {
    const startOfWeek = moment(currentDay).startOf('week').format('YYYY-MM-DD');

    const endOfWeek = moment(currentDay).endOf('week').format('YYYY-MM-DD');

    if (currentGarage) {
      dispatch(getBookings(currentGarage.id, startOfWeek, endOfWeek));
      dispatch(getSpecialDays(currentGarage.id, startOfWeek, endOfWeek));
    }
  };

  useEffect(() => {
    getPlanning();
  }, [currentDay]);

  const handleClose = () => setShow(false);
  const handleShow = (dayIndexToClose: number, isClosed: boolean) => {
    setIndexToClose(dayIndexToClose);
    setClosedDay(isClosed);
    setShow(true);
  };

  const removeSpecialDayIfClosed = (
    specialDays: Availability[],
    day: moment.Moment
  ): void => {
    if (
      closedDay &&
      specialDays.length > 0 &&
      specialDays[0].availability.slots === 0
    ) {
      dispatch(
        deleteSpecialDay(
          currentGarage.id,
          day.format('YYYY-MM-DD'),
          () => {
            setShow(false);
          },
          () => {}
        )
      );
    }
  };

  const createSpecialDay = (day: moment.Moment) => {
    const regularWeekDayHours =
      calendar?.regularWeek[indexToClose].openingHours || [];
    const slots = closedDay ? 5 : 0;
    const newAvailability: Availability = {
      day: day.format('YYYY-MM-DD'),
      availability: {
        openingHours:
          regularWeekDayHours.length > 0
            ? regularWeekDayHours
            : ['08:00-17:00'],
        slots,
      },
    };

    dispatch(
      saveSpecialDays(
        currentGarage.id,
        [newAvailability],
        () => {
          setShow(false);
        },
        () => {}
      )
    );
  };

  const createBookingsProblems = (bookings: Booking[]) => {
    if (closedDay) return;

    // TODO gérer le cas d'erreur
    bookings.forEach((booking) => {
      booking.quotes.forEach((quote) => {
        if (
          booking.bookingId &&
          ['VALIDATED', 'CONFIRMED'].includes(quote.status)
        ) {
          dispatch(
            updateQuoteStatus(
              booking.bookingId,
              quote.quoteId,
              QuoteStatus.REPORTED,
              'DAY_CLOSED',
              () => getPlanning(),
              () => {}
            )
          );
        }
      });
    });
  };

  const closeOpenDay = () => {
    if (!currentGarage) return;

    const day = moment(currentDay).day(indexToClose + 1);

    const specialDays = filter(
      currentWeekAvailabilities,
      matches({ day: day.format('YYYY-MM-DD') })
    );

    removeSpecialDayIfClosed(specialDays, day);
    createSpecialDay(day);
    createBookingsProblems(planning[indexToClose]);
  };

  // STYLES:
  const useStyles = makeStyles(() => ({
    root: {
      position: 'relative',
      height: `calc(${height}px - 200px)`,
      width: '100%',
      padding: '0 15px',
      overflowX: width < 1200 ? 'scroll' : 'hidden',
      overflow: 'scroll',
      '&::-webkit-scrollbar': {
        width: '4px',
        height: '4px',
        borderRadius: '2px',
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        width: '4px',
        height: '4px',
        margin: '2px',
        background: colors.lightGreyBlue,
      },
    },
    wrapWeek: {
      flexWrap: 'nowrap',
    },
  }));
  const classes = useStyles();

  const workingHours: number[] =
    calendar?.regularWeek.map((r) =>
      r.openingHours.length > 0 ? parseInt(r.slots.toString()) : 0
    ) || [];

  const startOfWeek = moment(currentDay).startOf('week').format('YYYY-MM-DD');

  return (
    <div className={`${classes.root} noHeight`}>
      <Row className={`wrapper-row-day ${classes.wrapWeek}`}>
        {planning.map((bookings: Booking[], index: any) => (
          <WeekDay
            key={index}
            bookings={bookings}
            dayIndex={index}
            startOfWeek={startOfWeek}
            workingHours={workingHours}
            handleShowModal={handleShow}
            pathDayModif={pathDayModif}
          />
        ))}
      </Row>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {!closedDay
              ? `${lg.planning.modification.modalDayClose}${prettyDate(
                  indexToClose
                )}?`
              : `${lg.planning.modification.modalDayReopen}${prettyDate(
                  indexToClose
                )}?`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button theme="dark" onClick={handleClose}>
            {lg.planning.modification.cancel}
          </Button>
          <Button theme="light" onClick={() => closeOpenDay()}>
            {lg.planning.modification.confirm}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default WeekView;

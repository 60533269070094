import React, { InputHTMLAttributes } from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import colors from '../style-colors';

interface InputMutipleSelectProps
  extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  datas: Array<any>;
  reference?: any;
  selected?: string;
  disabled?: boolean;
  dataIsCities?: boolean;
}

const InputMutipleSelect = ({
  className,
  onChange,
  children,
  disabled,
  label,
  name,
  reference,
  datas,
  selected,
  dataIsCities,
}: InputMutipleSelectProps) => (
  <div className={className}>
    <Form.Group>
      <Form.Label className="label">{label}</Form.Label>
      <Form.Control
        name={name}
        ref={reference}
        onChange={onChange}
        as="select"
        disabled={disabled}
        defaultValue={selected}
      >
        {datas &&
          datas.map((item, idx) =>
            dataIsCities ? (
              <option
                key={idx}
                disabled={item.disabled}
                value={item.name}
                selected={item.name.toUpperCase() === selected}
              >
                {item.name}
              </option>
            ) : (
              <option
                key={idx}
                disabled={item.disabled}
                value={item.value}
                selected={item.value === selected}
              >
                {item.label}
              </option>
            )
          )}
      </Form.Control>
    </Form.Group>
    <p className="labelError">{children}</p>
  </div>
);

InputMutipleSelect.defaultProps = {
  reference: null,
  label: '',
  selected: '',
  disabled: false,
  dataIsCities: false,
};

export default styled(InputMutipleSelect)`
  .label {
    color: ${colors.slateGrey};

    line-height: 1.17;
    font-size: 12px;
    margin-bottom: 10px;
  }
  .labelError {
    color: red;
    font-size: 10px;
    margin: 5px;
  }

  .form-control {
    border-width: 0;
    width: 100%;
    font-size: 13px;
    font-weight: 500;
    height: 40px;
    border-radius: 8px;
    background-color: ${colors.white};
    padding: 10px;
    color: ${colors.dark};
  }
  .form-control:disabled {
    border: 1px solid ${colors.barGrey};
    background-color: ${colors.veryPaleGrey};
  }
`;

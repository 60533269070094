import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import colors from '../../assets/style-colors';
import { BILLING } from '../../router/routes';
import Button from '../../assets/generic-components/Button';
import { Store } from '../../redux/types';
import * as ROUTES from '../../router/routes';

const AccountDisabledPage = () => {
  const lang = useSelector((state: Store) => state.languageReducer.language);
  const useStyles = makeStyles(() => ({
    root: {
      display: 'block',
      position: 'absolute',
      width: '100%',
      height: '100%'
    },
    popup: {
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      zIndex: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      '&:before': {
        width: '100%',
        left: '0',
        bottom: '0',
        height: '100%',
        content: '""',
        position: 'absolute',
        opacity: '0.2',
        backgroundColor: colors.black
      }
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '500px',
      height: 'auto',
      padding: '30px 20px',
      backgroundColor: colors.white,
      zIndex: 11
    },
    text: {
      lineHeight: '23px',
      marginBottom: '20px'
    },
    button: {
      '& button': {
        marginRight: 0
      }
    }
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.popup}>
        <div className={classes.container}>
          <div className={classes.text}>
            <p>
              {lang.accountDisabledPage.message1}&nbsp;
              <a href="mailto:contact@idgarages.com" target="_blank">
                {lang.accountDisabledPage.mail}
              </a>
              &nbsp;{lang.accountDisabledPage.message2}
            </p>
            <p>
              {lang.accountDisabledPage.message3}&nbsp;
              <Link to={ROUTES.BILLING}>
                {lang.accountDisabledPage.billingLink}
              </Link>
              &nbsp;{lang.accountDisabledPage.message4}
            </p>
          </div>
          <Link className={classes.button} to={BILLING}>
            <Button theme="dark" title={lang.accountDisabledPage.button} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AccountDisabledPage;

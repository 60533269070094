import React, { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import {
  getVehicleByPlateNumber,
  setRegistrationNumber,
} from '../../../../redux/stores/quotes/actions';
import InputLabel from '../../../../assets/generic-components/InputLabel';

interface ImmatriculationInputProps {
  label: string;
  reference?: any;
  errorLabel?: string | null;
  invalidRegistrationLabel: string;
  name: string;
  handleIsValidPlate?: (isValid: boolean) => any;
  defaultValue?: string;
}

const useStyles = makeStyles(() => ({
  root: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
}));

/**
 * Display text field allows you to type your vehicle identification number
 */
const ImmatriculationInput: React.FC<ImmatriculationInputProps> = ({
  label,
  name,
  reference,
  errorLabel,
  invalidRegistrationLabel,
  handleIsValidPlate,
  defaultValue,
}) => {
  const [value, setValue] = useState(defaultValue);
  const [isValidPlate, setIsValidPlate] = useState(false);
  const dispatch = useDispatch();

  const handleInputChanged = (element: ChangeEvent<HTMLInputElement>): void => {
    setValue(element.target.value);
    if (element.target.value.length >= 7) {
      dispatch(
        getVehicleByPlateNumber(
          element.target.value,
          () => {
            dispatch(setRegistrationNumber(element.target.value));
            setIsValidPlate(true);
            handleIsValidPlate?.(true);
          },
          () => {
            setIsValidPlate(false);
            handleIsValidPlate?.(false);
          }
        )
      );
    } else {
      handleIsValidPlate?.(false);
    }
  };
  const styles = useStyles();
  return (
    <InputLabel
      width="100%"
      name={name}
      label={label}
      reference={reference}
      value={value}
      onChange={handleInputChanged}
      className={styles.root}
    >
      {errorLabel}
      {value !== '' && !isValidPlate && invalidRegistrationLabel}
    </InputLabel>
  );
};

ImmatriculationInput.defaultProps = {
  reference: undefined,
  errorLabel: null,
  handleIsValidPlate: () => {},
  defaultValue: '',
};

export default ImmatriculationInput;

import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { connect } from 'react-redux';
import Language from './language';
import logo from '../../assets/images/logo-header.svg'

const mapStateToProps = state => ({
  ...state
});

const ConnexionHeader = () => {
  const useStyles = makeStyles(() => ({
    root: {
    
      display: 'flex',

      background: 'white',
      height: '70px',
    
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0px 40px',
      borderBottom: '1px solid #EAEBF1',
    },



  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
        <img src={logo} alt="myGarage" />
      <Language />
    </div>
  );
};


export default connect(mapStateToProps)(ConnexionHeader);

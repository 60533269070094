import _get from 'lodash/get';
import { Model, Vehicle, Version } from '../redux/stores/quotes/types';
import HttpService from './http-service';
import {
  CountryCodes,
  MessageNotification,
  Networks,
  SeverityMessage,
} from '../redux/stores/global/types';

export const errorNotificationOccurred = (
  message: string
): MessageNotification => ({
  severity: SeverityMessage.ERROR,
  message,
});

const getVehicleVersionsByModel = async (
  makeId: string,
  modelId: string
): Promise<Version[] | null> =>
  HttpService.getClient()
    .get(
      `${process.env.REACT_APP_API_CAR_IDENTIFICATION_URL}/models/${makeId}_${modelId}/versions`,
      {
        headers: {
          'api-key': process.env.REACT_APP_API_CAR_IDENTIFICATION_KEY,
          'x-network-id': Networks.IDG,
          countryCode: CountryCodes.FR,
        },
      }
    )
    .then((res) => res.data);

export const getVehicleModelsByMake = async (
  makeId: string
): Promise<Version[] | null> =>
  HttpService.getClient()
    .get(
      `${process.env.REACT_APP_API_CAR_IDENTIFICATION_URL}/makes/${makeId}`,
      {
        headers: {
          'api-key': process.env.REACT_APP_API_CAR_IDENTIFICATION_KEY,
          'x-network-id': Networks.IDG,
          countryCode: CountryCodes.FR,
        },
      }
    )
    .then((res) => {
      const versionsByModel: Iterable<Promise<Version[]>> = _get(
        res,
        'data',
        []
      ).map(
        (model: Model) =>
          new Promise((resolve) => {
            getVehicleVersionsByModel(model.make.id, model.id)
              .then((versions) => resolve(versions))
              .catch(() => resolve([]));
          })
      );
      return Promise.all(versionsByModel).then((versionsByModels) => {
        const versions: Array<Version> = [];
        (versionsByModels || []).forEach((versionForModel) => {
          (versionForModel || []).forEach((singleVersion) => {
            versions.push(singleVersion);
          });
        });
        return versions;
      });
    });

export const getIdByVersions = (
  makeStringPath: string
): Promise<Vehicle[] | null> =>
  HttpService.getClient()
    .get(
      `${process.env.REACT_APP_API_CAR_IDENTIFICATION_URL}/versions/${makeStringPath}`,
      {
        headers: {
          'api-key': process.env.REACT_APP_API_CAR_IDENTIFICATION_KEY,
          'x-network-id': Networks.IDG,
          countryCode: CountryCodes.FR,
        },
      }
    )
    .then((res) => res.data);

export const getVehicleDetailsById = (vehicleId: string): Promise<Vehicle> =>
  HttpService.getClient()
    .get(
      `${process.env.REACT_APP_API_CAR_IDENTIFICATION_URL}/vehicles/${vehicleId}`,
      {
        headers: {
          'api-key': process.env.REACT_APP_API_CAR_IDENTIFICATION_KEY,
          'x-network-id': Networks.IDG,
          countryCode: CountryCodes.FR,
        },
      }
    )
    .then((res) => res.data);

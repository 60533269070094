import last from 'lodash/last';
import { RegularWeek } from '../../../../redux/stores/calendar/types';
import { hoursRange2Array } from '../../../../utils/calendar';

export const standardDay: RegularWeek = {
  openingHours: ['08:00-18:00'],
  slots: 1,
};

/**
 * Update a day in a collection of a regular week days.
 * @param dayIndex
 */
export const updateDay =
  (dayIndex: number) =>
  (
    day: RegularWeek,
    callbackFunc: (days: RegularWeek[]) => any,
    actualWeek: RegularWeek[]
  ) => {
    let newDay = day;
    if (day.openingHours.length === 0) {
      newDay = {
        openingHours: ['8:00-17:00'],
        slots: day.slots,
      };
    }
    callbackFunc([
      ...actualWeek.slice(0, dayIndex),
      newDay,
      ...actualWeek.slice(dayIndex + 1),
    ]);
  };

/**
 * Check if all days of a garage has a lunch break.
 * @param regularWeek
 */
export const isLunchBreaksConcernAllDays = (regularWeek: RegularWeek[]) => {
  const daysWithoutLunchBreaks = regularWeek.filter(
    (regularDay) => regularDay.openingHours.length <= 1 && regularDay.slots > 0
  );

  return daysWithoutLunchBreaks.length > 0;
};

/**
 * Toggle all days with lunch break or not.
 * @param days
 * @param updateFunc
 */
export const toggleLunchBreaks = (
  days: RegularWeek[],
  updateFunc: (days: RegularWeek[]) => any
) => {
  const isLunchBreaksEnabled = isLunchBreaksConcernAllDays(days);
  updateFunc(
    days.map((day) => {
      const hoursRange = hoursRange2Array(day.openingHours);
      return {
        ...day,
        openingHours: isLunchBreaksEnabled
          ? [`${hoursRange[0]}-12:00`, `14:00-${last(hoursRange)}`]
          : [`${hoursRange[0]}-${last(hoursRange)}`],
      };
    })
  );
};

import {
  ActionTypes,
  BILLINGS_LOADING,
  SET_BILL,
  SET_BILLINGS
} from './actions';
import { BillingStore } from './type';

const initialState: BillingStore = {
  currentBillings: []
};

// Ne retourne pas d'erreur si le tes n'exite pas
const BillingsReducer = (
  state: BillingStore = initialState,
  action: ActionTypes
) => {
  switch (action.type) {
    case SET_BILLINGS:
      return { ...state, currentBillings: action.payload };
    case SET_BILL:
      return { ...state, currentBill: action.payload };
    case BILLINGS_LOADING:
      return { ...state, billingsLoading: action.payload };
    default:
      return state;
  }
};
export default BillingsReducer;

import Ajv from 'ajv';
import Requestable from './Requestable';
import { AutossimoLogin, Garage } from '../../redux/stores/global/types';
import {
  normalizeAutossimo,
  normalizeGarage
} from '../normalizer/garageNormalizer';
import garageResponse from './response/schema/garage-response.jsc';
import garagesResponse from './response/schema/garages-response.jsc';
import autossimoResponse from './response/schema/autossimo-response.jsc';

class GarageRequest extends Requestable {
  getGarageById(garageId: string): Promise<Garage | null> {
    return this.request(
      'GET',
      `${process.env.REACT_APP_API_GARAGES_URL}/garages/${garageId}`,

      new Ajv().compile(garageResponse),
      {},
      {},
      {
        ts: new Date().getTime()
      }
    )
      .then(res => normalizeGarage(res?.data))
      .catch(() => null);
  }

  getAutossimoLoginByGarageId(
    garageId: string
  ): Promise<AutossimoLogin | null> {
    return this.request(
      'GET',
      `${process.env.REACT_APP_API_GARAGES_URL}/garages/${garageId}/login-autossimo`,
      new Ajv().compile(autossimoResponse)
    )
      .then(res => normalizeAutossimo(res?.data))
      .catch(() => null);
  }

  getGaragesByName(companyName: string): Promise<Garage[]> {
    return this.request(
      'GET',
      `${process.env.REACT_APP_API_GARAGES_URL}/garages`,
      new Ajv().compile(garagesResponse),
      { companyName, state: 'ACTIVE' }
    ).then(res => res?.data.map((garage: any) => normalizeGarage(garage)));
  }
}

export default GarageRequest;

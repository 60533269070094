import makeStyles from '@material-ui/core/styles/makeStyles';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import colors from '../../../../assets/style-colors';
import { Store } from '../../../../redux/types';
import arrow from '../../../../assets/style-elements/arrow.svg';
// STYLES:
const useStyles = makeStyles(() => ({
  root: {
    width: '257px',
    height: 'auto',
    fontSize: '11px',
    fontWeight: 500,
    background: colors.veryPaleGrey,
    borderRadius: '9px',
    padding: '10px',
    boxShadow: '0 2px 18px 0 rgb(214 214 214 / 50%)',
  },
  header: {
    width: '100%',
    textAlign: 'center',
    height: '30px',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  text: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: colors.dark,
    whiteSpace: 'nowrap',
  },
  arrow: {
    background: 'transparent',
    textAlign: 'left',
    border: 'none',
    height: '100%',
    color: colors.primary,

    '&:hover': {
      background: colors.paleGrey,
    },
  },

  weekNumber: {
    display: 'block',
    fontSize: '9px',
    fontWeight: 'normal',
    color: colors.darkGray,
    marginTop: '3px',
  },

  arrowR: {
    background: 'transparent',
    textAlign: 'right',
    border: 'none',
    height: '100%',
    width: '50px',
    color: colors.lightGreyBlue,
    '&:hover': {
      background: colors.paleGrey,
    },
  },
  dayNames: {
    color: colors.darkGray,
    textAlign: 'center',
    padding: '10px 0 10px 0 ',
    fontWeight: 500,
    width: '22px',
  },
  calendar: {
    borderSpacing: 0,
    width: '100%',
  },
  dayFull: {
    width: '30px',
    height: '30px',
    textAlign: 'center',
  },
  dayButton: {
    background: 'transparent',
    padding: '0',
    width: '22px',
    height: '22px',
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '5px',
    lineHeight: 1.5,

    '&:hover': {
      outline: 'none',
      background: colors.black,
      color: `${colors.primary} !important`,
    },
    '&:focus': {
      outline: 'none',
    },
  },
  dayEmpty: {
    color: 'silver',
    textAlign: 'center',
    width: '22px',
    height: '22px',
  },
  points: {
    display: 'flex',
    justifyContent: 'center',
  },
  point: {
    width: '3px',
    height: '3px',
    borderRadius: '50%',
    margin: '0 1px 2px 1px',
  },

  arrowLeft: {
    textAlign: 'left',
  },
  arrowRight: {
    textAlign: 'right',
  },
  rightArrow: {
    transform: 'rotate(180deg)',
  },
}));

interface MonthProps {
  onSelect: (date: Moment) => void;
  initialDay?: string;
}

const Month: React.FC<MonthProps> = ({ onSelect, initialDay }) => {
  // CONSTANTS:
  const {
    languageReducer,
    planningStore: { currentDay },
  } = useSelector((state: Store) => state);
  const lg = languageReducer.language;
  const classes = useStyles();

  const date = moment(initialDay || currentDay);

  const currentMonth = date.month();
  const currentYear = date.year();

  // STATES:
  const [month, setMonth] = useState(currentMonth + 1);
  const [year, setYear] = useState(currentYear);
  const [daysinmonth, setDaysinmonth] = useState<JSX.Element[]>([]);

  const weekdayshortname = lg.planning.weekdaysShort.map((day, i) => (
    <th key={`weekdayshortname${i}`} className={classes.dayNames}>
      {day}
    </th>
  ));

  const rows: Array<JSX.Element[]> = [];

  let cells: JSX.Element[] = [];

  const getBackground = (day: Moment) => {
    if (day.isSame(moment(), 'day')) {
      return colors.dark;
    }
    // TODO Check if day closed

    if (day.day() === 0) {
      return colors.veryLightBlue;
    }

    return colors.transparent;
  };

  const buildCalendar = (dateObject: Moment) => {
    const firstDayIndex = dateObject.startOf('month').format('d');
    const firstDayOfMonth =
      firstDayIndex === '0' ? +firstDayIndex + 6 : +firstDayIndex - 1;
    const daysInMonth = [];

    for (let i = 0; i < firstDayOfMonth; i++) {
      if (firstDayOfMonth > 0) {
        const lastMonth =
          moment(moment(`${year} ${month}`, 'YYYY MM'))
            .subtract(1, 'months')
            .daysInMonth() +
          1 -
          firstDayOfMonth +
          i;
        daysInMonth.push(
          <td key={`daysInMonthLAst${i}`} className={classes.dayEmpty}>
            {lastMonth}
          </td>
        );
      }
    }

    for (let d = 1; d <= dateObject.daysInMonth(); d++) {
      const jour = moment(`${year} ${month} ${d}`, 'YYYY MM D');
      const day = jour.date();

      daysInMonth.push(
        <td key={`daysInMonth${d}`} className={classes.dayFull}>
          <button
            type="button"
            className={classes.dayButton}
            onClick={() => onSelect(jour)}
            style={{
              color:
                day === moment().date() &&
                month === moment().month() + 1 &&
                year === moment().year()
                  ? colors.white
                  : colors.dark,
              backgroundColor: getBackground(jour),
            }}
          >
            {day}
          </button>
        </td>
      );
    }
    const toFill =
      42 - Number(firstDayOfMonth) - dateObject.daysInMonth() < 7 ? 42 : 35;
    for (
      let i = Number(firstDayOfMonth) + dateObject.daysInMonth();
      i < toFill;
      i++
    ) {
      daysInMonth.push(
        <td key={`daysInMonthNext${i}`} className={classes.dayEmpty}>
          {i + 1 - Number(firstDayOfMonth) - dateObject.daysInMonth()}
        </td>
      );
    }

    daysInMonth.forEach((row, i) => {
      if (i % 7 !== 0) {
        cells.push(row); // if index not equal 7 that means not go to next week
      } else {
        rows.push(cells); // when reach next week we contain all td in last week to rows
        cells = []; // empty container
        cells.push(row); // in current loop we still push current row to new container
      }
      if (i === daysInMonth.length - 1) {
        // when end loop we add remain date
        rows.push(cells);
      }
    });

    setDaysinmonth(rows.map((d, i) => <tr key={`row${i}`}>{d}</tr>));
  };

  function handleMonthMinus() {
    if (month === 1) {
      setYear(year - 1);
      setMonth(12);
    } else {
      setMonth(month - 1);
    }
  }

  function handleMonthPlus() {
    if (month === 12) {
      setYear(year + 1);
      setMonth(1);
    } else {
      setMonth(month + 1);
    }
  }

  useEffect(() => {
    buildCalendar(moment(`${year} ${month}`, 'YYYY MM'));
    // updateState(moment().week());
  }, [month, year]);

  // HTML:

  return (
    <div className={`${classes.root} calendar`}>
      <div className={classes.header}>
        <button
          type="button"
          className={`${classes.arrow} ${classes.arrowLeft}`}
          onClick={handleMonthMinus}
        >
          <img src={arrow} alt="" />
        </button>
        <div className={classes.text}>
          {`${lg.planning.menuRight.months[month - 1]} ${year}`}
        </div>
        <button
          type="button"
          className={`${classes.arrow} ${classes.arrowRight}`}
          onClick={handleMonthPlus}
        >
          <img src={arrow} alt="" className={classes.rightArrow} />
        </button>
      </div>
      <table className={classes.calendar}>
        <thead>
          <tr>{weekdayshortname}</tr>
        </thead>
        <tbody>{daysinmonth}</tbody>
      </table>
    </div>
  );
};

Month.defaultProps = {
  initialDay: undefined,
};

export default Month;

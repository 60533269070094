import _get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Button from '../../../../assets/generic-components/Button';
import InputMutipleSelect from '../../../../assets/generic-components/InputMutipleSelect';
import TextaeraLink from '../../../../assets/generic-components/TextaeraLink';
import Title from '../../../../assets/generic-components/Title';
import colors from '../../../../assets/style-colors';
import {
  saveExtraContent,
  updateBrandForGarage,
} from '../../../../redux/stores/garages/actions';
import { GarageExtraContentLanguages } from '../../../../redux/stores/garages/type';
import {
  setGarage,
  setNotification,
} from '../../../../redux/stores/global/actions';
import { SeverityMessage } from '../../../../redux/stores/global/types';
import { Store } from '../../../../redux/types';
import Header from '../header';

const Boxs = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 60px;
`;

const Description: React.FC<{ className: string }> = ({ className }) => {
  // Redux
  const {
    languageReducer: { language: lg },
    globalReducer: { currentGarage },
    garagesReducer: { brands },
  } = useSelector((state: Store) => state);

  if (!currentGarage) return null;

  const dispatch = useDispatch();
  const [modalActive, setModalActive] = useState(false);
  const [modalContent, setModalContent] = useState(
    lg.help.account.aideEngagement
  );
  const [descriptions, setDescriptions] = useState({
    description: {},
    next_to: {},
    advantages: {},
    commitments: {},
  });

  type DescriptionsType = {
    description: string;
    next_to: string;
    advantages: string;
    commitments: string;
  };
  interface FormProps extends DescriptionsType {
    brand: string;
  }
  type DescriptionsKeys = keyof DescriptionsType;
  const { register, handleSubmit } = useForm<FormProps>();

  useEffect(() => {
    dispatch(setGarage(currentGarage.id));
  }, []);

  const onChangeText = (value: any, key: string) => {
    setDescriptions({
      ...descriptions,
      [key]: value,
    });
  };

  const saveIfExtraContentExist = (
    description: GarageExtraContentLanguages
  ) => {
    const { fr, nl } = description;
    if (fr) {
      dispatch(
        saveExtraContent(fr.lang, fr.field, fr.content, currentGarage?.id || '')
      );
    }
    if (nl) {
      dispatch(
        saveExtraContent(nl.lang, nl.field, nl.content, currentGarage?.id || '')
      );
    }
  };

  const onSave = (values: FormProps): void => {
    Object.keys(descriptions as DescriptionsType).forEach((fieldIndex) => {
      saveIfExtraContentExist(descriptions[fieldIndex as DescriptionsKeys]);
    });
    dispatch(
      updateBrandForGarage(
        currentGarage.id,
        values.brand,
        () => {
          dispatch(
            setNotification({
              severity: SeverityMessage.SUCCESS,
              message: lg.general.success,
            })
          );
          dispatch(setGarage(currentGarage.id));
        },
        (e) => {
          dispatch(
            setNotification({
              severity: SeverityMessage.ERROR,
              message: e.message,
            })
          );
        }
      )
    );
  };

  return (
    <>
      <Helmet>
        <title>Mon garage | Description</title>
      </Helmet>
      <Header show={false} />
      {modalActive && (
        <Modal show={modalActive}>
          <Modal.Header>
            <Modal.Title>SPEEDOTO</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>
              <span>{modalContent}</span>
            </p>
          </Modal.Body>

          <Modal.Footer>
            <Button
              onClick={() => setModalActive(false)}
              theme="dark"
              title={lg.rates.close}
            />
          </Modal.Footer>
        </Modal>
      )}

      <div className={className}>
        <>
          <Boxs className="custome-box pt-5">
            <Title title={lg.rates.description} />
            <TextaeraLink
              reference={register}
              name="description"
              field="description"
              contents={currentGarage.garageExtraContent}
              handleChange={(value) => onChangeText(value, 'description')}
            />
          </Boxs>
          <Boxs className="custome-box pt-30">
            <Title title={lg.rates.next} />
            <TextaeraLink
              reference={register}
              name="next_to"
              field="next_to"
              contents={currentGarage.garageExtraContent}
              handleChange={(value) => onChangeText(value, 'next_to')}
            />
          </Boxs>
          <Boxs className="custome-box pt-30">
            <Title title={lg.rates.learn} />
            <InputMutipleSelect
              name="brand"
              reference={register}
              datas={brands.map((b) => ({
                label: b.brand,
                value: b.slugBrand,
              }))}
              selected={_get(currentGarage, 'garageBrand.slugBrand')}
            />
          </Boxs>
          <Boxs className="custome-box pt-35">
            <div className="boxTitle">
              <Title title={lg.rates.strengths} />
              <p
                onClick={() => {
                  setModalActive(true);
                  setModalContent(lg.help.account.aidePointsForts);
                }}
                className="help"
              >
                {lg.rates.helps}
              </p>
            </div>
            <TextaeraLink
              reference={register}
              name="advantages"
              field="advantages"
              contents={currentGarage.garageExtraContent}
              handleChange={(value) => onChangeText(value, 'advantages')}
            />
          </Boxs>
          <Boxs className="custome-box pt-30">
            <div className="boxTitle">
              <Title title={lg.rates.commitments} />
              <p
                onClick={() => {
                  setModalActive(true);
                  setModalContent(lg.help.account.aideEngagement);
                }}
                className="help"
              >
                {lg.rates.helps}
              </p>
            </div>
            <TextaeraLink
              reference={register}
              name="commitments"
              field="commitments"
              contents={currentGarage.garageExtraContent}
              handleChange={(value) => onChangeText(value, 'commitments')}
            />
          </Boxs>
          <div className="pt-20 mb-100">
            <Button
              width="274px"
              theme="dark"
              title={lg.rates.save}
              onClick={handleSubmit(onSave)}
            />
          </div>
        </>
      </div>
    </>
  );
};

export default styled(Description)`
  display: flex;
  flex-direction: column;
  margin: 0 10vw;
  padding-top: 40px;
  width: 60%;

  .boxTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .help {
    padding-left: 15px;
    padding-bottom: 0;
    margin: 0 0 -3px 0;
    font-size: 13px;
    font-weight: 500;
    color: ${colors.primary};
    cursor: pointer;
    text-decoration: underline;
  }

  .custome-box {
    textarea {
      height: 135px;
    }

    h4 {
      margin-bottom: -3px;
    }
  }
  .pt-5 {
    padding-top: 5px !important;
  }
  .pt-20 {
    padding-top: 20px !important;
  }
  .pt-30 {
    padding-top: 30px !important;
  }
  .pt-35 {
    padding-top: 30px !important;
  }
  .mb-100 {
    margin-bottom: 100px;
  }
`;

import React, { useEffect } from 'react';
import moment from 'moment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { KeyboardArrowRight } from '@material-ui/icons';
import DayView from './day';
import {
  getBookings,
  setCurrentDay as setPlanningDay,
} from '../../../../../redux/stores/planning/actions';
import { Store } from '../../../../../redux/types';

interface DayWrapperProps {
  lateralMode?: boolean;
  setCurrentDay?: (date: string) => void;
  currentDay: string;
}

const DayWrapper: React.FC<DayWrapperProps> = ({
  lateralMode,
  setCurrentDay,
  currentDay,
}) => {
  const {
    globalReducer: { currentGarage },
  } = useSelector((state: Store) => state);
  const dispatch = useDispatch();

  const useStyle = makeStyles(() => ({
    container: {
      display: 'flex',
      position: 'relative',
      left: '-15%',
      right: '-15%',
      justifyContent: 'center',

      width: '130%',
    },
    previous: {
      opacity: '0.5',
      flex: '0.25 0 0',
      '& h2': {
        marginRight: '0',
        marginLeft: 'auto',
      },
    },
    current: {
      flex: '0.5 0 0',
    },
    next: {
      opacity: '0.5',
      flex: '0.25 0 0',
    },
    arrow: {
      position: 'absolute',
      top: '30%',
      fontSize: '65px',
      zIndex: 10,
      cursor: 'pointer',
    },
    arrowLeft: {
      left: '18%',
    },
    arrowRight: {
      right: '18%',
    },
  }));
  const classes = useStyle();

  const getPreviousDay = (day: string) => moment(day).subtract(1, 'day');

  const getNextDay = (day: string) => moment(day).add(1, 'day');

  useEffect(() => {
    if (currentGarage) {
      dispatch(
        getBookings(
          currentGarage.id,
          getPreviousDay(currentDay).format('YYYY-MM-DD'),
          getNextDay(currentDay).format('YYYY-MM-DD')
        )
      );
    }
  }, [currentDay]);

  return (
    <div className={`${classes.container} fullWidthPrint`}>
      <KeyboardArrowLeftIcon
        className={`${classes.arrow} ${classes.arrowLeft} printHidden`}
        onClick={() =>
          dispatch(setPlanningDay(getPreviousDay(currentDay).toISOString()))
        }
      />
      <div className={`${classes.previous} noprint`}>
        <DayView
          currentDay={getPreviousDay(currentDay).toISOString()}
          setCurrentDay={setCurrentDay}
          lateralMode={lateralMode}
          hiddenScrollBar
        />
      </div>
      <div className={`${classes.current} current`}>
        <DayView
          currentDay={currentDay}
          setCurrentDay={setCurrentDay}
          lateralMode={lateralMode}
          hiddenScrollBar
        />
      </div>
      <div className={`${classes.next} noprint`}>
        <DayView
          currentDay={getNextDay(currentDay).toISOString()}
          setCurrentDay={setCurrentDay}
          lateralMode={lateralMode}
          hiddenScrollBar
        />
      </div>
      <KeyboardArrowRight
        className={`${classes.arrow} ${classes.arrowRight} printHidden`}
        onClick={() =>
          dispatch(setPlanningDay(getNextDay(currentDay).toISOString()))
        }
      />
    </div>
  );
};

DayWrapper.defaultProps = {
  lateralMode: false,
  setCurrentDay: () => null,
};

export default DayWrapper;

import makeStyles from '@material-ui/core/styles/makeStyles';
import colors from '../../../../assets/style-colors';

const useStyles = makeStyles(() => ({
  root: {
    width: '60%',
    margin: '0 10vw',
    display: 'flex',
    flexDirection: 'column',
    '& label': {
      fontSize: '14px',
      marginBottom: 0,
    },
    '& input': {
      background: 'white',
      fontSize: '15px',
      padding: '9px 15px',
      margin: '0.5rem 0',
    },
  },

  colorSGrey: {
    color: colors.slateGrey,
    fontSize: '14px',
    marginBottom: 0,
  },

  displayB: {
    display: 'block',
  },

  ribInfo: {
    margin: '0 0 40px',
    fontSize: '14px',
  },

  pt10: {
    paddingTop: '10px',
  },

  pt20: {
    paddingTop: '20px',
  },

  pt45: {
    paddingTop: '45px',
  },

  inputFile: {
    '& input': {
      width: '0.1px',
      height: '0.1px',
      opacity: 0,
      overflow: 'hidden',
      position: 'absolute',
      cursor: 'pointer',
    },

    '& label': {
      width: '274px',
      height: '40px !important',
      cursor: 'pointer',
      display: 'inline-flex',
      alignItems: 'center',
      borderRadius: '8px',
      justifyContent: 'center',
      fontSize: '14px',
      color: `${colors.white} !important`,
      padding: '10px 12px',
      backgroundColor: colors.dark,
      boxShadow: ' 0px 0px 2px rgba(0, 0, 0, 0.25)',
    },
  },

  errorLabel: {
    color: colors.errorRed,
    marginTop: '5px',
    fontSize: '11px',
  },
}));

export default useStyles;

import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useState } from 'react';
import { GarageExtraContent } from '../../redux/stores/garages/type';
import colors from '../style-colors';

interface TextaeraLinkProps {
  label?: string;
  name: string;
  reference: any;
  field: string;
  contents?: GarageExtraContent[];
  handleChange?: (v: any) => any;
}

const TextaeraLink: React.FC<TextaeraLinkProps> = ({
  label,
  name,
  reference,
  field,
  contents,
  handleChange,
}) => {
  const textaeraStyles = makeStyles({
    formControl: {
      resize: 'none',
      width: '100%',
      height: '250px',
      display: 'block',
      padding: '10px 15px',
      fontSize: '13px',
      fontWeight: 400,
      lineHeight: '1.5',
      color: colors.dark,
      backgroundColor: colors.white,
      backgroundClip: 'padding-box',
      border: 'none',
      borderRadius: '8px',
      transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
    },
    label: {
      color: colors.slateGrey,
      fontSize: '12px',
      fontWeight: 'bold',
      marginBottom: '10px',
    },
    wrapper: {
      width: '100%',
      position: 'relative',
    },
    link: {
      backgroundColor: 'white',
      listStyleType: 'none',
      display: 'flex',
      width: '100%',
      margin: '0px',
      padding: '10px 11px 7px',
      textDecorationStyle: 'solid',
      textDecorationThickness: '4px',
      borderRadius: '8px 8px 0 0',
      '& ul, li': {
        margin: '5px 6px',
        fontSize: '12px',
        color: colors.dark,
      },
      '& + textarea': {
        borderRadius: '0 0 8px 8px',
      },
    },
    length: {
      position: 'absolute',
      bottom: '0',
      padding: '15px',
      right: '0',
      fontSize: '13px',
      color: colors.slateGrey,
    },
    labelLength: {
      color: colors.dark,
    },
    linkActive: {
      cursor: 'pointer',
      textDecorationColor: colors.primary,
      position: 'relative',
      '&::before': {
        position: 'absolute',
        content: '""',
        width: '21px',
        height: '4px',
        left: '-2px',
        background: colors.primary,
        bottom: '-5px',
        borderBottomLeftRadius: '5px',
        borderTopLeftRadius: '5px',
        borderBottomRightRadius: '5px',
        borderTopRightRadius: '5px',
      },
    },
    linkNoActive: {
      cursor: 'pointer',

      fontSize: '11px',
    },
  });
  const classes = textaeraStyles();
  const [locale, setLocale] = useState('fr');
  const [value, setValue] = useState<Record<string, GarageExtraContent>>({
    fr: {
      lang: 'fr',
      content:
        (contents || []).find((c) => c.field === field && c.lang === 'fr')
          ?.content || '',
      field,
    },
    nl: {
      lang: 'nl',
      content:
        (contents || []).find((c) => c.field === field && c.lang === 'nl')
          ?.content || '',
      field,
    },
  });

  return (
    <div>
      <div className="form-group">
        <label htmlFor="textarea" className={classes.label}>
          {label}
        </label>
        <div className={classes.wrapper}>
          <ul className={classes.link}>
            <li
              className={
                locale === 'fr' ? classes.linkActive : classes.linkNoActive
              }
              onClick={() => setLocale('fr')}
            >
              FR
            </li>
            <li
              className={
                locale === 'nl' ? classes.linkActive : classes.linkNoActive
              }
              onClick={() => setLocale('nl')}
            >
              NL
            </li>
          </ul>

          <textarea
            ref={reference}
            name={name}
            onChange={(e) => {
              const valueResult = {
                ...value,
                [locale]: { field, lang: locale, content: e.target.value },
              };
              setValue(valueResult);
              if (handleChange) {
                handleChange(valueResult);
              }
            }}
            maxLength={360}
            className={classes.formControl}
            rows={5}
            value={value[locale].content}
          >
            {value[locale].content}
          </textarea>
          <div className={classes.length}>
            <p className="labelLength">
              {value[locale].content.length}
              /360
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

TextaeraLink.defaultProps = {
  label: undefined,
  contents: undefined,
  handleChange: () => {},
};

export default TextaeraLink;

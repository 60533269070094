export const HOME = '/';
export const HELP = '/help';
export const CONTACT = '/contact';
export const SIGN_IN = '/';
export const SIGN_IN_ADMIN = '/signinAdmin';
export const RESET = '/reset';
export const COMMAND = '/command';
export const RESET_PASSWORD = '/resetPassword';
export const ACCOUNT = '/account';
export const ACCOUNT_BILLING = '/account/billing-info';
export const ACCOUNT_DESCRIPTION = '/account/description';
export const ACCOUNT_HOURS = '/account/opening-hours';
export const ACCOUNT_SERVICES = '/account/services';
export const ACCOUNT_DETAILS = '/account/details';
export const RATES = '/rates';
export const BILLING = '/billing';
export const VISIBILITY = '/visibility';
export const SEARCH_RESULTS = '/searchResults';
export const PLANNING = '/planning';
export const PLANNING_UPDATE = '/planning/update';
export const TYRES_SELECTION = '/tyres/selection';
export const QUOTE = '/quote';
export const QUOTE_SUMMARY = '/quote/summary';
export const QUOTE_CONFIRM = '/quote/confirm';
export const ADD_BOOKING = '/quote/add-booking';

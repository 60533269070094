import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import makeStyles from '@material-ui/core/styles/makeStyles';
import colors from '../style-colors';

interface CheckboxLabelProps {
  checked?: boolean;
  onchange?: (e: any) => void;
  label?: string;
  name?: string;
  disabled?: boolean;
}

const CheckboxLabel = ({
  checked,
  onchange,
  label,
  name,
  disabled,
}: CheckboxLabelProps) => {
  const useStyles = makeStyles({
    label: {
      fontWeight: 'bold',
      color: colors.slateGrey,
      fontSize: '12px',
    },

    root: {
      '& .MuiCheckbox-root': {
        color: checked && colors.primary,
      },
    },
  });

  const classes = useStyles();

  return (
    <FormControlLabel
      control={
        <Checkbox
          color="default"
          disabled={disabled}
          checked={checked}
          onChange={onchange}
          name={name}
        />
      }
      label={label}
      classes={classes}
    />
  );
};

CheckboxLabel.defaultProps = {
  checked: true,
  onchange: () => {},
  label: '',
  name: '',
  disabled: false,
};

export default CheckboxLabel;

import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import * as ROUTES from '../../../../router/routes';
import colors from '../../../../assets/style-colors';
import { Language } from '../../../../redux/stores/languages';

interface NavbarProps {
  className?: string;
  lg: Language;
}

const Navbar = ({ className, lg }: NavbarProps) => (
  <div className={className}>
    <ul className="list">
      <li>
        <NavLink activeClassName="isactive" exact to={ROUTES.ACCOUNT}>
          {lg.account.info}
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="isactive" to={`${ROUTES.ACCOUNT_HOURS}`}>
          {lg.account.timetable}
        </NavLink>
      </li>
      <li>
        <NavLink
          activeClassName="isactive"
          to={`${ROUTES.ACCOUNT_DESCRIPTION}`}
        >
          {lg.account.description}
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="isactive" to={`${ROUTES.ACCOUNT_BILLING}`}>
          {lg.account.bank}
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="isactive" to={`${ROUTES.ACCOUNT_SERVICES}`}>
          {lg.account.service}
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="isactive" to={`${ROUTES.ACCOUNT_DETAILS}`}>
          {lg.account.infoAccount}
        </NavLink>
      </li>
    </ul>
  </div>
);

Navbar.defaultProps = {
  className: ''
};

export default styled(Navbar)`
  height: 56px;
  display: flex;
  background-color: white;
  width: 100%;
  .list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 0;
  }

  .list,
  li {
    padding: 20px;
    border: none;
    font-size: 14px;
    text-align: center;
  }

  a {
    color: ${colors.lightGreyBlue};
  }
  & a:hover {
    text-decoration: none;
  }

  .isactive {
    text-decoration: none;
    border-bottom: 4px solid ${colors.primary};
    color: ${colors.dark};
    padding-bottom: 17px;
  }
`;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import alertStrokeIncon from '../../../assets/style-elements/01-electrons-icon-alert-stroke@3x.png';
import { Billing, ViewsListEnum } from '../../../redux/stores/billings/type';
import Contest from '../modal/contest';
import { Store } from '../../../redux/types';

interface OneViewBillingListProps {
  billing: Billing;
  isContested: boolean;
  classes: any;
  view: ViewsListEnum;
}
const OneViewBillingList: React.FC<OneViewBillingListProps> = ({
  billing,
  isContested,
  classes,
  view
}) => {
  const {
    languageReducer: { language: lg },
    billingsReducer: { currentBill: iscurrentBill }
  } = useSelector((state: Store) => state);

  const viewWithoutId: Array<string> = [
    ViewsListEnum.ADDITIONAL_VOUCHER,
    ViewsListEnum.SUBSCRIPTION
  ];
  const isVoucher = iscurrentBill?.type === 'VOUCHER';
  const [contested, setContested] = useState(false);
  const iContest = () => {
    setContested(true);
  };
  return (
    <div key={billing.id} className="tr">
      <div className="td_tiny_border_start">
        <span className={classes.startSpan}>
          {!viewWithoutId.includes(view) ? billing.quoteId : '-'}
        </span>
      </div>

      <div className={`${classes.description} td_tiny_border_end`}>
        <span>{billing.label}</span>
      </div>

      <div className="td_tiny_border_end">
        <span className="voucher">
          {isVoucher
            ? `-${billing.amount.toFixed(2)}`
            : billing.amount.toFixed(2)}
          €
        </span>
      </div>

      <div
        onFocus={() => {}}
        className={`${classes.alignCenter} td_tiny_border_end`}
      >
        {isContested || contested ? (
          <>
            <span className={classes.contextTrue}>
              {lg.billing.commission.contest}
            </span>
          </>
        ) : (
          <Contest billing={billing} iContest={iContest}>
            <div className={classes.context}>
              <img src={alertStrokeIncon} alt="alertStrokeIncon" />
              <span>{lg.billing.commission.context}</span>
            </div>
          </Contest>
        )}
      </div>
    </div>
  );
};

export default OneViewBillingList;

import { makeStyles, createTheme } from '@material-ui/core/styles';
import colors from '../../assets/style-colors';

// STYLES:
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1200,
      xl: 1920,
    },
  },
});

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    backgroundColor: colors.veryPaleGrey,
    color: 'darkgrey',
    overflow: 'hidden',
    flexDirection: 'column',
    height: '100%',
  },

  logo: {
    display: 'flex',
    justifyContent: 'center',
    width: '80px',
    height: '50px',
    marginRight: '10px',
  },

  headerRows: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  bodyContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    gap: '20px',
  },

  top: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    padding: '0px 15px',
    width: '100%',
    height: '56px',
    backgroundColor: colors.white,

    '& .back': {
      fontSize: '12px',
      lineHeight: 'normal',
      color: colors.slateGrey,
    },

    '& .pointer': {
      cursor: 'pointer',
    },

    '& .autossimo': {
      width: '37px',
      height: '14px',
    },

    '& #autossimo': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      width: '211px',
      height: '40px',
      borderRadius: '8px',
      backgroundColor: colors.dark,

      '& .piece': {
        width: '100%',
        fontSize: '12px',
        whiteSpace: 'nowrap',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: 'white',
        textAlign: 'center',
      },
    },
  },

  topSecond: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    padding: '15px 15px 15px 15px',
    width: '100%',
    height: '56px',
    backgroundColor: colors.veryPaleGrey,

    '& .numero': {
      fontSize: '16px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      lineHeight: 'normal',
      color: colors.dark,
    },

    '& .logo': {
      height: '30px',
      marginRight: '11px',
    },
  },

  nameCampany: {
    display: 'flex',
    alignItems: 'center',
  },

  bottom: {
    display: 'flex',
    flexDirection: 'column',
    margin: '20px',
  },

  bottomAvailability: {
    marginTop: '10px',
  },

  left: {
    width: '100%',

    '& .garage-info': {
      flex: 1,
      display: 'none',
      gap: '20px',
      alignItems: 'center',
      backgroundColor: colors.white,
      borderRadius: '20px',
      padding: '20px',
      margin: '20px',

      '& > img': {
        width: '96px',
        borderRadius: '20px',
        border: `1px solid ${colors.lightGrey}`,
        padding: '10px',
      },

      '& > div': {
        lineHeight: '150%',
      },

      h3: {
        fontWeight: 'bold',
      },
    },

    '& .detail': {
      flexDirection: 'column',
      display: 'flex',
      background: colors.white,
      borderRadius: '10px',
      padding: '25px 20px',
      margin: '20px 20px 0px 20px',
      boxShadow: '0 2px 18px 0 rgba(136, 135, 135, 0.17)',
    },

    '& .block': {
      background: colors.white,
      marginBottom: '10px',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
      },

      '&:ntn-child(2n)': {
        background: colors.WhiteDark,
        height: '1px',
        width: '100%',
      },

      '& .callToAction': {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        marginLeft: 'auto',
      },
    },

    '& .devis': {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      alignItems: 'flex-start',
      color: colors.white,

      '& .wrap': {
        display: 'flex',

        '& .span1': {
          padding: '8px',
          borderRadius: '4px',
          textTransform: 'uppercase',
          fontSize: '15px',
          lineHeight: 'normal',
        },

        '& .CONFIRMED': {
          background: colors.mediumGreen,
        },

        '& .VALIDATED': {
          background: colors.azure,
        },

        '& .REPORTED': {
          background: colors.brightOrange,
        },

        '& .CANCELLED': {
          background: colors.dark,
          textDecoration: 'line-through',
        },

        '& .WALLET': {
          background: colors.dark,
          textDecoration: 'line-through',
        },

        '& .walletAdmin': {
          background: colors.yellow,
        },

        '& .perso': {
          background: colors.perso,
        },
      },

      '& .span2': {
        display: 'flex',
        alignItems: 'center',
        color: colors.slateGrey,
        margin: '12px 0px',
        fontSize: '15px',
      },

      '& .span2.car-service-label': {
        fontWeight: 'bold',
        fontSize: '1.25rem',
        margin: '1rem 0',
      },

      '& .car-service-icon': {
        marginRight: '.5rem',
        width: '1.5rem',
        color: colors.primary,
      },
    },

    '& .span2': {
      fontSize: '12px',
      color: colors.white,
    },

    '& .button3': {
      padding: '8px',
      marginLeft: '10px',
      borderRadius: '5px',
      border: `solid 1px ${colors.veryLightBlue}`,
      cursor: 'pointer',
    },

    '& .tooltip': {
      width: '174px',
      height: 'auto',
      position: 'absolute',
      padding: '15px 12px',
      transform: 'translate(-129px)',
      border: `solid 1px ${colors.paleGrey}`,
      borderRadius: '8px',
      background: colors.white,
      boxShadow: '0 2px 18px 0 rgba(136, 135, 135, 0.5)',
      zIndex: '1',
      opacity: '1',
    },

    '& .cancel': {
      display: 'block',
      width: '100%',
      margin: '0px',
      padding: '5px 0',
      borderBottom: `solid 1px ${colors.WhiteDark}`,
      fontSize: '12px',
      fontWeight: 'bold',
      lineHeight: 'normal',
      color: colors.slateGrey,
    },

    '& .refund': {
      padding: '5px 0',
      fontSize: '12px',
      fontWeight: 'bold',
      lineHeight: 'normal',
      color: colors.slateGrey,
      cursor: 'pointer',
    },

    '& .show': {
      display: 'block',
    },

    '& .hide': {
      display: 'none',
    },

    '& .alert': {
      display: 'flex',
      gap: '1rem',
      flex: '100%',
      flexWrap: 'nowrap',
      alignItems: 'center',
      padding: '1rem',
      backgroundColor: colors.boxAlert,
      borderRadius: '10px',
      color: colors.errorColor,

      '& svg': {
        display: 'flex',
        width: '2rem',
        height: '2rem',
        flexShrink: 0,
      },

      '& #textAlert': {
        display: 'flex',
        fontSize: '.8rem',
        fontWeight: '600',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.38',
        letterSpacing: 'normal',
      },
    },

    '& .reported-issue': {
      backgroundColor: colors.reportedBcgColor,
      color: colors.REPORTED,
    },

    '& .without': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flex: '1',
      fontSize: '12px',
      fontWeight: '600',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.17',
      letterSpacing: 'normal',

      '& span': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '15px',
      },

      '& img': {
        width: '18px',
        height: '18px',
      },

      '& .apart': {
        display: 'flex',
        alignItems: 'center',
        fontSize: '15px',
        lineHeight: '1.27',
        color: colors.slateGrey,
      },
    },

    '& .note': {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      flex: '1',
      fontSize: '12px',
      fontWeight: '600',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.17',
      letterSpacing: 'normal',
    },
    '& .titleNote': {
      display: 'flex',
      flex: '100%',
      alignItems: 'center',
      justifyContent: 'flex-start',
      fontSize: '16px',
      marginTop: '5px',
      color: colors.dark,
    },

    '& .textNote': {
      flex: '100%',
      alignItems: 'center',
      justifyContent: 'flex-start',
      background: 'rgb(238, 240, 247)',
      borderRadius: '8px',
      padding: '15px',
      marginBottom: '15px',
      whiteSpace: 'pre-wrap',
      width: '250px',
      wordWrap: 'break-word',
    },
  },

  table: {
    width: '100%',
    marginTop: '20px',

    '& .edit-admin-actions': {
      display: 'flex',
      justifyContent: 'end',
      marginTop: '1.5rem',
      borderTop: 0,
    },

    '& .flex': { display: 'flex', alignItems: 'center' },

    '& .gap-2': { gap: '.5rem' },
    '& .tr': {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: '5fr repeat(4, 1fr) 1fr',
      color: colors.barGrey,
      fontSize: '0.8rem',
      backgroundColor: '#FAFAFA',

      '& th, & td': {
        borderTop: 'none',
      },

      '&.packaged': {
        backgroundColor: colors.paleGreyDrank,

        '& svg': {
          width: '1.5rem',
          flexShrink: 0,
        },

        '& .td_tiny_border_start': {
          borderBottom: '0 none',
          paddingLeft: '2rem',
        },

        '& .td_tiny_border_end': {
          visibility: 'hidden',
          borderBottom: '0 none',
        },
      },
    },

    '& .inputGrid': {
      gridTemplateColumns: '[a] 3fr [b] 1fr [c] 1fr [d] 1.3fr [e] 1fr [f] 1fr',

      '& .td_tiny_head_end': {
        textAlign: 'right',
      },
    },

    '& .tr:nth-child(even)': {
      backgroundColor: colors.trBcgColor,
    },

    '& .tr .icon': {
      width: '1.5rem',
    },

    '& .tr .icon-warning': {
      color: colors.trIconWarning,
    },

    '& .td_tiny_head_start, .td_tiny_head_end': {
      padding: '0.7em 0',
      wordBreak: 'break-word',
      whiteSpace: 'normal',
      borderBottom: `1px solid ${colors.tdTinyHeadStartBorderColor}`,
      fontWeight: 'bold',
    },

    '& .td_tiny_head_start': {
      textAlign: 'left',
    },

    '& .td_tiny_head_end': {
      textOverflow: 'ellipsis',
      textAlign: 'center',
      justifyContent: 'flex-end',
    },

    '& .td_tiny_head_icon': {
      display: 'flex',
      flexFlow: 'row nowrap',
      flexGrow: '0.2',
      flexBasis: '0',
      padding: '0.7em',
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      minWidth: '0px',
      whiteSpace: 'normal',
      textAlign: 'center',
      borderBottom: `1px solid ${colors.tdBorderColor}`,
      justifyContent: 'center',
    },

    '& .td_tiny_withoutBorder_start': {
      display: 'flex',
      flexFlow: 'row',
      flexGrow: '2',
      flexBasis: '0',
      padding: '0.7em',
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      minWidth: '0px',
      whiteSpace: 'normal',
      textAlign: 'right',
    },

    '& .td_tiny_total_end': {
      display: 'flex',
      flexFlow: 'row nowrap',
      flexGrow: '2',
      flexBasis: '0',
      padding: '0.7em',
      minWidth: '0px',
      whiteSpace: 'normal',
      justifyContent: 'flex-end',
    },

    '& .td_tiny_border_start': {
      display: 'flex',
      flexDirection: 'row',
      gap: '2rem',
      padding: '0.7em',
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      minWidth: '0px',
      whiteSpace: 'normal',
      textAlign: 'left',
      fontWeight: 100,
      borderBottom: `1px solid ${colors.tdBorderColor}`,
    },

    '& .td_tiny_border_start > *': {
      flex: 1,
    },

    '& .td_tiny_border_end': {
      display: 'flex',
      flexFlow: 'row nowrap',
      flexGrow: '1',
      flexBasis: '0',
      padding: '0.7em',
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      minWidth: '0px',
      whiteSpace: 'normal',
      textAlign: 'right',
      borderBottom: `1px solid ${colors.tdBorderColor}`,
      justifyContent: 'flex-end',
      alignItems: 'center',
    },

    '& .parts-selected': {
      maxWidth: '7rem',
      display: 'grid',
      placeItems: 'center',
      border: '1px solid rgb(68, 70, 74)',
      borderRadius: '10px',
      padding: '.25rem 0',
      flexShrink: 0,
      fontWeight: 'bold',
      textTransform: 'uppercase',

      '& small': {
        fontSize: '.8em',
        fontWeight: 400,
      },
    },
  },

  arrow_left: {
    color: colors.slateGrey,
    width: '18px',
  },
}));

export default useStyles;

import { filter, sum, matches } from 'lodash';
import makeStyles from '@material-ui/core/styles/makeStyles';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import colors from '../../../../../assets/style-colors';
import { Store } from '../../../../../redux/types';
import { getDuration } from '../../../../../utils/booking';
import clock from '../../../../../assets/style-elements/01-electrons-icon-time-big.png';

interface DayHoursProps {
  currentDay: string;
  isWithUpdatePlanningMode?: boolean;
}

const useStyles = makeStyles(() => ({
  hours: {
    fontSize: '9px',
    color: colors.slateGrey,
    borderRadius: '4px',
    padding: '7px',
    height: '20px',
    lineHeight: '20px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  iconClockSmall: {
    width: '10px',
    marginRight: '5px',
  },
}));

const DayHours: React.FC<DayHoursProps> = ({
  currentDay,
  isWithUpdatePlanningMode = false,
}) => {
  const lg = useSelector((state: Store) => state.languageReducer.language);
  const { bookings } = useSelector((state: Store) => state.planningStore);
  const calendar = useSelector((state: Store) => state.calendarStore.calendar);
  const currentWeekAvailabilities = useSelector(
    (state: Store) => state.calendarStore.currentWeekAvailabilities
  );

  const dayIndex = moment(currentDay).day() - 1;
  const classes = useStyles();
  const bookingsOfTheDay =
    bookings && bookings[dayIndex] ? bookings[dayIndex] : [];
  const hours = sum(
    bookingsOfTheDay.map((booking) =>
      sum(booking.quotes.map((quote) => getDuration(quote)))
    )
  );

  const workingHours: number[] =
    calendar?.regularWeek.map((r) =>
      r.openingHours.length > 0 ? parseInt(r.slots.toString()) : 0
    ) || [];

  const specialDays = filter(
    currentWeekAvailabilities,
    matches({ day: currentDay })
  );

  let totalHours = workingHours[dayIndex] || 0;

  if (specialDays.length > 0) {
    totalHours =
      specialDays[0].availability.openingHours.length > 0
        ? specialDays[0].availability.slots
        : 0;
  }
  const closed = totalHours === 0;
  const dayHoursColor = closed ? colors.veryLightBlue : colors.white;

  return (
    <div
      className={classes.hours}
      style={{
        backgroundColor: isWithUpdatePlanningMode
          ? 'transparent'
          : dayHoursColor,
      }}
    >
      {!isWithUpdatePlanningMode && (
        <img className={classes.iconClockSmall} src={clock} alt="o" />
      )}
      {closed && ` ${lg.planning.closed}`}
      {!closed && ` ${lg.planning.hours}: ${hours}/${totalHours}`}
    </div>
  );
};

DayHours.defaultProps = {
  isWithUpdatePlanningMode: false,
};

export default DayHours;

import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import * as Sentry from '@sentry/react';
import lg from '../../../assets/language/FR';
import HttpService from '../../../services/http-service';
import { Store } from '../../types';
import {
  AdditionalServicesCategory,
  AutossimoLogin,
  CarService,
  Garage,
  GarageService,
  Locale,
  MessageNotification,
  SeverityMessage,
} from './types';

import GarageRequest from '../../../services/http/Garage';

import AuthenticationService, {
  Roles,
} from '../../../services/authentication-service';

// TODO: API additional services

const mockedAdditionalServices = {
  serviceCertifications: [
    {
      id: 'rep_flag_certifcli',
      active: false,
    },
    {
      id: 'rep_allumage',
      active: false,
    },
    {
      id: 'rep_carosserie',
      active: false,
    },
    {
      id: 'rep_renovation',
      active: false,
    },
    {
      id: 'rep_is_diagnostic_electronique',
      active: false,
    },
    {
      id: 'rep_is_geometrie_trains',
      active: false,
    },
    {
      id: 'rep_is_specialiste_clim',
      active: false,
    },
    { id: 'rep_is_specialiste_gpl', active: false },
    {
      id: 'rep_is_specialiste_electro_diesel',
      active: false,
    },
    {
      id: 'rep_is_specialiste_electrique_hybride',
      active: false,
    },
  ],
  servicesPlus: [
    {
      id: 'rep_garage_mobile',
      active: false,
    },
    {
      id: 'rep_is_gare_proche',
      active: false,
    },
    { id: 'rep_depannage', active: false },

    {
      id: 'rep_veh_courtoisie',
      active: false,
    },
    { id: 'rep_service_domicile', active: false },
    { id: 'rep_station_lavage', active: false },
    {
      id: 'rep_is_gardiennage_pneus_neige',
      active: false,
    },
    { id: 'rep_facilire_paie', active: false },
    { id: 'rep_station_essence', active: false },
    {
      id: 'rep_is_distributeur_boissons_cafe',
      active: false,
    },

    {
      id: 'rep_is_raccompagne_client_domicile',
      active: false,
    },
    {
      id: 'rep_pctrl_technique',
      active: false,
    },
    {
      id: 'rep_pres_ctrltech',
      active: false,
    },
    {
      id: 'rep_is_demarche_declaration_ass',
      active: false,
    },
    {
      id: 'rep_is_parking',
      active: false,
    },
    {
      id: 'rep_is_specialiste_electro_diesel',
      active: false,
    },
    { id: 'rep_wifi', active: false },
    { id: 'rep_is_espace_attente', active: false },
    {
      id: 'rep_boutique',
      active: false,
    },
    {
      id: 'rep_is_montre_pieces_changees',
      active: false,
    },
    { id: 'rep_espace_enfant', active: false },
    { id: 'rep_is_vente_vo_vn', active: false },
    { id: 'rep_montage_pneus', active: false },
    { id: 'rep_carte_grise', active: false },
  ],
};

export const SET_CAR_SERVICES = 'SET_CAR_SERVICES';
export const SET_CURRENT_GARAGE = 'SET_CURRENT_GARAGE';
export const SET_CURRENT_LOCALE = 'SET_CURRENT_LOCALE';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const SET_GARAGE_SERVICES = 'SET_GARAGE_SERVICES';
export const SET_ADDITIONAL_SERVICES = 'SET_ADDITIONAL_SERVICES';
export const RESET_STORE = 'RESET_STORE';

export type ActionTypes =
  | { type: typeof RESET_STORE }
  | {
      type: typeof SET_CAR_SERVICES;
      payload: Record<string, CarService>;
    }
  | { type: typeof SET_CURRENT_LOCALE; payload: Locale }
  | { type: typeof SET_CURRENT_GARAGE; payload: Garage | null }
  | { type: typeof SET_NOTIFICATION; payload: MessageNotification | null }
  | { type: typeof SET_GARAGE_SERVICES; payload: GarageService[] | null }
  | {
      type: typeof SET_ADDITIONAL_SERVICES;
      payload: AdditionalServicesCategory | null;
    };

export const setServices = (
  carservices: Record<string, CarService>
): ActionTypes => ({ type: SET_CAR_SERVICES, payload: carservices });

export const setCurrentLocale = (locale: Locale): ActionTypes => ({
  type: SET_CURRENT_LOCALE,
  payload: locale,
});

export const setAddServices = (
  services: AdditionalServicesCategory
): ActionTypes => ({
  type: SET_ADDITIONAL_SERVICES,
  payload: services,
});

export const setCurrentGarage = (garage: Garage | null): ActionTypes => ({
  type: SET_CURRENT_GARAGE,
  payload: garage,
});

export const setGarageServices = (
  garageServices: GarageService[] | null
): ActionTypes => ({
  type: SET_GARAGE_SERVICES,
  payload: garageServices,
});

export const setNotification = (
  notification: MessageNotification | null
): ActionTypes => ({
  type: SET_NOTIFICATION,
  payload: notification,
});

export const setCurrentGarageServices =
  (garageId: string): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) =>
    HttpService.getClient()
      .get(
        `${process.env.REACT_APP_API_PRICING_URL}/garages/${garageId}/services`,
        {
          headers: { 'api-key': process.env.REACT_APP_API_PRICING_KEY },
          params: { ts: new Date().getTime() },
        }
      )
      .then((response) => {
        dispatch(
          setGarageServices(
            response.data?.subscriptions.map((subscription: GarageService) => ({
              ...subscription,
              service: response.data?.services.find(
                (service: GarageService) =>
                  service.serviceId === subscription.serviceId
              ),
            }))
          )
        );
      })
      .catch(() => {
        dispatch(setGarageServices(null));
      });

export const setGarage =
  (garageId: string): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const garage: Garage | null = await new GarageRequest().getGarageById(
      garageId
    );

    if (!garage) {
      dispatch(
        setNotification({
          severity: SeverityMessage.ERROR,
          message: getState().languageReducer.language.error.errorGarageNotFound,
        })
      );

      dispatch(setCurrentGarage(null));

      if (!AuthenticationService.hasRole([Roles.ADMIN, Roles.CALL_CENTER])) {
        Sentry.captureMessage(
          `the garage [${garageId}] for the user ${
            AuthenticationService.getUserInfo().email
          } does not exist`
        );
      }
      return;
    }

    const { garageNetwork } = garage;
    const networksId = garageNetwork.map((network) => network.networkId);

    if (
      !AuthenticationService.hasNetwork(networksId) &&
      !AuthenticationService.hasNetwork(['IMA']) &&
      AuthenticationService.hasRole([Roles.ADMIN, Roles.CALL_CENTER])
    ) {
      dispatch(
        setNotification({
          severity: SeverityMessage.ERROR,
          message: lg.error.errorGarageUnauthorized,
        })
      );
      dispatch(setCurrentGarage(null));
      return;
    }

    const autossimo: AutossimoLogin | null = await HttpService.getClient()
      .get(
        `${process.env.REACT_APP_API_GARAGES_URL}/garages/${garageId}/login-autossimo`,
        {
          headers: { 'api-key': process.env.REACT_APP_API_GARAGES_KEY },
          params: { ts: new Date().getTime() },
        }
      )
      .then((response) => response.data)
      .catch(() => null);
    dispatch(setCurrentGarage({ ...garage, autossimo }));
    dispatch(setCurrentGarageServices(garageId));
  };

export const setAdditionalServices =
  (): ThunkAction<void, Store, unknown, Action<string>> => async (dispatch) => {
    dispatch(setAddServices(mockedAdditionalServices));
  };

// TODO uses network id from current garage
// TODO uses current locale
export const getServices =
  (): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const { locale } = getState().languageReducer.language;
    const services: CarService[] = await HttpService.getClient()
      .get(`${process.env.REACT_APP_API_CAR_SERVICES_URL}`, {
        headers: {
          'api-key': process.env.REACT_APP_API_CAR_SERVICES_KEY,
          'Accept-Language': locale,
        },
        params: {
          'x-network-id': 'IDG',
          state: 'ACTIVE',
          countryCode: locale === 'fr' ? 'fr' : 'be',
        },
      })
      .then((res) => res.data);
    dispatch(
      setServices(
        services.reduce((acc: Record<string, CarService>, service) => {
          acc[service.id.toString()] = {
            ...service,
            id: service.id.toString(),
          };
          return acc;
        }, {})
      )
    );
  };

export const uploadRib = async (
  data: FormData,
  success: () => void,
  failure: () => void
) => {
  await HttpService.getClient()
    .post(`${process.env.REACT_APP_API_RIB_URL}`, data, {
      headers: {
        'api-key': process.env.REACT_APP_API_RIB_URL_KEY,
      },
    })
    .then(() => {
      success();
    })
    .catch(failure);
};

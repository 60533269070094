import React from 'react';
import {
  BarChart,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import getFormattedVisibilityChart from '../../../utils/visibility';
import colors from '../../../assets/style-colors';
import { Language, Visible } from '../../../redux/stores/languages';
import { VisibilityTabs } from '../../../redux/stores/global/types';

interface VisibilityChartProps {
  chartsByMonth: { data: [] };
  chartKey: { name: keyof Visible; icon: string; color: string };
  currentMonthIndex: number;
  lg: Language;
}

const VisibilityChart: React.FC<VisibilityChartProps> = ({
  chartsByMonth,
  chartKey,
  currentMonthIndex,
  lg,
}) => {
  const allOptionIndex = 12;

  const CustomTooltip = ({ active, payload, label }: any) => {
    const currency =
      lg.visibility.visible[chartKey.name] !== lg.visibility.visible.quotes
        ? '€'
        : '';

    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: colors.white,
            border: `1px solid ${colors.tooltipBorderColor}`,
            padding: '10px',
          }}
        >
          <p style={{ marginBottom: '5px' }}>{label}</p>
          <p style={{ color: chartKey.color }}>
            {`${lg.visibility.visible[chartKey.name]} : ${parseInt(
              payload[0].value
            )} ${currency}`}
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={230}>
      <BarChart
        barSize={15}
        margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
        data={getFormattedVisibilityChart(chartsByMonth?.data)}
      >
        <CartesianGrid
          strokeDasharray="3 3"
          vertical={false}
          stroke={colors.WhiteDark}
        />
        <XAxis
          fontSize={7}
          interval={0}
          dataKey="monthName"
          stroke={colors.lightGreyBlue}
          tickSize={1}
          tickMargin={3}
        />
        <YAxis
          width={30}
          fontSize={9}
          dataKey={chartKey.name}
          stroke={colors.lightGreyBlue}
          tickSize={4}
        />
        {window.location.pathname === VisibilityTabs.Sale ? (
          <Tooltip content={<CustomTooltip />} />
        ) : (
          <Tooltip
            formatter={(value: string) => [
              value,
              lg.visibility.visible[chartKey.name],
            ]}
          />
        )}
        <Bar
          dataKey={chartKey.name}
          fill={chartKey.color}
          radius={[10, 10, 0, 0]}
        >
          {currentMonthIndex === allOptionIndex ? (
            <Cell
              cursor="pointer"
              fill={chartKey.color}
              fillOpacity={1}
              key={`cell-${currentMonthIndex}`}
            />
          ) : (
            getFormattedVisibilityChart(chartsByMonth?.data).map(
              (element, dataIndex) => (
                <Cell
                  cursor="pointer"
                  fill={chartKey.color}
                  fillOpacity={dataIndex === currentMonthIndex ? 1 : 0.3}
                  key={`cell-${currentMonthIndex}`}
                />
              )
            )
          )}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default React.memo(VisibilityChart);

import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import {
  Route,
  RouteComponentProps,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import colors from '../../assets/style-colors';
import arrow from '../../assets/style-elements/arrow.svg';
import print from '../../assets/style-elements/print.svg';
import {
  activeUpdatePlanningMode,
  getNextBookings,
} from '../../redux/stores/planning/actions';
import { QuoteStatus } from '../../redux/stores/planning/types';
import { Store } from '../../redux/types';
import { PLANNING_UPDATE } from '../../router/routes';
import PlanningFull from './components/planning-full-page';
import RegularWeekAdmin from './components/regular-week-admin';
import StatusView from './components/statuses-page/statusView';
import UpdatePlanning from './components/update-planning';
import UpdateDay from './components/update-planning/day';
import UpdateHolidays from './components/update-planning/holidays';
import PrintPlanning from './printIndex';

const Planning: React.FC<RouteComponentProps> = ({ location }) => {
  // CONSTANTS:

  const { currentGarage } = useSelector((state: Store) => state.globalReducer);
  const lg = useSelector((state: Store) => state.languageReducer.language);
  const { nextBookings, updatePlanningMode } = useSelector(
    (state: Store) => state.planningStore
  );
  const statuses = nextBookings?.reduce(
    (acc, booking) => {
      booking.quotes.forEach((q) =>
        q.status.toLowerCase() === 'wallet' ? acc.CANCELLED++ : acc[q.status]++
      );
      return acc;
    },
    { VALIDATED: 0, CONFIRMED: 0, REPORTED: 0, CANCELLED: 0, WALLET: 0 }
  );

  if (!currentGarage) return null;

  const dispatch = useDispatch();
  const { url, path } = useRouteMatch();
  const history = useHistory();
  const [active, setActive] = useState(-1);
  const [activePl, setActivePl] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const isLarge = width >= 1440;

  // FUNCTIONS:

  const handleWindowSizeChange = () => {
    setHeight(window.innerHeight);
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    dispatch(getNextBookings(currentGarage.id));
  }, []);

  // STYLES:
  const useStyles = makeStyles(() => ({
    root: {
      position: 'relative',
      bottom: '0',
      right: '0',
      display: 'flex',
      flexDirection: 'row',
      background: colors.white,
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    back: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    backText: {
      marginLeft: '5px',
    },
    leftNavBar: {
      height: '70px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      paddingRight: '20px',
      marginLeft: '30px',
    },

    rightMenu: {
      right: 0,
      bottom: 0,
      zIndex: 30,
      borderBottom: `1px solid ${colors.paleGrey}`,
      borderTop: `solid 1px ${colors.WhiteDark}`,
      borderLeft: `solid 1px ${colors.WhiteDark}`,
      width: '0',
      position: 'fixed',
      height: `calc(${height}px - 142px)`,
      boxShadow: `-10px 0px 10px ${colors.WhiteDark}`,
    },
    main: {
      display: 'flex',
      position: 'fixed',
      justifyContent: 'space-between',
      left: isLarge ? '196px' : '70px',
      right: '0',
    },
    help: {
      color: colors.lightGreyBlue,
      fontWeight: 500,
      cursor: 'pointer',
      padding: '0 20px 0 20px',
      display: 'flex',
      minWidth: '90px',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    link: {
      width: '100%',
      fontSize: '14px',
      textDecoration: 'none',
      '&:hover': {
        color: colors.dark,
        textDecoration: 'none',
      },
    },
    active: {
      display: 'flex',
      minWidth: '90px',
      cursor: 'pointer',
      justifyContent: 'center',
      padding: '0 20px 0 20px',
      alignItems: 'center',
      color: colors.black,
      fontWeight: 'bold',
      textAlign: 'center',
      textDecoration: 'none',
      position: 'relative',

      '&:before': {
        width: '100%',
        left: '0',
        top: '41px',
        height: '4px',
        content: '""',
        position: 'absolute',
        borderRadius: '4px',
        backgroundColor: colors.dark,
      },
    },
    item: {
      fontSize: '9px',
      minWidth: '20px',
      height: '20px',
      textAlign: 'center',
      lineHeight: '20px',
      margin: ' 0 0 0 10px',
      color: colors.white,
      borderRadius: '5px',
    },
    printButtonWrapper: {
      marginLeft: 'auto',
    },

    printButton: {
      color: colors.black,
      border: 'none',
      cursor: 'pointer',
      height: '34px',
      margin: '5px',
      display: 'flex',
      padding: '10px',
      background: colors.white,
      boxShadow: '0 2px 18px 0 rgb(214 214 214 / 50%)',
      alignItems: 'center',
      borderRadius: '10px',
      justifyContent: 'center',
      fontSize: '12px',
    },

    CONFIRMED: {
      backgroundColor: colors.mediumGreen,
    },
    VALIDATED: {
      backgroundColor: colors.azure,
    },
    REPORTED: {
      backgroundColor: colors.pomeGranate,
    },
    CANCELLED: {
      backgroundColor: colors.dark,
    },
    icon: {
      backgroundColor: colors.dark,
      position: 'absolute',
      width: '40px',
      height: '40px',
      padding: '10px',
      cursor: 'pointer',
      right: 0,
      top: '50px',
      zIndex: 31,
      borderBottomLeftRadius: '5px',
      borderTopLeftRadius: '5px',
      boxShadow: `0px 0px 5px ${colors.dark}`,
    },
    white: { color: colors.white },
  }));

  const classes = useStyles();
  const getStyle = (status: string) => {
    switch (status) {
      case 'VALIDATED':
        return classes.VALIDATED;
      case 'CONFIRMED':
        return classes.CONFIRMED;
      case 'REPORTED':
        return classes.REPORTED;
      case 'CANCELLED':
        return classes.CANCELLED;
      default:
        return '';
    }
  };

  const handleRedirect = (toPath: string): void => {
    history.push(toPath);
  };

  /**
   * Disable update mode if goBack is used on update view.
   */
  const handleGoBackPlanningView = (): void => {
    if (location.pathname === PLANNING_UPDATE) {
      dispatch(activeUpdatePlanningMode(false));
      handleRedirect(path);
    } else {
      handleRedirect(PLANNING_UPDATE);
    }
  };

  const handlePlanning = (): void => {
    handleGoBackPlanningView();
    setActivePl(true);
    setActive(3);
  };
  const componentRef = useRef(null);

  console.log('test', componentRef)

  const ButtonPrint = () => {
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      documentTitle: 'Planning',
    });
    
    return (
      <button
        type="button"
        onClick={handlePrint}
        className={classes.printButton}
      >
        <img
          src={print}
          alt="Imprimer le planning"
          style={{ paddingRight: '5px' }}
        />
        {lg.planningHeader.printPlanning}
      </button>
    );
  };

  return (
    <div>
      <Helmet>
        <title>{lg.menuLeft.planning}</title>
      </Helmet>
      <div ref={componentRef}>
        <PrintPlanning />
      </div>
      <div className={classes.root}>
        <div className={`${classes.leftNavBar}`}>
          <div className={activePl === true ? classes.active : classes.help}>
            <div
              role="button"
              tabIndex={0}
              className={classes.link}
              onClick={handlePlanning}
            >
              {!updatePlanningMode ? (
                lg.planningHeader.planning
              ) : (
                <div className={classes.back}>
                  <img src={arrow} alt="arrow" />
                  <span className={classes.backText}>
                    {lg.planningHeader.back}
                  </span>
                </div>
              )}
            </div>
          </div>

          {updatePlanningMode
            ? null
            : statuses &&
              Object.entries(statuses)
                .filter(([, total]) => total > 0)
                .map(([status, total], index: number) => (
                  <div
                    key={status}
                    className={active === index ? classes.active : classes.help}
                  >
                    <div
                      className={classes.link}
                      onKeyPress={() => {}}
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        setActive(index);
                        setActivePl(false);
                        handleRedirect(`${path}/${status.toLowerCase()}`);
                      }}
                    >
                      {lg.planningHeader[status]}
                    </div>
                    <div className={`${getStyle(status)} ${classes.item}`}>
                      {total}
                    </div>
                  </div>
                ))}
          <div className={classes.printButtonWrapper}>
            <ButtonPrint />
          </div>
        </div>
      </div>

      <div className={`${classes.main} noLeft`}>
        <Switch>
          <Route path={`${path}/update`}>
            <UpdatePlanning
              pathUpdateDay={`${url}/update-day`}
              pathUpdate={`${url}/update`}
              pathHolidays={`${url}/manage-holidays`}
            />
          </Route>
          <Route path={`${path}/manage-holidays`}>
            <UpdateHolidays pathUpdate={`${url}/update`} />
          </Route>
          <Route path={`${path}/update-day/:index`}>
            <UpdateDay pathUpdate={`${url}/update`} />
          </Route>
          <Route path={`${path}/manage-week`}>
            <RegularWeekAdmin updatePath={`${url}/`} />
          </Route>
          <Route path={`${path}/validated`}>
            <StatusView status={[QuoteStatus.VALIDATED]} />
          </Route>
          <Route path={`${path}/confirmed`}>
            <StatusView status={[QuoteStatus.CONFIRMED]} />
          </Route>
          <Route path={`${path}/reported`}>
            <StatusView status={[QuoteStatus.REPORTED]} />
          </Route>
          <Route path={`${path}/cancelled`}>
            <StatusView status={[QuoteStatus.CANCELLED, QuoteStatus.WALLET]} />
          </Route>
          <Route path={`${path}`}>
            <PlanningFull
              pathDayModif={`${url}/update-day`}
              pathUpdate={`${url}/update`}
            />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

// EXPORT:

export default Planning;

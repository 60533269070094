import makeStyles from '@material-ui/core/styles/makeStyles';
import colors from '../../../../assets/style-colors';

const useStyles = makeStyles(() => ({
  right: {
    paddingRight: '20px',
    marginTop: '25px',

    '& .dropdown': {
      margin: '10px 0',

      '& .inputDropdown': {
        backgroundColor: colors.white,
        padding: '12px 6px',
        color: '#4E526A',
        border: 'none',
        fontSize: '12px',
        height: '40px',
        lineHeight: '1.4',
        borderRadius: '8px',
        '& .optionList': {
          color: '#4E526A',
        },
      },
    },

    '& .editButton': {
      width: '20px',
      alignSelf: 'flex-start',
      marginTop: '25px',
      marginLeft: 'auto',
      flex: '0 0 10%',
      maxWidth: '10%',
    },

    '& .donateList': {
      padding: '0 10px 25px',
      marginRight: 'auto',
      maxwidth: '80%',
      flex: '0 0 80%',
    },

    '& .donateListColor': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      flexDirection: 'column',
      flexWrap: 'wrap',
      maxWidth: '300px',
      padding: '13px 25px',
      marginTop: '12px',
      marginBottom: '12px',
      background: colors.paleGreyDrank,
      borderRadius: '2px',
    },

    '& .tag': {
      textTransform: 'uppercase',
    },

    '& .donateItem': {
      display: 'flex',
      flex: '0 1 auto',
      alignItems: 'flex-start',
      flexDirection: 'column',
      lineHeight: '1.5',
    },

    '& .donateItem .first': {
      fontSize: '13px',
      color: colors.dark,
    },

    '& .name-vehicle': {
      fontSize: '14px',
      color: colors.dark,
      fontWeight: 'bold',
    },

    '& .donateItem .second': {
      fontSize: '14px',
      color: colors.dark,
      fontWeight: 'bold',
    },

    '& .donateItem .second.text-wrap': {
      width: '220px',
      wordWrap: 'break-word',
    },

    '& .donateItem .third': {
      fontSize: '12px',
      lineHeight: '1.9',
      color: colors.dark,

      '& .ref': {
        wordBreak: 'break-all',
        display: 'flex',
        flexWrap: 'wrap',
        fontWeight: 'bold',
      },

      '& .mine': {
        maxHeight: '110px',
        overflowY: 'scroll',
      },
    },

    '& .donateItem .four': {
      fontSize: '13px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      lineHeight: '1.69',
      textTransform: 'uppercase',
      color: colors.dark,
    },

    '& .donateItem .five': {
      fontSize: '11px',
      fontWeight: 'normal',
      lineHeight: '1.45',
      color: colors.dark,
      marginBottom: '10px',
    },

    '& .donateItem .six': {
      fontSize: '11px',
      lineHeight: '1.45',
      color: colors.dark,
      marginBottom: '2px',
    },

    '& .donateItem *': {
      display: 'inline-block',
    },

    '& .leftIcon': {
      height: 'auto',
      width: '19px',
    },

    '& .donateItem .rightIcon': {
      height: '20px',
      width: '20px',
      marginLeft: '15px',
      cursor: 'pointer',
    },

    '& .donateItem .button': {
      marginTop: '15px',
      borderRadius: '4px',
      padding: '5px 15px',
      backgroundColor: colors.coral,
      fontSize: '13px',
      whiteSpace: 'nowrap',
      lineHeight: '1.2',
      color: 'white',
    },

    '& .diamond': {
      position: 'relative',
    },

    '& .diamondText': {
      width: '150px',
    },

    '& .history': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      flexDirection: 'column',
      flexWrap: 'wrap',
      width: '270px',
      padding: '13px 25px',
      marginTop: '12px',
      marginBottom: '12px',
      backgroundColor: colors.paleGreyDrank,
      borderRadius: '2px',

      '& .donateItem': {
        '& .four': {
          marginBottom: '40px',
        },
      },

      '& .quoteLabel': {
        textTransform: 'uppercase',
        padding: '4px 8px',
        borderRadius: '4px',
        fontSize: '11px',
        lineHeight: '1.27',
        color: colors.white,
      },

      '& .CONFIRMED': {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'unwrap',
        alignItems: 'baseline',
        marginBottom: '10px',

        '& .CONFIRMED': {
          background: colors.mediumGreen,
        },

        '& .in': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          paddingRight: '10px',
          width: '30px',

          '& .a': {
            width: '12px',
            height: '12px',
            borderRadius: '50%',
            background: colors.mediumGreen,
          },

          '& .b': {
            width: '5px',
            height: '5px',
            objectFit: 'contain',
            backgroundColor: colors.mediumGreen,
            position: 'absolute',
            transform: 'translate(3.5px, 3.5px)',
          },

          '& .c': {
            width: '2px',
            height: '74px',
            background: '#ced0da',
          },
        },

        '& .wrap': {
          display: 'flex',
          flexDirection: 'column',
          width: '190px',
          background: colors.white,
          borderRadius: '8px',
          padding: '10px 10px',
          boxShadow: '0 2px 18px 0 rgba(214,214,214,0.5)',
        },

        '& .background': {
          display: 'flex',
          flex: '100%',
          background: colors.white,
          fontSize: '11px',
        },

        '& .quote': {
          fontSize: '11px',
          lineHeight: '1.27',
          color: colors.dark,
          marginBottom: '5px',
        },

        '& .historyDate': {
          fontSize: '11px',
          fontWeight: 'normal',
          lineHeight: '1.09',
          color: colors.dark,
        },
      },

      '& .CANCELLED, .REPORTED, .VALIDATED, .POSTPONED, .WALLET': {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'unwrap',
        alignItems: 'baseline',

        '& .VALIDATED': {
          background: colors.azure,
        },

        '& .REPORTED': {
          background: colors.pomeGranate,
        },

        '& .WALLET': {
          background: colors.dark,
        },

        '& .POSTPONED': {
          background: colors.brightOrange,
        },

        '& .PERSO': {
          background: '#aaaec5',
        },

        '& .CANCELLED': {
          background: colors.dark,
        },

        '& .in': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          paddingRight: '10px',
          width: '30px',

          '& .a': {
            position: 'absolute',
            transform: 'translate(0px, -65px)',
            width: '2px',
            height: '34px',
            background: '#ced0da',
          },

          '& .b': {
            width: '12px',
            height: '12px',
            border: '1px solid #ced0da',
            borderRadius: '50%',
            marginBottom: '3px',
          },

          '& .c': {
            width: '2px',
            height: '74px',
            background: '#ced0da',
          },
        },

        '& .wrap': {
          display: 'flex',
          flexDirection: 'column',
          width: '190px',
          background: colors.white,
          borderRadius: '8px',
          padding: '10px 10px',
          boxShadow: '0 2px 18px 0 rgba(214,214,214,0.5)',
        },

        '& .background': {
          display: 'flex',
          flex: '100%',
          background: colors.white,
          fontSize: '11px',
        },

        '& .quotePerso': {
          textTransform: 'uppercase',
          padding: '4px 8px',
          borderRadius: '4px',
          fontSize: '11px',
          lineHeight: '1.27',
          color: colors.white,
        },

        '& .quote': {
          fontSize: '11px',
          lineHeight: '1.27',
          color: colors.dark,
          marginBottom: '5px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '174px',
          marginTop: '5px',
        },

        '& .historyDate': {
          fontSize: '11px',
          fontWeight: 'normal',
          lineHeight: '1.09',
          color: colors.dark,
        },
      },
    },
  },

  donateItemWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  suppliers: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '10px',
  },

  mineType: {
    marginRight: '5px',
  },

  all: {
    margin: '20px 0px 0px 0px',
    maxWidth: '270px',
  },
}));

export default useStyles;

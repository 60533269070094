import initState from '../initialState';

const planningReducer = (state = initState, action) => {
  switch (action.type) {
    case 'MODIF_MODE':
      return {
        ...state,
        modificationMode: action.payload
      };
    case 'MODIF_DAY':
      return {
        ...state,
        modificationDay: action.payload
      };
    case 'CLOSE_DAYS':
      return {
        ...state,
        closeDay: action.payload
      };
    case 'SET_ITEM':
      return {
        ...state,
        item: action.payload
      };
    case 'SET_CONGES':
      return {
        ...state,
        conges: action.payload
      };
    case 'SET_CONGES_SHOW':
      return {
        ...state,
        conges2show: action.payload
      };
    case 'SET_CONGES_MODIF':
      return {
        ...state,
        modificationConges: action.payload
      };
    case 'SET_DATA_SEMAINE':
      return {
        ...state,
        dataWeekAPI: action.payload
      };
    case 'SET_SEARCH_VALUE':
      return {
        ...state,
        searchForValue: action.payload
      };
    default:
      return state;
  }
};

export default planningReducer;

import moment from 'moment';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import HttpService from '../../../services/http-service';
import {
  Booking,
  BookingHistory,
  BookingVehicle,
  Customer,
  FixedPrice,
  Liquid,
  PartOperation,
  PartStatus,
  Quote,
  QuoteStatus,
  RepairTime,
} from './types';
import getCurrentBookingId from './utils';
import { Store } from '../../types';
import { setNotification } from '../global/actions';

import BookingRequest from '../../../services/http/Booking';
import { SeverityMessage } from '../global/types';

export const SET_BOOKING = 'SET_BOOKING';
export const SET_BOOKINGS = 'SET_BOOKINGS';
export const SET_BOOKINGS_RESULTS = 'SET_BOOKINGS_RESULTS';
export const SET_NEXT_BOOKINGS = 'SET_NEXT_BOOKINGS';
export const DISPLAY_BOOKING = 'DISPLAY_BOOKING';
export const UPDATE_PLANNING_MODE = 'UPDATE_PLANNING_MODE';
export const SET_CURRENT_DAY = 'SET_CURRENT_DAY';
export const SET_WEEK_VIEW = 'SET_WEEK_VIEW';
export const SET_BOOKING_HISTORIES = 'SET_BOOKING_HISTORIES';
export const SET_PLANNING_IS_LOADING = 'SET_PLANNING_IS_LOADING';

export const SET_SMS_IS_SENDING = 'SET_SMS_IS_SENDING';
export const SET_BOOKING_QUOTES = 'SET_BOOKING_QUOTES';
export const RESET_STORE = 'RESET_STORE';

export type ActionTypes =
  | { type: typeof RESET_STORE }
  | { type: typeof SET_BOOKING; payload: Booking }
  | { type: typeof SET_BOOKINGS; payload: Array<Booking[]> }
  | { type: typeof SET_BOOKINGS_RESULTS; payload: Booking[] }
  | { type: typeof SET_NEXT_BOOKINGS; payload: Booking[] }
  | { type: typeof DISPLAY_BOOKING; payload: boolean }
  | { type: typeof UPDATE_PLANNING_MODE; payload: boolean }
  | { type: typeof SET_CURRENT_DAY; payload: string }
  | { type: typeof SET_WEEK_VIEW; payload: boolean }
  | { type: typeof SET_BOOKING_HISTORIES; payload: BookingHistory[] }
  | { type: typeof SET_PLANNING_IS_LOADING; payload: boolean }
  | { type: typeof SET_SMS_IS_SENDING; payload: string }
  | { type: typeof SET_BOOKING_QUOTES; payload: Quote[] };

export const setBooking = (booking: Booking): ActionTypes => ({
  type: SET_BOOKING,
  payload: booking,
});

export const setBookingHistories = (
  bookingHistories: BookingHistory[]
): ActionTypes => ({
  type: SET_BOOKING_HISTORIES,
  payload: bookingHistories,
});

const setBookings = (bookings: Array<Booking[]>): ActionTypes => ({
  type: SET_BOOKINGS,
  payload: bookings,
});

const setBookingsResults = (bookings: Booking[]): ActionTypes => ({
  type: SET_BOOKINGS_RESULTS,
  payload: bookings,
});

const setNextBookings = (bookings: Booking[]): ActionTypes => ({
  type: SET_NEXT_BOOKINGS,
  payload: bookings,
});

export const displayBooking = (display: boolean): ActionTypes => ({
  type: DISPLAY_BOOKING,
  payload: display,
});

export const activeUpdatePlanningMode = (active: boolean): ActionTypes => ({
  type: UPDATE_PLANNING_MODE,
  payload: active,
});

export const setCurrentDay = (day: string): ActionTypes => ({
  type: SET_CURRENT_DAY,
  payload: day,
});

export const setWeekView = (display: boolean): ActionTypes => ({
  type: SET_WEEK_VIEW,
  payload: display,
});

export const setPlanningIsLoading = (isLoading: boolean): ActionTypes => ({
  type: SET_PLANNING_IS_LOADING,
  payload: isLoading,
});

export const setBookingQuotes = (quotes: Quote[]): ActionTypes => ({
  type: SET_BOOKING_QUOTES,
  payload: quotes,
});

export const setSmsIsSending = (plannigId: string): ActionTypes => ({
  type: SET_SMS_IS_SENDING,
  payload: plannigId,
});

const normalizeBookings = (bookings: Booking[]): Array<Booking[]> => {
  const planningWeek = [[], [], [], [], [], []];
  bookings.forEach((booking) => {
    const day: any[] = planningWeek[moment(booking.bookingDate).day() - 1];
    if (day) {
      day.push(booking);
    }
  });
  return planningWeek;
};

export const getBooking =
  (
    bookingId: string,
    success?: (booking: Booking) => void,
    failure?: (error: Error) => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) =>
    new BookingRequest()
      .getBookingById(bookingId)

      .then((booking: Booking) => {
        dispatch(setBooking(booking));

        success?.(booking);
      })
      .catch(failure);

export const setCurrentBookingHistory =
  (): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch, getState: () => Store) => {
    const currentBookingId = getCurrentBookingId(getState().planningStore);
    if (!currentBookingId) {
      return;
    }

    new BookingRequest()
      .getBookingHistoriesById(currentBookingId)

      .then((bookingHistories: BookingHistory[]) => {
        dispatch(setBookingHistories(bookingHistories));
      })
      .catch((e) => {
        setNotification({ severity: SeverityMessage.ERROR, message: e });
      });
  };

export const getBookings =
  (
    garageId: string,
    startDate: string,
    endDate: string
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(setBookings(normalizeBookings([])));
    dispatch(setPlanningIsLoading(true));
    const bookings = await new BookingRequest()
      .getBookings(garageId, startDate, endDate)
      .then(normalizeBookings)
      .catch((e) => {
        setNotification({ severity: SeverityMessage.ERROR, message: e });
        return [];
      });
    dispatch(setBookings(bookings));
  };

export const searchBookings =
  (
    garageId: string,
    query: string
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(setBookingsResults([]));
    dispatch(setPlanningIsLoading(true));
    const bookings = await HttpService.getClient()
      .get(`${process.env.REACT_APP_API_PLANNING_URL}/bookings`, {
        headers: { 'api-key': process.env.REACT_APP_API_PLANNING_KEY },
        params: {
          garageId,
          bookingId: query,
        },
      })
      // eslint-disable-next-line no-underscore-dangle
      .then((response) => response.data._embedded?.orders || []);

    dispatch(setBookingsResults(bookings));
  };

export const getNextBookings =
  (garageId: string): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    const bookings = await new BookingRequest()
      .getBookings(garageId, moment().format('YYYY-MM-DD'), undefined)
      // eslint-disable-next-line no-underscore-dangle
      .then((nextBookings: Booking[]) =>
        nextBookings.filter((b) => b.networkId !== 'GARAGE-OWNER')
      );

    dispatch(setNextBookings(bookings));
  };

type ErrorFn = (error: Error) => void;

export const updateQuoteStatus =
  (
    bookingId: string,
    quoteId: string,
    status: QuoteStatus,
    message: string,
    success: () => void,
    failure: ErrorFn
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    await HttpService.getClient()
      .patch(
        `${process.env.REACT_APP_API_PLANNING_URL}/bookings/${bookingId}/${quoteId}`,
        { status, message },
        {
          headers: { 'api-key': process.env.REACT_APP_API_PLANNING_KEY },
        }
      )
      .then((response) => response.data)
      .then(() => dispatch(getBooking(bookingId, success, failure)))
      .catch(failure);
  };

export const updateBookingDate =
  (
    bookingId: string,
    bookingDate: string,
    bookingDepositHour: string,
    success: () => void,
    failure: ErrorFn
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    const statusQuote = 'POSTPONED';
    const message = statusQuote;
    await HttpService.getClient()
      .patch(
        `${process.env.REACT_APP_API_PLANNING_URL}/bookings/${bookingId}`,
        { statusQuote, message, bookingDate, bookingDepositHour },
        {
          headers: { 'api-key': process.env.REACT_APP_API_PLANNING_KEY },
        }
      )
      .then((response) => response.data)
      .then(success, dispatch)
      .catch(failure);
  };

export const updateOperationInBookingQuote =
  (
    newOperation: PartOperation | RepairTime | Liquid | FixedPrice,
    quote: Quote
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const quotesList = getState().planningStore.currentBooking?.quotes;
    const quoteToUpdate = quotesList?.find(
      (currQuote) => currQuote.quoteId === quote.quoteId
    );

    if (!quoteToUpdate) {
      return;
    }

    quoteToUpdate.operations = quoteToUpdate?.operations.map(
      (currOperation) => {
        if (currOperation.operationId === newOperation.operationId) {
          return newOperation;
        }
        return currOperation;
      }
    );
    dispatch(setBookingQuotes(quotesList || []));
  };

export const updateBookingPartsOrdered =
  (
    booking: Booking,
    partsOrdered: PartStatus,
    success: () => void,
    failure: (error: Error) => void,
    finallyFn: () => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    const newBooking = {
      ...booking,
      partsOrdered,
    };
    dispatch(setBooking(newBooking));

    await HttpService.getClient()
      .patch(
        `${process.env.REACT_APP_API_PLANNING_URL}/bookings/${booking.bookingId}`,
        { partsOrdered },
        {
          headers: { 'api-key': process.env.REACT_APP_API_PLANNING_KEY },
        }
      )
      .then((response) => response.data)
      .then(success)

      .catch(failure)
      .finally(finallyFn);
  };
export const sendSms =
  (
    body: {
      booking_id: string;
      opening_hours: string[];
    },

    success: () => void,
    failure: (e: Error) => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async () => {
    await HttpService.getClient()
      .post(`${process.env.REACT_APP_API_SEND_SMS_URL}`, body, {
        headers: {
          'api-key': process.env.REACT_APP_API_CALENDAR_KEY,
        },
      })
      .then(() => {
        success();
      })
      .catch(failure);
  };

export const UpdateComment =
  (
    bookingId: string,
    comment: string,
    success: () => void,
    failure: (error: Error) => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(setPlanningIsLoading(true));
    await HttpService.getClient()
      .patch(
        `${process.env.REACT_APP_API_PLANNING_URL}/bookings/${bookingId}/comments`,
        { comment },
        {
          headers: { 'api-key': process.env.REACT_APP_API_PLANNING_KEY },
        }
      )
      .then((response) => response.data)
      .then(success)
      .catch(failure);
  };

export const updateCustomer =
  (
    bookingId: string,
    customer: Customer,
    success: () => void,
    failure: (error: Error) => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    const customerInfo = {
      first_name: customer.firstName,
      last_name: customer.lastName,
      email: customer.email,
      phone: customer.phone,
    };
    dispatch(setPlanningIsLoading(true));
    await HttpService.getClient()
      .patch(
        `${process.env.REACT_APP_API_PLANNING_URL}/bookings/${bookingId}/customers`,
        { ...customerInfo },
        {
          headers: { 'api-key': process.env.REACT_APP_API_PLANNING_KEY },
        }
      )
      .then((response) => response.data)
      .then(() => {
        success();
        dispatch(setPlanningIsLoading(false));
      })
      .catch(failure);
  };

export const updateVehicule =
  (
    bookingId: string,
    vehicle: BookingVehicle,
    success: () => void,
    failure: (error: Error) => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    const vehiclInfo = {
      vehicle_id: vehicle.vehicleId,
      registration_number: vehicle.registrationNumber,
      ...(vehicle.kilometers && {
        kilometers: Number(vehicle.kilometers),
      }),
      vin_code: vehicle.vinCode,
    };
    dispatch(setPlanningIsLoading(true));
    await HttpService.getClient()
      .patch(
        `${process.env.REACT_APP_API_PLANNING_URL}/bookings/${bookingId}/vehicles`,
        { ...vehiclInfo },
        {
          headers: { 'api-key': process.env.REACT_APP_API_PLANNING_KEY },
        }
      )
      .then((response) => response.data)
      .then(() => {
        success();
        dispatch(setPlanningIsLoading(false));
      })
      .catch(failure);
  };
export const updateVehiculeVin =
  (
    bookingId: string,
    vehicle: BookingVehicle,
    success: () => void,
    failure: (error: Error) => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    const vehiclInfo = {
      vin_code: vehicle.vinCode,
    };
    dispatch(setPlanningIsLoading(true));
    await HttpService.getClient()
      .patch(
        `${process.env.REACT_APP_API_PLANNING_URL}/bookings/${bookingId}/vehicles`,
        { ...vehiclInfo },
        {
          headers: { 'api-key': process.env.REACT_APP_API_PLANNING_KEY },
        }
      )
      .then((response) => response.data)
      .then(() => {
        success();
        dispatch(setPlanningIsLoading(false));
      })
      .catch(failure);
  };

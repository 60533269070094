import React, { InputHTMLAttributes, LegacyRef } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import colors from '../style-colors';
import InfoBubble from '../../atomic/components/atoms/infoBubble';

interface InputLabelProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  reference?: LegacyRef<HTMLInputElement>;
  width?: string;
  bubbleText?: string;
  theme?: string;
}

const InputLabel = ({
  disabled,
  children,
  reference,
  label,
  type,
  placeholder,
  className,
  value,
  onChange,
  name,
  min,
  onClick,
  onMouseOver,
  bubbleText,
  theme,
  defaultValue,
  onFocus,
  ...props
}: InputLabelProps) => {
  const useFormStyles = makeStyles({
    formcontrol: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'start',
      flexDirection: 'column',
      paddingTop: '12px',

      '& label': {
        height: '14px',
        fontSize: '12px',
        marginBottom: '10px',
        lineHeight: '1.17',

        color: colors.dark,
      },
      '& input': {
        width: (e: InputLabelProps) => e.width || '100%',
        height: '40px',
        borderRadius: '8px',
        backgroundColor: colors.white,
        padding: '9px 15px',
        fontSize: '13px',
        color: colors.dark,
        outline: '0',
        borderWidth: theme === 'dark' ? '1px' : '0px',
        border: theme === 'dark' ? `1px solid ${colors.paleFormGrey}` : '0',
      },
    },
    labelError: {
      display: 'block',
      minHeight: '16px',
      color: '#ff6363',
      fontSize: '11px',
      margin: '5px 0',
    },
  });

  const classes = useFormStyles(props);

  return (
    <div className={classes.formcontrol}>
      {label && (
        <label htmlFor="input">
          {label}
          {bubbleText && <InfoBubble infoText={bubbleText} />}
        </label>
      )}
      <input
        className={className}
        onFocus={onFocus}
        onMouseOver={onMouseOver}
        onClick={onClick}
        min={min}
        disabled={disabled}
        ref={reference}
        name={name}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        defaultValue={defaultValue}
      />
      <p className={classes.labelError}>{children}</p>
    </div>
  );
};

InputLabel.defaultProps = {
  label: '',
  reference: undefined,
  width: '100%',
  bubbleText: undefined,
  theme: 'light',
};

export default InputLabel;

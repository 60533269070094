import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import colors from '../style-colors';

interface CheckboxLargeProps {
  checked: boolean;
  onClose: () => void;
  onOpen: () => void;
}

const CheckboxLarge = ({ onClose, onOpen, checked }: CheckboxLargeProps) => {
  const useStyles = makeStyles(() => ({
    unchecked: {
      background: colors.lightGreyBlue,
      width: '19px',
      height: '19px',
      borderRadius: '4px',
      cursor: 'pointer',
    },
    checked: {
      background: colors.primary,
      width: '19px',
      height: '19px',
      borderRadius: '4px',
      cursor: 'pointer',
      color: colors.white,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    boxForCheck: {
      display: 'flex',
      alignItems: 'center',
      width: '51px!important',
      height: '25px',
      borderRadius: '6px',
      padding: '3px',
      background: colors.white,
      boxShadow: '0 2px 18px 0 rgba(214, 214, 214, 0.5)',
    },
  }));
  const classes = useStyles();

  return (
    <div
      className={classes.boxForCheck}
      style={{ justifyContent: !checked ? 'flex-start' : 'flex-end' }}
    >
      {checked ? (
        <div
          className={classes.checked}
          role="button"
          tabIndex={0}
          onClick={onClose}
          aria-label="close"
        >
          ✓
        </div>
      ) : (
        <div
          className={classes.unchecked}
          onKeyPress={() => {}}
          aria-label="open"
          role="button"
          tabIndex={0}
          onClick={onOpen}
        />
      )}
    </div>
  );
};

export default CheckboxLarge;

import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CheckboxLabel from '../../../assets/generic-components/CheckboxLabel';
import { Language } from '../../../redux/stores/languages';

interface AdditionalTyresOptionsProps {
  tyresOptions: Record<string, boolean>;
  setTyresOptions: (options: Record<string, boolean>) => void;
  lang: Language;
}

const useStyles = makeStyles(() => ({
  label: {
    marginTop: '30px',
    marginBottom: 0
  }
}));

const AdditionalTyresOptions: React.FC<AdditionalTyresOptionsProps> = ({
  tyresOptions,
  setTyresOptions,
  lang
}) => {
  const classes = useStyles();
  const toggleOption = (optionId: string) => {
    setTyresOptions({
      ...tyresOptions,
      [optionId]: !tyresOptions[optionId]
    });
  };

  return (
    <div>
      <p className={classes.label}>{lang.quote.tyres.options}</p>
      <CheckboxLabel
        checked={tyresOptions.runflat}
        onchange={() => toggleOption('runflat')}
        label={lang.quote.tyres.runflat}
      />
      <CheckboxLabel
        checked={tyresOptions.consolidated}
        onchange={() => toggleOption('consolidated')}
        label={lang.quote.tyres.reinforced}
      />
    </div>
  );
};

export default AdditionalTyresOptions;

import makeStyles from '@material-ui/core/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as ROUTES from '../../../../../router/routes';
import colors from '../../../../../assets/style-colors';
import clock from '../../../../../assets/style-elements/time-big.svg';
import piece from '../../../../../assets/style-elements/piece.svg';
import reviceved from '../../../../../assets/style-elements/icon-recived.svg';
import { setBooking } from '../../../../../redux/stores/planning/actions';
import { Booking, Quote } from '../../../../../redux/stores/planning/types';
import { getVehicleById } from '../../../../../redux/stores/quotes/actions';
import { Store } from '../../../../../redux/types';
import {
  getDuration,
  getLogo,
  getSpecificCommission,
  getTotals,
} from '../../../../../utils/booking';
import { CarService, Networks } from '../../../../../redux/stores/global/types';
import VehicleName from '../../../../../atomic/components/organisms/VehicleName';
import { COMMAND } from '../../../../../router/routes';
import { BookingType } from '../../../../../redux/stores/quotes/types';

interface DevisProps {
  data?: any;
  booking: Booking;
  opacity?: number;
  availableCarservices: Record<string, CarService>;
}

const Devis = ({
  data,
  opacity,
  booking,
  availableCarservices,
}: DevisProps) => {
  // CONSTANTS:
  const { quotes } = data;
  const time = booking.bookingDepositHour?.split('-')[0];
  const history = useHistory();
  const { vehicle } = booking;
  const firstName = booking.customer.firstName || '';
  const name = `${firstName} ${booking.customer.lastName}`;
  const { languageReducer } = useSelector((state: Store) => state);
  const lg = languageReducer.language;
  const dispatch = useDispatch();
  const notOrder = booking.partsOrdered === 'NOT_ORDERED';
  const ordered = booking.partsOrdered === 'ORDERED';

  // STYLES:
  const useStyles = makeStyles(() => ({
    root: {
      opacity,
      position: 'relative',
      height: '100%',
      minWidth: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      cursor: 'pointer',
      fontSize: '11px',
      padding: '10px',
      margin: '10px 10px 0 10px ',
      background: colors.white,
      borderRadius: '4px',
    },
    name: {
      display: 'block',
      whiteSpace: 'nowrap' /* forces text to single line */,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
    },
    header: {
      display: 'flex',

      justifyContent: 'space-between',
      borderBottom: `1px solid ${colors.lightGreyBlue}`,
      alignItems: 'center',
      paddingBottom: '7px',
      marginBottom: '7px',
    },
    footer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    image: {
      display: 'flex',
      justifyContent: 'right',
      alignItems: 'center',
      width: '70px',
      height: '20px',
    },
    devis: {
      width:
        window.location.pathname === ROUTES.PLANNING ||
        window.location.pathname === ROUTES.PLANNING_UPDATE
          ? '100%'
          : 'fit-content',
      color: colors.white,
      padding: '5px',
      margin: '5px 0 5px 0',
      textAlign: 'center',
      borderRadius: '4px',
    },
    infoPiece: {
      display: notOrder ? 'none' : 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: '10px 0 0',
      marginTop: '10px',
      borderTop: `1px solid ${colors.lightGreyBlue}`,
    },
    infoPieceIcon: {
      paddingRight: '5px',
    },
    info: {
      paddingLeft: '5px',
      fontWeight: 'bold',
    },
    quote: {
      marginTop: '0',
    },
    CONFIRMED: {
      backgroundColor: colors.mediumGreen,
    },
    VALIDATED: {
      backgroundColor: colors.azure,
    },
    REPORTED: {
      backgroundColor: colors.pomeGranate,
    },
    CANCELLED: {
      backgroundColor: colors.dark,
    },
    WALLET: {
      backgroundColor: colors.dark,
    },
    PERSO: {
      backgroundColor: colors.lightGreyBlue,

      '&$CANCELLED': {
        textDecoration: 'line-through',
      },
    },
    iconClock: {
      width: '11px',
      height: '11px',
      filter: 'grayscale(100%)',
      marginRight: '5px',
    },
    timeItem: {
      color: colors.slateGrey,
      fontSize: '11px',
    },
    timeHour: {
      color: '#4E526A',
      fontWeight: 700,
    },
    gender: {
      textTransform: 'capitalize',
    },
    nameItem: {
      color: colors.dark,
      fontSize: '11px',
      marginBottom: '5px',
    },
    imgItem: {
      height: '25px',
    },
    vehicle: {
      color: colors.dark,
      fontWeight: 'bold',
      marginBottom: '5px',
    },
    immat: {
      color: colors.dark,
      fontSize: '11px',
    },
    totalRate: {
      color: colors.dark,
    },
    timeWrapper: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 'bold',
    },
  }));
  const classes = useStyles();
  const pieces = {
    NOT_ORDERED: lg.command.partsOrdered.notOrdered,
    ORDERED: lg.command.partsOrdered.ordered,
    RECEIVED: lg.command.partsOrdered.deliveredOrdered,
  };
  const handleShow = (item: Booking) => {
    if (item.vehicle?.vehicleId) {
      dispatch(
        getVehicleById(
          item.vehicle.vehicleId,
          () => {},
          () => {}
        )
      );
    }
    dispatch(setBooking(item));
    history.push(COMMAND);
  };

  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = useCallback(() => {
    setWidth(window.innerWidth);
  }, [width]);

  const formatRegistrationNumber = (plate: string): string =>
    plate.replace(/[a-z](?=\d)|\d(?=[a-z])/gi, '$&-');

  const getCarServiceLabelById = (carServiceId: string) => {
    if (!availableCarservices || !availableCarservices[carServiceId]) {
      return null;
    }
    return availableCarservices[carServiceId].label;
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
  }, [handleWindowSizeChange]);

  const totalHours = quotes.reduce(
    (acc: number, q: Quote) => getDuration(q, true) + acc,
    0
  );

  const totalBooking = getTotals(
    quotes,
    booking.price.vat,
    booking.networkId !== Networks.IDG,
    getSpecificCommission(booking)
  );

  // HTML:
  return (
    <>
      <div
        className={`${classes.root} opacity`}
        role="button"
        tabIndex={0}
        onClick={() => handleShow(booking)}
      >
        <div className={classes.header}>
          {time && (
            <div className={classes.timeItem}>
              {lg.general.rdv} <span className={classes.timeHour}>{time}</span>
            </div>
          )}
          {booking.networkId !== 'GARAGE_OWNER' ? (
            <div className={classes.image}>
              <img
                className={classes.imgItem}
                src={getLogo(booking.networkId)}
                alt=""
              />
            </div>
          ) : null}
        </div>
        <div className={`${classes.name} ${classes.nameItem}`}>{name}</div>
        <p className={classes.vehicle}>
          {vehicle?.vehicleId && <VehicleName vehicleId={vehicle.vehicleId} />}
        </p>
        {vehicle?.registrationNumber && (
          <p className={classes.immat}>
            {lg.planning.immat} :{' '}
            {formatRegistrationNumber(vehicle.registrationNumber)}
          </p>
        )}
        {quotes.map((quote: Quote, index: number) => (
          <div
            key={`${quote.quoteId}`}
            className={`${classes.quote}`}
            style={{
              borderTop: index > 0 ? `1px solid ${colors.paleGrey}` : 'none',
            }}
          >
            <div
              className={`${
                booking.networkId === BookingType.GARAGE_OWNER && classes.PERSO
              } ${classes[quote.status]} ${classes.name} ${classes.devis} ${
                booking.networkId
              }`}
            >
              {getCarServiceLabelById(quote.carServiceId) ||
                lg.quote.prestation}
            </div>
          </div>
        ))}
        <div className={classes.footer}>
          <div className={classes.totalRate}>{`HT : ${
            totalBooking.totalBillingPrice.toFixed(2) || 0.0
          } €`}</div>
          <div className={classes.timeWrapper}>
            <img className={classes.iconClock} src={clock} alt=" " />

            <span> {` ${totalHours}${lg.general.shortHour}`}</span>
          </div>
        </div>
        {booking?.partsOrdered && (
          <div className={classes.infoPiece}>
            <>
              {ordered ? (
                <img src={piece} alt="" className={classes.infoPieceIcon} />
              ) : (
                <img src={reviceved} alt="" className={classes.infoPieceIcon} />
              )}

              <span className="info"> {pieces[booking?.partsOrdered]}</span>
            </>
          </div>
        )}
      </div>
    </>
  );
};

Devis.defaultProps = {
  data: [],
  opacity: 1,
};

// EXPORT:
export default Devis;

const descriptions = [
  {
    fr:
      'Hôpital intercommunal de Créteil.\nCentre hospitalier universitaire Henri-Mondor',
    en:
      'Intercommunal hospital of Créteil.\nHenri-Mondor University Hospital Center',
    nl:
      'Interkommunales Krankenhaus von Créteil.\nUniversitätsklinikum Henri-Mondor'
  },
  {
    fr:
      'Hôpital intercommunal de Créteil.\nCentre hospitalier universitaire Henri-Mondor',
    en:
      'For safety accessible to all, Norauto guarantees unbeatable prices on tires, oils, filters, brake pads, the bladder, car radios, spark plugs, and refunds the difference if you find cheaper elsewhere *.',
    nl:
      'Für die Sicherheit, die für alle zugänglich ist, garantiert SPEEDOTO unschlagbare Preise für Reifen, Öle, Filter, Bremsbeläge, die Blase, Autoradios, Zündkerzen und erstattet die Differenz, wenn Sie anderswo günstiger sind *'
  },
  {
    fr: 'SPEEDOTO vous garantie la qualité au meilleur prix.',
    en: 'SPEEDOTO guarantees you quality at the best price.',
    nl: 'SPEEDOTO garantiert Ihnen Qualität zum besten Preis.'
  },
  {
    fr:
      'Hôpital intercommunal de Créteil.\nCentre hospitalier universitaire Henri-Mondor',
    en:
      'Intercommunal hospital of Créteil.\nHenri-Mondor University Hospital Center',
    nl:
      'Interkommunales Krankenhaus von Créteil.\nUniversitätsklinikum Henri-Mondor'
  },
  {
    fr:
      'Hôpital intercommunal de Créteil.\nCentre hospitalier universitaire Henri-Mondor',
    en:
      'Intercommunal hospital of Créteil.\nHenri-Mondor University Hospital Center',
    nl:
      'Interkommunales Krankenhaus von Créteil.\nUniversitätsklinikum Henri-Mondor'
  },
  {
    fr:
      'Hôpital intercommunal de Créteil.\nCentre hospitalier universitaire Henri-Mondor',
    en:
      'Intercommunal hospital of Créteil.\nHenri-Mondor University Hospital Center',
    nl:
      'Interkommunales Krankenhaus von Créteil.\nUniversitätsklinikum Henri-Mondor'
  }
];

export default descriptions;

const lg = {
  locale: 'nl',
  lg: 'Nederlands',
  select: 'Selecteren...',
  error: {
    server: 'Er is een fout opgetreden, probeer het later nog eens',
    errorGarageUnauthorized: 'We hebben deze garage niet gevonden',
    errorGarageNotFound: 'De garage bestaat niet',
  },
  unauthorized:
    "Désolé, vous n'avez pas les droits pour accéder à ce service. Merci de vous déconnecter pour essayer avec un autre compte.",
  notyetavailable:
    'We werken aan deze pagina om u een optimale ervaring te bieden',
  returnOldInterface: 'Terug naar My.iDGARAGES V1',
  isLoading: 'Loading...',
  general: {
    yes: 'Ja',
    no: 'Niet',
    unknown: 'Onbekend',
    shortHour: 'u',
    ShortHour: 'U',
    back: 'Teruggave uiterlijk om 18u',
    type: 'Vervangwagen',
    payment: 'Gewenste betalingsfaciliteiten',
    nextDay: 'Teruggave uiterlijk de volgende dag om 18:00',
    rdv: 'AFS',
    fatalError: 'Sorry, er is een fout opgetreden. Probeer het nog eens.',
    confirm: 'Bevestigen',
    cancel: 'Annuleren',
  },
  header: {
    searchPlaceholder: 'Bestelnummer / offerte nummer / naam van de klant',
    contact: 'Neem contact met ons op',
    help: 'Hulp',
    language: 'Taal',
  },
  menuLeft: {
    planning: 'Planning',
    account: 'Mijn rekening',
    rates: 'Mijn tarieven',
    quote: 'Maak een offerte aan',
    visibility: 'Zichtbaarheid en verkoop',
    billing: 'Facturatie',
    basket: 'Autossimo',
    resetPassword: 'Paswoord wijzigen',
    command: 'Web afspraak bestelling',
    commandPerso: 'Persoonlijke afspraak bestelling',
    time: 'Mijn tijdschema',
    holidays: 'Voeg verlof toe',
  },
  signOut: {
    question: 'Bent u zeker dat u wilt uitloggen?',
    confirm: 'Bevestigen',
    cancel: 'Annuleren',
    changeGarage: 'Changer garage',
  },
  planningHeader: {
    back: 'Keer terug',
    planning: 'Planning',
    CONFIRMED: 'Gevalideerd',
    VALIDATED: 'Valideren',
    REPORTED: 'Lopende problemen',
    CANCELLED: 'Geannuleerd',
    printPlanning: 'Print het schema',
  },
  planning: {
    conflict: 'Not enough free hours in this day to create this booking.',
    title: 'Mijn typische week',
    week: 'Week',
    day: 'Dag',
    modify: 'week bewerken',
    addRDV: 'Voeg een afspraak toe',
    with: 'Met offerte',
    without: 'Zonder offerte',
    quote: 'OFFERTE',
    withoutDevis: 'OFFERTE TER PLAATSE',
    menuRight: {
      days: 'dagen',
      hours: 'uren',
      cars: 'voertuigen',
      infoDelay: 'minimale vertraging voor afspraak',
      infoWeekdays: 'beschikbaar per dag / week',
      infoWeekends: 'beschikbaar per dag / weekend',
      infoCars: 'van vervanging',
      button: 'Voeg verlof toe',
      months: [
        'Januari',
        'februari',
        'maart',
        'april',
        'mei',
        'juni',
        'juli',
        'augustus',
        'september',
        'oktober',
        'november',
        'december',
      ],
      congesTitle: 'Mijn vakanties',
      congesPeriod: 'Periode van',
      congesError:
        'Choisissez une date de la fin supérieure de la date de début de congés',
      congesClosed:
        "L'ensemble des jours seront fermés sur la période saisie sur votre planning",
    },
    weekdaysShort: ['M', 'D', 'W', 'D', 'V', 'Z', 'Z'],
    weekdays: ['Ma', 'di', 'wo', 'do', 'vr', 'za'],
    weekdaysFull: ['Maandag, dinsdag, woensdag, donderdag, vrijdag, zaterdag'],
    hours: 'Uren',
    closed: 'Gesloten',
    modification: {
      modify: 'Bewerken',
      closeWeek: 'Sluiten de hele week',
      modalClose: 'Bevestigt u de sluiting van de week?',
      modalReopen: 'Bevestigt u de heropening van de week?',
      modalDayClose: 'Bevestigt u de sluiting van de dag van',
      modalDayReopen: 'Bevestigt u de heropening van de dag van',
      confirm: 'Ja',
      cancel: 'Neen',
      openingHours: 'Openingstijden',
      pause: 'Lunchpauze',
      availableHours: 'Aantal beschikbare uren',
      congesPeriod: 'Vakantieperiode',
      addItem: 'Voeg een slot toe',
      delItem: 'Dit verlof verwijderen',
      closeAll: 'Volledige sluiting',
      saveButton: 'Wijzigingen opslaan',
      errorHours: 'De tijd is onjuist',
      conges: 'Er is al een verlof op',
      congesModif: 'Verlof wijzigen',
      semaineType: {
        openingHours: 'Open uren',
        daysOpenedForWeek: 'Open dagen per week',
        openingByDay: 'Open per dag',
        hours: 'Open uren',
        hoursCount: 'Vrije uren',
        header: 'Veranderen mijn typische week',
        delay: 'Minimale tijd voor een afspraak (geheel aantal dagen)',
        weekdays: 'Aantal uren per dag (week)',
        weekends: 'Aantal uren per dag (weekend)',
        cars: 'Aantal vervangwagen',
      },
    },
  },
  devis: {
    updateQuote: 'Wijzig het citaat',
    button: 'Bestelling bekijken',
    command: 'Bestellingsnr.',
    quote: 'OFFERTE Nr',
    sum: 'Bedrag ZBTW:',
    pricelessMessage:
      'Voor dit voertuig kan deze prestatie niet online worden berekend. U kunt echter doorgaan met het maken van een afspraak om deze aan uw planning toe te voegen.',
  },
  contact: {
    header: 'Neem contact met ons op',
    recipient: 'Ontvanger',
    message: 'Bericht',
    send: 'Sturen',
    qAtext: 'De antwoorden op uw vragen vindt u in onze rubriek',
    help: 'Hulp',
    byPhone: 'Telefonisch',
    info1: 'du lundi au vendredi : 9h-18h',
    info2: 'du lundi au vendredi : 9h-18h',
    numero1: '(+33)01.84.79.38.60',
    numero2: '(+33)01.84.79.38.60',
    destinataires: "L'équipe Ad.fr', ' L'équipe Idgarages.com",
  },
  visibilityHeader: {
    visibility: 'Zichtbaarheid',
    sale: 'Verkopen',
  },
  visibility: {
    title: 'Selecteer een periode',
    search: 'Zoeken',
    visible: {
      all: 'Alle',
      ad: 'AD.fr',
      idGarage: 'IDgarages.com',
      contact: 'Contactverzoeken',
      contactText:
        'Aantal automobilisten dat uw telefoonummer heeft aangevraagd',
      asset: 'Directoryweergaven',
      assetText: 'Aantal automobilisten dat uw garageadres heeft aangevraagd',
      compare: 'Offerteweergaven',
      compareText:
        'Aantal automobilisten  dat uw offertes heeft vergeleken met andere garages',
      browsed: 'Gedetailleerde informatieweergaven van uw garage',
      browseText: 'Aantal automobilisten dat uw garagevermelding heeft bekeken',
    },
    optionAll: 'elke maand',
  },
  billingHeader: {
    commission: 'Commissie per afspraak',
    voucher: 'Credits (Bon)',
    subscription: 'Abonnementen',
    additional_voucher: 'Regularisatie',
  },
  billing: {
    title: 'Selecteer een periode',
    search: 'Zoeken',
    commission: {
      context: 'Uitdaging',
      contest: 'Uitdaging',
      pdfDownload: 'Download als pdf',
      facture: 'Totale factuur',
      base: 'BTW-basis',
      taux: 'Tarief',
      tva: 'BTW €',
      total: 'Totaal betaling met BTW',
    },
    asset: {
      context: 'Uitdaging',
      contest: 'Uitdaging',
      pdfDownload: 'Download als pdf',
      facture: 'Totale factuur',
      base: 'BTW-basis',
      taux: 'Tarief',
      tva: 'BTW €',
      total: 'Totaal betaling met BTW',
      totalVoucher: 'Totaal betaling met BTW',
    },
    subscription: {
      context: 'Uitdaging',
      contest: 'Uitdaging',
      pdfDownload: 'Download als pdf',
      facture: 'Totale factuur',
      base: 'BTW-basis',
      taux: 'Tarief',
      tva: 'BTW €',
      total: 'Totaal betaling met BTW',
    },
    regularisation: {
      context: 'Uitdaging',
      contest: 'Uitdaging',
      pdfDownload: 'Download als pdf',
      facture: 'Totale factuur',
      base: 'BTW-basis',
      taux: 'Tarief',
      tva: 'BTW €',
      total: 'Totaal betaling met BTW',
    },
    contest: {
      title: 'Betwist de factuur',
      subtitle: 'KLANT',
      comment: 'Reactie betwisten',
      signal: 'Er is rekening gehouden met uw geschil',
      close: 'Sluiten de hele week',
      cancel: 'Uitdaging',
      back: 'Terug naar facturatie',
      error: 'Schrijf minder dan 360 tekens',
    },
    recap: 'Overzicht',
    rembourse: 'Terugbetaald',
    prelev: 'genomen ',
    total: 'Totaal',
    subscriptions: 'Abonnementen',
    prelevs: 'Verzameld',
  },
  helpHeader: {
    faq: 'FAQ',
    homeHelp: 'Home hulp',
    coupon: 'Bon',
    commission: 'Vooruitbetaling en commissie',
    premiumPiece: 'Premium onderdeel',
  },
  help: {
    helpHome: {
      title: 'Vind de antwoorden op uw vragen',
      coupon: 'Operatie Groupon - Hoe werkt het?',
      commission: 'Klantenvooruitbetaling en commissie - Hoe werkt het?',
      faq: 'Algemene FAQ',
      piece: 'Premium onderdeel',
      plus: 'Meer informatie gebruiksaanwijzing',
      questions: 'Zie alle vragen',
    },
    piece: {
      question: 'Gestelde vragen',
      answer: 'Kunt u geen antwoord vinden op uw vragen?',
      contact: 'Neem contact met ons op',
    },
    faq: {
      question: 'Gestelde vragen',
      answer: 'Kunt u geen antwoord vinden op uw vragen?',
      contact: 'Neem contact met ons op',
    },
    coupon: {
      question: 'Gestelde vragen',
      answer: 'Kunt u geen antwoord vinden op uw vragen?',
      contact: 'Neem contact met ons op',
    },
    commission: {
      question: 'Gestelde vragen',
      answer: 'Kunt u geen antwoord vinden op uw vragen?',
      contact: 'Neem contact met ons op',
    },
    account: {
      aideEngagement: 'Indiquez ici les engagements...... ',
      aidePointsForts: 'Voici quelques idées....',
    },
  },
  command: {
    back: 'Terug naar planning',
    piece: 'Onderdelen bestellen',
    print: 'Printen',
    regularisation: 'Regularisatie',
    commandNumber: 'Bestelnummer',
    commandToPrint: {
      command: 'Bestelling',
      registration: 'Registratie',
      vinCode: 'VIN Code',
      Minetype: 'Chassis nummer',
      cnit: 'CNIT',
      car: 'Ophalen van het geplande voertuig',
      total: 'Totale bestelling',
      totalOutDiscount: 'Totaal exclusief korting € ZBTW',
      totalDiscount: 'Totale korting € ZBTW',
      totalHT: 'TOTAAL € ZBTW',
      totalTVA: 'BTW €',
      billingTTC: 'Aan de klant te factureren bedrag € met BTW',
      billingMax: 'Bedrag te factureren aan de klant als max € met BTW.',
    },
    detail: {
      rdvWithout: 'Afspraak zonder offerte',
      issue: 'Om een ​​probleem te melden',
      rdvConfirm: 'Bevestig webafspraak',
      quoteCanceled: 'Bevestig webafspraak',
      quoteAsset: 'Hebbeding',
      canceled: 'Annuleer de dienst',
      noteModification: 'Afspraak notities',
      nodeRDV: 'Afspraak informaties',
    },
    autossimo: {
      title: 'Een onderdeel nodig?',
      subtitle: 'Bestel uw onderdelen online via Autossimo',
      piece: 'Onderdelen bestellen',
      delivery: 'Gegarandeerde levering !',
    },
    coupon: {
      attention:
        'LET OP: zorg ervoor dat u onderaan de factuur het kortingsbedrag met BTW vermeldt dat u wordt terugbetaald door &nbsp;',
    },
    total: {
      title: 'Totaal van de bestelling',
      totalHorsRemise: 'Totaal exclusief korting € ZBTW',
      totalAvecRemiseCoupon: 'Totaal € ZBTW met kortingsbon',
      totalHT: 'TOTAAL € ZBTW',
      tva: 'BTW €',
      montantFacturer: 'Aan de klant te factureren bedrag € met BTW',
      fourchette: 'Bedrag te factureren aan de klant als max € met BTW.',
      couponCode: 'Promotiecode',
      textLong:
        'Deze klant is geen mechanisch expert, maar hij heeft toch een voertuig dat behoeften heeft en hij kan een trouwe klant van uw garage worden! ',
      booking: `In dit geval, en in plaats van een prestatie te boeken die niet overeenkomt met de vastgestelde behoefte aan zijn auto, hebben wij hem toegestaan deze operatie te boeken zonder kostenraming.`,
      confirmBookingTitle: 'Wat te doen aan jouw kant?',
      confirmBooking:
        'U noteert de reservering, u valideert deze, u ontvangt de klant, u diagnosticeert en/of probeert het voertuig en u kunt een prijsopgave aan de klant formuleren vanuit uw deskundig oog!',
    },

    partsOrdered: {
      title: 'Vrijstaande stukken',
      notOrdered: 'Geen bestelling',
      ordered: 'Onderdelen besteld',
      deliveredOrdered: 'Onderdelen ontvangen',
    },
    lateral: {
      carCare: 'Omgaan met het voertuig',
      detailContact: 'Contactgegevens',
      btnSms: 'SMS voertuig klaar',
      titleModalSms: 'Informeer de klant',
      bodyModalSms:
        'Ik informeer mijn klant dat zijn voertuig klaar is om opgehaald te worden.',
      sendSms: 'SMS verzenden',
      validationSms: 'De SMS is verzonden naar uw klant.',
      smsAlreadySend: 'De SMS is al verzonden naar uw klant.',
      phoneNotValide: 'Telefoonnummer is ongeldig',
      question: 'Hebt u een vraag? Bel ons op 0033 1 84 79 38 60',
      vehicle: 'Voertuig',
      registration: 'Registratie',
      registrationVin: 'VIN number',
      mineType: 'Chassis nummer',
      cnit: 'CNIT',
      engine: 'Motorcode',
      fuel: 'Motorcode',
      comment: 'Brandstof',
      noComment: 'Geen commentaar',
      recommended: 'Premium uitrusting aanbevolen ',
      equipment: 'Geen uitrusting vereist',
      why: 'Waarom dit bedrag?',
      textlong:
        'Bij de reservatie van de klant gebruik gemaakt van een bon.\n' +
        '  Het bedrag van de bon wordt volledig aan u terugbetaald.\n' +
        '  Dus aan het einde van de prestatie betaalt de klant u\n' +
        '  bedrag weergegeven onder aan uw bestelling. ',
      total: 'Totaalbedrag',
      discount: 'Kortingsbon',
      bill: 'Het te betalen bedrag',
      commission: 'Garage commissie',
      subtitle: 'Voor deze bestelling wordt u gefactureerd van',
      invoice: 'ZBTW voor afspraak nr',
      prestation: 'als de klant een prestatie verricht',
      history: 'Historisch',
      historyDate: '04/01/2020 09:06:48',
      VALIDATED: 'Offerte in behandeling',
      CONFIRMED: 'Offerte gevalideerd',
      CANCELLED: 'Geannuleerd',
      WALLET: 'Avoir',
      REPORTED: 'Gerapporteerd probleem',
      POSTPONED: 'Uitgesteld',
    },
    appointment: {
      title: 'Wijzig de datum van de afspraak',
      confirm: 'Bevestiging van de wijziging van de afspraak',
      close: 'Sluiten',
      changedDate: 'Wijzig de datum van de afspraak',
      backCommand: 'Terug naar bestelling',
    },
    vehicle: {
      title: 'Voertuiginformatie wijzigen',
      confirm: 'Voertuiginformatie opslaan',
      close: 'Sluiten',
      vehicle: 'Voertuig',
      immatriculation: 'Registratie',
      kilometers: 'Mijlen',
      vinCode: 'Vin gecodeerd',
    },
    comment: {
      title: 'Opmerkingen bewerken',
      confirm: 'Bewaar opmerkingen',
      close: 'Sluiten',
      label: 'Klanten feedback',
    },
    user: {
      title: 'Contactgegevens wijzigen',
      confirm: 'Bewaar contactgegevens',
      close: 'Sluiten',
      firstName: 'Naam',
      lastName: 'Voornaam',
      phone: 'Telefoon',
      email: 'Mail adres',
    },
    cancelQuote: {
      title: 'Offerte annuleren',
      titleCanceled: 'Annuler la prestation',
      confirm: 'Bevestig de annulering van de offerte',
      confirmCanceled: "Veuillez confirmer l'annulation de la prestation",
      close: 'Sluiten',
      valid: 'Bevestigen',
    },
    issue: {
      title: 'Om een ​​probleem te melden',
      select: 'Selecteer het probleem',
      number: 'Prijsopgave',
      planning: 'Planningsprobleem',
      other: 'Andere',
      subtitle:
        'Specificeer de aard van de fout en specificeer het nieuwe bijbehorende tarief',
      confirm: 'Uw probleem is gemeld.',
      close: 'Sluiten',
      signal: 'Om een ​​probleem te melden',
      back: 'Terug naar bestelling',
      error: 'Schrijf minimaal 10 karakters',
      details: {
        advertiser:
          'De coupons worden volledig gedekt en vergoed door iDGARAGES.  \n' +
          'Geen enkel gemeld probleem kan de dag of de dag voor de afspraak worden behandeld.',
        planning: 'Heb je gecontroleerd of je planning up to date is? ',

        params:
          'Bij de berekening van de voorgestelde schatting wordt rekening gehouden met uw instellingen en met de aanbevelingen van de fabrikant. ',
      },
    },
    note: {
      title: 'Notitie bewerken',
      subtitle: 'Notities van de afspraak',
      close: 'Sluiten',
      changedNote: 'Notitie bewerken',
      error: 'Schrijf minimaal 10 karakters',
    },
    refund: {
      title: 'Bestelling hebben',
      subtitle: 'Terugbetaling in de vorm van een creditnota?',
      close: 'Sluiten',
      cancel: 'Notitie bewerken',
    },
    webAppointment: {
      title: 'Bevestigen de webafspraak',
      subtitle: 'Bevestigen de webafspraak',
      close: 'Sluiten',
      confirm: 'Bevestigen',
    },
  },
  rates: {
    title: 'Diensten aangeboden',
    labourCost: 'Arbeidstarief T1, T2, T3',
    pourcentage: 'Pas op deze tarieven een aanpassingspercentage toe ',
    taux1: 'Mechanisch tarief 1 (T1)',
    taux2: 'Mechanisch tarief 2 (T2)',
    taux3: 'Mechanisch tarief 3 (T3)',
    register: 'Wijzigingen opslaan',
    designation: 'Beschrijving',
    form: 'Uw formule',
    setting: 'Instellingen',
    clutch: 'Koppeling',
    preferences: 'Online offertevoorkeuren',
    service:
      'Ik bied deze prestatie aan: prijs van onderdelen (leverancier of fabrikant) en',
    help: 'Hulp',
    helps: 'Hulpen',
    internet: 'Ik wil deze prestatie niet op internet aanbieden ',
    prestation: 'De prestatie omvat',
    equipment: 'Leverancier',
    equipmentTitle: 'Kies uw leverancier',
    adjust: 'Aanpassing op onderdelenprijzen ',
    piece: 'Aanpassing percentage op PREMIUM algemene prijzen *',
    level: 'Nivellering',
    oil: 'Versnellingsbakolie *',
    forfaitPres: 'Bijbehorende servicepakketten',
    forfaitLiquide: 'Vervangingspakket hydraulische vloeistof *',
    forfaitClean: 'Reinigingspakket *',
    try: 'Testrit',
    save: 'Inschrijven',
    close: 'Sluiten',
    facturation: 'Factuur adres',
    address: 'Factuuradres hetzelfde als het adres van de garage',
    addAddress: 'Voeg een factuuradres toe',
    addRib: 'Upload bank informatie',
    bankInformation: 'Bankgegevens',
    iban: 'IBAN',
    messageIban: 'SIRET niet vermeld',
    bic: 'BIC',
    messageBic: 'Ongeldige BIC',
    change: 'Als u uw bankgegevens wijzigt, stuur ze ons dan toe.',
    requiredField: 'Dit veld is verplicht',
    validPostal: 'Ongeldige postcode',
    unknownPostal: 'Onbekende postcode',
    character: 'Het veld moet minimaal 3 karakters bevatten!',
    condition: 'Accepteer de algemene verkoopvoorwaarden',
    description: 'Beschrijving',
    learn: 'Onderwijst',
    commitments: 'Onze verplichtingen',
    lastname: 'Naam*',
    firstname: 'Voornaam*',
    garage: 'Garage naam *',
    email: 'E-mail adres *',
    oldPassword: 'Oud paswoord*',
    passwords: 'Paswoord*',
    newPassword: 'Nieuw paswoord*',
    phone: 'Vaste lijn',
    portable: 'Mobiele telefoon',
    siret: 'SIRET niet vermeld',
    rcs: 'CR',
    addressStreet: 'Adres*',
    additionalAddress: 'Extra adres',
    postal: 'Postcode*',
    city: 'Stad*',
    changeBackup: 'Wilt u uw wijzigingen opslaan',
    backup: 'Inschrijven',
    registered: 'Registreren',
    confirmPassword: 'Bevestig paswoord*',
    saveModification: 'Jullie hebben niet-opgeslagen wijzigingen',
    informationGarage: 'Garage informatie',
    password: 'Verander mijn paswoord',
    garageAddress: 'Garage adres',
    country: 'Land *',
    next: 'Naast*',
    charter: 'Valideer het kwaliteitscharter',
    messageMail: 'Ongeldig e-mailadres',
    messagePassword:
      'Uw paswoord moet minimaal 8 tekens bevatten, een hoofdletter, een cijfer',
    difference: 'De twee paswoorden zijn verschillend',
    messagePhone: 'Ongeldig telefoonnummer',
    messageSiret: 'Ongeldig SIRET-nummer',
    messageRCS: 'Ongeldig CR-nummer',
    messagePostal: 'Ongeldige postcode',
    break: 'Lunchpauze voor elke dag',
    noBreak: 'Geen lunchpauze',
    checkSchedules: 'Controleer uw openingsuren',
    services: 'Diensten en certificaten',
    servicesPlus: 'Extra diensten',
    minLength: 'Le champ doit contenir au moins {{min}} caractères !',
    maxLength: 'Le champ doit contenir au maximum {{max}} caractères !',
    exactLength: 'Le champ doit contenir {{length}} caractères !',
  },
  quote: {
    back: 'Ga terug',
    backPlanning: 'Keer terug naar de planning',
    create: 'Maak een offerte aan',
    addQuote: 'Maak een afspraak met offerte',
    addQuoteWithoutQuote: 'Een afspraak toevoegen zonder offerte',
    immat: 'Registratie',
    print: 'Printen',
    type: 'MINI TYPE',
    code: 'MOTOR CODE',
    fuel: 'Brandstof',
    flexHomologation: 'Goedkeuring voor ethanol kit',
    intermediateReview: 'Révision intermédiaire',
    attention:
      'LET OP: Voor deze webafspraak hebben we verschillende mogelijke fabrikantreferenties geïdentificeerd.\n' +
      'U moet ervoor zorgen dat de aangegeven referentie overeenkomt met die op het voertuig van de klant.De onderstaande gedetailleerde offerte is gebaseerd op het minimumbereik. ',
    designation: 'Aanwijzing',
    unitHT: 'Prijs per stuck € ZBTW',
    quantity: 'Hoeveelheid of arbeid',
    discount: 'Korting online',
    ht: 'Totale prijs € ZBTW',
    ttc: 'Totale prijs € met BTW',
    total: 'Totale bestelling',
    totalOutDiscount: 'Totaal exclusief korting € ZBTW',
    totalDiscount: 'Totale korting € ZBTW',
    totalHT: 'Totaal € ZBTW',
    tva: 'BTW €',
    billingTTC: 'Aan de klant te factureren bedrag € met BTW',
    billingMax: 'Bedrag te factureren aan de klant als max € met BTW.',
    previous: 'Vorig',
    rdv: 'Afspraken maken',
    validedRDV: 'Bevestig de afspraak',
    validRDV: 'Bevestig de afspraak',
    dayOfRDV: 'Afspraak van de dag,',
    noteRDV: 'Afspraak noteren',
    timeOfRDV: 'Afspraak tijd (uur)',
    hour: 'uren',
    prestation: 'Diensten aangeboden',
    lastName: 'Klantnaam *',
    messageField: 'Dit veld is verplicht',
    firstname: 'Voornaam klant',
    requiredFirstname: 'Voornaam klant *',
    requiredEmail: 'Klanten email',
    email: 'Klanten email',
    messageEmail: 'Ongeldig e-mailadres',
    phone: 'Telefoonnummer van de klant',
    requiredPhone: 'Telefoonnummer van de klant *',
    messagePhone: 'Ongeldig telefoonnummer',
    command: 'Bestelling hebben',
    confirmation: 'Bevestiging van de afspraak',
    selectedCar: 'Voertuigselectie',
    registration: 'Registratie',
    registrationLabel: 'registratie',
    registrationLabelCodeVin: 'VIN number',
    model: 'Model',
    brand: 'Merk',
    engine: 'Motorisering',
    choice: 'Kies een of meer diensten',
    date: 'Ophaaldatum en tijd',
    search: 'Zoeken',
    emptySearch: 'Geen resultaat',
    save: 'Opnemen',
    next: 'Volgende',
    download: 'Downloaden',
    discountPart: 'ANNULERING VOOR VORDERING (Delen)',
    discountRepairTime: 'ANNULERING VOOR VORDERING (Arbeid)',
    releaseDate: 'Datum van het in het verkeer brengen',
    releaseDateError: 'Ongeldig datumformaat',
    kmError: 'De waarde moet liggen tussen 1 en 999999',
    km: 'Kilometerstand',
    carMaintenanceInfo:
      'U heeft "Fabrieksrevisie" geselecteerd. De kosten van deze dienst vereisen een kilometerstand en een datum van omloop, gelieve deze informatie te verstrekken.',
    carMaintenanceInfoReleaseDate:
      'Datum van registratie bekend voor deze registratie :',
    vinCode: 'VIN Code',
    labelExtraKickstarter: 'Supplement voor kaarsen',
    labelExtraOil: 'Olietoeslag Niet inbegrepen',
  },
  search: {
    result: 'Zoekresultaten',
    emptyResult: 'Geen resultaten voor',
    quote: 'Offerte in behandeling',
  },
  pdf: {
    availability:
      'Geschatte schatting - De definitieve validatie, inclusief de nodige  gedetailleerde services, wordt gegeven na aankomst van het voertuig',
    command: {
      back: 'Ophalen van het geplande voertuig Restitutie uiterlijk op',
      alert:
        'LET OP: Voor deze webafspraak hebben we verschillende mogelijke fabrikantreferenties geïdentificeerd.\n' +
        'U moet ervoor zorgen dat de aangegeven referentie overeenkomt met die op het voertuig van de klant.\n' +
        'De onderstaande gedetailleerde offerte is gebaseerd op het minimumbereik. ',
      command: 'Bestelling hebben',
      designation: 'Aanwijzing',
      price: 'PPrijs per stuck € ZBTW',
      qty: 'Hoeveelheid of arbeid',
      discount: 'Korting online',
      ht: 'Totale prijs € ZBTW',
      ttc: 'Totale prijs € met BTW',
      total: 'Total de la commande',
      totalOutDiscount: 'Total hors remise € HT',
      totalDiscount: 'Total remise € HT',
      totalHT: 'TOTAL € HT',
      totalTVA: 'TVA €',
      billing: 'Montant à facturer au client € TTC',
      billingMax: 'Montant à facturer au client si fourchette max € TTC',
    },
    commission: {
      best: 'DE BESTE GARAGES VOOR DE BESTE PRIJZEN',
      facturation: 'Factuur',
      deliveryCustomer: 'Klant geleverd',
      billingCustomer: 'Klant gefactureerd',
      referenceCustomer: 'Uw Referentie',
      paymentCustomer: 'Con. betaling / betalingswijze',
      sampleCustomer: 'AUTOMATISCHE MONSTERNEMING',
      accounting: 'BOEKHOUDAFDELING',
      deliveryAddress: 'Leveringsadres',
      billingAddress: 'Factuur adres',
      ref: 'Bestel Ref',
      designation: 'Aanwijzing',
      ht: 'Bedrag ZBTW',
      attention: 'LET OP',
      textFooter1:
        '• Afspraken die niet worden nagekomen, moeten voor de 15e van de maand aan iDGARAGES worden gemeld. Na deze datum kan er geen terugbetaling plaatsvinden.',
      textFooter2:
        '• Voor onbetaalde schulden wordt een forfaitaire schadevergoeding betaald.',
      textFooter3:
        'Bij niet tijdige betaling, vaste vergoeding voor herstelkosten: 40 €',
      textFooter4: 'Geen korting bij vooruitbetaling',
      textFooter5:
        'Volgens de LME van 04/08/08 zal een gemiste deadline automatisch, zonder voorafgaande kennisgeving, aanleiding geven naast de kosten',
      textFooter6:
        'tot een boete voor te late betaling tegen het door de ECB in rekening gebrachte rentepercentage, verhoogd met 10 punten',
      totalArticle: 'Totaal artikelen',
      totalTVA: 'Totaal ZBTW',
      tva: 'BTW-basis',
      taux: 'Tarief',
      billingTVA: 'BTW-bedrag',
      totalTTC: 'Totaal te betalen inclusief BTW',
      couponTitle: 'Bon moet worden geretourneerd met betaling',
      couponCustomer: 'Klantnummer',
      couponFacturation: 'Factuurnummer',
      couponBilling: 'Bedrag met BTW',
      title: 'Uittreksel uit de algemene verkoop en gebruiksvoorwaarden ©',
      subtitle:
        'De volledige versie van de AVG is beschikbaar op de iDGARAGES.com-website. De AVG werd naar behoren aanvaard door de ledengarage.',
      cgvu: 'Het doel van deze AVG is het definiëren van de voorwaarden voor het verlenen van een contactdienst tussen automobilisten (onverschilling " een klant iDGARAGES.com" of "automobilist gebruiker") op zoek naar een serviceprovider voor het uitvoeren van auto-onderhoud en reparatiediensten en garages presenteren hun aan biedingen en diensten (individueel "een of de ledengarage" en gezamenlijk " de ledengarages"). Deze AVG worden indien nodig aangevuld of gewijzigd, door specifieke gebruiksvoorwaarden die specifiek zijn voor bepaalde functionaliteiten. ',
      titleCGVU: '1. AANVAARDING VAN DE AVG',
      textCGVU:
        'De ledengarage verklaart en erkent dat hij alle voorwaarden van deze algemene voorwaarden heeft gelezen. Bovendien, verbinding met een van de diensten die op de site worden aangeboden\n' +
        'toegankelijk op het adres: www.iDGARAGES.com (hierna de website www.iDGARAGES.com) via het speciale en beveiligde gebied, toegankelijk met een identificatie en een paswoord (“Garage Area”) impliceert de onvoorwaardelijke aanvaarding door de ledengarage van deze algemene voorwaarden.\n' +
        'DIGITAL AFTERMARKET behoudt zich het recht voor om deze Algemene Voorwaarden te allen tijde geheel of gedeeltelijk te wijzigen. Het is daarom de verantwoordelijkheid van de gebruikersgarage om te raadplegen\n' +
        'regelmatig wordt de laatste versie van de AVG gepost op het adres http://www.idgarages.com/166-AVG-garages. De ledengarage wordt geacht deze laatste versie te accepteren telkens wanneer deze nieuw is verbinding op de site, op de daarvoor bestemde ruimte. […] ',
      titleService: '3. TOEGANG TOT SERVICE VOOR DE LEDENGARAGE',
      textService:
        'Toegang tot de professionele interface iDGARAGES.com: "GARAGE AREA" - DIGITAL AFTERMARKET biedt de ledengarage een professionele interface waarmee het hun  \n' +
        '"iDGARAGES.com" planning en hun aanbod, prijzen, iDGARAGES.com en beschrijvende informatie over de garage configureren {...}  \n' +
        ' c.Online afspraakboekingservice - De automobilist kan online een afspraak maken in de ledengarage die hij heeft gekozen om een onderhoud prestatie te maken. \n' +
        'iDGARAGES.com afspraken die namens de ledengarage worden gehouden, worden geacht te zijn bevestigd door de ledengarage,  \n' +
        'zonder dat DIGITALE AFTERMARKET contact hoeft op te nemen met de ledengarage vooraf. Behalve in uitzonderlijke en gerechtvaardigde gevallen,  \n' +
        'kan de ledengarage de iDGARAGES.com afspraak niet wijzigen of weigeren. De ledengarage wordt op de hoogte gesteld van een reservering,  \n' +
        'a wijziging of annulering van een afspraak in zijn garage per e-mail en eventueel per telefoon, \n' +
        'sms, fax of ander contactmiddel. De ledengarage stemt ermee in om zijn e-mails minstens één keer per werkdag te controleren. \n' +
        'Behalve in uitzonderlijke en gerechtvaardigde gevallen (bijvoorbeeld door een onderbreking van de internettoegang),  \n' +
        'vormt de e-mail het enige bewijs van ontvangst en informatie over de afspraakreservering en de service automatisch aangevraagd door iDGARAGES.com klant. \n' +
        'Voor individuele diensten verbindt de ledengarage zich ertoe de door de klant van iDGARAGES.com gereserveerde dienst uit te voeren op de dag van de overeengekomen afspraak. \n' +
        'Ten laatste het voertuig wordt om 18:00 uur geretourneerd aan de klant van iDGARAGES.com. \n' +
        'De ledengarage en de iDGARAGES.com klant kunnen voor 18:00 uur een retourplanning afspreken. \n' +
        'Bij wijze van uitzondering kunnen de ledengarage en de iDAGRAGES.com klant overeenkomen om de afspraak met enkele dagen uit te stellen. \n' +
        'Voor complexe diensten verbindt de ledengarage zich ertoe de door de klant van iDGARAGES.com gereserveerde dienst binnen 48 uur na de overeengekomen afspraak uit te voeren. \n' +
        'Als de onderdelen die nodig zijn voor de interventie niet beschikbaar zijn op de dag van de afspraak, moet de ledengarage de iDGARAGES.com klant zo snel mogelijk en voor de geplande datum van afspraak, om een nieuwe afspraakdatum in te stellen. Als de afspraak wordt geannuleerd door de iDGARAGES.com-klant of  \n' +
        'als de iDGARAGES.com-klant niet komt opdagen voor de afspraak, moet de ledengarage zo snel mogelijk informeren DIGITALE AFTERMARKET en uiterlijk binnen 3 werkdagen na de initiële afspraakdatum. Na verificatie zal DIGITAL AFTERMARKET de ledengarage niet factureren voor geannuleerde of niet nagekomen afspraken. \n' +
        'Na een periode van 3 werkdagen kan DIGITAL AFTERMARKET de commissies die verschuldigd zijn op deze afspraken factureren. DIGITAL AFTERMARKET draagt geen verantwoordelijkheid in het geval van een afspraak geannuleerd of niet nagekomen door een iDGARAGES.com-klant. \n' +
        'De ledengarage kan er geen aanvragen vergoeding aan DIGITAL AFTERMARKET in het geval van een afspraak geannuleerd of niet nagekomen door een iDGARAGES.com-klant. d. \n' +
        'Bied voorstel en prijsofferte aan {...} \n' +
        'DIGITAL AFTERMARKET biedt op haar website www.iDGARAGES.com geschatte offertes aan voor diensten die worden berekend op basis van een grote hoeveelheid opgeslagen technische informatie naar databases van externe databaseproviders. Deze informatie wordt met de grootst mogelijke professionaliteit samengesteld en gebruikt.  \n' +
        'In sommige gevallen kan het berekende bedrag onjuist blijken te zijn en dit zal op geen enkele manier het gevolg zijn van DIGITALE AFTERMARKET,die de ledengarage herkent en accepteert. De leden garage doet afstand van alles doe een beroep op DIGITAL AFTERMARKET over dit onderwerp.   \n' +
        'In het geval van een onjuist bedrag, zal de ledengarage de automobilist onmiddelijk en rechtstreeks informeren en met hem de eerlijke prijs voor de gewenste service overeekomen. De automobilist kan, indien hij dit wenst, dit new voorstel weigeren en zijn reservering kosteloos annuleren.  \n' +
        'DIGITAL AFTERMARKET kan niet verantwoordelijk worden gehouden gevolgen van deze annulering of wijziging van het voorstel. e.  \n' +
        'Beschrijvende informatiedienst van de ledengarage. De site www.iDGARAGES.com biedt de ledengarage om zijn garage te benadrukken door de mogelijkheid aan te bieden, via de "GARAGE AREA", sectie "Mijn account",om zijn bestand te configureren beschrijvend. De meest beschrijvende informatie zal beschikbaar zijn voor \n' +
        'klanten van iDARAGES.com. De ledengarage verbindt zich ertoe dit blad objectief en feitelijk in te vullen en indien nodig bij te werken.  \n' +
        "De ledengarage, als deze deel uitmaakt van een netwerk van garages, erkent de rechten te hebben om de merken en logo's van zijn netwerk te gebruiken. \n" +
        "De ledengarage geeft uitdrukkelijk toestemming voor DIGITAL AFTERMARKET om deze merken en logo's alleen te gebruiken om het te benadrukken op www.iDGARAGES.com,vreedzaam gebruik van genoemde gebruiksrechten. De ledengarage is formeel verboden: - om concurrerende merken te citeren - om merken, \n" +
        "afbeeldingen, logo's te gebruiken waarvoor het niet de rechten heeft om te gebruiken - om de iDGARAGES.com service te denigreren - om een directe verbinding aan te bieden tussen de iDGARAGES.com klant en de ledengarage - om de andere garages te denigreren - om links en / of adressen van  \n" +
        'internetsites in te voegen - telfoon en / of faxnummers invoegen - om opmerkingen te maken of lasterlijke, beledigende, discriminerende,onwettige inhoud in te voegen, in strijd met de openbare orde en fatsoen. De ledengarage is als enige verantwoordelijk teksten en informatie op het beschrijvingsblad.  \n' +
        'DIGITAL AFTERMARKET behoudt zich het recht voor om alle of een deel van de teksten en informatie die door de ledengarage op het beschrijvingsblad worden verstrekt,te modereren, corrigeren, verwijderen, herformuleren. In dit geval wordt de ledengarage per e-mail geinformeerd. \n' +
        'DIGITAL AFTERMARKET behoudt zich het recht voor om een lid van een garage die zich hier niet aan houdt eenzijdig uit te schrijven voorzieningen. {...} \n',
      titleChart:
        '4. FINANCIËLE VOORWAARDEN, BETALINGSVOORWAARDEN EN WEDSTRIJDEN',
      textChart:
        'Elke garage die vermeld wil worden op www.iDGARAGES.com moet zich zonder uitzondering of zonder voorbehoud houden aan het kwaliteitscharter dat van toepassing is op garages die lid zijn van iDGARAGES.com. \n' +
        'Dit charter heeft betrekking op kwaliteitsverbintenissen waarop de ledengarage zal worden beoordeeld door zijn klanten van iDGARAGES.com.\n' +
        'Dit kwaliteitscharter is beschikbaar op het volgende adres: http://www.idgarages.com/21-kwaliteitcharter, evenals in het GARAGE AREA onder "Mijn account". In het bijzonder moet de ledengarage de verzekeringen afsluiten die nodig zijn voor de behoeften.',
      titleCondition:
        '7. CONDITIONS FINANCIÈRES, MODALITÉS DE PAIEMENT ET CONTESTATIONS',
      textCondition:
        'Er zijn geen registratiekosten voor de service www.iDGARAGES.com voor de ledengarage. Een verplicht zichtabonnement van € 15 exclusief belastingen per maand is verplicht vanaf eerste maand van registratie. Voor elke afspraak wordt een commissie in rekening gebracht op de volgende grondslagen:\n' +
        'Voor elke afspraak die namens de ledengarage wordt gemaakt, zal deze laatste DIGITALE AFTERMARKET vergoeden met een variabele commissie volgens het totale bedrag van de offerte gereserveerd door de\n' +
        'klant van iDGARAGES.com: 10% van het bedrag van de gereserveerde offerte.\n' +
        'Dit bedrag kan worden gewijzigd door DIGITAL AFTERMARKET, die de ledengarage uitdrukkelijk erkent en accepteert.\n' +
        'DIGITAL AFTERMARKET factureert aan het begin van de maand de commissies op de afspraken die de vorige maand zijn gereserveerd, evenals het abonnement.\n' +
        'Facturen zullen uiterlijk op de 5e van de maand worden opgesteld en beschikbaar zijn op zijn "GARAGE AREA" op het tabblad facturering. De domiciliëring vindt plaats vanaf de 15e van de maand (ofeerste werkdag na de 15e als het een feestdag is / niet gewerkt). Een enkele factuur bevat alle commissies voor de verzonden afspraken en de afgesloten abonnementen.\n' +
        'Alleen betaling via SEPA-machtiging voor automatische incasso is toegestaan. Bij betaling per cheque of overschrijving kan DIGITAL AFTERMARKET extra verwerkingskosten in rekening brengen€ 50 ZBTW per factuur ',
      footer1:
        'DIGITAL AFTERMARKET – 22, AVENUE ARISTIDE BRIAND – FR 94110 ARCUEIL',
      footer2:
        'TEL : 0033 1 69 79 89 89 – FAX : 0033 1 69 79 89 90 – www.iDGARAGES.com Société à responsabilité limitée au capital de 1 750 000 €',
      footer3:
        'CR CRETEIL B 351 126 438 – SIRET 351 126 438 00057 – Code APE 6311Z – Numéro de TVA : FR35 351126438 00058',
    },
    avoirAdditional: {
      best: 'DE BESTE GARAGES VOOR DE BESTE PRIJZEN',
      avoir: 'Krediet',
      deliveryCustomer: 'Klant geleverd',
      billingCustomer: 'Klant gefactureerd',
      referenceCustomer: 'Uw referentie',
      paymentCustomer: 'Con. betaling / betalingswijze',
      sampleCustomer: 'AUTOMATISCHE MONSTERNEMING',
      accounting: 'BOEKHOUDAFDELING',
      deliveryAddress: 'Leveringadres',
      billingAddress: 'Factuur adres',
      designation: 'Beschrijving',
      ht: 'Bedrag ZBTW',
      avoirAdd: 'Extra krediet',
      totalArticle: 'Totaal artikelen',
      totalTVA: 'Totaal ZBTW',
      tva: 'BTW-basis',
      taux: 'Tarief',
      billingTVA: 'BTW-bedrag',
      totalTTC: 'Totaal te betalen met BTW',
      attention: 'LET OP',
      textFooter1:
        'Bij niet tijdige betaling, vaste vergoeding voor herstelkosten: 40 €',
      textFooter2: 'Geen korting bij vooruitbetaling',
      textFooter3:
        'Volgens de LME van 04/08/08 zal een gemiste deadline automatisch, zonder voorafgaande kennisgeving, aanleiding geven naast de kosten',
      textFooter4:
        'tot een boete voor te late betaling tegen het door de ECB in rekening gebrachte rentepercentage, verhoogd met 10 punten',
      title: 'Uittreksel uit de algemene verkoop en gebruiksvoorwaarden ©',
      subtitle:
        'De volledige versie van de AVG is beschikbaar op de iDGARAGES.com-website. De AVG werd naar behoren aanvaard door de ledengarage',
      cgvu: 'Het doel van deze AVG is het definiëren van de voorwaarden voor het verlenen van een contactdienst tussen automobilisten (onverschilling " een klant iDGARAGES.com" of "automobilist gebruiker") op zoek naar een serviceprovider voor het uitvoeren van auto-onderhoud en reparatiediensten en garages presenteren hun aan biedingen en diensten (individueel "een of de ledengarage" en gezamenlijk " de ledengarages"). Deze AVG worden indien nodig aangevuld of gewijzigd, door specifieke gebruiksvoorwaarden die specifiek zijn voor bepaalde functionaliteiten. ',
      titleCGVU: '1. AANVAARDING VAN DE AVG',
      textCGVU:
        'De ledengarage verklaart en erkent dat hij alle voorwaarden van deze algemene voorwaarden heeft gelezen. Bovendien, verbinding met een van de diensten die op de site worden aangeboden \n' +
        'toegankelijk op het adres: www.iDGARAGES.com (hierna de website www.iDGARAGES.com) via het speciale en beveiligde gebied, toegankelijk met een identificatie en een paswoord (“Garage Area”) impliceert de onvoorwaardelijke aanvaarding door de ledengarage van deze algemene voorwaarden.\n' +
        'DIGITAL AFTERMARKET behoudt zich het recht voor om deze Algemene Voorwaarden te allen tijde geheel of gedeeltelijk te wijzigen. \n' +
        'Het is daarom de verantwoordelijkheid van de gebruikersgarage om te raadplegenregelmatig wordt de laatste versie van de AVG gepost op het adres http://www.idgarages.com/166-AVG-garages. De ledengarage wordt geacht deze laatste versie te accepteren telkens wanneer deze nieuw is verbinding op de site, op de daarvoor bestemde ruimte. […] ',
      titleService: '3. TOEGANG TOT SERVICE VOOR DE LEDENGARAGE',
      textService:
        'Toegang tot de professionele interface iDGARAGES.com: "GARAGE AREA" - DIGITAL AFTERMARKET biedt de ledengarage een professionele interface waarmee het hun "iDGARAGES.com" planning en hun aanbod, prijzen, iDGARAGES.com en beschrijvende informatie over de garage configureren {...} \n' +
        'c.Online afspraakboekingservice - De automobilist kan online een afspraak maken in de ledengarage die hij heeft gekozen om een onderhoud prestatie te maken.iDGARAGES.com afspraken die namens de ledengarage worden gehouden, worden geacht te zijn bevestigd door de ledengarage, zonder dat DIGITALE AFTERMARKET  \n' +
        'contact hoeft op te nemen met de ledengarage vooraf. Behalve in uitzonderlijke en gerechtvaardigde gevallen, kan de ledengarage de iDGARAGES.com \n' +
        'afspraak niet wijzigen of weigeren. De ledengarage wordt op de hoogte gesteld van een reservering, a wijziging of annulering van een afspraak in zijn garage per e-mail en eventueel per telefoon, sms, \n' +
        'fax of ander contactmiddel. De ledengarage stemt ermee in om zijn e-mails minstens één keer per werkdag te controleren. \n' +
        'Behalve in uitzonderlijke en gerechtvaardigde gevallen (bijvoorbeeld door een onderbreking van de internettoegang), vormt de e-mail het enige bewijs van ontvangst en\n' +
        ' informatie over de afspraakreservering en de service automatisch aangevraagd door iDGARAGES.com klant. Voor individuele diensten verbindt de ledengarage zich ertoe de door\n' +
        ' de klant van iDGARAGES.com gereserveerde dienst uit te voeren op de dag van de overeengekomen afspraak. Ten laatste het voertuig wordt om 18:00 uur geretourneerd aan de\n' +
        ' klant van iDGARAGES.com. De ledengarage en de iDGARAGES.com klant kunnen voor 18:00 uur een retourplanning afspreken. Bij wijze van uitzondering kunnen de ledengarage \n' +
        'en de iDAGRAGES.com klant overeenkomen om de afspraak met enkele dagen uit te stellen. Voor complexe diensten verbindt de ledengarage zich ertoe de door de klant van \n' +
        'iDGARAGES.com gereserveerde dienst binnen 48 uur na de overeengekomen afspraak uit te voeren. Als de onderdelen die nodig zijn voor de interventie niet beschikbaar\n' +
        ' zijn op de dag van de afspraak, moet de ledengarage de iDGARAGES.com klant zo snel mogelijk en voor de geplande datum van afspraak, om een nieuwe afspraakdatum in te stellen.\n' +
        ' Als de afspraak wordt geannuleerd door de iDGARAGES.com-klant of als de iDGARAGES.com-klant niet komt opdagen voor de afspraak, moet de ledengarage zo snel mogelijk\n' +
        ' informeren DIGITALE AFTERMARKET en uiterlijk binnen 3 werkdagen na de initiële afspraakdatum. Na verificatie zal DIGITAL AFTERMARKET de ledengarage niet factureren\n' +
        ' voor geannuleerde of niet nagekomen afspraken. Na een periode van 3 werkdagen kan DIGITAL AFTERMARKET de commissies die verschuldigd zijn op deze afspraken factureren.\n' +
        ' DIGITAL AFTERMARKET draagt geen verantwoordelijkheid in het geval van een afspraak geannuleerd of niet nagekomen door een iDGARAGES.com-klant.\n' +
        ' De ledengarage kan er geen aanvragen vergoeding aan DIGITAL AFTERMARKET in het geval van een afspraak geannuleerd of niet nagekomen door een iDGARAGES.com-klant.\n' +
        ' d. Bied voorstel en prijsofferte aan {...} DIGITAL AFTERMARKET biedt op haar website www.iDGARAGES.com geschatte offertes aan voor diensten die worden berekend op \n' +
        'basis van een grote hoeveelheid opgeslagen technische informatie naar databases van externe databaseproviders. Deze informatie wordt met de grootst mogelijke\n' +
        ' professionaliteit samengesteld en gebruikt. In sommige gevallen kan het berekende bedrag onjuist blijken te zijn en dit zal op geen enkele manier het gevolg zijn\n' +
        ' van DIGITALE AFTERMARKET, die de ledengarage herkent en accepteert. De leden garage doet afstand van alles doe een beroep op DIGITAL AFTERMARKET over dit onderwerp. \n' +
        'In het geval van een onjuist bedrag, zal de ledengarage de automobilist onmiddelijk en rechtstreeks informeren en met hem de eerlijke prijs voor de gewenste service \n' +
        'overeekomen. De automobilist kan, indien hij dit wenst, dit new voorstel weigeren en zijn reservering kosteloos annuleren. DIGITAL AFTERMARKET kan niet verantwoordelijk\n' +
        ' worden gehouden gevolgen van deze annulering of wijziging van het voorstel. e. Beschrijvende informatiedienst van de ledengarage. De site www.iDGARAGES.com biedt de\n' +
        ' ledengarage om zijn garage te benadrukken door de mogelijkheid aan te bieden, via de "GARAGE AREA", sectie "Mijn account", om zijn bestand te configureren beschrijvend. \n' +
        'De meest beschrijvende informatie zal beschikbaar zijn voor klanten van iDARAGES.com. De ledengarage verbindt zich ertoe dit blad objectief en feitelijk in te vullen\n' +
        " en indien nodig bij te werken. De ledengarage, als deze deel uitmaakt van een netwerk van garages, erkent de rechten te hebben om de merken en logo's van zijn netwerk \n" +
        "te gebruiken. De ledengarage geeft uitdrukkelijk toestemming voor DIGITAL AFTERMARKET om deze merken en logo's alleen te gebruiken om het te benadrukken\n" +
        ' op www.iDGARAGES.com, vreedzaam gebruik van genoemde gebruiksrechten. De ledengarage is formeel verboden: - om concurrerende merken te citeren - om merken, afbeeldingen,\n' +
        " logo's te gebruiken waarvoor het niet de rechten heeft om te gebruiken - om de iDGARAGES.com service te denigreren - om een directe verbinding aan te bieden\n" +
        ' tussen de iDGARAGES.com klant en de ledengarage - om de andere garages te denigreren - om links en / of adressen van internetsites \n' +
        'in te voegen - telfoon en / of faxnummers invoegen - om opmerkingen te maken of lasterlijke, beledigende, discriminerende, onwettige inhoud in te voegen,\n' +
        ' in strijd met de openbare orde en fatsoen. De ledengarage is als enige verantwoordelijk teksten en informatie op het beschrijvingsblad. DIGITAL AFTERMARKET behoudt \n' +
        'zich het recht voor om alle of een deel van de teksten en informatie die door de ledengarage op het beschrijvingsblad worden verstrekt, te modereren, corrigeren, \n' +
        'verwijderen, herformuleren. In dit geval wordt de ledengarage per e-mail geinformeerd. DIGITAL AFTERMARKET behoudt zich het recht voor om een lid van een garage die \n' +
        'zich hier niet aan houdt eenzijdig uit te schrijven voorzieningen. {...} \n',
      titleChart: '4. IDGARAGES.COM KWALITEITSCHARTER',
      textChart:
        ' Elke garage die vermeld wil worden op www.iDGARAGES.com moet zich zonder uitzondering of zonder voorbehoud houden aan het kwaliteitscharter dat van toepassing is op garages die lid zijn van iDGARAGES.com.\n' +
        'Dit charter heeft betrekking op kwaliteitsverbintenissen waarop de ledengarage zal worden beoordeeld door zijn klanten van iDGARAGES.com.\n' +
        'Dit kwaliteitscharter is beschikbaar op het volgende adres: http://www.idgarages.com/21-kwaliteitcharter, evenals in het GARAGE AREA onder "Mijn account". \n' +
        'In het bijzonder moet de ledengarage de verzekeringen afsluiten die nodig zijn voor de behoeften.',
      titleCondition:
        '7. FINANCIËLE VOORWAARDEN, BETALINGSVOORWAARDEN EN WEDSTRIJDEN',
      textCondition:
        'Er zijn geen registratiekosten voor de service www.iDGARAGES.com voor de ledengarage. Een verplicht zichtabonnement van € 15 exclusief belastingen per maand is verplicht \n' +
        'vanaf eerste maand van registratie. Voor elke afspraak wordt een commissie in rekening gebracht op de volgende grondslagen:\n' +
        'Voor elke afspraak die namens de ledengarage wordt gemaakt, zal deze laatste DIGITALE AFTERMARKET vergoeden met een variabele commissie volgens hettotale bedrag van de offerte gereserveerd door de\n' +
        'klant van iDGARAGES.com: 10% van het bedrag van de gereserveerde offerte.\n' +
        'Dit bedrag kan worden gewijzigd door DIGITAL AFTERMARKET, die de ledengarage uitdrukkelijk erkent en accepteert.\n' +
        'DIGITAL AFTERMARKET factureert aan het begin van de maand de commissies op de afspraken die de vorige maand zijn gereserveerd, evenals het abonnement.\n' +
        'Facturen zullen uiterlijk op de 5e van de maand worden opgesteld en beschikbaar zijn op zijn "GARAGE AREA" op het tabblad facturering. De domiciliëring vindt plaats vanaf de 15e van de maand (of\n' +
        'eerste werkdag na de 15e als het een feestdag is / niet gewerkt). Een enkele factuur bevat alle commissies voor de verzonden afspraken en de afgesloten abonnementen.\n' +
        'Alleen betaling via SEPA-machtiging voor automatische incasso is toegestaan. Bij betaling per cheque of overschrijving kan DIGITAL AFTERMARKET extra verwerkingskosten in rekening brengen€ 50 ZBTW per factuur ',
      footer1:
        'DIGITAL AFTERMARKET – 22, AVENUE ARISTIDE BRIAND – FR 94110 ARCUEIL',
      footer2:
        'TEL : 0033 1 69 79 89 89 – FAX : 0033 1 69 79 89 90 – www.iDGARAGES.com Société à responsabilité limitée au capital de 1 750 000 €',
      footer3:
        'CR CRETEIL B 351 126 438 – SIRET 351 126 438 00057 – Code APE 6311Z – Numéro de TVA : FR35 351126438 00058',
    },
    avoirPartnership: {
      best: 'DE BESTE GARAGES VOOR DE BESTE PRIJZEN',
      avoir: 'Krediet',
      deliveryCustomer: 'Klant geleverd',
      billingCustomer: 'Klant gefactureerd',
      referenceCustomer: 'Uw referentie',
      paymentCustomer: 'Con. betaling / betalingswijze',
      sampleCustomer: 'AUTOMATISCHE MONSTERNEMING',
      accounting: 'BOEKHOUDAFDELING',
      deliveryAddress: 'Leveringadres',
      billingAddress: 'Factuur adres',
      ref: 'Bestel Ref',
      designation: 'Beschrijving',
      ht: 'Bedrag ZBTW',
      totalArticle: 'Totaal artikelen',
      totalTVA: 'Totaal ZBTW',
      tva: 'BTW-basis',
      taux: 'Tarief',
      billingTVA: 'BTW-bedrag',
      totalTTC: 'Totaal te betalen met BTW',
      attention: 'LET OP',
      textFooter1:
        'Bij niet tijdige betaling, vaste vergoeding voor herstelkosten: 40 €',
      textFooter2: 'Geen korting bij vooruitbetaling',
      textFooter3:
        'Volgens de LME van 04/08/08 zal een gemiste deadline automatisch, zonder voorafgaande kennisgeving, aanleiding geven naast de kosten',
      textFooter4:
        'tot een boete voor te late betaling tegen het door de ECB in rekening gebrachte rentepercentage, verhoogd met 10 punten',
      title: 'Uittreksel uit de algemene verkoop en gebruiksvoorwaarden ©',
      subtitle:
        'De volledige versie van de AVG is beschikbaar op de iDGARAGES.com-website. De AVG werd naar behoren aanvaard door de ledengarage',
      cgvu: 'Het doel van deze AVG is het definiëren van de voorwaarden voor het verlenen van een contactdienst tussen automobilisten (onverschilling " een klant iDGARAGES.com" of "automobilist gebruiker") op zoek naar een serviceprovider voor het uitvoeren van auto-onderhoud en reparatiediensten en garages presenteren hun aan biedingen en diensten (individueel "een of de ledengarage" en gezamenlijk " de ledengarages"). Deze AVG worden indien nodig aangevuld of gewijzigd, door specifieke gebruiksvoorwaarden die specifiek zijn voor bepaalde functionaliteiten. ',
      titleCGVU: '1. AANVAARDING VAN DE AVG',
      textCGVU:
        'De ledengarage verklaart en erkent dat hij alle voorwaarden van deze algemene voorwaarden heeft gelezen. Bovendien, verbinding met een van de diensten die op de site worden aangeboden\n' +
        'toegankelijk op het adres: www.iDGARAGES.com (hierna de website www.iDGARAGES.com) via het speciale en beveiligde gebied, toegankelijk met een identificatie en een paswoord (“Garage Area”) impliceert de onvoorwaardelijke aanvaarding door de ledengarage van deze algemene voorwaarden.\n' +
        'DIGITAL AFTERMARKET behoudt zich het recht voor om deze Algemene Voorwaarden te allen tijde geheel of gedeeltelijk te wijzigen. Het is daarom de verantwoordelijkheid van de gebruikersgarage om te raadplegen\n' +
        'regelmatig wordt de laatste versie van de AVG gepost op het adres http://www.idgarages.com/166-AVG-garages. De ledengarage wordt geacht deze laatste versie te accepteren telkens wanneer deze nieuw is verbinding op de site, op de daarvoor bestemde ruimte. […] ',
      titleService: '3. TOEGANG TOT SERVICE VOOR DE LEDENGARAGE',
      textService:
        'Toegang tot de professionele interface iDGARAGES.com: "GARAGE AREA" - DIGITAL AFTERMARKET biedt de ledengarage een professionele interface waarmee het hun \n' +
        '"iDGARAGES.com" planning en hun aanbod, prijzen, iDGARAGES.com en beschrijvende informatie over de garage configureren {...} \n' +
        'c.Online afspraakboekingservice - De automobilist kan online een afspraak maken in de ledengarage die hij heeft gekozen om een onderhoud prestatie te maken.\n' +
        ' iDGARAGES.com afspraken die namens de ledengarage worden gehouden, worden geacht te zijn bevestigd door de ledengarage, zonder dat DIGITALE AFTERMARKET \n' +
        'contact hoeft op te nemen met de ledengarage vooraf. Behalve in uitzonderlijke en gerechtvaardigde gevallen, kan de ledengarage de iDGARAGES.com \n' +
        'afspraak niet wijzigen of weigeren. De ledengarage wordt op de hoogte gesteld van een reservering, a wijziging of annulering van een afspraak in zijn garage per e-mail\n' +
        ' en eventueel per telefoon, sms, fax of ander contactmiddel. De ledengarage stemt ermee in om zijn e-mails minstens één keer per werkdag te controleren. Behalve in \n' +
        'uitzonderlijke en gerechtvaardigde gevallen (bijvoorbeeld door een onderbreking van de internettoegang), vormt de e-mail het enige bewijs van ontvangst en informatie over\n' +
        ' de afspraakreservering en de service automatisch aangevraagd door iDGARAGES.com klant. Voor individuele diensten verbindt de ledengarage zich ertoe de door de klant van\n' +
        ' iDGARAGES.com gereserveerde dienst uit te voeren op de dag van de overeengekomen afspraak. Ten laatste het voertuig wordt om 18:00 uur geretourneerd aan de klant van\n' +
        ' iDGARAGES.com. De ledengarage en de iDGARAGES.com klant kunnen voor 18:00 uur een retourplanning afspreken. Bij wijze van uitzondering kunnen de ledengarage en de \n' +
        'iDAGRAGES.com klant overeenkomen om de afspraak met enkele dagen uit te stellen. Voor complexe diensten verbindt de ledengarage zich ertoe de door de klant van \n' +
        'iDGARAGES.com gereserveerde dienst binnen 48 uur na de overeengekomen afspraak uit te voeren. Als de onderdelen die nodig zijn voor de interventie niet beschikbaar\n' +
        ' zijn op de dag van de afspraak, moet de ledengarage de iDGARAGES.com klant zo snel mogelijk en voor de geplande datum van afspraak, om een nieuwe afspraakdatum in te stellen.\n' +
        ' Als de afspraak wordt geannuleerd door de iDGARAGES.com-klant of als de iDGARAGES.com-klant niet komt opdagen voor de afspraak, \n' +
        'moet de ledengarage zo snel mogelijk informeren DIGITALE AFTERMARKET en uiterlijk binnen 3 werkdagen na de initiële afspraakdatum. Na verificatie \n' +
        'zal DIGITAL AFTERMARKET de ledengarage niet factureren voor geannuleerde of niet nagekomen afspraken. Na een periode van 3 werkdagen kan DIGITAL AFTERMARKET \n' +
        'de commissies die verschuldigd zijn op deze afspraken factureren. DIGITAL AFTERMARKET draagt geen verantwoordelijkheid in het geval van een afspraak geannuleerd of niet \n' +
        'nagekomen door een iDGARAGES.com-klant. De ledengarage kan er geen aanvragen vergoeding aan DIGITAL AFTERMARKET in het geval van een afspraak geannuleerd of niet nagekomen \n' +
        'door een iDGARAGES.com-klant. d. Bied voorstel en prijsofferte aan {...} DIGITAL AFTERMARKET biedt op haar website www.iDGARAGES.com geschatte offertes aan voor diensten \n' +
        'die worden berekend op basis van een grote hoeveelheid opgeslagen technische informatie naar databases van externe databaseproviders. \n' +
        'Deze informatie wordt met de grootst mogelijke professionaliteit samengesteld en gebruikt. \n' +
        'In sommige gevallen kan het berekende bedrag onjuist blijken te zijn en dit zal op geen enkele manier het gevolg zijn van DIGITALE AFTERMARKET, \n' +
        'die de ledengarage herkent en accepteert. De leden garage doet afstand van alles doe een beroep op DIGITAL AFTERMARKET over dit onderwerp. \n' +
        'In het geval van een onjuist bedrag, zal de ledengarage de automobilist onmiddelijk en rechtstreeks informeren en met hem de eerlijke prijs voor de gewenste service \n' +
        'overeekomen. De automobilist kan, indien hij dit wenst, dit new voorstel weigeren en zijn reservering kosteloos annuleren. \n' +
        'DIGITAL AFTERMARKET kan niet verantwoordelijk worden gehouden gevolgen van deze annulering of wijziging van het voorstel. e. \n' +
        'Beschrijvende informatiedienst van de ledengarage. De site www.iDGARAGES.com biedt de ledengarage om zijn garage te benadrukken door de mogelijkheid aan te bieden, \n' +
        'via de "GARAGE AREA", sectie "Mijn account", om zijn bestand te configureren beschrijvend. De meest beschrijvende informatie zal beschikbaar zijn voor klanten van \n' +
        'iDARAGES.com. De ledengarage verbindt zich ertoe dit blad objectief en feitelijk in te vullen en indien nodig bij te werken. De ledengarage, als deze deel uitmaakt \n' +
        "van een netwerk van garages, erkent de rechten te hebben om de merken en logo's van zijn netwerk te gebruiken. De ledengarage geeft uitdrukkelijk toestemming voor \n" +
        "DIGITAL AFTERMARKET om deze merken en logo's alleen te gebruiken om het te benadrukken op www.iDGARAGES.com, vreedzaam gebruik van genoemde gebruiksrechten.\n" +
        " De ledengarage is formeel verboden: - om concurrerende merken te citeren - om merken, afbeeldingen, logo's te gebruiken waarvoor het niet de rechten \n" +
        'heeft om te gebruiken - om de iDGARAGES.com \n' +
        'service te denigreren - om een directe verbinding aan te bieden tussen de iDGARAGES.com klant en de ledengarage - om de andere garages te denigreren - om links en / of \n' +
        'adressen van internetsites in te voegen - telfoon en / of faxnummers invoegen - om opmerkingen te maken of lasterlijke, beledigende, discriminerende, onwettige inhoud \n' +
        'in te voegen, in strijd met de openbare orde en fatsoen. De ledengarage is als enige verantwoordelijk teksten en informatie op het beschrijvingsblad. \n' +
        'DIGITAL AFTERMARKET behoudt zich het recht voor om alle of een deel van de teksten en informatie die door de ledengarage op het beschrijvingsblad worden verstrekt, \n' +
        'te modereren, corrigeren, verwijderen, herformuleren. In dit geval wordt de ledengarage per e-mail geinformeerd. DIGITAL AFTERMARKET behoudt zich het recht voor\n' +
        ' om een lid van een garage die zich hier niet aan houdt eenzijdig uit te schrijven voorzieningen. {...}',
      titleChart: '4. CHARTE DE QUALITÉ IDGARAGES.COM',
      textChart:
        'Tout garage qui souhaite être référencé sur www.iDGARAGES.com, doit adhérer sans exception ni réserve à la charte de qualité qui s’applique aux garages adhérents iDGARAGES.com.' +
        ' Cette charte porte sur des engagements de qualité sur lesquels le garage adhérent sera évalué par ses clients iDGARAGES.com.' +
        ' Cette charte de qualité est disponible à l’adresse suivante : http://www.idgarages.com/21-charte-qualite, ainsi que dans l’ESPACE GARAGES, rubrique « Mon compte ».' +
        ' En particulier, le garage adhérent doit souscrire les polices d’assurance nécessaires aux besoins de ses activités.',
      titleCondition: '7. IDGARAGES.COM KWALITEITSCHARTER',
      textCondition:
        'Elke garage die vermeld wil worden op www.iDGARAGES.com moet zich zonder uitzondering of zonder voorbehoud houden aan het kwaliteitscharter dat van toepassing is op garages die lid zijn van iDGARAGES.com.\n' +
        'Dit charter heeft betrekking op kwaliteitsverbintenissen waarop de ledengarage zal worden beoordeeld door zijn klanten van iDGARAGES.com.\n' +
        'Dit kwaliteitscharter is beschikbaar op het volgende adres: http://www.idgarages.com/21-kwaliteitcharter, evenals in het GARAGE AREA onder "Mijn account".\n' +
        'In het bijzonder moet de ledengarage de verzekeringen afsluiten die nodig zijn voor de behoeften.\n',
      footer1:
        'DIGITAL AFTERMARKET – 22, AVENUE ARISTIDE BRIAND – FR 94110 ARCUEIL',
      footer2:
        'TEL : 0033 1 69 79 89 89 – FAX : 0033 1 69 79 89 90 – www.iDGARAGES.com Société à responsabilité limitée au capital de 1 750 000 €',
      footer3:
        'CR CRETEIL B 351 126 438 – SIRET 351 126 438 00057 – Code APE 6311Z – Numéro de TVA : FR35 351126438 00058',
    },
  },
  login: {
    emailPassword: 'Voer uw e-mailadres en paswoord in',
    email: 'Voer uw e-mailadres in',
    password: 'Voer uw paswoord in',
    emailPattern: 'Voer een geldig e-mailadres in',
    passwordPattern:
      'Uw paswoord moet minimaal 8 tekens bevatten, een hoofdletter en een cijfer',
    exist: 'Deze e-mail bestaat niet in onze api',
    incorrect: 'Incorrecte email of paswoord',
    title: 'Log in',
    emailField: 'E-mail adres *',
    passwordField: 'Paswoord*',
    connectButton: 'Inloggen',
    forgotPassword: 'Vergeten paswoord ?',
  },
  reset: {
    email: 'Voer uw e-mailadres in',
    emailPattern: 'Voer een geldig e-mailadres in',
    title: 'U hebt uw paswoord vergeten ?',
    subtitle:
      'Voer uw e-mailadres in om een ​​link voor het opnieuw instellen van uw wachtwoord te ontvangen',
    emailField: 'Garage naam',
    button: 'Ontvang een email',
  },
  resetPassword: {
    passwordConfirm: 'Voer uw nieuwe paswoord in en bevestig het',
    newPassword: 'Voer uw nieuwe paswoord in',
    confirmPassword: 'Bevestig alstublieft uw paswoord',
    passwordMatch: 'De twee paswoorden zijn verschillend',
    passwordPattern:
      'Uw paswoord moet minimaal 8 tekens bevatten, een hoofdletter en een cijfer',
    title: 'Verander uw paswoord',
    subtitle: 'Voer een nieuw paswoord in',
    passwordField: 'Nieuw paswoord*',
    confirmField: 'Paswoordbevestiging *',
    confirmButton: 'Bevestigen',
  },
  loginGarage: {
    garage: 'Voer een garage in',
    email: 'Voer een e-mail in',
    emailPattern: 'Voer een geldig e-mailadres in',
    code: 'Voer een garagecode in',
    codePattern: 'Voer een geldige code in',
    title: 'Betreed een garage door:',
    socialRaison: 'Sociale Reden',
    emailAdress: 'E-mail adres',
    codeRep: 'Garage code',
    garageField: 'Garage naam',
    emailField: 'E-mail',
    codeField: 'Garage code',
    connectButton: 'Enter',
    deconnectionButton: 'Uitloggen',
  },
  account: {
    civility: 'Beleefdheid*',
    garage: 'Garage naam *',
    lastname: 'Naam*',
    firstname: 'Voornaam*',
    title: 'Hoofd informatie',
    titleMyData: 'Mijn informatie',
    read: 'Bekijk uw bestand online',
    info: 'Garage info',
    description: 'Beschrijving',
    timetable: 'Uren',
    bank: 'Bankgegevens',
    infoAccount: 'Rekening info',
    service: 'Diensten',
    autossimo:
      'Voer uw Autossimo login en paswoord in om uw offerte over te maken en uw onderdelen op Autossimo te bestellen.',
    autossimoLogin: 'Identificatiecode *',
    autossimoPassword: 'Paswoord *',
    servicesList: {
      rep_carte_grise: 'Formaliteiten kentekenbewijs',
      rep_flag_certifcli: 'Airconditioning gecertificeerd',
      rep_allumage: 'Bedrijfsvoering Benzine/Diesel motor',
      rep_carosserie: 'Carrosserie (verf en reparatie)',
      rep_renovation: 'Reparatie en plastic renovatie',
      rep_is_diagnostic_electronique: 'Elektrische diagnose',
      rep_is_geometrie_trains: 'Wieluitlijning',
      rep_is_specialiste_clim: 'Airconditioning specialist',
      rep_is_specialiste_gpl: 'LPG specialist',
      rep_is_specialiste_electrique_hybride:
        'Elektrische en hybride specialist',
      rep_garage_mobile: 'Mobiele garage',
      rep_is_gare_proche: 'Openbaar vervoer in de buurt',
      rep_depannage: 'Pechhulp (evt. Pechhulp/ takelauto)',
      rep_veh_courtoisie: 'Vervangend vervoer',
      rep_service_domicile: 'Pick-up service',
      rep_station_lavage: 'Carwash',
      rep_is_gardiennage_pneus_neige: 'opslag winter- en zomerbanden',
      rep_facilire_paie: 'Betalingsmogelijkheden',
      rep_station_essence: 'Benzinestation',
      rep_is_distributeur_boissons_cafe: 'Koffiemachine en/of drankdispenser',
      rep_is_raccompagne_client_domicile:
        'Mogelijkheid tot thuis brengen van de klant',
      rep_pctrl_technique: 'Technische voorinspectie',
      rep_pres_ctrltech: 'Autokeuring /APK',
      rep_is_demarche_declaration_ass: 'Verzekeringsaangifteverklaring',
      rep_is_parking: 'Parking',
      rep_is_specialiste_electro_diesel: 'Specialist elektro-diesel',
      rep_wifi: 'Wifi voor de klanten',
      rep_is_espace_attente: 'Wachtruimte',
      rep_boutique: 'Winkel/winkelcentrum in de buurt',
      rep_is_montre_pieces_changees:
        'Mogelijkheid om de gebruikte / vervangen onderdelen te tonen',
      rep_espace_enfant: 'Kinderruimte',
      rep_is_vente_vo_vn: 'Verkoop occasions - nieuw',
      rep_montage_pneus: 'Banden montage',
    },
    bankInfo: {
      messageSizeError: 'Bestand te groot',
      messageWrongFileError: 'Onverenigbaar bestandsformaat',
    },
  },
  accountDisabledPage: {
    message1:
      'De toegang tot uw Garage ruimte is gedeactiveerd. Neem contact op met',
    message2: 'om de situatie recht te zetten.',
    message3: 'U kunt nog steeds uw',
    message4: 'raadplegen.',
    billingLink: 'factuur',
    mail: 'garagiste@idgarages.com',
    button: 'Ik heb begrepen',
  },
  printButton: {
    rdv: 'De vergadering',
    repair: 'De reparatieopdracht',
  },
  mobileApp: {
    admin: 'Administratie',
    garage: 'Garage',
    text1: 'Profiteer van een ervaring',
    text2: 'optimaal op mobiel',
    text3: 'door te downloaden',
    text4: "de applicatie idGarages.pro",
  },
};
export default lg;

import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { ButtonHTMLAttributes } from 'react';
import colors from '../style-colors';

const defaultThemes = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 400,
  marginRight: '16px',
  textAlign: 'center' as const,
  verticalAlign: 'middle',
  border: '1px solid transparent',
  padding: '10px 20px',
  fontSize: '12px',
  lineHeight: '1.5',
  borderRadius: '8px',
  transition:
    'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out',

  '& a': {
    color: colors.dark,
  },
};

interface ThemeType {
  [key: string]: string;
}

type ThemeValues =
  | 'dark'
  | 'light'
  | 'withBorder'
  | 'grey'
  | 'text'
  | 'lightV2'
  | 'withImg';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  theme: ThemeValues;
  width?: string;
  height?: string;
  active?: boolean;
  variant?: 'primary' | 'secondary';
}

const Button = ({
  type,
  children,
  name,
  title,
  onClick,
  disabled,
  theme,
  className,
  ...props
}: ButtonProps) => {
  const useButtonStyles = makeStyles({
    dark: {
      ...defaultThemes,
      width: () => props.width || '',
      color: 'white',
      backgroundColor: colors.dark,
      boxShadow: '0 2px 18px 0 rgba(214,214,214,0.5)',

      '&:hover': {
        backgroundColor: colors.black,
      },

      '&:disabled': {
        backgroundColor: colors.veryLightBlue,

        '&:hover': {
          backgroundColor: colors.veryLightBlue,
        },
      },
    },

    light: {
      ...defaultThemes,
      width: () => props.width || '',
      height: () => props.height || '',
      backgroundColor: () => colors.white,
      color: () => (props.active ? colors.paleDark : colors.dark),
      fontWeight: 100,
      boxShadow: '0 2px 18px 0 rgba(214,214,214,0.5)',

      '&:hover': {
        backgroundColor: colors.veryLightBlue,
      },

      '&:disabled': {
        color: colors.veryLightBlue,
      },
    },

    withBorder: {
      ...defaultThemes,
      fontWeight: 100,
      border: `1px solid ${colors.veryLightBlue}`,
      marginRight: 0,
      backgroundColor: colors.white,
      color: colors.dark,

      '&:hover': {
        backgroundColor: colors.veryLightBlue,
      },

      '&:disabled': {
        backgroundColor: colors.white,
        color: colors.veryLightBlue,
      },
    },

    grey: {
      ...defaultThemes,
      width: () => props.width || '',
      height: () => props.height || '',
      backgroundColor: colors.paleGrey,
      color: colors.dark,
      fontWeight: 600,

      '&:hover': {
        backgroundColor: colors.paleGrey,
      },

      '&:disabled': {
        color: colors.veryLightBlue,
      },
    },

    text: {
      ...defaultThemes,
      backgroundColor: 'transparent',
      fontSize: '12px',
    },

    lightV2: {
      ...defaultThemes,
      width: () => props.width || '',
      height: () => props.height || '',
      backgroundColor: () => (props.active ? 'transparent' : colors.white),
      color: () => (props.active ? colors.lightGreyBlue : colors.dark),
      border: () =>
        props.active
          ? `1px solid ${colors.veryLightBlue}`
          : '1px solid transparent',
      fontWeight: 100,
      boxShadow: '0 2px 18px 0 rgba(214,214,214,0.5)',
      marginRight: '10px',

      '&:hover': {
        color: `${colors.paleDark} !important`,
        backgroundColor: `${colors.white} !important`,
      },

      '&:disabled': {
        color: colors.veryLightBlue,
      },
    },

    withImg: {
      ...defaultThemes,
      width: '40px',
      height: '40px',
      padding: 0,
      fontWeight: 100,
      border: `1px solid ${colors.veryLightBlue}`,
      marginRight: 0,
      backgroundColor: colors.white,
      color: colors.dark,

      '&:hover': {
        backgroundColor: colors.white,
      },
    },
  });

  const classes: ThemeType = useButtonStyles(props);

  const themeSelector = () => {
    if (theme) return classes[theme];
    return true;
  };

  return (
    <button
      name={name}
      onClick={onClick}
      disabled={disabled}
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={`${themeSelector()} ${className}`}
    >
      {title}
      {children}
    </button>
  );
};

Button.defaultProps = {
  active: true,
  width: '',
  height: '',
  variant: '',
};

export default Button;

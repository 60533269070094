import createAnalyticsEmitter from '.';

interface BaseEventProps {
  garageId: string;
  garageName: string;
}

interface BookingProps extends BaseEventProps {
  booking_id: string;
}

interface QuoteProps extends BaseEventProps {
  quote_id: string;
}

interface PartsEventProps extends BookingProps {
  part_ids: string[];
  parts_price: number;
}

export const orderPartsFromAutossimoEvent = createAnalyticsEmitter<
  BaseEventProps
>('order_parts_from_autossimo');

export const partsOrderedEvent = createAnalyticsEmitter<PartsEventProps>(
  'parts_booked'
);
export const partsReceivedEvent = createAnalyticsEmitter<PartsEventProps>(
  'parts_received'
);

export const vehicleReadyEvent = createAnalyticsEmitter<BookingProps>(
  'vehicle_ready'
);

export const reportIssueEvent = createAnalyticsEmitter<QuoteProps>(
  'report_issue'
);

export const confirmQuoteEvent = createAnalyticsEmitter<QuoteProps>(
  'confirm_quote'
);

export const cancelQuoteEvent = createAnalyticsEmitter<QuoteProps>(
  'cancel_quote'
);

export const refundQuoteEvent = createAnalyticsEmitter<
  QuoteProps & { amount: number }
>('refund_quote');

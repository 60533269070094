import React from 'react';
import NativeSelect from '@material-ui/core/NativeSelect';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useSelector } from 'react-redux';
import UnfoldMore from '@material-ui/icons/UnfoldMore';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import colors from '../../assets/style-colors';
import { Store } from '../../redux/types';
import { yearsBilling } from '../../services/billing/billing';
import { BootstrapInput, BootstrapInputMonth } from '../bootstrapInput';

interface PeriodHeaderProps {
  year: string;
  handleChangeYear: (e: React.FormEvent<HTMLSelectElement>) => void;
  month: string;
  handleChangeMonth: (e: React.FormEvent<HTMLSelectElement>) => void;
  tabs: Array<{ label: string; href: string }>;
  setCurrentPath?: (path: string) => void;
}

const PeriodHeader: React.FC<PeriodHeaderProps> = ({
  year,
  handleChangeYear,
  month,
  handleChangeMonth,
  tabs,
  setCurrentPath,
}) => {
  const lg = useSelector((state: Store) => state.languageReducer.language);
  const months = moment.months();
  const years = yearsBilling();
  const useStyles = makeStyles(() => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      color: 'darkgrey',
      overflow: 'hidden',
    },
    leftNavBar: {
      display: 'flex',
      justifyContent: 'start',
      background: colors.white,
      borderBottom: '2px solid #eaebf1',
      paddingLeft: '20px',
    },
    help: {
      cursor: 'pointer',
      padding: '20px 10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: colors.lightGreyBlue,

      '& > div': {
        color: colors.lightGreyBlue,
        fontSize: '14px',
        textDecoration: 'none',
        padding: '0 10px 0 10px',
        '&:hover': {
          color: colors.dark,
          textDecoration: 'none',
        },
      },
    },
    helping: {
      cursor: 'pointer',
      padding: '20px 10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: colors.lightGreyBlue,
      position: 'relative',

      '& > div': {
        fontSize: '14px',
        padding: '0 10px 0 10px',
        color: colors.dark,
        textDecoration: 'none',
      },

      '&:after': {
        content: '""',
        height: '4px',
        width: 'calc(100% - 20px)',
        backgroundColor: colors.primary,
        position: 'absolute',
        bottom: '-2px',
        left: '10px',
        borderRadius: '2px',
      },
    },
    link: {
      color: colors.lightGreyBlue,
      fontSize: '14px',
      textDecoration: 'none',
      padding: '0 10px 0 10px',
      '&:hover': {
        color: colors.dark,
        textDecoration: 'none',
      },
    },
    linking: {
      fontSize: '14px',
      padding: '0 10px 0 10px',
      color: colors.dark,
      textDecoration: 'none',
    },
    item: {
      fontSize: '9px',
      fontWeight: 500,
      width: '20px',
      height: '20px',
      textAlign: 'center',
      padding: '5px',
      color: colors.white,
      borderRadius: '5px',
    },
    header: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1',
    },
    title: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flex: '1',
      background: 'white',
      fontSize: '16px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: colors.dark,
      padding: '15px 20px 10px',
    },
    choice: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'row',
      flex: '1',
      background: 'white',
      fontSize: '16px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: colors.dark,
      padding: '0 15px 2px',
    },
    spaceBetween: {
      marginRight: '20px',

      '&:first-child': {
        marginRight: '10px',
      },
    },
  }));

  const allOptionValue = 13;

  const classes = useStyles();

  return (
    <div className={classes.header}>
      <div className={classes.title}>
        <span>{lg.billing.title}</span>
      </div>

      <div className={classes.choice}>
        <div className={classes.spaceBetween}>
          <NativeSelect
            IconComponent={UnfoldMore}
            value={year}
            onChange={handleChangeYear}
            input={<BootstrapInput />}
          >
            {years.map((min) => (
              <option key={min} value={min}>
                {min}
              </option>
            ))}
          </NativeSelect>
        </div>

        <div className={classes.spaceBetween}>
          <NativeSelect
            IconComponent={UnfoldMore}
            value={month}
            onChange={handleChangeMonth}
            input={<BootstrapInputMonth />}
          >
            <option value={allOptionValue}>{lg.visibility.optionAll}</option>
            {months.map((mon, key) => (
              <option key={mon} value={`0${key + 1}`.toString().slice(-2)}>
                {mon}
              </option>
            ))}
          </NativeSelect>
        </div>
      </div>

      <div className={classes.leftNavBar}>
        {tabs.map((tab, index) => (
          <NavLink
            key={index}
            to={tab.href}
            className={(isActive) =>
              isActive ? classes.helping : classes.help
            }
            onClick={() => setCurrentPath?.(tab.href)}
          >
            <div>{tab.label}</div>
          </NavLink>
        ))}
      </div>
    </div>
  );
};

PeriodHeader.defaultProps = {
  setCurrentPath: () => null,
};

export default PeriodHeader;

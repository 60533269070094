import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputMutipleSelect from '../../assets/generic-components/InputMutipleSelect';
import { Store } from '../../redux/types';
import {
  getVehicleMakes,
  setRegistrationNumber
} from '../../redux/stores/quotes/actions';
import { Vehicle } from '../../redux/stores/quotes/types';
import ModelsList from './modelsList';
import VersionsList from './versionsList';

interface MakeModelFieldsProps {
  setVehicle: (vehicle: Vehicle | null) => void;
}

const MakeModelFields: React.FC<MakeModelFieldsProps> = ({ setVehicle }) => {
  const lg = useSelector((state: Store) => state.languageReducer.language);
  const dispatch = useDispatch();
  const fetchedMakesList = useSelector(
    (state: Store) => state.quotesReducer.makesList
  );

  const defaultOptionChoice = {
    label: lg.select,
    value: 'default'
  };

  const [makeValue, setMakeValue] = useState<string>('');
  const [modelValue, setModelValue] = useState<string>('');

  useEffect(() => {
    dispatch(setVehicle(null));
    dispatch(setRegistrationNumber(''));
  }, []);

  const handleMakeValueChanged = async (makeValueChanged: string) => {
    setMakeValue(makeValueChanged);
    setModelValue('');
  };

  const handleModelValueChanged = async (model: string) => {
    setModelValue(model);
  };

  const handleVersionValueChanged = async (
    versionValue: string,
    vehicleList: Vehicle[]
  ) => {
    const vehicleSelected = vehicleList.find(
      vehicle => vehicle.id === versionValue
    );
    if (vehicleSelected) {
      dispatch(setVehicle(vehicleSelected));
    }
  };

  useEffect(() => {
    dispatch(getVehicleMakes());
  }, []);

  return (
    <>
      <InputMutipleSelect
        width="578px"
        label={lg.quote.brand}
        datas={[defaultOptionChoice, ...(fetchedMakesList || [])]}
        onChange={e => handleMakeValueChanged(e.currentTarget.value)}
      />
      <ModelsList
        make={makeValue}
        lg={lg}
        defaultOptionChoice={defaultOptionChoice}
        handleModelValueChanged={handleModelValueChanged}
      />
      <VersionsList
        model={modelValue}
        make={makeValue}
        lg={lg}
        defaultOptionChoice={defaultOptionChoice}
        handleVersionValueChanged={handleVersionValueChanged}
      />
    </>
  );
};

export default MakeModelFields;

import uniq from 'lodash/uniq';
import { Booking, Quote } from '../redux/stores/planning/types';
import { CarService } from '../redux/stores/global/types';
import { getServiceDetailForQuote } from './quote/quote-service';

/**
 * Retrieve suppliers in a carService.
 * @param carService
 */
const getServiceSuppliers = (carService: CarService): string[] =>
  Object.values(carService?.suppliers?.suppliers || {});

/**
 * Get all suppliers for quotes in booking.
 * @param booking
 * @param availableCarServices
 */
const getBookingSuppliers = (
  booking: Booking,
  availableCarServices: Record<string, CarService>
): Array<string | null> => {
  const suppliers = booking.quotes.map((quote: Quote) => {
    const quoteServiceDetail = getServiceDetailForQuote(
      quote,
      availableCarServices
    );
    return quoteServiceDetail ? getServiceSuppliers(quoteServiceDetail) : null;
  });
  return uniq(suppliers.flat());
};

export default getBookingSuppliers;

import { makeStyles, createTheme } from '@material-ui/core/styles';
import colors from '../../../../assets/style-colors';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1200,
      xl: 1920,
    },
  },
});

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '5% 0% 10% 0%',
    fontSize: '12px',
    color: colors.slateGrey,
    fontWeight: 'bold',
    [theme.breakpoints.up('lg')]: {
      padding: '5% 15% 10% 15%',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: 640,
    },
  },
  header: {
    fontSize: '20px',

    paddingBottom: '10px',
    color: colors.dark,
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
    margin: '5px',
  },
  form: {
    appearance: 'none',
    width: '100px',
    height: '40px',
    textAlign: 'center',
    backgroundColor: colors.white,
    border: '1px solid transparent',
    lineHeight: '40px',
    padding: '0 .75rem',
    fontSize: '12px',
    fontWeight: 'bold',
    borderRadius: '8px',
    color: colors.dark,
  },
  formDate: {
    width: '140px',
  },
  formHours: {
    textAlign: 'left',
    width: '384px',
  },
  calendar: {
    position: 'absolute',
    zIndex: 100,

    transform: 'translate(-15px,5px)',
  },
  link: {
    outline: 'none',
    border: 'none',
    background: 'transparent',
    fontSize: '13px',
    textDecoration: 'underline',
    margin: '10px 0 20px 0',
    color: colors.dark,
  },
  fields: {
    '& span': {
      display: 'inline-block',
      width: '100%',
    },
  },
  fieldsC: {
    '& posBox': {
      width: '235px',
    },
  },
  formGroup: {
    marginLeft: '15px',
  },
  wrapFormDate: {
    marginLeft: '15px',
  },
  spaceItem: {
    padding: '11px 25px',
  },
  iconCreate: {
    width: '14px',
    margin: '0 10px 0 0',
  },
  textButtonEdit: {
    fontSize: '14px',
    margin: '0 10px 0 0',
    color: colors.coral,
  },
  error: {
    color: colors.coral,
  },
}));

export default useStyles;

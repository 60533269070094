import createAnalyticsEmitter from '.';

interface BaseEventProps {
  garageId: string;
  garageName: string;
}

export const handleTimeEvent = createAnalyticsEmitter<BaseEventProps>(
  'opening_hours'
);

export const handleHolidaysEvent = createAnalyticsEmitter<BaseEventProps>(
  'manage_holidays'
);
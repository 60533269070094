import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Account from '../account-page';
import Billing from '../billing-page';
import Command from '../command-page';
import Iframe from '../iframe/iFrame';
import Planning from '../planning-page';
import Quote from '../quote-page';
import SearchResults from '../search-results-page';
import VisilbityPage from '../visibility-page';
import TyresList from '../tyres/components/list';
import * as ROUTES from '../../router/routes';
import AccountDisabledPage from '../account-disabled-page';
import getIframeUrl from '../../utils/url';
import Help from '../help-page';

interface RoutesNavbarProps {
  garageId: string;
  locale: string;
  className: string;
}

export const RoutesNavbar: React.FC<RoutesNavbarProps> = ({
  garageId,
  locale,
  className
}) => (
  <div className={className}>
    <Route exact path={ROUTES.HOME}>
      <Redirect to={ROUTES.PLANNING} />
    </Route>
    <Route path={ROUTES.HELP} component={Help} />
    <Route exact path={ROUTES.SEARCH_RESULTS} component={SearchResults} />
    <Route exact path={ROUTES.CONTACT}>
      <Helmet>
        <title>Contact</title>
      </Helmet>

      <div>
        <Iframe
          title="Contact"
          src={getIframeUrl(`/${locale}/contact?iframeMode=true`, garageId)}
          style={{
            width: '100%',

            height: '92vh',
            border: '0 none'
          }}
        />
      </div>
    </Route>
    <Route exact path={ROUTES.COMMAND} component={Command} />
    <Route path={ROUTES.ACCOUNT} component={Account} />

    <Route path={ROUTES.PLANNING} component={Planning} />

    <Route path={ROUTES.RATES}>
      <Helmet>
        <title>Mes tarifs</title>
      </Helmet>

      <div>
        <Iframe
          title="Tarifs"
          src={getIframeUrl(`/${locale}/myPriceList?iframeMode=true`, garageId)}
          style={{
            width: '100%',
            height: '92vh',
            border: '0 none'
          }}
        />
      </div>
    </Route>
    <Route path={ROUTES.TYRES_SELECTION} component={TyresList} />
    <Route path={ROUTES.QUOTE} component={Quote} />
    <Route path={ROUTES.VISIBILITY} component={VisilbityPage} />
    <Route path={ROUTES.BILLING} component={Billing} />
  </div>
);

export const DisabledAccountRoutesNavbar = ({
  garageId,
  locale,
  className
}: RoutesNavbarProps) => (
  <div className={className}>
    <Route exact path={ROUTES.HOME}>
      <Redirect to={ROUTES.PLANNING} />
    </Route>
    <Route path={ROUTES.HELP} component={Help} />
    <Route exact path={ROUTES.SEARCH_RESULTS} component={SearchResults} />
    <Route exact path={ROUTES.CONTACT}>
      <div>
        <Helmet>
          <title>Contact</title>
        </Helmet>

        <Iframe
          title="Contact"
          src={getIframeUrl(`/${locale}/contact?iframeMode=true`, garageId)}
          style={{
            width: '100%',

            height: '92vh',
            border: '0 none'
          }}
        />
      </div>
    </Route>
    <Route exact path={ROUTES.COMMAND} component={Command} />
    <Route path={ROUTES.PLANNING} component={Planning} />
    <Route path={ROUTES.BILLING} component={Billing} />
    <Route path={ROUTES.ACCOUNT} component={AccountDisabledPage} />
    <Route path={ROUTES.RATES} component={AccountDisabledPage} />
    <Route path={ROUTES.TYRES_SELECTION} component={AccountDisabledPage} />
    <Route path={ROUTES.QUOTE} component={AccountDisabledPage} />
    <Route path={ROUTES.VISIBILITY} component={AccountDisabledPage} />
    <Route path={ROUTES.QUOTE} component={AccountDisabledPage} />
    <Route path={ROUTES.QUOTE} component={AccountDisabledPage} />
  </div>
);

import makeStyles from '@material-ui/core/styles/makeStyles';
import colors from '../../../../assets/style-colors';

const useStyles = makeStyles(() => ({
  container: {
    minHeight: '100vh',
    width: '90%',
    margin: 'auto',
    paddingBottom: '100px',

    '& b': {
      fontWeight: 'bold',
    },

    '& table': {
      border: 0,

      '& th': {
        fontSize: '11px',
        lineHeight: '15px',
        color: `${colors.dark}`,
        border: 0,
        padding: '10px 5px',
        verticalAlign: 'middle',

        '&:not(:first-child)': {
          textAlign: 'right',
        },
      },

      '& td': {
        padding: '10px 5px',
        verticalAlign: 'middle',
        fontSize: '11px',

        '&:not(:first-child)': {
          textAlign: 'right',
        },

        '& strong': {
          display: 'block',
        },

        '& .logo': {
          width: '16px',
        },
      },
    },

    '& .container-btn': {
      marginTop: '20px',
      marginBottom: '20px',
    },
  },

  boxTitle: {
    paddingTop: '60px',
    fontSize: '12px',
    fontWeight: 500,
  },

  wrapper: {
    paddingTop: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '20px',
  },

  backwardGrey: {
    color: `${colors.slateGrey}`,
  },

  backward: {
    fontSize: '12px',
    color: `${colors.slateGrey}`,
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',

    '& svg': {
      fontSize: '17px',
    },

    '& p': {
      margin: 0,
      padding: 0,
    },
  },

  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '16px',
    fontWeight: 'bold',
    color: `${colors.dark}`,
    marginBottom: '10px',

    '& h4': {
      fontSize: '20px',
      marginBottom: 0,
    },
  },

  box1: {
    width: '66.66%',
  },

  flex: {
    display: 'flex',
    gap: '20px',
  },

  garageInfo: {
    flex: 1,
    display: 'none',
    gap: '20px',
    alignItems: 'center',
    backgroundColor: `${colors.white}`,
    borderRadius: '20px',
    padding: '20px',

    '& > img': {
      width: '96px',
      borderRadius: '20px',
      border: `1px solid ${colors.lightGrey}`,
      padding: '10px',
    },

    '& > div': {
      lineHeight: '150%',
    },

    '& h3': {
      fontWeight: 'bold',
    },
  },

  box2: {
    backgroundColor: `${colors.white}`,
    boxShadow: '0 2px 18px 0 rgba(136, 135, 135, 0.17)',
    borderRadius: '10px',
    width: '33.33%',

    '& h5': {
      fontSize: '15px',
      marginBottom: 0,
      color: `${colors.dark}`,
    },
  },

  vehicleInfo: {
    backgroundColor: `${colors.vehicleInfoGrey}`,
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(32, 32, 32, 0.1)',
    flex: 1,
  },

  headerBoxContent: {
    display: 'grid',
    padding: '25px',
  },

  headerSubtitle: {
    fontSize: '16px',
    lineHeight: '24px',
    marginBottom: 0,
    fontWeight: 'bold',
    color: `${colors.dark}`,
  },

  headerDetails: {
    fontSize: '14px',
    lineHeight: '24px',
    marginBottom: 0,
    color: `${colors.slateGrey}`,
  },

  listItem: {
    backgroundColor: `${colors.white}`,
    boxShadow: '0 2px 18px 0 rgba(136, 135, 135, 0.17)',
    borderRadius: '10px',
    padding: '20px',
    marginTop: '15px',
  },

  listItemHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },

  boxAlert: {
    marginTop: '20px',
    backgroundColor: `${colors.boxAlert}`,
    borderRadius: '10px',
    marginBottom: '7px',
    display: 'flex',
    alignItems: 'center',
    padding: '20px',

    '& p': {
      marginbottom: 0,
      justifyItems: 'center',
      alignItems: 'center',
      fontSize: '13px',
      lineHeight: 1.38,
      color: `${colors.dark}`,
    },
  },

  boxLogo: {
    width: '74px',
    marginRight: '16px',
  },

  wrapperTotal: {
    display: 'flex',
    alignItems: 'center',
    padding: '20px',
    borderBottom: '1px solid',
    borderBottomColor: `${colors.paleGreyDrank}`,

    '& img': {
      width: '20px',
      marginRight: '15px',
    },
  },

  info: {
    fontStyle: 'italic',
    textAlign: 'center',
    color: `${colors.infoColor}`,
    display: 'none',
  },

  boxBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',

    '& a': {
      '&:first-child': {
        '& button': {
          color: `${colors.slateGrey}`,
        },
      },
    },

    '& button': {
      marginRight: 0,
    },
  },
}));

export default useStyles;

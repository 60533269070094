export default {
  type: 'object',
  properties: {
    data: {
      type: 'object',
      properties: {
        _embedded: {
          type: 'object',
          properties: {
            orders: {
              type: 'array',
              items: {
                $ref: '#/definitions/Booking'
              }
            }
          },
          required: ['orders']
        }
      },
      required: ['_embedded']
    }
  },
  required: ['data'],
  definitions: {
    Booking: {
      type: 'object',
      properties: {
        bookingId: {
          type: 'string'
        },
        networkId: {
          type: 'string'
        },
        garageId: {
          type: 'string'
        },
        customer: {
          $ref: '#/definitions/Customer'
        },
        vehicle: {
          $ref: '#/definitions/BookingVehicle'
        },
        bookingDate: {
          type: 'string'
        },
        bookingDepositHour: {
          type: 'string'
        },
        quotes: {
          type: 'array',
          items: {
            $ref: '#/definitions/Quote'
          }
        },
        price: {
          $ref: '#/definitions/BookingPrice'
        },
        paymentStatus: {
          enum: ['ACCEPTED', 'INITIATED', 'REFUSED'],
          type: 'string'
        },
        paidByCreditCard: {
          type: 'number'
        },
        paidByWallet: {
          type: 'number'
        },
        duration: {
          type: 'number'
        },
        comment: {
          type: ['null', 'string']
        },
        bookingHistories: {
          type: 'array',
          items: {
            $ref: '#/definitions/BookingHistory'
          }
        }
      },
      required: [
        'bookingDate',
        'customer',
        'garageId',
        'networkId',
        'paidByCreditCard',
        'paidByWallet',
        'paymentStatus',
        'price',
        'quotes',
        'vehicle'
      ]
    },
    Customer: {
      type: 'object',
      properties: {
        customerId: {
          type: ['null', 'string']
        },
        gender: {
          type: ['null', 'string']
        },
        firstName: {
          type: 'string'
        },
        lastName: {
          type: 'string'
        },
        email: {
          type: 'string'
        },
        phone: {
          type: 'string'
        },
        billingAddress: {
          $ref: '#/definitions/BillingAddres'
        }
      },
      required: ['email', 'firstName', 'lastName', 'phone']
    },
    BillingAddres: {
      type: 'object',
      properties: {
        street1: {},
        street2: {},
        postalCode: {},
        city: {},
        country: {}
      }
    },
    BookingVehicle: {
      type: 'object',
      properties: {
        vehicleId: {
          type: 'string'
        },
        registrationNumber: {
          type: ['null', 'string']
        }
      },
      required: ['vehicleId']
    },
    Quote: {
      type: 'object',
      properties: {
        quoteId: {
          type: 'string'
        },
        carServiceId: {
          type: 'string'
        },
        quantity: {
          type: 'number'
        },
        quoteType: {
          type: 'string'
        },
        mechanicalRateLevel: {
          type: ['null', 'string']
        },
        partsQuality: {
          type: ['null', 'string']
        },
        forfaitMalin: {
          type: 'boolean'
        },
        operations: {
          type: 'array',
          items: {
            anyOf: [
              {
                $ref: '#/definitions/PartOperation'
              },
              {
                $ref: '#/definitions/RepairTime'
              },
              {
                $ref: '#/definitions/Liquid'
              },
              {
                $ref: '#/definitions/FixedPrice'
              }
            ]
          }
        },
        discountTotal: {
          type: 'number'
        },
        status: {
          $ref: '#/definitions/QuoteStatus'
        },
        requestCancelationAt: {
          type: 'string'
        },
        isRequestCancelation: {},
        price: {
          $ref: '#/definitions/Price'
        },
        commission: {
          $ref: '#/definitions/Commission'
        },
        coupon: {
          anyOf: [
            {
              $ref: '#/definitions/Coupon'
            },
            {
              type: 'null'
            }
          ]
        },
        salesforceId: {
          type: ['null', 'string']
        },
        duration: {
          type: 'number'
        }
      },
      required: [
        'carServiceId',
        'discountTotal',
        'forfaitMalin',
        'operations',
        'price',
        'quantity',
        'quoteId',
        'quoteType',
        'status'
      ]
    },
    PartOperation: {
      type: 'object',
      properties: {
        operationId: {
          type: 'string'
        },
        operationType: {
          type: 'string',
          enum: ['parts']
        },
        partsQuality: {
          $ref: '#/definitions/PartsQuality'
        },
        label: {
          type: 'string'
        },
        discountTotal: {
          type: 'number'
        },
        garageDiscount: {
          type: 'number'
        },
        garageDiscountValue: {
          type: 'number'
        },
        garageDiscountValueMax: {
          type: 'number'
        },
        manufacturerPartsId: {
          type: 'array',
          items: {
            type: 'string'
          }
        },
        oemName: {
          type: 'string'
        },
        oemPartsId: {
          type: 'array',
          items: {
            type: 'string'
          }
        },
        partsSelected: {
          $ref: '#/definitions/Part'
        },
        partsSelectedMax: {
          $ref: '#/definitions/Part'
        },
        promotion: {},
        quantity: {
          type: 'number'
        },
        total: {
          type: 'number'
        },
        totalMax: {
          type: 'number'
        },
        unitPrice: {
          type: 'number'
        },
        unitPriceMax: {
          type: 'number'
        },
        unitTotal: {
          type: 'number'
        },
        unitTotalMax: {
          type: 'number'
        }
      },
      required: [
        'discountTotal',
        'garageDiscount',
        'garageDiscountValue',
        'garageDiscountValueMax',
        'label',
        'manufacturerPartsId',
        'oemName',
        'oemPartsId',
        'operationId',
        'operationType',
        'partsQuality',
        'partsSelected',
        'partsSelectedMax',
        'quantity',
        'total',
        'totalMax',
        'unitPrice',
        'unitPriceMax',
        'unitTotal',
        'unitTotalMax'
      ]
    },
    PartsQuality: {
      enum: ['budget', 'premium'],
      type: 'string'
    },
    Part: {
      type: 'object',
      properties: {
        partsId: {
          type: 'string'
        },
        manufacturerName: {
          type: 'string'
        },
        partsQuality: {
          $ref: '#/definitions/PartsQuality'
        },
        quantity: {
          type: 'number'
        },
        price: {
          type: 'number'
        },
        additionalInfos: {
          type: 'string'
        }
      },
      required: [
        'manufacturerName',
        'partsId',
        'partsQuality',
        'price',
        'quantity'
      ]
    },
    RepairTime: {
      type: 'object',
      properties: {
        operationId: {
          type: 'string'
        },
        operationType: {
          type: 'string',
          enum: ['repairTime']
        },
        provider: {
          enum: ['autodata', 'idgarages'],
          type: 'string'
        },
        duration: {
          type: 'number'
        },
        unitPrice: {
          type: 'number'
        },
        total: {
          type: 'number'
        },
        garageDiscount: {
          type: 'number'
        },
        garageDiscountValue: {
          type: 'number'
        }
      },
      required: [
        'duration',
        'garageDiscount',
        'garageDiscountValue',
        'operationId',
        'operationType',
        'provider',
        'total',
        'unitPrice'
      ]
    },
    Liquid: {
      type: 'object',
      properties: {
        operationId: {
          type: 'string'
        },
        operationType: {
          type: 'string',
          enum: ['liquid']
        },
        liquidId: {
          type: 'string'
        },
        unitPrice: {
          type: 'number'
        },
        volume: {
          type: 'number'
        },
        oilType: {
          type: 'string'
        },
        total: {
          type: 'number'
        }
      },
      required: [
        'liquidId',
        'oilType',
        'operationId',
        'operationType',
        'total',
        'unitPrice',
        'volume'
      ]
    },
    FixedPrice: {
      type: 'object',
      properties: {
        operationId: {
          type: 'string'
        },
        operationType: {
          type: 'string',
          enum: ['fixedPrice']
        },
        total: {
          type: 'number'
        }
      },
      required: ['operationId', 'operationType', 'total']
    },
    QuoteStatus: {
      enum: ['CANCELLED', 'CONFIRMED', 'REPORTED', 'VALIDATED', 'WALLET'],
      type: 'string'
    },
    Price: {
      type: 'object',
      properties: {
        minPriceExclTax: {
          type: ['null', 'number']
        },
        maxPriceExclTax: {
          type: ['null', 'number']
        }
      },
      required: ['maxPriceExclTax', 'minPriceExclTax']
    },
    Commission: {
      type: 'object',
      properties: {
        customer: {
          type: 'number'
        },
        garage: {
          type: 'number'
        }
      },
      required: ['customer', 'garage']
    },
    Coupon: {
      type: 'object',
      properties: {
        amount: {
          type: 'number'
        },
        code: {
          type: 'string'
        }
      },
      required: ['amount', 'code']
    },
    BookingPrice: {
      type: 'object',
      properties: {
        commission: {
          type: 'number'
        },
        garageCommission: {
          type: 'number'
        },
        vat: {
          type: 'number'
        },
        currency: {
          type: 'string'
        },
        totalMaxWithTax: {
          type: 'number'
        }
      },
      required: ['commission', 'currency', 'garageCommission', 'vat', 'totalMaxWithTax']
    },
    BookingHistory: {
      type: 'object',
      properties: {
        bookingId: {
          type: 'string'
        },
        quoteId: {
          type: 'string'
        },
        date: {
          type: 'string'
        },
        fieldName: {
          type: 'string'
        },
        oldValue: {
          $ref: '#/definitions/QuoteStatus'
        },
        newValue: {
          $ref: '#/definitions/QuoteStatus'
        },
        message: {
          $ref: '#/definitions/QuoteStatus'
        }
      },
      required: [
        'bookingId',
        'date',
        'fieldName',
        'message',
        'newValue',
        'oldValue',
        'quoteId'
      ]
    }
  },
  $schema: 'http://json-schema.org/draft-07/schema#'
};

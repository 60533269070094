import { makeStyles } from '@material-ui/core/styles';
import React, { FormEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import colors from '../../assets/style-colors';
import SearchIcon from '../../assets/style-elements/01-electrons-icon-search.png';
import { searchBookings } from '../../redux/stores/planning/actions';
import { Store } from '../../redux/types';

const useStyles = makeStyles(() => ({
  popover: {
    background: colors.white,
    display: 'block',
    minWidth: '200px',
    left: '300px',
    top: '70px',
    outline: 0,
    position: 'absolute',
    overflow: 'hidden',
    zIndex: 10,
    boxShadow: '5px 5px 20px silver',
    width: '80%',
  },
  search: {
    flexGrow: 1,
    background: colors.veryPaleGrey,
    border: '0',
    borderRadius: '8px',
    height: '40px',
    padding: '10px 15px 10px 10px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    outline: 'none',
    fontSize: '13px',

    color: colors.lightGreyBlue,
    '&::-webkit-input-placeholder': {
      color: colors.lightGreyBlue,
    },
    '&:-moz-placeholder': {
      color: colors.lightGreyBlue,
    },
    '&::-moz-placeholder': {
      color: colors.lightGreyBlue,
    },
    '&:-ms-placeholder': {
      color: colors.lightGreyBlue,
    },
    '&::placeholder': {
      color: colors.lightGreyBlue,
    },
  },
  popoverText: {
    padding: '30px 20px',
    textAlign: 'left',
    '&:hover': {
      textDecoration: 'underline',
    },
    font: 'Regular 12px/20px Open Sans, sans serif',
    letterSpacing: '0.6px',
    color: '#727272',
  },
  default: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  iconSearch: {
    cursor: 'pointer',
  },
}));

const Search = () => {
  // CONSTANTS:

  const {
    languageReducer,
    globalReducer: { currentGarage },
  } = useSelector((state: Store) => state);
  const [searchValue, setSearchValue] = useState<string>('');
  const lg = languageReducer.language;
  const history = useHistory();
  const dispatch = useDispatch();

  const classes = useStyles();

  // FUNCTIONS:

  const search = (e?: FormEvent<HTMLFormElement>): void => {
    e?.preventDefault();
    if (!currentGarage) {
      return;
    }
    dispatch(searchBookings(currentGarage.id, searchValue));
    setSearchValue('');
    history.push(`/searchResults`);
  };

  // HTML:

  return (
    <div className={classes.search}>
      <form onSubmit={search} className={classes.default}>
        <input
          type="text"
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder={lg.header.searchPlaceholder}
          className={classes.search}
        />
        <img
          alt=" "
          className={classes.iconSearch}
          onClick={() => search()}
          src={SearchIcon}
        />
      </form>
    </div>
  );
};

// EXPORT:

export default Search;

import React from 'react';
import { useSelector } from 'react-redux';
import { Store } from '../../../../../redux/types';
import { Booking } from '../../../../../redux/stores/planning/types';
import useStyles from '../style';
import alertCopyIcon from '../../../../../assets/style-elements/01-electrons-icon-alert-big-copy-gay@3x.png';
import { getSpecificCommission, getTotals } from '../../../../../utils/booking';
import { formatPrice } from '../../../../../services/quote/quote-service';

interface CommissionProps {
  currentBooking: Booking;
}

const Commission = ({ currentBooking }: CommissionProps) => {
  const lg = useSelector((state: Store) => state.languageReducer.language);

  const classes = useStyles();

  const totals = getTotals(
    currentBooking.quotes,
    currentBooking.price.vat,
    true,
    getSpecificCommission(currentBooking)
  );

  return (
    <div className={classes.donateItemWrapper}>
      {currentBooking.networkId !== 'AD-AUTO' && (
        <div className="donateListColor">
          <div className="donateItem">
            <p className="four">
              <img
                className="leftIcon"
                src={alertCopyIcon}
                alt="comission icon"
                style={{ marginRight: '5px' }}
              />
              {lg.command.lateral.commission}
            </p>

            <p className="five">
              {lg.command.lateral.subtitle} :{' '}
              {currentBooking.price.garageCommission?.toFixed(2)}{' '}
              {lg.command.lateral.invoice} {currentBooking.bookingId}{' '}
              {lg.command.lateral.prestation}
            </p>
          </div>
        </div>
      )}

      {currentBooking.networkId === 'AD-AUTO' &&
        (totals.totalCommissionGarageCredit !== 0 ||
          totals.totalCommissionGarageDebit !== 0) && (
          <div className="donateListColor">
            <div className="donateItem">
              <img
                className="leftIcon"
                src={alertCopyIcon}
                alt="comission icon"
              />
              <p className="four">{lg.command.lateral.commission}</p>
            </div>
            <div className="donateItem">
              <p className="five">
                Pour cette commande, vous allez avoir après réalisation du RDV :
              </p>
            </div>
            {totals.totalCommissionGarageCredit !== 0 && (
              <div className="donateItem">
                <p className="six">
                  Montant crédité HT:{' '}
                  {formatPrice(
                    Math.abs(totals.totalCommissionGarageCredit),
                    lg.locale
                  )}
                </p>
              </div>
            )}
            {totals.totalCommissionGarageDebit !== 0 && (
              <div className="donateItem">
                <p className="six">
                  Montant facturé HT:{' '}
                  {formatPrice(
                    Math.abs(totals.totalCommissionGarageDebit),
                    lg.locale
                  )}
                </p>
              </div>
            )}
          </div>
        )}
    </div>
  );
};

export default Commission;

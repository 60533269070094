import makeStyles from '@material-ui/core/styles/makeStyles';
import colors from '../../../../assets/style-colors';

const useStyles = makeStyles(() => ({
  setting: {
    height: '40px',

    '& .tooltip': {
      width: '174px',
      height: 'auto',
      position: 'absolute',
      padding: '15px 12px',
      transform: 'translate(-129px)',
      border: 'solid 1px #f6f7fb',
      borderRadius: '8px',
      background: 'white',
      boxShadow: '0 2px 18px 0 rgba(136, 135, 135, 0.5)',
      zIndex: '1',
      opacity: '1',
    },

    '& .show': {
      display: 'block',
    },

    '& .hide': {
      display: 'none',
    },
  },

  settingIconStyle: {
    display: 'block',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    padding: '8px',
    borderRadius: '5px',
  },

  settingTooltipItem: {
    color: colors.slateGrey,
    width: '100%',
    margin: '0px',
    display: 'block',
    padding: '5px 0',
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: 'normal',
    borderBottom: `solid 1px ${colors.WhiteDark}`,
  },

  setttingRefund: {
    padding: '5px 0',
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: 'normal',
    color: colors.slateGrey,
    cursor: 'pointer',
  },
}));

export default useStyles;

import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import calculatorIcon from '../../../assets/style-elements/total.svg';
import colors from '../../../assets/style-colors';
import { Store } from '../../../redux/types';
import { vatBilling } from '../../../services/billing/billing';
import { ViewsListEnum } from '../../../redux/stores/billings/type';

interface OneViewTotalBillProps {
  month: number;
  year: number;
  totalHt: number;
  tva: number;
  ttc: number;
}

const OneViewTotalBill: React.FC<OneViewTotalBillProps> = ({
  month,
  year,
  totalHt,
  tva,
  ttc,
}) => {
  const useStyles = makeStyles(() => ({
    contain: {
      display: 'flex',
      flexDirection: 'column',
      background: colors.white,
      borderRadius: '2px',
      boxShadow: '0 2px 18px 0 rgba(136, 135, 135, 0.17)',
    },
    calculator: {
      display: 'flex',
      alignItems: 'center',
      padding: '25px 25px',
      borderBottom: '1px solid #eaebf1',
      '& img': {
        height: '24px',
        marginRight: '9px',
      },
      '& span': {
        fontSize: '15px',
        lineHeight: '19px',
        color: colors.dark,
      },
    },
    base: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '0 25px',
      padding: '20px 0',
      borderBottom: '1px solid #eaebf1',
      '& span': {
        display: 'flex',
        fontSize: '13px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.23',
        letterSpacing: 'normal',
        color: colors.dark,

        '&:first-child': {},
      },
    },
    taux: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '0 25px',
      padding: '20px 0',
      borderBottom: '1px solid #eaebf1',
      '& span': {
        display: 'flex',
        fontSize: '13px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.23',
        letterSpacing: 'normal',
        color: colors.dark,

        '&:first-child': {},
      },
    },
    tva: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '0 25px',
      padding: '20px 0',
      borderBottom: '1px solid #eaebf1',
      '& span': {
        display: 'flex',
        fontSize: '13px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.23',
        letterSpacing: 'normal',
        color: colors.dark,

        '&:first-child': {},
      },
    },
    total: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '0px 25px',
      padding: '17px 0px',
      '& span': {
        display: 'flex',

        fontSize: '14px',
        lineHeight: '16px',
        color: colors.dark,
      },
    },
  }));

  const {
    languageReducer: { language: lg },
    billingsReducer: { currentBill: iscurrentBill },
  } = useSelector((state: Store) => state);

  const isVoucher = iscurrentBill?.type === ViewsListEnum.VOUCHER.toUpperCase();
  const months = moment.months();
  const classes = useStyles();
  return (
    <div className={classes.contain}>
      <div className={classes.calculator}>
        <img src={calculatorIcon} alt="calculatorIcon" />
        <span>{`${lg.billing.asset.facture} ${
          months[month - 1]
        } ${year}`}</span>
      </div>
      <div className={classes.base}>
        <span>{lg.billing.asset.base}</span>
        <span>{isVoucher ? `-${totalHt.toFixed(2)}` : totalHt.toFixed(2)}</span>
      </div>
      <div className={classes.taux}>
        <span>{lg.billing.asset.taux}</span>
        <span>{vatBilling}</span>
      </div>
      <div className={classes.tva}>
        <span>TVA €</span>
        <span>{isVoucher ? `-${tva.toFixed(2)}` : tva.toFixed(2)}</span>
      </div>
      <div className={classes.total}>
        <span>{lg.billing.asset.totalVoucher}</span>
        <span>{isVoucher ? `-${ttc.toFixed(2)}` : ttc.toFixed(2)}</span>
      </div>
    </div>
  );
};

export default OneViewTotalBill;

const calendarActions = {};

calendarActions.setWeekNumber = (dispatch, data) => {
  dispatch({
    type: 'WEEK_NUMBER',
    payload: data
  });
};
calendarActions.setDate = (dispatch, data) => {
  dispatch({
    type: 'DATETIME',
    payload: data
  });
};
calendarActions.setActive = (dispatch, bool) => {
  dispatch({
    type: 'ACTIVE_DAY_WEEK',
    payload: bool
  });
};
calendarActions.showDevis = (dispatch, string) => {
  dispatch({
    type: 'SHOW_DEVIS',
    payload: string
  });
};
calendarActions.setDevisData = (dispatch, data) => {
  dispatch({
    type: 'SET_DEVIS_DATA',
    payload: data
  });
};
calendarActions.setDevisDataDetails = (dispatch, data) => {
  dispatch({
    type: 'SET_DEVIS_DATA_DETAILS',
    payload: data
  });
};
calendarActions.setAllData = (dispatch, data) => {
  dispatch({
    type: 'SET_ALL_DATA',
    payload: data
  });
};
calendarActions.setDayIndex = (dispatch, data) => {
  dispatch({
    type: 'SET_DAY_INDEX',
    payload: data
  });
};
calendarActions.setDay = (dispatch, data) => {
  dispatch({
    type: 'SET_DAY',
    payload: data
  });
};
calendarActions.setModal = (dispatch, data) => {
  dispatch({
    type: 'SET_MODAL',
    payload: data
  });
};
export default calendarActions;

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import lg from './assets/language/FR';
import nlLg from './assets/language/NL';

const fallbackLng = ['fr'];
const availableLanguages = ['gb', 'fr', 'nl'];

i18n

  .use(LanguageDetector) // detect user language

  .use(initReactI18next) // pass the i18n instance to react-i18next.

  .init({
    lng: 'fr',
    resources: {
      fr: {
        translation: lg
      },
      nl: {
        translation: nlLg
      }
    },
    fallbackLng, // if user computer language is not on the list of available languages,
    // than we will be using the fallback language specified earlier
    debug: true,
    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

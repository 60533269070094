import React from 'react';

const VisibilityIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    <path
      fill="#AAAEC5"
      d="M4.429 11.886c.426 0 .771.345.771.771v4.572c0 .426-.345.771-.771.771H.77C.345 18 0 17.655 0 17.229v-4.572c0-.426.345-.771.771-.771zM17.229 0c.426 0 .771.345.771.771V17.23c0 .426-.345.771-.771.771H13.57c-.426 0-.771-.345-.771-.771V.77c0-.426.345-.771.771-.771zm-6.4 6.4c.426 0 .771.345.771.771V17.23c0 .426-.345.771-.771.771H7.17c-.426 0-.771-.345-.771-.771V7.17c0-.426.345-.771.771-.771zm5.627-4.857h-2.114v14.914h2.114V1.543zm-6.4 6.4H7.943v8.514h2.113V7.943zm-6.4 5.485H1.544v3.028h2.114v-3.028z"
    />
  </svg>
);

export default VisibilityIcon;
import AuthenticationService, {
  Roles
} from '../services/authentication-service';

const getIframeUrl = (url: string, garageId: string) =>
  process.env.REACT_APP_IFRAME_URL +
  (AuthenticationService.hasRole([Roles.ADMIN, Roles.CALL_CENTER])
    ? `/iframe/redirect?garageId=${garageId}&returnUrl=`
    : '') +
  url;

export default getIframeUrl;

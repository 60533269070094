import React, { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getVehicleByVinCode,
  setRegistrationNumber
} from '../../../../redux/stores/quotes/actions';
import InputLabel from '../../../../assets/generic-components/InputLabel';

interface VinInputProps {
  label: string;
  reference?: any;
  errorLabel?: string | null;
  invalidRegistrationLabel: string;
  name: string;
  handleIsValidVin?: (isValid: boolean) => void;
}

/**
 * Display text field allows you to type your vehicle identification number.
 */
const VinInput: React.FC<VinInputProps> = ({
  label,
  name,
  reference,
  errorLabel,
  invalidRegistrationLabel,
  handleIsValidVin
}) => {
  const [value, setValue] = useState('');
  const [isValidVin, setIsValidVin] = useState(false);
  const dispatch = useDispatch();
  const handleInputChanged = (element: ChangeEvent<HTMLInputElement>): void => {
    const valueVin = element.target.value;
    setValue(valueVin);
    if (valueVin.length >= 17) {
      dispatch(
        getVehicleByVinCode(
          valueVin,
          () => {
            dispatch(setRegistrationNumber(valueVin));
            setIsValidVin(true);
            handleIsValidVin?.(true);
          },

          () => {
            setIsValidVin(false);
            handleIsValidVin?.(false);
          }
        )
      );
    } else {
      handleIsValidVin?.(false);
    }
  };

  return (
    <InputLabel
      width="100%"
      name={name}
      label={label}
      reference={reference}
      value={value}
      onChange={handleInputChanged}
    >
      {errorLabel}
      {value !== '' && !isValidVin && invalidRegistrationLabel}
    </InputLabel>
  );
};

VinInput.defaultProps = {
  reference: undefined,
  errorLabel: null,
  handleIsValidVin: () => {}
};

export default VinInput;

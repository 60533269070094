import { AxiosError } from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import HttpService from '../../../services/http-service';
import { Store } from '../../types';
import { Bill, Billing, ViewsListEnum } from './type';

export const SET_BILLINGS = 'SET_BILLINGS';
export const BILLINGS_LOADING = 'BILLINGS_LOADING';
export const SET_BILL = 'SET_BRANDS';

export type ActionTypes =
  | { type: typeof SET_BILLINGS; payload: Billing[] }
  | { type: typeof BILLINGS_LOADING; payload: boolean }
  | { type: typeof SET_BILL; payload: Bill };

export const setBillings = (billings: Billing[]) => ({
  type: SET_BILLINGS,
  payload: billings,
});

export const billingsLoading = (isLoading: boolean): ActionTypes => ({
  type: BILLINGS_LOADING,
  payload: isLoading,
});

export const setBill = (bill: Bill | null) => ({
  type: SET_BILL,
  payload: bill,
});

export const getBillings =
  (
    garageId: string,
    view: ViewsListEnum,
    date: string,
    failure: (error: Error) => void,
    success: () => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(billingsLoading(true));

    await HttpService.getClient()
      .get(
        `${process.env.REACT_APP_API_BILLING_URL}/billing-data/${view}/${garageId}/${date}`,
        {
          headers: { 'api-key': process.env.REACT_APP_API_GARAGES_KEY },
          params: {},
        }
      )
      .then((res) => {
        dispatch(billingsLoading(true));
        dispatch(setBillings(res.data));
        success();
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 404) {
          dispatch(billingsLoading(false));
          dispatch(setBillings([]));
        } else {
          failure(error);
        }
      });
  };

export const getBill =
  (
    garageId: string,
    type: string,
    date: string,
    failure: (error: Error) => void,
    success: () => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(billingsLoading(true));

    await HttpService.getClient()
      .get(
        `${
          process.env.REACT_APP_API_BILLING_URL
        }/file-data/${garageId}/${type.toUpperCase()}/${date}`,
        {
          headers: { 'api-key': process.env.REACT_APP_API_GARAGES_KEY },
          params: {},
        }
      )
      .then((res) => {
        dispatch(billingsLoading(false));
        dispatch(setBill(res.data));
        success();
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 404) {
          dispatch(billingsLoading(false));
        } else {
          failure(error);
        }
        dispatch(setBill(null));
      });
  };

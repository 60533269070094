import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';

import { useSelector } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Store } from '../../redux/types';
import PlanningFull from './components/planning-full-page';
import RegularWeekAdmin from './components/regular-week-admin';
import StatusView from './components/statuses-page/statusView';
import UpdatePlanning from './components/update-planning';
import UpdateDay from './components/update-planning/day';
import UpdateHolidays from './components/update-planning/holidays';
import { QuoteStatus } from '../../redux/stores/planning/types';
import HeaderPrint from './components/update-planning/headerPrint';
import colors from '../../assets/style-colors';

const PrintPlanning = () => {
  // CONSTANTS:

  const {
    globalReducer: { currentGarage },
  } = useSelector((state: Store) => state);
  if (!currentGarage) return null;

  const { url, path } = useRouteMatch();

  // FUNCTIONS:

  // STYLES:
  const useStyles = makeStyles(() => ({
    main: {
      display: 'none',
      position: 'fixed',
      justifyContent: 'space-between',
      left: '70px',
      right: '0',
    },

    CONFIRMED: {
      backgroundColor: colors.mediumGreen,
    },
    VALIDATED: {
      backgroundColor: colors.azure,
    },
    REPORTED: {
      backgroundColor: colors.pomeGranate,
    },
    CANCELLED: {
      backgroundColor: colors.dark,
    },

    white: { color: colors.white },
  }));

  const classes = useStyles();

  return (
    <div>
      <div className="header">
        <HeaderPrint />
      </div>
      <div className={`${classes.main} noLeft main-screen page`}>
        <Switch>
          <Route path={`${path}/update`}>
            <UpdatePlanning
              pathUpdateDay={`${url}/update-day`}
              pathUpdate={`${url}/update`}
              pathHolidays={`${url}/manage-holidays`}
            />
          </Route>
          <Route path={`${path}/manage-holidays`}>
            <UpdateHolidays pathUpdate={`${url}/update`} />
          </Route>
          <Route path={`${path}/update-day/:index`}>
            <UpdateDay pathUpdate={`${url}/update`} />
          </Route>
          <Route path={`${path}/manage-week`}>
            <RegularWeekAdmin updatePath={`${url}/`} />
          </Route>
          <Route path={`${path}/validated`}>
            <StatusView status={[QuoteStatus.VALIDATED]} />
          </Route>
          <Route path={`${path}/confirmed`}>
            <StatusView status={[QuoteStatus.CONFIRMED]} />
          </Route>
          <Route path={`${path}/reported`}>
            <StatusView status={[QuoteStatus.REPORTED]} />
          </Route>
          <Route path={`${path}/cancelled`}>
            <StatusView status={[QuoteStatus.CANCELLED, QuoteStatus.WALLET]} />
          </Route>
          <Route path={`${path}`}>
            <PlanningFull
              pathDayModif={`${url}/update-day`}
              pathUpdate={`${url}/update`}
            />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

// EXPORT:

export default PrintPlanning;

import React from 'react';
import Row from 'react-bootstrap/Row';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Booking } from '../../../../../redux/stores/planning/types';
import Devis from './devis';

const Bookings = ({
  bookings,
  availableCarservices,
  opacity
}: {
  bookings: Booking[];
  availableCarservices: any;
  opacity: any;
}) => {
  const useStyle = makeStyles({
    element: {
      width: '100%',
      margin: 0,
      justifyContent: 'center'
    }
  });
  const classes = useStyle();
  return (
    <>
      {bookings.map((booking: Booking) => (
        <Row key={booking.bookingId} className={classes.element}>
          <Devis
            data={booking}
            booking={booking}
            availableCarservices={availableCarservices}
            opacity={opacity}
          />
        </Row>
      ))}
    </>
  );
};

export default Bookings;

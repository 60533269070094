import _get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';
import Button from '../../../../assets/generic-components/Button';
import CheckboxLabel from '../../../../assets/generic-components/CheckboxLabel';
import TextaeraLink from '../../../../assets/generic-components/TextaeraLink';
import Title from '../../../../assets/generic-components/Title';
import {
  saveExtraContent,
  saveGarage,
} from '../../../../redux/stores/garages/actions';
import {
  GarageExtraContentLanguages,
  GarageExtraContentServiceAdd,
} from '../../../../redux/stores/garages/type';
import {
  setAdditionalServices,
  setGarage,
  setNotification,
} from '../../../../redux/stores/global/actions';
import {
  AdditionalService,
  AdditionalServicesCategory,
  GlobalStore,
  SeverityMessage,
} from '../../../../redux/stores/global/types';
import { LanguageStore } from '../../../../redux/stores/languages';
import { Store } from '../../../../redux/types';
import MainInformation from '../../MainInformation';
import Header from '../header';

const mapStateToProps = (state: Store) => ({
  ...state,
});

interface ServiceProps {
  className: string;
  languageReducer: LanguageStore;
  globalReducer: GlobalStore;
}

const Service: React.FC<ServiceProps> = ({
  className,
  languageReducer,
  globalReducer,
}) => {
  // Redux
  const lg = languageReducer.language;
  const { additionalServices, currentGarage } = globalReducer;
  const dispatch = useDispatch();

  const enableServiceByIds = (
    service: AdditionalService[],
    ids: string[]
  ): AdditionalService[] => {
    const serviceDuplicate = [...service];
    return serviceDuplicate.map((item) => {
      item.active = !!ids.find((id) => id === item.id);
      return item;
    });
  };

  const checkServiceById = (
    service: AdditionalService[],
    id: string,
    isChecked: boolean
  ): AdditionalService[] => {
    const serviceDuplicate = [...service];
    return serviceDuplicate.map((item) => {
      if (item.id === id) {
        item.active = isChecked;
      }
      return item;
    });
  };

  // state
  const [dataServices, setDataServices] =
    useState<AdditionalServicesCategory | null>({
      serviceCertifications: additionalServices?.serviceCertifications || [],
      servicesPlus: additionalServices?.servicesPlus || [],
    });
  const [showInformations, setShowInformation] = useState(false);
  const [descriptions, setDescriptions] =
    useState<GarageExtraContentServiceAdd>({
      descriptionCertif: {},
      descriptionService: {},
    });

  const { register } = useForm();

  useEffect(() => {
    dispatch(setGarage(currentGarage?.id || ''));
    dispatch(setAdditionalServices());
  }, []);

  useEffect(() => {
    setDataServices({
      serviceCertifications: enableServiceByIds(
        additionalServices?.serviceCertifications || [],
        Object.keys(currentGarage?.servicesAdd || {})
      ),
      servicesPlus: enableServiceByIds(
        additionalServices?.servicesPlus || [],
        Object.keys(currentGarage?.servicesAdd || {})
      ),
    });
  }, [currentGarage?.servicesAdd]);

  // Toogle checkbox
  const handlechangeCheckbox = (
    item: AdditionalService,
    isChecked: boolean
  ) => {
    const { id } = item;
    if (dataServices) {
      const resultPlus = checkServiceById(
        dataServices.servicesPlus,
        id,
        isChecked
      );
      const resultCertif = checkServiceById(
        dataServices.serviceCertifications,
        id,
        isChecked
      );
      setDataServices({
        serviceCertifications: resultCertif,
        servicesPlus: resultPlus,
      });
    }
  };

  const onChangeText = (value: any, key: string) => {
    setDescriptions({
      ...descriptions,
      [key]: value,
    });
  };

  const saveIfExtraContentExist = (
    description: GarageExtraContentLanguages
  ) => {
    const { fr, nl } = description;
    if (fr) {
      dispatch(
        saveExtraContent(fr.lang, fr.field, fr.content, currentGarage?.id || '')
      );
    }
    if (nl) {
      dispatch(
        saveExtraContent(nl.lang, nl.field, nl.content, currentGarage?.id || '')
      );
    }
  };

  const handleServicePageSubmit = () => {
    const enabledServices: Array<string> = [];
    [dataServices?.servicesPlus, dataServices?.serviceCertifications].forEach(
      (serviceTab) => {
        (serviceTab || []).forEach((service) => {
          if (service.active) {
            enabledServices.push(service.id);
          }
        });
      }
    );
    saveIfExtraContentExist(descriptions.descriptionCertif);
    saveIfExtraContentExist(descriptions.descriptionService);
    dispatch(
      saveGarage(
        currentGarage?.id || '',
        { servicesAdd: enabledServices },
        () => {
          dispatch(setGarage(currentGarage?.id || ''));
          dispatch(
            setNotification({
              severity: SeverityMessage.SUCCESS,
              message: lg.general.success,
            })
          );
        },
        () => {
          dispatch(
            setNotification({
              severity: SeverityMessage.ERROR,
              message: lg.error.server,
            })
          );
        }
      )
    );
  };

  return (
    <>
      <Helmet>
        <title>Mon garage | Services</title>
      </Helmet>
      <Header show={showInformations} />
      <div className={`${className} pt-40`}>
        {showInformations ? (
          <MainInformation />
        ) : (
          <>
            <Title title={lg.rates.services} />
            <div className="box custom-checkbox pt-5">
              {dataServices?.serviceCertifications.map(
                (item: AdditionalService) => (
                  <div key={item.id}>
                    <CheckboxLabel
                      onchange={(e) =>
                        handlechangeCheckbox(item, e.target.checked)
                      }
                      checked={item.active}
                      label={_get(lg.account.servicesList, item.id)}
                    />
                  </div>
                )
              )}
            </div>

            <TextaeraLink
              reference={register}
              name="services_certifs_more"
              field="services_certifs_more"
              contents={currentGarage?.garageExtraContent}
              handleChange={(value) => onChangeText(value, 'descriptionCertif')}
            />

            <Title title={lg.rates.servicesPlus} />
            <div className="box custom-checkbox pt-5">
              {dataServices?.servicesPlus.map((item: AdditionalService) => (
                <div key={item.id}>
                  <CheckboxLabel
                    onchange={(e) => {
                      handlechangeCheckbox(item, e.target.checked);
                    }}
                    checked={item.active}
                    label={_get(lg.account.servicesList, item.id)}
                  />
                </div>
              ))}
            </div>

            <TextaeraLink
              reference={register}
              name="others_services_add"
              field="others_services_add"
              contents={currentGarage?.garageExtraContent}
              handleChange={(value) =>
                onChangeText(value, 'descriptionService')
              }
            />

            <div className="mt-e-20 mb-100">
              <Button
                width="274px"
                theme="dark"
                title={lg.rates.save}
                onClick={() => {
                  setShowInformation(false);
                  handleServicePageSubmit();
                }}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default styled(connect(mapStateToProps)(Service))`
  width: 60%;
  margin: 0 10vw;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  .pt60 {
    padding-top: 20px;
    margin-bottom: 100px;
  }
  .box {
    padding-top: 20px;
    display: grid;
    grid-template-columns: repeat(1fr);
  }
  @media screen and (min-width: 425px) {
    .box {
      display: flex;
      flex-wrap: wrap;
      max-width: 580px;

      div {
        width: 50%;
        padding-right: 30px;
      }
    }
  }

  textarea {
    margin-bottom: 50px;
  }

  .custom-checkbox {
    label {
      span {
        padding: 0 9px;

        font-weight: 500;

        span {
          padding: 0;
        }
      }
    }

    > div {
      padding-right: 15px;
    }
  }

  .form-group {
    textarea {
      height: 135px;
    }
  }
  .pt-5 {
    padding-top: 5px !important;
  }
  .pt-40 {
    padding-top: 40px !important;
  }
  .mb-100 {
    margin-bottom: 100px;
  }
`;

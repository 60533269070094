import makeStyles from '@material-ui/core/styles/makeStyles';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '../../../../assets/generic-components/Button';
import colors from '../../../../assets/style-colors';
import add from '../../../../assets/style-elements/01-electrons-icon-add.png';
import arrow from '../../../../assets/style-elements/arrow.svg';
import {
  deleteSpecialDay,
  saveSpecialDays,
} from '../../../../redux/stores/calendar/actions';
import { Availability } from '../../../../redux/stores/calendar/types';

import {
  setCurrentDay,
  updateQuoteStatus,
} from '../../../../redux/stores/planning/actions';

import { Store } from '../../../../redux/types';
import { QuoteStatus } from '../../../../redux/stores/planning/types';
import CheckboxLabel from '../../../../assets/generic-components/CheckboxLabel';

interface UpdateWeekNavbarProps {
  pathConges: string;
}

const UpdateWeekNavbar: React.FC<UpdateWeekNavbarProps> = ({ pathConges }) => {
  const {
    languageReducer,

    planningStore: { currentDay, weekView, bookings: planning },

    globalReducer: { currentGarage },
  } = useSelector((state: Store) => state);
  const dispatch = useDispatch();
  const dayIndex = moment(currentDay).day();
  const lg = languageReducer.language;
  const [closeWeek, setCloseWeek] = useState(false);
  const [show, setShow] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const isLarge = width >= 1440;
  const modeTablet = width <= 1024;
  const history = useHistory();

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
  });

  function handleRedirect(e: any, to: string) {
    e.preventDefault();
    history.push(to);
  }
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const createBookingsProblems = () => {
    if (!planning) return;

    planning.forEach((bookings) => {
      bookings.forEach((booking) =>
        booking.quotes.forEach((quote) => {
          if (booking.bookingId) {
            dispatch(
              updateQuoteStatus(
                booking.bookingId,
                quote.quoteId,
                QuoteStatus.REPORTED,
                'WEEK_CLOSED',
                () => {},
                () => {}
              )
            );
          }
        })
      );
    });
  };

  const closeDays = (open: boolean) => {
    const startOfWeek = moment(currentDay).startOf('week');
    const days = [startOfWeek.format('YYYY-MM-DD')];
    for (let i = 1; i < 6; i++) {
      const day = startOfWeek.add(1, 'day');
      days.push(day.format('YYYY-MM-DD'));
    }
    const availabilities: Availability[] = days.map((day) => ({
      day,
      availability: {
        openingHours: ['08:00-17:00'],
        slots: 0,
      },
    }));

    if (currentGarage && !open) {
      dispatch(
        saveSpecialDays(
          currentGarage.id,
          availabilities,
          () => {
            handleClose();
          },
          () => {}
        )
      );
    }
    if (open && currentGarage) {
      availabilities.forEach((availability) => {
        dispatch(
          deleteSpecialDay(
            currentGarage.id,
            availability.day || '',
            () => {
              handleClose();
            },
            () => {}
          )
        );
      });
    }
  };

  function handleCloseWeek() {
    closeDays(closeWeek);
    setCloseWeek(true);
    createBookingsProblems();
  }

  const getMonthName = () => {
    const month1 =
      lg.planning.menuRight.months[moment(currentDay).day(0).month()];
    const month0 =
      lg.planning.menuRight.months[moment(currentDay).day(6).month()];
    const string1 = month1 === month0 ? month1 : `${month1}/${month0}`;

    const year1 = moment(currentDay).day(0).format('YYYY');

    const year0 = moment(currentDay).day(6).format('YYYY');
    const string2 = year1 === year0 ? year1 : `${year1}/${year0}`;
    return year1 === year0
      ? `${string1} ${string2}`
      : `${month1} ${year1}/${month0} ${year0}`;
  };

  const useStyles = makeStyles(() => ({
    root: {
      position: 'relative',
      width: isLarge ? `${width - 196}px` : `${width - 55}px`,
      top: '0',
      display: 'flex',
      flexDirection: 'row',
      background: 'transparent',
      borderBottom: `1px solid ${colors.paleGreyDrank}`,
      height: '60px',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontWeight: 'bold',
      fontSize: '12px',
      color: colors.black,
    },
    rootLeft: {
      display: 'flex',
      justifyContent: 'start',
      height: '100%',
      alignItems: 'center',
    },
    rootRight: {
      display: 'flex',
      justifyContent: modeTablet ? 'flex-start' : 'flex-end',
      alignItems: 'center',
      height: '100%',
      paddingRight: '20px',
    },
    item: {
      background: colors.white,
      color: colors.black,
      borderRadius: '10px',
      height: '34px',
      padding: '10px',
      margin: '5px',
      border: 'none',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      cursor: 'pointer',
      boxShadow: '0 2px 18px 0 rgba(214,214,214,0.5)',
    },
    arrow: {
      width: '34px',
      '&:focus': {
        outline: 'none',
        border: 'none',
      },
    },
    week: {
      width: '80px',
      height: '18px',
      borderRadius: '4px',
      fontSize: '9px',
      fontWeight: 500,
      textAlign: 'center',
      color: colors.slateGrey,
      margin: '5px',
      backgroundColor: colors.veryLightBlue,
      lineHeight: '18px',
    },
    month: {
      fontSize: '14px',
      fontWeight: 500,
      margin: '10px',
      width: 'auto',
    },
    checkF: {
      '& span': {
        color: colors.slateGrey,
        '&:last-child': {
          whiteSpace: 'nowrap',
          marginRight: '30px',
        },
      },
    },
    box: {
      display: 'flex',
      alignItems: 'center',
    },
    rootLeftCuz: {
      justifyContent: 'center',
    },
    wrapButtonAdd: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    arrowRight: {
      transform: 'rotate(180deg)',
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={`${classes.rootLeft} ${classes.rootLeftCuz}`}>
        <button
          type="button"
          className={`${classes.item} ${classes.arrow}`}
          onClick={() => {
            if (weekView) {
              dispatch(
                setCurrentDay(
                  moment(currentDay).subtract(7, 'days').toISOString()
                )
              );
            } else {
              const day0 = moment(currentDay).day(0).toISOString();
              const newDay = moment(currentDay)
                .subtract(1, 'days')
                .toISOString();
              dispatch(setCurrentDay(dayIndex === 0 ? day0 : newDay));
            }
          }}
        >
          <img src={arrow} alt="" />
        </button>
        <button
          type="button"
          className={`${classes.item} ${classes.arrow}`}
          onClick={() => {
            if (weekView) {
              dispatch(
                setCurrentDay(moment(currentDay).add(7, 'days').toISOString())
              );
            } else {
              const day0 = moment(currentDay).day(0).toISOString();
              const newDay = moment(currentDay).add(1, 'days').toISOString();
              dispatch(setCurrentDay(dayIndex === 0 ? day0 : newDay));
            }
          }}
        >
          <img src={arrow} alt="" className={classes.arrowRight} />
        </button>
        <div className={classes.month}>{getMonthName()}</div>

        <div className={classes.week}>{`${lg.planning.week} ${moment(
          currentDay
        ).week()}`}</div>
      </div>
      <div className={classes.rootRight}>
        <div className={classes.box}>
          <div className={classes.checkF}>
            <CheckboxLabel
              checked={closeWeek}
              onchange={() => handleShow()}
              label={lg.planning.modification.closeWeek}
            />
          </div>
          <div
            className={classes.wrapButtonAdd}
            onKeyPress={() => {}}
            role="button"
            tabIndex={0}
            onClick={(e) => handleRedirect(e, pathConges)}
          >
            <Button theme="dark">
              {modeTablet ? (
                <img src={add} alt=" " />
              ) : (
                lg.planning.menuRight.button
              )}
            </Button>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {!closeWeek
              ? lg.planning.modification.modalClose
              : lg.planning.modification.modalReopen}
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button theme="dark" onClick={handleClose}>
            {lg.planning.modification.cancel}
          </Button>
          <Button theme="dark" onClick={() => handleCloseWeek()}>
            {lg.planning.modification.confirm}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UpdateWeekNavbar;

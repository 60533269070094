import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Store } from '../../../../../redux/types';
import { Booking, Quote, QuoteStatus } from '../../../../../redux/stores/planning/types';
import useStyles from '../style';
import editIcon from '../../../../../assets/style-elements/Icon-edit.svg';
import userIcon from '../../../../../assets/style-elements/01-electrons-icon-user-gay@3x.png';
import { BookingType } from '../../../../../redux/stores/quotes/types';
import BtnSendingSms from '../../notificationSms';
import { Networks } from '../../../../../redux/stores/global/types';
import EditContact from '../../../modal/editContact';

interface InfosProps {
  currentBooking: Booking;
  isCustomerAdditionalDataCanDisplay: () => boolean
}

const Infos = ({ currentBooking, isCustomerAdditionalDataCanDisplay }: InfosProps) => {

  const lg = useSelector((state: Store) => state.languageReducer.language);

  const classes = useStyles();

  const isGarageOwner = currentBooking.networkId === Networks.GARAGE_OWNER;

  const isPhoneValid =
    currentBooking.customer.phone !== null &&
    (currentBooking.customer.phone?.startsWith('+33') ||
      currentBooking.customer.phone?.startsWith('+32'));

  const isDateValid = moment(currentBooking.bookingDate).isSameOrBefore(
    moment(),
    'day'
  );

  const isStatusValid = (quote: Quote) =>
    quote.status === QuoteStatus.CONFIRMED ||
    (currentBooking.networkId === BookingType.GARAGE_OWNER &&
      quote.status === QuoteStatus.VALIDATED);

  const canSendSms =
    isPhoneValid && isDateValid && currentBooking.quotes.some(isStatusValid);
  
  return (
    <div className={classes.donateItemWrapper}>
      <img className="leftIcon" src={userIcon} alt="contact detail icon" />
      <div className="donateList">
        <div className="donateItem">
          <p className="first">{lg.command.lateral.detailContact}</p>

          <p className="second">
            {`
              ${
                currentBooking.customer.firstName
                  ? currentBooking.customer.firstName
                  : ''
              } 
              ${currentBooking.customer.lastName}
            `}
          </p>
          {isCustomerAdditionalDataCanDisplay() && (
            <>
              <p className="second">{currentBooking.customer.phone}</p>

              <p className="second">{currentBooking.customer.email}</p>
            </>
          )}

          {canSendSms && <BtnSendingSms />}
        </div>
      </div>
      {isGarageOwner && (
        <div className="editButton">
          <EditContact>
            <img className="rightIcon" src={editIcon} alt="edit icon" />
          </EditContact>
        </div>
      )}
    </div>
  );
}

export default Infos;
const facturationActions = {};

facturationActions.setCurrentContext = (dispath, data) => {
  dispath({
    type: 'SET_CURRENT_CONTEXT',
    payload: data
  });
};

export default facturationActions;

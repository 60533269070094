import { Action } from 'redux';
import { AxiosError } from 'axios';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import HttpService from '../../../services/http-service';
import { Store } from '../../types';
import {
  Booking,
  FixedPrice,
  Liquid,
  PartOperation,
  Quote,
  RepairTime,
} from '../planning/types';
import { Customer, Make, QuoteResponse, Vehicle, VehicleVin } from './types';
import { setNotification } from '../global/actions';
import {
  CarService,
  CountryCodes,
  Networks,
  ResponseCode,
  SeverityMessage,
} from '../global/types';
import {
  getDefaultPricelessPayload,
  getQuoteByVehicleAndService,
} from '../../../services/quote/quote-service';
import { getVehicleDetailsById } from '../../../services/vehicle-identification-service';

export const SET_QUOTES = 'SET_QUOTES';
export const ADD_QUOTE = 'ADD_QUOTE';
export const SET_VEHICLE = 'SET_VEHICLE';
export const SET_CUSTOMER = 'SET_CUSTOMER';
export const SET_REGISTRATION_NUMBER = 'SET_REGISTRATION_NUMBER';
export const SET_VIN_CODE = 'SET_VIN_CODE';
export const SET_MAKES_LIST = 'SET_MAKES_LIST';
export const SET_TYRES_OPTIONS = 'SET_TYRES_OPTIONS';
export const SET_SELECTED_CAR_SERVICES = 'SET_SELECTED_CAR_SERVICES';
export const SET_KM_VEHICLE = 'SET_KM_VEHICLE';
export const SET_RELEASE_DATE = 'SET_RELEASE_DATE';
export const RESET_STORE = 'RESET_STORE';

export type ActionTypes =
  | { type: typeof RESET_STORE }
  | { type: typeof SET_QUOTES; payload: Quote[] }
  | { type: typeof SET_VEHICLE; payload: Vehicle | null }
  | { type: typeof SET_CUSTOMER; payload: Customer }
  | { type: typeof SET_REGISTRATION_NUMBER; payload: string }
  | { type: typeof SET_VIN_CODE; payload: string }
  | { type: typeof SET_KM_VEHICLE; payload: number | null }
  | { type: typeof SET_RELEASE_DATE; payload: Date | null }
  | { type: typeof SET_MAKES_LIST; payload: Make[] | null }
  | { type: typeof SET_TYRES_OPTIONS; payload: Record<string, any> | null }
  | { type: typeof ADD_QUOTE; payload: Quote }
  | {
      type: typeof SET_SELECTED_CAR_SERVICES;
      payload: Record<string, CarService>;
    };

export const setQuotes = (quotes: Quote[]): ActionTypes => ({
  type: SET_QUOTES,
  payload: quotes,
});

export const addQuote = (quote: Quote): ActionTypes => ({
  type: ADD_QUOTE,
  payload: quote,
});

export const setTyresOptions = (
  options: Record<string, any> | null
): ActionTypes => ({
  type: SET_TYRES_OPTIONS,
  payload: options,
});

export const setMakesList = (makes: Make[] | null): ActionTypes => ({
  type: SET_MAKES_LIST,
  payload: makes,
});

export const setSelectedCarServices = (
  carServicesSelected: Record<string, CarService>
): ActionTypes => ({
  type: SET_SELECTED_CAR_SERVICES,
  payload: carServicesSelected,
});

export const setVehicle = (vehicle: Vehicle | null): ActionTypes => ({
  type: SET_VEHICLE,
  payload: vehicle,
});

export const setRegistrationNumber = (
  registrationNumber: string
): ActionTypes => ({
  type: SET_REGISTRATION_NUMBER,
  payload: registrationNumber,
});

export const setVinCode = (vinCode: string): ActionTypes => ({
  type: SET_VIN_CODE,
  payload: vinCode,
});

export const setKmVehicle = (kmVehicle: number | null): ActionTypes => ({
  type: SET_KM_VEHICLE,
  payload: kmVehicle,
});

export const setReleaseDate = (releaseDate: Date | null): ActionTypes => ({
  type: SET_RELEASE_DATE,
  payload: releaseDate,
});

export const updateQuoteValues = (baseQuote: Quote) => {
  const totalPrice = baseQuote.operations.reduce(
    (total, o) => total + o.total,
    0
  );
  const totalMaxPrice = baseQuote.operations.reduce(
    (total, o: any) => total + (o.totalMax || o.total),
    0
  );
  const updatedValues = {
    price: {
      minPriceExclTax: totalPrice,
      maxPriceExclTax: totalMaxPrice,
    },
    commission: {
      ...baseQuote.commission,
      garage: (totalPrice * 10) / 100,
    },
  };
  return { ...baseQuote, ...updatedValues } as Quote;
};

const catchError = (
  dispatch: ThunkDispatch<Store, unknown, Action<string>>,
  failure: { (error: Error): void },
  error: Error
) => {
  dispatch(setVehicle(null));
  failure(error);
};

const catchSuccess = (
  dispatch: ThunkDispatch<Store, unknown, Action<string>>,
  success: { (newVehicleId: string): void },
  vehicle: Vehicle
) => {
  dispatch(setVehicle(vehicle));
  success(vehicle.id);
};

export const getVehicleByPlateNumber =
  (
    plateNumber: string,
    success: (newVehicleId: string) => void,
    failure: (error: Error) => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    HttpService.getClient()
      .get(
        `${process.env.REACT_APP_API_CAR_IDENTIFICATION_URL}/search/registration/${plateNumber}`,
        {
          headers: {
            'api-key': process.env.REACT_APP_API_CAR_IDENTIFICATION_KEY,
            'x-network-id': Networks.IDG,
            countryCode: CountryCodes.FR,
          },
        }
      )
      .then((res) => res.data[0])
      .then((vehicle) => {
        catchSuccess(dispatch, success, vehicle);
      })
      .catch((error: Error) => {
        catchError(dispatch, failure, error);
      });
  };

export const updateOperationInQuotes =
  (
    newOperation: PartOperation | RepairTime | Liquid | FixedPrice,
    quote: Quote
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const quotesList = getState().quotesReducer.quotes || [];
    const quoteToUpdate = quotesList?.find(
      (currQuote) => currQuote.carServiceId === quote.carServiceId
    );

    if (!quoteToUpdate) {
      return;
    }

    quoteToUpdate.operations = quoteToUpdate?.operations.map(
      (currOperation) => {
        if (currOperation.operationId === newOperation.operationId) {
          return newOperation;
        }
        return currOperation;
      }
    );

    const updatedQuotes: Quote[] = [];
    quotesList.map((newQuote) =>
      updatedQuotes.push(updateQuoteValues(newQuote))
    );
    dispatch(setQuotes(updatedQuotes));
  };

export const getVehicleById =
  (
    vehicleId: string,
    success: () => void,
    failure: (error: Error) => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    getVehicleDetailsById(vehicleId)
      .then((vehicle) => {
        dispatch(setVehicle(vehicle));
        success();
      })
      .catch((error: Error) => {
        catchError(dispatch, failure, error);
      });
  };

// TODO authorize to quote only for the current garage
// TODO multi quotes refactor backend api
// TODO error management
export const getQuotes =
  (
    serviceIds: string[],
    vehicleId: string,
    garageId: string,
    success: () => void,
    failure: (error: any) => void,
    km?: number | null,
    releaseDate?: Date | null
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    const promises: Array<Promise<QuoteResponse>> = serviceIds.map(
      (serviceId) =>
        new Promise((resolve, reject) =>
          getQuoteByVehicleAndService(
            vehicleId,
            serviceId,
            garageId,
            km,
            releaseDate
          )
            .then((res) => resolve(res.data))
            .catch((e: AxiosError) => {
              if (e?.response?.status === ResponseCode.NOT_ACCEPTABLE) {
                resolve({
                  data: [getDefaultPricelessPayload(serviceId)],
                });
              }
              reject(e);
            })
        )
    );
    Promise.all(promises)
      .then((res) => res.flatMap((r) => r.data[0]).filter((r) => r !== null))
      .then((quotes: Quote[]) =>
        dispatch(setQuotes(quotes.filter((quote) => quote)))
      )
      .then(success)
      .catch(failure);
  };

export const makeBooking =
  (
    booking: Booking,
    success: (bookingId: string) => void,
    failure: (error: Error) => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch, getState) => {
    HttpService.getClient()
      .post(`${process.env.REACT_APP_API_PLANNING_URL}/bookings`, booking, {
        headers: {
          'api-key': process.env.REACT_APP_API_PLANNING_KEY,
          'x-network-id': Networks.IDG,
          countryCode: CountryCodes.FR,
        },
      })
      .then((resp) => {
        dispatch(setVehicle(null));
        dispatch(setRegistrationNumber(''));
        dispatch(setQuotes([]));
        success(resp.headers.bookingid);
      })
      .catch((e) => {
        if (e?.response?.status === ResponseCode.CONFLICT) {
          dispatch(
            setNotification({
              severity: SeverityMessage.ERROR,
              message: getState().languageReducer.language.planning.conflict,
            })
          );
        } else {
          failure(e);
        }
      });
  };

export const getVehicleMakes =
  (): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch, getState) => {
    HttpService.getClient()
      .get(`${process.env.REACT_APP_API_CAR_IDENTIFICATION_URL}/makes`, {
        headers: {
          'api-key': process.env.REACT_APP_API_CAR_IDENTIFICATION_KEY,
          'x-network-id': Networks.IDG,
          countryCode: CountryCodes.FR,
        },
      })
      .then((res) => {
        dispatch(
          setMakesList(
            (res.data || []).map((brand: Make) => ({
              label: brand.label,
              value: brand.id,
            }))
          )
        );
      })
      .catch(() => {
        dispatch(setMakesList(null));
        dispatch(
          setNotification({
            severity: SeverityMessage.ERROR,
            message: getState().languageReducer.language.error.server,
          })
        );
      });
  };
export const getVehicleByVinCode =
  (
    vinNumber: string,
    success: (newVehicleId: string) => void,
    failure: (error: Error) => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    HttpService.getClient()
      .get(
        `${process.env.REACT_APP_API_VEHICLE_VIN_URL}/vins/${vinNumber}?country-code=be`,
        {
          headers: {
            'api-key': process.env.REACT_APP_API_VEHICLE_VIN_KEY,
            countryCode: CountryCodes.BE,
            'x-network-id': Networks.GARAGE_OWNER,
          },
        }
      )
      .then(
        (res) =>
          res?.data?.data?.sort(
            (
              a: { score: number; vehicleId: string },
              b: { score: number; vehicleId: string }
            ) => b.score - a.score
          )[0]
      )

      .then((vehicle: VehicleVin) => getVehicleDetailsById(vehicle?.vehicleId))
      .then((vehicle) => {
        catchSuccess(dispatch, success, vehicle);
      })
      .catch((error: Error) => {
        catchError(dispatch, failure, error);
      });
  };

import axios, { AxiosInstance, Method, ResponseType } from 'axios';
import { ValidateFunction } from 'ajv';
import HttpHandler from './HttpHandler';

declare module 'axios' {
  export interface AxiosRequestConfig {
    validate?: ValidateFunction;
  }
}

export class Requestable {
  client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      timeout: 7000,
      headers: { 'content-type': 'application/json' }
    });
    new HttpHandler(this.client);
  }

  request(
    method: Method,
    url: string,
    validate: ValidateFunction,
    params = {},
    data = {},
    headers = {},
    format = 'json',
    timeout = 70000,
    responseType = 'json' as ResponseType
  ) {
    const doRequest = () =>
      this.client.request({
        baseURL: process.env.NEXT_PUBLIC_API_URL,
        url,
        method,
        data,
        headers,
        validate,
        params: {
          'api-key': process.env.REACT_APP_API_GARAGES_KEY,
          ...params,
          _format: format
        },
        timeout,
        responseType
      });
    return doRequest();
  }
}

export default Requestable;


import { VehicleEnergy } from '../../../utils/vehicle';
import { Quote } from '../planning/types';
import { CarService } from '../global/types';

export enum QuoteCalculMode {
  PACKAGE = 'package',
  REAL = 'real',
}

export interface Customer {
  id?: string;
  lastName: string;
  firstName?: string;
  email?: string;
  phone?: string;
  comments?: string;
}

export interface QuoteResponse {
  data: Quote[];
}

interface Tyres {
  catalog: Array<{}>;
  options: Record<string, any> | null;
}

export interface QuoteStore {
  quotes: Quote[] | null;
  currentVehicle: Vehicle | null;
  currentRegistrationNumber: string | null;
  customer: Customer | null;
  makesList: Make[] | null;
  tyres: Tyres;
  selectedCarServices: Record<string, CarService>;
  kmVehicle: number | null;
  releaseDate: Date | null;
}

export interface Make {
  id: string;
  label: string;
}

export interface Model {
  id: string;
  make: Make;
  label: string;
}

export interface Version {
  id: string;
  model: Model;
  label: string;
}

export enum EnergyType {
  B = 'BIFUEL',
  D = 'GAZOLE',
  E = 'ESSENCE',
  F = 'SUPERETHANOL',
  G = 'G P L',
  H = 'ELECTRIQUE+ESSENCE',
  I = 'ELECTRIQUE+GAZOLE',
  L = 'ELECTRIQUE',
  V = 'G N V',
}

export enum QuoteType {
  PRICELESS = 'PRICELESS',
  ONLINE = 'ONLINE',
}

export enum QuoteStatus {
  VALIDATED = 'VALIDATED',
  REPORTED = 'REPORTED',
  CONFIRMED = 'CONFIRMED',
  CANCELLED = 'CANCELLED',
  WALLET = 'WALLET',
}

export enum BookingType {
  GARAGE_OWNER = 'GARAGE-OWNER',
}

export enum QuoteIds {
  FLEXFUEL_SERVICEID = '760101',
}

export interface Vehicle {
  id: string;
  version: Version;
  ktypnr: number;
  numord: number;
  numords: number;
  label: string;
  serieStartDate: string;
  serieEndDate: string;
  typeBv: string;
  carBody: string;
  doorsNumber: number;
  energyType: VehicleEnergy;
  source: string;
  ltb: string;
  type: string;
  typeMine: string[];
  gearBox: string;
  cylinderSize: number;
  cylinderNumber: number;
  cylinderType: string;
  realPower: number;
  fiscalPower: number;
  powerSupply: string;
  labelMotor: string;
  flexFuel?: boolean | null;
  releaseDate: string | null;
}

export interface VehicleVin {
  vehicleId: string;
}

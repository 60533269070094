import React from 'react';
import { Roles } from '../../services/authentication-service';
import AccountIcon from '../../assets/images/svg/accountIcon';
import BillingIcon from '../../assets/images/svg/billingIcon';
import PlanningIcon from '../../assets/images/svg/planningIcon';
import QuoteIcon from '../../assets/images/svg/quoteIcon';
import RatesIcon from '../../assets/images/svg/ratesIcon';
import VisibilityIcon from '../../assets/images/svg/visibilityIcon';
import TimeIcon from '../../assets/images/svg/timeIcon';

export interface MenuItem {
  name: string;
  href: string;
  icon: React.SVGProps<SVGSVGElement>;
  roles: Roles[];
  class: string;
}

const PlaningMenu = {
  name: 'planning',
  href: '/planning',
  class: 'menu',
  roles: [Roles.GARAGE, Roles.CALL_CENTER],
  icon: PlanningIcon,
};

const AccountMenu = {
  name: 'account',
  href: '/account',
  class: 'menu',
  roles: [Roles.GARAGE, Roles.CALL_CENTER, Roles.SAD],
  icon: AccountIcon,
};

const RatesMenu = {
  name: 'rates',
  href: '/rates',
  class: 'menu',
  roles: [Roles.GARAGE, Roles.CALL_CENTER],
  icon: RatesIcon,
};

const QuoteMenu = {
  name: 'quote',
  href: '/quote',
  class: 'iconDevis',
  roles: [Roles.GARAGE, Roles.CALL_CENTER],
  icon: QuoteIcon,
};

const VisibilityMenu = {
  name: 'visibility',
  href: '/visibility',
  class: 'menu',
  roles: [Roles.GARAGE, Roles.CALL_CENTER],
  icon: VisibilityIcon,
};

const BillingMenu = {
  name: 'billing',
  href: '/billing',
  class: 'menu',
  roles: [Roles.GARAGE, Roles.CALL_CENTER],
  icon: BillingIcon,
};

const TimeMenu = {
  name: 'time',
  href: '/account/opening-hours',
  class: 'menu',
  roles: [Roles.GARAGE, Roles.CALL_CENTER],
  icon: TimeIcon,
};

const HolidaysMenu = {
  name: 'holidays',
  href: '/planning/manage-holidays',
  class: 'menu',
  roles: [Roles.GARAGE, Roles.CALL_CENTER],
  icon: PlanningIcon,
};

export const menuItems: MenuItem[] = [
  PlaningMenu,
  AccountMenu,
  RatesMenu,
  QuoteMenu,
  VisibilityMenu,
  BillingMenu,
  TimeMenu,
  HolidaysMenu,
];

export const disabledAccountMenuItems: MenuItem[] = [PlaningMenu, BillingMenu];

export const garageFranchiseMenuItems: MenuItem[] = [
  PlaningMenu,
  AccountMenu,
  QuoteMenu,
  VisibilityMenu,
  BillingMenu,
  TimeMenu,
  HolidaysMenu,
];

/**
 * LOCALE
 */
export enum Locale {
  FR = 'fr',
  NL = 'nl',
}

export enum ResponseCode {
  NOT_ACCEPTABLE = 406,
  CONFLICT = 409,
}

export interface Range<T> {
  min: T;
  max: T;
}

/**
 * CARSERVICE
 */

interface Supplier {
  [key: string]: string;
}

export interface CarService {
  id: string;
  label: string;
  categories: CarServiceCategory[];
  active: boolean;
  fuel: string;
  forfaitisable?: boolean;
  typeDevis: 'ONLINE' | 'PRICELESS';
  family: string;
  subfamily: string;
  mechanicalRateLevel: number;
  isComplex: boolean;
  operations: CarServiceOperations[];
  suppliers?: {
    id: number;
    lang: string;
    suppliers?: Supplier;
  };
}

export interface CarServiceOperations {
  id: string;
  label: string;
  type: string;
  family: string;
  subfamily: string;
  physicalPosition: string;
  forfaitisable: boolean;
  familyFoc: string;
  necessary: boolean;
  defaultPrice: number;
  itemmpid: string;
  rateTb: number;
  defaultCapacity: number;
  genart: string;
  repairId: string;
}

export enum CarServiceType {
  CAR_MAINTENANCE_SCHEDULE = '100601',
}

interface CarServiceCategory {
  id: string;
  label: string;
}

/**
 * GARAGE
 */

export interface ServicesAdd {
  rep_facilire_paie?: string;
  rep_garage_mobile?: string;
  rep_service_domicile?: string;
}

export interface Location {
  lat: number;
  lon: number;
}

export interface GarageAddres {
  address1: string;
  address2: string;
  zipcode: string;
  city: string;
  slugCity: string;
  country: string;
  location: Location;
}

export interface GarageBillingInfo {
  iban: string;
  bic: string;
}

export interface StandardWeek {
  normalDelay: number;
  complexDelay: number;
  rdvDay1: number;
  rdvDay2: number;
  rdvDay3: number;
  rdvDay4: number;
  rdvDay5: number;
  rdvDay6: number;
  rdvDay7: number;
  scheduleDay1: string;
  scheduleDay2: string;
  scheduleDay3: string;
  scheduleDay4: string;
  scheduleDay5: string;
  scheduleDay6: string;
  scheduleDay7: string;
}

export interface GarageCalendar {
  standardWeek: StandardWeek;
  exceptionalWeeks?: any;
}

export interface GarageNetwork {
  networkId: string;
  externalCodeId: number;
  createdAt: string[];
  updatedAt: string[];
}

export interface GarageBrand {
  brand: string;
  slugBrand: string;
  tradeMark: string;
}

export interface GarageExtraContent {
  lang: string;
  field: string;
  content: string;
}

export interface Membership {
  activatedAt: string[];
  amount: number;
  trial: number;
  comments: string;
  enrolledBy: string;
}

export enum CONFIDENCE {
  STANDARD = 'STANDARD',
  PREMIUM = 'PREMIUM',
}

export interface Garage {
  id: string;
  codis: number;
  civility: string;
  lastname: string;
  firstname: string;
  password: string;
  companyName: string;
  slugCompanyName: string;
  siret: string;
  email: string;
  phone: string;
  mobilePhone: string;
  fax: string;
  numrcs: string;
  cliCertificateNumber: string;
  servicesAdd: ServicesAdd;
  url: string;
  logo: string;
  state: string;
  parrain: string;
  superAdmin: boolean;
  confidence: CONFIDENCE;
  confidenceUpdateAt: string[];
  dms: string;
  garageAddress: GarageAddres;
  garageBillingAddress?: any;
  garageBillingInfo?: GarageBillingInfo;
  garageCalendar: GarageCalendar;
  garageNetwork: GarageNetwork[];
  garageBrand: GarageBrand;
  garageExtraContent: GarageExtraContent[];
  createdAt: string[];
  updatedAt: string[];
  distance?: number | null;
  favoriteLanguage: string;
  sadPartenaireIds: any[];

  membership?: Membership;

  garageTest: boolean;
  autossimo?: AutossimoLogin | null;
}

export interface AutossimoLogin {
  login: string;
  password: string;
}

export enum SeverityMessage {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success',
}

export interface MessageNotification {
  severity: SeverityMessage;
  message: string;
}

export interface GarageService {
  subscriptionId: string;
  garageId: string;
  serviceId: string;
}

export interface GlobalStore {
  currentGarage: Garage | undefined | null;
  currentLocale: Locale;
  availableCarservices: Record<string, CarService>;
  notification: MessageNotification | null;
  additionalServices: AdditionalServicesCategory | null;
  garageServices: GarageService[] | null;
}

export interface AdditionalService {
  id: string;
  title?: string;
  active: boolean;
}

export interface AdditionalServicesCategory {
  serviceCertifications: AdditionalService[];
  servicesPlus: AdditionalService[];
}

export enum Networks {
  IDG = 'IDG',
  AD = 'AD',
  GARAGE_OWNER = 'GARAGE-OWNER',
}

export enum CountryCodes {
  FR = 'fr',
  NL = 'nl',
  BE = 'be',
}

export enum VisibilityTabs {
  Visibility = '/visibility/visibility',
  Sale = '/visibility/sales',
}
export enum CountryCodesUppercase {
  FR = 'FR',
  BE = 'BE',
}

export const Countries = [
  { label: 'Belgique', value: CountryCodesUppercase.BE },
  { label: 'France', value: CountryCodesUppercase.FR },
];

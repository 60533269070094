import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import colors from '../../../../assets/style-colors';
import { QuoteStatus } from '../../../../redux/stores/planning/types';
import { Store } from '../../../../redux/types';
import Bookings from '../planning-full-page/components/bookings';

interface StatusViewProps {
  status: QuoteStatus[];
}

const StatusView: React.FC<StatusViewProps> = ({ status }) => {
  // CONSTANTS:
  const {
    planningStore: { nextBookings },
  } = useSelector((state: Store) => state);
  const {
    globalReducer: { availableCarservices },
  } = useSelector((state: Store) => state);
  const [height, setHeight] = useState(window.innerHeight);
  const bookings = nextBookings?.filter(
    (b) => b.quotes.filter((q) => status.includes(q.status)).length > 0
  );

  const handleWindowSizeChange = () => {
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
  }, []);

  // STYLES:
  const useStyles = makeStyles(() => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      height: `calc(${height}px - 200px)`,
      width: '100%',
      gap: '10px',
      overflowX: 'hidden',
      overflow: 'scroll',
      padding: '10px',
      '&::-webkit-scrollbar': {
        width: '4px',
        height: '4px',
        borderRadius: '2px',
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        width: '4px',
        height: '4px',
        margin: '2px',
        background: colors.lightGreyBlue,
      },
    },
    mgR: {
      '& button': {
        marginRight: '6px',
      },
    },
    ticket: {
      background: colors.white,
      padding: '20px 20px 0 20px',
      margin: '0',
      borderTop: `1px solid ${colors.WhiteDark}`,
      height: 'fit-content',
      borderRadius: '4px',
      marginBottom: '10px',

      '&:first-child': {
        borderTop: 'unset',
      },

      '&:last-child': {
        marginBottom: '0',
      },
    },
    ticketsBoxSd: {
      boxShadow: '0 2px 18px 0 rgba(136, 135, 135, 0.17)',
      marginBottom: '1em',
    },
    weekday: {
      fontSize: '13px',
      fontWeight: 'bold',
      padding: '32px 0 15px',
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      marginLeft: '0',
      color: colors.dark,

      '&:first-child': {
        padding: '4px 0 10px',
      },
    },
    day: {
      padding: '10px',
      fontSize: '25px',
    },
    hours: {
      padding: '5px',
      marginLeft: '0',
      fontSize: '9px',
      color: colors.slateGrey,
      borderRadius: '4px',
      height: 'fit-content',
    },
    name: {
      display: 'block',
      whiteSpace: 'nowrap' /* forces text to single line */,
      overflow: 'hidden',
      textOverflow: 'ellipsis',

      fontSize: '15px',
    },

    F14: {
      fontSize: '14px',
    },

    F15: {
      fontSize: '15px',
    },

    nameFb: {
      fontSize: '14px',
    },

    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: '24px',
    },
    image: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '93px',
      height: '93px',
      borderRadius: '4px',
      border: `1px solid ${colors.whiteLight}`,
    },
    devis: {
      color: colors.white,
      textAlign: 'center',
      borderRadius: '4px',
      padding: '0 8px',
      maxWidth: 'fit-content',
      height: '25px',
      lineHeight: '25px',

      width: '252px',
    },
    item: {
      color: colors.dark,
      // padding: '5px',
      width: 'fit-content',
      height: 'fit-content',
      display: 'flex',

      '& img': {
        display: 'inline-block',
      },

      '& div': {
        display: 'inline-block',
      },

      '& span': {},
    },
    body: {
      display: 'flex',
      flexDirection: 'row',
      height: 'fit-content',
    },
    CONFIRMED: {
      backgroundColor: colors.mediumGreen,
    },

    VALIDATED: {
      backgroundColor: colors.azure,
    },
    REPORTED: {
      backgroundColor: colors.pomeGranate,
    },
    CANCELLED: {
      backgroundColor: colors.dark,
    },
    WALLET: {
      backgroundColor: colors.dark,
    },
    PL10: {
      paddingLeft: '10px',
    },
    PL20: {
      paddingLeft: '20px',
    },
    wrapLeftInfo: {
      display: 'flex',
    },
    imgLeftInfo: {
      width: '65px',
      height: '41px',
    },
    timeLeftInfo: {
      fotSize: '14px',
      fontWeight: 500,
    },
    iconPlanning: {
      width: '14px',
    },
    itemHours: {
      width: '100px',
    },
    wrapBottomItem: {
      width: '100%',
      justifyContent: 'space-between',
      padding: '20px 0',
      marginBottom: '0',
      alignItems: 'center',
    },
    itemCenter: {
      width: '230px',
      marginLeft: '10px',
      display: 'flex',
      alignItems: 'center',
    },
    itemTotalRate: {
      width: '230px',
      marginLeft: '30px',
      display: 'flex',
    },
  }));
  const classes = useStyles();

  if (!bookings) return null;

  return (
    <div className={classes.root}>
      <Bookings
        bookings={bookings}
        availableCarservices={availableCarservices}
        opacity={1}
      />
    </div>
  );
};
export default StatusView;

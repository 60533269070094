import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { range } from 'lodash';
import Button from '../../../assets/generic-components/Button';
import InputLabel from '../../../assets/generic-components/InputLabel';
import InputMutipleSelect from '../../../assets/generic-components/InputMutipleSelect';
import TextaeraLabel from '../../../assets/generic-components/TextaeraLabel';
import Title from '../../../assets/generic-components/Title';
import { Store } from '../../../redux/types';
import Calendar from '../../planning-page/components/month-view-right/calendar';
import DayView from '../../planning-page/components/planning-full-page/components/day';
import {
  getBooking,
  setCurrentDay,
} from '../../../redux/stores/planning/actions';
import ImmatriculationInput from '../../../atomic/components/organisms/ImmatriculationInput';
import { getNewBookingPayload } from '../../../services/booking/booking-creation-service';
import {
  DEFAULT_PRICELESS_SERVICE_ID,
  getDefaultPricelessPayload,
} from '../../../services/quote/quote-service';
import {
  CountryCodes,
  MessageNotification,
  SeverityMessage,
} from '../../../redux/stores/global/types';
import { setNotification } from '../../../redux/stores/global/actions';
import { makeBooking } from '../../../redux/stores/quotes/actions';
import * as ROUTES from '../../../router/routes';
import { getDateSelectionRange } from '../../../utils/calendar';
import VinInput from '../../../atomic/components/organisms/VinInput/vinInput';
import PhoneField from '../../../assets/generic-components/phoneInput';
import AddAppointmentModal from './addAppointmentModal';
import useStyles from './style';

const durations = range(1, 16).map((d) => ({
  label: d.toString(),
  value: d.toString(),
}));

interface AddAppointmentProps {
  className: string;
}

type FormValues = {
  lastName: string;
  firstName: string;
  email: string;
  phone: string;
  comments: string;
  date: string;
  immatriculation?: string;
  duration: string;
};

const AddAppointment: React.FC<AddAppointmentProps> = () => {
  const {
    languageReducer: { language: lg },
    planningStore: { currentDay },
    quotesReducer: { currentVehicle },
    globalReducer: { currentGarage },
  } = useSelector((state: Store) => state);

  const history = useHistory();
  const dispatch = useDispatch();
  const [modalActive, setModalActive] = useState(false);
  const [displayCalendar, setDisplayCalendar] = useState(false);
  const [hour, setHour] = useState(getDateSelectionRange()[0]);
  const [isValidPlate, setIsValidPlate] = useState(false);
  const [noteValue, setNoteValue] = useState('');
  const [lateralDay, setLateralDay] = useState(
    moment(currentDay).toISOString()
  );
  const classes = useStyles();

  const { register, handleSubmit, errors, setError, control } =
    useForm<FormValues>({
      mode: 'onChange',
    });
  const isGarageBe = currentGarage?.garageAddress.country === CountryCodes.BE;

  if (!currentGarage) return null;

  const failure = (error: Error) => {
    const notification: MessageNotification = {
      severity: SeverityMessage.ERROR,
      message: `${lg.error.server}: ${error.message}`,
    };
    dispatch(setNotification(notification));
  };

  const onSubmit = (formData: FormValues) => {
    if (!isValidPlate && formData.immatriculation) {
      setError('immatriculation', {
        type: 'manual',
        message: lg.quote.invalidRegistration,
      });
      return;
    }
    const defaultPricelessPayload = getDefaultPricelessPayload(
      DEFAULT_PRICELESS_SERVICE_ID,
      Number(formData.duration ?? 1)
    );

    const newBooking = getNewBookingPayload({
      currentGarage,
      customer: {
        lastName: formData.lastName,
        firstName: formData.firstName || undefined,
        email: formData.email || undefined,
        phone: formData.phone || undefined,
        comments: formData.comments,
      },
      registrationNumber: formData.immatriculation || null,
      vehicleId: currentVehicle?.id,
      bookingDate: moment(currentDay),
      hour,
      quotes: [defaultPricelessPayload],
    });

    dispatch(
      makeBooking(
        newBooking,
        (bookingId: string) => {
          dispatch(
            getBooking(bookingId, () => history.push(ROUTES.COMMAND), failure)
          );
        },
        failure
      )
    );
  };

  const [width, setWidth] = useState(window.innerWidth);
  const modeTablet = width <= 1024;
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div
          onKeyPress={() => {}}
          role="button"
          tabIndex={0}
          onClick={() => history.goBack()}
          className={classes.boxTitle}
        >
          <div className={classes.backward}>
            <KeyboardArrowLeftIcon className={classes.backwardGrey} />
            <p>{lg.quote.back}</p>
          </div>
        </div>
        {modalActive && (
          <AddAppointmentModal
            active={modalActive}
            setActive={() => setModalActive(false)}
            hour={hour}
          />
        )}

        <Title title={lg.quote.addQuoteWithoutQuote} />

        <>
          <InputLabel
            label={lg.quote.lastName}
            name="lastName"
            reference={register({
              required: true,
              maxLength: 20,
            })}
          >
            {errors.lastName &&
              errors.lastName.type === 'required' &&
              lg.quote.messageField}
          </InputLabel>

          <InputLabel
            label={lg.quote.firstname}
            name="firstName"
            reference={register({
              required: false,
              maxLength: 20,
            })}
          />

          <InputLabel
            name="email"
            label={lg.quote.email}
            reference={register({
              required: false,
              pattern: {
                message: lg.quote.messageEmail,
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              },
            })}
          >
            {errors.email &&
              errors.email.type === 'pattern' &&
              lg.quote.messageEmail}
          </InputLabel>
          <div className={classes.phoneWrapper}>
            <span className="labelPhone">{lg.quote.phone}</span>
            <Controller
              as={
                <PhoneField
                  reset={undefined}
                  errorMessage={lg.quote.messagePhone}
                />
              }
              control={control}
              name="phone"
              defaultValue=""
              reference={register({
                required: false,
              })}
            />
            {errors.phone && errors.phone.type === 'pattern'}
          </div>
          {isGarageBe ? (
            <VinInput
              invalidRegistrationLabel={lg.quote.invalidRegistrationVin}
              label={lg.quote.registrationLabelCodeVin}
              reference={register({ required: false })}
              name="vinCode"
              handleIsValidVin={setIsValidPlate}
            />
          ) : (
            <ImmatriculationInput
              label={lg.quote.registrationLabel}
              invalidRegistrationLabel={lg.quote.invalidRegistration}
              errorLabel=""
              reference={register({
                required: false,
              })}
              handleIsValidPlate={setIsValidPlate}
              name="immatriculation"
            />
          )}
          <InputLabel
            name="without-quote"
            label={lg.quote.prestation}
            placeholder={lg.planning.withoutDevis}
            disabled
          />
        </>

        <div className={classes.boxInputDateTime}>
          <div className={classes.wrapCalendar}>
            <label htmlFor="Button">{lg.quote.date}</label>
            <Button
              onClick={() => setDisplayCalendar(!displayCalendar)}
              width="579px"
              theme="light"
              title={moment(currentDay).format('DD MMMM YYYY')}
            />
            {displayCalendar && (
              <Calendar
                onSelect={(date) => {
                  dispatch(setCurrentDay(date.toISOString()));
                  setLateralDay(date.toISOString());
                  setDisplayCalendar(false);
                }}
              />
            )}
          </div>
          <InputMutipleSelect
            className={classes.wrapHour}
            width="100px"
            reference={register}
            name="hours"
            label={lg.quote.hour}
            datas={getDateSelectionRange().map((r) => ({ label: r, value: r }))}
            onChange={(e) => setHour(e.target.value)}
          />
          <InputMutipleSelect
            className={classes.wrapSl}
            width="100px"
            name="duration"
            reference={register}
            datas={durations}
            label={lg.quote.timeOfRDV}
          />
        </div>

        <div>
          <TextaeraLabel
            length={noteValue?.length || 0}
            onChange={(e) => setNoteValue(e.target.value)}
            name="comments"
            reference={register}
            label={lg.quote.noteRDV}
          />
        </div>

        {modeTablet ? null : (
          <div className={classes.containerBtn}>
            <Button
              type="submit"
              onClick={handleSubmit(onSubmit)}
              theme="dark"
              title={lg.quote.validRDV}
            />
          </div>
        )}
      </div>
      {modeTablet ? null : (
        <div className={classes.containerOrder}>
          <h5 className={classes.dayViewHead}>{lg.quote.dayOfRDV}</h5>
          <DayView
            lateralMode
            currentDay={lateralDay}
            setCurrentDay={setLateralDay}
          />
        </div>
      )}
      {modeTablet ? (
        <div className={classes.containerBtnTablet}>
          <div className="box-btn">
            <Button
              type="submit"
              onClick={handleSubmit(onSubmit)}
              theme="dark"
              title={lg.quote.validRDV}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default AddAppointment;

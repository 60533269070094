import {
  ActionTypes,
  SET_BILLINGS_CLAIM,
  BILLINGS_CLAIM_LOADING
} from './action';
import { BillingsClaimStore } from './type';

const initialState: BillingsClaimStore = {
  currentBillingsClaim: [],
  billingsClaimLoading: true
};

const BillingsClaimReducer = (
  state: BillingsClaimStore = initialState,
  action: ActionTypes
) => {
  switch (action.type) {
    case SET_BILLINGS_CLAIM:
      return { ...state, currentBillingsClaim: action.payload };
    case BILLINGS_CLAIM_LOADING:
      return { ...state, billingsClaimLoading: action.payload };
    default:
      return state;
  }
};
export default BillingsClaimReducer;

import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Button from '../../../../assets/generic-components/Button';
import CheckboxLabel from '../../../../assets/generic-components/CheckboxLabel';
import { Store } from '../../../../redux/types';
import { CarService } from '../../../../redux/stores/global/types';
import QuoteChoicesCarService from './quoteChoicesCarService';
import useStyles from './style';

type QuoteDropdownProps = {
  open: boolean;
  setOpen: () => void;
  carServices: Record<string, CarService>; 
  removeCarService: (id: string) => void;
  search: string;
  setSearch: (e: { target: { value: React.SetStateAction<string> } }) => void;
  carServicesCloned: CarService[];
  selectCarservice: (e: any) => void;
  submit: () => void;
};

const QuoteDropdown = ({
  open,
  setOpen,
  carServices,
  removeCarService,
  search,
  setSearch,
  carServicesCloned,
  selectCarservice,
  submit
}: QuoteDropdownProps) => {
  const lg = useSelector((state: Store) => state.languageReducer.language);
  const classes = useStyles();

  return (
    <Dropdown onToggle={setOpen} show={open}>
      <Dropdown.Toggle>{lg.quote.choice}</Dropdown.Toggle>

      <Dropdown.Menu>
        <div className={classes.dropdownMenu}>
          <div className={classes.list}>
            {Object.values(carServices).map((c: any) => (
              <QuoteChoicesCarService
                carService={c}
                key={c.id}
                handleOnRemoveCarService={removeCarService}
              />
            ))}
          </div>

          <input
            className={classes.input}
            value={search}
            placeholder={lg.quote.search}
            onChange={setSearch}
          />
          <div className={classes.boxDropdow}>
            {carServicesCloned.map(carservice => (
              <div key={carservice.id}>
                <CheckboxLabel
                  checked={carservice.id in carServices}
                  label={carservice.label}
                  onchange={() => selectCarservice(carservice)}
                />
                <Dropdown.Divider />
              </div>
            ))}
            {carServicesCloned.length === 0 && (
              <div className={classes.resultfound}>
                <p>{lg.quote.emptySearch}</p>
              </div>
            )}
          </div>
          <div className={classes.boxBtn}>
            <Button
              onClick={submit}
              width="100%"
              title={lg.quote.save}
              theme="dark"
            />
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default QuoteDropdown;

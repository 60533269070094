import React from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Store } from '../../../../redux/types';
import logo from '../../../../assets/style-elements/01-electrons-icon-time-big.png';
import Button from '../../../../assets/generic-components/Button';
import { Booking } from '../../../../redux/stores/planning/types';

type ModalProps = {
  active: boolean;
  setActive: () => void;
  booking: Booking | null;
  submit: (event: any) => void;
};

const QuoteByAppointmentConfirmModal = ({
  active,
  setActive,
  booking,
  submit
}: ModalProps) => {
  const lg = useSelector((state: Store) => state.languageReducer.language);

  return (
    <Modal show={active} onHide={setActive}>
      <Modal.Header closeButton className="cus-moldal">
        <Modal.Title>{lg.quote.confirmation}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p className="f-b-14">
          {`${lg.quote.command} N° ${booking?.bookingId}`}
        </p>
        <p className="f-bk-14 w-60">
          {`${booking?.customer.lastName} ${booking?.customer.firstName}`}
        </p>
        <p className="f-m-14 pt-5">
          {`${moment(booking?.bookingDate).format('DD MMM YYYY')} ${
            booking?.bookingDepositHour
          }`}
        </p>
        <div className="d-flex">
          <div>
            <img src={logo} alt="logo" className="logo" />
          </div>
          <p className="px-2">{booking?.duration}h</p>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          onClick={submit}
          theme="dark"
          title={lg.quote.backPlanning}
          className="textWhite"
        />
      </Modal.Footer>
    </Modal>
  );
};

export default QuoteByAppointmentConfirmModal;

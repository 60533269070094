import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import * as ROUTES from '../../../router/routes';
import {
  FixedPrice,
  Liquid,
  PartOperation,
  Quote,
  RepairTime,
} from '../../../redux/stores/planning/types';
import QuoteOperationLine from '../../../atomic/components/organisms/QuoteOperationLine';
import { Store } from '../../../redux/types';
import { getTotals } from '../../../utils/booking';
import useStyles from '../commandStyles';
import { QuoteCalculMode } from '../../../redux/stores/quotes/types';
import { formatPrice } from '../../../services/quote/quote-service';
import { isOperationUpdatable } from '../../../services/quote/operation';
import Button from '../../../assets/generic-components/Button';

interface TableProps {
  quote: Quote;
  vat: number;
  onOperationQtyDiscountUpdate?: (
    operation: PartOperation | RepairTime | Liquid | FixedPrice
  ) => void;
  updateBookingQuote: () => void;
  cancelUpdateBookingQuote: () => void;
  isSummaryUpdatable?: any;
}

const Table: React.FC<TableProps> = ({
  quote,
  vat,
  onOperationQtyDiscountUpdate,
  updateBookingQuote,
  cancelUpdateBookingQuote,
  isSummaryUpdatable,
}) => {
  const {
    languageReducer,
    globalReducer: { availableCarservices },
  } = useSelector<Store, Store>((state) => state);
  const garageService = useSelector((state: Store) =>
    state.globalReducer.garageServices?.find(
      (service) => service.serviceId === quote.carServiceId
    )
  );
  const currentServiceDetail = useSelector(
    (state: Store) =>
      state.globalReducer.availableCarservices[quote.carServiceId]
  );
  const calculMode = _get(
    garageService,
    'service.calculMode',
    QuoteCalculMode.REAL
  );
  const lg = languageReducer.language;

  const classes = useStyles();
  const totalQuote = getTotals([quote], vat);

  const servicesLabel: Array<{ id: string; label: string }> = useMemo(
    () =>
      Object.entries(availableCarservices).reduce<
        Array<{ id: string; label: string }>
      >((services, curr) => {
        const [serviceId, serviceDetails] = curr;
        services.push({ id: serviceId, label: serviceDetails?.label });
        (serviceDetails?.operations || []).forEach((operation) => {
          services.push({ id: operation.id, label: operation.label });
        });
        return services;
      }, []),
    [availableCarservices]
  );

  const getServiceLabelById = (serviceId: string): string =>
    servicesLabel.find(
      (service) => service.id.toString() === serviceId.toString()
    )?.label || serviceId;

  const headers: { label: string; className: string }[] = [
    { label: lg.quote.designation, className: 'td_tiny_head_start' },
    { label: lg.quote.unitHT, className: 'td_tiny_head_end' },
    { label: lg.quote.quantity, className: 'td_tiny_head_end' },
    { label: lg.quote.discount, className: 'td_tiny_head_end' },
    { label: lg.quote.ht, className: 'td_tiny_head_end' },
    { label: lg.quote.ttc, className: 'td_tiny_head_end' },
  ];

  const packageableOperations = availableCarservices[
    quote.carServiceId
  ].operations
    .filter((o) => o.forfaitisable)
    .map((p) => p.id.toString());
  const operationsInPackage = quote.operations.filter((o) =>
    packageableOperations.includes(o.operationId)
  );
  const packageOperation = quote.operations
    .filter((o) => o.operationId === 'PACKAGE')
    .pop();
  const quoteOperations = packageOperation
    ? quote.operations
        .filter((o) => !packageableOperations.includes(o.operationId))
        .filter((o) => !o.operationId.includes('PACKAGE'))
    : quote.operations;

  const operationDetails = (currentServiceDetail?.operations || []).find(
    (operationService) =>
      operationService.id.toString() === packageOperation?.operationId
  );

  const isUpdatable = isOperationUpdatable(
    getServiceLabelById(packageOperation ? packageOperation.operationId : ''),
    calculMode,
    operationDetails,
    onOperationQtyDiscountUpdate
  );

  const [isDisabled, setIsDisabled] = useState(true);

  const onInputChange = () => {
    const inputs = Array.from(
      document.getElementsByClassName('operationLineInput')
    );
    inputs.forEach((item) => {
      item.addEventListener('change', () => setIsDisabled(false));
    });
  };

  const isPageUpdatable = ROUTES.QUOTE_SUMMARY
    ? isSummaryUpdatable
    : isUpdatable;

  return (
    <div className={classes.table}>
      <table className="table">
        <tbody>
          <tr className={`tr ${isPageUpdatable ? 'inputGrid' : ''}`}>
            {headers.map((header, index) => (
              <th key={index} className={header.className}>
                <b>{header.label}</b>
              </th>
            ))}
          </tr>

          {packageOperation && (
            <QuoteOperationLine
              key={packageOperation.operationId}
              operation={packageOperation}
              label={getServiceLabelById(packageOperation.operationId)}
              locale={lg.locale}
              vat={vat}
              onOperationQtyDiscountUpdate={onOperationQtyDiscountUpdate}
              isUpdatable={isUpdatable}
              isSummaryUpdatable={isPageUpdatable}
              onInputChange={onInputChange}
            />
          )}
          {packageOperation &&
            operationsInPackage.map((operation) => (
              <QuoteOperationLine
                key={operation.operationId}
                isPackaged
                operation={operation}
                label={getServiceLabelById(operation.operationId)}
                locale={lg.locale}
                vat={vat}
                onOperationQtyDiscountUpdate={onOperationQtyDiscountUpdate}
                isUpdatable={isUpdatable}
                isSummaryUpdatable={isPageUpdatable}
                onInputChange={onInputChange}
              />
            ))}

          {quoteOperations.map((operation) => (
            <QuoteOperationLine
              key={operation.operationId}
              operation={operation}
              label={getServiceLabelById(operation.operationId)}
              locale={lg.locale}
              vat={vat}
              onOperationQtyDiscountUpdate={onOperationQtyDiscountUpdate}
              isUpdatable={isUpdatable}
              isSummaryUpdatable={isSummaryUpdatable}
              onInputChange={onInputChange}
            />
          ))}

          <tr className={`tr ${isPageUpdatable ? 'inputGrid' : ''}`}>
            <td className="td_tiny_withoutBorder_start" />
            <td className="td_tiny_total_end" />
            <td className="td_tiny_total_end" />
            <td className="td_tiny_total_end">
              <span>{formatPrice(totalQuote.totalDiscount, lg.locale)}</span>
            </td>
            <td className="td_tiny_total_end">
              <span>
                {formatPrice(totalQuote.totalWithDiscounts, lg.locale)}
              </span>
            </td>
            <td className="td_tiny_total_end">
              <span>
                {formatPrice(
                  totalQuote.totalWithDiscounts * (1 + vat / 100),
                  lg.locale
                )}
              </span>
            </td>
            <td className="td_tiny_total_end" />
          </tr>

          {isPageUpdatable && (
            <tr>
              <td className="edit-admin-actions">
                <Button
                  theme="dark"
                  disabled={isDisabled}
                  tabIndex={0}
                  onClick={() => updateBookingQuote()}
                >
                  <span className="span2">{lg.general.confirm}</span>
                </Button>
                <Button
                  theme="light"
                  tabIndex={0}
                  onClick={() => {
                    setIsDisabled(true);
                    cancelUpdateBookingQuote();
                  }}
                >
                  <span className="span1">{lg.general.cancel}</span>
                </Button>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

Table.defaultProps = {
  onOperationQtyDiscountUpdate: undefined,
  isSummaryUpdatable: false,
};

export default React.memo(Table);

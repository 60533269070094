import React from 'react';
import { connect, useSelector } from 'react-redux';
import styled from 'styled-components';
import colors from '../../../../assets/style-colors';

const Wrapper = styled.div`
  padding-top: 20px;
  width: 100%;
  height: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-bottom: 1px solid ${colors.WhiteDark};
  border-top: 1px solid ${colors.WhiteDark};
  padding-bottom: 26px;
`;
const Company = styled.p`
  padding-left: 10px;
  margin: 0;
  color: ${colors.dark};
  font-size: 20px;
  position: relative;
  padding-right: 15px;

  font-weight: bold;

  &:after {
    height: 17px;
    width: 2px;
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    background: ${colors.lightGreyBlue};
    transform: translateY(-50%);
  }
`;

const FullName = styled.p`
  padding-left: 15px;
  margin: 0;
  color: ${colors.slateGrey};
  font-size: 20px;
`;

const BoxBtn = styled.div`
  display: flex;
  padding-right: 20px;
  align-items: center;
`;

const Information = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
`;

interface HeaderProps {
  children?: JSX.Element;
  className?: string;
  show: boolean;
}

const mapStateToProps = (state: any) => ({
  ...state
});

const Header = ({ children, className, show }: HeaderProps) => {
  const languageReducer = useSelector((state: any) => state.languageReducer);
  const currentGarage = useSelector(
    (state: any) => state.globalReducer.currentGarage
  );
  const lg = languageReducer.language;

  return (
    <Wrapper className={className}>
      <Information>
        {!show ? (
          <>
            <Company>{currentGarage.companyName}</Company>
            <FullName>
              {currentGarage.civility} {currentGarage.lastname}{' '}
              {currentGarage.firstName}
            </FullName>
          </>
        ) : (
          <div>
            <Company>{lg.account.title}</Company>
          </div>
        )}
      </Information>
      <BoxBtn className="custom-h">{children}</BoxBtn>
    </Wrapper>
  );
};

Header.defaultProps = {
  children: null,
  className: null
};

export default styled(connect(mapStateToProps)(Header))`
  .logo {
    padding-left: 15px;
  }

  .custom-h {
    height: 34px;
    padding: 0 11px;

    button {
      height: 34px;
      padding: 0 11px;
    }
  }
`;

import makeStyles from '@material-ui/core/styles/makeStyles';
import colors from '../../../assets/style-colors';

const useStyles = makeStyles(() => ({
  container: {
    minHeight: '100vh',
    width: '100%',
    margin: 'auto auto 100px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '50px',
    '& h4': {
      fontSize: '20px',
    },
  },
  wrapper: {
    display: 'flex',
    width: '559px',
    minHeight: '100vh',
    flexDirection: 'column',
  },
  boxTitle: {
    cursor: 'pointer',
  },
  backward: {
    fontSize: '12px',
    color: `${colors.slateGrey}`,
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
    '& svg': {
      fontSize: '17px',
    },
    '& p': {
      margin: 0,
      padding: 0,
    },
  },
  backwardGrey: {
    color: `${colors.slateGrey}`,
  },
  phoneWrapper: {
    '& .labelPhone': {
      color: `${colors.dark}`,
      height: '14px',
      fontSize: '12px',
      lineHeight: 1.17,
      marginBottom: '10px',
    },
    '& span': {
      display: 'block',
    },
  },
  boxInputDateTime: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '595px',
    alignItems: 'center',
    marginBottom: '15px',

    '& .calendar': {
      position: 'absolute',
    },
  },
  wrapHour: {
    width: '110px',
    height: '70px',
    marginLeft: '10px',
    '& select': {
      width: '110px',
    },

    '& .form-group': {
      marginBottom: 0,
    },
  },
  wrapSl: {
    width: '203px',
    height: '70px',
    '& .form-group': {
      marginLeft: '20px',
      marginBottom: 0,
    },
  },
  wrapCalendar: {
    width: '236px',
    height: '70px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: '4px',

    '& label': {
      fontSize: '12px',
    },
    '& .positionAbl': {
      '& > div': {
        position: 'absolute',
      },
    },
    '& > button': {
      outline: 'none',
      width: '231px',
      height: '40px',
      border: 'none',
    },
  },
  containerOrder: {
    display: 'flex',
    width: '30vw',
    minWidth: '340px',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: `${colors.white}`,
    boxShadow: '0 2px 18px 0 rgba(136, 135, 135, 0.17)',
    borderRadius: '10px',
    marginLeft: '20px',
    padding: '20px',
    marginTop: '6px',
  },
  containerBtn: {
    marginTop: '20px',
    marginBottom: '20px',
    '& .box-btn': {
      display: 'flex',
      justifyContent: 'center',
      '& button[type="submit"]': {
        marginRight: 0,
      },
    },
  },
  dayViewHead: {
    paddingTop: '20px',
  },

  containerBtnTablet: {
    position: 'fixed',
    bottom: 0,
    left: '55px',
    right: 0,
    backgroundColor: `${colors.white}`,
    padding: '10px 0',
    width: '100%',
    '& .box-btn': {
      display: 'flex',
      justifyContent: 'flex-start',
      paddingLeft: '30px',
      width: '100%',
      '& button[type="submit"]': {
        marginRight: 0,
      },
    },
  },
}));

export default useStyles;

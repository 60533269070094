import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import InputLabel from './InputLabel';

interface InputPasswordProps {
  name: string;
  label: string;
  theme: string;
  reference?: React.LegacyRef<HTMLInputElement>;
  onChange?: (e: {
    target: { value: React.SetStateAction<string | undefined> };
  }) => void;
  value?: string;
}

const InputPassword: React.FC<InputPasswordProps> = ({
  name,
  label,
  theme,
  reference,
  onChange,
  value,
}) => {
  const [isHiddenValue, setIsHiddenValue] = useState(true);
  const useStyles = makeStyles({
    inputPassword: {
      position: 'relative',
      marginBottom: '15px',
    },
    eye: {
      position: 'absolute',
      right: '10px',
      bottom: '35px',
      fontSize: '20px',
      cursor: 'pointer',
    },
  });
  const classes = useStyles();

  return (
    <div className={classes.inputPassword}>
      <InputLabel
        name={name}
        type={isHiddenValue ? 'password' : 'text'}
        label={label}
        theme={theme}
        reference={reference}
        onChange={onChange}
        value={value}
      />
      <VisibilityIcon
        className={classes.eye}
        onClick={() => setIsHiddenValue(!isHiddenValue)}
      />
    </div>
  );
};

InputPassword.defaultProps = {
  reference: null,
  onChange: () => {},
  value: '',
};

export default InputPassword;

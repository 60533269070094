import moment from 'moment';
import {
  ActionTypes,
  DISPLAY_BOOKING,
  SET_BOOKING,
  SET_BOOKING_HISTORIES,
  SET_BOOKINGS,
  SET_BOOKINGS_RESULTS,
  SET_CURRENT_DAY,
  SET_NEXT_BOOKINGS,
  SET_WEEK_VIEW,
  UPDATE_PLANNING_MODE,
  SET_PLANNING_IS_LOADING,
  SET_SMS_IS_SENDING,
  SET_BOOKING_QUOTES,
  RESET_STORE
} from './actions';
import { PlanningStore } from './types';

const initialState: PlanningStore = {
  today: moment().toISOString(),
  currentDay: moment().toISOString(),
  planingHasSendedSMS: [],
  displayBooking: false,
  updatePlanningMode: false,
  weekView: true,
  isPlanningLoading: true
};

const planningStore = (
  state: PlanningStore = initialState,
  action: ActionTypes
) => {
  switch (action.type) {
    case RESET_STORE:
      return initialState;
    case SET_BOOKING:
      return { ...state, currentBooking: action.payload };
    case SET_BOOKING_HISTORIES:
      return state.currentBooking
        ? {
            ...state,
            currentBooking: {
              ...state.currentBooking,
              bookingHistories: action.payload
            }
          }
        : state;
    case SET_BOOKINGS:
      return {
        ...state,
        bookings: action.payload,
        isPlanningLoading: false
      };
    case SET_BOOKINGS_RESULTS:
      return {
        ...state,
        bookingsResults: action.payload,
        isPlanningLoading: false
      };
    case SET_NEXT_BOOKINGS:
      return { ...state, nextBookings: action.payload };
    case DISPLAY_BOOKING:
      return { ...state, displayBooking: action.payload };
    case UPDATE_PLANNING_MODE:
      return { ...state, updatePlanningMode: action.payload };
    case SET_WEEK_VIEW:
      return { ...state, weekView: action.payload };
    case SET_CURRENT_DAY:
      return { ...state, currentDay: action.payload };
    case SET_PLANNING_IS_LOADING:
      return { ...state, isPlanningLoading: action.payload };
    case SET_SMS_IS_SENDING:
      return {
        ...state,
        planingHasSendedSMS: [...state.planingHasSendedSMS, action.payload]
      };
    case SET_BOOKING_QUOTES:
      return state.currentBooking
        ? {
            ...state,
            currentBooking: {
              ...state.currentBooking,
              quotes: action.payload,
              index: 1
            }
          }
        : state;
    default:
      return state;
  }
};

export default planningStore;

import useSWRImmutable from 'swr/immutable';
import { isEmpty } from 'lodash';
import HttpService from '../http-service';
import { CountryCodes, Networks } from '../../redux/stores/global/types';

export const TYRES_SERVICE_ID = '220101';

export type TyresTypes = 'type' | 'width' | 'aspect' | 'rim' | 'load' | 'speed';

export interface TyresFieldConfig {
  label: string;
  id: TyresTypes;
  dependency: TyresTypes | null;
  placeholder: string;
}

export enum TyresQuality {
  PREMIUM = 'PREMIUM',
  BUDGET = 'BUDGET',
  QUALITY = 'QUALITY',
}

export enum TyresSeasons {
  SUMMER = 'Été',
  WINTER = 'Hiver',
  ALL_SEASONS = '4 saisons',
}

export const TYRES_FIELDS: Array<TyresFieldConfig> = [
  { label: 'Type de pneu', id: 'type', placeholder: 'Tous', dependency: null },
  { label: 'Largeur', id: 'width', placeholder: '205', dependency: null },
  { label: 'Hauteur', id: 'aspect', placeholder: '55', dependency: 'width' },
  { label: 'Diamètre', id: 'rim', placeholder: '16', dependency: 'aspect' },
  { label: 'Charge', id: 'load', placeholder: '91', dependency: 'rim' },
  { label: 'Vitesse', id: 'speed', placeholder: 'V', dependency: 'load' },
];

const getParametersOptions = (options: any) =>
  HttpService.getClient()
    .get(
      `${process.env.REACT_APP_API_TYRES_URL}/carservices/tyres/parameters`,
      {
        headers: {
          'api-key': process.env.REACT_APP_API_TYRES_KEY,
          'x-network-id': Networks.IDG,
          countryCode: CountryCodes.FR,
        },
        params: {
          ...options,
        },
      }
    )
    .then((response) => response.data);

const getTyresTypes = () =>
  HttpService.getClient()
    .get(
      `${process.env.REACT_APP_API_TYRES_URL}/carservices/tyres/aggregate/type`,
      {
        headers: {
          'api-key': process.env.REACT_APP_API_TYRES_KEY,
          'x-network-id': Networks.IDG,
          countryCode: CountryCodes.FR,
        },
      }
    )
    .then((response) =>
      response.data.map((type: string) => (isEmpty(type) ? 'Tous' : type))
    );

export const useTyresOptions = (options: any) => {
  const { data: parameters } = useSWRImmutable(Object.values(options), () =>
    getParametersOptions(options)
  );
  const { data: tyresTypes } = useSWRImmutable('/tyres/types', getTyresTypes);

  return { ...parameters, types: tyresTypes };
};

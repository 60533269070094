import React from 'react';
import { useSelector } from 'react-redux';
import useStyles from './style';
import { Store } from '../../../../redux/types';
import Button from '../../../../assets/generic-components/Button';
import Calendar from '../../../planning-page/components/month-view-right/calendar';
import InputMutipleSelect from '../../../../assets/generic-components/InputMutipleSelect';

type QuoteInputDateTimeProps = {
  /**
   *  Whether or not the device is a tablet.
  */
  isTablet: boolean;
  /**
   *  Function to display or not the Calendar.
  */
  displayFunc: (event: React.MouseEvent<HTMLElement>) => void;
  /**
   *  Label of the button.
  */
  label: string;
  /**
   *  Whether or not the Calendar is displayed.
  */
  isDisplayed: boolean;
  /**
   *  Current day.
  */
  day: string;
  /**
   *  Function to set the booking date.
  */
  onSelect: (date: any) => void;
  /**
   *  Set the hour.
  */
  setHour: (e: { target: { value: React.SetStateAction<string> } }) => void;
  /**
   *  Reference.
  */
  reference: React.LegacyRef<HTMLInputElement>;
  /**
   *  Array of options.
  */
  datas: any[];
};

const QuoteInputDateTime = ({
  isTablet,
  displayFunc,
  label,
  isDisplayed,
  day,
  onSelect,
  setHour,
  reference,
  datas
}: QuoteInputDateTimeProps) => {
  const classes = useStyles();
  const lg = useSelector((state: Store) => state.languageReducer.language);

  return (
    <div className={classes.boxInputDateTime}>
      <div className={classes.fromBox}>
        <div className={classes.wrapCalendar}>
          <label htmlFor="Button">
            {isTablet ? lg.quote.date.slice(0, 4) : lg.quote.date}
          </label>
          <Button
            onClick={displayFunc}
            width="579px"
            theme="light"
            title={label}
          />
          {isDisplayed && <Calendar initialDay={day} onSelect={onSelect} />}
        </div>
        <InputMutipleSelect
          onChange={setHour}
          className={classes.wrapHour}
          reference={reference}
          name="hours"
          label={lg.quote.hour}
          datas={datas}
        />
      </div>
    </div>
  );
};

export default QuoteInputDateTime;

import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Store } from '../../../redux/types';
import colors from '../../../assets/style-colors';

interface NavigationProps {
  activePath: string;
  setActivePath: (path: string) => void;
}

const Navigation: React.FC<NavigationProps> = ({
  activePath,
  setActivePath,
}) => {
  const useStyles = makeStyles(() => ({
    leftNavBar: {
      display: 'flex',
      alignItems: 'center',
      height: '70px',
      justifyContent: 'start',
      background: colors.white,
    },
    help: {
      cursor: 'pointer',
      padding: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      color: colors.lightGreyBlue,
    },
    helping: {
      borderBottom: `${colors.primary} 4px solid`,
      paddingBottom: '17px',

      '& a': {
        color: colors.black,
      },
    },
    header: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1',
    },
    link: {
      color: colors.lightGreyBlue,
      fontSize: '14px',
      fontWeight: 500,
      textDecoration: 'none',
      padding: '0 10px 0 10px',

      '&:hover': {
        textDecoration: 'none',
        color: colors.lightGreyBlue,
      },
    },
  }));

  const classes = useStyles();
  const history = useHistory();
  const helpStore = useSelector((state: Store) => state.helpStore);
  const { helpCategories } = helpStore;
  const [paths, setPaths] = useState([
    {
      path: 'homeHelp',
      label: 'Accueil',
    },
  ]);
  const { url } = useRouteMatch();

  useEffect(() => {
    setPaths([
      { path: 'homeHelp', label: 'Accueil' },
      ...(helpCategories || []).map((category) => ({
        label: category.categoryLabel,
        path: category.path,
      })),
    ]);
  }, [helpCategories]);

  return (
    <div className={classes.header}>
      <ul className={classes.leftNavBar}>
        {paths.map((item, index) => (
          <li
            key={index}
            className={`
              ${classes.help} 
              ${item.path === activePath && classes.helping}`}
            onClick={() => {
              setActivePath(item.path);
              history.push(`${url}/${item.path}`);
            }}
          >
            <a className={classes.link}>{item.label}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Navigation;

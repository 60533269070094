import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '../../../../assets/generic-components/Button';
import colors from '../../../../assets/style-colors';
import { saveCalendar } from '../../../../redux/stores/calendar/actions';
import { setNotification } from '../../../../redux/stores/global/actions';
import { Store } from '../../../../redux/types';
import Item from './item';
import { SeverityMessage } from '../../../../redux/stores/global/types';

interface RegularWeekAdminProps {
  updatePath: string;
}

const RegularWeekAdmin: React.FC<RegularWeekAdminProps> = ({ updatePath }) => {
  // STYLES:
  const useStyles = makeStyles(() => ({
    root: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '5% 20% 10% 20%',
      fontSize: '12px',
      color: colors.slateGrey,
      fontWeight: 'bold',
    },
    header: {
      fontSize: '20px',
      fontWeight: 'bold',
      paddingBottom: '20px',
    },
    row: {
      display: 'flex',
      flexWrap: 'wrap',
      position: 'relative',
      justifyContent: 'space-between',
    },
  }));

  // CONSTANTS:
  const {
    languageReducer,
    globalReducer: { currentGarage },
    calendarStore: { calendar },
  } = useSelector((state: Store) => state);
  const dispatch = useDispatch();
  const lg = languageReducer.language;
  const classes = useStyles();
  const history = useHistory();
  const workingHours: {
    days: number;
    weekends: number;
  } = calendar?.regularWeek.reduce(
    (acc, day, dayIndex) => {
      if (dayIndex < 5 && +day.slots > 0) return { ...acc, days: +day.slots };
      if (dayIndex === 5) return { ...acc, weekends: +day.slots };
      return acc;
    },
    { days: 0, weekends: 0 }
  ) || { days: 0, weekends: 0 };

  // STATES:
  // const [cars, setCars] = useState(dataWeekAPI.cars);
  const [weekends, setWeekends] = useState(workingHours.weekends);
  const [weekdays, setWeekdays] = useState(workingHours.days);
  const [delay, setDelay] = useState(calendar?.delays.t1 || 0);

  function handleChanges() {
    if (currentGarage && calendar) {
      const newCalendar = { ...calendar };
      newCalendar.delays.t1 = +delay;
      newCalendar.delays.t2 = +delay;
      newCalendar.delays.t3 = +delay;
      newCalendar.regularWeek = newCalendar.regularWeek.map((w, index) => {
        if (index < 5 && w.slots !== 0) w.slots = +weekdays;
        if (index >= 5 && w.slots !== 0) w.slots = +weekends;
        return w;
      });
      dispatch(
        saveCalendar(
          currentGarage.id,
          newCalendar,
          false,
          () =>
            dispatch(
              setNotification({
                severity: SeverityMessage.SUCCESS,
                message: languageReducer.language.general.success,
              })
            ),
          () =>
            dispatch(
              setNotification({
                severity: SeverityMessage.ERROR,
                message: languageReducer.language.error.server,
              })
            )
        )
      );
    }

    history.push(updatePath);
  }

  const updatePeriod =
    (callback: Function, value: number) => (e: React.MouseEvent) => {
      const target = e.currentTarget as HTMLInputElement;
      const update = Number.isInteger(Number(target.value))
        ? target.value
        : value;
      callback(update >= 0 ? update : 0);
    };

  return (
    <Container className={classes.root}>
      <div className={`${classes.row} ${classes.header}`}>
        {lg.planning.modification.semaineType.header}
      </div>

      <Item
        header={lg.planning.modification.semaineType.delay}
        value={delay}
        onChange={updatePeriod(setDelay, delay)}
      />
      <Item
        header={lg.planning.modification.semaineType.weekdays}
        value={weekdays}
        onChange={updatePeriod(setWeekdays, weekdays)}
      />
      <Item
        header={lg.planning.modification.semaineType.weekends}
        value={weekends}
        onChange={updatePeriod(setWeekends, weekends)}
      />
      <div className={classes.row}>
        <Button
          theme="dark"
          title={lg.planning.modification.saveButton}
          onClick={() => handleChanges()}
          type="submit"
        />
      </div>
    </Container>
  );
};

export default RegularWeekAdmin;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Header from '../header';
import { Store } from '../../../../redux/types';
import AccountDetailsForm, {
  AccountDetailsFormValues,
  CIVILITY,
} from './AccountDetailsForm';
import { saveGarage } from '../../../../redux/stores/garages/actions';
import {
  setGarage,
  setNotification,
} from '../../../../redux/stores/global/actions';
import { SeverityMessage } from '../../../../redux/stores/global/types';
import AutossimoServices from './services/AutossimoServices';
import Button from '../../../../assets/generic-components/Button';
import HttpService from '../../../../services/http-service';
import setAutossimoUserData from '../../../../services/autossimo';
import Title from '../../../../assets/generic-components/Title';

const AccountDetails = () => {
  const useStyle = makeStyles({
    form: {
      width: '60%',
      margin: '0 10vw',
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: '50px',
      paddingTop: '50px',
    },

    title: {
      margin: '40px 0 10px 0',
    },

    servicesRoot: {
      marginBottom: '20px',
    },
  });

  const classes = useStyle();

  const dispatch = useDispatch();

  const {
    globalReducer: { currentGarage },
    languageReducer: { language: lg },
  } = useSelector((state: Store) => state);

  const [civility, setCivility] = useState(
    currentGarage?.civility || CIVILITY.M
  );

  const { register, handleSubmit, errors, control } =
    useForm<AccountDetailsFormValues>({
      mode: 'onChange',
    });

  if (!currentGarage) return null;

  const autossimoGarage = currentGarage.autossimo;

  const garageId = currentGarage.id;

  const client = HttpService.getClient();

  const onSubmit = (values: AccountDetailsFormValues): void => {
    dispatch(
      saveGarage(
        currentGarage?.id || '',
        {
          email: values.email,
          phone: values.phone,
          mobilePhone: values.mobile,
          civility,
          lastname: values.lastName,
          firstname: values.firstName,
        },
        () => {
          dispatch(setGarage(currentGarage?.id || ''));
          dispatch(
            setNotification({
              severity: SeverityMessage.SUCCESS,
              message: lg.general.success,
            })
          );
        },
        (error: Error) =>
          dispatch(
            setNotification({
              severity: SeverityMessage.ERROR,
              message: error.message,
            })
          )
      )
    );

    dispatch(
      setAutossimoUserData(
        autossimoGarage ? client.put : client.post,
        values.login,
        values.password,
        garageId || '',
        () => {
          dispatch(setGarage(currentGarage?.id || ''));
          dispatch(
            setNotification({
              severity: SeverityMessage.SUCCESS,
              message: lg.general.success,
            })
          );
        },
        (error: Error) =>
          dispatch(
            setNotification({
              severity: SeverityMessage.ERROR,
              message: error.message,
            })
          )
      )
    );
  };

  return (
    <>
      <Helmet>
        <title>Mon garage | Connexion</title>
      </Helmet>
      <Header show={false} />

      <div className={classes.form}>
        <AccountDetailsForm
          currentGarage={currentGarage}
          lg={lg}
          onChange={(e: {
            currentTarget: { value: React.SetStateAction<string> };
          }) => setCivility(e.currentTarget.value)}
          errors={errors}
          control={control}
          lastNameRef={register({
            required: true,
            maxLength: 20,
          })}
          firstNameRef={register({
            required: true,
            maxLength: 20,
          })}
          emailRef={register({
            required: true,
            pattern: {
              message: lg.quote.messageEmail,
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            },
          })}
        />

        <div className={classes.servicesRoot}>
          <div className={classes.title}>
            <Title title={lg.account.myServices} />
          </div>
          <AutossimoServices
            errors={errors}
            loginRef={register()}
            passwordRef={register()}
          />
        </div>

        <Button
          width="274px"
          theme="dark"
          type="submit"
          title={lg.planning.modification.saveButton}
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </>
  );
};

export default AccountDetails;

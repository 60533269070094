import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import HttpService from './http-service';
import { Store } from '../redux/types';

const setAutossimoUserData =
  (
    callFunc: (url: string, data: {}, config: {}) => Promise<any>,
    currLogin: string,
    currPassword: string,
    garageId: string,
    success?: () => void,
    failure?: (error: Error) => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async () => {
    await HttpService.getClient();
    callFunc(
      `${process.env.REACT_APP_API_GARAGES_URL}/garages/${garageId}/login-autossimo`,
      {
        code: garageId,
        login: currLogin,
        password: currPassword,
        id: garageId,
      },
      {
        headers: { 'api-key': process.env.REACT_APP_API_GARAGES_KEY },
      }
    )
      .then(success)
      .catch(failure);
  };

export default setAutossimoUserData;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { PDFDocument } from 'pdf-lib';
import useStyles from './style';
import { Store } from '../../../../redux/types';
import Button from '../../../../assets/generic-components/Button';
import logoPrint from '../../../../assets/style-elements/print.svg';
import arrow from '../../../../assets/style-elements/01-electrons-icon-arrow.png';
import pdfFile from '../../../../assets/pdf/ordre-de-reparation.pdf';
import { Locale } from '../../../../redux/stores/global/types';
import useOutsideClick from '../../../../utils/hooks/useOutsideClick';

const PrintButton = () => {
  const {
    planningStore: { currentBooking },
    globalReducer: { currentGarage, availableCarservices },
    quotesReducer: { currentVehicle },
    languageReducer: { language: lg },
  } = useSelector((state: Store) => state);

  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  const handleClickOutside = () => {
    setIsOpen(false);
  };

  const ref = useOutsideClick(handleClickOutside);

  const quoteLabels = currentBooking?.quotes.map(
    (quote) =>
      availableCarservices[quote.carServiceId]?.label || quote.carServiceId
  );

  const fillPdf = async () => {
    // Fetch the PDF with form fields
    const existingPdfBytes = await fetch(pdfFile).then((res) =>
      res.arrayBuffer()
    );

    // Load a PDF with form fields
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Get the form containing all the fields
    const form = pdfDoc.getForm();

    // Get all fields in the PDF by their names
    const garageName = form.getTextField('Garage name');
    const garageAdress = form.getTextField('Garage adress');
    const customerName = form.getTextField('Customer name');
    const customerPhone = form.getTextField('Customer phone');
    const customerEmail = form.getTextField('Customer email');
    const vehicleType = form.getTextField('Vehicle type');
    const immat = form.getTextField('Immatriculation');
    const startDate = form.getTextField('Vehicle start date');
    const kilometrage = form.getTextField('Kilometers');
    const serieNumber = form.getTextField('Code vin');
    const comments = form.getTextField('Comments');
    const quotes = form.getTextField('Quotes');

    // Fill in the info fields
    garageName.setText(currentGarage?.companyName);
    garageAdress.setText(
      `${currentGarage?.garageAddress.address1} ${currentGarage?.garageAddress.address2} ${currentGarage?.garageAddress.zipcode} ${currentGarage?.garageAddress.city}`
    );
    customerName.setText(
      `${currentBooking?.customer.lastName} ${currentBooking?.customer.firstName}`
    );
    customerPhone.setText(currentBooking?.customer.phone);
    customerEmail.setText(currentBooking?.customer.email);
    vehicleType.setText(
      `${currentVehicle?.version.model.make.label} ${currentVehicle?.label}`
    );
    immat.setText(`${currentBooking?.vehicle?.registrationNumber}`);
    startDate.setText(
      currentVehicle?.releaseDate ? `${currentVehicle?.releaseDate}` : ''
    );
    kilometrage.setText(
      currentBooking?.vehicle?.kilometers
        ? `${currentBooking?.vehicle.kilometers}`
        : ''
    );
    serieNumber.setText(
      currentBooking?.vehicle?.vinCode
        ? `${currentBooking?.vehicle?.vinCode}`
        : ''
    );
    comments.setText(
      currentBooking?.comment ? `${currentBooking?.comment}` : ''
    );
    quotes.setText(quoteLabels?.join('\r\n'));

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save();

    const blob = new Blob([pdfBytes], { type: 'application/pdf' });

    const fileURL = URL.createObjectURL(blob);

    window.open(fileURL);
  };

  return (
    <div
      ref={ref}
      className={`${classes.buttonContainer} ${
        isOpen && classes.buttonContainerClickStyle
      }`}
    >
      <Button
        theme="light"
        onClick={
          lg.locale === Locale.NL
            ? () => window.print()
            : () => setIsOpen((prev) => !prev)
        }
      >
        <img className={classes.printLogo} alt="logoPrint" src={logoPrint} />
        <span className={classes.printText}>{lg.command.print}</span>
        {lg.locale === Locale.FR && (
          <img
            className={
              isOpen ? classes.printArrowTransform : classes.printArrow
            }
            alt="arrow"
            src={arrow}
          />
        )}
      </Button>
      {isOpen && (
        <div
          className={`${classes.smallModal} ${
            isOpen && classes.smallModalClickStyle
          }`}
        >
          <Button
            theme="light"
            onClick={() => {
              window.print();
              setIsOpen(false);
            }}
          >
            {lg.printButton.rdv}
          </Button>
          <Button
            theme="light"
            onClick={() => {
              fillPdf();
              setIsOpen(false);
            }}
          >
            {lg.printButton.repair}
          </Button>
        </div>
      )}
    </div>
  );
};

export default PrintButton;

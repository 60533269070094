import React from 'react';
import useStyles from './style';

type CellProps = {
  /**
   *  Title of the component
  */
   title: string;
   /**
    *  The price
    */
   price: number;
   /**
    *  Whether or not the component is displayed.
    */
   displayIf?: boolean 
}


const QuoteCellPrice = ({ title, price, displayIf }: CellProps) => {
  const classes = useStyles();

  if (!displayIf) {
    return null;
  }

  return (
    <div className={classes.boxPrice}>
      <p>{title}</p>
      <p>{price.toFixed(2)}</p>
    </div>
  );
};

QuoteCellPrice.defaultProps = {
  displayIf: true
};

export default QuoteCellPrice;

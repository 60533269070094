import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import useBillingSummary from '../../../utils/hooks/useBillingSummary';
import infoIcon from '../../../assets/style-elements/01-electrons-icon-info@3x.png';
import colors from '../../../assets/style-colors';
import { Store } from '../../../redux/types';

interface BillingSummaryProps {
  year: string;
  month: string;
  garageId: string;
}

const BillingSummary: React.FC<BillingSummaryProps> = ({
  year,
  month,
  garageId,
}) => {
  const { total, commission, subscription, voucher, additionalVoucher } =
    useBillingSummary(garageId, `${year}-${month}`);
  const {
    languageReducer: { language: lg },
  } = useSelector((state: Store) => state);
  const euroFormat = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
  });
  const totalFormatted = euroFormat.format(Math.abs(total));

  const useStyles = makeStyles(() => ({
    summary: {
      marginLeft: '20px',
      paddingLeft: '20px',
      position: 'relative',

      '&:before': {
        position: 'absolute',
        width: '2px',
        height: 'calc(100% + 30px)',
        backgroundColor: colors.WhiteDark,
        content: '""',
        top: '-30px',
        left: 0,
      },
    },
    title: {
      fontSize: '16px',
      marginTop: '-30px',
      paddingBottom: '10px',
    },
    total: {
      backgroundColor: colors.veryPaleGrey,
      display: 'inline-flex',
      padding: '10px 20px',
      borderRadius: '8px',
      position: 'relative',

      '&:hover': {
        '& > div': {
          display: 'block',
          opacity: '1',
        },
      },

      '& p': {
        marginRight: '10px',
        marginBottom: 0,
        cursor: 'pointer',
      },
    },
    icon: {
      width: 18,
      height: 18,
      cursor: 'pointer',
    },
    detail: {
      position: 'absolute',
      opacity: '0',
      display: 'none',
      top: '-30px',
      minWidth: '100%',
      width: '300px',
      height: 'auto',
      padding: '20px',
      boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.15)',
      right: 'calc(-300px - 20px)',
      backgroundColor: colors.white,
    },
    count: {
      display: 'flex',
      justifyContent: 'space-between',
      fontWeight: 'normal',

      '& span': {
        fontWeight: 'bold',
      },
    },
    totalDetail: {
      marginTop: '10px',
      paddingTop: '10px',
      borderTop: '1px solid grey',
    },
  }));

  const styles = useStyles();
  const totalWordingDebitCredit =
    total > 0 ? lg.billing.rembourse : lg.billing.prelev;

  const currentMonthName = moment.months(parseInt(month) - 1);

  return (
    <div className={styles.summary}>
      <h2 className={styles.title}>
        {lg.billing.recap} {currentMonthName} {year}
      </h2>
      <div className={styles.total}>
        <p>
          {totalFormatted}{' '}
          {total > 0 ? lg.billing.rembourse : lg.billing.prelevs}
        </p>
        <img src={infoIcon} alt="Info" className={styles.icon} />
        <div className={styles.detail}>
          <p className={styles.count}>
            Commission RDV : <span>-{euroFormat.format(commission)}</span>
          </p>
          <p className={styles.count}>
            {lg.billing.subscriptions} :{' '}
            <span>-{euroFormat.format(subscription)}</span>
          </p>
          <p className={styles.count}>
            Avoirs (coupon) : <span>+{euroFormat.format(voucher)}</span>
          </p>
          <p className={styles.count}>
            {lg.command.regularisation} :{' '}
            <span>+{euroFormat.format(additionalVoucher)}</span>
          </p>
          <p className={`${styles.totalDetail} ${styles.count}`}>
            {lg.billing.total} {totalWordingDebitCredit}
            <span>{totalFormatted}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default BillingSummary;

export interface Billing {
  id: string;
  garageId: string;
  bookingId?: string;
  quoteId: string;
  label: string;
  date?: string;
  type: string;
  amount: number;
  createdAt?: string;
}

export interface Bill {
  id: string;
  billNumber: string;
  date: string;
  amount: number;
  type: string;
}

export interface BillingStore {
  currentBill?: Bill;
  currentBillings: Billing[];
}

export enum ViewsListEnum {
  COMMISSION = 'commission',
  SUBSCRIPTION = 'subscription',
  VOUCHER = 'voucher',
  ADDITIONAL_VOUCHER = 'additional_voucher'
}

import React from 'react';
import { useSelector } from 'react-redux';
import useStyles from './style';
import { Store } from '../../../../redux/types';
import logoCalc from '../../../../assets/style-elements/total.svg';
import QuoteCellPrice from './quoteCellPrice';

type QuoteOrderProps = {
  totalDiscountless: number;
  totalDiscount: number;
  totalCoupon: number;
  totalWithDiscounts: number;
  totalVAT: number;
  totalBillingPriceVat: number;
  totalBillingPrice: number;
  totalBillingPriceMax: number;
};

const QuoteOrder = ({
  totalDiscountless,
  totalDiscount,
  totalCoupon,
  totalWithDiscounts,
  totalVAT,
  totalBillingPriceVat,
  totalBillingPrice,
  totalBillingPriceMax
}: QuoteOrderProps) => {
  const classes = useStyles();
  const lg = useSelector((state: Store) => state.languageReducer.language);

  return (
    <div className={classes.containerOrder}>
      <div className={classes.headerTotal}>
        <img src={logoCalc} alt="logo" className={classes.logo} />
        <h5>{lg.quote.total}</h5>
      </div>

      <QuoteCellPrice
        title={lg.quote.totalOutDiscount}
        price={totalDiscountless}
      />
      <QuoteCellPrice title={lg.quote.totalDiscount} price={totalDiscount} />
      <QuoteCellPrice
        displayIf={totalCoupon > 0}
        title={lg.quote.totalCoupon}
        price={totalCoupon}
      />
      <QuoteCellPrice title={lg.quote.totalHT} price={totalWithDiscounts} />
      <QuoteCellPrice title={lg.quote.tva} price={totalVAT} />
      <div className={classes.totalBillingPriceVat}>
        <QuoteCellPrice
          title={lg.quote.billingTTC}
          price={totalBillingPriceVat}
        />
      </div>
      <QuoteCellPrice
        displayIf={totalBillingPrice !== totalBillingPriceMax}
        title={lg.quote.billingMax}
        price={totalBillingPriceMax}
      />

      {/* TODO add Dayview in quote confirmation page */}
      {/* <div className="">
        <h5 className="dayView-head">{lg.quote.dayOfRDV}</h5>
        <DayView lateralMode />
      </div> */}
    </div>
  );
};
 
export default QuoteOrder;

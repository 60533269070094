import { Language } from '../../redux/stores/languages';

const lg: Language = {
  locale: 'fr',
  lg: 'Français',
  unauthorized:
    "Désolé, vous n'avez pas les droits pour accéder à ce service. <br/> Merci de vous déconnecter pour essayer avec un autre compte.",
  notyetavailable:
    'Nous travaillons sur cette page pour vous proposez une expérience optimale',
  returnOldInterface: 'Retour sur My.iDGARAGES V1',
  isLoading: 'Chargement en cours...',
  select: 'Sélectionnez...',
  error: {
    server: 'Une erreur est survenue merci de réessayer plus tard',
    errorGarageUnauthorized: "Nous n'avons pas trouvé ce garage",
    errorGarageNotFound: "Le garage n'existe pas",
  },
  general: {
    yes: 'Oui',
    no: 'Non',
    unknown: 'Indéterminée',
    shortHour: 'h',
    ShortHour: 'H',
    back: 'Restitution au plus tard 18h00',
    type: 'Véhicule de courtoisie',
    payment: 'Facilité de paiement souhaitée',
    nextDay: 'Restitution au plus tard le lendemain 18h00',
    rdv: 'RDV',
    success: 'Vos modifications ont bien été prises en compte.',

    fatalError: "Désolé, une erreur s'est produite. Veuillez réessayer.",
    confirm: 'Valider',
    cancel: 'Annuler',
  },
  header: {
    searchPlaceholder: 'N° de commande/n° de devis/nom du client',
    contact: 'Nous contacter',
    help: 'Aide',
    language: 'Langue',
  },
  menuLeft: {
    planning: 'Planning',
    sad: 'SAD',
    account: 'Mon garage',
    rates: 'Mes tarifs',
    quote: 'Devis 5 secondes',
    visibility: 'Visibilité et ventes',
    billing: 'Facturation',
    basket: 'Autossimo',
    resetPassword: 'Changer de mot de passe',
    command: 'Commande RDV Web',
    commandPerso: 'Commande RDV Perso',
    time: 'Gérer mes horaires',
    holidays: 'Ajouter des congés',
  },
  signOut: {
    question: 'Etes-vous sur de vouloir vous déconnecter ?',
    confirm: 'Confirmer',
    cancel: 'Annuler',
    changeGarage: 'Changer garage',
  },
  planningHeader: {
    back: 'Retour',
    planning: 'Planning',
    CONFIRMED: 'Validé(s)',
    VALIDATED: 'A valider',
    REPORTED: 'Probleme(s) en cours',
    CANCELLED: 'Annulé(s)',
    printPlanning: 'Imprimer le planning',
  },

  planning: {
    notInit: "Votre planning n'est pas initialisé",
    updateMyPlanning: 'Mettre à jour mon planning',
    conflict:
      "Ajout impossible. La journée sélectionnée n'est pas libre pour créer ce RDV.",
    immat: 'Immat',
    title: 'Ma semaine type',
    week: 'Semaine',
    day: 'Jour',
    modify: 'Modifier la semaine',
    addRDV: 'Ajouter un RDV',
    editHours: 'Gérer mes disponibilités',
    with: 'Avec devis',
    without: 'Sans devis',
    quote: 'DEVIS',
    withoutDevis: 'DEVIS SUR PLACE',
    menuRight: {
      days: 'jours',
      hours: 'heures',
      cars: 'véhicules',
      infoDelay: 'de délai minimum avant RDV',
      infoWeekdays: 'disponible par jour/semaine',
      infoWeekends: 'disponible par jour/week-end',
      infoCars: 'de courtoisie',
      button: 'Ajouter des congés',
      months: [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
      ],
      congesTitle: 'Mes congés',
      congesPeriod: 'Période du',
      congesError:
        'Choisissez une date de la fin supérieure de la date de début de congés',
      congesClosed:
        "L'ensemble des jours seront fermés sur la période saisie sur votre planning",
    },
    weekdaysShort: ['L', 'M', 'M', 'J', 'V', 'S', 'D'],
    weekdays: ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'],
    weekdaysFull: ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    hours: 'Heures',
    closed: 'Fermé',
    modification: {
      modify: 'Modifier',
      closeWeek: 'Fermer toute la semaine',
      modalClose: 'Confirmez-vous la fermeture de la semaine ?',
      modalReopen: 'Confirmez-vous la reouverture de la semaine ?',
      modalDayClose: 'Confirmez-vous la fermeture de la journée du ',
      modalDayReopen: 'Confirmez-vous la reouverture de la journée du ',
      confirm: 'Oui',
      cancel: 'Non',
      openingHours: "Horaire d'ouverture",
      pause: 'Pause midi',
      availableHours: 'Nombre d’heures disponibles',
      congesPeriod: 'Période de congés',
      addItem: 'Ajouter un créneau',
      delItem: 'Supprimer ce congé',
      closeAll: 'Fermeture complète',
      saveButton: 'Enregistrer les modifications',
      errorHours: "L'heure est incorrecte",
      conges: 'Il y a déjà un congé le',
      congesModif: 'Modifier le congé',
      semaineType: {
        openingHours: "Horaires d'ouverture et disponibilités",
        daysOpenedForWeek: 'Jours ouverts par semaine',
        openingByDay: 'Ouverture par jour',
        hours: "Horaires d'ouverture",
        hoursCount: "Nombre d'heures disponibles",
        hoursCountBubble:
          "Renseignez ici le nombre d'heures de travail disponibles pour la journée",
        header: 'Modifier ma semaine type',
        delay: 'Délai de prise de RDV client (en jour)',
        delayBubble:
          'Le client ne pourra pas prendre rdv avant le nombre de jours renseignés ici. Le jour J est exclu pour le calcul de votre prochaine disponibilité',
        weekdays: "Nombre d'heures par jour (semaine)",
        weekends: "Nombre d'heures par jour (week-end)",
        cars: 'Nombre de véhicules de courtoisie',
      },
    },
  },
  devis: {
    button: 'Voir la commande',

    updateQuote: 'Modifier le devis',

    command: 'Commande N°',
    quote: 'DEVIS N°',
    sum: 'Montant HT :',
    pricelessMessage:
      "Pour ce véhicule, cette prestation n'est pas chiffrable en ligne. Vous pouvez cependant continuer la prise de rdv pour l'ajouter à votre planning.",
  },
  contact: {
    header: 'Contactez-nous',
    recipient: 'Destinataire',
    message: 'Message',
    send: 'Envoyer',
    qAtext:
      'Vous pouvez trouver les réponses à vos question dans notre rubrique',
    help: 'Aide',
    byPhone: 'Par téléphone',
    info1: 'du lundi au vendredi : 9h-18h',
    info2: 'du lundi au vendredi : 9h-18h',
    numero1: '(+33)01.84.79.38.60',
    numero2: '(+33)01.84.79.38.60',
    destinataires: "L'équipe Ad.fr', ' L'équipe Idgarages.com",
  },
  visibilityHeader: {
    visibility: 'Visibilité',
    sale: 'Ventes',
  },
  visibility: {
    title: 'Sélectionnez une période',
    search: 'Rechercher',
    visible: {
      all: 'Tout',
      ad: 'AD.fr',
      idGarage: 'IDgarages.com',
      contact_requests: 'Demandes de contact',
      contact_requests_text:
        "Nombre d'automobilistes qui ont\n demandé votre numéro de téléphone",
      directory_views: 'Vues Annuaires',
      directory_views_text:
        "Nombre d'automobilistes qui ont\n consulté l'adresse de votre garage",
      quote_views: 'Vues Devis',
      quote_views_text:
        "Nombre d'automobilistes qui ont\n comparé vos devis avec d'autres garages",
      detailed_information_views:
        'Vues informations\n détaillées de votre garage',
      detailed_information_views_text:
        "Nombre d'automobilistes qui ont\n parcouru la fiche de votre garage",
      quotes: 'Nombre de rendez-vous',
      quotes_text: 'Nombre de rendez-vous générés',
      revenue: 'CA HT',
      revenue_text: "Chiffre d'affaires apporté à votre garage",
      average_cart: 'Panier moyen HT',
      average_cart_text: 'Prix moyen des devis générés',
    },
    optionAll: 'tous',
  },
  billingHeader: {
    commission: 'Commissions RDV',
    voucher: 'Avoirs (Coupon)',
    subscription: 'Abonnement',
    additional_voucher: 'Régularisation',
  },
  billing: {
    title: 'Sélectionnez une période',
    search: 'Rechercher',
    orderRef: ' Ref commande',
    designation: 'Désignation',
    amount: 'Montant HT',
    empty: 'Aucune donnée pour ce mois-ci.',
    commission: {
      context: 'Contester',
      contest: 'Facture contestée',
      pdfDownload: 'Télécharger en PDF',
      facture: 'Total Facture',
      base: 'Base TVA',
      taux: 'Taux',
      tva: 'TVA €',
      total: 'Total TTC à payer',
    },
    asset: {
      context: 'Contexte',
      contest: 'Facture contestée',
      pdfDownload: 'Télécharger en PDF',
      facture: 'Total Facture',
      base: 'Base TVA',
      taux: 'Taux',
      tva: 'TVA €',
      total: 'Total TTC à payer',
      totalVoucher: 'Total TTC',
    },
    subscription: {
      context: 'Contexte',
      contest: 'Facture contestée',
      pdfDownload: 'Télécharger en PDF',
      facture: 'Total Facture',
      base: 'Base TVA',
      taux: 'Taux',
      tva: 'TVA €',
      total: 'Total TTC à payer',
    },
    regularisation: {
      context: 'Contexte',
      contest: 'Facture contestée',
      pdfDownload: 'Télécharger en PDF',
      facture: 'Total Facture',
      base: 'Base TVA',
      taux: 'Taux',
      tva: 'TVA €',
      total: 'Total TTC à payer',
    },
    contest: {
      title: 'Contester la facture',
      subtitle: 'CLIENT',
      comment: 'Commentaire de contestation',
      signal: 'Votre contestation a bien été prise en compte',
      close: 'Fermer',
      cancel: 'Contester',
      back: 'Retour à la facturation',
      error: 'Veuillez écrire moins de 360 caractères',
    },
    recap: 'Récapitulatif',
    rembourse: 'Remboursé',
    prelev: 'Prélevés ',
    total: 'Total',
    subscriptions: 'Abonnements',
    prelevs: 'Prélevés',
  },
  helpHeader: {
    faq: 'FAQ',
    homeHelp: 'Accueil aide',
    coupon: 'Coupon',
    commission: 'Accompte et commission',
    premiumPiece: 'Pièce prémium',
  },
  help: {
    helpHome: {
      title: 'Trouvez les réponses à vos questions',
      coupon: 'Opération Groupon - Comment ça marche ?',
      commission: 'Accompte client et commission - Comment ça marche ?',
      faq: 'FAQ générale',
      piece: 'Pièce prémium',
      plus: 'En savoir plus',
      questions: 'Voir toutes les questions',
    },
    piece: {
      question: 'Questions fréquentes',
      answer: 'Vous ne trouvez pas de \n réponse à vos questions ?',
      contact: 'Contactez-nous',
    },
    faq: {
      question: 'Questions fréquentes',
      answer: 'Vous ne trouvez pas de \n réponse à vos questions ?',
      contact: 'Contactez-nous',
    },
    coupon: {
      question: 'Questions fréquentes',
      answer: 'Vous ne trouvez pas de \n réponse à vos questions ?',
      contact: 'Contactez-nous',
    },
    commission: {
      question: 'Questions fréquentes',
      answer: 'Vous ne trouvez pas de \n réponse à vos questions ?',
      contact: 'Contactez-nous',
    },
    account: {
      aideEngagement:
        'Indiquez ici les engagements spécifiques à votre garage.Voici quelques exemples à personnaliser\n' +
        'Nous sommes engagés dans une démarche de recyclage de toutes les pièces usées…\n' +
        'Nous garantissons nos interventions X ans pièces et main d’œuvre…\n' +
        'Chaque année, nous formons X jeunes au métier de…\n' +
        'Notre équipement technique est contrôlé chaque année et renouvelé en moyenne tous les X ans…\n' +
        'Nous nous engageons à nettoyer votre véhicule intérieur/extérieur…\n' +
        'Nous nous engageons à nettoyer votre pare-brise',
      aidePointsForts:
        'Voici quelques idées à personnaliser, utiles pour mettre en avant votre garage\n' +
        'Notre garage est situé à proximité de … (rue / place / route / magasin / arrêt de bus, métro, tramway)\n' +
        'Notre équipe est composée de techniciens expérimentés…\n' +
        'Nous sommes à votre service depuis 19XX / XX ans…\n' +
        'Notre garage accueille toutes les marques de véhicules…\n' +
        'Nos techniciens sont formés et équipés pour intervenir sur tous types de véhicules…',
    },
  },
  command: {
    back: 'Retour planning',
    piece: 'Commander des pièces',
    print: 'Imprimer',
    regularisation: 'Régularisation',
    commandNumber: 'RDV N°',
    priceAdvice:
      'Le devis communiqué au client a été établi à partir des tarifs que vous avez renseignés sur idGarages.pro. Nous comptons sur votre participation pour maintenir ce tarif.',
    commandToPrint: {
      command: 'Commande',
      registration: 'Immatriculation',
      minetype: 'Mine type',
      cnit: 'CNIT',
      car: 'Prise en charge du véhicule prévue le',
      total: 'Total de la commande',
      totalOutDiscount: 'Total hors remise € HT',
      totalDiscount: 'Total remise € HT',
      totalHT: 'TOTAL € HT',
      totalTVA: 'TVA €',
      billingTTC: 'Montant à facturer au client € TTC',
      billingMax: 'Montant à facturer au client si fourchette max € TTC',
    },
    detail: {
      rdvWithout: 'Rendez-vous sans Devis',
      issue: 'Signaler un problème',
      rdvConfirm: 'Confirmer le RDV web',
      quoteCanceled: 'Annuler le devis',
      quoteAsset: 'Devis avoir',
      canceled: 'Annuler la prestation',
      noteModification: 'Modifier la note',
      nodeRDV: 'Notes du Rendez-vous',
    },
    autossimo: {
      title: "Besoin d'une pièce ?",
      subtitle: 'Commandez vos pièces en ligne \n via Autossimo',
      piece: 'Commander des pièces',
      delivery: 'Livraison garantie !',
    },
    coupon: {
      attention:
        'ATTENTION : veillez bien inclure en bas de facture le montant de réduction TTC qui vous sera \n remboursé par',
    },
    total: {
      title: 'Total de la commande',
      totalHorsRemise: 'Total hors remise € HT',
      totalAvecRemiseCoupon: 'Total € HT avec remise coupon',
      totalHT: 'TOTAL € HT',
      totalCoupon: 'Remise coupon',
      totalCommission: 'Déduction réservation client HT',
      tva: 'TVA €',
      montantFacturer: 'Montant à facturer au client € TTC',
      fourchette: 'Montant à facturer au client si\n fourchette max € TTC',
      couponCode: 'Code promo',
      textLong: `Ce client n’est pas un expert en mécanique  mais pourtant il a un véhicule qui a des besoins et il peut devenir un client fidèle à votre garage ! `,
      booking: `Dans ce cas, et plutôt que de réserver une prestation qui ne correspondrait pas au besoin identifié sur sa voiture, nous lui avons permis de réserver cette opération sans chiffrage associé.`,
      confirmBookingTitle: 'Que faire de votre côté ?',
      confirmBooking:
        'Vous prenez connaissance de la réservation, vous la validez, vous recevez le client, vous diagnostiquez et/ou essayez le véhicule et vous pouvez formuler un devis au client à partir de votre œil d’expert !',
      commissionPriceless: `Une commission de 10€ HT vous sera facturée à la fin du mois (uniquement si le client a réalisé une prestation dans votre garage).`,
      textLongUpdate: `Saviez-vous que vous pouvez créer vos devis directement sur idGarages.pro ? Gagnez du temps et répondez plus vite à vos clients.`,
      quotePricelessTitle: `Le saviez-vous ? `,
      quotePricelessWebsite: `RDV sans devis`,
    },

    partsOrdered: {
      title: 'Pièces détachées',

      notOrdered: 'Pas de commande',

      ordered: 'Pièces commandées',
      deliveredOrdered: 'Pièces reçues',
    },
    lateral: {
      carCare: 'Prise en charge du véhicule',
      detailContact: 'Coordonnées',
      question: 'Une question ? Appelez-nous au 01 84 79 38 60',
      vehicle: 'Véhicule',
      registration: 'Immatriculation',
      registrationVin: 'Code VIN',
      mineType: 'Mine type',
      cnit: 'CNIT',
      engine: 'Code moteur',
      fuel: 'Code moteur',
      comment: 'Commentaire du client',
      noComment: 'Aucun commentaire',
      recommended: 'Equipementier Premium\n Préconisés',
      equipment: 'Aucun équipement imposé',
      why: 'Pourquoi ce montant ?',
      textlong: `Lors de sa réservation le client a utilisé un coupon.\n Le montant du coupon vous sera intégralement remboursé.\n Ainsi à la fin de la prestation, le client vous réglera\n montant affiché en bas de votre commande.`,
      total: 'Montant total TTC',
      customerCommission: 'Déduction réservation client TTC',
      discount: 'Remise coupon TTC',
      bill: 'Montant à régler TTC',
      commission: 'Commission garage',
      subtitle: 'Pour cette commande vous serez facturé\n de',
      invoice: 'HT pour le RDV\n N°',
      prestation: 'si le client réalise une prestation',
      history: 'Historique',
      historyDate: '04/01/2020 09:06:48',
      VALIDATED: 'Devis en attente',
      CONFIRMED: 'Devis validé',
      CANCELLED: 'Annulé',
      WALLET: 'Avoir',
      REPORTED: 'Problème signalé',
      POSTPONED: 'Reporté',

      btnSms: 'SMS véhicule prêt',
      titleModalSms: 'Informer le client par SMS : véhicule prêt',
      bodyModalSms:
        'Je préviens mon client que son véhicule est prêt à être récupéré.',
      sendSms: 'Envoyer le SMS',
      validationSms: 'Le SMS a bien été envoyé à votre client.',
      smsAlreadySend: 'Le SMS a déjà été envoyé à votre client.',
      phoneNotValide: 'Le numéro de Téléphone est invalide.',
    },
    appointment: {
      title: 'Changer la date du rendez-vous',
      confirm: 'Confirmation de la modification du rdv',
      close: 'Fermer',
      changedDate: 'Modifier la date du rendez-vous',
      backCommand: 'Retour à la commande',
    },
    comment: {
      title: 'Modifier les commentaires',
      confirm: 'Enregistrer les commentaires',
      close: 'Fermer',
      label: 'Commentaires du client',
    },
    user: {
      title: 'Modifier les coordonnées',
      confirm: 'Enregistrer les coordonnées',
      close: 'Fermer',
      firstName: 'Nom',
      lastName: 'Prénom',
      phone: 'Téléphone',
      email: 'Adresse mail',
    },
    vehicle: {
      title: 'Modifier les information du véhicule',
      confirm: 'Enregistrer les information du véhicule',
      close: 'Fermer',
      vehicle: 'Véhicule',
      immatriculation: 'Immatriculation',
      kilometers: 'Kilométers',
      vinCode: 'Vin Code',
    },
    cancelQuote: {
      title: 'Annuler le devis',
      titleCanceled: 'Annuler la prestation',
      confirm: "Veuillez confirmer l'annulation du devis",
      confirmCanceled: "Veuillez confirmer l'annulation de la prestation",
      close: 'Fermer',
      valid: 'Confirmer',
    },
    issue: {
      title: 'Signaler un problème',
      select: 'Sélectionnez le problème',
      number: 'Chiffrage devis',
      planning: 'Problème planning',
      other: 'Autre',
      subtitle:
        'Merci de préciser la nature de l’erreur et préciser le nouveau tarif associé',
      confirm: 'Votre problème a bien été signalé.',
      close: 'Fermer',
      signal: 'Signaler le problème',
      back: 'Retour à la commande',
      error: 'Veuillez écrire au minimum 10 caractères',
      details: {
        advertiser:
          'Les coupons sont entièrement pris en charge et remboursés par iDGARAGES. \n' +
          'Aucun problème signalé ne pourra être traité le jour ou la veille du rendez-vous.',
        planning: 'Avez-vous vérifié si votre planning est bien à jour ?',

        params:
          'Le devis proposé est calculé en tenant compte de votre paramétrage ainsi que des préconisations constructeurs.',
      },
    },
    note: {
      title: 'Modifier la note',
      subtitle: 'Notes du RDV',
      close: 'Fermer',
      changedNote: 'Modifier la note',
      error: 'Veuillez écrire au minimum 10 caractères',
    },
    refund: {
      title: 'Commande avoir',
      subtitle: "Rembourser sous forme d'avoir ?",
      close: 'Fermer',
      cancel: 'Modifier la note',
    },
    webAppointment: {
      title: 'Confirmer le RDV web',
      subtitle: 'Veuillez confirmer le rendez-vous web',
      close: 'Fermer',
      confirm: 'Confirmer',
    },
  },
  rates: {
    title: 'Prestations proposées',
    labourCost: 'Taux de main oeuvre \n (M.O.) T1, T2, T3',
    pourcentage: "Appliquer un pourcentage d'ajustement sur ces taux",
    taux1: 'Taux méchanique 1 (T1)',
    taux2: 'Taux méchanique 2 (T2)',
    taux3: 'Taux méchanique 3 (T3)',
    register: 'Enregistrer les modifications',
    designation: 'Désignation',
    form: 'Votre formule',
    setting: 'Paramètres',
    clutch: 'Embrayage',
    preferences: 'Préférences devis en ligne',
    service:
      'Je propose cette prestation : tarif des pièces (équipementier ou constructeur) et temps de main d’œuvre barêmés',
    help: 'Aide',
    helps: 'Aides',
    internet: 'Je ne souhaite pas proposer cette prestation sur internet',
    prestation: 'La prestation comprend',
    equipment: 'Equipementier',
    equipmentTitle: 'Choisissez votre équipementier',
    adjust: 'Ajustement sur tarifs pièces',
    piece: 'Pourcentage ajustement sur tarif public Pièces PREMIUM *',
    level: 'Mise à niveau',
    oil: 'Huile boîte de vitesse *',
    forfaitPres: 'Forfaits prestations associées',
    forfaitLiquide: 'Forfait remplacement liquide hydraulique *',
    forfaitClean: 'Forfait Nettoyant *',
    try: 'Essai routier',
    save: 'Enregistrer',
    close: 'Fermer',
    facturation: 'Adresse de facturation',
    address: 'Adresse de facturation identique à l’adresse du garage',
    addAddress: 'Ajouter une adresse de facturation',
    addRib: 'Télécharger RIB',
    bankInformation: 'Informations bancaires',
    iban: 'IBAN',
    messageIban: 'IBAN non valide',
    bic: 'BIC',
    messageBic: 'BIC non valide',
    change: 'En cas de changement de RIB, merci de nous le faire parvenir.',
    requiredField: 'Ce champ est requis',
    validPostal: 'Code postal non valide',
    unknownPostal: 'Code postal inconnu',
    character: 'Le champ doit contenir au moins 3 caractères!',
    condition: 'Accepter les conditions générales de vente',
    description: 'Description',
    learn: 'Enseigne',
    commitments: 'Vos engagements',
    strengths: 'Vos points forts',
    lastname: 'Nom*',
    firstname: 'Prénom*',
    garage: 'Nom du garage*',
    email: 'Email*',
    oldPassword: 'Ancien mot de passe*',
    passwords: 'Mot de passe*',
    newPassword: 'Nouveau mot de passe*',
    phone: 'Ligne fixe',
    portable: 'Portable',
    siret: 'SIRET',
    rcs: 'RCS',
    addressStreet: 'Adresse*',
    additionalAddress: "Complément d'adresse",
    postal: 'Code postal*',
    city: 'Ville*',
    changeBackup: 'Voulez-vous enregistrer vos modifications',
    backup: 'Enregistrer',
    registered: 'Sauvegarder',
    confirmPassword: 'Confirmer le mot de passe*',
    saveModification: 'Vous avez des modifications non sauvegardées',
    informationGarage: 'Informations garage',
    password: 'Modifier mon mot de passe',
    garageAddress: 'Adresse garage',
    country: 'Pays*',
    next: 'A proximité de*',
    charter: 'Valider la charte de qualité',
    messageMail: 'Adresse mail non valide',
    messagePassword:
      'Votre mot de passe doit contenir au moins 8 caractères , une Majuscule, un Chiffre',
    difference: 'Les deux mots de passe sont différent',
    messagePhone: 'Numéro de téléphone non valide',
    messageSiret: 'Numéro SIRET non valide',
    messageRCS: 'Numéro RCS non valide',
    messagePostal: 'Code postal non valide',
    break: 'Pause midi pour tous les jours',
    noBreak: 'Pas de pause midi',
    checkSchedules: 'Veuillez vérifier vos horaires',
    services: 'Services et certifications',
    servicesPlus: 'Services Plus',
    minLength: 'Le champ doit contenir au moins {{min}} caractères !',
    maxLength: 'Le champ doit contenir au maximum {{max}} caractères !',
    exactLength: 'Le champ doit contenir {{length}} caractères !',
  },
  quote: {
    back: 'Retour',
    backPlanning: 'Revenir au planning',
    create: 'Créer un devis',
    addQuote: 'Ajouter un rdv avec devis',
    addQuoteWithoutQuote: 'Ajouter un RDV sans devis',
    immat: 'Immatriculation',
    print: 'Imprimer',
    type: 'TYPE MINE',
    code: 'CODE MOTEUR',
    fuel: 'Carburant',
    qualities: 'Qualités',
    order: 'Tri',
    priceAsc: 'Prix croissant',
    priceDesc: 'Prix décroissant',
    tyre: 'Pneu',

    buyPrice: 'Prix de vente',

    publicPrice: 'Prix public',
    choose: 'Choisir',
    flexHomologation: 'Homologation pour boitier éthanol',
    intermediateReview: 'Révision intermédiaire',
    attention:
      'ATTENTION : Pour ce rendez-vous web nous avons identifié plusieurs références constructeur possible.\n  Vous devez vous assurer que la référence indiquée correspond bien à celle montée sur le véhicule du client.\n  Le détail du devis ci après se base sur la fourchette minimum.',
    designation: 'Désignation',
    unitHT: 'Prix unit. € HT',
    quantity: 'Qté. ou MO',
    discount: 'Remise en ligne',
    ht: 'Prix total € HT',
    ttc: 'Prix total € TTC',
    total: 'Total de la commande',
    totalOutDiscount: 'Total hors remise € HT ',
    totalDiscount: 'Total remise € HT',
    totalCoupon: 'Total € HT remise coupon',
    totalHT: 'TOTAL € HT ',
    tva: 'TVA € ',
    billingTTC: 'Montant à facturer au client € TTC ',
    billingMax: 'Montant à facturer au client si fourchette max € TTC',
    previous: 'Précédent',
    rdv: 'Prise de RDV',
    validedRDV: 'Valider le RDV',
    validRDV: 'validez le rendez-vous',
    dayOfRDV: 'Rendez-vous du jour',
    noteRDV: 'Note du RDV',
    timeOfRDV: 'Temps de rdv (h)',
    hour: 'Heure',
    prestation: 'Prestation(s)',
    lastName: 'Nom du client *',
    messageField: 'Ce champ est requis',
    firstname: 'Prénom du client',
    requiredFirstname: 'Prénom du client *',
    requiredEmail: 'Email du client *',
    email: 'Email du client',
    messageEmail: 'Adresse mail non valide',
    phone: 'Numéro de téléphone du client',
    requiredPhone: 'Numéro de téléphone du client *',
    messagePhone: 'Numéro de téléphone non valide',
    command: 'Commande',
    confirmation: 'Confirmation de prise de RDV',
    selectedCar: 'Sélection du véhicule',
    registration: 'Immatriculation',
    releaseDate: 'Date de mise en circulation',
    releaseDateError: 'Format de date non valide',
    kmError: 'La valeur doit être comprise entre 1 et 999999',
    km: 'Kilométrage',
    carMaintenanceInfo:
      'Vous avez sélectionné "Révision Constructeur". Le chiffrage de cette prestation demande un kilométrage et une date de mise en circulation,  merci de renseigner ces informations.',
    carMaintenanceInfoReleaseDate:
      'Date de mise en circulation connue pour cette immatriculation :',
    vinCode: 'Code VIN',
    invalidRegistration: 'Immatriculation invalide',
    registrationLabel: 'Immatriculation',
    registrationLabelCodeVin: 'Code VIN',
    invalidRegistrationVin: 'Code Vin Invalide',
    model: 'Modèle',
    brand: 'Marque',
    engine: 'Motorisation',
    choice: 'Choisir une ou plusieurs prestation(s)',
    date: 'Date et heure de prise en charge',
    search: 'Rechercher',
    emptySearch: 'Aucun resultat',
    save: 'Enregistrer',
    next: 'Suivant',
    download: 'Télécharger',
    discountPart: 'ANNULATION CAR FORFAIT (Pièces)',
    discountRepairTime: 'ANNULATION CAR FORFAIT (Main d’œuvre)',
    tyres: {
      options: 'Options',
      season: 'Saison',
      runflat: 'Runflat',
      reinforced: 'Renforcé',
      errorSelectingTyre:
        'Nous ne pouvons pas générer un devis pour ce pneumatique',
    },
    labelExtraKickstarter: 'Supplément bougies',
    labelExtraOil: 'Supplément huile Hors forfait',
  },
  search: {
    result: 'Résultats pour la recherche',
    emptyResult: 'Pas de résultats pour',
    quote: 'DEVIS',
  },
  pdf: {
    availability:
      'Devis valable un mois. Devis estimatif – La validation finale, incluant les prestations détaillées nécessaires, sera remis après l’arrivée du véhicule',
    command: {
      back: 'Prise en charge du véhicule prévue la Restitution au plus tard à',
      alert:
        'ATTENTION : Pour ce rendez-vous web nous avons identifié plusieurs références constructeur\n   possible. Vous devez vous assurez que la référence indiquée correspond bien à celle montée\n  sur le véhicule du client. Le détail du devis ci après se base sur la fourchette\n  minimum.',
      command: 'Commande',
      designation: 'Désignation',
      price: 'Prix unit. € HT',
      qty: 'Qté. ou MO',
      discount: 'Remise en ligne',
      ht: 'Prix total € HT',
      ttc: 'Prix total € TTC',
      total: 'Total de la commande',
      totalOutDiscount: 'Total hors remise € HT',
      totalDiscount: 'Total remise € HT',
      totalHT: 'TOTAL € HT',
      totalTVA: 'TVA €',
      billing: 'Montant à facturer au client € TTC',
      billingMax: 'Montant à facturer au client si fourchette max € TTC',
    },
    commission: {
      best: 'LES MEILLEURS GARAGE AUX MEILLEURS PRIX',
      facturation: 'Facture',
      deliveryCustomer: 'Client livré',
      billingCustomer: 'Client facturé',
      referenceCustomer: 'Votre référence',
      paymentCustomer: 'Con. paiement / Mode paiement',
      sampleCustomer: 'PRELEVT AUTOMAT',
      accounting: 'SERVICE COMPTABILITE',
      deliveryAddress: 'Adresse de livraison',
      billingAddress: 'Adresse de facturation',
      ref: 'Ref commande',
      designation: 'Désignation',
      ht: 'Montant HT',
      attention: 'ATTENTION',
      textFooter1:
        '• Les rendez-vous non honorés doivent être indiqués à iDGARAGES avant le 15 du mois. Aucun remboursement ne pourra être réalisé après cette date',
      textFooter2:
        '• Les impayés seront soumis à une indemnité forfaitaire pour recouvrement.',
      textFooter3:
        'En cas de retard de paiement, indemnité forfaitaire pour frais de recouvrement : 40 euros',
      textFooter4: 'Aucun escompte pour paiement anticipé',
      textFooter5:
        'Selon la LME du 04/08/08, toute échéance non respectée donnera lieu de plein droit sans mise en demeure préalable, outre les frais',
      textFooter6:
        "judiciaires, à une pénalité de retard au taux d'intérêt pratiqué par la BCE majoré de 10 points",
      totalArticle: 'Total article',
      totalTVA: 'Total Hors TVA',
      tva: 'Base TVA',
      taux: 'Taux',
      billingTVA: 'Montant TVA',
      totalTTC: 'Total TTC à payer',
      couponTitle: 'Coupon à retourner avec le réglement',
      couponCustomer: 'N° Client',
      couponFacturation: 'N° Facture',
      couponBilling: 'Montant TTC',
      title: "Extrait des conditions générales de vente et d'utilisation ©",
      subtitle:
        'La version intégrale des CGVU est consultable sur le site iDGARAGES.com. Les CGVU ont été dument acceptées par le garage adhérent',
      cgvu:
        "Les présentes CGVU ont pour objet de définir les conditions et modalités de la mise à la disposition d'un service de mise en relation entre des automobilistes (indifféremment « un client" +
        ' iDGARAGES.com » ou «utilisateur automobiliste ») cherchant un prestataire de service pour la réalisation de prestations d’entretien et de réparation automobile et des garages présentant' +
        ' leurs offres et leurs services (individuellement « un ou le garage adhérent » et collectivement « les garages adhérents »). Les présentes CGVU sont complétées ou modifiées, le cas échéant,' +
        " par des conditions et modalités d'utilisation spécifiques propres à certaines fonctionnalités.",
      titleCGVU: '1. ACCEPTATION DES CGVU',
      textCGVU:
        "Le garage adhérent déclare et reconnaît avoir lu l'intégralité des termes des présentes conditions générales. En outre, la connexion à l'un quelconque des services proposés sur le site" +
        " accessible à l'adresse :www.iDGARAGES.com (ci-après le site Internet www.iDGARAGES.com) par l’intermédiaire de son espace dédié et sécurisé, accessible par un identifiant et un mot de" +
        ' passe (« Espace Garages ») emporte une acceptation sans réserve par le garage adhérent des présentes conditions générales.' +
        ' DIGITAL AFTERMARKET se réserve la possibilité de modifier à tout moment, en tout ou partie, les présentes CGVU. Il appartient en conséquence au garage utilisateur de consulter' +
        " régulièrement la dernière version des CGVU affichée à l'adresse http://www.idgarages.com/166-cgvu-garages. Le garage adhérent est réputé accepter cette dernière version à chaque nouvelle" +
        ' connexion sur le site, sur son espace dédié. […]',
      titleService: '3. ACCÈS AU SERVICE POUR LE GARAGE ADHÉRENT',
      textService:
        'Accès à l’interface professionnelle iDGARAGES.com : « ESPACE GARAGES » - DIGITAL AFTERMARKET met à la disposition du garage adhérent une interface professionnelle lui permettant de' +
        ' gérer ses rendez-vous « iDGARAGES.com » et de paramétrer son offre, ses tarifs, son planning de rendez-vous iDGARAGES.com et les informations descriptives du garage. […]' +
        ' c. Service de réservation de rendez-vous en ligne - L’utilisateur automobiliste a la possibilité de prendre rendez-vous en ligne dans le garage adhérent qu’il a sélectionné pour réaliser une' +
        ' prestation d’entretien auto. […]' +
        ' Les rendez-vous iDGARAGES.com pris pour le compte du garage adhérent sont réputés confirmés par le garage adhérent, sans que DIGITAL AFTERMARKET n’ait à contacter le garage adhérent' +
        ' au préalable. Sauf cas exceptionnels et justifiés, le garage adhérent ne peut modifier ou refuser le rendez-vous iDGARAGES.com. Le garage adhérent est prévenu d’une réservation, d’une' +
        ' modification ou d’une annulation de rendez-vous dans son garage par email et, éventuellement, par téléphone, SMS, FAX, ou tout autre moyen de contact.' +
        ' Le garage adhérent s’engage à consulter ses emails au moins une fois par jour ouvré.' +
        ' Sauf cas exceptionnels et justifiés (par exemple, coupure d’accès à Internet), l’email constitue la seule preuve de réception et d’information de la réservation de rendez-vous et de la prestation' +
        ' auto demandée par le client iDGARAGES.com.' +
        ' Pour les prestations simples, le garage adhérent s’engage à exécuter la prestation réservée par le client iDGARAGES.com dans la journée du rendez-vous convenu. Au plus tard, le véhicule' +
        ' sera restitué au client iDGARAGES.com à 18h00. Le garage adhérent et le client iDGARAGES.com peuvent convenir d’un horaire de restitution avant 18h00.' +
        ' De façon exceptionnelle, le garage adhérent et le client iDGARAGES.com peuvent convenir de décaler le rendez-vous de quelques jours.' +
        ' Pour les prestations complexes, le garage adhérent s’engage à exécuter la prestation réservée par le client iDGARAGES.com dans les 48 heures du rendez-vous convenu.' +
        ' En cas d’indisponibilité des pièces nécessaires à l’intervention pour le jour du rendez-vous, le garage adhérent devra prévenir le client iDGARAGES.com au plus tôt et avant la date prévue du' +
        ' rendez-vous, pour fixer une nouvelle date de rendez-vous.' +
        ' En cas d’annulation du rendez-vous du fait du client iDGARAGES.com ou de non présentation du client iDGARAGES.com au rendez-vous, le garage adhérent doit en informer au plus vite' +
        ' DIGITAL AFTERMARKET et au plus tard dans les 3 jours ouvrés qui suivent la date initiale de rendez-vous. Après vérifications, DIGITAL AFTERMARKET ne facturera pas le garage adhérent des' +
        ' rendez-vous annulés ou non honorés. Passé le délai de 3 jours ouvrés, DIGITAL AFTERMARKET pourra facturer les commissions dues sur ces rendez-vous.' +
        ' DIGITAL AFTERMARKET n’encourt aucune responsabilité en cas de rendez-vous annulé ou non honoré par un client iDGARAGES.com. Le garage adhérent ne pourra demander aucun' +
        ' dédommagement à DIGITAL AFTERMARKET en cas de rendez-vous annulé ou non honoré par un client iDGARAGES.com.' +
        ' d. Service de proposition d’offre et de calcul de devis estimatifs […]' +
        ' DIGITAL AFTERMARKET propose sur son site www.iDGARAGES.com des devis estimatifs concernant des prestations qui sont calculés à partir de nombreuses informations techniques stockées' +
        ' en base de données par des fournisseurs tiers de bases de données. Ces informations sont compilées et exploitées avec le plus grand professionnalisme possible. Néanmoins, dans certains' +
        ' cas, le montant calculé peut s’avérer erroné et cela ne sera aucunement le fait de DIGITAL AFTERMARKET, ce que le garage adhérent reconnaît et accepte. Le garage adhérent renonce à tous' +
        ' recours à l’encontre de DIGITAL AFTERMARKET à ce sujet.' +
        ' En cas de montant erroné, le garage adhérent en informera immédiatement et directement l’utilisateur automobiliste et conviendra avec lui du juste prix pour la prestation souhaitée.' +
        ' L’utilisateur automobiliste pourra, s’il le souhaite, refuser cette nouvelle proposition et annuler sa réservation, sans aucun frais. DIGITAL AFTERMARKET ne pourra être tenue responsable des' +
        ' conséquences liées à cette annulation ou à ce changement de proposition.' +
        ' e. Service d’informations descriptives du garage adhérent' +
        ' Le site www.iDGARAGES.com, propose au garage adhérent de mettre en avant son garage en offrant la possibilité, via l’ESPACE GARAGES, rubrique « Mon compte », de paramétrer sa fiche' +
        ' descriptive. La plupart des informations descriptives seront proposées à visibilité des clients iDGARAGES.com.' +
        ' Le garage adhérent s’engage à renseigner cette fiche de façon objective et factuelle, et à la mettre à jour, le cas échéant.' +
        ' Le garage adhérent, s’il appartient à un réseau de garages, reconnaît disposer des droits d’utilisation des marques et logos de son réseau. Le garage adhérent autorise expressément DIGITAL' +
        ' AFTERMARKET à utiliser ces marques et logos uniquement pour le mettre en avant sur www.iDGARAGES.com, et garantit l’exercice paisible desdits droits d’utilisation.' +
        ' Il est formellement interdit au garage adhérent :' +
        ' - de citer des marques concurrentes- d’utiliser des marques, visuels, logos dont il n’a pas les droits d’utilisation - de dénigrer le service iDGARAGES.com' +
        ' - de proposer une mise en relation directe entre le client iDGARAGES.com et le garage adhérent - de dénigrer les autres garages' +
        ' - d’insérer des liens et/ou adresses de sites Internet' +
        ' - d’insérer des numéros de téléphone et/ou de fax' +
        ' - de tenir des propos ou d’insérer des contenus diffamatoires, injurieux, discriminatoires, illicites, contraires à l’ordre public et aux bonnes mœurs. Le garage adhérent est seul responsable des' +
        ' textes et informations fournies sur sa fiche descriptive.' +
        ' DIGITAL AFTERMARKET se réserve le droit de modérer, corriger, supprimer, reformuler tout ou partie des textes et informations renseignés par le garage adhérent sur sa fiche descriptive. Dans' +
        ' ce cas, le garage adhérent en sera informé par email. DIGITAL AFTERMARKET se réserve le droit de désinscrire de façon unilatérale tout garage adhérent qui ne respecterait pas ces' +
        ' dispositions. […]',
      titleChart: '4. CHARTE DE QUALITÉ IDGARAGES.COM',
      textChart:
        'Tout garage qui souhaite être référencé sur www.iDGARAGES.com, doit adhérer sans exception ni réserve à la charte de qualité qui s’applique aux garages adhérents iDGARAGES.com.' +
        ' Cette charte porte sur des engagements de qualité sur lesquels le garage adhérent sera évalué par ses clients iDGARAGES.com.' +
        ' Cette charte de qualité est disponible à l’adresse suivante : http://www.idgarages.com/21-charte-qualite, ainsi que dans l’ESPACE GARAGES, rubrique « Mon compte ».' +
        ' En particulier, le garage adhérent doit souscrire les polices d’assurance nécessaires aux besoins de ses activités.',
      titleCondition:
        '7. CONDITIONS FINANCIÈRES, MODALITÉS DE PAIEMENT ET CONTESTATIONS',
      textCondition:
        "Aucun frais d'inscription n'est prévu pour le service www.iDGARAGES.com pour le garage adhérent. Un abonnement de visibilité obligatoire de 15€ hors taxes par mois est obligatoire dès le" +
        " premier mois d'inscription. Une commission pour chaque rendez-vous est prélevée sur les bases suivantes :" +
        " Pour chaque rendez-vous pris pour le compte du garage adhérent, ce dernier rémunérera DIGITAL AFTERMARKET d'une commission variable selon le montant total du devis réservé par le" +
        ' client iDGARAGES.com : 10% du montant du devis réservé.' +
        ' Ce montant pourra être modifié par DIGITAL AFTERMARKET, ce que le garage adhérent reconnaît et accepte expressément.' +
        ' DIGITAL AFTERMARKET facturera chaque début de mois les commissions sur les rendez-vous réservés le mois précédent ainsi que l’abonnement.' +
        ' Les factures seront établies et disponibles sur son espace garage dans l’onglet facturation au plus tard le 5 du mois. Le prélèvement automatique sera effectué à partir du 15 du mois (ou le' +
        ' premier jour ouvré suivant le 15 si c’est un jour férié/non travaillé). Une même facture regroupera toutes les commissions des rendez-vous transmis et les abonnements souscrits.' +
        ' Seul le paiement par mandat de prélèvement SEPA est autorisé. En cas de paiement par chèque ou virement, DIGITAL AFTERMARKET pourra facturer des frais de traitement supplémentaires' +
        ' de 50 €ht par facture.',
      footer1:
        'DIGITAL AFTERMARKET – 22, AVENUE ARISTIDE BRIAND – 94110 ARCUEIL',
      footer2:
        'TEL : 01 69 79 89 89 – FAX : 01 69 79 89 90 – www.iDGARAGES.com Société à responsabilité limitée au capital de 1 750 000 €',
      footer3:
        'RCS CRETEIL B 351 126 438 – SIRET 351 126 438 00057 – Code APE 6311Z – Numéro de TVA : FR35 351126438 00057',
    },
    avoirAdditional: {
      best: 'LES MEILLEURS GARAGE AUX MEILLEURS PRIX',
      avoir: 'Avoir',
      deliveryCustomer: 'Client livré',
      billingCustomer: 'Client facturé',
      referenceCustomer: 'Votre référence',
      paymentCustomer: 'Con. paiement / Mode paiement',
      sampleCustomer: 'PRELEVT AUTOMAT',
      accounting: 'SERVICE COMPTABILITE',
      deliveryAddress: 'Adresse de livraison',
      billingAddress: 'Adresse de facturation',
      designation: 'Désignation',
      ht: 'Montant HT',
      avoirAdd: 'Avoir additionnel',
      totalArticle: 'Total article',
      totalTVA: 'Total Hors TVA',
      tva: 'Base TVA',
      taux: 'Taux',
      billingTVA: 'Montant TVA',
      totalTTC: 'Total TTC à payer',
      attention: 'ATTENTION',
      textFooter1:
        'En cas de retard de paiement, indemnité forfaitaire pour frais de recouvrement : 40 euros',
      textFooter2: 'Aucun escompte pour paiement anticipé',
      textFooter3:
        'Selon la LME du 04/08/08, toute échéance non respectée donnera lieu de plein droit sans mise en demeure préalable, outre les frais',
      textFooter4:
        "judiciaires, à une pénalité de retard au taux d'intérêt pratiqué par la BCE majoré de 10 points",
      title: "Extrait des conditions générales de vente et d'utilisation ©",
      subtitle:
        'La version intégrale des CGVU est consultable sur le site iDGARAGES.com. Les CGVU ont été dument acceptées par le garage adhérent',
      cgvu:
        "Les présentes CGVU ont pour objet de définir les conditions et modalités de la mise à la disposition d'un service de mise en relation entre des automobilistes (indifféremment « un client" +
        ' iDGARAGES.com » ou «utilisateur automobiliste ») cherchant un prestataire de service pour la réalisation de prestations d’entretien et de réparation automobile et des garages présentant' +
        ' leurs offres et leurs services (individuellement « un ou le garage adhérent » et collectivement « les garages adhérents »). Les présentes CGVU sont complétées ou modifiées, le cas échéant,' +
        " par des conditions et modalités d'utilisation spécifiques propres à certaines fonctionnalités.",
      titleCGVU: '1. ACCEPTATION DES CGVU',
      textCGVU:
        "Le garage adhérent déclare et reconnaît avoir lu l'intégralité des termes des présentes conditions générales. En outre, la connexion à l'un quelconque des services proposés sur le site" +
        " accessible à l'adresse :www.iDGARAGES.com (ci-après le site Internet www.iDGARAGES.com) par l’intermédiaire de son espace dédié et sécurisé, accessible par un identifiant et un mot de" +
        ' passe (« Espace Garages ») emporte une acceptation sans réserve par le garage adhérent des présentes conditions générales.' +
        ' DIGITAL AFTERMARKET se réserve la possibilité de modifier à tout moment, en tout ou partie, les présentes CGVU. Il appartient en conséquence au garage utilisateur de consulter' +
        " régulièrement la dernière version des CGVU affichée à l'adresse http://www.idgarages.com/166-cgvu-garages. Le garage adhérent est réputé accepter cette dernière version à chaque nouvelle" +
        ' connexion sur le site, sur son espace dédié. […]',
      titleService: '3. ACCÈS AU SERVICE POUR LE GARAGE ADHÉRENT',
      textService:
        'Accès à l’interface professionnelle iDGARAGES.com : « ESPACE GARAGES » - DIGITAL AFTERMARKET met à la disposition du garage adhérent une interface professionnelle lui permettant de' +
        ' gérer ses rendez-vous « iDGARAGES.com » et de paramétrer son offre, ses tarifs, son planning de rendez-vous iDGARAGES.com et les informations descriptives du garage. […]' +
        ' c. Service de réservation de rendez-vous en ligne - L’utilisateur automobiliste a la possibilité de prendre rendez-vous en ligne dans le garage adhérent qu’il a sélectionné pour réaliser une' +
        ' prestation d’entretien auto. […]' +
        ' Les rendez-vous iDGARAGES.com pris pour le compte du garage adhérent sont réputés confirmés par le garage adhérent, sans que DIGITAL AFTERMARKET n’ait à contacter le garage adhérent' +
        ' au préalable. Sauf cas exceptionnels et justifiés, le garage adhérent ne peut modifier ou refuser le rendez-vous iDGARAGES.com. Le garage adhérent est prévenu d’une réservation, d’une' +
        ' modification ou d’une annulation de rendez-vous dans son garage par email et, éventuellement, par téléphone, SMS, FAX, ou tout autre moyen de contact.' +
        ' Le garage adhérent s’engage à consulter ses emails au moins une fois par jour ouvré.' +
        ' Sauf cas exceptionnels et justifiés (par exemple, coupure d’accès à Internet), l’email constitue la seule preuve de réception et d’information de la réservation de rendez-vous et de la prestation' +
        ' auto demandée par le client iDGARAGES.com.' +
        ' Pour les prestations simples, le garage adhérent s’engage à exécuter la prestation réservée par le client iDGARAGES.com dans la journée du rendez-vous convenu. Au plus tard, le véhicule' +
        ' sera restitué au client iDGARAGES.com à 18h00. Le garage adhérent et le client iDGARAGES.com peuvent convenir d’un horaire de restitution avant 18h00.' +
        ' De façon exceptionnelle, le garage adhérent et le client iDGARAGES.com peuvent convenir de décaler le rendez-vous de quelques jours.' +
        ' Pour les prestations complexes, le garage adhérent s’engage à exécuter la prestation réservée par le client iDGARAGES.com dans les 48 heures du rendez-vous convenu.' +
        ' En cas d’indisponibilité des pièces nécessaires à l’intervention pour le jour du rendez-vous, le garage adhérent devra prévenir le client iDGARAGES.com au plus tôt et avant la date prévue du' +
        ' rendez-vous, pour fixer une nouvelle date de rendez-vous.' +
        ' En cas d’annulation du rendez-vous du fait du client iDGARAGES.com ou de non présentation du client iDGARAGES.com au rendez-vous, le garage adhérent doit en informer au plus vite' +
        ' DIGITAL AFTERMARKET et au plus tard dans les 3 jours ouvrés qui suivent la date initiale de rendez-vous. Après vérifications, DIGITAL AFTERMARKET ne facturera pas le garage adhérent des' +
        ' rendez-vous annulés ou non honorés. Passé le délai de 3 jours ouvrés, DIGITAL AFTERMARKET pourra facturer les commissions dues sur ces rendez-vous.' +
        ' DIGITAL AFTERMARKET n’encourt aucune responsabilité en cas de rendez-vous annulé ou non honoré par un client iDGARAGES.com. Le garage adhérent ne pourra demander aucun' +
        ' dédommagement à DIGITAL AFTERMARKET en cas de rendez-vous annulé ou non honoré par un client iDGARAGES.com.' +
        ' d. Service de proposition d’offre et de calcul de devis estimatifs […]' +
        ' DIGITAL AFTERMARKET propose sur son site www.iDGARAGES.com des devis estimatifs concernant des prestations qui sont calculés à partir de nombreuses informations techniques stockées' +
        ' en base de données par des fournisseurs tiers de bases de données. Ces informations sont compilées et exploitées avec le plus grand professionnalisme possible. Néanmoins, dans certains' +
        ' cas, le montant calculé peut s’avérer erroné et cela ne sera aucunement le fait de DIGITAL AFTERMARKET, ce que le garage adhérent reconnaît et accepte. Le garage adhérent renonce à tous' +
        ' recours à l’encontre de DIGITAL AFTERMARKET à ce sujet.' +
        ' En cas de montant erroné, le garage adhérent en informera immédiatement et directement l’utilisateur automobiliste et conviendra avec lui du juste prix pour la prestation souhaitée.' +
        ' L’utilisateur automobiliste pourra, s’il le souhaite, refuser cette nouvelle proposition et annuler sa réservation, sans aucun frais. DIGITAL AFTERMARKET ne pourra être tenue responsable des' +
        ' conséquences liées à cette annulation ou à ce changement de proposition.' +
        ' e. Service d’informations descriptives du garage adhérent' +
        ' Le site www.iDGARAGES.com, propose au garage adhérent de mettre en avant son garage en offrant la possibilité, via l’ESPACE GARAGES, rubrique « Mon compte », de paramétrer sa fiche' +
        ' descriptive. La plupart des informations descriptives seront proposées à visibilité des clients iDGARAGES.com.' +
        ' Le garage adhérent s’engage à renseigner cette fiche de façon objective et factuelle, et à la mettre à jour, le cas échéant.' +
        ' Le garage adhérent, s’il appartient à un réseau de garages, reconnaît disposer des droits d’utilisation des marques et logos de son réseau. Le garage adhérent autorise expressément DIGITAL' +
        ' AFTERMARKET à utiliser ces marques et logos uniquement pour le mettre en avant sur www.iDGARAGES.com, et garantit l’exercice paisible desdits droits d’utilisation.' +
        ' Il est formellement interdit au garage adhérent :' +
        ' - de citer des marques concurrentes- d’utiliser des marques, visuels, logos dont il n’a pas les droits d’utilisation - de dénigrer le service iDGARAGES.com' +
        ' - de proposer une mise en relation directe entre le client iDGARAGES.com et le garage adhérent - de dénigrer les autres garages' +
        ' - d’insérer des liens et/ou adresses de sites Internet' +
        ' - d’insérer des numéros de téléphone et/ou de fax' +
        ' - de tenir des propos ou d’insérer des contenus diffamatoires, injurieux, discriminatoires, illicites, contraires à l’ordre public et aux bonnes mœurs. Le garage adhérent est seul responsable des' +
        ' textes et informations fournies sur sa fiche descriptive.' +
        ' DIGITAL AFTERMARKET se réserve le droit de modérer, corriger, supprimer, reformuler tout ou partie des textes et informations renseignés par le garage adhérent sur sa fiche descriptive. Dans' +
        ' ce cas, le garage adhérent en sera informé par email. DIGITAL AFTERMARKET se réserve le droit de désinscrire de façon unilatérale tout garage adhérent qui ne respecterait pas ces' +
        ' dispositions. […]',
      titleChart: '4. CHARTE DE QUALITÉ IDGARAGES.COM',
      textChart:
        'Tout garage qui souhaite être référencé sur www.iDGARAGES.com, doit adhérer sans exception ni réserve à la charte de qualité qui s’applique aux garages adhérents iDGARAGES.com.' +
        ' Cette charte porte sur des engagements de qualité sur lesquels le garage adhérent sera évalué par ses clients iDGARAGES.com.' +
        ' Cette charte de qualité est disponible à l’adresse suivante : http://www.idgarages.com/21-charte-qualite, ainsi que dans l’ESPACE GARAGES, rubrique « Mon compte ».' +
        ' En particulier, le garage adhérent doit souscrire les polices d’assurance nécessaires aux besoins de ses activités.',
      titleCondition:
        '7. CONDITIONS FINANCIÈRES, MODALITÉS DE PAIEMENT ET CONTESTATIONS',
      textCondition:
        "Aucun frais d'inscription n'est prévu pour le service www.iDGARAGES.com pour le garage adhérent. Un abonnement de visibilité obligatoire de 15€ hors taxes par mois est obligatoire dès le" +
        " premier mois d'inscription. Une commission pour chaque rendez-vous est prélevée sur les bases suivantes :" +
        " Pour chaque rendez-vous pris pour le compte du garage adhérent, ce dernier rémunérera DIGITAL AFTERMARKET d'une commission variable selon le montant total du devis réservé par le" +
        ' client iDGARAGES.com : 10% du montant du devis réservé.' +
        ' Ce montant pourra être modifié par DIGITAL AFTERMARKET, ce que le garage adhérent reconnaît et accepte expressément.' +
        ' DIGITAL AFTERMARKET facturera chaque début de mois les commissions sur les rendez-vous réservés le mois précédent ainsi que l’abonnement.' +
        ' Les factures seront établies et disponibles sur son espace garage dans l’onglet facturation au plus tard le 5 du mois. Le prélèvement automatique sera effectué à partir du 15 du mois (ou le' +
        ' premier jour ouvré suivant le 15 si c’est un jour férié/non travaillé). Une même facture regroupera toutes les commissions des rendez-vous transmis et les abonnements souscrits.' +
        ' Seul le paiement par mandat de prélèvement SEPA est autorisé. En cas de paiement par chèque ou virement, DIGITAL AFTERMARKET pourra facturer des frais de traitement supplémentaires' +
        ' de 50 €ht par facture.',
      footer1:
        'DIGITAL AFTERMARKET – 22, AVENUE ARISTIDE BRIAND – 94110 ARCUEIL',
      footer2:
        'TEL : 01 69 79 89 89 – FAX : 01 69 79 89 90 – www.iDGARAGES.com Société à responsabilité limitée au capital de 1 750 000 €',
      footer3:
        'RCS CRETEIL B 351 126 438 – SIRET 351 126 438 00057 – Code APE 6311Z – Numéro de TVA : FR35 351126438 00057',
    },
    avoirPartnership: {
      best: 'LES MEILLEURS GARAGE AUX MEILLEURS PRIX',
      avoir: 'Avoir',
      deliveryCustomer: 'Client livré',
      billingCustomer: 'Client facturé',
      referenceCustomer: 'Votre référence',
      paymentCustomer: 'Con. paiement / Mode paiement',
      sampleCustomer: 'PRELEVT AUTOMAT',
      accounting: 'SERVICE COMPTABILITE',
      deliveryAddress: 'Adresse de livraison',
      billingAddress: 'Adresse de facturation',
      ref: 'Ref commande',
      designation: 'Désignation',
      ht: 'Montant HT',
      totalArticle: 'Total article',
      totalTVA: 'Total Hors TVA',
      tva: 'Base TVA',
      taux: 'Taux',
      billingTVA: 'Montant TVA',
      totalTTC: 'Total TTC à payer',
      attention: 'ATTENTION',
      textFooter1:
        'En cas de retard de paiement, indemnité forfaitaire pour frais de recouvrement : 40 euros',
      textFooter2: 'Aucun escompte pour paiement anticipé',
      textFooter3:
        'Selon la LME du 04/08/08, toute échéance non respectée donnera lieu de plein droit sans mise en demeure préalable, outre les frais',
      textFooter4:
        "judiciaires, à une pénalité de retard au taux d'intérêt pratiqué par la BCE majoré de 10 points",
      title: "Extrait des conditions générales de vente et d'utilisation ©",
      subtitle:
        'La version intégrale des CGVU est consultable sur le site iDGARAGES.com. Les CGVU ont été dument acceptées par le garage adhérent',
      cgvu:
        "Les présentes CGVU ont pour objet de définir les conditions et modalités de la mise à la disposition d'un service de mise en relation entre des automobilistes (indifféremment « un client" +
        ' iDGARAGES.com » ou «utilisateur automobiliste ») cherchant un prestataire de service pour la réalisation de prestations d’entretien et de réparation automobile et des garages présentant' +
        ' leurs offres et leurs services (individuellement « un ou le garage adhérent » et collectivement « les garages adhérents »). Les présentes CGVU sont complétées ou modifiées, le cas échéant,' +
        " par des conditions et modalités d'utilisation spécifiques propres à certaines fonctionnalités.",
      titleCGVU: '1. ACCEPTATION DES CGVU',
      textCGVU:
        "Le garage adhérent déclare et reconnaît avoir lu l'intégralité des termes des présentes conditions générales. En outre, la connexion à l'un quelconque des services proposés sur le site" +
        " accessible à l'adresse :www.iDGARAGES.com (ci-après le site Internet www.iDGARAGES.com) par l’intermédiaire de son espace dédié et sécurisé, accessible par un identifiant et un mot de" +
        ' passe (« Espace Garages ») emporte une acceptation sans réserve par le garage adhérent des présentes conditions générales.' +
        ' DIGITAL AFTERMARKET se réserve la possibilité de modifier à tout moment, en tout ou partie, les présentes CGVU. Il appartient en conséquence au garage utilisateur de consulter' +
        " régulièrement la dernière version des CGVU affichée à l'adresse http://www.idgarages.com/166-cgvu-garages. Le garage adhérent est réputé accepter cette dernière version à chaque nouvelle" +
        ' connexion sur le site, sur son espace dédié. […]',
      titleService: '3. ACCÈS AU SERVICE POUR LE GARAGE ADHÉRENT',
      textService:
        'Accès à l’interface professionnelle iDGARAGES.com : « ESPACE GARAGES » - DIGITAL AFTERMARKET met à la disposition du garage adhérent une interface professionnelle lui permettant de' +
        ' gérer ses rendez-vous « iDGARAGES.com » et de paramétrer son offre, ses tarifs, son planning de rendez-vous iDGARAGES.com et les informations descriptives du garage. […]' +
        ' c. Service de réservation de rendez-vous en ligne - L’utilisateur automobiliste a la possibilité de prendre rendez-vous en ligne dans le garage adhérent qu’il a sélectionné pour réaliser une' +
        ' prestation d’entretien auto. […]' +
        ' Les rendez-vous iDGARAGES.com pris pour le compte du garage adhérent sont réputés confirmés par le garage adhérent, sans que DIGITAL AFTERMARKET n’ait à contacter le garage adhérent' +
        ' au préalable. Sauf cas exceptionnels et justifiés, le garage adhérent ne peut modifier ou refuser le rendez-vous iDGARAGES.com. Le garage adhérent est prévenu d’une réservation, d’une' +
        ' modification ou d’une annulation de rendez-vous dans son garage par email et, éventuellement, par téléphone, SMS, FAX, ou tout autre moyen de contact.' +
        ' Le garage adhérent s’engage à consulter ses emails au moins une fois par jour ouvré.' +
        ' Sauf cas exceptionnels et justifiés (par exemple, coupure d’accès à Internet), l’email constitue la seule preuve de réception et d’information de la réservation de rendez-vous et de la prestation' +
        ' auto demandée par le client iDGARAGES.com.' +
        ' Pour les prestations simples, le garage adhérent s’engage à exécuter la prestation réservée par le client iDGARAGES.com dans la journée du rendez-vous convenu. Au plus tard, le véhicule' +
        ' sera restitué au client iDGARAGES.com à 18h00. Le garage adhérent et le client iDGARAGES.com peuvent convenir d’un horaire de restitution avant 18h00.' +
        ' De façon exceptionnelle, le garage adhérent et le client iDGARAGES.com peuvent convenir de décaler le rendez-vous de quelques jours.' +
        ' Pour les prestations complexes, le garage adhérent s’engage à exécuter la prestation réservée par le client iDGARAGES.com dans les 48 heures du rendez-vous convenu.' +
        ' En cas d’indisponibilité des pièces nécessaires à l’intervention pour le jour du rendez-vous, le garage adhérent devra prévenir le client iDGARAGES.com au plus tôt et avant la date prévue du' +
        ' rendez-vous, pour fixer une nouvelle date de rendez-vous.' +
        ' En cas d’annulation du rendez-vous du fait du client iDGARAGES.com ou de non présentation du client iDGARAGES.com au rendez-vous, le garage adhérent doit en informer au plus vite' +
        ' DIGITAL AFTERMARKET et au plus tard dans les 3 jours ouvrés qui suivent la date initiale de rendez-vous. Après vérifications, DIGITAL AFTERMARKET ne facturera pas le garage adhérent des' +
        ' rendez-vous annulés ou non honorés. Passé le délai de 3 jours ouvrés, DIGITAL AFTERMARKET pourra facturer les commissions dues sur ces rendez-vous.' +
        ' DIGITAL AFTERMARKET n’encourt aucune responsabilité en cas de rendez-vous annulé ou non honoré par un client iDGARAGES.com. Le garage adhérent ne pourra demander aucun' +
        ' dédommagement à DIGITAL AFTERMARKET en cas de rendez-vous annulé ou non honoré par un client iDGARAGES.com.' +
        ' d. Service de proposition d’offre et de calcul de devis estimatifs […]' +
        ' DIGITAL AFTERMARKET propose sur son site www.iDGARAGES.com des devis estimatifs concernant des prestations qui sont calculés à partir de nombreuses informations techniques stockées' +
        ' en base de données par des fournisseurs tiers de bases de données. Ces informations sont compilées et exploitées avec le plus grand professionnalisme possible. Néanmoins, dans certains' +
        ' cas, le montant calculé peut s’avérer erroné et cela ne sera aucunement le fait de DIGITAL AFTERMARKET, ce que le garage adhérent reconnaît et accepte. Le garage adhérent renonce à tous' +
        ' recours à l’encontre de DIGITAL AFTERMARKET à ce sujet.' +
        ' En cas de montant erroné, le garage adhérent en informera immédiatement et directement l’utilisateur automobiliste et conviendra avec lui du juste prix pour la prestation souhaitée.' +
        ' L’utilisateur automobiliste pourra, s’il le souhaite, refuser cette nouvelle proposition et annuler sa réservation, sans aucun frais. DIGITAL AFTERMARKET ne pourra être tenue responsable des' +
        ' conséquences liées à cette annulation ou à ce changement de proposition.' +
        ' e. Service d’informations descriptives du garage adhérent' +
        ' Le site www.iDGARAGES.com, propose au garage adhérent de mettre en avant son garage en offrant la possibilité, via l’ESPACE GARAGES, rubrique « Mon compte », de paramétrer sa fiche' +
        ' descriptive. La plupart des informations descriptives seront proposées à visibilité des clients iDGARAGES.com.' +
        ' Le garage adhérent s’engage à renseigner cette fiche de façon objective et factuelle, et à la mettre à jour, le cas échéant.' +
        ' Le garage adhérent, s’il appartient à un réseau de garages, reconnaît disposer des droits d’utilisation des marques et logos de son réseau. Le garage adhérent autorise expressément DIGITAL' +
        ' AFTERMARKET à utiliser ces marques et logos uniquement pour le mettre en avant sur www.iDGARAGES.com, et garantit l’exercice paisible desdits droits d’utilisation.' +
        ' Il est formellement interdit au garage adhérent :' +
        ' - de citer des marques concurrentes- d’utiliser des marques, visuels, logos dont il n’a pas les droits d’utilisation - de dénigrer le service iDGARAGES.com' +
        ' - de proposer une mise en relation directe entre le client iDGARAGES.com et le garage adhérent - de dénigrer les autres garages' +
        ' - d’insérer des liens et/ou adresses de sites Internet' +
        ' - d’insérer des numéros de téléphone et/ou de fax' +
        ' - de tenir des propos ou d’insérer des contenus diffamatoires, injurieux, discriminatoires, illicites, contraires à l’ordre public et aux bonnes mœurs. Le garage adhérent est seul responsable des' +
        ' textes et informations fournies sur sa fiche descriptive.' +
        ' DIGITAL AFTERMARKET se réserve le droit de modérer, corriger, supprimer, reformuler tout ou partie des textes et informations renseignés par le garage adhérent sur sa fiche descriptive. Dans' +
        ' ce cas, le garage adhérent en sera informé par email. DIGITAL AFTERMARKET se réserve le droit de désinscrire de façon unilatérale tout garage adhérent qui ne respecterait pas ces' +
        ' dispositions. […]',
      titleChart: '4. CHARTE DE QUALITÉ IDGARAGES.COM',
      textChart:
        'Tout garage qui souhaite être référencé sur www.iDGARAGES.com, doit adhérer sans exception ni réserve à la charte de qualité qui s’applique aux garages adhérents iDGARAGES.com.' +
        ' Cette charte porte sur des engagements de qualité sur lesquels le garage adhérent sera évalué par ses clients iDGARAGES.com.' +
        ' Cette charte de qualité est disponible à l’adresse suivante : http://www.idgarages.com/21-charte-qualite, ainsi que dans l’ESPACE GARAGES, rubrique « Mon compte ».' +
        ' En particulier, le garage adhérent doit souscrire les polices d’assurance nécessaires aux besoins de ses activités.',
      titleCondition:
        '7. CONDITIONS FINANCIÈRES, MODALITÉS DE PAIEMENT ET CONTESTATIONS',
      textCondition:
        "Aucun frais d'inscription n'est prévu pour le service www.iDGARAGES.com pour le garage adhérent. Un abonnement de visibilité obligatoire de 15€ hors taxes par mois est obligatoire dès le" +
        " premier mois d'inscription. Une commission pour chaque rendez-vous est prélevée sur les bases suivantes :" +
        " Pour chaque rendez-vous pris pour le compte du garage adhérent, ce dernier rémunérera DIGITAL AFTERMARKET d'une commission variable selon le montant total du devis réservé par le" +
        ' client iDGARAGES.com : 10% du montant du devis réservé.' +
        ' Ce montant pourra être modifié par DIGITAL AFTERMARKET, ce que le garage adhérent reconnaît et accepte expressément.' +
        ' DIGITAL AFTERMARKET facturera chaque début de mois les commissions sur les rendez-vous réservés le mois précédent ainsi que l’abonnement.' +
        ' Les factures seront établies et disponibles sur son espace garage dans l’onglet facturation au plus tard le 5 du mois. Le prélèvement automatique sera effectué à partir du 15 du mois (ou le' +
        ' premier jour ouvré suivant le 15 si c’est un jour férié/non travaillé). Une même facture regroupera toutes les commissions des rendez-vous transmis et les abonnements souscrits.' +
        ' Seul le paiement par mandat de prélèvement SEPA est autorisé. En cas de paiement par chèque ou virement, DIGITAL AFTERMARKET pourra facturer des frais de traitement supplémentaires' +
        ' de 50 €ht par facture.',
      footer1:
        'DIGITAL AFTERMARKET – 22, AVENUE ARISTIDE BRIAND – 94110 ARCUEIL',
      footer2:
        'TEL : 01 69 79 89 89 – FAX : 01 69 79 89 90 – www.iDGARAGES.com Société à responsabilité limitée au capital de 1 750 000 €',
      footer3:
        'RCS CRETEIL B 351 126 438 – SIRET 351 126 438 00057 – Code APE 6311Z – Numéro de TVA : FR35 351126438 00057',
    },
  },
  login: {
    emailPassword: 'Veuillez entrer votre email et votre mot de passe',
    email: 'Veuillez entrer votre email',
    password: 'Veuillez entrer votre mot de passe',
    emailPattern: 'Veuillez entrer un email valide',
    passwordPattern:
      'Votre mot de passe doit contenir au moins 8 caractères , une Majuscule, un Chiffre',
    exist: "Nom d'utilisateur ou mot de passe incorrect",
    incorrect: 'Email ou Mot de passe incorrecte',
    title: 'Connectez-vous',
    emailField: 'Email*',
    passwordField: 'Mot de passe*',
    connectButton: 'Se connecter',
    forgotPassword: 'Mot de passe perdu ?',
  },
  reset: {
    email: 'Veuillez entrer votre email',
    emailPattern: 'Veuillez entrer un email valide',
    title: 'Vous avez oublié votre mot de passe ?',
    subtitle:
      'Entrez votre adresse e-mail afin de recevoir un lien de réinitialisation de mot de passe',
    emailField: 'Nom du garage',
    button: 'Recevoir un e-mail',
  },
  resetPassword: {
    passwordConfirm:
      'Veuillez remplir votre nouveau mot de passe et le confirmer',
    newPassword: 'Veuillez remplir votre nouveau mot de passe',
    confirmPassword: 'Veuillez confirmer votre mot de passe',
    passwordMatch: 'Les deux mots de passe sont différent',
    passwordPattern:
      'Votre mot de passe doit contenir au moins 8 caractères , une Majuscule, un Chiffre',
    title: 'Modifier votre mot de passe',
    subtitle: 'Entrer un nouveau mot de passe',
    passwordField: 'Nouveau mot de passe*',
    confirmField: 'Confirmation de mot de passe*',
    confirmButton: 'Confirmer',
  },
  loginGarage: {
    garage: 'Veuillez entrer un garage',
    email: 'Veuillez entrer un email',
    emailPattern: 'Veuillez entrer un email valide',
    code: 'Veuillez entrer un code réparateur',
    codePattern: 'Veuillez entrer un code valide',
    title: 'Entrez dans un garage par :',
    socialRaison: 'Raison Sociale',
    emailAdress: 'Adresse email',
    codeRep: 'Code réparateur',
    garageField: 'Nom du garage',
    emailField: 'Email',
    codeField: 'Code réparateur',
    connectButton: 'Entrer',
    deconnectionButton: 'Déconnexion',
  },
  account: {
    civility: 'Civilité*',
    garage: 'Nom du garage*',
    lastname: 'Nom*',
    firstname: 'Prénom*',
    title: 'Informations principales',
    titleMyData: 'Mes informations',
    read: 'Visualiser votre fiche en ligne',
    info: 'Infos garages',
    description: 'Description',
    timetable: 'Horaires',
    bank: 'Infos bancaires',
    service: 'Services',
    infoAccount: 'Infos compte',
    see: 'lire',
    myServices: 'Gérer mes services',
    autossimo:
      'Merci de saisir vos login et mot de passe Autossimo afin de transférer votre devis et de passer commande de vos pièces sur Autossimo.',
    autossimoLogin: 'Identifiant *',
    autossimoPassword: 'Mot de passe *',
    servicesList: {
      rep_carte_grise: 'Formalités carte grise',
      rep_flag_certifcli: 'Certifié climatisation',
      rep_allumage: 'Gestion moteur Essence/Diesel',
      rep_carosserie: 'Carrosserie (peinture et réparation)',
      rep_renovation: 'Réparation et rénovation plastique',
      rep_is_diagnostic_electronique: 'Diagnostic électronique',
      rep_is_geometrie_trains: 'Géométrie des trains',
      rep_is_specialiste_clim: 'Spécialiste climatisation',
      rep_is_specialiste_gpl: 'Spécialiste GPL',
      rep_is_specialiste_electro_diesel: 'Spécialiste électro-diesel',
      rep_is_specialiste_electrique_hybride: 'Spécialiste électrique & hybride',
      rep_garage_mobile: 'Garage Mobile',
      rep_is_gare_proche: 'Transport en commun à proximité',
      rep_depannage: 'Dépannage',
      rep_veh_courtoisie: 'Véhicule de courtoisie',
      rep_service_domicile: 'Service à domicile',
      rep_station_lavage: 'Station de lavage',
      rep_is_gardiennage_pneus_neige: 'Gardiennage pneus neige',
      rep_facilire_paie: 'Facilités de paiement',
      rep_station_essence: 'Station essence',
      rep_is_distributeur_boissons_cafe:
        'Machine à café et/ou distributeur de boissons',
      rep_is_raccompagne_client_domicile:
        'Possibilité de raccompagner le client à son domicile',
      rep_pctrl_technique: 'Pré-contrôle technique',
      rep_pres_ctrltech: 'Présentation au contrôle technique',
      rep_is_demarche_declaration_ass:
        'Démarche déclaration sinistre assurance',
      rep_is_parking: 'Parking/Stationnement facile',
      rep_wifi: 'Wifi pour les clients',
      rep_is_espace_attente: 'Espace attente',
      rep_boutique: 'Boutique/centre commercial à proximité',
      rep_is_montre_pieces_changees:
        'Possibilité de monter les pièces usagées/changées sur demande',
      rep_espace_enfant: 'Espace enfants',
      rep_is_vente_vo_vn: 'Vente VO-VN',
      rep_montage_pneus: 'Montages de pneus',
    },
    bankInfo: {
      messageSizeError: 'Fichier trop volumineux',
      messageWrongFileError: 'Format du fichier incompatible',
    },
  },
  accountDisabledPage: {
    message1:
      "L'accès à votre espace Garage a été désactivé. Afin de régulariser la situation, contactez",
    message2: '',
    message3: 'Vous pouvez toujours consulter votre',
    message4: '',
    billingLink: 'facturation.',
    mail: 'garagiste@idgarages.com.',
    button: "J'ai compris",
  },
  printButton: {
    rdv: 'Le rendez-vous',
    repair: `L'ordre de réparation`,
  },
  mobileApp: {
    admin: 'Administration',
    garage: 'Garage',
    text1: "Bénéficiez d'une expérience",
    text2: 'optimale sur mobile',
    text3: 'en téléchargeant',
    text4: "l'application idGarages.pro",
  },
};

export default lg;

import React from 'react';

const TimeIcon = (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.25 0.25C3.38401 0.25 0.25 3.38401 0.25 7.25C0.25 11.116 3.38401 14.25 7.25 14.25C11.116 14.25 14.25 11.116 14.25 7.25C14.25 3.38401 11.116 0.25 7.25 0.25ZM7.25 1.45C10.4533 1.45 13.05 4.04675 13.05 7.25C13.05 10.4533 10.4533 13.05 7.25 13.05C4.04675 13.05 1.45 10.4533 1.45 7.25C1.45 4.04675 4.04675 1.45 7.25 1.45ZM7.84452 3.96858C7.80479 3.67572 7.55376 3.45 7.25 3.45C6.91863 3.45 6.65 3.71863 6.65 4.05V7.25L6.65747 7.34443C6.67726 7.46857 6.73572 7.58425 6.82574 7.67426L8.42574 9.27426L8.49303 9.33236C8.72792 9.50664 9.06125 9.48728 9.27426 9.27426L9.33236 9.20697C9.50664 8.97208 9.48728 8.63875 9.27426 8.42574L7.85 7.0012V4.05L7.84452 3.96858Z"
      fill="#AAAEC5"
    />
  </svg>
);

export default TimeIcon;

import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import Button from '../../../assets/generic-components/Button';
import colors from '../../../assets/style-colors';
import { Store } from '../../../redux/types';

interface CancelQuoteProps {
  onClick: () => void;
}

const CancelQuote: React.FC<CancelQuoteProps> = ({ onClick, children }) => {
  const useStyles = makeStyles(() => ({
    root: {
      display: 'flex',
      flex: '100%',
      width: '530px',
      margin: '0px auto',
      flexWrap: 'wrap',
      alignItems: 'flex-start',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },

    content: {
      width: '530px',
      margin: '0px auto',
    },

    head: {
      display: 'flex',
      flex: '100%',
      fontSize: '15px',
      fontWeight: 'bolder',
      color: colors.WALLET,
      marginTop: '5px',
    },

    title: {
      display: 'flex',
      flex: '100%',
      fontSize: '12px',
      color: colors.slateGrey,
    },

    textarea: {
      background: colors.paleGrey,
      padding: '15px',
      borderRadius: '8px',
      border: '0px solid black',
      fontSize: '13px',
      fontWeight: 'bold',
      color: colors.WALLET,
    },

    modal_header: {
      padding: '30px 30px 0px 30px',
      border: `0px solid ${colors.white}`,
    },

    modal_body: {
      padding: '15px 30px 15px 30px',
    },

    modal_footer: {
      background: colors.paleGrey,
      borderTop: `0px solid ${colors.paleGrey}`,
      padding: '20px',
    },
  }));

  // CONSTANTS:
  const classes = useStyles();
  const { languageReducer } = useSelector((state: Store) => state);
  const lg = languageReducer.language;
  const [show, setShow] = useState(false);

  // FUNCTIONS:

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // STYLES:

  // HTML:

  return (
    <>
      <div role="button" tabIndex={0} onClick={handleShow}>
        {children}
      </div>

      <Modal
        dialogClassName={classes.content}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className={classes.modal_header} closeButton>
          <div className={classes.root}>
            <span className={classes.head}>{lg.command.cancelQuote.title}</span>
          </div>
        </Modal.Header>
        <Modal.Body className={classes.modal_body}>
          <div className={classes.root}>
            <p className={classes.title}>{lg.command.cancelQuote.confirm}</p>
          </div>
        </Modal.Body>

        <Modal.Footer className={classes.modal_footer}>
          <Button
            theme="light"
            title={lg.command.cancelQuote.close}
            onClick={() => handleClose()}
          />
          <Button
            theme="dark"
            title={lg.command.cancelQuote.valid}
            onClick={() => {
              onClick();
              handleClose();
            }}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CancelQuote;

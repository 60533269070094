import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Store } from '../../../../../redux/types';
import editIcon from '../../../../../assets/style-elements/Icon-edit.svg';
import planningIcon from '../../../../../assets/style-elements/01-electrons-icon-planning-gray@3x.png';
import useStyles from '../style';
import { Booking } from '../../../../../redux/stores/planning/types';
import RenderOnRoles from '../../../../../assets/generic-components/RenderOnRoles';
import Appointment from '../../../modal/appointment';
import { Roles } from '../../../../../services/authentication-service';

interface PlanningProps {
  currentBooking: Booking
}

enum QUOTEID {
  EMBRAYAGE = '240101',
  EMBRAYAGE_VOLANT = '240201',
  DISTRIBUTION = '250101',
  DIESEL = '100101',
  ESSENCE = '100102'
}

const Planning = ({ currentBooking }: PlanningProps) => {

  const {
    languageReducer: { language: lg }
  } = useSelector((state: Store) => state);
  
  const classes = useStyles();

  const quoteIds = currentBooking?.quotes.map(
    quote => quote.carServiceId
  );

  const messageVehiculeReturn = () => {
    const maxHour = 11;

    const { bookingDepositHour } = currentBooking;

    if (bookingDepositHour && parseInt(bookingDepositHour) >= maxHour) {
      switch (true) {
        case quoteIds.includes(QUOTEID.ESSENCE):
        case quoteIds.includes(QUOTEID.DIESEL):
        case quoteIds.includes(QUOTEID.DISTRIBUTION):
        case quoteIds.includes(QUOTEID.EMBRAYAGE):
        case quoteIds.includes(QUOTEID.EMBRAYAGE_VOLANT):
          return lg.general.nextDay;
        default:
          return lg.general.back;
      }
    }
  
    return lg.general.back;
  };

  return (
    <div className={classes.donateItemWrapper}>
      <img className="leftIcon" src={planningIcon} alt="planning icon" />

      <div className="donateList">
        <div className="donateItem">
          <p className="first">{lg.command.lateral.carCare}</p>
          <p className="second">
            {`${moment(currentBooking.bookingDate).format('dddd, D MMMM ')} ${
              currentBooking.bookingDepositHour
                ? `- ${currentBooking?.bookingDepositHour?.split('-')[0]}`
                : ''
            }`}
          </p>
          <p className="third">{messageVehiculeReturn()}</p>
        </div>

        {currentBooking.isWithCourtesyVehicle && (
          <div className="donateItem tag">
            <span className="button">{lg.general.type}</span>
          </div>
        )} 
        {currentBooking.isWithEasyPayment && (
          <div className="donateItem tag">
            <span className="button">{lg.general.payment}</span>
          </div>
        )} 
      </div>
      <div className="editButton">
        {currentBooking.networkId !== 'GARAGE-OWNER' && (
          <RenderOnRoles roles={[Roles.CALL_CENTER, Roles.ADMIN]}>
            <Appointment>
              <img className="rightIcon" src={editIcon} alt="edit icon" />
            </Appointment>
          </RenderOnRoles>
        )}
        {currentBooking.networkId === 'GARAGE-OWNER' && (
          <RenderOnRoles roles={[Roles.GARAGE]}>
            <Appointment>
              <img className="rightIcon" src={editIcon} alt="edit icon" />
            </Appointment>
          </RenderOnRoles>
        )}
      </div>
    </div>
  );
}

export default Planning;
import makeStyles from '@material-ui/core/styles/makeStyles';
import colors from '../../assets/style-colors';

const useStyles = makeStyles(() => ({
  leftMenuItem: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    color: colors.lightGreyBlue,
    marginBottom: '25px',
  },

  activeItem: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    textDecoration: 'none',
    color: colors.black,
    position: 'relative',
    marginBottom: '25px',

    '&:after': {
      content: '""',
      width: '4px',
      height: '27px',
      backgroundColor: colors.dark,
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: '-16px',
      borderRadius: '2px',
    },

    '& svg': {
      '& path ': {
        fill: colors.primary,
      },
    },
    '& .iconDevis svg': {
      '& path': {
        stroke: colors.primary,
        fill: 'inherit',
        strokeWidth: '1',
        '&:first-child,&:nth-child(2)': {
          fill: colors.dark,
          strokeWidth: 0,
        },
      },
    },
  },

  leftMenuIcon: {
    cursor: 'pointer',
    fill: colors.lightGreyBlue,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& .MuiSvgIcon-root': {
      marginLeft: '13px',
    },
  },

  activeIcon: {
    cursor: 'pointer',
    position: 'relative',

    '& svg': {
      '& path ': {
        fill: colors.primary,
      },
    },
    '& .iconDevis svg': {
      '& path': {
        stroke: colors.primary,
        fill: 'inherit',
        strokeWidth: '1',
        '&:first-child,&:nth-child(2)': {
          fill: colors.dark,
          strokeWidth: 0,
        },
      },
    },
  },
  leftMenuText: {
    fontSize: ' 12px',
    marginLeft: '15px',
    color: colors.darkGray,
    fontWeight: 'bold',
    transition: 'all .1s ease-in',
    '&:hover': {
      color: colors.dark,
    },
  },
  activeText: {
    color: colors.dark,
    fontSize: '12px',
    marginLeft: '15px',
    fontWeight: 'bold',
  },
}));

export default useStyles;

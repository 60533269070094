import makeStyles from '@material-ui/core/styles/makeStyles';
import colors from '../../assets/style-colors';
import photo from '../../assets/images/bitmap@3x.png';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flex: '1',
    backgroundColor: colors.paleGrey,
    overflow: 'hidden',
  },

  hide: {
    display: 'none',
  },

  error: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flex: '100%',
    paddingTop: '10px',
    color: 'red',
    fontWeight: 'bolder',
  },

  photo: {
    flex: '40%',
    height: '100vh',
    flexDirection: 'column',
    backgroundImage: `url(${photo})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },

  form: {
    display: 'flex',
    flex: '60%',
    flexWrap: 'nowrap',
    flexDirection: 'column',
  },

  email: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    flexDirection: 'column',
    flex: '100%',
    paddingTop: '23px',

    '& fieldset': {
      border: 0,
    },

    '& button': {
      display: 'none',
    },

    '& label': {
      fontSize: '12px',
      fontWeight: '500',
      lineHeight: '1.17',
      marginBottom: '11px',
      color: colors.slateGrey,
    },

    '& input': {
      width: '440px',
      height: '40px',
      borderRadius: '8px',
      backgroundColor: colors.white,
      padding: '0px 1em',
      borderWidth: '0px',
    },

    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
      {
        padding: '0px 1em',
      },

    '& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]':
      {
        padding: 0,
      },
  },

  codeReparateur: {
    fontSize: '16px',
  },

  connect: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: colors.WALLET,
    marginTop: '30px',
  },

  block: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    flex: '100%',
    width: '440px',
    height: '60px',
    borderRadius: '8px',
    marginTop: '25px',

    '& .focusleft, .focusright, .unfocusleft, .unfocusright': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '147px',
      height: '60px',
      fontSize: '13px',
      fontWeight: '500',
      textAlign: 'center',
      cursor: 'pointer',
    },

    '& .focusleft, .focusright': {
      backgroundColor: colors.Whisper,
      color: colors.WALLET,
    },

    '& .unfocusleft, .unfocusright': {
      backgroundColor: colors.white,
      color: colors.lightGreyBlue,
    },

    '& .focusleft, .unfocusleft': {
      borderRadius: '8px 0px 0px 8px',
    },

    '& .focusright, .unfocusright': {
      borderRadius: '0px 8px 8px 0px',
    },
  },

  send: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flex: '100%',
    paddingTop: '25px',

    '& .connection, .deconnexion': {
      width: '215px',
      height: '40px',
      borderRadius: '8px',
      display: 'flex',
      justifyContent: 'center',
      fontSize: '12px',
      fontWeight: '500',
      lineHeight: '40px',
      borderWidth: '0px',
    },

    '& .connection': {
      color: colors.white,
      backgroundColor: colors.WALLET,
    },

    '& .deconnexion': {
      backgroundColor: colors.white,
      color: colors.slateGrey,
      marginLeft: '10px',
    },
  },

  textFieldForm: {
    width: '440px',
    height: '40px',
    borderRadius: '8px',
    padding: 0,
    borderWidth: 0,
  },

  logo: {
    width: '212px',
  },

  wrapperSignIn: {
    height: '100%',
    padding: 0,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;

import { useEffect, useState } from 'react';

const useModeTablet = () => {
  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
  });

  return width <= 1024;
};

export default useModeTablet;

import useSWRImmutable from 'swr/immutable';
import HttpService from '../../services/http-service';

export const getVisibilityDataByPath = (
  path: string,
  network?: 'AD-AUTO' | 'IDG'
) =>
  HttpService.getClient()
    .get(`${process.env.REACT_APP_API_STATISTICS_URL}/${path}`, {
      params: {
        'api-key': process.env.REACT_APP_API_STATISTICS_KEY,
        limit: 1,
      },
      headers: {
        'Content-Type': 'application/json',
        ...(network && { 'x-network-id': network }),
      },
      data: {},
    })
    .then((res) => res.data);

export const useVisibilitySalesForYear = (
  year: string,
  garageId?: string,
  network?: 'AD-AUTO' | 'IDG'
) => {
  const { data: views } = useSWRImmutable(
    [`views/${garageId}/${year}`, network],
    (path) => getVisibilityDataByPath(path, network)
  );
  const { data: sales } = useSWRImmutable(
    [`sales/${garageId}/${year}`, network],
    (path) => getVisibilityDataByPath(path, network)
  );
  return { views, sales };
};

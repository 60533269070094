const colors = {
  primary: '#EF7050',
  transparent: 'transparent',
  mediumGreen: '#309d38',
  CONFIRMED: '#309d38',
  forestGreen: '#83B02B',
  lightGreyBlue: '#aaaec5',
  perso: '#aaaec5',
  coral: '#fa423e',
  barGrey: '#44464a',
  paleGrey: '#f6f7fb',
  slateGrey: '#575667',
  lightGrey: '#eee',
  dark: '#4D4D4D',
  paleDark: '#808080',
  WALLET: '#2c2e3d',
  CANCELLED: '#2c2e3d',
  brightOrange: '#fa6602',
  REPORTED: '#F8462D',
  reportedBcg: '#FFEDD5',
  veryPaleGrey: '#f6f7fb',
  veryLightBlue: '#e4e7f0',
  white: '#ffffff',
  red: '#ff0000',
  black: '#000000',
  dustyOrange: '#ff7320',
  veryLightPink: '#d0d0d0',
  tealish: '#23c6c8',
  watermelon: '#ed5565',
  VALIDATED: '#0894fc',
  azure: '#0894fc',
  yellow: '#e8e818',
  whiteLight: '#eceef6',
  WhiteDark: '#eaebf1',
  paleGreyDrank: '#eef0f7',
  paleFormGrey: 'rgba(78, 82, 106, 0.25)',
  inputBorder: '#c0c3d4',
  blueGrey: '#4E526A',
  Whisper: ' #E8E7E7',
  darkGray: '#ADABAB',
  fordGray: '#979797',
  pomeGranate: '#F8462D',
  cobalt: '#0053A1',
  paleBlue: '#dee2e6',
  tooltipBorderColor: '#cccccc',
  cloudWhite: '#F1F1F1',
  infoColor: '#64748b',
  vehicleInfoGrey: '#e2e8f0',
  boxAlert: '#fef3c7',
  errorRed: '#ff6363',
  charcoalGrey: '#6F6F6F',
  errorColor: '#A16207',
  reportedBcgColor: '#FFEDD5',
  reportedColor: '#C2410C',
  trBcgColor: '#FAFAFA',
  trIconWarning: '#CA8A04',
  tdTinyHeadStartBorderColor: '#9CA3AF',
  tdBorderColor: '#E4E4E7',
  alertBcgColor: '#E8F4FD',
};

export default colors;

import makeStyles from '@material-ui/core/styles/makeStyles';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '../../assets/generic-components/Button';
import colors from '../../assets/style-colors';

import car from '../../assets/style-elements/car.svg';

import euro from '../../assets/style-elements/01-electrons-icon-euro.png';

import planning from '../../assets/style-elements/icon-planning.svg';
import clock from '../../assets/style-elements/time.svg';

import { setBooking } from '../../redux/stores/planning/actions';
import { Store } from '../../redux/types';
import { getDuration, getLogo } from '../../utils/booking';

const SearchResults = () => {
  // CONSTANTS:

  const {
    languageReducer,
    planningStore: { bookingsResults },
    globalReducer: { availableCarservices },
  } = useSelector((state: Store) => state);
  const lg = languageReducer.language;
  const history = useHistory();
  const dispatch = useDispatch();

  // STYLES:
  const useStyles = makeStyles(() => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      width: '100%',
      overflowX: 'hidden',
      overflow: 'scroll',
      padding: '10px',
      '&::-webkit-scrollbar': {
        width: '4px',
        height: '4px',
        borderRadius: '2px',
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        width: '4px',
        height: '4px',
        margin: '2px',
        background: colors.lightGreyBlue,
      },
      '& h1': {
        fontSize: '16px',
      },
    },
    tickets: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
    },
    row: {
      display: 'flex',
      justifyContent: 'start',
    },
    ticket: {
      background: colors.white,
      padding: '20px',
      margin: '20px 0 0 0 ',
      borderTop: `1px solid ${colors.paleGrey}`,
      height: 'fit-content',
    },
    weekday: {
      fontSize: '12px',
      fontWeight: 'bold',
      paddingTop: '10px',
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      marginLeft: '15px',
    },
    day: {
      padding: '10px',
      fontSize: '25px',
    },
    hours: {
      padding: '5px',
      marginLeft: '15px',
      fontSize: '9px',
      color: colors.slateGrey,
      borderRadius: '4px',
      height: 'fit-content',
    },
    name: {
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontWeight: 'bold',
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    image: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '93px',
      height: '93px',
      borderRadius: '4px',
      border: `1px solid ${colors.paleGrey}`,
    },
    devis: {
      color: colors.white,
      padding: '5px',
      margin: '5px 0 5px 0',
      textAlign: 'center',
      borderRadius: '4px',
      maxWidth: 'fit-content',
      height: 'fit-content',
      fontSize: '16px',
      lineHeight: 1,
    },
    item: {
      color: colors.black,
      display: 'flex',
      alignItems: 'center',
    },
    body: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      height: 'fit-content',
      fontSize: '16px',
    },
    CONFIRMED: {
      backgroundColor: colors.mediumGreen,
    },
    VALIDATED: {
      backgroundColor: colors.azure,
    },
    REPORTED: {
      backgroundColor: colors.pomeGranate,
    },
    CANCELLED: {
      backgroundColor: colors.dark,
    },
    WALLET: {
      backgroundColor: colors.dark,
    },
    perso: {
      backgroundColor: colors.lightGreyBlue,
    },
    wrapItem: {
      width: '100%',
    },
    boxLogo: {
      width: '65px',
      height: '41px',
    },
    wrapInfo: {
      paddingLeft: '20px',
      lineHeight: '1.4',
      fontSize: '16px',
    },
    infoDate: {
      fontSize: '14px',
      fontWeight: 500,
    },
    wrapLeft: {
      display: 'flex',
    },
    wrapListItem: {
      width: '50%',
    },
    iconItem: {
      paddingLeft: '10px',
    },
    iconPlanning: {
      width: '14px',
    },
    itemPagination: {
      padding: '10px',
      cursor: 'pointer',
    },
  }));

  const classes = useStyles();

  function handleRedirect(e: any, path: string) {
    e.preventDefault();
    history.push(path);
  }

  // HTML:

  return (
    <div className={classes.root}>
      <h1>
        {bookingsResults && bookingsResults.length > 0
          ? `${bookingsResults.length} ${lg.search.result}`
          : `${lg.search.emptyResult}`}
      </h1>
      {bookingsResults &&
        bookingsResults.map((booking) => {
          const date = moment(booking.bookingDate);
          return (
            <>
              <div
                key={booking.bookingId}
                id={`#${booking.bookingId}`}
                className={classes.weekday}
              >
                <div
                  className={classes.hours}
                  style={{
                    backgroundColor:
                      booking.quotes.length === 0 ? colors.veryLightBlue : '',
                  }}
                >
                  <img
                    className={classes.iconPlanning}
                    src={planning}
                    alt="o"
                  />
                </div>
                <div>{`${lg.planning.weekdaysFull[date.day()]}, ${
                  // TODO extract date format to centralize format
                  date.format('dddd, D MMMM YYYY')
                }`}</div>
              </div>

              <div className={classes.tickets}>
                {booking.quotes.map((quote) => (
                  <div
                    key={`ticket_${quote.quoteId}`}
                    className={classes.ticket}
                  >
                    <div className={classes.header}>
                      <div className={classes.wrapLeft}>
                        <div className={classes.image}>
                          <img
                            className={classes.boxLogo}
                            src={getLogo(booking.networkId)}
                            alt=""
                          />
                        </div>
                        <div className={classes.wrapInfo}>
                          <div
                            className={classes.name}
                          >{`${lg.devis.command} ${quote.quoteId}`}</div>
                          <div>{`${booking.customer.firstName} ${booking.customer.lastName} - ${booking.vehicle?.registrationNumber}`}</div>
                        </div>
                      </div>
                      <div>
                        <Button
                          onClick={(e) => {
                            dispatch(setBooking(booking));
                            handleRedirect(e, '/command');
                          }}
                          theme="dark"
                          variant="secondary"
                          title={lg.devis.button}
                        />
                      </div>
                    </div>

                    <div
                      key={quote.quoteId}
                      className={`${classes.body} ${classes.ticket} ${classes.wrapItem}`}
                    >
                      <div
                        className={`${classes[quote.status]} ${classes.name} ${
                          classes.devis
                        }`}
                      >
                        {`${lg.search.quote} N° ${quote.quoteId}`}
                      </div>
                      <div
                        className={`${classes.body} ${classes.wrapListItem}`}
                      >
                        <div className={classes.item}>
                          <img src={clock} alt=" " />
                          <div className={classes.iconItem}>{` ${getDuration(
                            quote
                          )}h`}</div>
                        </div>
                        <div className={classes.item}>
                          <img src={car} alt=" " />
                          <div className={classes.iconItem}>
                            {availableCarservices[quote.carServiceId]?.label ||
                              quote.carServiceId}
                          </div>
                        </div>
                        <div className={classes.item}>
                          <img src={euro} alt=" " />
                          <div className={classes.iconItem}>{`${
                            lg.devis.sum
                          } ${quote.price.minPriceExclTax?.toFixed(
                            2
                          )}  €`}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          );
        })}
    </div>
  );
};

export default SearchResults;

import makeStyles from '@material-ui/core/styles/makeStyles';
import moment from 'moment';
import { KeyboardArrowRight } from '@material-ui/icons';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import React from 'react';
import { useSelector } from 'react-redux';
import colors from '../../../../../assets/style-colors';
import { Store } from '../../../../../redux/types';
import Bookings from './bookings';
import DayHours from './dayHours';

interface DayViewProps {
  lateralMode?: boolean;
  setCurrentDay?: (date: string) => void;
  currentDay: string;
  hiddenScrollBar?: boolean;
}
const DayView: React.FC<DayViewProps> = ({
  lateralMode,
  setCurrentDay,
  currentDay,
  hiddenScrollBar,
}) => {
  const {
    planningStore: { bookings },
    globalReducer: { availableCarservices },
  } = useSelector((state: Store) => state);

  const dayIndex = moment(currentDay).day();

  const bookingsOfTheDay =
    bookings && bookings[dayIndex - 1] ? bookings[dayIndex - 1] : [];

  // STYLES:
  const useStyles = makeStyles(() => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      width: '100%',
      height: lateralMode ? 'auto' : '100vh',
      paddingBottom: lateralMode ? 50 : 300,
      overflowX: 'hidden',
      overflow: lateralMode ? 'fit-content' : 'scroll',
      padding: '10px',
      '&::-webkit-scrollbar': {
        width: '4px',
        height: '4px',
        borderRadius: '2px',
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        width: '4px',
        height: '4px',
        margin: '2px',
        background: hiddenScrollBar ? 'transparent' : colors.lightGreyBlue,
      },
    },
    bookings: {
      display: 'flex',
      gap: '10px',
      flexDirection: 'column',
      justifyContent: 'start',
    },
    booking: {
      justifyContent: 'center',
      color: colors.slateGrey,
      height: 'auto',
      fontSize: '11px',
      padding: '10px',
      margin: '10px 10px 0 10px ',
      background: colors.white,
      borderRadius: '2px',
      boxShadow: '0 2px 18px 0 rgba(136, 135, 135, 0.17)',
    },
    weekday: {
      fontSize: '12px',
      fontWeight: 'bold',
      paddingTop: '10px',
      display: 'flex',
      justifyContent: lateralMode ? 'space-between' : 'flex-start',
      alignItems: 'center',
      marginLeft: lateralMode ? '0' : '15px',
      textTransform: 'capitalize',
    },
    currentDay: {
      display: 'flex',
      width: '100%',
    },
    dayTitle: {
      fontWeight: 'normal',
      fontSize: '12px',
      marginBottom: 0,
      display: 'flex',
      alignItems: 'center',
    },
    dayNavigation: {
      cursor: 'pointer',
      padding: '5px',
    },
    day: {
      padding: '10px',
      fontSize: '25px',
    },
    hours: {
      padding: '5px',
      marginLeft: '15px',
      fontSize: '9px',
      color: colors.slateGrey,
      borderRadius: '4px',
      height: 'fit-content',
    },
    name: {
      width: lateralMode ? 200 : '100%',
      display: 'block',
      whiteSpace: 'nowrap' /* forces text to single line */,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: colors.dark,
    },
    spaceT: {
      marginTop: '15px !important',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      position: 'relative',
    },
    col: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    image: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '40px',
      height: '40px',
      borderRadius: '4px',
      border: `1px solid ${colors.whiteLight}`,
    },
    devis: {
      color: colors.white,
      padding: '0 8px',
      margin: '5px 0 5px 0',
      textAlign: 'center',
      borderRadius: '4px',
      maxWidth: 'fit-content',
      cursor: 'pointer',
      height: '22px',
      lineHeight: '22px',
    },
    CONFIRMED: {
      backgroundColor: colors.mediumGreen,
    },
    VALIDATED: {
      backgroundColor: colors.azure,
    },
    REPORTED: {
      backgroundColor: colors.pomeGranate,
    },
    CANCELLED: {
      backgroundColor: colors.dark,
    },
    WALLET: {
      backgroundColor: colors.dark,
    },
    perso: {
      backgroundColor: colors.lightGreyBlue,
    },
    iconClock: {
      width: '11px',
      height: '11px',
      filter: 'grayscale(100%)',
    },
    timeItem: {
      color: colors.lightGreyBlue,
    },
    nameItem: {},
    imgItem: {
      width: '30px',
      height: '16px',
    },
    colStyle: {
      justifyContent: 'flex-end',
      color: colors.dark,
      alignItems: 'center',
      flexDirection: 'row',
    },
    totalRate: {
      color: colors.lightGreyBlue,
    },
    nameQuote: {
      color: colors.slateGrey,
    },
    iconClockSmall: {
      width: '10px',
    },
  }));
  const classes = useStyles();

  const updateDayIndex = (dayModifier: number): void => {
    setCurrentDay?.(moment(currentDay).add(dayModifier, 'day').toISOString());
  };

  return (
    <div className={`${classes.root} stylePrint`}>
      <div className={classes.weekday}>
        {lateralMode && (
          <span
            className={classes.dayNavigation}
            onClick={() => updateDayIndex(-1)}
            role="navigation"
          >
            <KeyboardArrowLeftIcon className="backward-grey" />
          </span>
        )}
        <div className={classes.currentDay}>
          <h2 className={`${classes.dayTitle} noprint`}>
            {moment(currentDay).format('dddd DD')}
            <DayHours currentDay={currentDay} />
          </h2>
        </div>
        {lateralMode && (
          <span
            className={classes.dayNavigation}
            onClick={() => updateDayIndex(1)}
            role="navigation"
          >
            <KeyboardArrowRight className="backward-grey" />
          </span>
        )}
      </div>

      <div className={classes.bookings}>
        <Bookings
          bookings={bookingsOfTheDay}
          availableCarservices={availableCarservices}
          opacity={1}
        />
      </div>
    </div>
  );
};

DayView.defaultProps = {
  lateralMode: false,
  hiddenScrollBar: false,
  setCurrentDay: () => null,
};

export default DayView;

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Store } from '../../../../redux/types';
import useStyles from './style';
import { QUOTE, QUOTE_CONFIRM } from '../../../../router/routes';
import Button from '../../../../assets/generic-components/Button';

const QuoteButtonFooter = ({ styleMode }: { styleMode: string }) => {
  const lg = useSelector((state: Store) => state.languageReducer.language);

  const classes = useStyles();

  const history = useHistory();

  return (
    <div className={`${styleMode} no-print`}>
      <div className={classes.boxBtn}>
        <Button
          onClick={() => history.push(QUOTE)}
          theme="light"
          title={lg.quote.previous}
        />
        <Button
          onClick={() => history.push(QUOTE_CONFIRM)}
          width="274px"
          theme="dark"
          title={lg.quote.rdv}
        />
      </div>
      <div />
    </div>
  );
};

export default QuoteButtonFooter;

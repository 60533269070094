import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import RenderOnRoles from '../../assets/generic-components/RenderOnRoles';
import allActions from '../../redux/actions';
import { Store } from '../../redux/types';
import AuthenticationService, {
  Roles,
} from '../../services/authentication-service';
import {
  menuItems,
  disabledAccountMenuItems,
  garageFranchiseMenuItems,
} from './items';
import frFlag from '../../assets/images/fr.png';
import nlFlag from '../../assets/images/nl.png';
import openmenu from '../../assets/style-elements/menu-open.svg';
import useStyles from './style';
import { SmallMenu, LargeMenu } from './menu';
import { GARAGESTATE } from '../../utils/constants';
import { CountryCodesUppercase } from '../../redux/stores/global/types';
import {
  handleHolidaysEvent,
  handleTimeEvent,
} from '../../lib/events/menuleft';

interface MenuLeftProps {
  logo1?: string;
  logo2?: string;
  logo2mini?: string;
  garageName: string;
}

const MenuLeft = ({ logo1, logo2, garageName, logo2mini }: MenuLeftProps) => {
  // CONSTANTS:
  const {
    languageReducer: { language: lg },
    globalReducer: { currentGarage },
  } = useSelector((state: Store) => state);
  const dispatch = useDispatch();
  const setModificationMode = (data: any) =>
    allActions.planningActions.setModificationMode(dispatch, data);
  const setActive = (data: any) =>
    allActions.calendarActions.setActive(dispatch, data);
  const setWeekNumber = (data: any) =>
    allActions.calendarActions.setWeekNumber(dispatch, data);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [active, setActiveItem] = useState([true]);
  const isLarge = width >= 1440;
  const isTall = height >= 700;
  const [initialMenu, setInitialMenu] = useState(isLarge);
  const [hideMenu, setHideMenu] = useState(!initialMenu);

  // STATES:
  const history = useHistory();
  const classes = useStyles();

  // FUNCTIONS:
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    setInitialMenu(isLarge);
  }, [isLarge]);

  function handleOpen() {
    setHideMenu(false);
  }
  function handleClose() {
    setHideMenu(true);
  }

  function handleRedirect(
    e: React.MouseEvent<HTMLElement>,
    path: string,
    index: number
  ) {
    e.preventDefault();
    history.push(path);
    setModificationMode(false);
    setActive(true);
    setWeekNumber(moment().week());
    const update = [];
    update[index] = true;
    setActiveItem(update);
  }
  const GARAGEFRANCHISE = AuthenticationService.hasRole([
    Roles.GARAGE_FRANCHISE,
  ]);

  const isAdmin = AuthenticationService.hasRole([
    Roles.ADMIN,
    Roles.CALL_CENTER,
  ]);

  const handleMenuClick = (
    e: React.MouseEvent<HTMLElement>,
    item: { href: string; name: string },
    index: number
  ) => {
    handleRedirect(e, item.href, index);

    if (currentGarage) {
      if (item.name === 'time') {
        handleTimeEvent({
          garageId: currentGarage.id,
          garageName: currentGarage.companyName,
        });
      }

      if (item.name === 'holidays') {
        handleHolidaysEvent({
          garageId: currentGarage.id,
          garageName: currentGarage.companyName,
        });
      }
    }
  };

  // HTML:
  return hideMenu && !isLarge ? (
    <div className="tw-flex tw-flex-col tw-items-center tw-justify-around	tw-fixed tw-z-50 bg-white tw-border-r tw-border-solid tw-border-blue-veryLight tw-w-14 tw-h-screen noprint">
      {currentGarage?.garageNetwork.some((n) => n.networkId === 'AD-AUTO') && (
        <img alt="logo1" className="tw-w-8 tw-h-auto" src={logo1} />
      )}
      <img alt="logo2" className="tw-w-8 tw-h-auto" src={logo2mini} />
      <div
        className="tw-cursor-pointer tw-w-10	tw-h-10"
        role="button"
        tabIndex={0}
        onClick={() => handleOpen()}
      >
        <img src={openmenu} alt="" />
      </div>

      {currentGarage?.garageNetwork.some((n) => n.networkId === 'AD-AUTO') && (
        <img alt="logo1" className="tw-w-8 tw-h-auto" src={logo1} />
      )}

      <div className="tw-w-auto tw-h-12" />
      {GARAGESTATE() && !isAdmin
        ? disabledAccountMenuItems.map((menuItem: any, index: any) => (
            <SmallMenu
              key={index}
              index={index}
              role={menuItem.roles}
              classNameActive={
                active[index] === true
                  ? classes.activeIcon
                  : classes.leftMenuIcon
              }
              redirection={(e: React.MouseEvent<HTMLElement>) =>
                handleRedirect(e, menuItem.href, index)
              }
              link={
                menuItem.href === '/basket' ? 'http://www3.autossimo.com/' : '#'
              }
              className={menuItem.class}
              icon={menuItem.icon}
            />
          ))
        : GARAGEFRANCHISE
        ? garageFranchiseMenuItems.map((menuItem, index) => (
            <SmallMenu
              key={index}
              index={index}
              role={menuItem.roles}
              classNameActive={
                active[index] === true
                  ? classes.activeIcon
                  : classes.leftMenuIcon
              }
              redirection={(e: React.MouseEvent<HTMLElement>) =>
                handleMenuClick(e, menuItem, index)
              }
              link={
                menuItem.href === '/basket' ? 'http://www3.autossimo.com/' : '#'
              }
              className={menuItem.class}
              icon={menuItem.icon}
            />
          ))
        : menuItems.map((menuItem, index) => (
            <SmallMenu
              key={index}
              index={index}
              role={menuItem.roles}
              classNameActive={
                active[index] === true
                  ? classes.activeIcon
                  : classes.leftMenuIcon
              }
              redirection={(e: React.MouseEvent<HTMLElement>) =>
                handleMenuClick(e, menuItem, index)
              }
              link={
                menuItem.href === '/basket' ? 'http://www3.autossimo.com/' : '#'
              }
              className={menuItem.class}
              icon={menuItem.icon}
            />
          ))}

      {isTall && GARAGESTATE() && !isAdmin ? (
        <div className="tw-w-auto tw-h-96" />
      ) : (
        <div className="tw-w-auto tw-h-48" />
      )}
    </div>
  ) : (
    <div className="tw-flex tw-flex-col tw-justify-around tw-fixed tw-z-50 tw-p-5 bg-white tw-border-r tw-border-solid tw-border-white-dark tw-w-[196px] tw-h-screen noprint">
      {!isLarge && (
        <div className="tw-cursor-pointer tw-w-10	tw-h-10">
          <CloseIcon onClick={() => handleClose()} />
        </div>
      )}
      <div className="tw-flex tw-justify-between">
        {currentGarage?.garageNetwork?.some(
          (n) => n.networkId === 'AD-AUTO'
        ) && (
          <span>
            <img alt="logo1" className="tw-w-full tw-h-auto" src={logo1} />
          </span>
        )}
        {currentGarage?.garageNetwork?.some((n) => n.networkId === 'IDG') && (
          <span>
            <img alt="logo2" className="tw-w-full tw-h-auto" src={logo2} />
          </span>
        )}
      </div>
      <div className="tw-flex tw-flex-col tw-text-base tw-justify-center tw-items-center tw-w-40 tw-h-36 tw-rounded-lg tw-bg-grey-veryPale tw-my-5 tw-mx-0">
        <RenderOnRoles roles={[Roles.CALL_CENTER, Roles.ADMIN]}>
          <div className="tw-text-orange-primary">Administration</div>
        </RenderOnRoles>

        <h2 className="tw-text-grey-dark tw-text-base tw-mx-0">
          {garageName}
        </h2>
        <RenderOnRoles roles={[Roles.CALL_CENTER, Roles.ADMIN]}>
          <div className="tw-text-grey-dark tw-mb-2.5">
            ({currentGarage?.id})
          </div>
        </RenderOnRoles>
        <img
          alt="Flag country"
          src={
            currentGarage?.garageAddress.country === CountryCodesUppercase.FR
              ? frFlag
              : nlFlag
          }
          className="tw-w-4"
        />
      </div>

      {GARAGESTATE() && !isAdmin
        ? disabledAccountMenuItems.map((menuItem: any, index: any) => (
            <LargeMenu
              key={index}
              index={index}
              role={menuItem.roles}
              classNameActive={
                active[index] === true
                  ? classes.activeItem
                  : classes.leftMenuItem
              }
              redirection={(e: React.MouseEvent<HTMLElement>) =>
                handleRedirect(e, menuItem.href, index)
              }
              link={
                menuItem.href === '/basket' ? 'http://www3.autossimo.com/' : '#'
              }
              className={menuItem.class}
              icon={menuItem.icon}
              classNameIcon={
                active[index] === true
                  ? classes.activeIcon
                  : classes.leftMenuIcon
              }
              classNameText={
                active[index] === true
                  ? classes.activeText
                  : classes.leftMenuText
              }
              name={lg.menuLeft[menuItem.name]}
            />
          ))
        : GARAGEFRANCHISE
        ? garageFranchiseMenuItems.map((menuItem, index) => (
            <LargeMenu
              key={index}
              index={index}
              role={menuItem.roles}
              classNameActive={
                active[index] === true
                  ? classes.activeItem
                  : classes.leftMenuItem
              }
              redirection={(e: React.MouseEvent<HTMLElement>) =>
                handleMenuClick(e, menuItem, index)
              }
              link={
                menuItem.href === '/basket' ? 'http://www3.autossimo.com/' : '#'
              }
              className={menuItem.class}
              icon={menuItem.icon}
              classNameIcon={
                active[index] === true
                  ? classes.activeIcon
                  : classes.leftMenuIcon
              }
              classNameText={
                active[index] === true
                  ? classes.activeText
                  : classes.leftMenuText
              }
              name={lg.menuLeft[menuItem.name]}
            />
          ))
        : menuItems.map((menuItem, index) => (
            <LargeMenu
              key={index}
              index={index}
              role={menuItem.roles}
              classNameActive={
                active[index] === true
                  ? classes.activeItem
                  : classes.leftMenuItem
              }
              redirection={(e: React.MouseEvent<HTMLElement>) =>
                handleMenuClick(e, menuItem, index)
              }
              link={
                menuItem.href === '/basket' ? 'http://www3.autossimo.com/' : '#'
              }
              className={menuItem.class}
              icon={menuItem.icon}
              classNameIcon={
                active[index] === true
                  ? classes.activeIcon
                  : classes.leftMenuIcon
              }
              classNameText={
                active[index] === true
                  ? classes.activeText
                  : classes.leftMenuText
              }
              name={lg.menuLeft[menuItem.name]}
            />
          ))}
      {isTall && GARAGESTATE() && !isAdmin ? (
        <div className="tw-w-auto tw-h-96" />
      ) : (
        <div className="tw-w-auto tw-h-48" />
      )}
    </div>
  );
};

MenuLeft.defaultProps = {
  logo1: null,
  logo2: null,
  logo2mini: null,
};

// EXPORT:
export default MenuLeft;

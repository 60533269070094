import { ApolloClient, HttpLink, InMemoryCache, gql } from '@apollo/client';
import _get from 'lodash/get';
import { DocumentNode } from 'graphql';
import { MyIdgQuestions } from '../redux/stores/help/types';

const createApolloClient = (): ApolloClient<any> => {
  const cache = new InMemoryCache();
  return new ApolloClient({
    ssrMode: typeof window !== 'undefined',
    link: new HttpLink({
      uri: `${process.env.REACT_APP_STRAPI_URL}/graphql`
    }),
    cache
  });
};

const fetchAPI = async (
  query: DocumentNode,
  variables: {},
  retry = 1
): Promise<any | null> => {
  const apolloClient = createApolloClient();
  try {
    const result = await apolloClient.query({
      query,
      variables
    });

    return result.data;
  } catch (e) {
    if (retry <= 5) {
      return fetchAPI(query, variables, retry + 1);
    }

    return null;
  }
};

export async function getHelpCategoriesData(): Promise<[MyIdgQuestions]> {
  const data = await fetchAPI(
    gql`
      {
        myidgQuestions {
          categoryLabel
          path
          faq {
            question {
              id
              question
              answer
              featured
            }
          }
        }
      }
    `,
    {}
  );
  return _get(data, 'myidgQuestions', []);
}

export default fetchAPI;

import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useDispatch } from 'react-redux';
import {
  TYRES_FIELDS,
  TyresFieldConfig,
  TyresTypes,
  useTyresOptions,
} from '../../../services/tyres';
import SelectionDropdown from './selection-dropdown';
import Season from './season';
import AdditionalTyresOptions from './additional-tyres-options';
import { Language } from '../../../redux/stores/languages';
import { setTyresOptions } from '../../../redux/stores/quotes/actions';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
}));

interface TyresSelectionProps {
  lang: Language;
}

const TyresSelection: React.FC<TyresSelectionProps> = ({ lang }) => {
  const dispatch = useDispatch();
  const [tyresValues, setTyresValues] = useState<
    Record<TyresTypes | string, string | null>
  >({});
  const [seasons, setSeasons] = useState<Array<string>>([]);
  const [tyresAdditionalOptions, setTyresAdditionalOptions] = useState<
    Record<string, boolean>
  >({
    runflat: false,
    consolidated: false,
  });
  const tyresOptions = useTyresOptions(tyresValues);
  const classes = useStyles();

  const isDropdownDisabled = (field: TyresFieldConfig): boolean => {
    if (field.dependency) {
      return !tyresValues[field.dependency];
    }
    return false;
  };

  const onFieldSelection = (
    value: string | null,
    field?: TyresFieldConfig
  ): void => {
    const dependencedField = TYRES_FIELDS.find(
      (tyresField) => tyresField.dependency === field?.id
    );
    const newTyresOptions = {
      ...tyresValues,
      [field?.id || '']: value,
      [dependencedField?.id as string]: null,
    };
    setTyresValues(newTyresOptions);
    dispatch(
      setTyresOptions({
        ...newTyresOptions,
        seasons,
        ...tyresAdditionalOptions,
      })
    );
  };

  useEffect(() => {
    onFieldSelection('other');
  }, [seasons, tyresAdditionalOptions]);

  return (
    <div className={classes.root}>
      {TYRES_FIELDS.map((field, index) => (
        <SelectionDropdown
          key={index}
          field={field}
          options={tyresOptions[`${field.id}s`] || []}
          disabled={isDropdownDisabled(field)}
          onSelect={(value) => onFieldSelection(value, field)}
          value={tyresValues[field.id]}
        />
      ))}
      <Season seasons={seasons} setSeasons={setSeasons} lang={lang} />
      <AdditionalTyresOptions
        tyresOptions={tyresAdditionalOptions}
        setTyresOptions={setTyresAdditionalOptions}
        lang={lang}
      />
    </div>
  );
};

export default TyresSelection;

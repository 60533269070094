import React from 'react';
import { useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import { Store } from '../../../../redux/types';
import InputLabel from '../../../../assets/generic-components/InputLabel';
import PhoneField from '../../../../assets/generic-components/phoneInput';
import useStyles from './style';

type QuoteFieldsProps = {
  tablet: boolean;
  firstnameReference: React.LegacyRef<HTMLInputElement>;
  lastnameReference: React.LegacyRef<HTMLInputElement>;
  emailReference: React.LegacyRef<HTMLInputElement>;
  phoneReference: React.LegacyRef<HTMLInputElement>;
  firstnameError?: JSX.Element;
  lastnameError: JSX.Element;
  emailError?: JSX.Element;
  phoneError?: JSX.Element;
  control: any;
};

const QuoteFields = ({
  tablet,
  firstnameReference,
  lastnameReference,
  emailReference,
  phoneReference,
  firstnameError,
  lastnameError,
  emailError,
  phoneError,
  control
}: QuoteFieldsProps) => {
  const lg = useSelector((state: Store) => state.languageReducer.language);
  const classes = useStyles();

  return (
    <div>
      <div className="d-flex">
        <InputLabel
          label={tablet ? lg.quote.firstname.slice(0, 3) : lg.quote.firstname}
          name="firstName"
          reference={firstnameReference}
        >
          {firstnameError}
        </InputLabel>

        <InputLabel
          label={tablet ? lg.quote.lastName.slice(0, 6) : lg.quote.lastName}
          name="lastName"
          reference={lastnameReference}
        >
          {lastnameError}
        </InputLabel>
      </div>

      <div className="d-flex">
        <InputLabel
          name="email"
          label={tablet ? lg.quote.email.slice(0, 4) : lg.quote.email}
          reference={emailReference}
        >
          {emailError}
        </InputLabel>
        <div className={classes.phoneWrapper}>
          <span>{lg.quote.phone}</span>
          <Controller
            as={
              <PhoneField
                reset={undefined}
                errorMessage={lg.quote.messagePhone}
              />
            }
            control={control}
            name="phone"
            defaultValue=""
            reference={phoneReference}
          />
          {phoneError}
        </div>
      </div>
    </div>
  );
};

QuoteFields.defaultProps = {
  firstnameError: '',
  emailError: '',
  phoneError: ''
};

export default QuoteFields;

import React from 'react';
import { useSelector } from 'react-redux';
import { Store } from '../../../redux/types';
import { getCurrentBookingQuoteReportedMessage } from '../../../redux/stores/planning/utils';
import IconWarning from '../../../assets/style-elements/icon-warning';

interface ReportedQuoteMessageProps {
  quoteId: string;
}

const ReportedQuoteMessage: React.FC<ReportedQuoteMessageProps> = React.memo(
  ({ quoteId }) => {
    const { planningStore, languageReducer } = useSelector(
      (state: Store) => state
    );
    const reportedHistory = getCurrentBookingQuoteReportedMessage(
      planningStore,
      quoteId
    );

    if (!reportedHistory) return null;

    return (
      <div className="alert reported-issue">
        <IconWarning className="reported-issue--icon" />
        <span id="textAlert">
          {languageReducer.language.command.lateral.REPORTED} :{' '}
          {`"${reportedHistory?.message}"`}
        </span>
      </div>
    );
  }
);

export default ReportedQuoteMessage;

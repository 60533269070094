import initState from '../initialState';

const helpReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET_FAQ':
      return {
        ...state,
        faq: action.payload
      };
    case 'SET_COUPON':
      return {
        ...state,
        coupon: action.payload
      };
    case 'SET_COMMISSION':
      return {
        ...state,
        commission: action.payload
      };
    case 'SET_PIECE':
      return {
        ...state,
        piece: action.payload
      };
    default:
      return state;
  }
};

export default helpReducer;

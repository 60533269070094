import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  content: {
    width: '530px',
    margin: '0px auto',
  },
  modalHeader: {
    borderBottom: 'none',
    padding: '30px 30px 17px 30px',
  },
  titleModal: {
    fontWeight: 'bold',
  },
  subTitle: {
    fontSize: '12px',
    marginBottom: '15px',
  },
  colWidth: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '25px 20px',
    alignItems: 'baseline',
    '& label': {
      marginBottom: '0 !important',
    },
    '& input': {
      margin: '10px 0',
      border: 'none',
      padding: '11px 15px',
      backgroundColor: '#F6F7FB !important',
      fontWeight: 'bold',
      fontSize: '13px',
      color: ' #4E526A',
    },
  },
  boxComment: {
    width: '100%',
  },
  phoneWrapper: {
    width: '47%',
  },
  labelPhone: {
    paddingBottom: '9px',
    display: 'block',
    color: '#4d4d4d',
    height: '14px',
    fontSize: '12px',
    lineHeight: '1.17',
    marginBottom: '10px',
  },
  commentBox: {
    width: '100%',
    backgroundColor: '#F6F7FB !important',
    borderRadius: '8px',
    border: 'none',
    padding: '11px 15px',
    height: '200px',
  },
  labelStyle: {
    fontSize: '12px',
    display: 'block',
    marginBottom: '10px',
    width: '100%',
  },
}));

export default useStyles;

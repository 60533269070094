import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import annuaire from '../../assets/style-elements/visibility/annuaire.png';
import ca from '../../assets/style-elements/visibility/ca.png';
import cart from '../../assets/style-elements/visibility/cart.png';
import contact from '../../assets/style-elements/visibility/contact.png';
import devis from '../../assets/style-elements/visibility/devis.png';
import garage from '../../assets/style-elements/visibility/garage.png';
import rdv from '../../assets/style-elements/visibility/rdv.png';
import { Visible } from '../../redux/stores/languages';
import { Store } from '../../redux/types';
import { useVisibilitySalesForYear } from '../../utils/hooks/useVisibilitySales';
import PeriodHeader from '../layout/periodHeader';
import Charts from './components/charts';
import NetworkTabs, { NetworkTabsEnum } from './components/networkTabs';

const useStyles = makeStyles({
  root: {
    margin: '0 20px',
  },
});

export interface ChartKey {
  name: keyof Visible;
  icon: string;
  color: string;
}

const visibilityChartsKeys: ChartKey[] = [
  { name: 'contact_requests', color: '#0894FC', icon: contact },
  { name: 'directory_views', color: '#FF7320', icon: annuaire },
  { name: 'quote_views', color: '#FA423E', icon: devis },
  { name: 'detailed_information_views', color: '#309D38', icon: garage },
];
const salesChartsKeys: ChartKey[] = [
  { name: 'average_cart', color: '#FA423E', icon: cart },
  { name: 'quotes', color: '#0894FC', icon: ca },
  { name: 'revenue', color: '#FF7320', icon: rdv },
];

const VisilbityPage: React.FC = () => {
  const {
    globalReducer: { currentGarage },
    languageReducer: { language: lg },
  } = useSelector((state: Store) => state);

  const garageId = currentGarage?.id;

  const previousMonthYear = moment().add(-1, 'M').format('YYYY');
  const previousMonth = moment().add(-1, 'M').format('MM');
  const [year, setYear] = useState(previousMonthYear);
  const [month, setMonth] = useState(previousMonth);

  const isAdAuto = currentGarage?.garageNetwork.some(
    (n) => n.networkId === 'AD-AUTO'
  );

  const NetworkTab = isAdAuto ? NetworkTabsEnum.ALL : NetworkTabsEnum.IDG;

  const [activeNetworkTab, setActiveNetworkTab] = useState(NetworkTab);

  const history = useHistory();
  const classes = useStyles();
  const { pathname } = useLocation();
  const isSalesPage = pathname.includes('sales');

  const handleChangeYear = (
    event: React.FormEvent<HTMLSelectElement>
  ): void => {
    setYear(event.currentTarget.value);
    event.preventDefault();
  };

  const handleChangeMonth = (
    event: React.FormEvent<HTMLSelectElement>
  ): void => {
    setMonth(event.currentTarget.value);
    event.preventDefault();
  };

  const visibilityTabs = [
    { label: lg.visibilityHeader.visibility, href: '/visibility/visibility' },
    { label: lg.visibilityHeader.sale, href: '/visibility/sales' },
  ];

  useEffect(() => {
    history.push('/visibility/visibility');
  }, [history]);

  const { views, sales } = useVisibilitySalesForYear(
    year,
    garageId,
    activeNetworkTab !== NetworkTabsEnum.ALL ? activeNetworkTab : undefined
  );

  return (
    <>
      <Helmet>
        <title>Mes stats</title>
      </Helmet>
      <div>
        <PeriodHeader
          year={year}
          month={month}
          handleChangeMonth={handleChangeMonth}
          handleChangeYear={handleChangeYear}
          tabs={visibilityTabs}
        />
        <div className={classes.root}>
          <NetworkTabs
            activeNetworkTab={activeNetworkTab}
            setActiveNetworkTab={setActiveNetworkTab}
          />
          <Charts
            chartKeys={isSalesPage ? salesChartsKeys : visibilityChartsKeys}
            chartsByMonth={isSalesPage ? sales : views}
            currentMonthIndex={parseInt(month) - 1}
          />
        </div>
      </div>
    </>
  );
};

export default VisilbityPage;

import { GarageAddres } from '../../redux/stores/global/types';

enum CountryVat {
  BELGIUM_VAT = 21,
  FRENCH_VAT = 20
}

/**
 * Return VAT depending on garage country.
 * @param garage
 */
const getVatForGarage = (garage: GarageAddres): CountryVat => {
  switch (garage.country) {
    case 'BE':
      return CountryVat.BELGIUM_VAT;
    case 'FR':
      return CountryVat.FRENCH_VAT;
    default:
      return CountryVat.FRENCH_VAT;
  }
};

export default getVatForGarage;

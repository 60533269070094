import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Dropdown from 'react-bootstrap/Dropdown';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/nl';
import french from '../../assets/language/FR';
import dutch from '../../assets/language/NL';
import fr from '../../assets/images/fr.png';
import nl from '../../assets/images/nl.png';
import allActions from '../../redux/actions';
import colors from '../../assets/style-colors';
import LanguageButton from '../../assets/generic-components/LanguageButton';

// REDUX:

const mapStateToProps = (state: any) => ({
  ...state
});

const mapDispatchToProps = (dispatch: any) => ({
  setLanguage: (string: any) =>
    allActions.languageActions.setLanguage(dispatch, string),
  setImage: (image: any) => allActions.languageActions.setImage(dispatch, image)
});

//

const Language = (props: { setLanguage: any; setImage: any; }) => {

  const {setLanguage, setImage} = props;
  const [width, setWidth] = useState(window.innerWidth);
  const modeTablet = width <= 1024;

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
  });

  const useStyles = makeStyles(() => ({
    root: {
      fontSize: '13px'
    },
    head: {
      display: 'inline-flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      color: colors.lightGreyBlue,
      '& img:nth-of-type(1)': {
        width: '18px !important',
        margin: '0 4px 0 15px'
      }
    },
    dutch: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start'
    },
    dropdown: {
      zIndex: 100,
      display: 'block',
      position: 'absolute',
      top: '0px',
      width: '100%',
      left: '0px',
      transform: 'translate3d(0px, 45px, 0px)',
      willChange: 'transform',
      background: colors.white,
      padding: '10px'
    },
    item: {
      cursor: 'pointer',
      width: '100%',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '60px',
      '& img:nth-of-type(1)': {
        width: '18px !important',
        marginLeft: '5px'
      }
    },
    french: {
      borderBottomColor: colors.paleGrey,
      borderBottomWidth: '2px',
      borderBottomStyle: 'solid'
    },
    dutchPadding: {},
    button: {
      background: 'transparent',
      outline: 'none',
      border: 'none',
      borderRadius: '8px',
      height: '40px',
      color: colors.dark,
      padding: '0 10px',
      '&:hover': {
        background: colors.veryPaleGrey,
        outline: 'none',
        border: 'none',
        color: 'black'
      },
      '&:active': {
        background: 'transparent',
        outline: 'none!important',
        border: 'none!important',
        color: 'black'
      },
      '&:focus': {
        background: colors.veryPaleGrey,
        outline: 'none!important',
        border: 'none!important',
        color: 'black'
      }
    },
    iconLanguage: {
      width: '30px'
    },
    iconLanguageDropdown: {
      width: '30px',
      height: '20px',
      marginLeft: '20px'
    },
    iconLanguageDropdown2: {
      width: '30px',
      height: '15px',
      marginLeft: '20px'
    }
  }));

  const classes = useStyles();

  return (
    <Dropdown className={classes.root}>
      <LanguageButton
        onFrClick={() => {
          setLanguage(french);
          setImage(fr);
          moment.locale('fr');
        }}
        onNlClick={() => {
          setLanguage(dutch);
          setImage(nl);
          moment.locale('nl');
        }}
        tablet={modeTablet}
      />
    </Dropdown>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Language);

import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import IconButton from '../../../atomic/components/atoms/IconButton/IconButton';
import smsIcon from '../../../assets/style-elements/icon-sms.svg';
import { Store } from '../../../redux/types';
import NotificationBySms from '../modal/notificationBySms';

const WrapButton = styled.div`
  margin: 10px 0px;
  .btn-sms {
    display: flex;
  }
`;
const BtnSendingSms = () => {
  const {
    languageReducer: { language }
  } = useSelector<Store, Store>(state => state);

  const lg = language;
  return (
    <NotificationBySms>
      <WrapButton className="">
        <IconButton
          className="btn-sms"
          icon={smsIcon}
          label={lg.command.lateral.btnSms}
          onClick={() => {}}
        />
      </WrapButton>
    </NotificationBySms>
  );
};

export default BtnSendingSms;

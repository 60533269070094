/* eslint-disable react/no-danger */
import { makeStyles } from '@material-ui/core';
import * as moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/nl';
import React, { useEffect } from 'react';
import { Language } from '../../../../redux/stores/languages';
import FullscreenMessage from '../../organisms/FullscreenMessage';
import LogoutHeader from '../../organisms/LogoutHeader';

interface UnauthorizedTemplateProps {
  lang: Language;
  onLogout: () => void;
}

const UnauthorizedTemplate = ({
  lang,
  onLogout
}: UnauthorizedTemplateProps) => {
  useEffect(() => {
    moment.locale(lang.lg);
  });

  const useStyles = makeStyles(() => ({
    root: {
      display: 'block',
      width: '100vw',
      height: '100vh'
    },
    block: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      flex: '100%',
      width: '100%',
      height: '100vh',
      padding: '0 20%',
      textAlign: 'center'
    }
  }));

  const styles = useStyles();
  return (
    <>
      <div className={styles.root}>
        <LogoutHeader onLogout={onLogout} />
        <FullscreenMessage title="403">
          <h2 dangerouslySetInnerHTML={{ __html: lang.unauthorized }} />
        </FullscreenMessage>
      </div>
    </>
  );
};
export default UnauthorizedTemplate;

/* eslint-disable jsx-a11y/interactive-supports-focus */
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import allActions from '../../../redux/actions';
import useStyles from '../commandStyles';
import { getLogo } from '../../../utils/booking';
import { LanguageStore } from '../../../redux/stores/languages';
import PrintButton from '../../../atomic/components/atoms/printButton';

interface HeaderProps {
  languageReducer: LanguageStore;
}

const mapStateToProps = (state: any) => ({
  ...state,
});

const mapDispatchToProps = (dispatch: any) => ({
  setAllData: (bool: any) =>
    allActions.calendarActions.setAllData(dispatch, bool),
});

const Header = ({ languageReducer }: HeaderProps) => {
  const lg = languageReducer.language;

  const history = useHistory();

  const classes = useStyles();

  const { currentBooking } = useSelector((state: any) => state.planningStore);

  return (
    <>
      <div className={`${classes.headerRows} noprint`}>
        <div className={classes.top}>
          <div
            className="pointer"
            onKeyPress={() => {}}
            role="button"
            tabIndex={0}
            onClick={(event) => {
              event.preventDefault();
              history.push('/planning');
            }}
          >
            <KeyboardArrowLeftIcon className={classes.arrow_left} />
            <span className="back">{lg.command.back}</span>
          </div>
        </div>
        <div className={classes.topSecond}>
          <div className={classes.nameCampany}>
            <div className={classes.logo}>
              <img alt="logo2" src={getLogo(currentBooking.networkId)} />
            </div>

            <span className="numero">{`${lg.command.commandNumber} ${currentBooking.bookingId}`}</span>
          </div>

          <PrintButton />
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

import React from 'react';
import { useSelector } from 'react-redux';
import {
  NetworkId,
  Quote,
  QuoteStatus,
} from '../../../redux/stores/planning/types';
import { Store } from '../../../redux/types';
import AuthenticationService, {
  Roles,
} from '../../../services/authentication-service';
import BookingQuote from './quote';

const With = () => {
  const {
    planningStore: { currentBooking },
  } = useSelector((state: Store) => state);

  const isUserAdmin = AuthenticationService.hasRole([
    Roles.ADMIN,
    Roles.CALL_CENTER,
  ]);
  const isUserGarageOwner = AuthenticationService.hasRole([Roles.GARAGE]);

  const isNetworkIdGarageOwner = currentBooking?.networkId === NetworkId.GARAGE;

  const isQuoteValidated = (quote: Quote) =>
    quote.status === QuoteStatus.VALIDATED;
  const isQuoteReported = (quote: Quote) =>
    quote.status === QuoteStatus.REPORTED;
  const isQuoteConfirmed = (quote: Quote) =>
    quote.status === QuoteStatus.CONFIRMED;

  return (
    <>
      {currentBooking?.quotes.map((quote) => (
        <BookingQuote
          key={quote.quoteId}
          quote={quote}
          currentBooking={currentBooking}
          isUserGarageOwner={isUserGarageOwner}
          isQuoteValidated={isQuoteValidated(quote)}
          isQuoteReported={isQuoteReported(quote)}
          isUserAdmin={isUserAdmin}
          isQuoteConfirmed={isQuoteConfirmed(quote)}
          isNetworkIdGarageOwner={isNetworkIdGarageOwner}
        />
      ))}
    </>
  );
};

export default With;

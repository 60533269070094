import React from 'react';
import moment from 'moment';
import styled from 'styled-components';

import { useSelector } from 'react-redux';
import { Store } from '../../../../redux/types';

import logo from '../../../../assets/style-elements/idGarages.pro_logo.svg';
import colors from '../../../../assets/style-colors';

const HeaderWrapper = styled.div`
  .weekStyle {
    font-size: 16px;
    line-height: 18px;

    color: ${colors.blueGrey};
  }
  .weekStyleDate {
    font-weight: 700;
  }
`;

const getWeekName = (currentDay: string) => {
  const weekStart = moment(currentDay).day(1).format('DD/MM/YYYY');

  const weekEnd = moment(currentDay).day(6).format('DD/MM/YYYY');
  return weekStart === weekEnd ? ` ` : ` ${weekStart} au ${weekEnd}`;
};
const HeaderPrint = () => {
  const lg = useSelector((state: Store) => state.languageReducer.language);

  const {
    planningStore: { currentDay },
  } = useSelector((state: Store) => state);

  return (
    <HeaderWrapper>
      <div className="header-print">
        <style type="text/css" media="print">
          {' @page { size: A4 landscape; } '}
        </style>
        <img src={logo} alt="ID Garage Pro" />
        <div className="weekStyle">
          {lg.menuLeft.planning} {getWeekName(currentDay)}
        </div>
      </div>
    </HeaderWrapper>
  );
};

export default HeaderPrint;

import Keycloak, { KeycloakConfig } from 'keycloak-js';
import analytics from '../lib/events/analytics';

export enum Roles {
  SAD = 'SAD',
  GARAGE = 'GARAGE_OWNER',
  CALL_CENTER = 'CALL_CENTER',
  ADMIN = 'ADMIN',
  GARAGE_FRANCHISE = 'GARAGE_FRANCHISE',
}

export interface UserInfo {
  garageId: string;
  email: string;
  networks_id: string[];
}

const config: KeycloakConfig = {
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? '',
  realm: process.env.REACT_APP_KEYCLOAK_REALM ?? '',
  url: process.env.REACT_APP_KEYCLOAK_URL,
};

const keycloak = Keycloak(config);

const doLogin = keycloak.login;

const doLogout = () => {
  analytics.track('log_out');
  keycloak.logout();
};

const isLoggedIn = () => !!keycloak.token;

const getToken = () => keycloak.token;

const getParsedToken = () => keycloak.tokenParsed;

const getUserInfo = (): UserInfo => getParsedToken() as UserInfo;

const updateToken = (successCallback: () => void) => {
  keycloak.updateToken(5).then(successCallback).catch(doLogin);
};

const hasRole = (roles: Roles[]): boolean =>
  roles.some((role) => keycloak.hasRealmRole(role));

const hasNetwork = (networksId: string[]): boolean =>
  networksId.some((networkId: string) =>
    getUserInfo().networks_id.includes(networkId)
  );

const initKeycloak = (onAuthenticationCallback: () => void) => {
  keycloak
    .init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
    })
    .then((isAuthenticated: boolean) => {
      if (!isAuthenticated) {
        doLogin();
      }

      const user = getParsedToken();
      if (user) {
        analytics.identify(user.sub || 'anonymous', {
          name: user.name,
          email: user.email,
          garageId: user.garageId,
        });
      }
      onAuthenticationCallback();
    });
};

const AuthenticationService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  getParsedToken,
  getUserInfo,
  updateToken,
  hasRole,
  hasNetwork,
  admin: ['ADMIN', 'CALL_CENTER'],
};

export default AuthenticationService;

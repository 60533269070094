import React, { useState } from 'react';
import {
  Control,
  Controller,
  FieldError,
  NestDataObject,
} from 'react-hook-form';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Title from '../../../../assets/generic-components/Title';
import Button from '../../../../assets/generic-components/Button';
import InputPassword from '../../../../assets/generic-components/InputPassword';
import PhoneField from '../../../../assets/generic-components/phoneInput';
import InputMutipleSelect from '../../../../assets/generic-components/InputMutipleSelect';
import InputLabel from '../../../../assets/generic-components/InputLabel';
import { Garage } from '../../../../redux/stores/global/types';
import { Language } from '../../../../redux/stores/languages';

export enum CIVILITY {
  M = 'M.',
  MME = 'Mme',
}

export type AccountDetailsFormValues = {
  email: string;
  phone: string;
  mobile: string;
  lastName: string;
  firstName: string;
  civility: string;
  login: string;
  password: string;
};

interface AccountDetailsFormProps {
  currentGarage: Garage;
  lg: Language;
  onChange: (e: {
    currentTarget: { value: React.SetStateAction<string> };
  }) => void;
  lastNameRef: React.LegacyRef<HTMLInputElement>;
  firstNameRef: React.LegacyRef<HTMLInputElement>;
  emailRef: React.LegacyRef<HTMLInputElement>;
  errors: NestDataObject<AccountDetailsFormValues, FieldError>;
  control: Control<AccountDetailsFormValues>;
}

const AccountDetailsForm = ({
  currentGarage,
  lg,
  onChange,
  lastNameRef,
  firstNameRef,
  emailRef,
  errors,
  control,
}: AccountDetailsFormProps) => {
  const [displayPassword, setDisplayPassword] = useState<boolean>(false);

  const useStyle = makeStyles({
    passwordSection: {
      margin: '15px 0',
    },

    title: {
      margin: '40px 0 10px 0',
    },

    phoneWrapper: {
      paddingTop: '12px',
    },

    labelPhone: {
      display: 'block',
      height: '14px',
      fontSize: '12px',
      lineHeight: 1.17,
      marginBottom: '10px',
    },
  });

  const classes = useStyle();

  const handleDisplayPassword = (e: React.MouseEvent): void => {
    e.preventDefault();
    setDisplayPassword(!displayPassword);
  };

  return (
    <>
      <div className={classes.title}>
        <Title title={lg.account.titleMyData} />
      </div>
      <InputMutipleSelect
        datas={[
          { label: 'Monsieur', value: CIVILITY.M },
          { label: 'Madame', value: CIVILITY.MME },
        ]}
        label={lg.account.civility}
        name="civility"
        selected={currentGarage?.civility}
        onChange={onChange}
      />
      <InputLabel
        label={lg.account.lastname}
        name="lastName"
        type="text"
        placeholder="De La Molette"
        reference={lastNameRef}
        defaultValue={currentGarage?.lastname}
      >
        {errors.lastName &&
          errors.lastName.type === 'required' &&
          lg.quote.messageField}
      </InputLabel>
      <InputLabel
        label={lg.account.firstname}
        name="firstName"
        type="text"
        placeholder="Jean-Eduard"
        reference={firstNameRef}
        defaultValue={currentGarage?.firstname}
      >
        {errors.firstName &&
          errors.firstName.type === 'required' &&
          lg.quote.messageField}
      </InputLabel>
      <InputLabel
        label={lg.rates.email}
        name="email"
        type="text"
        placeholder="jedlm@gmail.com"
        reference={emailRef}
        defaultValue={currentGarage?.email}
      >
        {errors.email &&
          errors.email.type === 'pattern' &&
          lg.quote.messageEmail}
        {errors.email &&
          errors.email.type === 'required' &&
          lg.quote.messageField}
      </InputLabel>

      <div className={classes.passwordSection}>
        {displayPassword && (
          <>
            <InputPassword
              name="oldPassword"
              theme="light"
              label={lg.rates.oldPassword}
            />
            <InputPassword
              name="newPassword"
              theme="light"
              label={lg.rates.newPassword}
            />
            <InputPassword
              name="confirmNewPassword"
              theme="light"
              label={lg.rates.confirmPassword}
            />
          </>
        )}
        <Button
          onClick={handleDisplayPassword}
          width="274px"
          theme="dark"
          title={lg.rates.password}
        />
      </div>

      <div className={classes.phoneWrapper}>
        <span className={classes.labelPhone}>{lg.rates.phone}</span>
        <Controller
          as={
            <PhoneField
              reset={undefined}
              errorMessage={lg.quote.messagePhone}
            />
          }
          control={control}
          name="phone"
          defaultValue={currentGarage?.phone}
        />
      </div>

      <div className={classes.phoneWrapper}>
        <span className={classes.labelPhone}>{lg.rates.portable}</span>
        <Controller
          as={
            <PhoneField
              reset={undefined}
              errorMessage={lg.quote.messagePhone}
            />
          }
          control={control}
          name="mobile"
          defaultValue={currentGarage?.mobilePhone}
        />
        {errors.phone && errors.phone.type === 'pattern'}
      </div>
    </>
  );
};

export default AccountDetailsForm;

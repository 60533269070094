// eslint-disable-next-line @typescript-eslint/no-unused-vars
import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';
import {
  createTheme,
  InputAdornment,
  makeStyles,
  SimplePaletteColorOptions,
  TextField,
  ThemeProvider,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Store } from '../../../../redux/types';

interface CarMaintenanceProps {
  handleOnChangeKm: (element: number | null) => void;
  handleDateChange: (date: Date | null) => void;
  selectedDate: Date | null;
}

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '20px',
    display: 'flex',
    '& .MuiFormControl-root': {
      width: '60%',
    },
  },
  b: {
    fontWeight: 'bold',
  },
  row: {
    flex: 'auto',
  },
  datepicker: {
    flex: 'auto',
  },
  input: {
    background: '#ffffff',
    padding: '11px',
    borderRadius: '8px',
    marginTop: '20px !important',
    MozAppearance: 'textfield',
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
  label: {
    transform: 'translate(0, 1.5px) scale(0.75)',
  },
  km: {
    fontWeight: 'bold',
    color: '#757575',
  },
  info: {
    marginTop: '2rem',
    borderRadius: '10px',
  },
  bold: {
    fontWeight: 'bold',
  },
}));

const palette: SimplePaletteColorOptions = {
  main: '#4d4d4d',
};

const theme = createTheme({
  palette: {
    primary: palette,
  },
  props: {
    MuiInput: {},
  },
});

const CarMaintenance: React.FC<CarMaintenanceProps> = ({
  handleOnChangeKm,
  handleDateChange,
  selectedDate,
}) => {
  const styles = useStyles();

  const [error, setError] = useState<string>('');

  const {
    quotesReducer: { currentVehicle },
    languageReducer: { language: lg },
  } = useSelector((state: Store) => state);

  const handleOnChangeValidator = (km: number) => {
    if (km < 1 || km > 999999 || Number.isNaN(km)) {
      setError(lg.quote.kmError);
      handleOnChangeKm(null);
    } else {
      setError('');
      handleOnChangeKm(km);
    }
  };

  return (
    <>
      <Alert severity="info" className={styles.info}>
        {lg.quote.carMaintenanceInfo}
        {currentVehicle?.releaseDate !== null && (
          <p>
            {' '}
            {lg.quote.carMaintenanceInfoReleaseDate}{' '}
            <b className={styles.b}>
              {moment(currentVehicle?.releaseDate).format('DD-MM-YYYY')}
            </b>
          </p>
        )}
      </Alert>

      <div className={styles.root}>
        <div className={styles.row}>
          <TextField
            type="tel"
            error={!!error.length}
            label={lg.quote.km}
            name="km"
            minRows={1}
            maxRows={999999}
            required
            onChange={(e) => handleOnChangeValidator(+e.target.value)}
            InputProps={{
              className: styles.input,
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <div className={styles.km}>KM</div>
                </InputAdornment>
              ),
            }}
            helperText={error}
            InputLabelProps={{ className: styles.label }}
          />
        </div>

        <div className={styles.datepicker}>
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                format="DD/MM/yyyy"
                required
                value={selectedDate}
                autoOk
                disableToolbar
                label={lg.quote.releaseDate}
                onChange={handleDateChange}
                InputProps={{
                  className: styles.input,
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                invalidDateMessage={lg.quote.releaseDateError}
              />
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </div>
      </div>
    </>
  );
};

export default CarMaintenance;

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

const PhoneField = ({
  name,
  className,
  id,
  label,
  onChange,
  phoneError,
  value,
  withIcon,
  withButton,
  setHasError,
  reset,
  errorMessage,
}) => {
  const [phoneValue, setValue] = useState('');
  const [error, setError] = useState(phoneError);

  useEffect(() => {
    if (value && phoneValue === '') {
      setValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (reset) {
      setValue('');
    }
  }, [phoneValue, reset]);

  useEffect(() => {
    setError(phoneError);
  }, [phoneError]);

  const changePhoneNumber = (args) => {
    if (args[0]) {
      const number = args[3] && args[3].replace(/ /g, '');
      if (!number) {
        setError(true);
        setHasError(true);
        return;
      }
      setValue(number);
      setError(false);
      setHasError(false);
      onChange(number);
    } else {
      setError(true);
    }
  };

  return (
    <div className={className} id={id}>
      {label && (
        <label htmlFor="phoneNumber" className={withIcon && 'withIcon'}>
          <span>{label}</span>
        </label>
      )}
      <div className="inputErrorContainer">
        <IntlTelInput
          containerClassName={`intl-tel-input ${
            error ? 'input-error-true' : ''
          }`}
          inputClassName="form-control"
          placeholder=""
          fieldId={id}
          label={label}
          formatOnInit={false}
          fieldName={name || 'phoneNumber'}
          preferredCountries={['fr', 'be']}
          onlyCountries={['fr', 'be']}
          nationalMode
          value={phoneValue}
          onPhoneNumberChange={(...args) => {
            if (!withButton) {
              changePhoneNumber(args);
            }
            setValue(args[1]);
            setError(!args[0]);
            setHasError(!args[0]);
          }}
        />
        {error && <p className="error-label">{errorMessage}</p>}
      </div>
    </div>
  );
};

PhoneField.defaultProps = {
  className: 'phoneContainer',
  id: '',
  name: '',
  label: null,
  value: null,
  phoneError: false,
  withIcon: false,
  withButton: false,
  onChange: () => {},
  setHasError: () => {},
  reset: null,
  errorMessage: '',
};

PhoneField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  setHasError: PropTypes.func,
  phoneError: PropTypes.bool,
  value: PropTypes.string,
  withIcon: PropTypes.bool,
  withButton: PropTypes.bool,
  reset: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default PhoneField;

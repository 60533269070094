import React, { useState } from 'react';

interface TyreFilterProps {
  options: Array<string>;
  handleOptionsChanged: (newOptions: Array<string>) => void;
  label: string;
}

const TyreFilter: React.FC<TyreFilterProps> = ({
  options,
  handleOptionsChanged,
  label
}) => {
  const [selectedOptions, setSelectedOptions] = useState<Array<string>>([]);

  const toggleSelectedOption = (option: string): void => {
    if (selectedOptions.includes(option)) {
      const newOptions = selectedOptions.filter(old => old !== option);
      setSelectedOptions(newOptions);
      handleOptionsChanged(newOptions);
    } else {
      const newOptions = [...selectedOptions, option];
      setSelectedOptions(newOptions);
      handleOptionsChanged(newOptions);
    }
  };

  return (
    <>
      <h3>{options.length > 0 && label}</h3>
      {options.map(option => (
        <label htmlFor={option}>
          <input
            type="checkbox"
            name={option}
            id={option}
            checked={selectedOptions.includes(option)}
            onChange={() => toggleSelectedOption(option)}
          />
          {option}
        </label>
      ))}
    </>
  );
};

export default TyreFilter;

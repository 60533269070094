import { Action, Store } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { City } from './types';

export const SET_CITIES = 'SET_CITIES';

export type ActionTypes = { type: typeof SET_CITIES; payload: City[] };

export const setCities = (cities: City[]): ActionTypes => ({
  type: SET_CITIES,
  payload: cities,
});

export const getCities =
  (
    countrycode: string,
    zipcode: string
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    const query = `
    {
      villes(where: { country_code: "${countrycode}", postal_code: "${zipcode}" }) {
        name
      }
    }
    `;

    return fetch(`${process.env.REACT_APP_API_STRAPI}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query,
      }),
    })
      .then((response) => {
        if (response.status >= 400) {
          throw new Error('Error fetching data');
        } else {
          return response.json();
        }
      })
      .then((data) => dispatch(setCities(data.data.villes)));
  };

import _get from 'lodash/get';
import {
  AutossimoLogin,
  CONFIDENCE,
  Garage
} from '../../redux/stores/global/types';

export const normalizeGarage = (garageData: any): Garage => ({
  ...garageData,
  codis: _get(garageData, 'codis', 0),
  civility: _get(garageData, 'civility', ''),
  lastname: _get(garageData, 'lastname', ''),
  firstname: _get(garageData, 'firstname', ''),
  companyName: _get(garageData, 'companyName', ''),
  slugCompanyName: _get(garageData, 'slugCompanyName', ''),
  fax: _get(garageData, 'fax', ''),
  numrcs: _get(garageData, 'numrcs', ''),
  cliCertificateNumber: _get(garageData, 'cliCertificateNumber', ''),
  servicesAdd: _get(garageData, 'servicesAdd', []),
  confidence: _get(garageData, 'confidence', CONFIDENCE.STANDARD),
  garageAddress: {
    address1: _get(garageData, 'garageAddress.address1', ''),
    address2: _get(garageData, 'garageAddress.address2', ''),
    zipcode: _get(garageData, 'garageAddress.zipcode', ''),
    city: _get(garageData, 'garageAddress.city', ''),
    slugCity: _get(garageData, 'garageAddress.slugCity', ''),
    country: _get(garageData, 'garageAddress.country', ''),
    location: {
      ...garageData?.garageAddress?.location
    }
  },
  garageBillingInfo: {
    ...garageData.garageBillingInfo
  },
  garageCalendar: {
    exceptionalWeeks: _get(garageData, 'garageCalendar.exceptionalWeeks', []),
    standardWeek: _get(garageData, 'garageCalendar.standardWeek', null)
  },
  garageNetwork: _get(garageData, 'garageNetwork', []),
  garageBrand: {
    brand: _get(garageData, 'garageBrand.brand', ''),
    slugBrand: _get(garageData, 'garageBrand.slugBrand', ''),
    tradeMark: _get(garageData, 'garageBrand.tradeMark', '')
  },
  garageExtraContent: _get(garageData, 'garageExtraContent', []),
  membership: _get(garageData, 'membership', undefined),
  autossimo: _get(garageData, 'autossimo', null),
  logo: _get(garageData, 'logo', ''),
  state: _get(garageData, 'state', ''),
  parrain: _get(garageData, 'parrain', ''),
  superAdmin: _get(garageData, 'superAdmin', false),
  confidenceUpdateAt: _get(garageData, 'confidenceUpdateAt', []),
  createdAt: _get(garageData, 'createdAt', []),
  updatedAt: _get(garageData, 'updatedAt', []),
  distance: _get(garageData, 'distance', null),
  favoriteLanguage: _get(garageData, 'favoriteLanguage', ''),
  sadPartenaireIds: _get(garageData, 'sadPartenaireIds', []),
  garageTest: _get(garageData, 'garageTest', false)
});

export const normalizeAutossimo = (autossimoData: any): AutossimoLogin => ({
  login: _get(autossimoData, 'login', ''),
  password: _get(autossimoData, 'password', '')
});

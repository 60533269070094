import React, { useState } from 'react';

import Modal from 'react-bootstrap/Modal';

import { useDispatch, useSelector } from 'react-redux';
import { FormikValues } from 'formik';
import { useForm } from 'react-hook-form';

import { Store } from '../../../redux/types';
import Button from '../../../assets/generic-components/Button';

import close from '../../../assets/style-elements/01-electrons-icon-close.png';
import { setNotification } from '../../../redux/stores/global/actions';
import { SeverityMessage } from '../../../redux/stores/global/types';
import {
  setBooking,
  UpdateComment,
} from '../../../redux/stores/planning/actions';
import { Booking } from '../../../redux/stores/planning/types';
import useStyles from './stylesModal';

const EditComment: React.FC = ({ children }) => {
  const style = useStyles();

  const {
    languageReducer: { language: lg },
    planningStore: { currentBooking },
  } = useSelector<Store, Store>((state) => state);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const { register } = useForm<FormikValues>({
    mode: 'onChange',
  });

  if (!currentBooking) return null;
  const [comments, setComments] = useState(currentBooking.comment as string);

  const onSave = (comment: any): void => {
    dispatch(
      UpdateComment(
        currentBooking?.bookingId || '',
        comment,
        () => {
          const newComment: Booking = {
            ...currentBooking,
            comment,
          };
          dispatch(setBooking(newComment));
          dispatch(
            setNotification({
              severity: SeverityMessage.SUCCESS,
              message: lg.general.success,
            })
          );
        },
        (e) => {
          dispatch(
            setNotification({
              severity: SeverityMessage.ERROR,
              message: e.message,
            })
          );
        }
      )
    );
  };

  return (
    <>
      <div role="button" tabIndex={0} onClick={handleShow}>
        {children}
      </div>
      <Modal
        dialogClassName={style.content}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className={style.modalHeader}>
          <span className={style.titleModal}>{lg.command.comment.title}</span>
          <img
            className="imgPopup"
            src={close}
            alt="X"
            onClick={() => handleClose()}
          />
        </Modal.Header>

        <Modal.Body>
          <label htmlFor="comments" className={style.boxComment}>
            <span className={style.labelStyle}>{lg.command.comment.label}</span>
            <textarea
              className={style.commentBox}
              onChange={(e) => setComments(e.target.value)}
              name="comments"
              ref={register}
              value={comments}
            />
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button
            theme="light"
            title={lg.command.comment.close}
            onClick={() => handleClose()}
          />
          <Button
            theme="dark"
            title={lg.command.comment.confirm}
            onClick={() => {
              onSave(comments);
              handleClose();
            }}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditComment;

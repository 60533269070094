import React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as ROUTES from '../../router/routes';
import Command from '../command-page';
import PlanningFull from '../planning-page/components/planning-full-page';
import AddAppointment from './appointment-without-quote';
import quoteByAppointmentConfirm from './appointment-with-quote/quoteByAppointmentConfirm';
import quoteByAppointmentSelection from './appointment-with-quote/quoteByAppointmentSelection';
import quoteByAppointmentSummaryOrder from './appointment-with-quote/quoteByAppointmentSummaryOrder';

const Quote = () => (
  <Switch>
    <Route exact path={ROUTES.QUOTE} component={quoteByAppointmentSelection} />
    <Route path={ROUTES.ADD_BOOKING} component={AddAppointment} />
    <Route
      path={ROUTES.QUOTE_SUMMARY}
      component={quoteByAppointmentSummaryOrder}
    />
    <Route path={ROUTES.QUOTE_CONFIRM} component={quoteByAppointmentConfirm} />
    <Route path={ROUTES.PLANNING} component={PlanningFull} />
    <Route path={ROUTES.COMMAND} component={Command} />
  </Switch>
);

export default Quote;

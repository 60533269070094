import React from 'react';
import { useSelector } from 'react-redux';
import useStyles from './style';
import { Store } from '../../../../redux/types';
import InputMutipleSelect from '../../../../assets/generic-components/InputMutipleSelect';

type QuoteServicesProps = {
  key: string;
  carServices: string;
  onChange: (e: { currentTarget: { value: string | number } }) => void;
  reference: React.LegacyRef<HTMLInputElement>;
  datas: {}[];
  selected?: string;
};

const QuoteServices = ({
  key,
  carServices,
  onChange,
  reference,
  datas,
  selected
}: QuoteServicesProps) => {
  const classes = useStyles();
  const lg = useSelector((state: Store) => state.languageReducer.language);

  return (
    <li key={key}>
      <label htmlFor="InputMutipleSelect">{carServices}</label>
      <InputMutipleSelect
        onChange={onChange}
        className={classes.wrapSl}
        name="duration"
        reference={reference}
        datas={datas}
        label={lg.quote.timeOfRDV}
        selected={selected}
      />
    </li>
  );
};

QuoteServices.defaultProps = {
  selected: ''
};

export default QuoteServices;

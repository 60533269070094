import React from 'react';
import useSWRImmutable from 'swr/immutable';
import TyreFilter from './tyre-filter';
import { getTyresBrands } from '../../../../services/quote/tyres';

interface BrandFilterProps {
  handleOptionsChanged: (newOptions: Array<string>) => void;
}

const BrandFilter: React.FC<BrandFilterProps> = ({ handleOptionsChanged }) => {
  const { data } = useSWRImmutable('/tyres/brands', getTyresBrands);

  return (
    <TyreFilter
      options={data || []}
      handleOptionsChanged={handleOptionsChanged}
      label="Marques"
    />
  );
};

export default BrandFilter;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Tyre from './tyre';
import colors from '../../../../assets/style-colors';
import { Store } from '../../../../redux/types';
import { ITyre, getTyresByOptions } from '../../../../services/quote/tyres';
import { PaginationDetails } from '../../../../services/http-service';
import TyreFilter from './tyre-filter';
import BrandFilter from './brand-filter';
import { TyresQuality } from '../../../../services/tyres';

const useStyle = makeStyles({
  tyresView: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    width: '70%',
    minHeight: '100%',
    margin: '0 auto',
    paddingTop: '60px',
    paddingBottom: '60px',
    gap: '20px',
  },
  sidebar: {
    flex: '0.3 0 0',
    height: '100%',
    top: '70px',
    position: 'sticky',

    lineHeight: '2',

    '& > p': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      marginBottom: '15px',
      marginTop: '10px',
    },
  },
  filter: {
    backgroundColor: colors.paleGreyDrank,
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    width: '100%',
    height: 'calc(100vh - 150px)',
    overflowY: 'scroll',

    '& h3': {
      fontSize: '14px',
      fontWeight: 'bold',

      margin: '15px 0',
    },
    '& input': {
      marginRight: '10px',
    },
  },
  content: {
    flex: '0.8 0 0',
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& p': {
      fontSize: '16px',
      fontWeight: 'normal',

      '& span': {
        fontWeight: 'bold',
      },
    },
  },
  tyres: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  order: {
    borderRadius: '8px',
    border: 'none',
    marginBottom: '15px',
    padding: '10px',
  },
});

const TyresList: React.FC = () => {
  const tyresListRef = useRef<HTMLDivElement>(null);
  const classes = useStyle();
  const history = useHistory();
  const tyresOptions = useSelector(
    (state: Store) => state.quotesReducer.tyres.options
  );
  const lang = useSelector((state: Store) => state.languageReducer.language);
  const garage = useSelector(
    (state: Store) => state.globalReducer.currentGarage
  );
  const [tyresQualityType, setTyresQualityType] = useState<Array<string>>([]);
  const [tyresBrands, setTyresBrands] = useState<Array<string>>([]);
  const [tyres, setTyres] = useState<Array<ITyre>>([]);
  const [tyresPagination, setTyresPagination] =
    useState<PaginationDetails | null>(null);
  const [offset, setOffset] = useState(0);
  const [order, setOrder] = useState<string | null>(null);

  const handleGoBack = () => {
    history.goBack();
  };

  useEffect(() => {
    setTyres([]);
    setOffset(0);
  }, [tyresQualityType, tyresBrands, order]);

  useEffect(() => {
    if (tyresOptions && garage) {
      getTyresByOptions(
        { ...tyresOptions, tyresQualityType, tyresBrands, order },
        garage,
        offset
      ).then((tyresList) => {
        setTyresPagination(tyresList.paginationDetails);
        setTyres((oldTyres) => [...oldTyres, ...tyresList.tyres]);
      });
    }
  }, [offset]);

  const handleObserver = useCallback(
    (entries) => {
      if (tyres.length < offset) {
        return;
      }
      if (entries[0]?.isIntersecting) {
        setOffset((oldOffset) => oldOffset + 20);
      }
    },
    [tyres]
  );

  useEffect(() => {
    const lazyObserver = new IntersectionObserver(handleObserver);
    if (tyresListRef.current) {
      lazyObserver.observe(tyresListRef.current);
    }
    return () => lazyObserver.disconnect();
  }, [tyresListRef, handleObserver]);

  return (
    <div className={classes.tyresView}>
      <div className={classes.sidebar}>
        <p onClick={handleGoBack}>
          <KeyboardArrowLeftIcon className="backward-grey" />
          {lang.quote.back}
        </p>
        <div className={classes.filter}>
          <TyreFilter
            options={[
              TyresQuality.PREMIUM,
              TyresQuality.BUDGET,
              TyresQuality.QUALITY,
            ]}
            handleOptionsChanged={setTyresQualityType}
            label={lang.quote.qualities}
          />
          <BrandFilter handleOptionsChanged={setTyresBrands} />
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.head}>
          <p>
            <span>{tyresPagination?.count || '...'} pneus</span> correspondent à
            votre recherche
          </p>
          <select
            name="order"
            className={classes.order}
            onChange={(e) => setOrder(e.target.value)}
          >
            <option value="none">{lang.quote.order}</option>
            <option value="asc">{lang.quote.priceAsc}</option>
            <option value="desc">{lang.quote.priceDesc}</option>
          </select>
        </div>
        <div className={classes.tyres}>
          {tyres.map((tyre: ITyre) => (
            <Tyre tyre={tyre} />
          ))}
        </div>
        <div ref={tyresListRef} />
      </div>
    </div>
  );
};

export default TyresList;

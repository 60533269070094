import React, { useRef, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Button from '../../assets/generic-components/Button';
import { Store } from '../../redux/types';
import HttpService from '../../services/http-service';
import { setNotification } from '../../redux/stores/global/actions';
import setAutossimoUserData from '../../services/autossimo';
import AutossimoFormFields, {
  AutossimoFormValues,
} from './AutossimoFormFields';
import AutossimoHiddenForm from './AutossimoHiddenForm';
import { SeverityMessage } from '../../redux/stores/global/types';

interface AutossimoModalFormProps {
  isOpen: boolean;
  onHide: () => void;
  autossimoXML: string;
}

const useStyles = makeStyles({
  header: {
    padding: '30px 30px 0px 30px',
    border: '0px solid white',
  },
  modal: {},
  body: {
    marginTop: '20px',
  },
  text: {
    marginTop: '20px',
  },
  fields: {
    marginTop: '15px',

    '& svg': {
      bottom: '30px',
    },
  },
});

const AutossimoModalForm: React.FC<AutossimoModalFormProps> = ({
  isOpen,
  onHide,
  autossimoXML,
}) => {
  const dispatch = useDispatch();

  const {
    globalReducer: { currentGarage },
    languageReducer: { language: lg },
  } = useSelector((state: Store) => state);

  const autossimoGarage = useSelector(
    (state: Store) => state.globalReducer.currentGarage?.autossimo
  );
  const garageId = currentGarage?.id;

  const [password, setPassword] = useState(currentGarage?.autossimo?.password);
  const [login, setLogin] = useState(currentGarage?.autossimo?.login);

  const autossimoHiddenForm = useRef<HTMLFormElement>();

  const client = HttpService.getClient();

  const { register, errors } = useForm<AutossimoFormValues>({
    mode: 'onChange',
  });

  const handleAutossimoSubmit = (values: AutossimoFormValues): void => {
    setLogin(values.login);
    setPassword(values.password);

    dispatch(
      setAutossimoUserData(
        autossimoGarage ? client.put : client.post,
        login || '',
        password || '',
        garageId || '',
        () => {
          autossimoHiddenForm?.current?.submit();
        },
        (error: Error) =>
          dispatch(
            setNotification({
              severity: SeverityMessage.ERROR,
              message: error.message,
            })
          )
      )
    );
  };

  const classes = useStyles();

  return (
    <Modal show={isOpen} onHide={onHide} className={classes.modal}>
      <Modal.Header className={classes.header} closeButton>
        <Modal.Title>Connexion à Autossimo</Modal.Title>
      </Modal.Header>
      <Modal.Body className={classes.body}>
        <AutossimoFormFields
          className={classes.fields}
          loginRef={register({
            required: true,
          })}
          passwordRef={register({
            required: true,
          })}
          passwordValue={password}
          loginChange={(e) => setLogin(e.target.value)}
          passwordChange={(e) => setPassword(e.target.value)}
          errors={errors}
        />
        <AutossimoHiddenForm
          login={login}
          password={password}
          ref={autossimoHiddenForm}
          autossimoXML={autossimoXML}
        />
        <p className={classes.text}>
          Mot de passe perdu ? <Link to="/contact">Envoyez-nous un mail</Link>{' '}
          avec vos coordonnées, nous ne manqueront pas de vous recontacter.
        </p>
        <p className={classes.text}>
          Si vous souhaitez vous abonner à autossimo,{' '}
          <a href="http://www3.autossimo.com/">cliquez ici</a>.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          theme="light"
          title={lg.command.appointment.close}
          onClick={(e) => {
            e.preventDefault();
            onHide();
          }}
        />
        <Button
          width="274px"
          theme="dark"
          type="submit"
          title={lg.login.connectButton}
          onClick={() => handleAutossimoSubmit({ login, password })}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default AutossimoModalForm;
